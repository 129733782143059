<script lang="ts">
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	
	import formatCurrency from 'utility/format/format-currency'
	import formatDuration from 'utility/format/format-duration'
	import { providerStatCalculations, type JobClaimStats } from 'utility/provider-stat-calculations'

	export let jobClaims: JobClaimStats[] = []

	$: providerEarningsInLastSevenDays = providerStatCalculations(jobClaims, { weeks: 1 })
	$: providerEarningsInLastThirtyDays = providerStatCalculations(jobClaims, { months: 1 })
	$: providerEarningsInLastYear = providerStatCalculations(jobClaims, { years: 1 })
</script>

<CollapsibleCard
	entireHeaderToggles
	cardHeaderClass="card-header d-flex justify-content-between h6"
	headerText="Earnings"
	bodyShown={false}
	bodyClass="p-1"
>
	<table class="table table-borderless table-sm mb-0">
		<thead>
			<tr>
				<th scope="col"></th>
				<th scope="col">Earnings</th>
				<th scope="col"># Jobs</th>
				<th scope="col">Hours</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th scope="row">Week</th>
				<td style="font-size: small;">{formatCurrency(providerEarningsInLastSevenDays.totalEarnings.toString())}</td>
				<td style="font-size: small;">{providerEarningsInLastSevenDays.numberOfJobs}</td>
				<td style="font-size: small;">{formatDuration(providerEarningsInLastSevenDays.hours)}</td>
			</tr>
			<tr>
				<th scope="row">Month</th>
				<td style="font-size: small;">{formatCurrency(providerEarningsInLastThirtyDays.totalEarnings.toString())}</td>
				<td style="font-size: small;">{providerEarningsInLastThirtyDays.numberOfJobs}</td>
				<td style="font-size: small;">{formatDuration(providerEarningsInLastThirtyDays.hours)}</td>
			</tr>
			<tr>
				<th scope="row">Year</th>
				<td style="font-size: small;">{formatCurrency(providerEarningsInLastYear.totalEarnings.toString())}</td>
				<td style="font-size: small;">{providerEarningsInLastYear.numberOfJobs}</td>
				<td style="font-size: small;">{formatDuration(providerEarningsInLastYear.hours)}</td>
			</tr>
		</tbody>
	</table>
</CollapsibleCard>
