<script lang="ts">
	import type { GetQuoteGoogleAddress } from 'utility/get-quote-helper'
	import SignUpForm from 'components/SignUpForm.svelte'
	export let requestedRoles: Array<'CUSTOMER' | 'PROVIDER'> = []
	export let firstAddress: GetQuoteGoogleAddress | null = null
</script>

<div class="container d-flex justify-content-center mt-3">
	<div>
		<img
			src="images/lawnhiro-logo-horizontal.svg"
			style="height: 50px;"
			class="mb-2"
			alt="LawnHiro logo"
		/>
		<div
			class="card"
			style="max-width: 900px;"
		>
			<h2 class="card-header">Create Account</h2>
			<div class="card-body">
				<SignUpForm
					{requestedRoles}
					showAccountTypeSelection
					{firstAddress}
				/>
			</div>
		</div>
	</div>
</div>
