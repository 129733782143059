<script lang="ts">
	import { onMount, tick } from 'svelte'
	import Input from '@isoftdata/svelte-input'

	export let places: google.maps.places.PlaceResult[]
	export let bounds: google.maps.LatLngBounds | null
	export let pacInputValue = ''
	export let inputLabel = 'Property Address'
	let autocomplete: google.maps.places.Autocomplete

	export let pacInput: HTMLInputElement | undefined = undefined

	$: pacInputValue = places?.[0]?.formatted_address ?? ''

	$: bounds ? autocomplete?.setBounds(bounds) : null

	onMount(async () => {
		await tick()
		const { Autocomplete } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary
		try {
			if (pacInput) {
				autocomplete = new Autocomplete(pacInput, { types: ['address'] })
			}

			autocomplete.addListener('place_changed', () => {
				places = [autocomplete.getPlace()]
			})
		} catch (e) {
			console.error(e)
		}
	})
</script>

<Input
	id="pac-input"
	value={pacInputValue}
	inputmode="text"
	label={inputLabel}
	placeholder="1234 Main St, City, State, Zip"
	bind:input={pacInput}
/>
