export default {
    "name": "LoadProviderClaimableJobsCount",
    "kind": "HoudiniQuery",
    "hash": "f199be4893186db71e658784033b4a8f2e53ee17003f3f9fd5cf69cadfe16201",

    "raw": `query LoadProviderClaimableJobsCount($filter: ClaimableJobFilter) {
  claimableJobs(filter: $filter) {
    info {
      totalItems
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "claimableJobs": {
                "type": "JobResponse",
                "keyRaw": "claimableJobs(filter: $filter)",

                "selection": {
                    "fields": {
                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ClaimableJobFilter"
        },

        "types": {
            "ClaimableJobFilter": {
                "regionIds": "PositiveInt",
                "requestedScheduleFrom": "Date",
                "requestedScheduleTo": "Date",
                "serviceId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2c0a31aa2c5789e36b3e2cab3627265415b84d48b2d5141200c61fa17c986494";