import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetNotificationPreferences'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetNotificationPreferencesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetNotificationPreferencesStore",
			variables: false,
		})
	}
}

export async function load_GetNotificationPreferences(params) {
	await initClient()

	const store = new GetNotificationPreferencesStore()

	await store.fetch(params)

	return {
		GetNotificationPreferences: store,
	}
}
