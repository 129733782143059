import type { WritableDeep } from 'type-fest'

import { endOfDay, parseISO, startOfDay } from 'date-fns'

import { graphql, LoadPayablesStore, type LoadPayables$result, type TransactionPaymentClassification$options } from '$houdini'
import getComputedJob from 'utility/get-computed-job'
import { formatDuration } from 'utility/format/format-duration'
import financialNumber from 'financial-number'

export type ProviderPayable = WritableDeep<LoadPayables$result['payables']['data'][number]>

export type ComputedProviderPayable = {
	providerName: string
	providerFirstName: string
	providerLastName: string
	providerEmail: string
	providerId: number
	jobs: ComputedTransactionPayment[]
	totalPayout: string
	totalElapsedTime: number
}

export type ComputedTransactionPayment = ProviderPayable & {
	providerId: number | undefined
	providerName: string | null | undefined
	providerEmail: string | undefined
	formattedElapsedTime: string
	payout: string
	payoutType: TransactionPaymentClassification$options
	jobId: number | null | undefined
	customerAddress: string | null | undefined
	customerName: string | null | undefined
	requestedScheduleFormatted: string | null | undefined
	completedFormatted: string | null | undefined
	cost: string | null | undefined
	tip: string | null | undefined
}

export type ComputedProviderPayout = {
	providerEmail: string
	providerName: string
	providerId: number
	providerFirstName: string
	providerLastName: string
	jobs: ComputedProviderPayable[]
	totalPayout: string
	totalElapsedTime: number
}

export type PayableJob = WritableDeep<Exclude<ProviderPayable['transaction']['job'], null>>

export type PayableJobClaim = WritableDeep<Exclude<PayableJob, null>['activeJobClaim']>

const loadPayablesQuery: LoadPayablesStore = graphql`
	query LoadPayables($filter: PayableJobFilter) {
		payables(filter: $filter) {
			data {
				transaction {
					paymentStatus
					cost
					invoiceStatus
					coupon {
						code
					}
					job {
						residence {
							zip
							state
							city
							street
						}
						customer {
							userAccount {
								fullName
							}
						}
						completed
						totalElapsedTime
						jobStatus
						requestedSchedule
						id
						created
						activeJobClaim {
							provider {
								userAccount {
									firstName
									lastName
									fullName
									email
								}
								id
							}
							id
						}
					}
					tip
				}
				payableDate
				amount
				created
				transactionPaymentClassification
			}
		}
	}
`

export async function loadPayables({ from, to }: { from: string; to: string }): Promise<ProviderPayable[]> {
	const completedFrom = startOfDay(parseISO(from))
	const completedTo = endOfDay(parseISO(to))

	const payables = await loadPayablesQuery.fetch({
		variables: {
			filter: {
				completedFrom,
				completedTo,
			},
		},
	})
	return payables.data?.payables.data ?? []
}

export function computeTransactionPayments(payables: ProviderPayable[]): ComputedTransactionPayment[] {
	const computedTransactionPayments: ComputedTransactionPayment[] = payables.reduce((acc, transactionPayment) => {
		if (transactionPayment.transaction?.job?.activeJobClaim) {
			const computedJob = getComputedJob<PayableJob>(transactionPayment.transaction.job, { relativeDates: false })
			let payout = '0'
			const providerId = transactionPayment.transaction.job.activeJobClaim.provider.id
			if (transactionPayment.transactionPaymentClassification === 'JOB') {
				payout = transactionPayment.transaction.cost
			} else if (transactionPayment.transactionPaymentClassification === 'TIP') {
				payout = transactionPayment.transaction.tip ?? '0'
			}
			acc.push({
				...transactionPayment,
				customerAddress: `${transactionPayment.transaction.job.residence.street} ${computedJob.residence.cityStateZipFormatted}`,
				customerName: transactionPayment.transaction.job.customer.userAccount.fullName,
				providerId,
				providerEmail: transactionPayment.transaction.job.activeJobClaim.provider.userAccount.email,
				providerName: transactionPayment.transaction.job.activeJobClaim.provider.userAccount.fullName,
				cost: transactionPayment.transaction.cost,
				formattedElapsedTime: transactionPayment.transactionPaymentClassification === 'JOB' ? formatDuration(transactionPayment?.transaction?.job?.totalElapsedTime ?? 0) : '',
				jobId: transactionPayment.transaction.job.id,
				requestedScheduleFormatted: computedJob.requestedScheduleFormatted,
				completedFormatted: new Date(transactionPayment.payableDate).toUTCString(),
				tip: transactionPayment.transaction.tip,
				payoutType: transactionPayment.transactionPaymentClassification,
				payout,
			})
		}
		return acc
	}, new Array<ComputedTransactionPayment>())
	return computedTransactionPayments
}

export function computeProviderPayouts(computedTransactionPayments: ComputedTransactionPayment[]): ComputedProviderPayable[] {
	return computedTransactionPayments.reduce((acc, transactionPayment) => {
		const providerIndex = acc.findIndex(accProviders => accProviders.providerId === transactionPayment.providerId)
		if (providerIndex > -1) {
			acc[providerIndex].jobs.push(transactionPayment)
			acc[providerIndex].totalPayout = financialNumber(acc[providerIndex].totalPayout).plus(transactionPayment.payout).toString(2)
			if (transactionPayment.payoutType === 'JOB') {
				acc[providerIndex].totalElapsedTime = acc[providerIndex].totalElapsedTime + (transactionPayment?.transaction?.job?.totalElapsedTime ?? 0)
			}
		} else {
			const providerId = transactionPayment.providerId as number // We can be sure if we find the index, this will be defined
			acc.push({
				providerEmail: transactionPayment.providerEmail ?? '',
				providerName: transactionPayment.providerName ?? '',
				providerId,
				providerFirstName: transactionPayment.transaction.job?.activeJobClaim?.provider.userAccount.firstName ?? '',
				providerLastName: transactionPayment.transaction.job?.activeJobClaim?.provider.userAccount.lastName ?? '',
				jobs: [transactionPayment],
				totalPayout: transactionPayment.payout,
				totalElapsedTime: transactionPayment?.transactionPaymentClassification === 'JOB' ? transactionPayment?.transaction?.job?.totalElapsedTime ?? 0 : 0,
			})
		}
		return acc
	}, new Array<ComputedProviderPayable>())
}

export function getCouponCodesUsed(computedTransactionPayments: ComputedTransactionPayment[]): Record<string, number> {
	const couponCodesUsed = computedTransactionPayments.reduce((acc: Record<string, number>, transactionPayment) => {
		if (transactionPayment.transaction.coupon && transactionPayment.transactionPaymentClassification === 'JOB') {
			const couponCode = transactionPayment.transaction.coupon.code
			if (acc[couponCode]) {
				acc[couponCode]++
			} else {
				acc[couponCode] = 1
			}
		}
		return acc
	}, {})
	return couponCodesUsed
}
