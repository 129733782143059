export default {
    "name": "MakeCoupon",
    "kind": "HoudiniMutation",
    "hash": "892f9104ab56adc55de4bc57ab1a12a632f6ceead7ca2063da185915a3841d43",

    "raw": `mutation MakeCoupon($newCoupon: NewCoupon!) {
  newCoupon(newCoupon: $newCoupon) {
    ...CouponData
    id
  }
}

fragment CouponData on Coupon {
  id
  code
  description
  validFrom
  validTo
  numberOfTimesUsed
  maximumTimesUsed
  usesPerUser
  serviceId
  priceAdjustment
  isPercentage
  active
  firstJobOnly
  isValid
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newCoupon": {
                "type": "Coupon",
                "keyRaw": "newCoupon(newCoupon: $newCoupon)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "NonEmptyString",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "validFrom": {
                            "type": "DateTime",
                            "keyRaw": "validFrom",
                            "visible": true
                        },

                        "validTo": {
                            "type": "DateTime",
                            "keyRaw": "validTo",
                            "nullable": true,
                            "visible": true
                        },

                        "numberOfTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "numberOfTimesUsed",
                            "visible": true
                        },

                        "maximumTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "maximumTimesUsed",
                            "nullable": true,
                            "visible": true
                        },

                        "usesPerUser": {
                            "type": "NonNegativeInt",
                            "keyRaw": "usesPerUser",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "serviceId",
                            "nullable": true,
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "isPercentage": {
                            "type": "Boolean",
                            "keyRaw": "isPercentage",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "firstJobOnly": {
                            "type": "Boolean",
                            "keyRaw": "firstJobOnly",
                            "visible": true
                        },

                        "isValid": {
                            "type": "Boolean",
                            "keyRaw": "isValid",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "CouponData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newCoupon": "NewCoupon"
        },

        "types": {
            "NewCoupon": {
                "code": "NonEmptyString",
                "description": "String",
                "firstJobOnly": "Boolean",
                "isPercentage": "Boolean",
                "maximumTimesUsed": "NonNegativeInt",
                "priceAdjustment": "String",
                "serviceId": "NonNegativeInt",
                "usesPerUser": "NonNegativeInt",
                "validFrom": "DateTime",
                "validTo": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ffae5bd758af80e732d52d35bbd4b6c229fd79e5e9fd236844156a39754d301c";