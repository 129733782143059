import template from './detail.ractive.html'
import { differenceInDays } from 'date-fns'
import toTitleCase from 'to-title-case'
import formatCityStateZip from 'utility/format/format-city-state-zip'
import formatDateString from 'utility/format/format-date-string'
import makeButton from '@isoftdata/button'
import makeGenericBadge from 'components/generic-badge'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.customer.recurring-order.detail',
		route: 'detail',
		template: {
			template,
			components: {
				itButton: makeButton(),
				genericBadge: makeGenericBadge(),
			},
			computed: {
				computedServiceSchedule() {
					const selectedResidenceSchedules = this.get('selectedResidenceSchedules')
					const completedScheduleJobs = this.get('completedScheduleJobs')

					return selectedResidenceSchedules.reduce((services, serviceSchedule) => {
						const completedJobsForService = completedScheduleJobs.filter(completedScheduleJob => completedScheduleJob.serviceScheduleId === serviceSchedule.id)
						const completedJobsForServiceCount = completedJobsForService.length

						const period = serviceSchedule.serviceSchedulePeriod.serviceSchedulePeriod
						const numOfDaysFromFirstToLast = differenceInDays(new Date(serviceSchedule.endOfSchedule), new Date(serviceSchedule.created))
						const totalNumOfJobs = Math.floor(numOfDaysFromFirstToLast / period)
						const validJobsToCheck = serviceSchedule.jobs.filter(job => job.jobStatus !== 'EXPIRED')
						const numOfCancelledJobs = validJobsToCheck.filter(job => job.jobStatus === 'CANCELLED').length
						const mostRecentJobCreatedBySchedule = getClosestJob(validJobsToCheck)

						let status
						if (serviceSchedule.status === 'DISABLED') {
							status = 'Cancelled'
						} else {
							status = toTitleCase(serviceSchedule.status)
						}
						services.push({
							...serviceSchedule,
							serviceId: serviceSchedule.service.id,
							serviceName: serviceSchedule.service.name,
							cityStateZipFormatted: formatCityStateZip({
								city: serviceSchedule.residence.city,
								state: serviceSchedule.residence.state,
								zip: serviceSchedule.residence.zip,
							}),
							currentServiceDate: formatDateString(mostRecentJobCreatedBySchedule.requestedSchedule),
							nextJobDate: formatDateString(serviceSchedule.nextJob),
							endOfSchedule: formatDateString(serviceSchedule.endOfSchedule),
							serviceSchedulePeriod: serviceSchedule.serviceSchedulePeriod.serviceSchedulePeriod,
							status,
							jobsCompleted: completedJobsForServiceCount,
							totalNumOfJobs,
							mostRecentJobStatus: mostRecentJobCreatedBySchedule.jobStatus,
							numOfCancelledJobs,
						})

						return services
					}, [])
				},
			},
			openAndCloseRecurringServiceDetails(serviceId) {
				const currentShowServiceDetailsState = this.get('showServiceDetails')
				const selectedServiceId = this.get('selectedServiceId')
				if (currentShowServiceDetailsState === true && selectedServiceId === serviceId) {
					this.set({ showServiceDetails: false, selectedServiceId: null })
				} else {
					this.set({ showServiceDetails: true, selectedServiceId: serviceId })
				}
			},
			async cancelRecurringScheduleOrder(serviceScheduleId) {
				const ractive = this
				if (confirm('Are you sure you want to cancel this recurring subscription? This will not cancel any jobs that have already been created.')) {
					const cancelRecurringScheduleOrderMutation = `#graphql
						mutation DisableServiceSchedule($serviceScheduleId: PositiveInt!) {
							disableServiceSchedule(serviceScheduleId: $serviceScheduleId)
						}
					`

					try {
						await mediator.call('apiFetch', cancelRecurringScheduleOrderMutation, { serviceScheduleId })

						const selectedResidenceSchedules = ractive.get('selectedResidenceSchedules')
						const selectedServiceScheduleIndex = selectedResidenceSchedules.findIndex(serviceSchedule => serviceSchedule.id === serviceScheduleId)
						ractive.set(`selectedResidenceSchedules.${selectedServiceScheduleIndex}.status`, 'CANCELLED')
					} catch (error) {
						console.log(error?.message)
					}
				}
			},
		},
		async resolve(data, { residenceId }) {
			residenceId = parseInt(residenceId, 10)
			const selectedResidenceSchedulesQuery = `#graphql
				query GetCustomerServiceSchedules($filter: CustomerServiceScheduleFilter) {
					getCustomerServiceSchedules(filter: $filter) {
							id
							created
							endOfSchedule
							nextJob
							residence {
								city
								country
								state
								street
								zip
							}
							service {
								id
								name
							}
							serviceSchedulePeriod {
								serviceSchedulePeriod
							}
							status
							answers {
								id
								answer
							}
							jobs {
								id
								requestedSchedule
								jobStatus
								created
							}
					}
				}
			`

			const getCustomerResidenceCompletedScheduleJobsQuery = `#graphql
				query GetCustomerResidenceCompletedScheduleJob($residenceId: PositiveInt!) {
					getCustomerResidenceCompletedScheduleJob(residenceId: $residenceId) {
						completed
						jobStatus
						serviceScheduleId
					}
				}
			`

			const { getCustomerServiceSchedules: selectedResidenceSchedules } = await mediator.call('apiFetch', selectedResidenceSchedulesQuery, {
				filter: {
					residenceId,
					status: 'ACTIVE',
				},
			})

			const { getCustomerResidenceCompletedScheduleJob: completedScheduleJobs } = await mediator.call('apiFetch', getCustomerResidenceCompletedScheduleJobsQuery, {
				residenceId,
			})

			return {
				selectedResidenceSchedules,
				showServiceDetails: selectedResidenceSchedules.length === 1,
				selectedServiceId: selectedResidenceSchedules.length === 1 ? selectedResidenceSchedules[0].service.id : null,
				completedScheduleJobs,
			}
		},
		activate({ domApi: _ractive }) {
		},
	})
}

function getClosestJob(jobList) {
	const reducedList = jobList.reduce((acc, job) => {
		const diff = Math.abs(differenceInDays(new Date(job.created), new Date()))
		if (diff < acc.diff) {
			acc.diff = diff
			acc.job = job
		}
		return acc
	}, { diff: Infinity, job: null })
	return reducedList.job
}
