import type { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
import component from './provider.svelte'
import type { ComponentProps } from 'svelte'

import { AppContext } from 'types/common'

import type { MediatorProviders } from 'services/api-fetch'
import { getSession } from 'stores/session'
import { writable } from 'svelte/store'
import { getProviderPassedScheduledJobClaimCount } from 'utility/graphql/get-provider-alert-counts'
// import { LoadProviderAvailableServicesHelper, LoadProviderUserRegionsHelper } from 'utility/available-jobs-helper'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.provider',
		route: 'provider',
		defaultChild: 'available-jobs',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve() {
			const session = getSession()

			if (!session?.provider || !session?.userAccountId) {
				return Promise.reject({
					redirectTo: {
						name: 'login',
					},
				})
			}
			const childStates: ComponentProps<AsrNavTabBar>['tabs'] = [
				{
					title: 'Available',
					name: 'app.provider.available-jobs',
					inherit: false,
				},
				{
					title: 'Claimed',
					name: 'app.provider.claimed-jobs',
					inherit: false,
				},
				{
					title: 'Completed',
					name: 'app.provider.completed-jobs',
					inherit: false,
				},
			]

			const availableJobsStore = writable<number>(0)
			const claimedJobsStore = writable<number>(0)

			// NOTE: Jake requested to remove this, but I'm going to just comment it out for now
			// let availableJobs = 0
			let providerPassedScheduleJobClaimCount = 0

			// const providerServiceIds = (await LoadProviderAvailableServicesHelper(session.provider.id)).map(service => service.service.id)
			// const providerRegionIds = (await LoadProviderUserRegionsHelper(session.userAccountId)).map(region => region.region.id)

			// try {
			// 	availableJobs = await getAvailableJobCount(providerServiceIds, providerRegionIds)
			// } catch (error) {
			// 	console.error('Error fetching available job count', error)
			// }
			try {
				providerPassedScheduleJobClaimCount = await getProviderPassedScheduledJobClaimCount({ providerId: session.provider.id, statuses: ['IN_PROGRESS', 'ACCEPTED'] })
			} catch (error) {
				console.error('Error fetching claimed job count', error)
			}
			// if (availableJobs > 0) {
			// 	availableJobsStore.set(availableJobs)
			// 	childStates[0] = {
			// 		...childStates[0],
			// 		icon: {
			// 			class: 'text-danger',
			// 			icon: 'bell-exclamation',
			// 		},
			// 	}
			// }

			if (providerPassedScheduleJobClaimCount > 0) {
				claimedJobsStore.set(providerPassedScheduleJobClaimCount)
				childStates[1] = {
					...childStates[1],
					icon: {
						class: 'text-danger',
						icon: 'bell-exclamation',
					},
				}
			}

			return Promise.resolve({
				childStates,
				providerId: session.provider.id,
				availableJobsStore,
				claimedJobsStore,
				// providerServiceIds,
				// providerRegionIds,
			})
		},
	})
}

export type ProviderMediatorProviders = MediatorProviders & {
	jobWasClaimed: () => void
}
