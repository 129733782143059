import component from './Management.svelte'

import { AppContext } from 'types/common'
import { formatProviderData, providerManagementQuery } from 'utility/provider-management-helper'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.provider.management',
		route: 'management',
		querystringParameters: [],
		defaultParameters: {},
		data: {},
		template: {
			svelte: true,
			component,
			options: {},
		},

		async resolve(_data, _parameters) {
			const pageNumber = 1
			const pageSize = 12

			const { data } = await providerManagementQuery.fetch({
				variables: {
					ProviderPagination: {
						pageNumber,
						pageSize,
					},
					orderBy: ['NAME_ASC'],
				},
			})

			const regions = data?.regions.data ?? []
			const services = data?.services.data ?? []
			const zones = data?.zones.data ?? []
			const initialProviders = data?.providers.data ?? []
			const providerPaginationInfo = data?.providers.info ?? { totalItems: 0, totalPages: 1 }

			const providerCache = {
				pages: [
					{
						providers: formatProviderData(initialProviders),
						retrivalTime: new Date(),
					},
				],
			}

			return {
				regions,
				services,
				zones,
				providerCache,
				providerPaginationInfo,
				pageSize,
			}
		},
	})
}
