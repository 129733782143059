export default {
    "name": "UpcomingOrdersCheckQuery",
    "kind": "HoudiniQuery",
    "hash": "d4ccdfbc94f5da1ad66dad309702deff7cd4cb5edeacdfae52dcd6ad78225036",

    "raw": `query UpcomingOrdersCheckQuery {
  upcomingOrders {
    data {
      id
      customerId
      residenceId
      jobStatus
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "upcomingOrders": {
                "type": "JobResponse",
                "keyRaw": "upcomingOrders",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Job",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "customerId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "customerId",
                                        "visible": true
                                    },

                                    "residenceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "residenceId",
                                        "visible": true
                                    },

                                    "jobStatus": {
                                        "type": "JobStatus",
                                        "keyRaw": "jobStatus",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=de530adea3af0fb8298f2dd29bcdfed10aa12a21dcefdb7b1035e1323b34a2bb";