<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import GooglePlacesAutocomplete from './GooglePlacesAutocomplete.svelte'
	import GooglePlacesMap from './GooglePlacesMap.svelte'
	import type { ParsedGoogleMapsAddressWithLatLng, PlaceResultWithSelected } from 'utility/google-maps-helper'
	import { formatCustomerResidences, type CustomerResidence } from 'utility/graphql/load-customer-residences'
	import { deactivateAddress, loadCustomerUpcomingOrdersCheck } from 'utility/customer-addresses-helper'
	import { getSession } from 'stores/session'

	import { createEventDispatcher } from 'svelte'

	import parseGoogleMapsPlaceAddress from 'utility/map-google-place'
	import { addNewCustomerResidence } from 'utility/graphql/add-new-address'

	const dispatch = createEventDispatcher()

	export let cardClass: string = ''
	export let headerClass: string = 'h4'
	export let title: string = 'Addresses'
	export let places: PlaceResultWithSelected[] = []
	export let bounds: google.maps.LatLngBounds | null = null
	export let customerResidences: CustomerResidence[] = []
	export let selectableAddresses: boolean = false
	export let addResidence: boolean = false
	export let hideAddButton: boolean = false

	let pacInputField: HTMLInputElement | undefined

	let pacInputValue: string = ''
	let isSavingNewResidence: boolean = false
	let selectedResidenceId: number | null = null

	$: computedResidences = formatCustomerResidences(customerResidences)

	function addNewResidence() {
		addResidence = true
		setTimeout(() => {
			// Focus on the input field after it's rendered
			pacInputField?.focus()
		}, 100)
	}

	async function addAddress(places: PlaceResultWithSelected[]) {
		const session = getSession()

		if (!session.customer?.id) {
			throw new Error('You must be logged in to add an address.')
		}

		const customerId = session.customer.id

		const addressData: ParsedGoogleMapsAddressWithLatLng = {
			...parseGoogleMapsPlaceAddress(places[0].address_components || {}),
			latitude: places[0].geometry?.location?.lat?.() || null,
			longitude: places[0].geometry?.location?.lng?.() || null,
		}

		isSavingNewResidence = true

		try {
			if (!places[0].place_id) {
				throw new Error('The address is missing a place_id.')
			}
			const newResidence = await addNewCustomerResidence(customerId, addressData, places[0].place_id)
			addResidence = false
			customerResidences.splice(0, 0, newResidence)
			customerResidences = customerResidences
			dispatch('addressAdded', { residenceId: newResidence.id, residences: customerResidences })
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			alert(
				error?.message ||
					`An error occurred while trying to save the address.

					Please try again later or contact Lawn Hiro Support.`,
			)
		} finally {
			isSavingNewResidence = false
		}
	}

	function selectAddress(address: CustomerResidence) {
		if (selectableAddresses) {
			selectedResidenceId = address.id
			dispatch('selectAddress', address)
		}
	}

	async function removeAddress(address: CustomerResidence) {
		try {
			const pendingAndClaimedJobs = await loadCustomerUpcomingOrdersCheck()

			const [numOfPendingJobs, numOfClaimedJobs] = pendingAndClaimedJobs.reduce(
				([pending, claimed], job) => {
					if (job.residenceId !== address.id) {
						return [pending, claimed]
					}
					if (job.jobStatus === 'PENDING') {
						return [pending + 1, claimed]
					} else if (job.jobStatus === 'CLAIMED') {
						return [pending, claimed + 1]
					}
					return [pending, claimed]
				},
				[0, 0],
			)

			if (numOfClaimedJobs > 0) {
				return alert(`You have ${numOfClaimedJobs} in-progress orders with this address. You are only allowed to remove this address after the claimed order(s) are complete.`)
			}

			if (numOfPendingJobs) {
				if (confirm(`You have ${numOfPendingJobs} pending orders associated with this address. Are you sure you want to remove this address and any associated orders?`)) {
					return deactivateCustomerAddress(address)
				} else {
					//Don't ask them anymore questions, they cancelled.
					return
				}
			}

			if (confirm('Are you sure you want to remove this address?')) {
				return await deactivateCustomerAddress(address)
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			if (error.message) {
				alert(error.message)
			} else {
				alert('An error occurred while trying to remove the address.\n\nPlease try again later or contact Lawn Hiro Support.')
			}
		}
	}

	async function deactivateCustomerAddress(selectedAddress: CustomerResidence) {
		try {
			const deactivateAddressId = await deactivateAddress(selectedAddress.id)
			const addressIndex = customerResidences.findIndex(address => address.id === deactivateAddressId)
			if (addressIndex > -1) {
				customerResidences.splice(addressIndex, 1)
				customerResidences = customerResidences
				dispatch('addressRemoved', { residenceId: deactivateAddressId, residences: customerResidences })
			}
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			alert(
				error?.message ||
					`An error occurred while trying to remove the address.

					Please try again later or contact Lawn Hiro Support.`,
			)
		}
	}
</script>

<div class="card {cardClass}">
	<div class="{headerClass} card-header d-flex justify-content-between">
		<span>{title}</span>
		{#if !hideAddButton}
			<Button
				outline
				color="success"
				size="sm"
				icon={{
					icon: 'plus',
				}}
				on:click={addNewResidence}>Add</Button
			>
		{/if}
	</div>
	{#if addResidence}
		<div class="card-body">
			<div class="col">
				<div class="row-12">
					<div
						style="height: 100%;"
						class="d-flex flex-column justify-content-between"
					>
						<div>
							<GooglePlacesAutocomplete
								bind:places
								bind:bounds
								inputLabel="Enter your address"
								bind:pacInput={pacInputField}
								{pacInputValue}
							/>
						</div>
					</div>
				</div>
				<div class="row-12 row-md-6 row-lg-8 row-xl-9">
					<GooglePlacesMap
						{places}
						{bounds}
						mapStyle="height: 30vh; min-height: 200px;"
					/>
				</div>
				<div class="form-row mt-2">
					<div class="col-6">
						<Button
							block
							color="danger"
							size="sm"
							on:click={() => (addResidence = false)}>Cancel</Button
						>
					</div>
					<div class="col-6">
						<Button
							block
							color="success"
							size="sm"
							icon={{
								icon: 'plus',
							}}
							disabled={isSavingNewResidence}
							on:click={() => addAddress(places)}
						>
							{#if isSavingNewResidence}
								Saving Address
							{:else}
								Add Address
							{/if}
						</Button>
					</div>
				</div>
			</div>
		</div>
	{/if}
	{#if computedResidences.length}
		<ul class="list-group m-3">
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			{#each computedResidences as address}
				<!-- svelte-ignore missing-declaration -->
				<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
				<li
					class="list-group-item"
					class:active={selectedResidenceId === address.id}
					class:cursor-pointer={selectableAddresses && address.region.status === 'ACTIVE'}
					on:click={() => {
						if (address.region.status === 'ACTIVE') {
							selectAddress(address)
						}
					}}
				>
					<div class="d-flex justify-content-between align-items-center">
						<div style="width: 50%;">
							{address.street}
							<br />
							{address.cityStateZipFormatted}
						</div>
						<div style="width: 10%; text-align: right;">
							<Button
								outline={selectedResidenceId === address.id}
								size="sm"
								color="danger"
								icon={{
									icon: 'trash',
								}}
								on:click={() => removeAddress(address)}
							/>
						</div>
					</div>
					{#if address.region.status === 'INACTIVE'}
						<div class="alert alert-danger mt-2 mb-1 py-1 text-smaller text-center">
							<i class="fa-solid fa-triangle-exclamation"></i> Currently there are no services available at this location.
						</div>
					{/if}
				</li>
			{/each}
		</ul>
	{:else}
		<h4 class="p-4">You don't yet have a saved address.</h4>
	{/if}
</div>
