export default {
    "name": "ResidenceData",
    "kind": "HoudiniFragment",
    "hash": "4023c20fd0f41f695e8fc46673c7c4c86942709dc09429494df0bb351d16b04d",

    "raw": `fragment ResidenceData on Residence {
  id
  street
  city
  state
  zip
  regionId
  estimatedLawnSquareFootage
  effectiveLawnSquareFootage
  customer {
    userAccount {
      fullName
      email
      id
    }
    id
  }
  answers {
    id
    answer
  }
  __typename
}
`,

    "rootType": "Residence",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "street": {
                "type": "String",
                "keyRaw": "street",
                "visible": true
            },

            "city": {
                "type": "String",
                "keyRaw": "city",
                "visible": true
            },

            "state": {
                "type": "String",
                "keyRaw": "state",
                "visible": true
            },

            "zip": {
                "type": "String",
                "keyRaw": "zip",
                "visible": true
            },

            "regionId": {
                "type": "PositiveInt",
                "keyRaw": "regionId",
                "visible": true
            },

            "estimatedLawnSquareFootage": {
                "type": "NonNegativeInt",
                "keyRaw": "estimatedLawnSquareFootage",
                "visible": true
            },

            "effectiveLawnSquareFootage": {
                "type": "NonNegativeInt",
                "keyRaw": "effectiveLawnSquareFootage",
                "nullable": true,
                "visible": true
            },

            "customer": {
                "type": "Customer",
                "keyRaw": "customer",

                "selection": {
                    "fields": {
                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "fullName": {
                                        "type": "String",
                                        "keyRaw": "fullName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "EmailAddress",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "answers": {
                "type": "Answer",
                "keyRaw": "answers",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "answer": {
                            "type": "String",
                            "keyRaw": "answer",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=6733dc90482d3e3c169075f57d2fc6e83e23548381b084aabf7f274d7655ff95";