<script lang="ts">
	import type { SvelteAsr } from 'types/common'

	import toTitleCase from 'to-title-case'

	export let asr: SvelteAsr

	// This computes the H3 title based on the current deepest active state name (If the active state is 'admin.users.list', the title will be 'Admin > Users > List')
	// If we ever add a "Detail" state to some lower state it will automatically update the title to 'Admin > Users > Detail' and look silly so instead we should just use
	// A svelte component in place of "Detail" level states so that the title doesn't brick
	$: activeChildState = asr
		.getActiveState()
		.name.split('.')
		.reduce((acc, stateName, index) => {
			if (index > 1) {
				acc += ` > ${toTitleCase(stateName)}`
			}
			return acc
		}, 'Admin')
</script>

<div class="card mb-3">
	<div class="card-header">
		<div>
			<h3>
				<i class="fas fa-user-helmet-safety"></i>
				{activeChildState}
			</h3>
		</div>
	</div>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>
