export default {
    "name": "DeactivateAddressMutation",
    "kind": "HoudiniMutation",
    "hash": "897260d2ebf3da9b86997486e867f39e5e100cfe3511f4b55b8f770c74d3cc62",

    "raw": `mutation DeactivateAddressMutation($deactivateResidenceId: PositiveInt!) {
  deactivateResidence(id: $deactivateResidenceId) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deactivateResidence": {
                "type": "Residence",
                "keyRaw": "deactivateResidence(id: $deactivateResidenceId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "deactivateResidenceId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6bc9d5b738d2aecfd36dca7855fde34c98327f6107fdccbfac102b2560bb55aa";