export interface GoogleMapsPin {
	id: string | number
	marker: google.maps.marker.AdvancedMarkerElement
	animation: google.maps.Animation | null
	// infoWindow: google.maps.InfoWindow
}

export interface PlaceResultWithSelected extends google.maps.places.PlaceResult {
	id?: number
	selected: boolean
}

export function getLabelForMarker(index: number): string {
	const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	return alphabet[index % alphabet.length]
}

export interface ParsedGoogleMapsAddressWithLatLng {
	street: string
	city: string
	state: string
	zip: string
	country: string
	county: string
	latitude: number | null
	longitude: number | null
}
