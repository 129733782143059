import template from './customer-order-detail.html'
import getComputedJob from 'utility/get-computed-job'
import formatCurrency from 'utility/format/format-currency'
import jobStatusMap from 'utility/job-status-map'
import { klona } from 'klona'

//Ractive components
import makeButton from '@isoftdata/button'
import makeJobClaimInfo from './job-claim-info'
import makeModal from '@isoftdata/modal'
import makeTextArea from '@isoftdata/textarea'

export default function createCustomerOrderDetailComponent({ mediator, defaultCancelJobModalState = {} }) {
	return Ractive.extend({
		template,
		isolated: true,
		attributes: {
			required: [ 'job' ],
			optional: [ 'showFooter' ],
		},
		data() {
			return {
				job: {},
				jobStatusMap,
				formatCurrency,
				showFooter: false,
				cancelJobModal: klona(defaultCancelJobModalState),
			}
		},
		components: {
			itButton: makeButton(),
			jobClaimInfo: makeJobClaimInfo(mediator),
			itModal: makeModal(),
			itTextArea: makeTextArea({ twoway: true, lazy: 300 }),
		},
		computed: {
			computedJob() {
				const job = getComputedJob(this.get('job'))
				return {
					...job,
					requestedSchedule: new Date(job.requestedSchedule).toDateString(),
				}
			},
		},
		openCancelJobModal(jobId) {
			this.set('cancelJobModal', {
				...klona(defaultCancelJobModalState),
				show: true,
				jobId,
			})
		},
	})
}
