export default {
    "name": "CouponData",
    "kind": "HoudiniFragment",
    "hash": "6280f4c790a89c84ba883db5f2393fdbfa429f4f314623fb409885c554eec436",

    "raw": `fragment CouponData on Coupon {
  id
  code
  description
  validFrom
  validTo
  numberOfTimesUsed
  maximumTimesUsed
  usesPerUser
  serviceId
  priceAdjustment
  isPercentage
  active
  firstJobOnly
  isValid
  __typename
}
`,

    "rootType": "Coupon",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "code": {
                "type": "NonEmptyString",
                "keyRaw": "code",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "visible": true
            },

            "validFrom": {
                "type": "DateTime",
                "keyRaw": "validFrom",
                "visible": true
            },

            "validTo": {
                "type": "DateTime",
                "keyRaw": "validTo",
                "nullable": true,
                "visible": true
            },

            "numberOfTimesUsed": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfTimesUsed",
                "visible": true
            },

            "maximumTimesUsed": {
                "type": "NonNegativeInt",
                "keyRaw": "maximumTimesUsed",
                "nullable": true,
                "visible": true
            },

            "usesPerUser": {
                "type": "NonNegativeInt",
                "keyRaw": "usesPerUser",
                "nullable": true,
                "visible": true
            },

            "serviceId": {
                "type": "NonNegativeInt",
                "keyRaw": "serviceId",
                "nullable": true,
                "visible": true
            },

            "priceAdjustment": {
                "type": "String",
                "keyRaw": "priceAdjustment",
                "visible": true
            },

            "isPercentage": {
                "type": "Boolean",
                "keyRaw": "isPercentage",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "firstJobOnly": {
                "type": "Boolean",
                "keyRaw": "firstJobOnly",
                "visible": true
            },

            "isValid": {
                "type": "Boolean",
                "keyRaw": "isValid",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=2ca2ff9bacafb21097a5045e6ea7af3582026b24bcc29392c6caee9bd32f0809";