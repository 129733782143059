import component from './FailedPaymentJob.svelte'

import getFailedPaymentJobs from 'utility/admin-job-alerts'

import { AppContext } from 'types/common'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.admin.manage-job.failed-payment-job',
		route: 'failed-payment-job',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const failedPaymentJobs = await getFailedPaymentJobs(false)
			return {
				jobs: failedPaymentJobs,
			}
		},
	})
}
