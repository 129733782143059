import { graphql, AdminJobsReleaseJobFromClaimsStore, AdminJobsCancelJobStore, AdminJobsStore, type AdminJobs$input, type AdminJobs$result } from '$houdini'
import { formatDuration } from 'utility/format/format-duration'
import { getComputedJob } from './get-computed-job'
import formatImageFileUrl from './format/format-image-file-url'
import type { BaseAttachmentFile } from '@isoftdata/svelte-attachments'

const absoluteDateTimeFormatter = (date: Date) => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)
const currencyFormatter = (amount: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(`${amount}`)

graphql`
	fragment JobData on Job {
		id
		jobStatus
		created
		requestedSchedule
		totalElapsedTime
		customerId
		completed
		serviceScheduleId
		customerFirstJob
		customer {
			id
			userAccount {
				fullName
				email
				mobile
				status
			}
		}
		residenceId
		residence {
			id
			region {
				id
				name
			}
			longitude
			latitude
			zip
			street
			state
			estimatedLawnSquareFootage
			effectiveLawnSquareFootage
			city
			country
			googleMapsPlaceId
		}
		jobClaims {
			provider {
				userAccountId
				userAccount {
					fullName
				}
				profilePictureFile {
					path
				}
			}
			cancellationReason
			internalNotes
			providerId
			jobClaimStatus
			scheduledAt
			isLate
		}
		transaction {
			cost
			total
			tax
			subtotal
		}
		service {
			name
		}
		files {
			id
			fileId
			rank
			public
			imageFileType
			file {
				id
				name
				created
				updated
				hash
				path
				type
				mimeType
			}
		}
	}
`

export type AdminJob = AdminJobs$result['jobs']['data'][number]
export type ComputedAdminJob = ReturnType<typeof adminJobsComputedJob>

export const adminJobsListQuery: AdminJobsStore = graphql`
	query AdminJobs($filter: JobFilter, $pagination: PaginatedInput, $orderBy: [JobOrderBy!]) {
		jobs(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
			data {
				...JobData
			}
			info {
				totalPages
				totalItems
				pageSize
				pageNumber
			}
		}
	}
`

const adminJobsReleaseJobFromClaimsMutation: AdminJobsReleaseJobFromClaimsStore = graphql`
	mutation AdminJobsReleaseJobFromClaims($jobId: PositiveInt!) {
		releaseJobFromClaims(jobId: $jobId) {
			...JobData
		}
	}
`

const adminJobsCancelJobMutation: AdminJobsCancelJobStore = graphql`
	mutation AdminJobsCancelJob($cancelJobInput: CancelJobInput!) {
		cancelJob(cancelJobInput: $cancelJobInput) {
			...JobData
		}
	}
`

export async function loadAdminJobs(filter?: AdminJobs$input['filter'], pagination?: AdminJobs$input['pagination'], orderBy?: AdminJobs$input['orderBy']) {
	const { data } = await adminJobsListQuery.fetch({
		variables: {
			filter,
			pagination,
			orderBy,
		},
	})

	if (!data?.jobs.data) {
		return {
			data: [],
			info: {
				totalPages: 0,
				totalItems: 0,
				pageSize: 0,
				pageNumber: 0,
			},
		}
	}

	return data.jobs
}

export async function releaseJobFromClaims(jobId: number) {
	const { data } = await adminJobsReleaseJobFromClaimsMutation.mutate({
		jobId,
	})

	if (!data?.releaseJobFromClaims) {
		throw new Error('Failed to release job from claims')
	}

	return data.releaseJobFromClaims
}

export async function cancelJob(jobId: number, reason: string) {
	const { data } = await adminJobsCancelJobMutation.mutate({
		cancelJobInput: {
			jobId,
			reason,
		},
	})

	if (!data?.cancelJob) {
		throw new Error('Failed to cancel job')
	}

	return data.cancelJob
}

export function adminJobsComputedJob(job: AdminJob) {
	const computedJob = getComputedJob(job)
	const activeJobClaim = job.jobClaims?.find(({ jobClaimStatus }) => jobClaimStatus === 'ACCEPTED' || jobClaimStatus === 'IN_PROGRESS')
	const scheduledAt = activeJobClaim?.scheduledAt ?? null
	const jobClaims =
		job.jobClaims?.map(jobClaim => {
			return {
				...jobClaim,
				provider: {
					...jobClaim.provider,
					profilePictureUrl: jobClaim.provider.profilePictureFile?.path ? formatImageFileUrl(jobClaim.provider.profilePictureFile.path) : null,
				},
			}
		}) ?? []
	const jobFiles: BaseAttachmentFile[] =
		job.files?.map(metadata => {
			return {
				...metadata,
				id: metadata.id,
				fileId: metadata.file?.id,
				rank: metadata.rank,
				public: metadata.public,
				imageFileType: metadata.imageFileType,
				file: metadata.file,
				mimeType: metadata.file?.mimeType ?? '',
				name: metadata.file?.name ?? '',
				path: formatImageFileUrl(metadata.file?.path ?? ''),
				size: 0,
			}
		}) ?? []

	return {
		...computedJob,
		jobClaims,
		files: jobFiles,
		created: absoluteDateTimeFormatter(computedJob.created),
		requestedScheduleFormatted: new Intl.DateTimeFormat('en-US').format(computedJob.requestedSchedule),
		completedFormatted: computedJob.completed ? absoluteDateTimeFormatter(computedJob.completed) : '',
		activeJobClaim: activeJobClaim
			? {
					...activeJobClaim,
					scheduledAt,
					scheduledAtFormatted: scheduledAt ? absoluteDateTimeFormatter(scheduledAt) : '',
					inProgress: activeJobClaim.jobClaimStatus === 'IN_PROGRESS',
			  }
			: null,
		scheduledAt,
		formattedElapsedTime: formatDuration(job.totalElapsedTime),
		customerName: job?.customer?.userAccount?.fullName ?? '',
		customerEmail: job?.customer?.userAccount?.email ?? '',
		customerStatus: job?.customer?.userAccount?.status ?? '',
		customerPhone: job?.customer?.userAccount?.mobile ?? '',
		formattedResidence: `${job?.residence?.street}\n${job?.residence?.city}`,
		squareFootage: job?.residence?.effectiveLawnSquareFootage ?? job?.residence?.estimatedLawnSquareFootage,
		formattedSquareFootage: new Intl.NumberFormat('en-US').format(job?.residence?.effectiveLawnSquareFootage ?? job?.residence?.estimatedLawnSquareFootage),
		cost: job?.transaction?.cost ? currencyFormatter(parseFloat(job.transaction.cost)) : '',
		subtotal: job?.transaction?.subtotal ? currencyFormatter(parseFloat(job.transaction.subtotal)) : '',
		tax: job?.transaction?.tax ? currencyFormatter(parseFloat(job.transaction.tax)) : '',
		total: job?.transaction?.total ? currencyFormatter(parseFloat(job.transaction.total)) : '',
		serviceName: job.service.name,
		isRecurringSchedule: !!job?.serviceScheduleId,
	}
}
