export default {
    "name": "AttachFileToProvider",
    "kind": "HoudiniMutation",
    "hash": "4f776e9c9f447eb8042bccfbf0dd471029a252d9186468b925b1059124b31326",

    "raw": `mutation AttachFileToProvider($input: NewProviderFileInput!) {
  attachFileToProvider(input: $input) {
    id
    profilePictureFile {
      path
      id
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "attachFileToProvider": {
                "type": "Provider",
                "keyRaw": "attachFileToProvider(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "profilePictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "profilePictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewProviderFileInput"
        },

        "types": {
            "ImageFile": {
                "base64String": "String",
                "fileName": "String"
            },

            "NewProviderFileInput": {
                "imageFile": "ImageFile",
                "imageFileType": "ProviderImageType",
                "providerId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ce1b61153d9b8fafd5740e000075521ee59c199e912e277dc1fa4e4b9fbbd1d0";