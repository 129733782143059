export default {
    "name": "ProviderManagementProvidersQuery",
    "kind": "HoudiniQuery",
    "hash": "7f5265b283ebc518075671036a2dbb8d2493780833cc65527e7aca267f402905",

    "raw": `query ProviderManagementProvidersQuery($pagination: PaginatedInput, $filter: ProviderFilter) {
  providers(pagination: $pagination, filter: $filter) {
    data {
      id
      homeRegion {
        id
        name
        status
      }
      city
      country
      county
      state
      street
      zip
      profilePictureFile {
        id
        path
      }
      status
      latestOnboardingApplication {
        id
        status
      }
      userAccount {
        id
        firstName
        lastName
        fullName
        email
        created
        mobile
        status
        authorizedRegions {
          id
          name
          status
        }
        authorizedZones {
          id
          name
          regions {
            id
            name
            status
          }
        }
      }
      authorizedServices {
        id
        service {
          id
          name
          codeName
          description
          active
        }
      }
    }
    info {
      totalItems
      totalPages
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "providers": {
                "type": "ProviderResponse",
                "keyRaw": "providers(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Provider",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "homeRegion": {
                                        "type": "Region",
                                        "keyRaw": "homeRegion",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "RegionStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "county": {
                                        "type": "String",
                                        "keyRaw": "county",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "latestOnboardingApplication": {
                                        "type": "ProviderApplication",
                                        "keyRaw": "latestOnboardingApplication",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderApplicationStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "firstName": {
                                                    "type": "String",
                                                    "keyRaw": "firstName",
                                                    "visible": true
                                                },

                                                "lastName": {
                                                    "type": "String",
                                                    "keyRaw": "lastName",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "mobile": {
                                                    "type": "PhoneNumber",
                                                    "keyRaw": "mobile",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "UserAccountStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "authorizedRegions": {
                                                    "type": "Region",
                                                    "keyRaw": "authorizedRegions",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "status": {
                                                                "type": "RegionStatus",
                                                                "keyRaw": "status",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "authorizedZones": {
                                                    "type": "Zone",
                                                    "keyRaw": "authorizedZones",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "regions": {
                                                                "type": "Region",
                                                                "keyRaw": "regions",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "status": {
                                                                            "type": "RegionStatus",
                                                                            "keyRaw": "status",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "authorizedServices": {
                                        "type": "ProviderService",
                                        "keyRaw": "authorizedServices",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "service": {
                                                    "type": "Service",
                                                    "keyRaw": "service",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "codeName": {
                                                                "type": "String",
                                                                "keyRaw": "codeName",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "active": {
                                                                "type": "Boolean",
                                                                "keyRaw": "active",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "ProviderFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ProviderFilter": {
                "homeRegionIds": "PositiveInt",
                "regionId": "PositiveInt",
                "search": "String",
                "serviceIds": "PositiveInt",
                "status": "ProviderStatus",
                "zoneIds": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9888721830edd5706a5d8b41bb486290c7d68b06957b174ab581c617c47892f3";