export default {
    "name": "ToggleTransactionStatus",
    "kind": "HoudiniMutation",
    "hash": "2b7160ace47d828b6bd0e2e1edf1736dbfc6479ae2e0f21d48f85b6966b4dc30",

    "raw": `mutation ToggleTransactionStatus($transactionId: PositiveInt!) {
  toggleTransactionStatus(transactionId: $transactionId) {
    id
    total
    paymentStatus
    invoiceStatus
    job {
      id
      service {
        name
        id
      }
    }
    customer {
      userAccount {
        fullName
        email
        id
      }
      id
    }
    transactionPayments {
      created
      gatewayMessage
      transactionType
      transactionPaymentClassification
      id
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "toggleTransactionStatus": {
                "type": "Transaction",
                "keyRaw": "toggleTransactionStatus(transactionId: $transactionId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "total": {
                            "type": "String",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "paymentStatus": {
                            "type": "PaymentStatus",
                            "keyRaw": "paymentStatus",
                            "visible": true
                        },

                        "invoiceStatus": {
                            "type": "InvoiceStatus",
                            "keyRaw": "invoiceStatus",
                            "visible": true
                        },

                        "job": {
                            "type": "Job",
                            "keyRaw": "job",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",

                            "selection": {
                                "fields": {
                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "transactionPayments": {
                            "type": "TransactionPayment",
                            "keyRaw": "transactionPayments",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "gatewayMessage": {
                                        "type": "String",
                                        "keyRaw": "gatewayMessage",
                                        "visible": true
                                    },

                                    "transactionType": {
                                        "type": "TransactionType",
                                        "keyRaw": "transactionType",
                                        "visible": true
                                    },

                                    "transactionPaymentClassification": {
                                        "type": "TransactionPaymentClassification",
                                        "keyRaw": "transactionPaymentClassification",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "transactionId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c982a4e525e32bfc931ebf3c26df1ee26c2787ce474b712c0b63da00221a8334";