export default {
    "name": "SetGlobalSetting",
    "kind": "HoudiniMutation",
    "hash": "8e791aa9b6ed173839d49feb7cb606e6bf4facfb175f791785cf15751ff1f5e7",

    "raw": `mutation SetGlobalSetting($value: SettingChange!) {
  setGlobalSetting(value: $value)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setGlobalSetting": {
                "type": "Void",
                "keyRaw": "setGlobalSetting(value: $value)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "value": "SettingChange"
        },

        "types": {
            "SettingChange": {
                "category": "String",
                "name": "String",
                "newValue": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a0fe08167b9fc6ed55a287dc56ca45a1db1576f33ec13383157b8973b2037e51";