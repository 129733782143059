<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import { type GetQuoteStore, type GetQuoteGoogleAddress, getQuotePrice } from 'utility/get-quote-helper'
	import type { Readable, Writable } from 'svelte/store'
	import GetQuoteCardHeader from './GetQuoteCardHeader.svelte'
	import CustomerQuoteQuestions from '../CustomerQuoteQuestions.svelte'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'

	export let quoteData: Writable<GetQuoteStore>
	export let derivedAllQuestionsAnswered: Readable<boolean>
	export let derivedSelectedAddress: Readable<GetQuoteGoogleAddress | undefined>
	export let stepOpen: boolean
	export let disabled: boolean

	let stepFinished = false

	async function confirmOptions(e: Event) {
		e.preventDefault()
		$quoteData.isLoadingPrice = true

		try {
			const findParcelResultData = await getQuotePrice($quoteData, $derivedSelectedAddress)
			if (!findParcelResultData) {
				throw new Error('No data returned')
			}
			$quoteData.quotedPrice = findParcelResultData.priceQuote.price
			$quoteData.inServiceArea = true
		} catch (error) {
			console.error(error)
			$quoteData.inServiceArea = false
			$quoteData.quotedPrice = ''
		}
		$quoteData.googleMapsPlaceId = $derivedSelectedAddress?.googleMapsPlaceId || ''
		$quoteData.isLoadingPrice = false
		stepFinished = true
	}

	$: header = buildHeader($quoteData.selectedAnswerIds)
	$: if (!$derivedAllQuestionsAnswered) {
		stepFinished = false
	}

	function buildHeader(answerIds: Set<number>) {
		if (stepOpen) {
			if (answerIds.size >= 0 && answerIds.size < $quoteData.questions.length) {
				return `${answerIds.size}/${$quoteData.questions.length} Questions Answered`
			} else if (answerIds.size === $quoteData.questions.length) {
				return 'All Questions Answered'
			}
		} else {
			// step is closed
			if (answerIds.size > 0 && $quoteData.selectedService) {
				return `${$quoteData.selectedService.name} Questions`
			} else {
				return 'Questions'
			}
		}
		return 'Questions'
	}
</script>

<CollapsibleCard
	entireHeaderToggles
	{disabled}
	cardHeaderClass="card-header d-flex justify-content-between h4"
	cardStyle="border-radius: 0px; border-bottom: none;"
	bind:bodyShown={stepOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<GetQuoteCardHeader
			{header}
			{stepFinished}
		/>
	</svelte:fragment>
	<div class="row">
		<div class="col-12">
			<div
				style="height: 100%;"
				class="d-flex flex-column justify-content-between"
			>
				<CustomerQuoteQuestions
					bind:questions={$quoteData.questions}
					bind:selectedAnswerIds={$quoteData.selectedAnswerIds}
				/>
				<form
					on:submit|preventDefault={confirmOptions}
					on:submit
				>
					<div class="mt-3">
						<Button
							id="seeMyPriceBtn"
							color="primary"
							iconClass="check"
							type="submit"
							disabled={!$derivedAllQuestionsAnswered}
						>
							See My Price
						</Button>
					</div>
				</form>
			</div>
		</div>
	</div>
</CollapsibleCard>
