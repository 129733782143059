import { formatCouponResponse, loadCouponServices, loadCoupons } from 'utility/coupon-helper'
import component from './Coupon.svelte'

import { AppContext } from 'types/common'
import type { CouponQuery$input } from '$houdini'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.coupon',
		route: 'coupon',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const filter: CouponQuery$input['filter'] = {
				valid: null,
			}

			// Using all settled so we can do it in one go because loadCoupons is used in the component itself
			const [couponServicesRes, couponsRes] = await Promise.allSettled([
				loadCouponServices(),
				loadCoupons({
					valid: filter.valid,
				}),
			])

			const couponServices = couponServicesRes.status === 'fulfilled' ? couponServicesRes.value : []
			const coupons = couponsRes.status === 'fulfilled' ? couponsRes.value : []

			const formattedCoupons = formatCouponResponse(coupons, couponServices)

			return Promise.resolve({
				couponServices,
				coupons: formattedCoupons,
				couponStatusFilter: filter.valid,
			})
		},
	})
}
