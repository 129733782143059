export default {
    "name": "AdminTags",
    "kind": "HoudiniQuery",
    "hash": "967bc458599e31c1340d09254d5b889540200d4003ade7bc0eee22c78b8971fc",

    "raw": `query AdminTags {
  tags {
    data {
      id
      code
      name
      description
      prompt
      entityType
      tagPricings {
        ...AdminTagPricingData
        id
      }
    }
  }
}

fragment AdminTagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  service {
    name
    id
  }
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "tags": {
                "type": "TagResponse",
                "keyRaw": "tags",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Tag",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricings": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricings",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "service": {
                                                    "type": "Service",
                                                    "keyRaw": "service",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "AdminTagPricingData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ebce62a2d836b7fb2bd5c438554a025576542ac41db6d6e199aa3ddc58a10eac";