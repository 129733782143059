<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Attachments, { type BaseAttachmentFile, type UnsavedAttachmentFile } from '@isoftdata/svelte-attachments'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import ProviderProfilePicture from './ProviderProfilePicture.svelte'
	import jobClaimStatusMap from 'utility/job-claim-status-map'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import { attachNewFilesToJob, detachFilesFromJob, getMaxImageDimensions } from 'utility/graphql/manage-image-files'
	import { ImageFileType, type ImageFileType$options, type JobClaimStatus$options } from '$houdini'
	import { upsert } from '@isoftdata/utility-array'

	const absoluteDateTimeFormatter = (date: Date) => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)

	type JobClaim = {
		jobClaimStatus: JobClaimStatus$options
		scheduledAt: Date | null
		provider?: {
			userAccountId: number
			profilePictureUrl: string | null
			userAccount: {
				fullName: string | null
			}
		}
		cancellationReason?: string | null
		internalNotes?: string | null
	}

	type Job = {
		id: number
		jobStatus: string
		scheduledAt: Date | null
		completed: Date | null
		completedFormatted: string | null
		activeJobClaim: { scheduledAtFormatted: string } | null
		files: Array<BaseAttachmentFile>
	}

	export let jobClaim: JobClaim
	export let job: Job
	export let adminView = false

	let saving = false

	let photoViewerModal: {
		show: boolean
		photoFilePaths: string[]
		selectedPhotoIndex: number
	} = {
		show: false,
		photoFilePaths: [],
		selectedPhotoIndex: 0,
	}

	let adminPhotoAttachmentModal: {
		show: boolean
		fileAssociation: {
			jobId: number | null
			imageFileType: ImageFileType$options
			userAccountId: number | null
		}
		maxImageDimensions: number
	} = {
		fileAssociation: {
			jobId: null,
			imageFileType: ImageFileType.OTHER,
			userAccountId: null,
		},
		maxImageDimensions: 1600,
		show: false,
	}

	async function openPhotoModal() {
		if (adminView && jobClaim.provider) {
			try {
				adminPhotoAttachmentModal.maxImageDimensions = await getMaxImageDimensions()
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
			}
			adminPhotoAttachmentModal.show = true
			adminPhotoAttachmentModal.fileAssociation = {
				jobId: job.id,
				imageFileType: ImageFileType.OTHER,
				userAccountId: jobClaim.provider?.userAccountId,
			}
		} else {
			photoViewerModal.photoFilePaths = job.files.map(metadata => formatImageFileUrl(metadata.path ?? ''))
			photoViewerModal.show = true
			photoViewerModal.selectedPhotoIndex = 0
		}
	}

	async function addFiles(e: CustomEvent<UnsavedAttachmentFile[]>) {
		try {
			saving = true
			const filesAdded = e.detail

			let uploadedFiles: BaseAttachmentFile[] = []

			for (const file of filesAdded) {
				const uploadedFile = await attachNewFilesToJob(file, adminPhotoAttachmentModal.fileAssociation)
				uploadedFiles.push({
					...file,
					...uploadedFile,
				})
			}
			uploadedFiles.forEach(uploadedFile => upsert(job.files, 'uuid', uploadedFile))
			job.files = job.files

			saving = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			saving = false
		}
	}

	async function deleteFiles(e: CustomEvent<BaseAttachmentFile[]>) {
		try {
			saving = true
			const filesDeleted = e.detail

			if (!job.id) {
				throw new Error('Job ID is required to delete files')
			}

			const fileIdsToDelete = filesDeleted.map(file => {
				if (!file.fileId) {
					throw new Error('File ID is required to delete file')
				} else {
					return file.fileId
				}
			})
			try {
				await detachFilesFromJob(job.id, fileIdsToDelete)
				fileIdsToDelete.forEach(fileId => {
					job.files = job.files.filter(file => file.fileId !== fileId)
				})
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
			}

			saving = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			saving = false
		}
	}
</script>

<div class="d-flex align-items-center">
	<ProviderProfilePicture profilePictureUrl={jobClaim.provider?.profilePictureUrl ?? null} />
	<div class="pl-1 pl-md-3">
		<ul
			style="list-style-type: none;"
			class="pl-0"
		>
			<li>
				<h5>{jobClaim.provider?.userAccount.fullName}</h5>
			</li>
			<li>
				<span class="badge badge-{jobClaimStatusMap[jobClaim.jobClaimStatus].class}">
					{#if jobClaimStatusMap[jobClaim.jobClaimStatus].iconClass}
						<i class={jobClaimStatusMap[jobClaim.jobClaimStatus].iconClass}></i>
					{/if}
					{jobClaimStatusMap[jobClaim.jobClaimStatus].label}
				</span>
			</li>
			<li>
				{#if jobClaim.jobClaimStatus === 'COMPLETED'}
					{job.completedFormatted}
				{:else if jobClaim.scheduledAt}
					{absoluteDateTimeFormatter(jobClaim.scheduledAt)}
				{:else}
					Not Scheduled
				{/if}
			</li>
			{#if jobClaim.cancellationReason && adminView}
				<li>
					<strong>Cancellation Reason:</strong>
					{jobClaim.cancellationReason}
				</li>
			{/if}
			{#if jobClaim.internalNotes && adminView}
				<li>
					<strong>Internal Notes:</strong>
					{jobClaim.internalNotes}
				</li>
			{/if}
		</ul>
		{#if job.files.length > 0 || adminView}
			<Button
				outline
				icon={{
					icon: 'image',
				}}
				size="sm"
				color="info"
				class="mt-2"
				on:click={() => {
					openPhotoModal()
				}}>View Photos</Button
			>
		{/if}
	</div>
</div>

<Modal
	bind:show={adminPhotoAttachmentModal.show}
	title="Upload Photos"
	confirmButtonText="Close Viewer"
	cancelShown={false}
	modalSize="lg"
	on:close={() => {
		adminPhotoAttachmentModal.show = false
		adminPhotoAttachmentModal.fileAssociation = {
			jobId: null,
			imageFileType: ImageFileType.OTHER,
			userAccountId: null,
		}
	}}
	on:backdrop={() => {
		adminPhotoAttachmentModal.show = false
		adminPhotoAttachmentModal.fileAssociation = {
			jobId: null,
			imageFileType: ImageFileType.OTHER,
			userAccountId: null,
		}
	}}
>
	<Attachments
		selectionButtonsDisabled
		hideRankFeatures
		hidePublicFeatures
		uniqueFileTypeSpecifer="image/*"
		bind:fileList={job.files}
		on:filesAdded={e => {
			addFiles(e)
		}}
		on:filesDeleted={e => {
			deleteFiles(e)
		}}
		maxImageSize={adminPhotoAttachmentModal.maxImageDimensions}
	/>
</Modal>

<ImageViewer
	showIndicators={false}
	bind:show={photoViewerModal.show}
	files={photoViewerModal.photoFilePaths}
	bind:currentPhotoIndex={photoViewerModal.selectedPhotoIndex}
	modalTitle="Job Photos"
	on:close={() => {
		photoViewerModal.show = false
		photoViewerModal.selectedPhotoIndex = 0
	}}
/>
