export default {
    "name": "ZoneData",
    "kind": "HoudiniFragment",
    "hash": "97eadd6ad40e211ff118e4e40619522ce3f43fcfdf27dfe9624ce3d702fe3e33",

    "raw": `fragment ZoneData on Zone {
  id
  name
  state
  description
  regions {
    id
    name
    description
    latitude
    longitude
    timeZone
    status
    zoneId
  }
  __typename
}
`,

    "rootType": "Zone",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "state": {
                "type": "String",
                "keyRaw": "state",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "regions": {
                "type": "Region",
                "keyRaw": "regions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "latitude": {
                            "type": "Latitude",
                            "keyRaw": "latitude",
                            "visible": true
                        },

                        "longitude": {
                            "type": "Longitude",
                            "keyRaw": "longitude",
                            "visible": true
                        },

                        "timeZone": {
                            "type": "TimeZone",
                            "keyRaw": "timeZone",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "RegionStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "zoneId": {
                            "type": "PositiveInt",
                            "keyRaw": "zoneId",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=ef9b897d5cec7b997fd3f89eb9f5b3be47f4986df846e2b6ee2335e3caa0a735";