export default {
    "name": "UpdateProviderOnboardingApplicationDriversLicenseStatus",
    "kind": "HoudiniMutation",
    "hash": "a9f390e0d4d5a13dbe1e7d1d07247f26bf3821d5151bf83ac0a54e53ec467589",

    "raw": `mutation UpdateProviderOnboardingApplicationDriversLicenseStatus($status: PictureFileStatus!, $providerOnboardingApplicationId: PositiveInt!, $rejectMessage: String) {
  updateProviderOnboardingApplicationDriversLicenseStatus(
    status: $status
    providerOnboardingApplicationId: $providerOnboardingApplicationId
    rejectMessage: $rejectMessage
  ) {
    id
    status
    driverLicenseStatus
    driverLicenseRejectMessage
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderOnboardingApplicationDriversLicenseStatus": {
                "type": "ProviderApplication",
                "keyRaw": "updateProviderOnboardingApplicationDriversLicenseStatus(providerOnboardingApplicationId: $providerOnboardingApplicationId, rejectMessage: $rejectMessage, status: $status)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "driverLicenseStatus": {
                            "type": "PictureFileStatus",
                            "keyRaw": "driverLicenseStatus",
                            "visible": true
                        },

                        "driverLicenseRejectMessage": {
                            "type": "String",
                            "keyRaw": "driverLicenseRejectMessage",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "status": "PictureFileStatus",
            "providerOnboardingApplicationId": "PositiveInt",
            "rejectMessage": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b52460942a0dc9302ac6d1b815a00aa7c95b024ebf125f4aefe0fe4cc6da2b2f";