import { graphql, LoadGlobalSettingStore } from '$houdini'
import pProps from 'p-props'

export const globalSettingQuery: LoadGlobalSettingStore = graphql`
	query LoadGlobalSetting($lookup: SettingLookup!) {
		getGlobalSetting(lookup: $lookup) {
			value
		}
	}
`

export async function loadFileCountSetting(name: string) {
	const defaultValue = '2'
	const { data } = await globalSettingQuery.fetch({
		variables: {
			lookup: {
				category: 'Admin settings',
				settingType: 'IMPORTANT_CONFIGURATION',
				defaultValue,
				name,
			},
		},
	})

	if (!data?.getGlobalSetting) {
		return parseInt(defaultValue, 10)
	}

	return parseInt(data.getGlobalSetting.value, 10)
}

export async function getFileLimitGlobalSetting() {
	return await pProps({
		beforeServiceMinFileCount: loadFileCountSetting('Provider before service minimum files count'),
		beforeServiceMaxFileCount: loadFileCountSetting('Provider before service maximum files count'),
		afterServiceMinFileCount: loadFileCountSetting('Provider after service minimum files count'),
		afterServiceMaxFileCount: loadFileCountSetting('Provider after service maximum files count'),
	})
}

export default getFileLimitGlobalSetting
