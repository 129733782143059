import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/CompletedOrders'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class CompletedOrdersStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "CompletedOrdersStore",
			variables: false,
		})
	}
}

export async function load_CompletedOrders(params) {
	await initClient()

	const store = new CompletedOrdersStore()

	await store.fetch(params)

	return {
		CompletedOrders: store,
	}
}
