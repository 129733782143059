export default {
    "name": "DetachFilesFromJob",
    "kind": "HoudiniMutation",
    "hash": "93149723fa32d7fa797180ed9c11d4836d4668b386adf32a08767fe97da109e5",

    "raw": `mutation DetachFilesFromJob($jobId: PositiveInt!, $fileIds: [PositiveInt!]!) {
  detachFilesFromJob(jobId: $jobId, fileIds: $fileIds)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "detachFilesFromJob": {
                "type": "Boolean",
                "keyRaw": "detachFilesFromJob(fileIds: $fileIds, jobId: $jobId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobId": "PositiveInt",
            "fileIds": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=291293b008184588a79b71876080790241ea5459ae3bb7b9fbaa75e5ae87dbe8";