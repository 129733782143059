import template from './upcoming-orders.ractive.html'
import { getUpcomingOrders } from 'utility/load-upcoming-orders'
import makeButton from '@isoftdata/button'
import makeCustomerOrderListItem from 'components/customer-order-list-item'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.customer.upcoming-orders',
		route: 'upcoming-orders',
		querystringParameters: [ 'jobId' ],
		template: {
			template,
			components: {
				itButton: makeButton(),
				customerOrderListItem: makeCustomerOrderListItem(),
			},
			computed: {
				customerHasJobsFromMultipleCities() {
					return new Set(this.get('jobs').map(job => `${job.residence.city}${job.residence.state}`)).size > 1
				},
			},
		},
		async resolve(_data, { jobId }) {
			const upcomingOrders = await getUpcomingOrders()

			const selectedJobId = parseInt(jobId, 10) || upcomingOrders?.[0]?.id || undefined

			// go to detail state if we are taking the first jobId off the list
			if (selectedJobId) {
				stateRouter.go('app.customer.upcoming-orders.detail', { jobId: selectedJobId }, { inherit: true, replace: true })
			}

			return {
				//past would probably be both expired and competed status
				jobs: upcomingOrders || [],
				selectedJobId,
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext
			/*
				Ensure the selected job is visible in the list
				focus seems to give us better behavior than scrollIntoView
				as far as moving the list only when the job in the list is not already visible
			*/
			ractive.find(`#job-id-${ ractive.get('selectedJobId') }`)?.focus()

			const removeUpdateJobStatusProvider = mediator.provide('updateJobStatus', async({ jobId, jobStatus }) => {
				const jobs = ractive.get('jobs')
				const jobIndex = jobs.findIndex(job => job.id === jobId)
				if (jobIndex > -1) {
					jobs[jobIndex].jobStatus = jobStatus
					await ractive.splice('jobs', jobIndex, 1, jobs[jobIndex])
				}
			})

			activateContext.on('destroy', () => {
				removeUpdateJobStatusProvider?.()
			})
		},
	})
}
