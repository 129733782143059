import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadTimeDateRegions'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadTimeDateRegionsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadTimeDateRegionsStore",
			variables: false,
		})
	}
}

export async function load_LoadTimeDateRegions(params) {
	await initClient()

	const store = new LoadTimeDateRegionsStore()

	await store.fetch(params)

	return {
		LoadTimeDateRegions: store,
	}
}
