import { graphql, AddNewAddressMutationStore, AddNewAddressWithPriceQuoteMutationStore, type AddNewAddressMutation$result } from '$houdini'
import type { ParsedGoogleMapsAddressWithLatLng } from '../google-maps-helper'

export type NewResidenceResult = (AddNewAddressMutation$result['newResidence'] & { parcel?: { priceQuote: { price: string | null } } }) | null

graphql`
	fragment NewResidenceData on Residence {
		id
		regionId
		region {
			status
		}
		customerId
		status
		street
		city
		state
		zip
		country
		county
		googleMapsPlaceId
		estimatedLawnSquareFootage
		effectiveLawnSquareFootage
		displayLawnSquareFootage
		latitude
		longitude
		answers {
			id
			question {
				services {
					id
				}
			}
		}
	}
`

const addNewAddressMutation: AddNewAddressMutationStore = graphql`
	mutation AddNewAddressMutation($newResidence: NewResidence!) {
		newResidence(newResidence: $newResidence) {
			...NewResidenceData
		}
	}
`

const addNewAddressWithPriceQuoteMutation: AddNewAddressWithPriceQuoteMutationStore = graphql`
	mutation AddNewAddressWithPriceQuoteMutation($serviceId: PositiveInt, $newResidence: NewResidence!) {
		newResidence(newResidence: $newResidence) {
			...NewResidenceData
			parcel {
				priceQuote(serviceId: $serviceId) {
					price
				}
			}
		}
	}
`

export async function addNewCustomerResidence(customerId: number, addressData: ParsedGoogleMapsAddressWithLatLng, placeId: string, serviceId?: number) {
	try {
		let result: NewResidenceResult = null
		if (serviceId) {
			const { data } = await addNewAddressWithPriceQuoteMutation.mutate({
				newResidence: {
					customerId,
					googleMapsPlaceId: placeId,
					...addressData,
				},
				serviceId,
			})

			if (!data?.newResidence) {
				throw new Error('Failed to add new address with price quote')
			}

			result = data.newResidence
		} else {
			const { data } = await addNewAddressMutation.mutate({
				newResidence: {
					customerId,
					googleMapsPlaceId: placeId,
					...addressData,
					zip: addressData.zip.toString(),
				},
			})

			if (!data?.newResidence) {
				throw new Error('Failed to add new address')
			}

			result = data.newResidence
		}

		return result
	} catch (err: unknown) {
		const error = err as Error
		console.error('Failed to add new address', error)
		throw error //Rethrow the error
	}
}
