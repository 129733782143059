import { AppContext, Redirect, ResolveParameters } from 'types/common'
import { getSession } from 'stores/session'
import jsonOrNull from 'utility/json-or-null'

type EmailLandingParameters = {
	action?: string
	parameters?: string
} // the parameters property is a JSON encoded object

const actionToStates: Record<string, string> = {
	TIP: 'app.customer.order-history', // Customer tip location ORDER HISTORY
	CLAIM: 'app.provider.available-jobs', // Provider claim location AVAILABLE JOBS
	COMPLETED_JOBS: 'app.provider.completed-jobs', // Provider completed jobs location COMPLETED JOBS
	AVAILABLE_JOBS: 'app.provider.available-jobs', // Provider available jobs location AVAILABLE JOBS
	CLAIMED_JOBS: 'app.provider.claimed-jobs', // Provider claimed jobs location CLAIMED JOBS
	EMAIL_VERIFICATION: 'email-verification', // Email verification location EMAIL VERIFICATION
}

const template = {
	template: '',
	data: { action: '', parameters: '' },
}

function resolve(data, parameters: ResolveParameters<EmailLandingParameters>) {
	const session = getSession()
	const destinationState = actionToStates[parameters.action]

	if (parameters.action === 'EMAIL_VERIFICATION') {
		return Promise.reject({
			redirectTo: {
				name: 'email-verification',
				params: jsonOrNull(parameters.parameters),
			},
		} satisfies Redirect)
	}

	// the getSession() will always return a session object, so to check if the user is logged in, we need to check if the authToken exists
	if (!session.authToken && destinationState) {
		return Promise.reject({
			redirectTo: {
				name: 'login',
				params: {
					redirectTo: destinationState,
					redirectToParams: parameters.parameters,
				},
			},
		})
	} else if (!session.authToken) {
		return Promise.reject({
			redirectTo: {
				name: 'login',
			},
		})
	} else if (destinationState) {
		return Promise.reject({
			redirectTo: {
				name: destinationState,
				params: jsonOrNull(parameters.parameters),
			},
		} satisfies Redirect)
	} else if (!destinationState) {
		return Promise.reject({
			redirectTo: {
				name: 'app',
			},
		})
	} else {
		return Promise.reject({
			redirectTo: {
				name: 'login',
			},
		})
	}
}

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'email-landing',
		route: 'email-landing',
		template,
		resolve,
	})
	// We're not just landing here from emails anymore, but keep `email-landing` for backwards compatibility
	stateRouter.addState({
		name: 'landing',
		route: 'landing',
		template,
		resolve,
	})
}
