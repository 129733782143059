export default {
    "name": "DeleteAnswer",
    "kind": "HoudiniMutation",
    "hash": "07eb70eed1a3cc462c9fefb7e5b2a61620647b742cc9b3682f813c7d9a1eae33",

    "raw": `mutation DeleteAnswer($deleteAnswerId: PositiveInt!) {
  deleteAnswer(id: $deleteAnswerId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteAnswer": {
                "type": "Boolean",
                "keyRaw": "deleteAnswer(id: $deleteAnswerId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "deleteAnswerId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5c8c3a1d3589a8717a6ebf88cf68e9bbf30c385a4000ae0ff7093f4ee86b75ba";