<script lang="ts">
	export let header: string
	export let stepFinished: boolean
</script>

<div>
	<i
		class="fa-solid fa-check"
		class:text-muted={!stepFinished}
		class:text-success={stepFinished}
	></i>
	<span>{header}</span>
</div>
