// eslint-disable-next-line no-redeclare
/*global google, Ractive*/
import template from './google-places-autocomplete.html'
import makeInput from '@isoftdata/input'

export default function createMapSearchComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			itInput: makeInput({ twoway: true, lazy: 300 }),
		},
		css: `
		.pac-card {
			background-color: #fff;
			border: 0;
			border-radius: 2px;
			box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
			margin: 10px;
			padding: 0 0.5em;
			font: 400 18px Roboto, Arial, sans-serif;
			overflow: hidden;
			font-family: Roboto;
			padding: 0;
		}

		#pac-container {
			padding-bottom: 12px;
			margin-right: 12px;
		}

		.pac-controls {
			display: inline-block;
			padding: 5px 11px;
		}

		.pac-controls label {
			font-family: Roboto;
			font-size: 13px;
			font-weight: 300;
		}

		#pac-input {
			text-overflow: ellipsis;
		}

		#target {
			width: 345px;
		}`,
		attributes: {
			required: [ 'places', 'bounds' ],
			optional: [],
		},
		data() {
			return {
				places: null, // set by this component
				bounds: null, // consumed by this component
			}
		},
		getInputElement() {
			return this.find('#pac-input')
		},
		onrender() { // need to do this on render, since it needs DOM elements to be rendered
			const ractive = this
			try {
				// Create the search box and link it to the UI element.
				const input = document.getElementById("pac-input")
				const autocomplete = new google.maps.places.Autocomplete(input, { types: [ 'address' ] })

				// update the bounds here when the map bounds change
				ractive.observe('bounds', bounds => {
					autocomplete.setBounds(bounds)
				})

				// Set the places here so we can use them in the places map component
				autocomplete.addListener('place_changed', () => {
					const places = [ autocomplete.getPlace() ] // autocomplete only returns one place, map expects array
					ractive.set({ places })
				})
			} catch (err) {
				console.error(err)
			}
		},
	})
}
