import type { SidebarItemType } from '@isoftdata/svelte-sidebar'

import component from './App.svelte'
import formatImageFileUrl from 'utility/format/format-image-file-url'
import { getFailedPaymentJobCount, getAllPassedScheduledTimeJobClaimCount } from 'utility/admin-job-alerts'
import { getSession } from 'stores/session'
import { writable } from 'svelte/store'

import { AppContext } from 'types/common'

export const adminJobAlertStore = writable<{ failedJobCount: number; passedScheduledTimeJobClaimCount: number }>({
	failedJobCount: 0,
	passedScheduledTimeJobClaimCount: 0,
})

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app',
		route: '',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters) {
			const session = getSession()
			const isAdmin = !!session?.userRoles?.find(role => role.permissionName === 'ADMINISTRATOR' && role.value === 'Global')

			if (!session) {
				return Promise.reject({
					redirectTo: {
						name: 'login',
					},
				})
			}

			const states: Array<SidebarItemType> = [
				{
					name: 'Orders',
					type: 'CUSTOMER_JOB_LIST',
					route: 'app.customer',
					category: 'Customer',
					icon: 'list-ul',
					hidden: !session?.customer,
				},
				{
					name: 'Jobs',
					type: 'PROVIDER_JOBS',
					route: 'app.provider',
					category: 'Provider',
					icon: {
						class: 'fa-lawnmower',
						prefix: 'fak',
					},
					hidden: !session?.provider,
				},
				{
					name: 'Onboarding',
					type: 'PROVIDER_ONBOARDING',
					route: 'app.onboarding',
					category: 'Provider',
					icon: 'user-plus',
					hidden: !session?.provider || !(session?.provider?.status === 'PENDING_APPROVAL' && session?.hasOnboardingApplication),
					// for v1: once the provider is approved, the onboarding page should be hidden
					// in the future, we may want to show the onboarding page for providers to update their information, this could live in settings
				},
			]

			if (isAdmin) {
				let adminJobIconPrefix: 'fas' | 'fak' = 'fas'
				let adminJobIconClass = 'fa-briefcase'
				let failedJobCount = 0
				let passedScheduledTimeJobClaimCount = 0

				try {
					failedJobCount = await getFailedPaymentJobCount()
					passedScheduledTimeJobClaimCount = await getAllPassedScheduledTimeJobClaimCount()
				} catch (error) {
					console.error('Failed to get failed job count', error)
				}

				if (failedJobCount || passedScheduledTimeJobClaimCount) {
					adminJobIconPrefix = 'fak'
					adminJobIconClass = 'fa-solid-briefcase-circle-exclamation fa-shake icon-slow-animation text-danger'
				}
				adminJobAlertStore.set({ failedJobCount, passedScheduledTimeJobClaimCount })
				states.push(
					{
						name: 'Analytics',
						type: 'ANALYTICS',
						route: 'app.admin.analytics',
						category: 'Admin',
						icon: 'chart-line',
					},
					{
						name: 'Coupons',
						type: 'COUPONS',
						route: 'app.admin.coupon',
						category: 'Admin',
						icon: 'ticket',
					},
					{
						name: 'Locations',
						type: 'LOCATIONS',
						route: 'app.admin.location',
						category: 'Admin',
						icon: 'map-location-dot',
					},
					{
						name: 'Jobs',
						type: 'ADMIN_JOB',
						route: 'app.admin.manage-job',
						category: 'Admin',
						icon: {
							prefix: adminJobIconPrefix,
							class: adminJobIconClass,
						},
						badge: {
							show: failedJobCount > 0 || passedScheduledTimeJobClaimCount > 0,
							text: (failedJobCount + passedScheduledTimeJobClaimCount).toString(),
							color: 'danger',
						},
					},
					{
						name: 'Payables',
						type: 'PAYABLES',
						route: 'app.admin.accounts-payable',
						category: 'Admin',
						icon: 'money-check-dollar',
					},
					{
						name: 'Providers',
						type: 'PROVIDERS',
						route: 'app.admin.provider',
						category: 'Admin',
						icon: 'users',
					},
					{
						name: 'Questions',
						type: 'QUESTIONS',
						route: 'app.admin.question',
						category: 'Admin',
						icon: 'question',
					},
					{
						name: 'Quotes',
						type: 'QUOTES',
						route: 'app.admin.quote',
						category: 'Admin',
						icon: 'quotes',
					},
					{
						name: 'Residences',
						type: 'RESIDENCES',
						route: 'app.admin.residence',
						category: 'Admin',
						icon: 'house',
					},
					{
						name: 'Services',
						type: 'SERVICES',
						route: 'app.admin.service',
						category: 'Admin',
						icon: {
							class: 'fa-lawnmower',
							prefix: 'fak',
						},
					},
					{
						name: 'Tags',
						type: 'TAGS',
						route: 'app.admin.tag',
						category: 'Admin',
						icon: 'tag',
					},
					{
						name: 'Users',
						type: 'USERS',
						route: 'app.admin.user-account',
						category: 'Admin',
						icon: 'person',
					}
				)
			}

			const avatarImgSrc = session?.provider?.profilePictureFile?.path ? formatImageFileUrl(session?.provider?.profilePictureFile?.path) : null

			return Promise.resolve({
				isAdmin,
				user: session,
				avatarImgSrc,
				states,
				parameters,
				adminJobAlertStore,
			})
		},
	})
}
