export function formatPhoneNumber(phoneNumber?: string | null): string {
	if (phoneNumber) {
		phoneNumber = phoneNumber.replace(/\D/g, '')
		const match = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

		if (match) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [full, intlCode, areaCode, first3, last4] = match

			// intlCode = ''//(intlCode ? '+1 ' : '')
			return `(${areaCode}) ${first3}-${last4}`
		}
		return phoneNumber
	}

	return ''
}

export default formatPhoneNumber
