export default {
    "name": "CompleteJobClaimEdit",
    "kind": "HoudiniMutation",
    "hash": "459dc9e95fb1cb9669e843856f6ce5d5404f754baa8f43e6fbfbf60b6dd78fa6",

    "raw": `mutation CompleteJobClaimEdit($completedClaim: CompleteJobClaim!) {
  completeJobClaim(completedClaim: $completedClaim) {
    id
    jobClaimStatus
    scheduledAt
    cancellationReason
    allActivity {
      id
      elapsedTime
      end
      start
    }
    internalNotes
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "completeJobClaim": {
                "type": "JobClaim",
                "keyRaw": "completeJobClaim(completedClaim: $completedClaim)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "jobClaimStatus": {
                            "type": "JobClaimStatus",
                            "keyRaw": "jobClaimStatus",
                            "visible": true
                        },

                        "scheduledAt": {
                            "type": "DateTime",
                            "keyRaw": "scheduledAt",
                            "visible": true
                        },

                        "cancellationReason": {
                            "type": "String",
                            "keyRaw": "cancellationReason",
                            "nullable": true,
                            "visible": true
                        },

                        "allActivity": {
                            "type": "JobClaimActivity",
                            "keyRaw": "allActivity",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "elapsedTime": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "elapsedTime",
                                        "visible": true
                                    },

                                    "end": {
                                        "type": "DateTime",
                                        "keyRaw": "end",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "start": {
                                        "type": "DateTime",
                                        "keyRaw": "start",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "internalNotes": {
                            "type": "String",
                            "keyRaw": "internalNotes",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "completedClaim": "CompleteJobClaim"
        },

        "types": {
            "CompleteJobClaim": {
                "id": "PositiveInt",
                "internalNotes": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a807be2609f990da7e7be8c8a3354cddb5aff900df7edbdbfbae789b9db5aaba";