import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AdminTags'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AdminTagsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AdminTagsStore",
			variables: false,
		})
	}
}

export async function load_AdminTags(params) {
	await initClient()

	const store = new AdminTagsStore()

	await store.fetch(params)

	return {
		AdminTags: store,
	}
}
