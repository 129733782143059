import {
	graphql,
	LoadProviderJobClaimsStore,
	type LoadProviderJobClaims$input,
	type LoadProviderJobClaims$result,
	LoadPassedScheduleTimeJobClaimsStore,
	LoadPassedScheduleTimeJobClaims$input,
} from '$houdini'

const providerClaimsQuery: LoadProviderJobClaimsStore = graphql`
	query LoadProviderJobClaims($filter: JobClaimFilter, $orderBy: [JobClaimOrderBy!]) {
		jobClaims(filter: $filter, orderBy: $orderBy) {
			data {
				id
				jobClaimStatus
				scheduledAt
				cancellationReason
				jobId
				isLate
				job {
					id
					requestedSchedule
					customerFirstJob
					completed
					created
					residence {
						street
						city
						state
						zip
						country
						googleMapsPlaceId
						displayLawnSquareFootage
						latitude
						longitude
						region {
							name
						}
						answers {
							tag {
								id
								name
								entityType
							}
						}
					}
					jobStatus
					specialInstructions
					transaction {
						cost
						tip
					}
					files {
						id
						rank
						public
						imageFileType
						file {
							id
							name
							path
							hash
							type
						}
					}
					answers {
						id
						answer
					}
					service {
						name
					}
					jobClaims {
						id
						scheduledAt
					}
				}
				allActivity {
					id
					elapsedTime
					end
					start
				}
			}
		}
	}
`

const passedScheduleTimeJobClaimsQuery: LoadPassedScheduleTimeJobClaimsStore = graphql`
	query LoadPassedScheduleTimeJobClaims($pagination: PaginatedInput, $filter: JobClaimFilter) {
		jobClaims(pagination: $pagination, filter: $filter) {
			data {
				isLate
			}
		}
	}
`

export type ProviderJobClaimStatus = LoadProviderJobClaims$result['jobClaims']['data'][number]['jobClaimStatus']

export async function getProviderJobClaims(
	providerId: number,
	statuses: ProviderJobClaimStatus[],
	scheduledAtFrom: Date | null,
	scheduledAtTo: Date | null,
	orderBy: LoadProviderJobClaims$input['orderBy'] = ['SCHEDULED_AT_ASC'],
) {
	const { data } = await providerClaimsQuery.fetch({
		variables: {
			filter: {
				providerId,
				statuses,
				scheduledAtFrom,
				scheduledAtTo,
			},
			orderBy,
		},
	})

	if (!data?.jobClaims) {
		throw new Error('Failed to load provider job claims')
	}

	return data.jobClaims.data
}

// I want to keep the above function as it has restriction on the filter type where it is required to have providerId
// This function is used to get the count of passed scheduled time job claims, for a provider or all job claims (for admin use case)
export async function getPassedScheduleTimeJobClaimCount(filter: LoadPassedScheduleTimeJobClaims$input['filter']) {
	const { data } = await passedScheduleTimeJobClaimsQuery.fetch({
		variables: {
			filter,
			pagination: {
				pageNumber: 1,
				pageSize: 0,
			},
		},
	})

	if (!data?.jobClaims) {
		throw new Error('Failed to load provider job claims')
	}

	return data.jobClaims.data.reduce((acc: number, jobClaim) => {
		if (jobClaim.isLate) {
			return acc + 1
		}
		return acc
	}, 0)
}

export default getProviderJobClaims
