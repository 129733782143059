<script lang="ts">
	import { createEventDispatcher } from 'svelte'
	import type { JobStatus$options } from '$houdini/graphql'

	import { formatCurrency } from 'utility/format/format-currency'

	import GenericBadge from 'components/GenericBadge.svelte'
	import ProviderJobListResidenceStreet from 'components/ProviderJobListResidenceStreet.svelte'

	const dispatch = createEventDispatcher()

	interface ProviderCompletedJobsListJobProp {
		id: number
		jobStatus: JobStatus$options
		transaction: {
			cost: string
			tip: string | null
		} | null
		service: {
			name: string
		}
		answers:
			| {
					id: number
			  }[]
			| null
		residence: {
			street: string
			region: {
				name: string
			}
		}
		customerFirstJob: boolean
		files:
			| {
					id: number
			  }[]
			| null
	}

	export let job: ProviderCompletedJobsListJobProp
	export let selectedJobId: number | null
</script>

<button
	id="job-{job.id}"
	type="button"
	class="list-group-item list-group-itemp-action p-2"
	style="width:100%;"
	on:click={() => dispatch('jobClicked', job.id)}
	class:active={selectedJobId === job.id}
>
	<div class="d-flex align-items-center">
		<div class="d-flex mr-3 flex-column align-items-start">
			<p
				class="mb-0"
				style="font-size: xx-small;"
			>
				Earnings
			</p>
			<h5 class="mb-1">{formatCurrency(job.transaction?.cost ?? 'Err')}</h5>
			{#if job.transaction?.tip}
				<p
					class="mb-0"
					style="font-size: xx-small;"
				>
					Tip
				</p>
				<h5 class="mb-0">{formatCurrency(job.transaction.tip)}</h5>
			{/if}
		</div>
		<div class="ellipsis">
			<div class="d-flex flex-wrap service-badge mt-2">
				<GenericBadge
					type="SERVICE"
					text={job.service.name}
				/>
			</div>
			<div class="d-flex flex-wrap mt-2 mb-1">
				<GenericBadge
					type="REGION"
					text={job.residence.region.name}
				/>
				{#if job.answers && job.answers?.length > 0}
					<span class="badge badge-danger mb-1">
						<i class="fa-solid fa-circle-exclamation"></i>
					</span>
				{/if}
				{#if job.files && job.files?.length > 0}
					<span class="badge badge-info mb-1">
						<i class="fa-solid fa-image"></i>
					</span>
				{/if}
			</div>
			<ProviderJobListResidenceStreet
				street={job.residence.street}
				customerFirstJob={job.customerFirstJob}
			/>
		</div>
	</div>
</button>
