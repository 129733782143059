import { graphql, LoadProviderClaimableJobsStore, type LoadProviderClaimableJobs$input } from '$houdini'

const query: LoadProviderClaimableJobsStore = graphql`
	query LoadProviderClaimableJobs($orderBy: [JobOrderBy!], $filter: ClaimableJobFilter, $pagination: PaginatedInput) {
		claimableJobs(orderBy: $orderBy, filter: $filter, pagination: $pagination) {
			data {
				id
				jobStatus
				requestedSchedule
				customerFirstJob
				specialInstructions
				residence {
					longitude
					latitude
					zip
					street
					state
					displayLawnSquareFootage
					country
					city
					googleMapsPlaceId
					region {
						id
						name
					}
					answers {
						id
						answer
						tag {
							id
							name
							entityType
						}
					}
				}
				answers {
					id
					answer
					tag {
						id
						name
						entityType
					}
				}
				transaction {
					cost
				}
				service {
					name
					minimumDaysToPerform
					maximumDaysToPerform
					serviceOffer {
						id
						regionId
						timeRange
					}
				}
			}
		}
	}
`

export async function LoadProviderJobsQuery(variables: LoadProviderClaimableJobs$input) {
	const { data } = await query.fetch({
		variables: {
			filter: variables.filter ?? {
				requestedScheduleFrom: null,
				requestedScheduleTo: null,
				serviceId: null,
				regionIds: null,
			},
			pagination: variables.pagination ?? {
				pageNumber: 1,
				pageSize: 0,
			},
			orderBy: variables.orderBy ?? ['REQUESTED_SCHEDULE_ASC'],
		},
	})

	if (!data) {
		return []
	}

	return data.claimableJobs.data
}

export default LoadProviderJobsQuery
