import { graphql, ResendVerificationEmailStore } from '$houdini'

const resendVerificationEmailMutation: ResendVerificationEmailStore = graphql`
	mutation ResendVerificationEmail($email: EmailAddress, $userId: PositiveInt) {
		requestEmailVerification(email: $email, userId: $userId)
	}
`

export function sendEmailVerification() {
	return resendVerificationEmailMutation
}
