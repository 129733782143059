import { graphql, CustomerResidencesQueryStore, type CustomerResidencesQuery$result } from '$houdini'
import { formatCityStateZip } from '../format/format-city-state-zip'

export type CustomerResidence = CustomerResidencesQuery$result['residences']['data'][number]

const customerResidencesQuery: CustomerResidencesQueryStore = graphql`
	query CustomerResidencesQuery($filter: ResidenceFilter) {
		residences(filter: $filter) {
			data {
				id
				street
				city
				state
				zip
				regionId
				region {
					status
				}
				customerId
				status
				country
				county
				latitude
				longitude
				googleMapsPlaceId
				displayLawnSquareFootage
				estimatedLawnSquareFootage
				effectiveLawnSquareFootage
				answers {
					id
					question {
						services {
							id
						}
					}
				}
			}
		}
	}
`

export async function loadCustomerResidences(userAccountId: number) {
	const { data } = await customerResidencesQuery.fetch({
		variables: {
			filter: {
				userAccountId,
				residenceStatus: 'ACTIVE',
			},
		},
	})

	if (!data?.residences) {
		return []
	}

	return data.residences.data.sort((a, b) => {
		if (a.region?.status === 'ACTIVE' && b.region?.status !== 'ACTIVE') {
			return -1
		} else if (a.region?.status !== 'ACTIVE' && b.region?.status === 'ACTIVE') {
			return 1
		} else {
			return 0
		}
	})
}

export function formatCustomerResidences(residences: CustomerResidence[]) {
	return residences.map(residence => {
		return {
			...residence,
			cityStateZipFormatted: formatCityStateZip(
				{
					city: residence.city,
					state: residence.state,
					zip: residence.zip,
				},
				true
			),
		}
	})
}

export default loadCustomerResidences
