<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'

	import { changeUserPassword, updateUserAccountInfo, updateUserDOB, updateUserHomeRegion, type UserAccountSession, type UserZone } from 'utility/profile-helper'
	import type { Mediator } from 'types/common'
	import type { CustomerResidence } from 'utility/graphql/load-customer-residences'
	import sessionStore from 'stores/session'
	import UserProfile from 'components/UserProfile.svelte'
	import CustomerAddresses from 'components/CustomerAddresses.svelte'
	import ProviderRegionSelector from 'components/ProviderRegionSelector.svelte'

	import { slide } from 'svelte/transition'
	import type { WritableDeep } from 'type-fest'
	import { getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	export let userAccount: WritableDeep<UserAccountSession>
	export let isProvider: boolean
	export let isCustomer: boolean
	export let customerResidences: CustomerResidence[]
	export let providerZones: UserZone[]
	export let dobSaved: boolean

	const providerRegions = providerZones.flatMap(zone => zone.regions)

	let defaultUserAccount: UserAccountSession = userAccount
	let alertState: { message: string; class: string } = { message: '', class: '' }
	let editProfileMode: boolean = false
	let isSavingProfile: boolean = false
	let changePasswordModalShown: boolean = false
	let dateOfBirthChanged: boolean = false
	let homeRegionChanged: boolean = false

	let currentPassword: string = ''
	let newPassword: string = ''
	let newPasswordConfirm: string = ''

	let oldMobilePhoneNumber: string = ''
	let oldFirstName: string = ''
	let oldLastName: string = ''

	$: changePasswordErrorAlert = newPassword !== newPasswordConfirm ? `Your passwords don't match!` : ''

	function showChangePasswordModal() {
		currentPassword = ''
		newPassword = ''
		newPasswordConfirm = ''
		changePasswordModalShown = true
		const currentPasswordInputElement = document.getElementById('currentPassword')
		if (currentPasswordInputElement) {
			currentPasswordInputElement.focus()
		}
	}

	function enableProfileEdit() {
		editProfileMode = true
		isSavingProfile = false

		oldMobilePhoneNumber = userAccount.mobile || ''
		oldFirstName = userAccount.firstName
		oldLastName = userAccount.lastName

		defaultUserAccount = userAccount
	}

	async function saveProfile() {
		isSavingProfile = true
		const { id, firstName, lastName, mobile } = userAccount
		let dob: Date | null | undefined
		if (isProvider && dateOfBirthChanged) {
			dob = userAccount.provider?.dateOfBirth
		}

		try {
			const userAccountInfo = {
				id,
				firstName,
				lastName,
				mobile,
			}

			if (userAccountInfo.mobile === '') {
				userAccountInfo.mobile = null
			}

			//We did validation here at one point on the mobile number but its handled in the user profile component now

			const updatedAccountInfo = await updateUserAccountInfo(userAccountInfo)

			sessionStore.update((session: any) => {
				session.userAccount = updatedAccountInfo
				return session
			})

			if (isProvider && dateOfBirthChanged && dob && userAccount.provider) {
				await updateUserDOB(dob, userAccount.provider.id)
				dobSaved = true
				dateOfBirthChanged = false // reset the flag in case the user changes something else
			}

			if (isProvider && homeRegionChanged && userAccount.provider && userAccount.provider.homeRegionId) {
				await updateUserHomeRegion(userAccount.provider.id, userAccount.provider.homeRegionId)
				homeRegionChanged = false
			}

			oldMobilePhoneNumber = ''
			oldFirstName = ''
			oldLastName = ''

			editProfileMode = false
			isSavingProfile = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: error.message, title: 'Error Saving Profile' })
			cancelEdit() // reset the user account to the original values if the save fails
		}
	}

	function cancelEdit() {
		userAccount.mobile = oldMobilePhoneNumber
		userAccount.firstName = oldFirstName
		userAccount.lastName = oldLastName
		if (isProvider && userAccount.provider) {
			userAccount.provider.dateOfBirth = null
		}
		editProfileMode = false
		userAccount = defaultUserAccount
	}

	async function confirmChangePassword(currentPassword: any, newPassword: any, newPasswordConfirm: any, e: SubmitEvent & { currentTarget: EventTarget & HTMLFormElement }) {
		e.preventDefault()
		if (newPassword !== newPasswordConfirm) {
			return
		}

		try {
			if (!userAccount.recoveryEmail) {
				throw new Error('You must have a recovery email to change your password.')
			}
			await changeUserPassword(newPassword, currentPassword, userAccount.recoveryEmail)
			changePasswordModalShown = false
			alertState.message = 'Password changed successfully!'
			alertState.class = 'success'
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: error.message, title: 'Error Changing Password' })
		} finally {
			changePasswordModalShown = false
		}
	}
</script>

<div
	class="d-flex m-3"
	class:justify-content-between={!editProfileMode}
	class:justify-content-end={editProfileMode}
>
	{#if !editProfileMode}
		<Button
			id="change-password-button"
			title="Change Password"
			outline
			color="primary"
			size="sm"
			icon={{
				icon: 'key',
			}}
			on:click={showChangePasswordModal}
			disabled={!userAccount.recoveryEmail}>Change Password</Button
		>
	{/if}
	<div>
		{#if !editProfileMode}
			<Button
				id="edit-profile-button"
				title="Edit Profile"
				color="primary"
				size="sm"
				icon={{
					icon: 'pen',
				}}
				on:click={enableProfileEdit}
				disabled={!userAccount.recoveryEmail}>Edit Profile</Button
			>
		{:else}
			<Button
				id="save-profile-button"
				title="Save Profile"
				color="primary"
				size="sm"
				icon={{
					icon: 'save',
				}}
				on:click={saveProfile}
				disabled={isSavingProfile || !userAccount.recoveryEmail}>Save</Button
			>
			<Button
				id="cancel-edit-button"
				title="Cancel Edit"
				outline
				class="ml-2"
				color="danger"
				size="sm"
				icon={{
					icon: 'xmark',
				}}
				on:click={cancelEdit}
				disabled={isSavingProfile || !userAccount.recoveryEmail}>Cancel</Button
			>
		{/if}
	</div>
</div>

<div class="p-3">
	{#if alertState?.message}
		<div
			class="alert alert-{alertState?.class} mb-2"
			role="alert"
			in:slide={{ duration: 200 }}
			out:slide={{ duration: 200 }}
		>
			{alertState?.message}
		</div>
	{/if}
	<UserProfile
		disableEmailField
		{userAccount}
		disableOtherField={!editProfileMode}
		showProfilePicture={isProvider}
		showHomeRegionSelect={isProvider}
		availableRegions={providerRegions}
		isEditing={editProfileMode}
		dobChangedSaved={dobSaved}
		on:DOBChanged={() => {
			dateOfBirthChanged = true
		}}
		on:homeRegionChanged={() => {
			homeRegionChanged = true
		}}
	/>
	<div class="mt-4">
		<div class="form-row">
			<div
				class:col-12={isCustomer && !isProvider}
				class:col-md-6={isProvider && isCustomer}
				class:d-none={!isCustomer}
			>
				<CustomerAddresses
					selectableAddresses={false}
					bind:customerResidences
				/>
			</div>
			<div
				class:col-12={!isCustomer && isProvider}
				class:col-md-6={isProvider && isCustomer}
				class:d-none={!isProvider}
			>
				<div class="card">
					<div class="card-header">
						<h4 class="mb-0">Opt Into Regions</h4>
					</div>
					<div class="card-body">
						<ProviderRegionSelector />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<Modal
	title="Change Password"
	show={changePasswordModalShown}
	confirmButtonSize="sm"
	cancelButtonSize="sm"
	confirmButtonColor="primary"
	cancelButtonColor="danger"
	cancelButtonText="Cancel"
	modalSize="sm"
	confirmButtonType="submit"
	confirmButtonFormId="changePassword"
	confirmButtonDisabled={(changePasswordErrorAlert !== '' && newPassword !== newPasswordConfirm) || !currentPassword || !newPassword || !newPasswordConfirm}
	on:backdrop={() => (changePasswordModalShown = false)}
	on:close={() => (changePasswordModalShown = false)}
>
	<form
		id="changePassword"
		on:submit={e => confirmChangePassword(currentPassword, newPassword, newPasswordConfirm, e)}
	>
		<div class="form-row">
			<div class="col-md-12">
				<div class="form-row">
					<div class="col-md-12">
						<Input
							required
							id="current-password"
							title="Current Password"
							label="Current Password"
							bind:value={currentPassword}
							type="password"
							validation={{
								value: currentPassword,
							}}
						/>
					</div>
					<div class="col-md-12">
						<Input
							id="new-password"
							title="New Password"
							label="New Password"
							bind:value={newPassword}
							type="password"
							required
							validation={{
								value: newPassword,
							}}
						/>
					</div>
					<div class="col-md-12">
						<Input
							id="new-password-confirm"
							title="Confirm New Password"
							label="Confirm New Password"
							bind:value={newPasswordConfirm}
							type="password"
							required={!!newPassword}
							validation={{
								value: newPasswordConfirm,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		{#if changePasswordErrorAlert}
			<div
				class="alert alert-danger mb-0 mt-2"
				role="alert"
			>
				{changePasswordErrorAlert}
			</div>
		{/if}
	</form>
</Modal>
