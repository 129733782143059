const parcelPriceQuoteQuery = `#graphql
	parcel {
		priceQuote(serviceId: $serviceId) {
            price
        }
	}
`

export default async function addNewResidence(mediator, serviceId, customerId, addressData, placeId) {
	const newResidenceMutation = `#graphql
        mutation NewResidence(${serviceId ? '$serviceId: PositiveInt, ' : ''}$newResidence: NewResidence!) {
            newResidence(newResidence: $newResidence) {
                id
                regionId
                region {
                    status
                }
                customerId
                status
                street
                city
                county
                state
                zip
                country
                googleMapsPlaceId
                estimatedLawnSquareFootage
                effectiveLawnSquareFootage
                displayLawnSquareFootage
                latitude
                longitude
                ${serviceId ? parcelPriceQuoteQuery : ''}
            }
        }
    `

	try {
		return await mediator.call('apiFetch', newResidenceMutation, {
			newResidence: {
				customerId,
				street: addressData?.street,
				city: addressData?.city,
				county: addressData?.county,
				state: addressData?.state,
				zip: addressData?.zip.toString(),
				country: addressData?.country,
				latitude: addressData?.latitude,
				longitude: addressData?.longitude,
				googleMapsPlaceId: placeId,
			},
			...(serviceId ? { serviceId } : {}),
		})
	} catch (err) {
		console.error(err)
		throw err //rethrow
	}
}

