import template from './customer-order-list-item.html'
import getComputedJob from 'utility/get-computed-job'
import statusMap from 'utility/job-status-map'
import makeGenericBadge from './generic-badge'

export default function createMapSearchComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				job: {},
				class: 'list-group-item list-group-item-action',
				showCityStateZip: true,
				statusMap,
			}
		},
		components: {
			genericBadge: makeGenericBadge(),
		},
		attributes: {
			required: [ 'job' ],
			optional: [ 'showCityStateZip' ],
		},
		computed: {
			computedJob() {
				return getComputedJob(this.get('job'), { includeZip: false })
			},
		},
	})
}
