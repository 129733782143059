<script lang="ts">
	export let customerFirstJob: boolean = false
	export let street: string
</script>

<div class="d-flex align-items-center">
	{#if customerFirstJob}
		<i
			class="fa-solid fa-xl fa-star mr-1"
			style="color:gold;"
		>
		</i>
	{/if}
	<span
		class="ellipsis"
		style="font-size: larger;">{street}</span
	>
</div>
