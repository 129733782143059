import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadResidences'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadResidencesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadResidencesStore",
			variables: false,
		})
	}
}

export async function load_LoadResidences(params) {
	await initClient()

	const store = new LoadResidencesStore()

	await store.fetch(params)

	return {
		LoadResidences: store,
	}
}
