<script lang="ts">
	import type { Colors } from '@isoftdata/utility-bootstrap'

	export let color: Colors = 'primary'
	export let size: 'sm' | 'md' | 'lg' = 'md'

	let computedSize = computeSizeStyle()
	let computedClass = `spinner-border text-${color}`
	let computedStyle = `width: ${computedSize}; height: ${computedSize}`

	function computeSizeStyle() {
		switch (size) {
			case 'sm':
				return '1rem'
			case 'md':
				return '3rem'
			case 'lg':
				return '5rem'
		}
	}
</script>

<div
	role="status"
	class={computedClass}
	style={computedStyle}
></div>
