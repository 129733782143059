<script lang="ts">
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

	import type { SvelteAsr } from 'types/common'

	import type { ComponentProps } from 'svelte'

	export let asr: SvelteAsr
	export let childStates: ComponentProps<AsrNavTabBar>['tabs']

	let selectedTab: string
</script>

<div class="card-header admin-nav-tab-bar">
	<AsrNavTabBar
		tabs={childStates}
		{asr}
		breakpoint="md"
		bind:selectedTab
	/>
</div>

<uiView />
