import { booleanToString } from '@isoftdata/utility-boolean'
import { SetUserSettingStore, SettingChange, graphql } from '$houdini'

export type SetUserSettingInput = Omit<SettingChange, 'newValue'> & { newValue: string | boolean }

const setUserSetting: SetUserSettingStore = graphql`
	mutation SetUserSetting($value: SettingChange!) {
		setUserSetting(value: $value)
	}
`

export default (value: SetUserSettingInput) => {
	return setUserSetting.mutate({
		value: {
			...value,
			newValue: typeof value.newValue === 'boolean' ? booleanToString(value.newValue) : value.newValue,
		},
	})
}
