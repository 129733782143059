export default {
    "name": "TagPricingsData",
    "kind": "HoudiniFragment",
    "hash": "5099f0c3b34e4814c75d970f25a254085c989f29f9c1bc82469aa9b3210939f7",

    "raw": `fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "TagPricing",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "tagId": {
                "type": "PositiveInt",
                "keyRaw": "tagId",
                "visible": true
            },

            "serviceId": {
                "type": "PositiveInt",
                "keyRaw": "serviceId",
                "visible": true
            },

            "priceAdjustment": {
                "type": "String",
                "keyRaw": "priceAdjustment",
                "visible": true
            },

            "pricingType": {
                "type": "PricingType",
                "keyRaw": "pricingType",
                "visible": true
            },

            "postPriceAdjustment": {
                "type": "Boolean",
                "keyRaw": "postPriceAdjustment",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=0869c4055f99d9847b9ff7fe35ca6a658b4001364cfedab7164443a3634ded2e";