export default {
    "name": "DeleteServiceApplicationInOnboardingApplication",
    "kind": "HoudiniMutation",
    "hash": "b6fbe40ac080a8eafbfd02df7922f3e4bcbc7a13753a1c9f7d6c43cfe5c6e0c7",

    "raw": `mutation DeleteServiceApplicationInOnboardingApplication($providerApplicationId: PositiveInt!, $providerServiceApplicationId: PositiveInt!) {
  deleteServiceApplicationInOnboardingApplication(
    providerApplicationId: $providerApplicationId
    providerServiceApplicationId: $providerServiceApplicationId
  ) {
    id
    status
    lastUpdated
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteServiceApplicationInOnboardingApplication": {
                "type": "ProviderApplication",
                "keyRaw": "deleteServiceApplicationInOnboardingApplication(providerApplicationId: $providerApplicationId, providerServiceApplicationId: $providerServiceApplicationId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastUpdated": {
                            "type": "DateTime",
                            "keyRaw": "lastUpdated",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerApplicationId": "PositiveInt",
            "providerServiceApplicationId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=7e3b5d8899b032c9e9a4ba4d4eafcdd8332dc8ffd15f196c78416baa9c1640e2";