<script lang="ts">
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

	import type { SvelteAsr } from 'types/common'

	import type { ComponentProps } from 'svelte'

	export let asr: SvelteAsr
	export let childStates: ComponentProps<AsrNavTabBar>['tabs']

	let selectedTab: string
</script>

<div class="card mb-3">
	<div class="card-header">
		<div>
			<h3>
				<i class="fas fa-user-gear"></i>
				Account Settings
			</h3>
			<AsrNavTabBar
				tabs={childStates}
				{asr}
				breakpoint="md"
				bind:selectedTab
			/>
		</div>
	</div>
	<div class="card-body p-0">
		<uiView />
	</div>
</div>
