<script lang="ts">
	import type { Mediator, SvelteAsr } from 'types/common'

	import { loginAndSaveSession } from 'utility/login-and-save-session'
	import Button from '@isoftdata/svelte-button'
	import PasswordFields from '@isoftdata/svelte-password-fields'
	import { graphql, ChangePasswordStore } from '$houdini'
	import { getContext, onMount } from 'svelte'

	const changePasswordMutation: ChangePasswordStore = graphql`
		mutation ChangePassword($email: String!, $resetToken: String, $newPassword: String!) {
			changePassword(email: $email, resetToken: $resetToken, newPassword: $newPassword)
		}
	`

	const mediator = getContext<Mediator>('mediator')

	export let account: string // email?
	export let recoveryCode: string
	export let password: string
	export let confirmPassword: string
	export let isChangingPassword: boolean
	export let asr: SvelteAsr

	async function setPassword(newPassword: string, confirmPassword: string) {
		isChangingPassword = true

		try {
			if (newPassword !== confirmPassword) {
				throw new Error('Passwords do not match')
			}
			const email = account

			const { data } = await changePasswordMutation.mutate({
				email,
				resetToken: recoveryCode,
				newPassword,
			})

			if (data?.changePassword) {
				await loginAndSaveSession(mediator, { email, password: newPassword, regionId: 1 })
				asr.go('app')
			} else {
				throw new Error('') //throw an empty message so we use the default error message in our catch block
			}
		} catch (err) {
			console.error(err)
			alert((err as Error)?.message || 'An unknown error occurred while resetting your password. Please contact Lawn Hiro support.')
		} finally {
			isChangingPassword = false
		}
	}

	onMount(() => {
		// Focus password input
		document.querySelector<HTMLInputElement>('input[type="password"]')?.focus()
	})
</script>

<div class="container d-flex justify-content-center mt-3">
	<form on:submit|preventDefault={() => setPassword(password, confirmPassword)}>
		<div class="card mt-2">
			<h2 class="card-header">Set A New Password</h2>
			<div class="card-body">
				<PasswordFields
					bind:password
					bind:confirmPassword
				/>
			</div>
			<div class="card-footer">
				<Button
					class="w-100"
					type="submit"
					isLoading={isChangingPassword}
					disabled={isChangingPassword}>Set Password & Continue to Account</Button
				>
			</div>
		</div>
	</form>
</div>
