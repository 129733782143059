import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ProviderOnboardingApplications'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ProviderOnboardingApplicationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ProviderOnboardingApplicationsStore",
			variables: false,
		})
	}
}

export async function load_ProviderOnboardingApplications(params) {
	await initClient()

	const store = new ProviderOnboardingApplicationsStore()

	await store.fetch(params)

	return {
		ProviderOnboardingApplications: store,
	}
}
