export default {
    "name": "AddNewAddressMutation",
    "kind": "HoudiniMutation",
    "hash": "d09d5007682bc535577e59f3da2894fcd749db2b6a29a1a3daf6de5dd533e807",

    "raw": `mutation AddNewAddressMutation($newResidence: NewResidence!) {
  newResidence(newResidence: $newResidence) {
    ...NewResidenceData
    id
  }
}

fragment NewResidenceData on Residence {
  id
  regionId
  region {
    status
    id
  }
  customerId
  status
  street
  city
  state
  zip
  country
  county
  googleMapsPlaceId
  estimatedLawnSquareFootage
  effectiveLawnSquareFootage
  displayLawnSquareFootage
  latitude
  longitude
  answers {
    id
    question {
      services {
        id
      }
      id
    }
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newResidence": {
                "type": "Residence",
                "keyRaw": "newResidence(newResidence: $newResidence)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "region": {
                            "type": "Region",
                            "keyRaw": "region",

                            "selection": {
                                "fields": {
                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "customerId": {
                            "type": "PositiveInt",
                            "keyRaw": "customerId",
                            "visible": true
                        },

                        "status": {
                            "type": "ResidenceStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "county": {
                            "type": "String",
                            "keyRaw": "county",
                            "visible": true
                        },

                        "googleMapsPlaceId": {
                            "type": "String",
                            "keyRaw": "googleMapsPlaceId",
                            "nullable": true,
                            "visible": true
                        },

                        "estimatedLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "estimatedLawnSquareFootage",
                            "visible": true
                        },

                        "effectiveLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "effectiveLawnSquareFootage",
                            "nullable": true,
                            "visible": true
                        },

                        "displayLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "displayLawnSquareFootage",
                            "visible": true
                        },

                        "latitude": {
                            "type": "Latitude",
                            "keyRaw": "latitude",
                            "nullable": true,
                            "visible": true
                        },

                        "longitude": {
                            "type": "Longitude",
                            "keyRaw": "longitude",
                            "nullable": true,
                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "question": {
                                        "type": "Question",
                                        "keyRaw": "question",

                                        "selection": {
                                            "fields": {
                                                "services": {
                                                    "type": "Service",
                                                    "keyRaw": "services",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "NewResidenceData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newResidence": "NewResidence"
        },

        "types": {
            "NewResidence": {
                "city": "String",
                "country": "String",
                "county": "String",
                "customerId": "PositiveInt",
                "googleMapsPlaceId": "String",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "state": "String",
                "street": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=321ae57a19cf593e6b4aec6c69edb73f17c0a622972dfa30aeaaf9f3f86e776a";