export default {
    "name": "UpcomingOrders",
    "kind": "HoudiniQuery",
    "hash": "ce93464af26d08dcc7139e674932086ab813ba2bf128f44ae48657e78e08b173",

    "raw": `query UpcomingOrders($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
  upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
    data {
      id
      residence {
        street
        city
        state
        zip
        country
        id
      }
      jobStatus
      requestedSchedule
      service {
        name
        id
      }
      jobClaims {
        scheduledAt
        id
      }
      answers {
        id
        answer
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "upcomingOrders": {
                "type": "JobResponse",
                "keyRaw": "upcomingOrders(orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Job",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "residence": {
                                        "type": "Residence",
                                        "keyRaw": "residence",

                                        "selection": {
                                            "fields": {
                                                "street": {
                                                    "type": "String",
                                                    "keyRaw": "street",
                                                    "visible": true
                                                },

                                                "city": {
                                                    "type": "String",
                                                    "keyRaw": "city",
                                                    "visible": true
                                                },

                                                "state": {
                                                    "type": "String",
                                                    "keyRaw": "state",
                                                    "visible": true
                                                },

                                                "zip": {
                                                    "type": "String",
                                                    "keyRaw": "zip",
                                                    "visible": true
                                                },

                                                "country": {
                                                    "type": "String",
                                                    "keyRaw": "country",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "jobStatus": {
                                        "type": "JobStatus",
                                        "keyRaw": "jobStatus",
                                        "visible": true
                                    },

                                    "requestedSchedule": {
                                        "type": "Date",
                                        "keyRaw": "requestedSchedule",
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "jobClaims": {
                                        "type": "JobClaim",
                                        "keyRaw": "jobClaims",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "scheduledAt": {
                                                    "type": "DateTime",
                                                    "keyRaw": "scheduledAt",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "JobOrderBy",
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=54877bcf39bdc25359e615b78a6d73c168be6581efd9ffa05d2e3407c5df562c";