import { loadQuestions } from 'utility/question-helper'
import component from './Question.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.question',
		route: 'question',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const questions = await loadQuestions()

			return Promise.resolve({
				questions,
			})
		},
	})
}
