export default {
    "name": "DeleteTag",
    "kind": "HoudiniMutation",
    "hash": "b4f90e694be89b3d2daf6e92f71b1fabc550923e7b76dc1ee918bb6ef18100e8",

    "raw": `mutation DeleteTag($tagId: PositiveInt!) {
  deleteTag(tagId: $tagId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteTag": {
                "type": "Boolean",
                "keyRaw": "deleteTag(tagId: $tagId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tagId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=474a5c62b2a896571bc7f2b49313ea83a91de7ea2a74acaf7b9cc6147c3dfd52";