import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadPassedScheduleTimeJobClaims'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadPassedScheduleTimeJobClaimsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadPassedScheduleTimeJobClaimsStore",
			variables: false,
		})
	}
}

export async function load_LoadPassedScheduleTimeJobClaims(params) {
	await initClient()

	const store = new LoadPassedScheduleTimeJobClaimsStore()

	await store.fetch(params)

	return {
		LoadPassedScheduleTimeJobClaims: store,
	}
}
