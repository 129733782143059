import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadOnboardingQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadOnboardingQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadOnboardingQueryStore",
			variables: true,
		})
	}
}

export async function load_LoadOnboardingQuery(params) {
	await initClient()

	const store = new LoadOnboardingQueryStore()

	await store.fetch(params)

	return {
		LoadOnboardingQuery: store,
	}
}
