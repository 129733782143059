export default {
    "name": "UpdateDelayJobClaim",
    "kind": "HoudiniMutation",
    "hash": "6695819329c1d0cc8811baec71e6417759317686cba5a211ee1859a5931c4eb7",

    "raw": `mutation UpdateDelayJobClaim($delayedClaim: DelayJobClaim!) {
  delayJobClaim(delayedClaim: $delayedClaim) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "delayJobClaim": {
                "type": "JobClaim",
                "keyRaw": "delayJobClaim(delayedClaim: $delayedClaim)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "delayedClaim": "DelayJobClaim"
        },

        "types": {
            "DelayJobClaim": {
                "id": "PositiveInt",
                "internalNotes": "String",
                "newScheduledAt": "DateTime",
                "reason": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4d755f4bfecd4c970c07192ca2b0991cd269155a952b04293518209f003e40c4";