import artifact from '$houdini/artifacts/CreateZone'
import { MutationStore } from '../runtime/stores/mutation'

export class CreateZoneStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
