export default {
    "name": "DeleteQuestion",
    "kind": "HoudiniMutation",
    "hash": "d8cebcee673bf301fbdee91fdb421c68e9c5d141a848cb85fdb4a26b566549ba",

    "raw": `mutation DeleteQuestion($deleteQuestionId: PositiveInt!) {
  deleteQuestion(id: $deleteQuestionId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteQuestion": {
                "type": "Boolean",
                "keyRaw": "deleteQuestion(id: $deleteQuestionId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "deleteQuestionId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=594086b904f840cf0741d35febee08e2b1b7bce7244603400b9a79ce8cbfad2a";