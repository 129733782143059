export default {
    "name": "LoadQuestions",
    "kind": "HoudiniQuery",
    "hash": "ba91a3113f3c1e4b9d2a299ce367900f95cdab381d54f6c95b3c5fa411af825c",

    "raw": `query LoadQuestions {
  questions {
    data {
      ...QuestionData
      id
    }
  }
}

fragment QuestionData on Question {
  id
  question
  description
  questionDataType
  answers {
    ...AnswerData
    id
  }
  __typename
}

fragment AnswerData on Answer {
  id
  answer
  questionId
  tagId
  tag {
    ...TagData
    id
  }
  __typename
}

fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "questions": {
                "type": "QuestionResponse",
                "keyRaw": "questions",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Question",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "question": {
                                        "type": "String",
                                        "keyRaw": "question",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "questionDataType": {
                                        "type": "QuestionDataType",
                                        "keyRaw": "questionDataType",
                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                },

                                                "questionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "questionId",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "tag": {
                                                    "type": "Tag",
                                                    "keyRaw": "tag",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "code": {
                                                                "type": "String",
                                                                "keyRaw": "code",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "prompt": {
                                                                "type": "String",
                                                                "keyRaw": "prompt",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "entityType": {
                                                                "type": "EntityType",
                                                                "keyRaw": "entityType",
                                                                "visible": true
                                                            },

                                                            "tagPricings": {
                                                                "type": "TagPricing",
                                                                "keyRaw": "tagPricings",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "tagId": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "tagId",
                                                                            "visible": true
                                                                        },

                                                                        "serviceId": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "serviceId",
                                                                            "visible": true
                                                                        },

                                                                        "priceAdjustment": {
                                                                            "type": "String",
                                                                            "keyRaw": "priceAdjustment",
                                                                            "visible": true
                                                                        },

                                                                        "pricingType": {
                                                                            "type": "PricingType",
                                                                            "keyRaw": "pricingType",
                                                                            "visible": true
                                                                        },

                                                                        "postPriceAdjustment": {
                                                                            "type": "Boolean",
                                                                            "keyRaw": "postPriceAdjustment",
                                                                            "visible": true
                                                                        },

                                                                        "__typename": {
                                                                            "type": "String",
                                                                            "keyRaw": "__typename",
                                                                            "visible": true
                                                                        }
                                                                    },

                                                                    "fragments": {
                                                                        "TagPricingsData": {
                                                                            "arguments": {}
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "TagData": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "AnswerData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "QuestionData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7c164382b89cef90cd2e235b23abf6db3345b70f5e106eb946524b0adcb854a4";