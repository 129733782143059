<script lang="ts">
	import OnboardingSteps from 'components/OnboardingSteps.svelte'
	import type { Provider } from 'utility/provider-application-helper'

	export let provider: Provider
	export let shirtSizes: string[]
</script>

<div class="card mb-3 border-0">
	<div class="card-header">
		<h3 class="mb-0">Welcome, {provider.userAccount.firstName}</h3>
		<span class="text-muted">Here's what you need to do to set up your account.</span>
	</div>
	<div class="card-body">
		<OnboardingSteps
			bind:provider
			{shirtSizes}
		/>
	</div>
</div>
