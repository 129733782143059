<script lang="ts">
	import Sidebar, { type SidebarItemType } from '@isoftdata/svelte-sidebar'
	import Alert from '@isoftdata/svelte-alert'
	import Button from '@isoftdata/svelte-button'
	import type { Colors } from '@isoftdata/utility-bootstrap'

	import type { DomApi, Template, Mediator, SvelteAsr } from 'types/common'
	import type { ClientSession } from 'utility/login-and-save-session'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import { getFailedPaymentJobCount, getAllPassedScheduledTimeJobClaimCount } from 'utility/admin-job-alerts'
	import { minToMs } from 'utility/min-to-ms'
	import { isManifest } from 'utility/check-manifest'
	import ErrorModal from 'components/ErrorModal.svelte'
	import sessionStore from 'stores/session'

	import type { Writable } from 'svelte/store'
	import type { AbstractStateRouter } from 'abstract-state-router'
	import { getContext, onDestroy } from 'svelte'
	import { localWritable } from '@macfja/svelte-persistent-store'

	export let user: ClientSession | Record<string, never>
	export let isAdmin: boolean
	export let avatarImgSrc: string
	export let adminJobAlertStore: Writable<{ failedJobCount: number; passedScheduledTimeJobClaimCount: number }>
	export let states: SidebarItemType[]
	export let asr: SvelteAsr

	let alertIsShown = false
	let errorModal: ErrorModal
	let alert: Alert
	let stateIsChanging = false
	let destinationStateName: string = ''

	const stateRouter = getContext<AbstractStateRouter<Template, DomApi>>('stateRouter')
	const mediator = getContext<Mediator>('mediator')
	const expanded = localWritable('sidebarExpanded', true)

	stateRouter.on('stateChangeStart', state => {
		stateIsChanging = true
		destinationStateName = state.name
	})

	stateRouter.on('stateChangeEnd', state => {
		destinationStateName = ''
		stateIsChanging = false
	})

	stateRouter.on('stateChangeError', err => {
		const stateName = destinationStateName
		stateIsChanging = false
		destinationStateName = ''
		mediator.call('showError', err, { title: `Failed to change to state: ${stateName}`, message: err.message })
	})

	if (!user) {
		asr.go('login')
	}

	const sessionUnsub = sessionStore.subscribe((newSession: ClientSession | Record<string, never>) => {
		if (newSession?.provider?.profilePictureFile?.path) {
			avatarImgSrc = formatImageFileUrl(newSession.provider.profilePictureFile.path)
		}
		user = newSession
	})

	function getActiveState(state: ReturnType<typeof asr.getActiveState>) {
		return {
			name: state.name,
			route: state.name,
			parameters: state.parameters as Record<string, string | undefined>,
		}
	}

	$: activeState = getActiveState(asr.getActiveState())
	//ensure they made it to a role state
	$: {
		if (activeState.name === 'app') {
			if (user.customer) {
				asr.go('app.customer')
			} else if (user.provider) {
				if (user.provider.status === 'PENDING_APPROVAL') {
					asr.go('app.onboarding')
				} else {
					asr.go('app.provider')
				}
			}
		}
	}

	let adminJobRefreshIntervalId
	if (isAdmin) {
		// get admin attention items every 5 minutes
		adminJobRefreshIntervalId = setInterval(async () => {
			let failedJobNewCount = 0
			let passedScheduledTimeClaimedJobNewCount = 0
			try {
				failedJobNewCount = await getFailedPaymentJobCount()
				passedScheduledTimeClaimedJobNewCount = await getAllPassedScheduledTimeJobClaimCount()
			} catch (err) {
				console.error('Failed to get failed job count', err)
			}
			if (failedJobNewCount !== $adminJobAlertStore.failedJobCount) {
				$adminJobAlertStore.failedJobCount = failedJobNewCount
			}
			if (passedScheduledTimeClaimedJobNewCount !== $adminJobAlertStore.passedScheduledTimeJobClaimCount) {
				$adminJobAlertStore.passedScheduledTimeJobClaimCount = passedScheduledTimeClaimedJobNewCount
			}
		}, minToMs(5))
	}

	const adminJobAlertUnsub = adminJobAlertStore.subscribe(count => {
		const adminJobStateIndex = states.findIndex(state => state.type === 'ADMIN_JOB')
		const adminJobState = states[adminJobStateIndex]
		if (adminJobState && ($adminJobAlertStore.failedJobCount > 0 || $adminJobAlertStore.passedScheduledTimeJobClaimCount > 0)) {
			// set icons to notification
			adminJobState.icon = {
				prefix: 'fak',
				class: 'fa-solid-briefcase-circle-exclamation fa-shake icon-slow-animation text-danger',
			}
			adminJobState.badge = {
				show: true,
				text: ($adminJobAlertStore.failedJobCount + $adminJobAlertStore.passedScheduledTimeJobClaimCount).toString(),
				color: 'danger',
			}
		} else if (adminJobState) {
			// set icons to no notification
			adminJobState.icon = {
				prefix: 'fas',
				icon: 'briefcase',
			}
			adminJobState.badge = undefined
		}

		states[adminJobStateIndex] = adminJobState
		states = states
	})

	const removeShowErrorProvider = mediator.provide('showError', (err, options = {}) => {
		// If you want to change the title or message shown to the user, pass in an options object with the title and message properties.
		errorModal.show({ error: err, ...options })
	})

	const removeShowMessageProvider = mediator.provide(
		'showMessage',
		(options: { color?: Colors; time?: false | number; heading: string; message?: string; dismissable?: boolean } = { heading: '' }) => {
			const { color, time, dismissable, ...otherOptions } = options
			// ractive alert called it "type" but now it's "color" so we should support both. Same with "time" being false or a number
			alert?.show({
				color: color || 'secondary',
				time: time === false ? 0 : time,
				...otherOptions,
				dismissable: dismissable !== false, //if they don't pass a value for dismissable, make sure we default it to true
			})
		},
	)

	const removeHideMessageProvider = mediator.provide('hideMessage', () => {
		alert.hide()
	})

	let checkUpdateInterval: ReturnType<typeof setInterval> | undefined = undefined
	if (import.meta.env.PROD) {
		checkUpdateInterval = setInterval(async () => {
			const response = await fetch('manifest.json', { cache: 'no-store' })

			const data = await response.json()
			if (isManifest(data) && data.client.version !== '__buildVersion__') {
				mediator.call('showMessage', {
					color: 'warning',
					time: 0,
					heading: 'An Update to LawnHiro is Available',
					message: 'RELOAD_BUTTON',
				})
				// Don't show more than once
				clearInterval(checkUpdateInterval)
			}
		}, minToMs(2)) // every 2 minutes
	} else {
		console.log('Not in production, skipping update check')
	}

	onDestroy(() => {
		sessionUnsub()
		adminJobAlertUnsub()
		clearInterval(adminJobRefreshIntervalId)
		removeShowErrorProvider()
		removeShowMessageProvider()
		removeHideMessageProvider()
		clearInterval(checkUpdateInterval)
	})
</script>

<ErrorModal bind:this={errorModal}></ErrorModal>

<Sidebar
	buildRoute={asr.makePath}
	items={states}
	{activeState}
	bind:expanded={$expanded}
	logoImageUrl="./images/lawnhiro-logo-horizontal.svg"
	collapsedLogoImageUrl="./images/lawnhiro-logo-square.svg"
	contactUsItem={{ name: 'Contact Us', icon: 'comments', route: 'https://lawnhiro.com/contact/', external: true }}
	configurationItem={{ name: 'Settings', icon: 'gear', route: 'app.account' }}
	userAccountItem={{
		userName: user.fullName ?? `${user.firstName} ${user.lastName}`,
		email: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		route: 'app.account',
		avatarImgSrc,
	}}
	avatarImgClass="avatar-image-size"
	on:logout={() => asr.go('login')}
>
	<uiView
		id="main-app"
		role="main"
		class="pl-0 pr-0"
	></uiView>
</Sidebar>

<div class="d-flex justify-content-center">
	<Alert
		bind:this={alert}
		bind:shown={alertIsShown}
		style="margin-bottom: .5rem; position:fixed; bottom: 0px; left: .5rem; box-shadow: 0 20px 40px -6px grey; z-index: {alertIsShown ? '1100' : '-1'};"
		class="mr-2"
		let:message
	>
		<div class="alert-padding">
			{#if message === 'RELOAD_BUTTON'}
				<Button
					outline
					color="secondary"
					style="overflow-wrap: break-word;"
					class="w-100"
					on:click={() => window.location.reload()}>Click Here to Update</Button
				>
			{:else}
				{#each message.split('\n') as line}
					<p>{line}</p>
				{/each}
			{/if}
		</div>
	</Alert>
</div>

{#if stateIsChanging}
	<div class="loading-state">
		<div
			class="spinner-border text-primary awesome-spinner"
			role="status"
		></div>
	</div>
{/if}

<style>
	.loading-state {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000000;
		background-color: rgba(255, 255, 255, 0.8);
	}

	.awesome-spinner {
		width: 5rem;
		height: 5rem;
		position: absolute;
		top: 15%;
	}
</style>
