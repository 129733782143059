export default {
    "name": "RegisterNewUserAccount",
    "kind": "HoudiniMutation",
    "hash": "d4c7cd2e7282f5315076632fafd5cb0b0f575f04c31e114e908d7ee165baefb0",

    "raw": `mutation RegisterNewUserAccount($newUserAccountInfo: NewUserAccountInfo!, $requestedRoles: [UserAccessType!]) {
  registerNewUserAccount(
    newUserAccountInfo: $newUserAccountInfo
    requestedRoles: $requestedRoles
  ) {
    id
    email
    customer {
      id
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "registerNewUserAccount": {
                "type": "UserAccount",
                "keyRaw": "registerNewUserAccount(newUserAccountInfo: $newUserAccountInfo, requestedRoles: $requestedRoles)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "email": {
                            "type": "EmailAddress",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "newUserAccountInfo": "NewUserAccountInfo",
            "requestedRoles": "UserAccessType"
        },

        "types": {
            "OptionalNewResidence": {
                "city": "String",
                "country": "String",
                "county": "String",
                "googleMapsPlaceId": "String",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "state": "String",
                "street": "String",
                "zip": "String"
            },

            "NewUserAccountInfo": {
                "email": "EmailAddress",
                "firstName": "String",
                "lastName": "String",
                "mobile": "PhoneNumber",
                "optionalAddress": "OptionalNewResidence",
                "password": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=aea2bd830000ea7d95562facd540e51ff24181913cf34e995a10f8a297fa2e67";