export default {
    "name": "LoadTags",
    "kind": "HoudiniQuery",
    "hash": "4db44cf17e5012ee14bbef142a44502ecb8358a6c26cc9172bca8e0e5d5c16e1",

    "raw": `query LoadTags($filter: TagQueryFilter) {
  tags(filter: $filter) {
    data {
      ...TagData
      id
    }
  }
}

fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "tags": {
                "type": "TagResponse",
                "keyRaw": "tags(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Tag",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricings": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricings",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagPricingsData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "TagData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "TagQueryFilter"
        },

        "types": {
            "TagQueryFilter": {
                "code": "String",
                "entityType": "EntityType",
                "id": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d3dd88c6724062db9be8667715e4b2dc1595190130c7bd97ba0389f9f007b4df";