export default {
    "name": "LoadResidences",
    "kind": "HoudiniQuery",
    "hash": "2943653181bb60ba6a24cdcf90953e2dd2a25db7197cbd249931ae96b5f901ae",

    "raw": `query LoadResidences($filter: ResidenceFilter, $pagination: PaginatedInput) {
  residences(filter: $filter, pagination: $pagination) {
    data {
      id
      street
      city
      state
      zip
      regionId
      estimatedLawnSquareFootage
      effectiveLawnSquareFootage
      customer {
        userAccount {
          fullName
          email
          id
        }
        id
      }
      answers {
        id
        answer
      }
    }
    info {
      totalItems
      pageSize
      pageNumber
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "residences": {
                "type": "ResidenceResponse",
                "keyRaw": "residences(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Residence",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "regionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "regionId",
                                        "visible": true
                                    },

                                    "estimatedLawnSquareFootage": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "estimatedLawnSquareFootage",
                                        "visible": true
                                    },

                                    "effectiveLawnSquareFootage": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "effectiveLawnSquareFootage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "customer": {
                                        "type": "Customer",
                                        "keyRaw": "customer",

                                        "selection": {
                                            "fields": {
                                                "userAccount": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "userAccount",

                                                    "selection": {
                                                        "fields": {
                                                            "fullName": {
                                                                "type": "String",
                                                                "keyRaw": "fullName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "email": {
                                                                "type": "EmailAddress",
                                                                "keyRaw": "email",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ResidenceFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ResidenceFilter": {
                "customerId": "PositiveInt",
                "residenceKeywordSearch": "String",
                "residenceStatus": "ResidenceStatus",
                "tagId": "PositiveInt",
                "userAccountId": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=55488821a07127f38b544c9ffdfdbff626dc3490fbb4366960690d058b465d4b";