<script lang="ts">
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import Button from '@isoftdata/svelte-button'

	import type { SvelteAsr } from 'types/common'

	import { type ComponentProps } from 'svelte'

	export let asr: SvelteAsr
	export let childStates: ComponentProps<AsrNavTabBar>['tabs']

	export let scheduleType: string
</script>

<div class="card mb-3 border-0">
	<div class="card-header rounded-0">
		<div>
			<h3>
				<i class="fas fa-list"></i>
				Orders
			</h3>
			<AsrNavTabBar
				{asr}
				ulClass="nav-tabs card-header-tabs align-items-end"
				tabs={childStates}
				breakpoint={false}
			/>
		</div>
	</div>
	<div class="card-body">
		<div class="form-row flex-row-reverse mb-2 p-2">
			<Button
				id="new-order-button"
				title="Start New Order"
				class="responsive-button"
				color="success"
				icon={{
					icon: 'plus',
				}}
				href={asr.makePath('app.new-order', {}, { inherit: true })}
			>
				Start New
				{#if scheduleType === 'RECURRING_SCHEDULE'}
					Subscription
				{:else}
					Order
				{/if}
			</Button>
		</div>
		<uiView />
	</div>
</div>
