import template from './google-places-map.html'
import { v4 as uuid } from '@lukeed/uuid'

export default function createGooglePlaceMapComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		css: `
		#description {
			font-family: Roboto;
			font-size: 15px;
			font-weight: 300;
		}

		#infowindow-content .title {
			font-weight: bold;
		}

		#infowindow-content {
			display: none;
		}

		#map #infowindow-content {
			display: inline;
		}

		#title {
			color: #fff;
			background-color: #4d90fe;
			font-size: 25px;
			font-weight: 500;
			padding: 6px 12px;
		}`,
		data() {
			return {
				places: null, // set by this component
				bounds: null, // consumed by this component
				mapStyle: 'height: 500px', // need to set the height explicitly
				anchorInputToMap: false, // Should the input be inside the map?
				initWithCurrentLocation: false,
				uuid: uuid(),
				animateMarkerClick: false,
				pins: [],
				map: null,
				showPois: false,
			}
		},
		attributes: {
			required: [ 'places', 'bounds' ],
			optional: [
				'mapStyle',
				'anchorInputToMap',
				'initWithCurrentLocation',
				'animateMarkerClick',
				'pins',
				'showPois',
			],
		},
		onrender() { // need to do this on render, since it needs DOM elements to be rendered
			const ractive = this

			try {
				const map = new google.maps.Map(document.getElementById(`places-map-${ractive.get('uuid')}`), {
					center: { lat: 40.8136, lng: -96.7026 }, // hard coded to center on Lincoln on load
					zoom: 18,
					mapTypeId: 'hybrid',
					zoomControl: false,
					scaleControl: false,
					streetViewControl: true,
					rotateControl: false,
				})
				if (!ractive.get('showPois')) {
					map.setOptions({
						styles: [
							{
								featureType: 'poi',
								stylers: [
									{ visibility: 'off' },
								],
							},
						],
					})
				}

				// Move the input inside of the map, if we so desire
				if (ractive.get('anchorInputToMap')) {
					const input = document.getElementById("pac-input")
					map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)
				}
				// Store the map bounds for use in the places autocomplete component
				map.addListener('bounds_changed', () => {
					ractive.set({ bounds: map.getBounds() })
				})

				if (ractive.get('initWithCurrentLocation') && navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(position => {
						if (position?.coords) {
							map.setCenter({
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							})
						}
					})
				}

				ractive.set({ map })

				// Listen for changes in the 'places' array, and update the map accordingly
				ractive.observe('places', places => {
					if (!Array.isArray(places)) {
						return
					}

					// Clear out the old pins.
					ractive.fire('removeAllPins', {})

					// For each place, get the icon, name and location.
					const bounds = new google.maps.LatLngBounds()

					const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
					let pins = []

					places.forEach((place, index) => {
						const position = place?.position || place?.geometry?.location
						if (!position) {
							console.error('Returned place contains no geometry or lat/long')
							return
						}

						let icon

						if (place?.selected) {
							icon = {
								path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
								fillColor: "#78c2ad",
								fillOpacity: 1,
								strokeWeight: 0,
								rotation: 0,
								scale: 2,
								anchor: new google.maps.Point(15, 30),
							}
						}

						// Create a marker/pin for each place.
						const marker = new google.maps.Marker({
							map,
							title: place.name,
							label: labels[index % labels.length],
							icon,
							position,
						})

						const infoWindow = new google.maps.InfoWindow({
							content: `<div class="h6">${place.name}</div>`,
						})

						pins.push({ id: place.id, marker, infoWindow })

						marker.addListener('click', ({ latLng }) => {
							const clickedMarker = ractive.get('places').find(({ position }) => {
								return position?.lat === latLng?.lat?.() &&
									position?.lng === latLng?.lng?.()
							})

							//clickedMarker only return the object with 'lat' and 'lng'
							const markerPosition = {
								position: {
									latitude: clickedMarker?.position?.lat,
									longitude: clickedMarker?.position?.lng,
								},
							}

							ractive.fire('markerClicked', {}, clickedMarker) //fire the 'jobClicked' event to select the job from the list
							ractive.fire('showInfoWindow', {}, markerPosition) //the event only take an input with 'latitude' and 'longitude', 'lat' and 'lng' are not accepted
						})

						if (place?.geometry?.viewport) {
							// Only geocodes have viewport.
							bounds.union(place.geometry.viewport)
						} else if (place?.geometry?.location) {
							bounds.extend(place.geometry.location)
						}

						bounds.extend(marker.position)
					})

					map.setCenter(bounds.getCenter())
					map.fitBounds(bounds)
					ractive.set({ pins })
					if (pins.length === 1) {
						map.setZoom(18)
						map.setCenter(pins[0].marker.getPosition())
					}
				})

				const listener = google.maps.event.addListener(map, 'idle', () => {
					const pins = ractive.get('pins')
					if (pins.length === 1) {
						if (map.getZoom() < 18) {
							map.setZoom(18)
							map.setCenter(pins[0].marker.getPosition())
						}
					}
					google.maps.event.removeListener(listener)
				})
			} catch (err) {
				console.error(err)
			}

			ractive.on('bounceMarker', (context, { lat, lng }) => {
				ractive.get('pins').forEach(({ marker }) => {
					if (marker.position.lat() === lat && marker.position.lng() === lng) {
						marker.setAnimation(google.maps.Animation.BOUNCE)
						setTimeout(() => marker.setAnimation(null), 500)
					}
				})
			})

			ractive.on('showInfoWindow', (context, { position }) => {
				const { latitude, longitude } = position
				ractive.get('pins').forEach(({ marker, infoWindow }) => {
					if (marker.position.lat() === latitude && marker.position.lng() === longitude) {
						infoWindow?.open({
							anchor: marker,
							map: ractive.get('map'),
							shouldFocus: false,
						})
					} else {
						infoWindow?.close()
					}
				})
			})

			ractive.on('removeAllPins', _context => {
				ractive.get('pins').forEach(({ marker, infoWindow }) => {
					marker?.setMap?.(null)
					infoWindow?.close?.()
				})
			})

			//ractive.observe('places', v => console.log(v))
			//ractive.observe('pins', v => console.log(v))
		},
	})
}
