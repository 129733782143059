import { loadAdminTagsData } from 'utility/tag-helper'
import component from './Tag.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.tag',
		route: 'tag',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve() {
			const res = await loadAdminTagsData()

			return {
				tags: res.tags,
				allServices: res.services,
			}
		},
	})
}
