export default {
    "name": "EditCardInCustomerWallet",
    "kind": "HoudiniMutation",
    "hash": "1954b8024d1ba4b10569ff95216db72d0b4cd3718bb31d01220a6d3c1bd82cb2",

    "raw": `mutation EditCardInCustomerWallet($cardInfo: editCustomerCard!) {
  editCardInCustomerWallet(cardInfo: $cardInfo) {
    id
    lastFour
    expirationMonth
    expirationYear
    cardType
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "editCardInCustomerWallet": {
                "type": "CustomerWallet",
                "keyRaw": "editCardInCustomerWallet(cardInfo: $cardInfo)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "lastFour": {
                            "type": "NonNegativeInt",
                            "keyRaw": "lastFour",
                            "visible": true
                        },

                        "expirationMonth": {
                            "type": "PositiveInt",
                            "keyRaw": "expirationMonth",
                            "visible": true
                        },

                        "expirationYear": {
                            "type": "PositiveInt",
                            "keyRaw": "expirationYear",
                            "visible": true
                        },

                        "cardType": {
                            "type": "NonEmptyString",
                            "keyRaw": "cardType",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "cardInfo": "editCustomerCard"
        },

        "types": {
            "editCustomerCard": {
                "cardType": "NonEmptyString",
                "expirationMonth": "PositiveInt",
                "expirationYear": "PositiveInt",
                "id": "PositiveInt",
                "lastFour": "NonNegativeInt",
                "name": "String",
                "token": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a7de144de4c1e85dd97a4f6d48834c73c63e4ff0aeb040e8e49fb8db5b7dd961";