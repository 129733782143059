import { getSession } from 'stores/session'
import component from './Notifications.svelte'
import { SettingLookup } from '$houdini'
import { AppContext } from 'client/types/common'
import { load_GetNotificationPreferences } from '$houdini'
import { get } from 'svelte/store'
import { stringToBoolean } from '@isoftdata/utility-string'
import type { NotificationPreference } from './NotificationTable.svelte'
import { canEnableWebPush } from '@isoftdata/client-web-push'

const settingsProperties: Record<string, SettingLookup> = {
	// Customer Email
	jobScheduledEmail: {
		category: 'Notification settings',
		name: 'Send job scheduled emails',
		settingType: 'PREFERENCE',
		defaultValue: 'True',
	},
	jobClaimedEmail: {
		category: 'Notification settings',
		name: 'Send job claimed emails',
		settingType: 'PREFERENCE',
		defaultValue: 'True',
	},
	jobCompletedEmail: {
		category: 'Notification settings',
		name: 'Send job completed emails',
		settingType: 'PREFERENCE',
		defaultValue: 'True',
	},
	jobDelayedEmail: {
		category: 'Notification settings',
		name: 'Send job delayed emails',
		settingType: 'PREFERENCE',
		defaultValue: 'True',
	},
	// Customer Push (placeholder)
	// Provider Email
	jobAvailableEmail: {
		category: 'Notification settings',
		name: 'Provider: Send job available emails',
		settingType: 'PREFERENCE',
		defaultValue: 'True',
	},
	tipReceivedEmail: {
		category: 'Notification settings',
		name: 'Provider: Send tip received emails',
		settingType: 'PREFERENCE',
		defaultValue: 'True',
	},
	// Provider Push
	jobAvailablePush: {
		category: 'Notification settings',
		name: 'Provider: Send job available push notifications',
		settingType: 'PREFERENCE',
		defaultValue: 'False',
	},
	tipReceivedPush: {
		category: 'Notification settings',
		name: 'Provider: Send tip received push notifications',
		settingType: 'PREFERENCE',
		defaultValue: 'False',
	},
}

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.account.notifications',
		route: 'notifications',
		querystringParameters: ['reset'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()
			const isCustomer = !!session.customer
			const isProvider = !!session.provider

			const { GetNotificationPreferences } = await load_GetNotificationPreferences({})
			const { data } = get(GetNotificationPreferences)
			if (!data?.session?.user) {
				throw new Error('No data')
			}
			const notifications = data.session.user

			const customerNotificationSettings: Array<NotificationPreference> = [
				{
					name: 'Scheduled',
					sendEmail: stringToBoolean(notifications.sendJobScheduledEmails.value),
					sendPush: false,
					emailKey: 'jobScheduledEmail',
				},
				{
					name: 'Claimed by a provider',
					sendEmail: stringToBoolean(notifications.sendJobClaimedEmails.value),
					sendPush: false,
					emailKey: 'jobClaimedEmail',
				},
				{
					name: 'Completed',
					sendEmail: stringToBoolean(notifications.sendJobCompletedEmails.value),
					sendPush: false,
					emailKey: 'jobCompletedEmail',
				},
				{
					name: 'Rain delayed',
					sendEmail: stringToBoolean(notifications.sendJobDelayedEmails.value),
					sendPush: false,
					emailKey: 'jobDelayedEmail',
				},
			]

			const providerNotificationSettings: Array<NotificationPreference> = [
				{
					name: 'Available',
					sendEmail: stringToBoolean(notifications.sendJobAvailableEmails.value),
					sendPush: stringToBoolean(notifications.sendJobAvailablePush.value),
					emailKey: 'jobAvailableEmail',
					pushKey: 'jobAvailablePush',
				},
				{
					name: 'Tipped on',
					sendEmail: stringToBoolean(notifications.sendTipReceivedEmails.value),
					sendPush: stringToBoolean(notifications.sendTipReceivedPush.value),
					emailKey: 'tipReceivedEmail',
					pushKey: 'tipReceivedPush',
				},
			]

			return {
				publicVapidKey: data.getServerInformation.publicVapidKey,
				isSavingSetting: false,
				isCustomer,
				isProvider,
				settingsProperties,
				customerNotificationSettings,
				providerNotificationSettings,
				pushNotificationsEnabled: false,
				canEnablePushNotifications: canEnableWebPush(),
			}
		},
	})
}
