<script lang="ts">
	import UserProfile from 'components/UserProfile.svelte'
	import Button from '@isoftdata/svelte-button'
	import PasswordFields from '@isoftdata/svelte-password-fields'

	import { RegisterNewUserAccountStore, graphql, type NewUserAccountInfo, type OptionalNewResidence } from '$houdini'
	import formatE164 from 'utility/format/format-e164-phone-number'
	import type { GetQuoteGoogleAddress } from 'utility/get-quote-helper'
	import { sendEmailVerification } from 'utility/resend-email-verification'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	export let showAccountTypeSelection = false
	export let userAccount: Omit<NewUserAccountInfo, 'password'> = {
		email: '',
		mobile: '',
		firstName: '',
		lastName: '',
	}
	export let requestedRoles: Array<'CUSTOMER' | 'PROVIDER'> = []
	export let firstAddress: GetQuoteGoogleAddress | null = null

	export let emailIsValid = true
	export let phoneNumberIsValid = true

	let password = ''
	let confirmPassword = ''
	let accountSuccessfullyCreated = false
	let isCreatingAccount = false
	let userAccountId: number | undefined
	let userAccountCreationValidators: Record<string, boolean> = {}

	const createAccountMutation: RegisterNewUserAccountStore = graphql`
		mutation RegisterNewUserAccount($newUserAccountInfo: NewUserAccountInfo!, $requestedRoles: [UserAccessType!]) {
			registerNewUserAccount(newUserAccountInfo: $newUserAccountInfo, requestedRoles: $requestedRoles) {
				id
				email
				customer {
					id
				}
			}
		}
	`

	$: passwordsMatch = password === confirmPassword

	async function resendVerificationEmail() {
		try {
			await sendEmailVerification().mutate({
				userId: userAccountId,
			})
			alert(`Verification email has been resent to ${userAccount.email} !`)
		} catch (err: unknown) {
			console.error(err)
			if ((err as { extensions?: { code?: string } })?.extensions?.code === 'EMAIL_VERIFICATION_ERROR_EMAIL_ALREADY_SENT') {
				alert(`A verification email has already been sent to ${userAccount.email}.`)
			} else {
				alert((err as { message: string }).message)
			}
		}
	}

	function isAllowedToCreateAccount(): boolean {
		const { mobile, firstName, lastName } = userAccount

		userAccountCreationValidators = {
			'Email Address Is Invalid': emailIsValid,
			'Missing Phone Number': !mobile || (!!mobile && phoneNumberIsValid), // Optional, but if present, must be valid
			'Missing First Name': !!firstName,
			'Missing Last Name': !!lastName,
			'Missing Password': !!password,
			'Passwords Do Not Match': passwordsMatch,
			'Select at least one account type': requestedRoles?.length > 0,
		}
		return Object.values(userAccountCreationValidators).every(value => value)
	}

	async function createAccount() {
		if (isAllowedToCreateAccount() && !isCreatingAccount) {
			isCreatingAccount = true

			let { email, mobile, firstName, lastName } = userAccount

			// isAllowedToCreateAccount() should prevent this, so satisfy TS
			password ??= ''

			if (mobile) {
				mobile = formatE164(mobile)
			} else {
				mobile = null
			}
			let newAddress: OptionalNewResidence | undefined
			if (firstAddress) {
				newAddress = {
					city: firstAddress?.city,
					state: firstAddress?.state,
					zip: firstAddress?.zip ? firstAddress?.zip.toString() : firstAddress?.zip,
					street: firstAddress?.street,
					googleMapsPlaceId: firstAddress?.googleMapsPlaceId,
					county: firstAddress?.county,
					country: firstAddress?.country,
					latitude: firstAddress?.latitude,
					longitude: firstAddress?.longitude,
				}
			}

			const filter = {
				newUserAccountInfo: {
					email,
					firstName,
					lastName,
					mobile,
					password,
					optionalAddress: newAddress,
				},
				requestedRoles,
			}

			let customerId: number | undefined

			try {
				const { data } = await createAccountMutation.mutate(filter)
				if (!data) {
					throw new Error('No data returned from create account mutation')
				}
				customerId = data.registerNewUserAccount.customer?.id
				userAccountId = data.registerNewUserAccount.id
				accountSuccessfullyCreated = true
				dispatch('accountCreated')
			} catch (err) {
				const theErr = err as Error
				if (theErr?.message) {
					alert(theErr.message)
				}
				console.error(theErr?.message || err)
			} finally {
				isCreatingAccount = false
			}
		} else {
			const validators = userAccountCreationValidators
			let errorFields = new Array<string>()

			for (const [key, value] of Object.entries(validators)) {
				if (!value) {
					errorFields.push(key)
				}
			}
			let errorMessage = errorFields.join(', ')
			console.error('Account Validation Failure:\r\n', errorMessage)
			alert(`Error: ${errorMessage}`)
		}
	}
</script>

{#if accountSuccessfullyCreated}
	<div class="jumbotron text-center">
		<h2>Congratulations</h2>
		<p class="lead">Your account has been created!</p>
		<hr class="my-4" />
		<h6>
			<span>A verification email has been sent to <span class="font-weight-bold">{userAccount.email}</span>.</span> <br />
			<span>Please verify your email to continue.</span>
		</h6>
		<div class="mt-3">
			<Button
				color="primary"
				outline
				on:click={() => resendVerificationEmail()}
			>
				Resend Verification Email
			</Button>
		</div>
	</div>
{:else}
	<form on:submit|preventDefault={() => createAccount()}>
		<UserProfile
			smallSpace
			showOptionalFieldsHint
			showProfilePicture={false}
			bind:userAccount
			bind:emailIsValid
			bind:phoneNumberIsValid
		></UserProfile>
		<hr />
		<PasswordFields
			bind:password
			bind:confirmPassword
		/>
		{#if showAccountTypeSelection}
			<h4 class="mt-3">Account Type</h4>
			<div class="form-group form-check">
				<input
					class="form-check-input"
					id="isCustomer"
					type="checkbox"
					value="CUSTOMER"
					bind:group={requestedRoles}
				/>
				<label
					class="form-check-label"
					for="isCustomer">Customer</label
				>
				<small class="d-block">I want to get my lawn mowed!</small>
			</div>
			<div class="form-group form-check mb-0">
				<input
					class="form-check-input"
					id="isProvider"
					type="checkbox"
					value="PROVIDER"
					bind:group={requestedRoles}
				/>
				<label
					class="form-check-label"
					for="isProvider">Provider</label
				>
				<small class="d-block">I want to mow lawns & earn money!</small>
			</div>
		{/if}
		{#if requestedRoles.length < 1}
			<div class="d-block invalid-feedback">You must select at least one account type</div>
		{/if}
		<div class="mt-2">
			<Button
				id="createAccount"
				type="submit"
				disabled={isCreatingAccount}
				isLoading={isCreatingAccount}>Create Account</Button
			>
		</div>
	</form>
{/if}
