<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import type { LoadRegionHelperServices$result } from '$houdini'
	import { saveServiceOfferHelper } from 'utility/region-helper'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	const timeRangeList = [
		{ value: 'TwentyFourHour', label: `24 Hours` },
		{ value: 'SunriseSunset', label: `Sunrise to Sunset` },
	]

	export let services: LoadRegionHelperServices$result['services']['data']

	export let show: boolean
	export let editingService: boolean
	export let serviceOfferId: number | undefined
	export let chargePerSquareFoot: number | null
	export let chargeMinimum: number | null
	export let chargeFixed: number | null
	export let timeRange: string
	export let serviceId: number | undefined
	export let regionId: number | undefined
	export let serviceOfferErrorMessage: string

	async function saveServiceoffer(): Promise<void> {
		try {
			const updatedServiceOffer = await saveServiceOfferHelper({serviceId, serviceOfferId, chargePerSquareFoot, chargeMinimum, chargeFixed, timeRange, regionId, editingService})
			if (updatedServiceOffer) {
				dispatch('serviceOfferSaved', updatedServiceOffer)
			}
		} catch (error: any) {
			serviceOfferErrorMessage = error.message
			console.error(error)
		} finally {
			show = false
			editingService = false
			serviceOfferId = undefined
			chargePerSquareFoot = null
			chargeMinimum = null
			chargeFixed = null
			timeRange = 'SunriseSunset'
			serviceId = undefined
			regionId = undefined
		}
	}

	$: disableConfirmButton =
		!!serviceId && ((chargePerSquareFoot !== null && chargeMinimum !== null) || chargeFixed !== null)
</script>

<Modal
	bind:show
	title="Service Offer"
	cancelButtonText="Cancel"
	confirmButtonText={editingService ? 'Edit Service Offer' : 'Add Service Offer'}
	confirmButtonDisabled={!disableConfirmButton}
	on:confirm={() => saveServiceoffer()}
	on:cancel={() => (show = false)}
	on:close={() => (show = false)}
>
	<div class="form-row">
		<div class="col-6">
			{#if editingService}
				<Input
					label="Service"
					value={services.find(service => service.id === serviceId)?.name ?? 'Missing Service'}
					type="text"
					disabled
				/>
			{:else}
				<Select
					label="Service"
					bind:value={serviceId}
					showEmptyOption={true}
					emptyOptionText="Select a service"
				>
					{#each services as service}
						<option value={service.id}>{service.name}</option>
					{/each}
				</Select>
			{/if}
		</div>
		<div class="col-6">
			<Input
				label="Charge per Square Foot"
				bind:value={chargePerSquareFoot}
				type="number"
				required={chargeFixed === null}
				validation={{
					value: chargePerSquareFoot,
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				label="Minimum Charge"
				bind:value={chargeMinimum}
				type="number"
				required={chargeFixed === null}
				validation={{
					value: chargeMinimum,
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				label="Fixed Charge"
				bind:value={chargeFixed}
				type="number"
			/>
			{#if chargeFixed}
				<div
					style="font-size: 80%;"
					class="d-block text-info"
				>
					*This field will override other fields.
				</div>
			{/if}
		</div>
		<div class="col-6">
			<Select
				label="Time Range"
				bind:value={timeRange}
				showEmptyOption={false}
			>
				{#each timeRangeList as timeRange}
					<option value={timeRange.value}>{timeRange.label}</option>
				{/each}
			</Select>
		</div>
	</div>
	{#if serviceOfferErrorMessage}
		<div class="alert alert-danger mt-2">{serviceOfferErrorMessage}</div>
	{/if}
</Modal>
