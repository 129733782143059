import template from './generic-badge.html'
import jobStatusMap from 'utility/job-status-map'
import jobClaimStatusMap from 'utility/job-claim-status-map'

export default function genericBadgeComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				type: '',
				text: '',
				class: '',
				isJobClaim: false,
			}
		},
		attributes: {
			required: [ 'type' ],
			optional: [ 'isJobClaim', 'text', 'border', 'class' ],
		},
		computed: {
			computedClass() {
				const ractive = this
				const type = ractive.get('type')
				const baseClass = ractive.get('class')
				if (type == "SERVICE") {
					return `badge badge-info ellipsis ${baseClass}`
				} else if ((type == 'LOCATION') || (type == 'REGION')) {
					return `badge badge-danger mr-1 mb-1 ${baseClass}`
				} else if (ractive.isJobClaim == true) {
					return `badge badge-${ jobClaimStatusMap[type].class } mb-1 ${baseClass}`
				} else {
					return `badge badge-${ jobStatusMap[type].class } mb-1 ${baseClass}`
				}
			},
			computedText() {
				const ractive = this
				const type = ractive.get('type')
				if ((type == 'LOCATION') || (type == 'REGION') || (type == 'SERVICE')) {
					return ractive.get('text')
				} else if (ractive.isJobClaim == true) {
					return jobClaimStatusMap[type].label
				} else {
					return jobStatusMap[type].label
				}
			},
			computedIconClass() {
				const ractive = this
				const type = ractive.get('type')
				if (type == 'SERVICE') {
					return ''
				} else if (type == 'LOCATION') {
					return `fa-solid fa-location-dot`
				} else if (type == 'REGION') {
					return `fa-solid fa-city`
				} else if (ractive.get('isJobClaim') == true) {
					return jobClaimStatusMap[type].iconClass
				} else {
					return jobStatusMap[type].iconClass
				}
			},
			computedBorderClass() {
				const ractive = this
				const type = ractive.get('type')
				if ((type == 'LOCATION') || (type == 'REGION')) {
					return "selectedJobId === id"
				} else if (ractive.get('border')) {
					return ractive.get('border')
				}
			},
		},
	})
}
