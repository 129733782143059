export default {
    "name": "UpdateTagPricingMutation",
    "kind": "HoudiniMutation",
    "hash": "11755f37c9ee2da8b7bd22a027e9dd57ab1f2483571d780dfea2d3c8c92b98e9",

    "raw": `mutation UpdateTagPricingMutation($tagPricing: EditTagPricing!) {
  editTagPricing(tagPricing: $tagPricing) {
    ...AdminTagPricingData
    id
  }
}

fragment AdminTagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  service {
    name
    id
  }
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "editTagPricing": {
                "type": "TagPricing",
                "keyRaw": "editTagPricing(tagPricing: $tagPricing)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "pricingType": {
                            "type": "PricingType",
                            "keyRaw": "pricingType",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "postPriceAdjustment": {
                            "type": "Boolean",
                            "keyRaw": "postPriceAdjustment",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "AdminTagPricingData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tagPricing": "EditTagPricing"
        },

        "types": {
            "EditTagPricing": {
                "postPriceAdjustment": "Boolean",
                "priceAdjustment": "String",
                "pricingType": "PricingType",
                "tagPricingId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5c18e3483c16fc2c9dc61fa6ac54c2d59106965ec6e6fbf27c2c3d0e174520aa";