<script lang="ts">
	import { format } from 'date-fns'
	import type { JobClaimWithMarker } from 'utility/claimed-jobs-helper'

	import GenericBadge from 'components/GenericBadge.svelte'
	import ProviderJobListResidenceStreet from 'components/ProviderJobListResidenceStreet.svelte'
	import Icon from '@isoftdata/svelte-icon'

	export let job: JobClaimWithMarker['job']
	export let jobHasPassedScheduledTime: boolean
</script>

<div class="d-flex mr-3 flex-column align-items-start">
	<p
		class="mb-0"
		style="font-size: xx-small;"
	>
		Earnings
	</p>
	<h5 class="mb-1">${job.transaction?.cost ?? 'Err'}</h5>
	{#if job.jobStatus !== 'COMPLETED'}
		<p
			class="mb-0"
			style="font-size: xx-small;"
		>
			Scheduled for
		</p>
		<div class="d-flex align-items-center">
			<h5 class="mb-0 text-nowrap">{format(job.scheduledAt, 'h aa')}</h5>
			{#if jobHasPassedScheduledTime}
				<Icon
					icon="bell-exclamation"
					class="text-danger fa-shake icon-slow-animation ml-1"
					size="lg"
				/>
			{/if}
		</div>
	{/if}
	{#if job.transaction?.tip}
		<p
			class="mb-0"
			style="font-size: xx-small;"
		>
			Tip
		</p>
		<h5 class="mb-0">${job.transaction?.tip}</h5>
	{/if}
</div>
<div class="ellipsis">
	<div class="d-flex flex-wrap service-badge mt-2">
		<GenericBadge
			type="SERVICE"
			text={job.service.name}
		/>
	</div>
	<div class="d-flex flex-wrap mt-2 mb-1">
		{#if job.jobStatus !== 'COMPLETED'}
			<GenericBadge
				type="LOCATION"
				text={job.markerLabel.toString()}
			/>
		{/if}

		<GenericBadge
			type="REGION"
			text={job.residence.region.name}
		/>
		<GenericBadge
			type="SQFT"
			text={`${Math.round(job.residence.displayLawnSquareFootage / 100) * 100} sqft`}
		/>
		{#if job.answers && job.answers?.length > 0}
			<span class="badge badge-danger mb-1 mr-1"><i class="fa-solid fa-circle-exclamation fa-beat"></i> {job.answers.length} Add-Ons</span>
		{/if}
		{#if job.files && job.files?.length > 0}
			<span class="badge badge-info mb-1"><i class="fa-solid fa-image"></i></span>
		{/if}
	</div>
	<ProviderJobListResidenceStreet
		street={job.residence.street}
		customerFirstJob={job.customerFirstJob}
	/>
</div>

<style>
	@media (min-width: 768px) {
		.service-badge {
			font-size: 20px;
		}
	}
</style>
