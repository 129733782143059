import template from './job-claim-info.html'
import makeAttachmentComponent from '@isoftdata/attachment'
import formatImageFileUrl from 'utility/format/format-image-file-url'
import { attachFilesToJob, detachFilesFromJob, getMaxImageDimensions } from 'utility/manage-image-files'
import jobClaimStatusMap from 'utility/job-claim-status-map'

//Ractive components
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeProviderProfilePicture from './provider-profile-picture'
import makeImageViewerModal from '@isoftdata/image-viewer-modal'

export default function createJobClaimInfoComponent(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				jobClaim: {},
				jobClaimStatusMap,
				job: {},
				adminView: false,
				adminPhotoAttachmentModal: { show: false },
				photoViewerModal: { show: false },
				imageButtonText: '',
			}
		},
		attributes: {
			required: [ 'jobClaim' ],
			optional: [ 'job', 'adminView' ],
		},
		components: {
			itButton: makeButton(),
			itModal: makeModal(),
			providerProfilePicture: makeProviderProfilePicture(),
			itImageViewerModal: makeImageViewerModal(),
			itAttachment: makeAttachmentComponent({
				saveFileAndAssociate: (...args) => attachFilesToJob(mediator, ...args),
				deleteFileAssociation: (...args) => detachFilesFromJob(mediator, ...args),
				handleError: err => {
					if (typeof err === 'object' && err !== null && typeof err.message === 'string') {
						let userMessage = 'There was an error uploading the file. Please try again.'
						if (err.extensions?.code === 'ER_DUP_ENTRY') {
							userMessage = 'The file(s) you are trying to upload already exist. Please try again with a different file(s)'
						}
						mediator.call('showError', err, { message: userMessage, title: 'Error Uploading File' })
					}
				},
			}),
		},
		computed: {
			computedProfilePictureUrl() {
				const filePath = this.get('jobClaim.provider.profilePictureFile.path')

				if (filePath) {
					return formatImageFileUrl(filePath)
				}
			},
		},
		async openPhotoModal() {
			const ractive = this
			const job = ractive.get('job')
			const adminView = ractive.get('adminView')
			const jobId = ractive.get('job.id')

			if (adminView) {
				//if user is an admin, allow them to upload images/pictures
				ractive.set({
					adminPhotoAttachmentModal: {
						fileAssociation: {
							jobId,
							imageFileType: 'OTHER',
						},
						maxImageDimensions: await getMaxImageDimensions(mediator),
						show: true,
					},
				})
			} else {
				//only allow user to view the images/pictures
				ractive.set({
					photoViewerModal: {
						photoFilePaths: job.files.map(metadata => formatImageFileUrl(metadata.file.path)),
						selectedPhotoIndex: 0,
						show: true,
					},
				})
			}
		},
		oninit() {
			const ractive = this

			ractive.observe('job.files', jobFiles => {
				ractive.set({
					imageButtonText: `${jobFiles.length > 0 ? 'View' : 'Add'} Photos`,
				})
			})
		},
	})
}
