import { getProviderJobClaims, type ProviderJobClaimStatus } from './graphql/provider-job-claims-query'
import { getComputedJobClaim } from 'utility/get-computed-job-claim'
import { getComputedJob } from 'utility/get-computed-job'
import {
	graphql,
	LoadJobClaimActivityStore,
	JobForPhotoUploadStore,
	type LoadProviderJobClaims$result,
	CompleteJobClaimEditStore,
	ClockIntoJobClaimStore,
	ClockOutOfJobClaimStore,
	UpdateDelayJobClaimStore,
} from '$houdini'
import { getLabelForMarker } from './google-maps-helper'

export type JobClaimWithMarker = ReturnType<typeof getJobsWithMarkers>[number]

export async function loadJobClaimsForDate(providerId: number) {
	// The following commented out stuff is for whenever Job Claims need filters in the future
	// const selectedDate = new Date(`${dateString}T00:00:00`)

	// const startTime = startOfDay(selectedDate)
	// const startDay = isToday(selectedDate) ? sub(selectedDate, { weeks: 4 }) : selectedDate
	// const endTime = endOfDay(startTime)

	const statuses: ProviderJobClaimStatus[] = ['IN_PROGRESS', 'ACCEPTED']
	const scheduledAtFrom = null //startDay
	const scheduledAtTo = null //endTime

	return await getProviderJobClaims(providerId, statuses, scheduledAtFrom, scheduledAtTo)
}

export function formatJobClaims(jobClaims: LoadProviderJobClaims$result['jobClaims']['data']) {
	return jobClaims.map(jobClaim => {
		const computedJobClaim = getComputedJobClaim(jobClaim, undefined)

		return {
			...computedJobClaim,
			job: getComputedJob(computedJobClaim.job),
		}
	})
}

export function getJobsWithMarkers(formattedJobClaims: ReturnType<typeof formatJobClaims>) {
	return (
		formattedJobClaims.map((jobClaim, index) => {
			return {
				...jobClaim,
				markerLabel: getLabelForMarker(index),
				position: jobClaim.job.residence.latitude && jobClaim.job.residence.longitude ? { latitude: jobClaim.job.residence.latitude, longitude: jobClaim.job.residence.longitude } : undefined,
				job: {
					...jobClaim.job,
					scheduledAt: jobClaim.scheduledAt,
					markerLabel: getLabelForMarker(index),
				},
			}
		}) ?? []
	)
}

export async function loadFormattedJobClaimsWithMarkers(providerId: number): Promise<JobClaimWithMarker[]> {
	const jobClaims = await loadJobClaimsForDate(providerId)

	const formattedJobClaims = formatJobClaims(jobClaims)
	const jobsWithMarkers = getJobsWithMarkers(formattedJobClaims)

	return jobsWithMarkers
}

const jobClaimActivityQuery: LoadJobClaimActivityStore = graphql`
	query LoadJobClaimActivity($jobClaimId: PositiveInt!) {
		jobClaim(id: $jobClaimId) {
			id
			jobClaimStatus
			scheduledAt
			cancellationReason
			allActivity {
				id
				elapsedTime
				end
				start
			}
			job {
				id
				requestedSchedule
				specialInstructions
				residence {
					latitude
					longitude
					answers {
						tag {
							id
							name
						}
					}
				}
				service {
					name
					maximumDaysToPerform
				}
				answers {
					id
					answer
				}
			}
		}
	}
`

export async function loadJobClaimActivity(jobClaimId: number) {
	const { data } = await jobClaimActivityQuery.fetch({
		variables: {
			jobClaimId,
		},
	})

	if (!data?.jobClaim) {
		return null
	}
	return data.jobClaim
}

const loadJobForPhotoUploadQuery: JobForPhotoUploadStore = graphql`
	query JobForPhotoUpload($jobId: PositiveInt!) {
		job(id: $jobId) {
			files {
				fileId
				public
				rank
				imageFileType
				file {
					name
					created
					mimeType
					path
				}
			}
		}
	}
`

export async function loadJobForPhotoUpload(jobId: number) {
	const { data } = await loadJobForPhotoUploadQuery.fetch({
		variables: {
			jobId,
		},
	})

	if (!data?.job) {
		return null
	}
	return data.job.files
}

const completeJobClaimMutation: CompleteJobClaimEditStore = graphql`
	mutation CompleteJobClaimEdit($completedClaim: CompleteJobClaim!) {
		completeJobClaim(completedClaim: $completedClaim) {
			id
			jobClaimStatus
			scheduledAt
			cancellationReason
			allActivity {
				id
				elapsedTime
				end
				start
			}
			internalNotes
		}
	}
`

export async function completeJobClaim(jobClaimId: number, internalNotes: string | null) {
	const { data } = await completeJobClaimMutation.mutate({
		completedClaim: {
			id: jobClaimId,
			internalNotes,
		},
	})

	if (!data?.completeJobClaim) {
		return null
	}
	return data.completeJobClaim
}

const clockInMutation: ClockIntoJobClaimStore = graphql`
	mutation ClockIntoJobClaim($jobClaimId: PositiveInt!) {
		clockIntoJobClaim(jobClaimId: $jobClaimId) {
			id
			start
			end
			elapsedTime
		}
	}
`

const clockOutMutation: ClockOutOfJobClaimStore = graphql`
	mutation ClockOutOfJobClaim($jobClaimId: PositiveInt!) {
		clockOutOfJobClaim(jobClaimId: $jobClaimId) {
			id
			start
			end
			elapsedTime
		}
	}
`

export type ClockInOrOutResponse = {
	id: number
	start: Date
	end: Date | null
	elapsedTime: number
}

export async function clockInOrOut(jobClaimId: number, clockIn: boolean): Promise<ClockInOrOutResponse | null> {
	if (!clockIn) {
		const { data } = await clockInMutation.mutate({ jobClaimId })
		if (!data?.clockIntoJobClaim) {
			return null
		}
		return data.clockIntoJobClaim
	} else {
		const { data } = await clockOutMutation.mutate({ jobClaimId })
		if (!data?.clockOutOfJobClaim) {
			return null
		}
		return data.clockOutOfJobClaim
	}
}

const delayJobClaimMutation: UpdateDelayJobClaimStore = graphql`
	mutation UpdateDelayJobClaim($delayedClaim: DelayJobClaim!) {
		delayJobClaim(delayedClaim: $delayedClaim) {
			id
		}
	}
`

export async function delayClaim(jobClaimId: number, newScheduledAtDate: Date, reason: string | null) {
	const { data } = await delayJobClaimMutation.mutate({
		delayedClaim: {
			id: jobClaimId,
			newScheduledAt: newScheduledAtDate,
			reason: reason ?? '',
		},
	})

	if (!data?.delayJobClaim) {
		return null
	}

	return data.delayJobClaim.id
}
