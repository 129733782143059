import component from './PasswordReset.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'password-reset',
		route: 'password-reset',
		querystringParameters: ['account', 'recoveryCode'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		resolve(_data, { account, recoveryCode }) {
			if (!account || !recoveryCode) {
				throw {
					redirectTo: {
						name: 'login',
					},
				}
			}

			return Promise.resolve({
				account,
				recoveryCode,
				password: '',
				confirmPassword: '',
				isChangingPassword: false,
			})
		},
	})
}
