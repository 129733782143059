import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/UpcomingOrders'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class UpcomingOrdersStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "UpcomingOrdersStore",
			variables: false,
		})
	}
}

export async function load_UpcomingOrders(params) {
	await initClient()

	const store = new UpcomingOrdersStore()

	await store.fetch(params)

	return {
		UpcomingOrders: store,
	}
}
