import template from './admin-user-table.html'
import toTitleCase from 'to-title-case'
import formatPhoneNumber from 'utility/format/format-phone-number'
import getComputedUserAccount from 'utility/get-computed-user-account'
import { userAccountFields } from 'utility/graphql/user-accounts-query.js'
import formatImageFileUrl from 'utility/format/format-image-file-url'
import { klona } from 'klona'

//Ractive components
import makeTable from '@isoftdata/table'
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeCheckboxButton from '@isoftdata/checkbox-button'
import makeProviderProfilePicture from './provider-profile-picture'

const defaultProfilePictureModalState = Object.freeze({
	show: false,
	providerId: null,
	profilePictureId: null,
	providerProfilePicturePath: null,
})

export default function createAdminUserAccountTable(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				//initial data here
				userAccounts: null,
				selectedUserAccountId: null,
				accountTypeFilter: '',
				accountStatusFilter: '',
				readonly: false,
				absoluteDateTimeFormatter: date => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date),
				formatPhoneNumber,
				filterEnabled: 'true',
				showFilterLabel: 'true',
				filterLabel: 'Filter Users',
				detachAndViewProfilePictureModal: klona(defaultProfilePictureModalState),
				isLoading: false,
			}
		},
		css: `
			.profile-picture {
				width: 100% !important;
				height: 100% !important;
				max-width: 100px !important;
				max-height: 100px !important;
				margin: auto;
				border-radius: 100%;
				position: relative;
			}
			.camera-icon {
				width: 28px;
				height: 25px;
				border-radius: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
			  }
		`,
		components: {
			itTable: makeTable(),
			itSelect: makeSelect({ twoway: true, lazy: false }),
			itButton: makeButton(),
			itModal: makeModal(),
			itCheckboxButton: makeCheckboxButton({ twoway: false }),
			providerProfilePicture: makeProviderProfilePicture(),
		},
		attributes: {
			required: [ 'userAccounts' ],
			optional: [ 'accountTypeFilter', 'selectedUserAccountId', 'accountStatusFilter', 'readonly', 'filterEnabled', 'showFilterLabel', 'filterLabel' ],
		},
		computed: {
			displayUserAccounts() {
				const accountTypeFilter = this.get('accountTypeFilter')
				return this.get('userAccounts')
					.map(userAccount => {
						const isCustomer = !!userAccount.customer
						const isProvider = !!userAccount.provider

						return {
							...userAccount,
							providerProfilePicturePath: userAccount?.provider?.profilePictureFile ? formatImageFileUrl(userAccount.provider.profilePictureFile.path) : null,
							isCustomer,
							isProvider,
							status: toTitleCase(userAccount.status),
							providerStatus: userAccount?.provider ? toTitleCase(userAccount?.provider?.status) : 'N/A',
						}
					})
					.filter(({ isCustomer, isProvider }) => {
						if (accountTypeFilter === '') {
							return true
						}

						if (accountTypeFilter === 'BOTH') {
							return isCustomer && isProvider
						} else if (accountTypeFilter === 'PROVIDER') {
							return isProvider
						} else if (accountTypeFilter === 'CUSTOMER') {
							return isCustomer
						}
					})
			},
			columns() {
				let columns = [
					{ property: 'fullName', name: 'Name', defaultSortColumn: true },
					{ property: 'email', name: 'Email' },
					{ property: 'mobile', name: 'Mobile' },
					{ property: 'created', name: 'Created' },
					{ property: 'status', name: 'Status' },
					{ property: 'providerStatus', name: 'Provider Status' },
				]

				if (this.get('accountTypeFilter') === '') {
					columns.push({ property: 'accountType', name: 'Account Type' })
				}

				return columns
			},
		},
		viewProfilePicture({ provider, providerProfilePicturePath }) {
			if (providerProfilePicturePath !== null) {
				this.set('detachAndViewProfilePictureModal', {
					show: true,
					providerId: provider?.id,
					profilePictureId: provider?.profilePictureFile?.id,
					providerProfilePicturePath,
				})
			}
		},
		async detachProviderProfilePicture({ providerId, profilePictureId }) {
			this.set({ isLoading: true })
			if (confirm(`Are you sure you want to delete this profile picture?`)) {
				const detachProviderProfilePicture = `#graphql
					mutation DetachFilesFromProvider($providerId: PositiveInt!, $fileId: PositiveInt!) {
						detachFilesFromProvider(providerId: $providerId, fileId: $fileId)
					}
				`

				try {
					await mediator.call('apiFetch', detachProviderProfilePicture, {
						providerId,
						fileId: profilePictureId,
					})

					const userAccounts = this.get('userAccounts')
					const userAccountIndex = userAccounts.findIndex(userAccount => userAccount.provider?.id === providerId)

					this.set({ detachAndViewProfilePictureModal: klona(defaultProfilePictureModalState) })
					this.set(`userAccounts[${userAccountIndex}].provider.profilePictureFile`, null)
				} catch (err) {
					console.error(err?.message)
				} finally {
					this.set({ isLoading: false })
				}
			}
		},
		async reactivateUserAccount(user) {
			if (confirm(`Are you sure you want to reactivate ${user.fullName}?`)) {
				const reactivateSelectedUser = `#graphql
					mutation UserAccountReactivate($userAccountId: Float!) {
						  userAccountReactivate(userAccountId: $userAccountId) {
							${userAccountFields}
						}
					}
				`

				try {
					const { userAccountReactivate: accountStatus } = await mediator.call('apiFetch', reactivateSelectedUser, {
						userAccountId: user.id,
					})

					this.upsert('userAccounts', 'id', getComputedUserAccount(accountStatus))
				} catch (err) {
					console.error(err?.message || 'An unknown error occurred.')
				}
			}
		},
		async deactivateUserAccount(user) {
			if (confirm(`Are you sure you want to deactivate ${user.fullName}?`)) {
				const deactivateSelectedUser = `#graphql
					mutation UserAccountDeactivate($lockNotes: String!, $userAccountId: Float!) {
						userAccountDeactivate(lockNotes: $lockNotes, userAccountId: $userAccountId) {
							${userAccountFields}
						}
					}
				`

				try {
					const supportEmail = 'support@lawnhiro.com'
					const { userAccountDeactivate: accountStatus } = await mediator.call('apiFetch', deactivateSelectedUser, {
						lockNotes: `Please contact ${supportEmail} to reactivate your account.`,
						userAccountId: user.id,
					})

					this.upsert('userAccounts', 'id', getComputedUserAccount(accountStatus))
				} catch (err) {
					console.error(err?.message || 'An unknown error occurred.')
				}
			}
		},
	})
}
