import { LoadResidencesStore, type LoadResidences$input, type LoadResidences$result } from '$houdini/index'
import { graphql } from '$houdini/runtime'

export type Residence = LoadResidences$result['residences']['data'][number]

const residencesQuery: LoadResidencesStore = graphql`
	query LoadResidences($filter: ResidenceFilter, $pagination: PaginatedInput) {
		residences(filter: $filter, pagination: $pagination) {
			data {
				id
				street
				city
				state
				zip
				regionId
				estimatedLawnSquareFootage
				effectiveLawnSquareFootage
				customer {
					userAccount {
						fullName
						email
					}
				}
				answers {
					id
					answer
				}
			}
			info {
				totalItems
				pageSize
				pageNumber
			}
		}
	}
`

export async function getResidences(variables?: LoadResidences$input) {
	const res = await residencesQuery.fetch({
		variables: {
			filter: variables?.filter ?? {
				residenceStatus: 'ACTIVE',
			},
			pagination: variables?.pagination ?? {
				pageNumber: 1,
				pageSize: 0,
			},
		},
	})

	if (!res.data) {
		throw new Error('No data returned')
	}

	return res
}

export default residencesQuery
