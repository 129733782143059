<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import { tagPricingTypeList } from 'utility/tag-pricing-types'
	import { updateTagPricing, newTagPricing, type PricingType, type AdminTagService } from 'utility/tag-helper'

	import { createEventDispatcher, onMount } from 'svelte'

	const dispatch = createEventDispatcher()

	export let show: boolean = false
	export let editing: boolean = false
	export let serviceTypesList: AdminTagService[] = []
	export let tagId: number | null = null
	export let tagPricingId: number | null = null
	export let pricingType: PricingType = 'PERCENT'
	export let priceAdjustment: number = 0
	export let postPriceAdjustment: boolean = false
	export let serviceId: number | null = null

	let errorMessage: string = ''

	onMount(() => {
		if (editing) {
			if (pricingType === 'PERCENT') {
				const element = document.getElementById('price-adjustment-percent-input')
				if (element instanceof HTMLInputElement) {
					element.focus()
				}
			} else {
				const element = document.getElementById('price-adjustment-flat-input')
				if (element instanceof HTMLInputElement) {
					element.focus()
				}
			}
		} else {
			const element = document.getElementById('tag-pricing-modal-service-select')
			if (element instanceof HTMLInputElement) {
				element.focus()
			}
		}
	})

	async function saveTagPricing() {
		if (!pricingType || !priceAdjustment || (postPriceAdjustment && priceAdjustment > 0) || !serviceId) {
			errorMessage = 'Please fill in all required fields'
			return
		}
		try {
			if (editing) {
				if (!tagPricingId) {
					throw new Error('Tag pricing ID is required')
				}
				const res = await updateTagPricing({
					id: tagPricingId,
					pricingType,
					priceAdjustment,
					postPriceAdjustment,
				})
				dispatch('tagPricingUpdated', { ...res, tagId })
			} else {
				if (!tagId) {
					throw new Error('Tag ID is required')
				}
				const res = await newTagPricing({
					serviceId,
					pricingType,
					priceAdjustment,
					postPriceAdjustment,
					tagId,
				})
				dispatch('tagPricingAdded', { ...res, tagId })
			}
			show = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			errorMessage = error.message
		}
	}
</script>

<Modal
	bind:show
	title={editing ? 'Update Tag Price' : 'New Tag Price'}
	cancelButtonText="Cancel"
	confirmButtonText={editing ? 'Done' : 'Create Tag Price'}
	confirmButtonDisabled={!pricingType || !priceAdjustment || (postPriceAdjustment && priceAdjustment > 0) || !serviceId}
	on:confirm={() => saveTagPricing()}
	on:close={() => (show = false)}
	on:backdrop={() => (show = false)}
>
	<div class="form-row">
		<div class="col-12">
			<Select
				id="tag-pricing-modal-service-select"
				title="Service"
				label="Type of Service"
				bind:value={serviceId}
				disabled={editing}
				emptyText="Select a service"
			>
				{#each serviceTypesList as service}
					<option value={service.id}>{service.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Select
				id="tag-pricing-modal-pricing-type-select"
				title="Pricing Type"
				label="Pricing Type"
				bind:value={pricingType}
				showEmptyOption={false}
			>
				{#each tagPricingTypeList as type}
					<option value={type.value}>{type.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			{#if pricingType === 'PERCENT'}
				<Input
					id="price-adjustment-percent-input"
					title="Percentage Amount"
					type="text"
					placeholder="0"
					label="Percentage Amount"
					bind:value={priceAdjustment}
					validation={{
						validator: value => {
							if (postPriceAdjustment) {
								if (typeof value === 'string') {
									return parseInt(value, 10) < 0
								} else if (typeof value === 'number') {
									return value < 0
								} else {
									return false
								}
							} else {
								return true
							}
						},
						value: priceAdjustment,
					}}
				/>
			{:else}
				<CurrencyInput
					required
					id="price-adjustment-flat-input"
					title="Flat Amount"
					label="Flat Amount"
					value={priceAdjustment.toString()}
					inputmode="decimal"
					validation={{
						validator: value => {
							if (postPriceAdjustment) {
								if (typeof value === 'string') {
									return parseInt(value, 10) < 0
								} else if (typeof value === 'number') {
									return value < 0
								} else {
									return false
								}
							} else {
								return true
							}
						},
						value: priceAdjustment,
					}}
					on:change={e => {
						priceAdjustment = parseInt(e.detail.value, 10)
					}}
				/>
			{/if}
			{#if postPriceAdjustment && priceAdjustment > 0}
				<small class="form-text text-danger"> Amount must be negative </small>
			{/if}
		</div>
		<div class="col-12">
			<div class="form-group mb-0">
				<label
					class="col-form-label w-100"
					for="tag-pricing-modal-post-price-adjustment-checkbox"
				>
					Does this price apply after subtotal?
				</label>
				<Checkbox
					id="tag-pricing-modal-post-price-adjustment-checkbox"
					title="Post Price Adjustment"
					type="radio"
					showLabel={false}
					bind:checked={postPriceAdjustment}
					trueLabel="Yes"
					falseLabel="No"
					radioButtonColor="primary"
				/>
			</div>
		</div>
	</div>
	<small class="text-danger"> Enter a negative value for a discount. Subscription discounts will always apply to the final price. </small>
	<div class="col-12">
		{#if errorMessage}
			<div
				style="opacity: 1;"
				class="signin-message-box text-center alert alert-danger"
				role="alert"
			>
				<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{errorMessage}</strong>
			</div>
		{/if}
	</div>
</Modal>
