export default {
    "name": "LoadAdminOnboardingApplications",
    "kind": "HoudiniQuery",
    "hash": "82ab5c8939e6cc402c40c50626f7a891161e4b07039ffe97f3f2a0774ebe9463",

    "raw": `query LoadAdminOnboardingApplications($servicesFilter: ServiceFilter, $applicationFilter: OnboardingApplicationFilter, $pagination: PaginatedInput, $orderBy: [ProviderApplicationOrderBy!], $regionOrderBy: [RegionOrderBy!]) {
  services(filter: $servicesFilter, pagination: {pageNumber: 1, pageSize: 0}) {
    data {
      id
      name
      codeName
      description
      active
    }
  }
  regions(pagination: {pageNumber: 1, pageSize: 0}, orderBy: $regionOrderBy) {
    data {
      id
      name
      status
    }
  }
  getUniqueOnboardingFilters {
    cities
    states
    zips
  }
  onboardingApplications: providerOnboardingApplications(
    filter: $applicationFilter
    pagination: $pagination
    orderBy: $orderBy
  ) {
    ...ProviderOnboardingApplicationFields
  }
}

fragment ProviderOnboardingApplicationFields on ProviderOnboardingApplicationResponse {
  data {
    id
    providerId
    status
    created
    completed
    driversLicensePictureFile {
      path
      id
    }
    driverLicenseStatus
    driverLicenseRejectMessage
    transportationPictureFile {
      path
      id
    }
    transportationStatus
    transportationRejectMessage
    acceptedProviderAgreement
    hasLiabilityInsurance
    inWaitlist
    rejectMessage
    serviceApplications: providerServiceApplications {
      id
      serviceId
      status
      rejectMessage
      service {
        name
        id
      }
      equipmentPictureFile {
        path
        id
      }
    }
    provider {
      id
      status
      city
      country
      county
      state
      street
      zip
      shirtSize
      homeRegion {
        id
        name
        status
      }
      profilePictureFile {
        path
        id
      }
      userAccount {
        id
        firstName
        lastName
        fullName
        email
        created
        mobile
      }
    }
  }
  info {
    totalItems
    totalPages
    pageSize
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(filter: $servicesFilter, pagination: {pageNumber: 1, pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "regions": {
                "type": "RegionResponse",
                "keyRaw": "regions(orderBy: $regionOrderBy, pagination: {pageNumber: 1, pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Region",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "getUniqueOnboardingFilters": {
                "type": "UniqueOnboardingFilters",
                "keyRaw": "getUniqueOnboardingFilters",

                "selection": {
                    "fields": {
                        "cities": {
                            "type": "String",
                            "keyRaw": "cities",
                            "nullable": true,
                            "visible": true
                        },

                        "states": {
                            "type": "String",
                            "keyRaw": "states",
                            "nullable": true,
                            "visible": true
                        },

                        "zips": {
                            "type": "String",
                            "keyRaw": "zips",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "onboardingApplications": {
                "type": "ProviderOnboardingApplicationResponse",
                "keyRaw": "onboardingApplications(filter: $applicationFilter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProviderApplication",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "providerId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "providerId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderApplicationStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "completed": {
                                        "type": "DateTime",
                                        "keyRaw": "completed",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "driversLicensePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "driversLicensePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "driverLicenseStatus": {
                                        "type": "PictureFileStatus",
                                        "keyRaw": "driverLicenseStatus",
                                        "visible": true
                                    },

                                    "driverLicenseRejectMessage": {
                                        "type": "String",
                                        "keyRaw": "driverLicenseRejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "transportationPictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "transportationPictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "transportationStatus": {
                                        "type": "PictureFileStatus",
                                        "keyRaw": "transportationStatus",
                                        "visible": true
                                    },

                                    "transportationRejectMessage": {
                                        "type": "String",
                                        "keyRaw": "transportationRejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "acceptedProviderAgreement": {
                                        "type": "Boolean",
                                        "keyRaw": "acceptedProviderAgreement",
                                        "visible": true
                                    },

                                    "hasLiabilityInsurance": {
                                        "type": "Boolean",
                                        "keyRaw": "hasLiabilityInsurance",
                                        "visible": true
                                    },

                                    "inWaitlist": {
                                        "type": "Boolean",
                                        "keyRaw": "inWaitlist",
                                        "visible": true
                                    },

                                    "rejectMessage": {
                                        "type": "String",
                                        "keyRaw": "rejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "serviceApplications": {
                                        "type": "ProviderServiceApplication",
                                        "keyRaw": "serviceApplications",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderServiceApplicationStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "rejectMessage": {
                                                    "type": "String",
                                                    "keyRaw": "rejectMessage",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "service": {
                                                    "type": "Service",
                                                    "keyRaw": "service",

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "equipmentPictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "equipmentPictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "provider": {
                                        "type": "Provider",
                                        "keyRaw": "provider",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "city": {
                                                    "type": "String",
                                                    "keyRaw": "city",
                                                    "visible": true
                                                },

                                                "country": {
                                                    "type": "String",
                                                    "keyRaw": "country",
                                                    "visible": true
                                                },

                                                "county": {
                                                    "type": "String",
                                                    "keyRaw": "county",
                                                    "visible": true
                                                },

                                                "state": {
                                                    "type": "String",
                                                    "keyRaw": "state",
                                                    "visible": true
                                                },

                                                "street": {
                                                    "type": "String",
                                                    "keyRaw": "street",
                                                    "visible": true
                                                },

                                                "zip": {
                                                    "type": "String",
                                                    "keyRaw": "zip",
                                                    "visible": true
                                                },

                                                "shirtSize": {
                                                    "type": "String",
                                                    "keyRaw": "shirtSize",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "homeRegion": {
                                                    "type": "Region",
                                                    "keyRaw": "homeRegion",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "status": {
                                                                "type": "RegionStatus",
                                                                "keyRaw": "status",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "profilePictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "profilePictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "userAccount": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "userAccount",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "firstName": {
                                                                "type": "String",
                                                                "keyRaw": "firstName",
                                                                "visible": true
                                                            },

                                                            "lastName": {
                                                                "type": "String",
                                                                "keyRaw": "lastName",
                                                                "visible": true
                                                            },

                                                            "fullName": {
                                                                "type": "String",
                                                                "keyRaw": "fullName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "email": {
                                                                "type": "EmailAddress",
                                                                "keyRaw": "email",
                                                                "visible": true
                                                            },

                                                            "created": {
                                                                "type": "DateTime",
                                                                "keyRaw": "created",
                                                                "visible": true
                                                            },

                                                            "mobile": {
                                                                "type": "PhoneNumber",
                                                                "keyRaw": "mobile",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    },

                                    "totalPages": {
                                        "type": "Int",
                                        "keyRaw": "totalPages",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ProviderOnboardingApplicationFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "servicesFilter": "ServiceFilter",
            "applicationFilter": "OnboardingApplicationFilter",
            "pagination": "PaginatedInput",
            "orderBy": "ProviderApplicationOrderBy",
            "regionOrderBy": "RegionOrderBy"
        },

        "types": {
            "ServiceFilter": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "regionId": "PositiveInt"
            },

            "OnboardingApplicationFilter": {
                "city": "String",
                "createdFrom": "DateTime",
                "createdTo": "DateTime",
                "hideWaitlist": "Boolean",
                "homeRegionIds": "PositiveInt",
                "search": "String",
                "showWaitlistOnly": "Boolean",
                "state": "String",
                "status": "ProviderApplicationStatus",
                "zip": "String"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4f426b42f8955a9c64b34a5812db1e488f060997d1c3cbc2d82aca066c8ab80f";