export default {
    "name": "DetachFileFromProviderManagementMutationProviderManagement",
    "kind": "HoudiniMutation",
    "hash": "885b2035c500b2dabc5b558548532b7dacacf750e4cd0f806657b3e9284160f2",

    "raw": `mutation DetachFileFromProviderManagementMutationProviderManagement($providerId: PositiveInt!, $fileId: PositiveInt!) {
  detachFilesFromProvider(providerId: $providerId, fileId: $fileId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "detachFilesFromProvider": {
                "type": "Boolean",
                "keyRaw": "detachFilesFromProvider(fileId: $fileId, providerId: $providerId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerId": "PositiveInt",
            "fileId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=d0afbea5b3a01d3e4519a5fbd9876897449228c5947161b0d9f3ae8b14e75ee8";