type Account = {
	created: string | Date
}

export function getComputedUserAccount<T extends Account>(account: T) {
	if (account.created) {
		account.created = new Date(account.created)
	}

	return account
}

export default getComputedUserAccount
