<script lang="ts">
	import type { ComponentEvents } from 'svelte'
	import type { Mediator, SvelteAsr, ManifestVersion } from 'types/common'
	import { getContext, onDestroy } from 'svelte'
	import Login from '@isoftdata/svelte-login'
	import ChangePasswordModal from '@isoftdata/svelte-change-password'
	import { GetServerInformationStore, PasswordRecoveryStore, graphql } from '$houdini'
	import Button from '@isoftdata/svelte-button'
	import { loginAndSaveSession } from 'utility/login-and-save-session'
	import { getUpcomingOrdersCount } from 'utility/load-upcoming-orders'
	import { getCompletedOrdersCount } from 'utility/load-completed-orders'
	import Cookies from 'js-cookie'
	import { sendEmailVerification } from 'utility/resend-email-verification'
	import { minToMs } from 'utility/min-to-ms'
	import { isManifest } from 'utility/check-manifest'

	export let lastUsedEmail: string
	export let redirectTo: string
	export let redirectToParams: Record<string, string> | null
	export let asr: SvelteAsr

	let apiVersionNumber: string | null = null
	let changePasswordModal: ChangePasswordModal
	let loginComponent: Login
	let isLoading = false
	// Literally just used to disabled the forgot password button
	let email: string = lastUsedEmail ?? ''
	let unverifiedEmail = false

	const mediator = getContext<Mediator>('mediator')

	const getServerInformation: GetServerInformationStore = graphql`
		query GetServerInformation {
			getServerInformation {
				releaseVersionNumber
			}
		}
	`

	const passwordRecoveryMutation: PasswordRecoveryStore = graphql`
		mutation PasswordRecovery($email: String!) {
			passwordRecovery(email: $email) {
				email
			}
		}
	`

	getServerInformation.fetch().then(result => {
		apiVersionNumber = result.data?.getServerInformation?.releaseVersionNumber ?? null
	})

	async function sendPasswordRecoveryEmail(email: string) {
		// Now, this mutation will check whether the email has been verified
		// If it hasn't, it will return an error early
		// TODO: Have a way to add a button to resend the verification email
		// - the problem to do that is the error is caught by the ChangePasswordModal component, so we need a way to do that
		const { data } = await passwordRecoveryMutation.mutate({ email })
		return {
			userName: data?.passwordRecovery?.email ?? '',
			recoveryEmail: data?.passwordRecovery?.email ?? '',
		}
	}

	async function resendEmailVerificationEmail() {
		try {
			await sendEmailVerification().mutate({
				email,
			})
			loginComponent.setAlert({
				message: 'Verification email sent!',
				color: 'success',
			})
		} catch (err: unknown) {
			loginComponent.setAlert({
				message: (err as { message: string }).message,
				color: 'danger',
			})
			console.error(err)
		}
	}

	async function login(event: ComponentEvents<Login>['login']) {
		isLoading = true
		const { username: email, password, rememberMe } = event.detail

		try {
			const storedSession = await loginAndSaveSession(mediator, { email, password, regionId: 1 })

			if (storedSession.authToken) {
				Cookies.set('auth-token', storedSession.authToken)
			}

			if (rememberMe) {
				localStorage.setItem('lastUsedEmail', email)
			} else {
				localStorage.removeItem('lastUsedEmail')
			}

			if (redirectTo) {
				asr.go(redirectTo, redirectToParams ?? undefined)
			} else if (storedSession?.customer) {
				const [upcomingOrdersCount, completedOrdersCount] = await Promise.all([getUpcomingOrdersCount(), getCompletedOrdersCount()])

				if (!upcomingOrdersCount && !completedOrdersCount) {
					asr.go('app.new-order')
				} else if (!upcomingOrdersCount && completedOrdersCount) {
					asr.go('app.customer.order-history')
				} else {
					asr.go('app.customer')
				}
			} else if (storedSession?.provider) {
				if (storedSession.provider.status === 'PENDING_APPROVAL' && storedSession.hasOnboardingApplication) {
					asr.go('app.onboarding')
				} else {
					asr.go('app.provider')
				}
			}
		} catch (err: unknown) {
			// Terrible type assertions, but idk how to do it more better
			if ((err as { extensions?: { code?: string } })?.extensions?.code === 'LOGIN_ERROR') {
				switch ((err as { extensions?: { type?: string } })?.extensions?.type) {
					case 'BAD_CREDENTIALS':
					case 'INVALID_ACCOUNT':
					case 'INVALID_PASSWORD':
						// Specifically, this is if the login was just typed in wrong, use the more opinionated route
						loginComponent.invalidLogin()
						break
					case 'ACCOUNT_LOCKED':
					case 'ACCOUNT_DEACTIVATED':
						// This section is if they've been deactivated or locked, with sub-notes
						loginComponent.setAlert({
							message: (err as { message: string }).message,
						})
						break
					case 'ACCOUNT_NOT_YET_ACTIVATED':
						// This is if they haven't activated their account yet
						unverifiedEmail = true
						loginComponent.setAlert({
							message: 'Your email address has not been verified. Please check your email for the verification link.',
						})
						break
					case 'INSUFFICIENT_PRIVILEGES':
					default:
						console.log(err)
						loginComponent.setAlert({
							message: (err as { message: string }).message,
						})
						break
				}
			} else {
				loginComponent.setAlert({
					message: (err as { message: string }).message,
				})
			}
		} finally {
			isLoading = false
		}
	}
	let checkUpdateInterval: ReturnType<typeof setInterval> | undefined = undefined
	if (import.meta.env.PROD) {
		checkUpdateInterval = setInterval(async () => {
			const response = await fetch('manifest.json', { cache: 'no-store' })

			const data = await response.json()
			if (isManifest(data) && data.client.version !== '__buildVersion__') {
				clearInterval(checkUpdateInterval)
				window.location.reload()
			}
		}, minToMs(2)) // every 2 minutes
	} else {
		console.log('Not in production, skipping update check')
	}

	onDestroy(() => {
		clearInterval(checkUpdateInterval)
	})
</script>

<Login
	{isLoading}
	userNamePlaceholder="Email"
	logoImagePath="./images/lawnhiro-logo-square.svg"
	rememberMe={!!lastUsedEmail}
	forgotPasswordDisabled={!email}
	on:login={login}
	on:forgotPassword={event => changePasswordModal.initiatePasswordRecovery(event.detail.username, true)}
	bind:this={loginComponent}
	bind:username={email}
>
	<div class="d-flex flex-column align-items-center">
		{#if unverifiedEmail}
			<Button
				class="mb-1"
				color="info"
				size="xs"
				outline
				on:click={resendEmailVerificationEmail}
			>
				Resend Verification Email
			</Button>
		{/if}
		<p>Don't have an account? <a href={asr.makePath('sign-up')}>Sign up!</a></p>
		<a
			href="https://changelog.isoftdata.com/?product=lawn-hiro-web#__buildVersion__"
			target="lawnhiro_web_changelog"
		>
			<small
				class="text-dim"
				title="Built Date: __buildDate__
Enviroment: __buildEnv__">App Version __buildVersion__</small
			>
		</a>
		{#if apiVersionNumber}
			<a
				href="https://changelog.isoftdata.com/?product=lawn-hiro-api#{{ apiVersionNumber }}"
				target="lawnhiro_api_changelog"
			>
				<small class="text-dim">API Version {apiVersionNumber}</small>
			</a>
		{/if}
	</div>
</Login>

<!-- We don't use the changePassword function because we have a password recovery state -->
<ChangePasswordModal
	bind:this={changePasswordModal}
	sendPasswordResetTokenEmail={sendPasswordRecoveryEmail}
	changePassword={() => Promise.resolve()}
	showCodeInput={false}
></ChangePasswordModal>
