export default {
    "name": "SetUserZonePermission",
    "kind": "HoudiniMutation",
    "hash": "815e3874b1733d677a8ce0aa16f17ca3c4aca0a313ec903ac2dffcda0b27bcd7",

    "raw": `mutation SetUserZonePermission($userZone: EditUserZonePermissions!) {
  setUserZonePermission(userZone: $userZone)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setUserZonePermission": {
                "type": "Boolean",
                "keyRaw": "setUserZonePermission(userZone: $userZone)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userZone": "EditUserZonePermissions"
        },

        "types": {
            "EditUserZonePermissions": {
                "active": "Boolean",
                "userAccountId": "PositiveInt",
                "zoneId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=51e77c8795c64a6d8a771e45914d03e72fa1f5f313199b5d82913838b779fbdc";