export default {
    "name": "CouponQuery",
    "kind": "HoudiniQuery",
    "hash": "1b4396caded7391080d9f80cad1b2aa1c41e55c5ad3f1c25e31fe5c525560b73",

    "raw": `query CouponQuery($filter: CouponFilter, $orderBy: [CouponOrderBy!]) {
  coupons(filter: $filter, orderBy: $orderBy) {
    ...CouponData
    id
  }
}

fragment CouponData on Coupon {
  id
  code
  description
  validFrom
  validTo
  numberOfTimesUsed
  maximumTimesUsed
  usesPerUser
  serviceId
  priceAdjustment
  isPercentage
  active
  firstJobOnly
  isValid
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "coupons": {
                "type": "Coupon",
                "keyRaw": "coupons(filter: $filter, orderBy: $orderBy)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "NonEmptyString",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "validFrom": {
                            "type": "DateTime",
                            "keyRaw": "validFrom",
                            "visible": true
                        },

                        "validTo": {
                            "type": "DateTime",
                            "keyRaw": "validTo",
                            "nullable": true,
                            "visible": true
                        },

                        "numberOfTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "numberOfTimesUsed",
                            "visible": true
                        },

                        "maximumTimesUsed": {
                            "type": "NonNegativeInt",
                            "keyRaw": "maximumTimesUsed",
                            "nullable": true,
                            "visible": true
                        },

                        "usesPerUser": {
                            "type": "NonNegativeInt",
                            "keyRaw": "usesPerUser",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "serviceId",
                            "nullable": true,
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "isPercentage": {
                            "type": "Boolean",
                            "keyRaw": "isPercentage",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "firstJobOnly": {
                            "type": "Boolean",
                            "keyRaw": "firstJobOnly",
                            "visible": true
                        },

                        "isValid": {
                            "type": "Boolean",
                            "keyRaw": "isValid",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "CouponData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "CouponFilter",
            "orderBy": "CouponOrderBy"
        },

        "types": {
            "CouponFilter": {
                "active": "Boolean",
                "firstJobOnly": "Boolean",
                "isPercentage": "Boolean",
                "maximumTimesUsed": "NonNegativeInt",
                "numberOfTimesUsed": "NonNegativeInt",
                "priceAdjustment": "NonEmptyString",
                "serviceId": "NonNegativeInt",
                "valid": "Boolean",
                "validFrom": "DateTime",
                "validTo": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=46abe0e102b1728bc4039e65056da875256a193b725ada48cbdf2fcbf0f1c9fa";