import component from './Login.svelte'
import { storeSession } from 'utility/login-and-save-session'
import sessionStore from 'stores/session'

import { AppContext, ResolveParameters } from 'types/common'
import { graphql } from '$houdini'
import Cookies from 'js-cookie'
import jsonOrNull from 'utility/json-or-null'

// TODO figure out if we can reuse the userFields/sessionFields as partials here
const sessionQuery = graphql(`
	query Session {
		session {
			sessionToken: token
			email: userName
			userAccountId
			user {
				recoveryEmail
				firstName
				fullName
				lastName
				customer {
					id
					billingCountry
					billingZip
					billingState
					billingCity
					billingStreet
					ownedResidences {
						data {
							id
							street
							city
							state
							zip
						}
					}
				}
				provider {
					id
					status
					profilePictureFile {
						path
					}
					latestOnboardingApplication {
						id
						status
					}
				}
				userRoles {
					permissionName
					value
				}
			}
		}
	}
`)
const logoutMutation = graphql(`
	mutation Logout {
		closeSession
	}
`)

type LoginParams = {
	redirectTo?: string
	redirectToParams?: Record<string, unknown>
	sessionToken?: string
	email?: string
}

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'login',
		route: 'login',
		data: {},
		template: {
			svelte: true,
			component,
			options: {},
		},
		querystringParameters: ['sessionToken', 'email'],

		async resolve(_data, parameters: ResolveParameters<LoginParams>) {
			if (parameters.sessionToken) {
				let hasSessionError = false
				try {
					const { data } = await sessionQuery.fetch()
					const session = data?.session
					const email = session?.email
					// Ensure both session and email (userName) are truthy
					if (!session || !email) {
						throw new Error('Invalid Session Token')
					}
					storeSession({
						...session,
						email,
					})
				} catch (err) {
					console.error(err)
					hasSessionError = true
				}

				if (!hasSessionError) {
					throw {
						redirectTo: {
							name: 'app',
						},
					}
				}
			}

			try {
				await logoutMutation.mutate(null)
			} catch (err) {
				console.error(err)
			}

			localStorage.removeItem('session')
			sessionStore.set({})
			Cookies.remove('auth-token')
			localStorage.removeItem('newAddress')

			let lastUsedEmail = localStorage.getItem('lastUsedEmail') ?? ''

			if (parameters.email) {
				lastUsedEmail = parameters.email
			}

			return {
				lastUsedEmail, // TODO: use persistent store?
				redirectTo: parameters.redirectTo,
				redirectToParams: jsonOrNull(parameters.redirectToParams),
			}
		},
	})
}
