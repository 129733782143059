export default {
    "name": "LoadUserZonesQuery",
    "kind": "HoudiniQuery",
    "hash": "3ca7955816709c2fa2ea88fd0b7fb3f2197b9a5cf5d8cc4e1600b821ae92236e",

    "raw": `query LoadUserZonesQuery($userId: PositiveInt!) {
  getUserZones(userId: $userId) {
    zone {
      id
      name
      state
      regions {
        id
        name
        status
      }
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getUserZones": {
                "type": "UserZone",
                "keyRaw": "getUserZones(userId: $userId)",

                "selection": {
                    "fields": {
                        "zone": {
                            "type": "Zone",
                            "keyRaw": "zone",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "regions": {
                                        "type": "Region",
                                        "keyRaw": "regions",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "RegionStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=aaf84ca04834ff1d763d789820b770b8957578bc0412bfdcc637235dfd66330e";