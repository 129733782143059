import template from './order-schedule-selection.html'
import formatDateString from 'utility/format/format-date-string'
import formatCurrency from 'utility/format/format-currency'
import { toDisplay as percentToDisplay } from 'utility/format/format-percent'

//Ractive components
import makeInput from '@isoftdata/input'
import makeSelect from '@isoftdata/select'

const TODAY = (new Date()).toISOString().slice(0, 10)

export default function orderScheduleSelection() {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				session: null, //we'll use a session object to determine if we should allow the selection of the ones that require you to be logged in
				chosenDate: TODAY,
				minDate: TODAY,
				scheduleSelection: 'ASAP',
				endOfSeasonDate: null,
				selectedRecurringSchedulePeriodId: null,
				recurringScheduleOptions: [],
				showRecurringOption: false,
				serviceWindow: 2,
			}
		},
		components: {
			itInput: makeInput({ twoway: true, lazy: false }),
			itSelect: makeSelect({ twoway: true, lazy: false }),
		},
		attributes: {
			required: [ ],
			optional: [
				'session',
				'scheduleSelection',
				'chosenDate',
				'selectedRecurringSchedulePeriodId',
				'showRecurringOption',
				'recurringScheduleOptions',
				'serviceWindow',
				'endOfSeasonDate',
			],
		},
		computed: {
			displaySelectedDate() {
				return formatDateString(this.get('chosenDate'))
			},
			formattedSchedulePeriodOptions() {
				const activeRecurringScheduleOptions = this.get('recurringScheduleOptions')
				return activeRecurringScheduleOptions.map(option => {
					const pricing = option?.tag?.tagPricingForService
					let priceNumber = Number(pricing?.priceAdjustment)
					const pricingType = pricing?.pricingType

					if (priceNumber < 0) {
						// strip off the negative sign
						priceNumber = -priceNumber
					}

					const formattedPriceAdjustment = pricingType === 'PERCENT' ? `${percentToDisplay(priceNumber)}%` : formatCurrency(priceNumber)

					return {
						label: `Every ${option?.serviceSchedulePeriod} days (save ${formattedPriceAdjustment})`,
						value: option?.id,
					}
				})
			},
		},
	})
}
