import component from './AccountsPayable.svelte'

import { formatISO, previousMonday, previousSunday } from 'date-fns'

import { AppContext } from 'types/common'

import { loadPayables } from 'utility/accounts-payable-helper'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.accounts-payable',
		route: 'accounts-payable',
		querystringParameters: [],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const to = previousSunday(new Date())
			const from = previousMonday(to)

			const payables = (await loadPayables({ from: formatISO(from), to: formatISO(to) })) ?? []

			return {
				payables,
				dates: {
					from: formatISO(from, { representation: 'date' }), //This is an annoying gatcha with the DateRange Component, that this is the format it expects
					to: formatISO(to, { representation: 'date' }),
				},
				loadingPayables: false,
			}
		},
	})
}
