<script lang="ts">
	import Table, { Td } from '@isoftdata/svelte-table'
	import Select from '@isoftdata/svelte-select'
	import DateRange from '@isoftdata/svelte-date-range'
	import { type QuoteManagementQuote, priceErrorStatusFilterList, loadQuotes } from 'utility/quotes-helper'
	import { rangeFromDates } from '@isoftdata/utility-date-time'
	import formatCurrency from 'utility/format/format-currency'

	import toTitleCase from 'to-title-case'
	import { parseISO } from 'date-fns'
	import { type QuoteFilter, type PriceErrorStatus$options } from '$houdini'

	export let quotes: QuoteManagementQuote[] = []
	export let range: ReturnType<typeof rangeFromDates> & undefined
	export let dates: {
		from: string
		to: string
	}
	export let regionStatusFilter: PriceErrorStatus$options | null

	async function loadNewFilteredQuotes() {
		const filter: QuoteFilter = {
			createdFrom: dates?.from ? parseISO(dates.from) : null,
			createdTo: dates?.to ? parseISO(dates.to) : null,
			statuses: regionStatusFilter ? [regionStatusFilter] : undefined,
		}

		quotes = await loadQuotes(filter)
	}
</script>

<div class="container-fluid mt-3">
	<Table
		responsive
		filterEnabled
		showFilterLabel
		rows={quotes}
		columns={[
			{ property: 'city', name: 'City' },
			{ property: 'state', name: 'State' },
			{ property: 'street', name: 'Street' },
			{ property: 'zip', name: 'Zip' },
			{ property: 'email', name: 'Email' },
			{ property: 'price', name: 'Quoted Price', align: 'right', sortType: 'ALPHA_NUM' },
			{ property: 'service[name]', name: 'Service' },
			{ property: 'created', name: 'Created', defaultSortColumn: true, defaultSortDirection: 'DESC' },
			{ property: 'priceErrorStatus', name: 'Region Status' },
		]}
		filterLabel="Search"
		let:row
	>
		<svelte:fragment slot="header">
			<div class="form-row align-items-end">
				<div class="col-12 col-md-3">
					<Select
						label="Region Status"
						bind:value={regionStatusFilter}
						emptyText="Any Status"
						on:change={() => {
							loadNewFilteredQuotes()
						}}
					>
						{#each priceErrorStatusFilterList as status}
							<option value={status.value}>{status.label}</option>
						{/each}
					</Select>
				</div>
				<div class="col-12 col-md-9">
					<DateRange
						label=""
						inline
						allowNone
						bind:range
						bind:dates
						on:change={loadNewFilteredQuotes}
					/>
				</div>
			</div>
		</svelte:fragment>
		<svelte:fragment slot="no-rows">
			<tr>
				<td colspan="9">No Quotes Found</td>
			</tr>
		</svelte:fragment>

		<tr>
			<Td property="city">{row.city}</Td>
			<Td property="state">{row.state}</Td>
			<Td property="street">
				<a
					href="https://www.google.com/maps/place/?q=place_id:{row.googleMapsPlaceId}"
					target="_blank"
					rel="noopener noreferrer">{row.street}</a
				>
			</Td>
			<Td property="zip">{row.zip}</Td>
			<Td property="email">
				<a href="mailto:{row.email}">{row.email}</a>
			</Td>
			<Td property="price">
				{formatCurrency(row.price)}
			</Td>
			<Td property="service[name]">{row.service?.name}</Td>
			<Td property="created">{row.created.toLocaleDateString()}</Td>
			<Td property="priceErrorStatus">{toTitleCase(row.priceErrorStatus)}</Td>
		</tr>
	</Table>
</div>
