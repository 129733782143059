export default {
    "name": "LoadResidenceHelperAnswers",
    "kind": "HoudiniQuery",
    "hash": "94514b422883c3cf420d38f950fd6d3d5c2ca73cf7bffea35b1464ceee55578d",

    "raw": `query LoadResidenceHelperAnswers {
  answers {
    data {
      id
      answer
      tag {
        id
        code
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "answers": {
                "type": "AnswerResponse",
                "keyRaw": "answers",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Answer",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    },

                                    "tag": {
                                        "type": "Tag",
                                        "keyRaw": "tag",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=d50e6d8af1d8d6dd1da52193f817803f0e8a60e4555114628a0dbf9a0ef3fb61";