import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadResidenceHelperServices'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadResidenceHelperServicesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadResidenceHelperServicesStore",
			variables: false,
		})
	}
}

export async function load_LoadResidenceHelperServices(params) {
	await initClient()

	const store = new LoadResidenceHelperServicesStore()

	await store.fetch(params)

	return {
		LoadResidenceHelperServices: store,
	}
}
