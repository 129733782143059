export default {
    "name": "LoadProviderAvailableServices",
    "kind": "HoudiniQuery",
    "hash": "869a5b2f5b3999034b2ab8f538f78b345c97e006bded4fc47f62011620d0f69f",

    "raw": `query LoadProviderAvailableServices($providerId: PositiveInt!) {
  provider(id: $providerId) {
    authorizedServices {
      service {
        id
        name
        description
        active
        codeName
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "provider": {
                "type": "Provider",
                "keyRaw": "provider(id: $providerId)",

                "selection": {
                    "fields": {
                        "authorizedServices": {
                            "type": "ProviderService",
                            "keyRaw": "authorizedServices",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "codeName": {
                                                    "type": "String",
                                                    "keyRaw": "codeName",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1bb4dd47236550497722182560d4dcdd24fc55dc192cac36473613d4cb36f3de";