import {
	graphql,
	AdminTagsStore,
	AdminTagServiceQueryStore,
	type AdminTags$result,
	type AdminTagServiceQuery$result,
	DeleteTagStore,
	CreateNewTagStore,
	UpdateTagPricingMutationStore,
	NewTagPricingMutationStore,
	DeleteTagPricingMutationStore,
} from '$houdini'

import { toSave } from 'utility/format/format-percent'

export type AdminTag = AdminTags$result['tags']['data'][number]
export type AdminTagService = AdminTagServiceQuery$result['services']['data'][number]
export type PricingType = Exclude<AdminTag['tagPricings'], null>[number]['pricingType']
export type EntityType = AdminTag['entityType']

graphql`
	fragment AdminTagPricingData on TagPricing {
		id
		priceAdjustment
		pricingType
		serviceId
		service {
			name
		}
		postPriceAdjustment
	}
`

const adminTagsQuery: AdminTagsStore = graphql`
	query AdminTags {
		tags {
			data {
				id
				code
				name
				description
				prompt
				entityType
				tagPricings {
					...AdminTagPricingData
				}
			}
		}
	}
`

const adminTagServiceQuery: AdminTagServiceQueryStore = graphql`
	query AdminTagServiceQuery($pagination: PaginatedInput) {
		services(pagination: $pagination) {
			data {
				id
				name
			}
		}
	}
`

const removeTagMutation: DeleteTagStore = graphql`
	mutation DeleteTag($tagId: PositiveInt!) {
		deleteTag(tagId: $tagId)
	}
`

const addNewTagMutation: CreateNewTagStore = graphql`
	mutation CreateNewTag($tag: NewTag!) {
		newTag(tag: $tag) {
			id
			code
			name
			description
			prompt
			entityType
			tagPricings {
				...AdminTagPricingData
			}
		}
	}
`

const updateTagPricingMutation: UpdateTagPricingMutationStore = graphql`
	mutation UpdateTagPricingMutation($tagPricing: EditTagPricing!) {
		editTagPricing(tagPricing: $tagPricing) {
			...AdminTagPricingData
		}
	}
`

const newTagPricingMutation: NewTagPricingMutationStore = graphql`
	mutation NewTagPricingMutation($tagPricing: NewTagPricing!) {
		newTagPricing(tagPricing: $tagPricing) {
			...AdminTagPricingData
		}
	}
`

const deleteTagPricingMutation: DeleteTagPricingMutationStore = graphql`
	mutation DeleteTagPricingMutation($tagPricingId: PositiveInt!) {
		deleteTagPricing(tagPricingId: $tagPricingId)
	}
`

export async function loadAdminTags() {
	const { data } = await adminTagsQuery.fetch()

	if (!data?.tags.data) {
		return []
	}

	return data.tags.data
}

export async function loadAdminTagsServices(pagination?: { pageNumber: number; pageSize: number }) {
	const { data } = await adminTagServiceQuery.fetch({
		variables: {
			pagination: {
				pageNumber: pagination?.pageNumber ?? 1,
				pageSize: pagination?.pageSize ?? 0,
			},
		},
	})

	if (!data?.services.data) {
		return []
	}

	return data.services.data
}

export async function loadAdminTagsData() {
	const [tags, services] = await Promise.all([loadAdminTags(), loadAdminTagsServices()])

	return {
		tags,
		services,
	}
}

export async function removeTag(tagId: number) {
	const { data } = await removeTagMutation.mutate({
		tagId,
	})

	if (!data) {
		throw new Error('Failed to delete tag')
	}

	return data.deleteTag
}

export async function removeTagPricing(tagPricingId: number) {
	const { data } = await deleteTagPricingMutation.mutate({
		tagPricingId,
	})

	if (!data) {
		throw new Error('Failed to delete tag pricing')
	}

	return data.deleteTagPricing
}

export async function updateTagPricing(tagPricing: { id: number; pricingType: PricingType; priceAdjustment: number; postPriceAdjustment: boolean }) {
	const { data } = await updateTagPricingMutation.mutate({
		tagPricing: {
			tagPricingId: tagPricing.id,
			pricingType: tagPricing.pricingType,
			priceAdjustment: tagPricing.pricingType === 'PERCENT' ? toSave(tagPricing.priceAdjustment) : tagPricing.priceAdjustment.toString(),
			postPriceAdjustment: tagPricing.postPriceAdjustment,
		},
	})

	if (!data?.editTagPricing) {
		throw new Error('Failed to update tag pricing')
	}

	return data.editTagPricing
}

export async function newTagPricing(tagPricing: { pricingType: PricingType; priceAdjustment: number; postPriceAdjustment: boolean; serviceId: number; tagId: number }) {
	const { data } = await newTagPricingMutation.mutate({
		tagPricing: {
			pricingType: tagPricing.pricingType,
			priceAdjustment: tagPricing.pricingType === 'PERCENT' ? toSave(tagPricing.priceAdjustment) : tagPricing.priceAdjustment.toString(),
			postPriceAdjustment: tagPricing.postPriceAdjustment,
			serviceId: tagPricing.serviceId,
			tagId: tagPricing.tagId,
		},
	})

	if (!data?.newTagPricing) {
		throw new Error('Failed to create tag pricing')
	}

	return data.newTagPricing
}

export async function saveNewTag(tag: { code: string; name: string; description: string; prompt: string; entityType: EntityType }) {
	const { data } = await addNewTagMutation.mutate({
		tag: {
			code: tag.code,
			name: tag.name,
			description: tag.description,
			prompt: tag.prompt,
			entityType: tag.entityType,
		},
	})

	if (!data?.newTag) {
		throw new Error('Failed to create tag')
	}

	return data.newTag
}
