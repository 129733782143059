<script lang="ts">
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	type SwitchItemList = {
		id: number
		name: string
		checked: boolean
	}

	export let showLabel: boolean = true
	export let switchLabel: string = ''
	export let itemList: SwitchItemList[]

	function updateFunction(id: number, event: Event) {
		const checked = (event.target as HTMLInputElement).checked
		dispatch('change', { id, checked })
	}
</script>

<div class="list-group scroll">
	{#each itemList as item}
		<div class="list-group-item d-flex justify-content-between align-items-center">
			{item.name}
			<Checkbox
				inline
				{showLabel}
				label={switchLabel}
				type="switch"
				checked={item.checked}
				on:change={e => updateFunction(item.id, e)}
			/>
		</div>
	{/each}
</div>

<style>
	.scroll {
		max-height: 515px;
		overflow-y: auto;
	}
</style>
