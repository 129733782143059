<script lang="ts">
	import type { ProviderManagementProvider } from 'utility/provider-management-helper'
	import ProviderProfilePicture from './ProviderProfilePicture.svelte'
	import Icon from '@isoftdata/svelte-icon'

	export let provider: ProviderManagementProvider
	export let selectedProvider: ProviderManagementProvider | undefined

	let providerStatusMap = {
		APPROVED: { display: 'Approved', badgeClass: 'badge-success' },
		PENDING_APPROVAL: { display: 'Pending Approval', badgeClass: 'badge-warning' },
		DISABLED: { display: 'Disabled', badgeClass: 'badge-danger' },
	}

	const providerStatusBadgeClass = () => {
		return providerStatusMap[provider.status].badgeClass
	}

	let displayProviderStatus = () => {
		return providerStatusMap[provider.status].display
	}
</script>

<div
	class="card"
	aria-label="Provider Details"
	role="button"
	tabindex="0"
	class:bg-primary={selectedProvider?.id === provider.id}
	on:click={event => {
		event.stopPropagation()
		if (selectedProvider?.id === provider.id) {
			selectedProvider = undefined
		} else {
			selectedProvider = provider
		}
	}}
	on:keydown={event => {
		if (event.key === 'Enter') {
			event.stopPropagation()
			if (selectedProvider?.id === provider.id) {
				selectedProvider = undefined
			} else {
				selectedProvider = provider
			}
		}
	}}
>
	<div class="card-body">
		<div class="d-flex flex-column align-items-center">
			<ProviderProfilePicture
				profilePictureUrl={provider.profilePictureFile?.url ?? null}
				pictureSize={80}
				showFloatingButton={false}
			/>
			<span>{provider.userAccount.firstName} {provider.userAccount.lastName}</span>
			<div class="text-center">
				<span class="badge badge-pill {providerStatusBadgeClass()}">{displayProviderStatus()}</span>
				{#if provider.homeRegion}
					<span
						class="badge badge-pill badge-light"
						title="{provider.homeRegion.name} is currently {provider.homeRegion.status === 'ACTIVE' ? 'active' : 'inactive'}"
					>
						<Icon
							icon="circle"
							class={provider.homeRegion.status === 'ACTIVE' ? 'text-primary' : 'text-secondary'}
						/>
						{provider.homeRegion.name}
					</span>
				{:else}
					<span class="badge badge-pill badge-light">
						<Icon
							icon="circle"
							class="text-warning"
						/>
						No home region
					</span>
				{/if}
			</div>
			<div class="mt-2">
				<a
					href="mailto:{provider.userAccount.email}"
					class:text-dark={selectedProvider?.id === provider.id}
					on:click={event => event.stopPropagation()}
				>
					{provider.userAccount.email}
				</a>
			</div>
			{#if provider.userAccount.mobile}
				<div>
					<a
						href="tel:{provider.userAccount.email}"
						class:text-dark={selectedProvider?.id === provider.id}
						on:click={event => event.stopPropagation()}
					>
						{provider.userAccount.mobile}
					</a>
				</div>
			{:else}
				<i class="text-muted">No mobile number</i>
			{/if}
		</div>
	</div>
</div>
