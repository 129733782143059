export default {
    "name": "ProviderAvailableJobsClaimJob",
    "kind": "HoudiniMutation",
    "hash": "9d89e2bfcbe28c77071a17a63ed0dcfb2f42e5808bb6059459095e9fd5db4750",

    "raw": `mutation ProviderAvailableJobsClaimJob($jobClaim: NewJobClaim!) {
  claimJob(jobClaim: $jobClaim) {
    scheduledAt
    job {
      id
      jobStatus
    }
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "claimJob": {
                "type": "JobClaim",
                "keyRaw": "claimJob(jobClaim: $jobClaim)",

                "selection": {
                    "fields": {
                        "scheduledAt": {
                            "type": "DateTime",
                            "keyRaw": "scheduledAt",
                            "visible": true
                        },

                        "job": {
                            "type": "Job",
                            "keyRaw": "job",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "jobStatus": {
                                        "type": "JobStatus",
                                        "keyRaw": "jobStatus",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobClaim": "NewJobClaim"
        },

        "types": {
            "NewJobClaim": {
                "jobId": "PositiveInt",
                "scheduledAt": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=8a6619a3636fd764a882008fb3a299124021f37975aee003f76223bad24a1225";