export default googleMapsPlaceAddressComponent => {
	let address = {
		street: '',
		city: '',
		county: '',
		state: '',
		zip: '',
		country: '',
	}

	const googleMapsPlaceComponentTypesMap = {
		street: {
			types: [ 'street_number', 'route' ],
			key: 'short_name',
		},
		city: {
			types: [ 'locality' ],
			key: 'long_name',
		},
		county: {
			types: [ 'administrative_area_level_2' ],
			key: 'short_name',
		},
		state: {
			types: [ 'administrative_area_level_1' ],
			key: 'short_name',
		},
		zip: {
			types: [ 'postal_code' ],
			key: 'long_name',
			parseFn: val => parseInt(val, 10) || val,
		},
		country: {
			types: [ 'country' ],
			key: 'short_name',
		},
	}

	for (const key in address) {
		const componentsForKey = googleMapsPlaceAddressComponent.filter(component => {
			return component.types.some(type => googleMapsPlaceComponentTypesMap[key].types.includes(type))
		})

		const val = componentsForKey.map(component => component[googleMapsPlaceComponentTypesMap[key].key]).join(' ')
		const parseFn = googleMapsPlaceComponentTypesMap[key].parseFn

		address[key] = parseFn?.(val) || val
	}

	if (address.county) {
		address.county = address.county.replace('County', '').trim()
	}

	return address
}
