export default {
    "name": "AdminTagServiceQuery",
    "kind": "HoudiniQuery",
    "hash": "c10ac11a0b0b4676c372c919868f09c6274fff58dc5b8e3dfb99136383c75992",

    "raw": `query AdminTagServiceQuery($pagination: PaginatedInput) {
  services(pagination: $pagination) {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ec33d4edbfc872f07a0a7263f589077d907ce169b1b627d1fd2fcb21e96e3bb8";