export default {
    "name": "LoadStates",
    "kind": "HoudiniQuery",
    "hash": "1cd2a4a19bfdf371f654864641d20b06b2fc224afe2b32de6b9b3c25c1455634",

    "raw": `query LoadStates {
  states {
    data {
      stateCode
      stateName
      stateAbbreviation
      country
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "states": {
                "type": "StateResponse",
                "keyRaw": "states",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "State",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "stateCode": {
                                        "type": "PositiveInt",
                                        "keyRaw": "stateCode",
                                        "visible": true
                                    },

                                    "stateName": {
                                        "type": "String",
                                        "keyRaw": "stateName",
                                        "visible": true
                                    },

                                    "stateAbbreviation": {
                                        "type": "String",
                                        "keyRaw": "stateAbbreviation",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9ca0d81fc66d3e41219264ad9f7bbdcd54fe2621b9f7338f2bf68180701df94f";