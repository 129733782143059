import type { Provider, Service } from 'utility/provider-application-helper'

import { AppContext } from 'types/common'
import component from './Onboarding.svelte'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import formatImageFileUrl from 'utility/format/format-image-file-url'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.onboarding',
		route: 'onboarding',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()

			if (!session.provider) {
				throw new Error('You do not have permission to access this page.')
			}

			const { data } = await onboardingQuery.fetch({
				variables: {
					providerId: session.provider.id,
					filter: {
						allowNewApplication: true,
					},
					pagination: {
						pageNumber: 1,
						pageSize: 0,
					},
				},
			})

			if (!data) {
				throw new Error('Failed to fetch services')
			}

			const provider = data.provider
			const availableServices = data.services.data

			const computedProvider: Provider = {
				...provider,
				userAccount: {
					...provider.userAccount,
					mobile: provider.userAccount.mobile ?? '',
				},
				inWaitlist: provider.onboardingApplication?.inWaitlist ?? false,
				tshirtSize: provider.shirtSize,
				onboardingApplicationStatus: provider.onboardingApplication?.status ?? null,
				homeRegionName: provider.homeRegion?.name ?? null,
				hasAcceptedProviderAgreement: !!provider.onboardingApplication?.acceptedProviderAgreement,
				hasLiabilityInsurance: !!provider.onboardingApplication?.hasLiabilityInsurance,
				transportationPictureFile: {
					status: provider.onboardingApplication?.transportationStatus ?? null,
					rejectMessage: provider.onboardingApplication?.transportationRejectMessage ?? null,
					path: provider.onboardingApplication?.transportationPictureFile ? formatImageFileUrl(provider.onboardingApplication.transportationPictureFile.path) : null,
				},
				driversLicensePictureFile: {
					status: provider.onboardingApplication?.driverLicenseStatus ?? null,
					rejectMessage: provider.onboardingApplication?.driverLicenseRejectMessage ?? null,
					path: provider.onboardingApplication?.driversLicensePictureFile ? formatImageFileUrl(provider.onboardingApplication.driversLicensePictureFile.path) : null,
				},
				services: availableServices.reduce((acc: Service[], service) => {
					const serviceApplication = provider.onboardingApplication?.serviceApplications?.find(serviceApplication => serviceApplication.serviceId === service.id)
					const equipmentPictureFilePath = serviceApplication?.equipmentPictureFile?.path
					acc.push({
						...service,
						examplePictureFilePath: service.examplePictureFile ? formatImageFileUrl(service.examplePictureFile.path) : null,
						uploadedPictureFilePath: equipmentPictureFilePath ? formatImageFileUrl(equipmentPictureFilePath) : null,
						newPictureFileUploaded: false,
						serviceApplicationId: serviceApplication?.id ?? null,
						serviceApplicationStatus: serviceApplication?.status ?? null,
						rejectMessage: serviceApplication?.rejectMessage ?? null,
					})
					return acc
				}, []),
			}

			return {
				provider: computedProvider,
				shirtSizes: data.shirtSizes,
			}
		},
	})
}

const onboardingQuery = graphql(`
	query LoadOnboardingQuery($providerId: PositiveInt!, $filter: ServiceFilter, $pagination: PaginatedInput) {
		provider(id: $providerId) {
			id
			status
			street
			city
			state
			zip
			status
			shirtSize
			homeRegion {
				id
				name
			}
			userAccount {
				firstName
				lastName
				email
				mobile
				provider {
					dateOfBirth
					homeRegionId
					profilePictureFile {
						path
					}
				}
			}
			onboardingApplication: latestOnboardingApplication {
				id
				status
				created
				completed
				driversLicensePictureFile {
					path
				}
				driverLicenseStatus
				driverLicenseRejectMessage
				transportationPictureFile {
					path
				}
				transportationStatus
				transportationRejectMessage
				acceptedProviderAgreement
				hasLiabilityInsurance
				inWaitlist
				rejectMessage
				lastUpdated
				serviceApplications: providerServiceApplications {
					id
					serviceId
					rejectMessage
					status
					equipmentPictureFile {
						path
					}
				}
			}
		}
		services(filter: $filter, pagination: $pagination) {
			data {
				id
				name
				description
				requirement
				examplePictureFile {
					path
				}
			}
		}
		shirtSizes: getShirtSizes
	}
`)
