import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/TagData'


// create the fragment store

export class TagDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "TagDataStore",
			variables: true,
			
		})
	}
}
