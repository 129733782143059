export default {
    "name": "getQuoteQuestions",
    "kind": "HoudiniQuery",
    "hash": "a094f1d52caec911fa786251a8cb19122a5e26954badde32eeab4a91b7432056",

    "raw": `query getQuoteQuestions($questionFilter: QuestionFilter!) {
  questions(questionFilter: $questionFilter) {
    data {
      id
      question
      answers {
        id
        answer
      }
      services {
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "questions": {
                "type": "QuestionResponse",
                "keyRaw": "questions(questionFilter: $questionFilter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Question",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "question": {
                                        "type": "String",
                                        "keyRaw": "question",
                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "services": {
                                        "type": "Service",
                                        "keyRaw": "services",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "questionFilter": "QuestionFilter"
        },

        "types": {
            "QuestionFilter": {
                "id": "PositiveInt",
                "questionDataType": "QuestionDataType",
                "serviceId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5bfa7b411c2d06c25b0e69cc1119351e415c3f9a7afe54b4a8992a733530c349";