export default {
    "name": "TagData",
    "kind": "HoudiniFragment",
    "hash": "62142db522da255d7d145f998932b98ff0c312314da9ad1992b7cefaad68bb5a",

    "raw": `fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Tag",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "code": {
                "type": "String",
                "keyRaw": "code",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "prompt": {
                "type": "String",
                "keyRaw": "prompt",
                "nullable": true,
                "visible": true
            },

            "entityType": {
                "type": "EntityType",
                "keyRaw": "entityType",
                "visible": true
            },

            "tagPricings": {
                "type": "TagPricing",
                "keyRaw": "tagPricings",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "priceAdjustment": {
                            "type": "String",
                            "keyRaw": "priceAdjustment",
                            "visible": true
                        },

                        "pricingType": {
                            "type": "PricingType",
                            "keyRaw": "pricingType",
                            "visible": true
                        },

                        "postPriceAdjustment": {
                            "type": "Boolean",
                            "keyRaw": "postPriceAdjustment",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "TagPricingsData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=ff5b3f2948efb0802328091e18487144284077e0c90ca04557496ea2e02f87c7";