import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetAdminAlertTransactionsCount'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetAdminAlertTransactionsCountStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetAdminAlertTransactionsCountStore",
			variables: false,
		})
	}
}

export async function load_GetAdminAlertTransactionsCount(params) {
	await initClient()

	const store = new GetAdminAlertTransactionsCountStore()

	await store.fetch(params)

	return {
		GetAdminAlertTransactionsCount: store,
	}
}
