import component from './ManageJob.svelte'

import { getFailedPaymentJobCount, getAllPassedScheduledTimeJobClaimCount } from 'utility/admin-job-alerts'

import { AppContext } from 'types/common'
import type { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
import type { ComponentProps } from 'svelte'
import type { MediatorProviders } from 'services/api-fetch'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.manage-job',
		route: 'manage-job',
		defaultChild: 'job',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			let adminJobAlertIcon: ComponentProps<AsrNavTabBar>['tabs'][number]['icon'] = undefined
			let adminFailedJobAlertIcon: ComponentProps<AsrNavTabBar>['tabs'][number]['icon'] = undefined
			let failedJobCount = 0
			let passedScheduledTimeJobClaimCount = 0

			try {
				const [failedJobCountData, passedScheduledTimeJobClaimCountRes] = await Promise.allSettled([getFailedPaymentJobCount(), getAllPassedScheduledTimeJobClaimCount()]).then(results =>
					results.map(result => (result.status === 'fulfilled' ? result.value : 0))
				)
				if (failedJobCountData > 0) {
					adminFailedJobAlertIcon = {
						class: 'text-danger',
						icon: 'circle-exclamation',
					}
				}
				if (passedScheduledTimeJobClaimCountRes > 0) {
					adminJobAlertIcon = {
						class: 'text-danger',
						icon: 'circle-check',
					}
				}
				failedJobCount = failedJobCountData
				passedScheduledTimeJobClaimCount = passedScheduledTimeJobClaimCountRes
			} catch (err: unknown) {
				console.error('Failed to fetch admin job alerts', err)
			}

			return {
				childStates: [
					{
						title: 'Failed Payment Jobs',
						name: 'app.admin.manage-job.failed-payment-job',
						icon: adminFailedJobAlertIcon,
					},
					{
						title: 'Jobs',
						name: 'app.admin.manage-job.job',
						icon: adminJobAlertIcon,
					},
				],
				showRefreshHeader: false,
				failedJobCount,
				passedScheduledTimeJobClaimCount,
			}
		},
	})
}

export type AdminJobMediatorProviders = MediatorProviders & {
	failedJobResolved: () => void
}
