export default {
    "name": "ProviderOnboardingApplicationFields",
    "kind": "HoudiniFragment",
    "hash": "6a12d29309a2ec5981d306c81dc6260d8f0fef943f358ad52716368e2938860c",

    "raw": `fragment ProviderOnboardingApplicationFields on ProviderOnboardingApplicationResponse {
  data {
    id
    providerId
    status
    created
    completed
    driversLicensePictureFile {
      path
      id
    }
    driverLicenseStatus
    driverLicenseRejectMessage
    transportationPictureFile {
      path
      id
    }
    transportationStatus
    transportationRejectMessage
    acceptedProviderAgreement
    hasLiabilityInsurance
    inWaitlist
    rejectMessage
    serviceApplications: providerServiceApplications {
      id
      serviceId
      status
      rejectMessage
      service {
        name
        id
      }
      equipmentPictureFile {
        path
        id
      }
    }
    provider {
      id
      status
      city
      country
      county
      state
      street
      zip
      shirtSize
      homeRegion {
        id
        name
        status
      }
      profilePictureFile {
        path
        id
      }
      userAccount {
        id
        firstName
        lastName
        fullName
        email
        created
        mobile
      }
    }
  }
  info {
    totalItems
    totalPages
    pageSize
  }
  __typename
}
`,

    "rootType": "ProviderOnboardingApplicationResponse",
    "stripVariables": [],

    "selection": {
        "fields": {
            "data": {
                "type": "ProviderApplication",
                "keyRaw": "data",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "providerId": {
                            "type": "PositiveInt",
                            "keyRaw": "providerId",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "created": {
                            "type": "DateTime",
                            "keyRaw": "created",
                            "visible": true
                        },

                        "completed": {
                            "type": "DateTime",
                            "keyRaw": "completed",
                            "nullable": true,
                            "visible": true
                        },

                        "driversLicensePictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "driversLicensePictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "driverLicenseStatus": {
                            "type": "PictureFileStatus",
                            "keyRaw": "driverLicenseStatus",
                            "visible": true
                        },

                        "driverLicenseRejectMessage": {
                            "type": "String",
                            "keyRaw": "driverLicenseRejectMessage",
                            "nullable": true,
                            "visible": true
                        },

                        "transportationPictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "transportationPictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "transportationStatus": {
                            "type": "PictureFileStatus",
                            "keyRaw": "transportationStatus",
                            "visible": true
                        },

                        "transportationRejectMessage": {
                            "type": "String",
                            "keyRaw": "transportationRejectMessage",
                            "nullable": true,
                            "visible": true
                        },

                        "acceptedProviderAgreement": {
                            "type": "Boolean",
                            "keyRaw": "acceptedProviderAgreement",
                            "visible": true
                        },

                        "hasLiabilityInsurance": {
                            "type": "Boolean",
                            "keyRaw": "hasLiabilityInsurance",
                            "visible": true
                        },

                        "inWaitlist": {
                            "type": "Boolean",
                            "keyRaw": "inWaitlist",
                            "visible": true
                        },

                        "rejectMessage": {
                            "type": "String",
                            "keyRaw": "rejectMessage",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceApplications": {
                            "type": "ProviderServiceApplication",
                            "keyRaw": "serviceApplications",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderServiceApplicationStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "rejectMessage": {
                                        "type": "String",
                                        "keyRaw": "rejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "equipmentPictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "equipmentPictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "provider": {
                            "type": "Provider",
                            "keyRaw": "provider",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "county": {
                                        "type": "String",
                                        "keyRaw": "county",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "shirtSize": {
                                        "type": "String",
                                        "keyRaw": "shirtSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "homeRegion": {
                                        "type": "Region",
                                        "keyRaw": "homeRegion",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "RegionStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "firstName": {
                                                    "type": "String",
                                                    "keyRaw": "firstName",
                                                    "visible": true
                                                },

                                                "lastName": {
                                                    "type": "String",
                                                    "keyRaw": "lastName",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "mobile": {
                                                    "type": "PhoneNumber",
                                                    "keyRaw": "mobile",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "info": {
                "type": "PageInfo",
                "keyRaw": "info",

                "selection": {
                    "fields": {
                        "totalItems": {
                            "type": "Int",
                            "keyRaw": "totalItems",
                            "visible": true
                        },

                        "totalPages": {
                            "type": "Int",
                            "keyRaw": "totalPages",
                            "visible": true
                        },

                        "pageSize": {
                            "type": "Int",
                            "keyRaw": "pageSize",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=d38f9e126a3c406f48a9cf5fb17084169ece5d6743047fae53765197bbde0e82";