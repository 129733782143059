import { LoadQuotesStore, type LoadQuotes$result, type QuoteFilter, PriceErrorStatus, graphql, type PriceErrorStatus$options } from '$houdini'

export type QuoteManagementQuote = LoadQuotes$result['quotes'][number]

type PriceErrorStatusFilter = {
	value: PriceErrorStatus$options
	label: string
}

export const priceErrorStatusFilterList: PriceErrorStatusFilter[] = [
	{ value: PriceErrorStatus.IN_SERVICE_REGION, label: 'In Service Region' },
	{ value: PriceErrorStatus.OUT_OF_SERVICE_REGION, label: 'Out of Service Region' },
]

const loadQuotesQuery: LoadQuotesStore = graphql`
	query LoadQuotes($filter: QuoteFilter) {
		quotes(filter: $filter) {
			email
			googleMapsPlaceId
			price
			serviceId
			street
			city
			state
			country
			zip
			priceErrorStatus
			service {
				id
				name
			}
			created
		}
	}
`

export async function loadQuotes(filter: QuoteFilter = {}): Promise<QuoteManagementQuote[]> {
	const { data } = await loadQuotesQuery.fetch({
		variables: {
			filter,
		},
	})

	if (!data?.quotes) {
		return new Array<QuoteManagementQuote>()
	}

	return data.quotes
}
