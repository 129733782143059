<script lang="ts">
	import jobStatusMap from 'utility/job-status-map'
	import jobClaimStatusMap from 'utility/job-claim-status-map'

	export let jobClaimType: keyof typeof jobClaimStatusMap | null = null
	export let jobType: keyof typeof jobStatusMap | null = null
	export let type: 'LOCATION' | 'REGION' | 'SERVICE' | 'SQFT' | null = null
	export let text: string = ''
	export let border: string = ''
	export let badgeClass: string = ''
	export let isJobClaim: boolean = false
	export let style: string = ''

	function getComputedClass() {
		if (type === 'SERVICE') {
			return `badge badge-info ellipsis ${badgeClass}`
		} else if (type === 'LOCATION' || type === 'REGION' || type === 'SQFT') {
			return `badge badge-danger mr-1 mb-1 ${badgeClass}`
		} else if (isJobClaim && jobClaimType) {
			return `badge badge-${jobClaimStatusMap[jobClaimType].class} mb-1 ${badgeClass}`
		} else if (jobType === 'CLAIMED') {
			return `badge badge-secondary mb-1 ${badgeClass}`
		}
	}

	function getComputedText() {
		if (type === 'LOCATION' || type === 'REGION' || type === 'SERVICE' || type === 'SQFT') {
			return text
		} else if (isJobClaim && jobClaimType) {
			return jobClaimStatusMap[jobClaimType].label
		} else if (jobType) {
			return text || jobStatusMap[jobType].label
		} else {
			return ''
		}
	}

	function getComputedIconClass() {
		if (type === 'SERVICE') {
			return ''
		} else if (type === 'LOCATION') {
			return 'fa-solid fa-location-dot'
		} else if (type === 'REGION') {
			return 'fa-solid fa-city'
		} else if (type === 'SQFT') {
			return 'fa-regular fa-ruler-triangle'
		} else if (isJobClaim && jobClaimType) {
			return jobClaimStatusMap[jobClaimType].iconClass
		} else if (jobType) {
			return jobStatusMap[jobType].iconClass
		} else {
			return ''
		}
	}

	function getComputedBorderClass() {
		if (type === 'LOCATION' || type === 'REGION' || type === 'SQFT') {
			return 'selectedJobId === id'
		} else {
			return border
		}
	}

	$: computedClass = getComputedClass()
	$: computedText = getComputedText()
	$: computedIconClass = getComputedIconClass()
	$: computedBorderClass = getComputedBorderClass()
</script>

<span
	class={computedClass}
	class:border={computedBorderClass}
	{style}
>
	<i class={computedIconClass}></i>
	{computedText}
</span>
