import type { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

import component from './Account.svelte'
import { getSession } from 'stores/session'
import { AppContext } from 'types/common'

import type { ComponentProps } from 'svelte'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.account',
		route: 'account',
		defaultChild: 'profile',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()

			if (!session) {
				stateRouter.go('login')
				return {}
			}

			const childStates: ComponentProps<AsrNavTabBar>['tabs'] = [
				{
					name: 'app.account.profile',
					title: 'Profile',
					inherit: false,
				},
				{
					name: 'app.account.notifications',
					title: 'Notifications',
					inherit: false,
				},
			]

			if (session?.customer) {
				childStates.push({
					name: 'app.account.payment-method',
					title: 'Payment Methods',
					inherit: false,
				})
			}

			return Promise.resolve({
				childStates,
			})
		},
	})
}
