export default {
    "name": "LoadAllTimeRegion",
    "kind": "HoudiniQuery",
    "hash": "36a1a94398ce208ab1565ece319358179f6d96569b6aa40f15710f91dcdf29cb",

    "raw": `query LoadAllTimeRegion($regionId: PositiveInt!, $startDate: Date, $endDate: Date) {
  region(id: $regionId) {
    id
    name
    description
    latitude
    longitude
    timeZone
    status
    zoneId
    containedCities {
      ...RegionHelperRegionAreaData
      id
    }
    offeredServices {
      ...RegionHelperServiceOfferData
      id
    }
    regionMetricData(startDate: $startDate, endDate: $endDate) {
      ...RegionHelperRegionMetricData
    }
  }
}

fragment RegionHelperRegionAreaData on RegionArea {
  id
  city
  state
  zip
  country
  description
  __typename
}

fragment RegionHelperServiceOfferData on ServiceOffer {
  id
  active
  service {
    id
    name
  }
  regionId
  chargeMinimum
  chargePerSquareFoot
  chargeFixed
  costPercent
  serviceId
  timeRange
  __typename
}

fragment RegionHelperRegionMetricData on RegionMetricData {
  numberOfActiveProviders
  numberOfQuotes
  numberOfJobsCompleted
  numberOfPerformingProviders
  conversion
  revenue
  providerJobVolume
  numberOfProvidersHomeRegion
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "region": {
                "type": "Region",
                "keyRaw": "region(id: $regionId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "latitude": {
                            "type": "Latitude",
                            "keyRaw": "latitude",
                            "visible": true
                        },

                        "longitude": {
                            "type": "Longitude",
                            "keyRaw": "longitude",
                            "visible": true
                        },

                        "timeZone": {
                            "type": "TimeZone",
                            "keyRaw": "timeZone",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "RegionStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "zoneId": {
                            "type": "PositiveInt",
                            "keyRaw": "zoneId",
                            "visible": true
                        },

                        "containedCities": {
                            "type": "RegionArea",
                            "keyRaw": "containedCities",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "RegionHelperRegionAreaData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "offeredServices": {
                            "type": "ServiceOffer",
                            "keyRaw": "offeredServices",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "regionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "regionId",
                                        "visible": true
                                    },

                                    "chargeMinimum": {
                                        "type": "String",
                                        "keyRaw": "chargeMinimum",
                                        "visible": true
                                    },

                                    "chargePerSquareFoot": {
                                        "type": "String",
                                        "keyRaw": "chargePerSquareFoot",
                                        "visible": true
                                    },

                                    "chargeFixed": {
                                        "type": "String",
                                        "keyRaw": "chargeFixed",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "costPercent": {
                                        "type": "String",
                                        "keyRaw": "costPercent",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "timeRange": {
                                        "type": "String",
                                        "keyRaw": "timeRange",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "RegionHelperServiceOfferData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "regionMetricData": {
                            "type": "RegionMetricData",
                            "keyRaw": "regionMetricData(endDate: $endDate, startDate: $startDate)",

                            "selection": {
                                "fields": {
                                    "numberOfActiveProviders": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "numberOfActiveProviders",
                                        "visible": true
                                    },

                                    "numberOfQuotes": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "numberOfQuotes",
                                        "visible": true
                                    },

                                    "numberOfJobsCompleted": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "numberOfJobsCompleted",
                                        "visible": true
                                    },

                                    "numberOfPerformingProviders": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "numberOfPerformingProviders",
                                        "visible": true
                                    },

                                    "conversion": {
                                        "type": "String",
                                        "keyRaw": "conversion",
                                        "visible": true
                                    },

                                    "revenue": {
                                        "type": "String",
                                        "keyRaw": "revenue",
                                        "visible": true
                                    },

                                    "providerJobVolume": {
                                        "type": "Float",
                                        "keyRaw": "providerJobVolume",
                                        "visible": true
                                    },

                                    "numberOfProvidersHomeRegion": {
                                        "type": "Float",
                                        "keyRaw": "numberOfProvidersHomeRegion",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "RegionHelperRegionMetricData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "regionId": "PositiveInt",
            "startDate": "Date",
            "endDate": "Date"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=379498ecd04f2fb7464f67b3b2e91e8a81d759988a8d99f6aa5d7309a772ea90";