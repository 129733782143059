<script
	context="module"
	lang="ts"
>
	export type Question = {
		id: number
		question: string
		answers: Array<Answer>
	}

	export type Answer = {
		id: number
		answer: string
		selected: boolean | null
	}

	// End context module section
</script>

<script lang="ts">
	import Button from '@isoftdata/svelte-button'

	export let questions: Array<Question>
	export let selectedAnswerIds: Array<number> = []
	export let allQuestionsAnswered: boolean = false

	function setSelected(questionIndex: number, answerIndex: number) {
		questions[questionIndex].answers.forEach(a => (a.selected = false))
		questions[questionIndex].answers[answerIndex].selected = true

		// udpate selectedAnswerIds array and check if all questions are answered
		selectedAnswerIds = questions.reduce((idList: number[], question) => {
			const selectedAnswer = question.answers.find(answer => answer.selected)
			if (selectedAnswer) {
				idList.push(selectedAnswer.id)
			}
			return idList
		}, [])
		allQuestionsAnswered = questions.length === selectedAnswerIds.length
	}
</script>

<ul class="list-group">
	{#each questions as question, questionIndex (question.id)}
		<li
			class="list-group-item"
			style="background-color: white;"
		>
			<div class="form-row align-items-end">
				<div class="col-12">
					<h5>{question.question}</h5>
				</div>
				{#each question.answers as answer, answerIndex (answer.id)}
					<div class="col-12 col-md-auto d-flex flex-column">
						<Button
							outline={!answer.selected}
							size="sm"
							color="primary"
							class="mb-1"
							style="border-radius: 0.4rem;"
							on:click={() => setSelected(questionIndex, answerIndex)}
						>
							{answer.answer}
						</Button>
					</div>
				{/each}
			</div>
		</li>
	{/each}
</ul>
