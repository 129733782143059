import template from './order-history.ractive.html'
import makeButton from '@isoftdata/button'
import makeCustomerOrderListItem from 'components/customer-order-list-item'
import { getCompletedOrders } from 'utility/load-completed-orders'
import ractiveTransitionsSlide from 'ractive-transitions-slide'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.customer.order-history',
		route: 'order-history',
		querystringParameters: [ 'jobId' ],
		template: {
			template,
			components: {
				itButton: makeButton(),
				customerOrderListItem: makeCustomerOrderListItem(),
			},
			transitions: {
				slide: ractiveTransitionsSlide,
			},
			computed: {
				customerHasJobsFromMultipleCities() {
					return new Set(this.get('jobs').map(job => `${job.residence.city}${job.residence.state}`)).size > 1
				},
			},
		},
		async resolve(_data, parameters) {
			const completeOrders = await getCompletedOrders()

			const selectedJobId = parseInt(parameters.jobId, 10) || completeOrders?.[0]?.id || undefined

			// go to detail state if we are taking the first jobId off the list
			if (selectedJobId) {
				stateRouter.go('app.customer.order-history.detail', { jobId: selectedJobId }, { inherit: true, replace: true })
			}

			return {
				//past would probably be both expired and competed status
				jobs: completeOrders,
				selectedJobId,
			}
		},
		activate({ domApi: ractive }) {
			/*
				Ensure the selected job is visible in the list
				focus seems to give us better behavior than scrollIntoView
				as far as moving the list only when the job in the list is not already visible
			*/
			ractive.find(`#job-id-${ ractive.get('selectedJobId') }`)?.focus()
		},
	})
}
