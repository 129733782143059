export default {
    "name": "GetNotificationPreferences",
    "kind": "HoudiniQuery",
    "hash": "55de9038dc4668e101defda86d52df557954071858f6c2f950666c7dfdd2b134",

    "raw": `query GetNotificationPreferences {
  session {
    user {
      sendJobScheduledEmails: getUserSetting(
        lookup: {category: "Notification settings", name: "Send job scheduled emails", settingType: PREFERENCE, defaultValue: "True"}
      ) {
        value
      }
      sendJobClaimedEmails: getUserSetting(
        lookup: {category: "Notification settings", name: "Send job claimed emails", settingType: PREFERENCE, defaultValue: "True"}
      ) {
        value
      }
      sendJobCompletedEmails: getUserSetting(
        lookup: {category: "Notification settings", name: "Send job completed emails", settingType: PREFERENCE, defaultValue: "True"}
      ) {
        value
      }
      sendJobDelayedEmails: getUserSetting(
        lookup: {category: "Notification settings", name: "Send job delayed emails", settingType: PREFERENCE, defaultValue: "True"}
      ) {
        value
      }
      sendJobAvailableEmails: getUserSetting(
        lookup: {category: "Notification settings", name: "Provider: Send job available emails", settingType: PREFERENCE, defaultValue: "True"}
      ) {
        value
      }
      sendTipReceivedEmails: getUserSetting(
        lookup: {category: "Notification settings", name: "Provider: Send tip received emails", settingType: PREFERENCE, defaultValue: "True"}
      ) {
        value
      }
      sendJobAvailablePush: getUserSetting(
        lookup: {category: "Notification settings", name: "Provider: Send job available push notifications", settingType: PREFERENCE, defaultValue: "False"}
      ) {
        value
      }
      sendTipReceivedPush: getUserSetting(
        lookup: {category: "Notification settings", name: "Provider: Send tip received push notifications", settingType: PREFERENCE, defaultValue: "False"}
      ) {
        value
      }
      id
    }
  }
  getServerInformation {
    publicVapidKey
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "sendJobScheduledEmails": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendJobScheduledEmails(lookup: {category: \"Notification settings\", name: \"Send job scheduled emails\", settingType: PREFERENCE, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendJobClaimedEmails": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendJobClaimedEmails(lookup: {category: \"Notification settings\", name: \"Send job claimed emails\", settingType: PREFERENCE, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendJobCompletedEmails": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendJobCompletedEmails(lookup: {category: \"Notification settings\", name: \"Send job completed emails\", settingType: PREFERENCE, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendJobDelayedEmails": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendJobDelayedEmails(lookup: {category: \"Notification settings\", name: \"Send job delayed emails\", settingType: PREFERENCE, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendJobAvailableEmails": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendJobAvailableEmails(lookup: {category: \"Notification settings\", name: \"Provider: Send job available emails\", settingType: PREFERENCE, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendTipReceivedEmails": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendTipReceivedEmails(lookup: {category: \"Notification settings\", name: \"Provider: Send tip received emails\", settingType: PREFERENCE, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendJobAvailablePush": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendJobAvailablePush(lookup: {category: \"Notification settings\", name: \"Provider: Send job available push notifications\", settingType: PREFERENCE, defaultValue: \"False\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "sendTipReceivedPush": {
                                        "type": "SettingResult",
                                        "keyRaw": "sendTipReceivedPush(lookup: {category: \"Notification settings\", name: \"Provider: Send tip received push notifications\", settingType: PREFERENCE, defaultValue: \"False\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "getServerInformation": {
                "type": "ServerInformation",
                "keyRaw": "getServerInformation",

                "selection": {
                    "fields": {
                        "publicVapidKey": {
                            "type": "String",
                            "keyRaw": "publicVapidKey",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=37e380987a8a1db380b3af95068895e7eacb6d2d06b30f52550f707e732d1588";