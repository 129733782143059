import component from './Job.svelte'

import { adminJobsComputedJob, loadAdminJobs } from 'utility/admin-jobs-helper'

import { AppContext } from 'types/common'
import { datesFromRange } from '@isoftdata/utility-date-time'
import { JobOrderBy } from '$houdini/graphql'
import { endOfDay, parseISO, startOfDay } from 'date-fns'

const DEFAULT_RANGE = 'Last 7 Days'

const creationDate = {
	from: {
		field: 'createdFrom',
		formatter: (date: string) => startOfDay(parseISO(date)),
	},
	to: {
		field: 'createdTo',
		formatter: (date: string) => endOfDay(parseISO(date)),
	},
}

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.manage-job.job',
		route: 'job',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const defaultDates = {
				fromDate: () => datesFromRange(DEFAULT_RANGE).from,
				toDate: () => datesFromRange(DEFAULT_RANGE).to,
			}
			const datesFilter = {
				from: creationDate.from.formatter?.(defaultDates.fromDate()) ?? defaultDates.fromDate(),
				to: creationDate.to.formatter?.(defaultDates.toDate()) ?? defaultDates.toDate(),
			}
			const jobRes = await loadAdminJobs(
				{
					createdFrom: datesFilter.from,
					createdTo: datesFilter.to,
				},
				{
					pageSize: 50,
					pageNumber: 1,
				},
				[JobOrderBy.ID_DESC]
			)

			const dates = {
				from: defaultDates.fromDate(),
				to: defaultDates.toDate(),
			}

			const computedJobs = jobRes.data.map(job => {
				return adminJobsComputedJob(job)
			})

			return {
				jobs: computedJobs,
				pagination: jobRes.info,
				dates,
				DEFAULT_RANGE,
			}
		},
	})
}
