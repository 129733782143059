import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadProviderAvailableServices'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadProviderAvailableServicesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadProviderAvailableServicesStore",
			variables: true,
		})
	}
}

export async function load_LoadProviderAvailableServices(params) {
	await initClient()

	const store = new LoadProviderAvailableServicesStore()

	await store.fetch(params)

	return {
		LoadProviderAvailableServices: store,
	}
}
