export function formatCurrency(currencyString: string | null | undefined, fallbackString = 'N/A') {
	if (!currencyString) {
		return fallbackString
	}
	return new Intl.NumberFormat(navigator.language || 'en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(parseFloat(currencyString))
}

export default formatCurrency
