<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import { upsert } from '@isoftdata/utility-array'

	import { addCustomerCardToWallet, updateCustomerCardInWallet, type PaymentMethodCard, type PaymentMethodSuccessResponse } from 'utility/payment-method-helper'
	import type { Mediator } from 'services/api-fetch'
	import { getSession } from 'stores/session'
	import CardPicker from 'components/CardPicker.svelte'
	import NewCardEntry from 'components/NewCardEntry.svelte'

	import { getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	export let cards: PaymentMethodCard[] = []

	let session = getSession()
	let showCreditCardModal = false
	let editing: boolean = false
	let selectedCardId: number | null = null
	let modalError: string | null = null

	async function addCardToWallet(card: PaymentMethodSuccessResponse) {
		try {
			const newCard = await addCustomerCardToWallet(card)
			cards.push(newCard)
			cards = cards
			showCreditCardModal = false
			modalError = null
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: 'An error occurred while adding your card to your wallet', title: 'Error Adding Card' })
		}
	}

	async function updateCardInWallet(card: PaymentMethodSuccessResponse) {
		try {
			if (!selectedCardId) {
				throw new Error('No card selected to update.')
			}
			const updatedCard = await updateCustomerCardInWallet(card, selectedCardId)
			cards = upsert(cards, 'id', updatedCard)
			showCreditCardModal = false
			modalError = null
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: 'An error occurred while updating your card in your wallet', title: 'Error Updating Card' })
		}
	}

	function openCreditCardModal(updateCard: boolean, event: CustomEvent<number> | null = null) {
		if (updateCard && event) {
			// The event.detail in this case is the card ID
			selectedCardId = event.detail
			editing = true
		} else {
			selectedCardId = null
			editing = false
		}
		showCreditCardModal = true
	}

	async function editCardInWalletTokenSuccess(e: CustomEvent<PaymentMethodSuccessResponse>) {
		if (e.detail.cardType === 'amex') {
			modalError = 'American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.'
		} else {
			await updateCardInWallet(e.detail)
		}
	}

	function editCardInWalletTokenError(e: CustomEvent<any>) {
		modalError = 'An unknown error occurred while editing your card in your wallet. Please try again later.'
	}

	async function addCardToWalletTokenSuccess(e: CustomEvent<PaymentMethodSuccessResponse>) {
		if (e.detail.cardType === 'amex') {
			modalError = 'American Express cards are not currently supported. Please use a Visa, Mastercard, or Discover card.'
		} else {
			await addCardToWallet(e.detail)
		}
	}

	function addCardToWalletTokenError(e: CustomEvent<any>) {
		modalError = 'An unknown error occurred while adding your card to your wallet. Please try again later.'
	}
</script>

<div class="m-4">
	{#if cards.length === 0}
		<div class="form-row flex-row-reverse mb-3">
			<Button
				id="add-payment-method-button"
				title="Add Payment Method"
				class="responsive-button btn"
				icon={{
					icon: 'plus',
				}}
				on:click={() => openCreditCardModal(false)}>Add Payment Method</Button
			>
		</div>
	{/if}
	<h3>Payment Methods</h3>
	<hr />
	{#if cards.length < 1}
		<span>You don't have any payment methods.</span>
	{/if}
	{#if cards.length > 0}
		<CardPicker
			bind:cards
			showUpdateButton={true}
			on:editCard={e => openCreditCardModal(true, e)}
		/>
	{/if}
	{#if cards.length > 1}
		<div>
			<span class="text-danger">
				<i class="fa-regular fa-circle-explanation"></i> We will now only save one credit card for you. Please remove the second card.
			</span>
		</div>
	{/if}
</div>

<Modal
	bind:show={showCreditCardModal}
	title={editing ? 'Update Card' : 'Add New Payment Method'}
	on:close={() => {
		showCreditCardModal = false
		modalError = null
	}}
	on:backdropClick={() => {
		showCreditCardModal = false
		modalError = null
	}}
	cancelButtonText="Cancel"
	confirmShown={false}
>
	<NewCardEntry
		bind:fullName={session.fullName}
		on:tokenSuccess={editing ? e => editCardInWalletTokenSuccess(e) : e => addCardToWalletTokenSuccess(e)}
		on:tokenError={editing ? e => editCardInWalletTokenError(e) : e => addCardToWalletTokenError(e)}
		submitButtonText={editing ? 'Update Card' : 'Add Card to Wallet'}
	/>
	{#if modalError}
		<div
			class="alert alert-danger"
			role="alert"
		>
			{modalError}
		</div>
	{/if}
</Modal>
