<script lang="ts">
	import { updateProviderServiceStatusMutation, type ProviderManagementProvider, type ProviderManagementService } from 'utility/provider-management-helper'
	import SwitchCard from './SwitchCard.svelte'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Input from '@isoftdata/svelte-input'

	import { type ComponentEvents, type ComponentProps, createEventDispatcher } from 'svelte'

	export let provider: ProviderManagementProvider
	export let serviceList: ProviderManagementService[] = []
	export let servicesOpen = false
	let filter = ''

	let switchItemList: ComponentProps<SwitchCard>['itemList']
	let filteredSwitchItemList: ComponentProps<SwitchCard>['itemList']

	const dispatch = createEventDispatcher()

	$: switchItemList = serviceList.map(service => {
		return {
			id: service.id,
			name: service.name,
			checked: !!provider.authorizedServices?.find(s => s.service.id === service.id),
		}
	})

	$: if (filter) {
		filteredSwitchItemList = switchItemList.filter(region => region.name.toLowerCase().includes(filter.toLowerCase()))
	} else {
		filteredSwitchItemList = switchItemList
	}

	async function updateService(event: ComponentEvents<SwitchCard>['change']) {
		const certified = event.detail.checked
		const serviceId = event.detail.id
		try {
			const { data } = await updateProviderServiceStatusMutation.mutate({
				certifiedServicesPermission: {
					providerId: provider.id,
					serviceId,
					certified,
				},
			})
			if (data?.setProviderService) {
				provider.authorizedServices = data.setProviderService.authorizedServices
			}
			dispatch('updateProvider', { provider })
		} catch (error) {
			console.error(error)
			alert('An error occurred when giving the user permission to the service. Please try again later. If the problem persists, please contact support.')
		}
	}
</script>

<CollapsibleCard
	entireHeaderToggles
	cardClass="mb-1 w-100"
	cardHeaderClass="d-flex justify-content-between align-items-center card-header h5"
	bind:bodyShown={servicesOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<span>
			{provider.userAccount.firstName}'s Services
			<span class="badge badge-primary mx-2">{provider.authorizedServices?.length ?? 0}</span>
		</span>
	</svelte:fragment>
	<Input
		type="text"
		placeholder="Search Services"
		bind:value={filter}
		showLabel={false}
	/>
	{#if filteredSwitchItemList.length > 0}
		<SwitchCard
			showLabel={false}
			itemList={filteredSwitchItemList}
			on:change={updateService}
		/>
	{:else}
		<div
			class="alert alert-info w-100"
			role="alert"
		>
			No regions found
		</div>
	{/if}
</CollapsibleCard>
