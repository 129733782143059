<script lang="ts">
	import Table, { Td } from '@isoftdata/svelte-table'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import CustomerQuoteOptions, { type Question, type Answer } from 'components/CustomerQuoteOptions.svelte'

	import {
		getQuestionsFromResidenceServiceHelper,
		getServices,
		updateAnswerToResidence,
		formatResidenceResult,
		updateResidenceSize,
		type AnswerToTag,
		type HelperTag,
		type FormattedResidence,
	} from 'utility/residence-helper'
	import type { LoadResidenceHelperServices$input, LoadResidenceHelperServices$result } from '$houdini'
	import residencesQuery, { getResidences } from 'utility/graphql/residences-query'

	type QuestionWithAnswers = Record<number, Answer[]>
	type ServiceQuestions = Record<
		number,
		{
			questionId: number
			question: string
		}[]
	>

	export let residenceTags: HelperTag[] = []
	export let residences: FormattedResidence[] = []
	export let answersToTags: AnswerToTag[] = []
	export let totalItems: number
	export let pageSize: number = 10
	export let pageNumber: number = 1

	let residenceTagFilter: number | null = null
	let residenceSearchFilter: string | null = null
	let selectedResidence: FormattedResidence | null = null
	let selectedResidenceServiceQuestions: ServiceQuestions = {}
	let selectedResidenceQuestionAnswers: QuestionWithAnswers = {}

	let residenceSizeModalShow: boolean = false
	let useGISValue: boolean = false
	let effectiveLawnSquareFootage: number | null = null
	let isSavingResidenceSize: boolean = false
	let residenceSizeModalResidence: ResidenceSizeModalStateResidence | null = null

	let residenceAnswersModalShow: boolean = false
	let tagId: number | null = null
	let selectedServiceId: number | null = null
	let questions: Question[] = []
	let residenceAnswersModalSelectedAnswerIds: number[] = []
	let availableServicesForSelectedResidence: LoadResidenceHelperServices$result['services']['data'] = []

	interface ResidenceSizeModalStateResidence extends Omit<FormattedResidence, 'id' | 'regionId' | 'squareFootage'> {
		id: number
		regionId: number
		squareFootage: number
		formattedGISSquareFootage: string | null
	}

	async function loadResidences() {
		const residencesResult = await getResidences({
			filter: {
				residenceStatus: 'ACTIVE',
				tagId: residenceTagFilter,
				residenceKeywordSearch: residenceSearchFilter ? residenceSearchFilter : undefined,
			},
			pagination: {
				pageNumber,
				pageSize,
			},
		})

		const formattedRes = residencesResult.data?.residences.data.map(res => formatResidenceResult(res, answersToTags))
		if (!formattedRes) {
			return
		}

		residences = formattedRes
		totalItems = residencesResult.data?.residences.info.totalItems || 0
		pageSize = residencesResult.data?.residences.info.pageSize || 0
		pageNumber = residencesResult.data?.residences.info.pageNumber || 0
	}

	async function selectResidence(residenceId: number) {
		selectedResidence = residences.find(residence => residence.id === residenceId) || null
	}

	function editResidenceSquareFootage(residenceId: number) {
		const foundResidence = residences.find(residence => residence.id === residenceId)

		if (!foundResidence) {
			return
		}

		residenceSizeModalShow = true
		useGISValue = !foundResidence.effectiveLawnSquareFootage
		residenceSizeModalResidence = {
			...foundResidence,
			formattedGISSquareFootage: new Intl.NumberFormat('en-US').format(foundResidence.estimatedLawnSquareFootage),
		}
		effectiveLawnSquareFootage = foundResidence.effectiveLawnSquareFootage
		isSavingResidenceSize = false
	}

	async function openAnswerModal() {
		if (!selectedResidence) {
			return
		}

		const filter: LoadResidenceHelperServices$input = {
			filter: {
				regionId: selectedResidence.regionId,
				active: true,
			},
		}

		const services = await getServices(filter)
		const serviceQuestionsInSelectedRegion = await getQuestionsFromResidenceServiceHelper({ residenceId: selectedResidence.id })
		selectedResidenceServiceQuestions = serviceQuestionsInSelectedRegion.reduce((acc: ServiceQuestions, serviceWithQuestions) => {
			acc[serviceWithQuestions.serviceId] = serviceWithQuestions.questions.map(question => {
				selectedResidenceQuestionAnswers[question.id] = question.answers.map(answer => {
					return {
						id: answer.id,
						answer: answer.answer,
						selected: answer.selected,
					}
				})
				return {
					questionId: question.id,
					question: question.question,
				}
			})
			return acc
		}, {})
		getSelectedServiveQuestionsWithAnswers(services[0].id)

		residenceAnswersModalShow = true
		availableServicesForSelectedResidence = services
		selectedServiceId = services[0].id
		tagId = null
	}

	async function setResidenceSize() {
		try {
			if (!residenceSizeModalResidence?.id) {
				throw new Error('No residence selected')
			}
			const updatedResidence = await updateResidenceSize(residenceSizeModalResidence.id, useGISValue ? null : effectiveLawnSquareFootage)

			const formattedRes = formatResidenceResult(updatedResidence, answersToTags)

			const residenceIndex = residences.findIndex(res => res.id === residenceSizeModalResidence?.id)

			if (residenceIndex !== -1) {
				residences[residenceIndex] = formattedRes
			}

			residences = residences
		} catch (error) {
			console.error(error)
		} finally {
			residenceSizeModalShow = false
			isSavingResidenceSize = false
			residenceSizeModalResidence = null
			useGISValue = false
		}
	}

	async function updateAnswers() {
		try {
			if (!selectedResidence?.id) {
				throw new Error('No residence selected')
			}
			const updatedResidence = await updateAnswerToResidence(residenceAnswersModalSelectedAnswerIds, selectedResidence.id)

			const formattedRes = formatResidenceResult(updatedResidence, answersToTags)

			const residenceIndex = residences.findIndex(res => res.id === selectedResidence?.id)

			if (residenceIndex !== -1) {
				residences[residenceIndex] = formattedRes
			}

			residences = residences

			residenceAnswersModalShow = false
			tagId = null
			selectedServiceId = null
			questions = []
			residenceAnswersModalSelectedAnswerIds = []
			availableServicesForSelectedResidence = []
		} catch (error) {
			console.error(error)
		}
	}

	function getSelectedServiveQuestionsWithAnswers(serviceId: number) {
		const selectedServiceQuestions = selectedResidenceServiceQuestions[serviceId]
		const selectedServiceQuestionsQuestionsWithAnswers = selectedServiceQuestions.map(question => ({
			id: question.questionId,
			question: question.question,
			answers: selectedResidenceQuestionAnswers[question.questionId],
		}))

		const selectedResidenceAnswerIds = selectedServiceQuestionsQuestionsWithAnswers.reduce((acc, question) => {
			const ans = question.answers.find(answer => answer.selected)

			if (ans) {
				acc.push(ans.id)
			}
			return acc
		}, new Array<number>())

		questions = selectedServiceQuestionsQuestionsWithAnswers
		residenceAnswersModalSelectedAnswerIds = selectedResidenceAnswerIds
	}
</script>

<div class="container-fluid mt-3">
	<div class="form-row align-items-end justify-content-between">
		<div class="col-12 col-md-3">
			<Select
				label="Residence Tag Filter"
				bind:value={residenceTagFilter}
				emptyText="Any Status"
				on:change={() => {
					pageNumber = 1
					selectedResidence = null
					loadResidences()
				}}
			>
				{#each residenceTags as tag}
					<option value={tag.id}>{tag.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12 col-md-3">
			<Input
				label="Residence Filter"
				bind:value={residenceSearchFilter}
				on:change={() => {
					pageNumber = 1
					selectedResidence = null
					loadResidences()
				}}
			/>
		</div>
	</div>
	{#if $residencesQuery.fetching}
		<i class="h3 fa-solid fa-spinner-third fa-spin"></i>
	{:else}
		<Table
			responsive
			bind:rows={residences}
			columns={[
				{ property: 'customerName', name: 'Customer Name' },
				{ property: 'customerEmail', name: 'Customer Email' },
				{ property: 'formattedResidence', name: 'Address' },
				{ property: 'squareFootage', name: 'sq. ft.' },
			]}
			filterEnabled={false}
			showFilterLabel={false}
			paginationEnabled
			totalItemsCount={totalItems}
			perPageCount={pageSize}
			currentPageNumber={pageNumber}
			on:pageChange={e => {
				pageNumber = e.detail.pageNumber
				selectedResidence = null
				loadResidences()
			}}
			let:row
		>
			<svelte:fragment slot="no-rows">
				<tr>
					<td
						colspan="4"
						class="text-center">No residences found</td
					>
				</tr>
			</svelte:fragment>

			<tr
				class="cursor-pointer"
				class:table-dark={selectedResidence?.id === row.id}
				on:click={() => {
					if (selectedResidence?.id === row.id) {
						selectedResidence = null
					} else {
						selectResidence(row.id)
					}
				}}
			>
				<Td property="customerName">{row.customerName}</Td>
				<Td property="customerEmail">
					<a href={`mailto:${row.customerEmail}`}>{row.customerEmail}</a>
				</Td>
				<Td property="formattedResidence">{row.formattedResidence}</Td>
				<Td
					property="squareFootage"
					stopPropagation
				>
					<Button
						iconClass="pencil"
						size="xs"
						on:click={() => editResidenceSquareFootage(row.id)}
					/>
					{row.formattedSquareFootage}
					{#if row.effectiveLawnSquareFootage}
						<span class="badge badge-primary">Custom</span>
					{/if}
				</Td>
			</tr>
			{#if selectedResidence?.id === row.id}
				<tr>
					<td colspan="4">
						<div class="d-flex justify-content-between">
							<div>
								{#each row.residenceTags as tag}
									<span class="badge badge-pill badge-primary mx-1">{tag.code}</span>
								{/each}
							</div>
							<Button
								size="xs"
								iconClass="pencil"
								on:click={openAnswerModal}>Edit Answers</Button
							>
						</div>
					</td>
				</tr>
			{/if}
		</Table>
	{/if}
</div>

<Modal
	bind:show={residenceSizeModalShow}
	title="Edit Residence Lawn Square Footage"
	confirmButtonDisabled={isSavingResidenceSize || (!useGISValue && !effectiveLawnSquareFootage)}
	on:confirm={setResidenceSize}
	on:close={() => {
		residenceSizeModalShow = false
		useGISValue = false
		effectiveLawnSquareFootage = null
		residenceSizeModalResidence = null
		isSavingResidenceSize = false
	}}
>
	<h4>{residenceSizeModalResidence?.street}</h4>
	<div class="form-check">
		<input
			id="useGis"
			type="checkbox"
			bind:checked={useGISValue}
			class="form-check-input"
		/>
		<label
			for="useGis"
			class="form-check-label">Use the {residenceSizeModalResidence?.formattedGISSquareFootage} square feet from GIS</label
		>
	</div>

	<hr />

	<Input
		id="lawnSquareFootage"
		label="Effective Lawn Square Footage"
		type="number"
		bind:value={effectiveLawnSquareFootage}
		disabled={useGISValue}
	/>
	{#if !useGISValue}
		<small class="text-dark">Setting this value will not affect existing orders. It will take effect the next time an order is placed for this residence.</small>
	{/if}
</Modal>

<Modal
	bind:show={residenceAnswersModalShow}
	title={selectedResidence?.formattedResidence}
	on:confirm={updateAnswers}
	on:close={() => {
		residenceAnswersModalShow = false
		tagId = null
		selectedServiceId = null
		questions = []
		residenceAnswersModalSelectedAnswerIds = []
		availableServicesForSelectedResidence = []
	}}
>
	<Select
		label="Filter by Service"
		bind:value={selectedServiceId}
		showEmptyOption={false}
		on:change={() => {
			if (!selectedServiceId) return
			getSelectedServiveQuestionsWithAnswers(selectedServiceId)
		}}
	>
		{#each availableServicesForSelectedResidence as service}
			<option value={service.id}>{service.name}</option>
		{/each}
	</Select>
	{#if questions}
		<CustomerQuoteOptions
			{questions}
			bind:selectedAnswerIds={residenceAnswersModalSelectedAnswerIds}
		/>
	{/if}
</Modal>
