export default {
    "name": "SetProviderApplicationWaitlist",
    "kind": "HoudiniMutation",
    "hash": "cd2fa0438f4cf0e26f59973a3578d263007dd50232a16d235af9f992fa2a4202",

    "raw": `mutation SetProviderApplicationWaitlist($moveToWaitlist: Boolean!, $providerOnboardingApplicationId: PositiveInt!) {
  setProviderApplicationWaitlist(
    moveToWaitlist: $moveToWaitlist
    providerOnboardingApplicationId: $providerOnboardingApplicationId
  ) {
    id
    inWaitlist
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setProviderApplicationWaitlist": {
                "type": "ProviderApplication",
                "keyRaw": "setProviderApplicationWaitlist(moveToWaitlist: $moveToWaitlist, providerOnboardingApplicationId: $providerOnboardingApplicationId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "inWaitlist": {
                            "type": "Boolean",
                            "keyRaw": "inWaitlist",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "moveToWaitlist": "Boolean",
            "providerOnboardingApplicationId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c7a3ca7c2e0b0e1084e1ff33501f521d3bb3b2f0317e7c78d005aa82a7ec43ab";