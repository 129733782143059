import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadQuestions'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadQuestionsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadQuestionsStore",
			variables: false,
		})
	}
}

export async function load_LoadQuestions(params) {
	await initClient()

	const store = new LoadQuestionsStore()

	await store.fetch(params)

	return {
		LoadQuestions: store,
	}
}
