import artifact from '$houdini/artifacts/TestPushNotification'
import { MutationStore } from '../runtime/stores/mutation'

export class TestPushNotificationStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
