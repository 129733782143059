export default {
    "name": "UpdateProviderStatusMutationProviderManagement",
    "kind": "HoudiniMutation",
    "hash": "e0d1c20f90bbd9a00f172da784f35c4905e529caffb5681f04d4f26c40a2506c",

    "raw": `mutation UpdateProviderStatusMutationProviderManagement($providerId: PositiveInt!, $status: ProviderStatus!) {
  setProviderStatus(providerId: $providerId, status: $status) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setProviderStatus": {
                "type": "Provider",
                "keyRaw": "setProviderStatus(providerId: $providerId, status: $status)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerId": "PositiveInt",
            "status": "ProviderStatus"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ea996ce223e2e82e4d5524bd4f27afbafb3ecc39fe15a4401cc43cb2f74b7c9a";