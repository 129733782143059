import {
	graphql,
	UserProfileSessionQueryStore,
	UpdateUserAccountInfoMutationStore,
	type UserProfileSessionQuery$result,
	ChangeUserPasswordStore,
	LoadUserZonesQueryStore,
	type LoadUserZonesQuery$result,
	UserProfileProviderDOBUpdateMutationStore,
	UserProfileUpdateProviderHomeRegionStore,
} from '$houdini'

export type UserAccountSession = Exclude<Exclude<UserProfileSessionQuery$result['session'], null>['user'], null>

export type UserZone = LoadUserZonesQuery$result['getUserZones'][number]['zone']

const userZonesQuery: LoadUserZonesQueryStore = graphql`
	query LoadUserZonesQuery($userId: PositiveInt!) {
		getUserZones(userId: $userId) {
			zone {
				id
				name
				state
				regions {
					id
					name
					status
				}
			}
		}
	}
`

const userProfileProviderDOBUpdateMutation: UserProfileProviderDOBUpdateMutationStore = graphql`
	mutation UserProfileProviderDOBUpdateMutation($dateOfBirth: DateTime!, $providerId: PositiveInt!) {
		updateProviderDateOfBirth(dateOfBirth: $dateOfBirth, providerId: $providerId) {
			id
			dateOfBirth
		}
	}
`

const userProfileQuery: UserProfileSessionQueryStore = graphql`
	query UserProfileSessionQuery {
		session {
			user {
				id
				email
				firstName
				lastName
				fullName
				recoveryEmail
				mobile
				provider {
					id
					dateOfBirth
					homeRegionId
					profilePictureFile {
						id
						path
					}
				}
			}
		}
	}
`

const userProfileUpdateProviderHomeRegionMutation: UserProfileUpdateProviderHomeRegionStore = graphql`
	mutation UserProfileUpdateProviderHomeRegion($providerId: PositiveInt!, $regionId: PositiveInt!) {
		updateProviderHomeRegion(providerId: $providerId, regionId: $regionId) {
			id
			homeRegionId
		}
	}
`

const updateUserAccountInfoMutation: UpdateUserAccountInfoMutationStore = graphql`
	mutation UpdateUserAccountInfoMutation($userAccountInfo: UpdateUserAccountInfo!) {
		updateAccountInfo(userAccountInfo: $userAccountInfo) {
			recoveryEmail
			firstName
			fullName
			lastName
			customer {
				id
				billingCountry
				billingZip
				billingState
				billingCity
				billingStreet
				ownedResidences {
					data {
						id
						street
						city
						state
						zip
					}
				}
			}
			provider {
				id
				status
				profilePictureFile {
					path
				}
				latestOnboardingApplication {
					id
					status
				}
			}
			userRoles {
				permissionName
				value
			}
		}
	}
`

const changePasswordMutation: ChangeUserPasswordStore = graphql`
	mutation ChangeUserPassword($newPassword: String!, $email: String!, $currentPassword: String!) {
		changePassword(newPassword: $newPassword, email: $email, currentPassword: $currentPassword)
	}
`

export async function getUserProfileSession() {
	const res = await userProfileQuery.fetch()

	if (!res.data?.session?.user) {
		return null
	}

	return res.data.session.user
}

export async function updateUserAccountInfo(userAccountInfo: { id: number; firstName: string; lastName: string; mobile: string | null }) {
	const res = await updateUserAccountInfoMutation.mutate({
		userAccountInfo,
	})

	if (!res.data?.updateAccountInfo) {
		throw new Error('Failed to update user account info')
	}

	return res.data.updateAccountInfo
}

export async function changeUserPassword(newPassword: string, currentPassword: string, email: string) {
	const res = await changePasswordMutation.mutate({ newPassword, currentPassword, email })

	if (!res.data?.changePassword) {
		throw new Error('Failed to change password')
	}

	return res.data.changePassword
}

export async function getUserZones(userId: number) {
	const res = await userZonesQuery.fetch({
		variables: {
			userId,
		},
	})

	if (!res.data?.getUserZones) {
		throw new Error('Failed to load user zones')
	}

	return res.data.getUserZones.map(zone => zone.zone)
}

export async function updateUserDOB(dob: Date, providerId: number) {
	const res = await userProfileProviderDOBUpdateMutation.mutate({
		dateOfBirth: dob,
		providerId,
	})

	if (!res.data?.updateProviderDateOfBirth) {
		throw new Error('Failed to update date of birth')
	}

	return res.data.updateProviderDateOfBirth
}

export async function updateUserHomeRegion(providerId: number, regionId: number) {
	const res = await userProfileUpdateProviderHomeRegionMutation.mutate({
		providerId,
		regionId,
	})

	if (!res.data?.updateProviderHomeRegion) {
		throw new Error('Failed to update home region')
	}

	return res.data.updateProviderHomeRegion
}
