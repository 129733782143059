import { graphql, GetGlobalSettingStore, type GetGlobalSetting$input, SetGlobalSettingStore } from '$houdini'

const globalSettingQuery: GetGlobalSettingStore = graphql`
	query GetGlobalSetting($lookup: SettingLookup!) {
		getGlobalSetting(lookup: $lookup) {
			value
		}
	}
`

const globalSettingMutation: SetGlobalSettingStore = graphql`
	mutation SetGlobalSetting($value: SettingChange!) {
		setGlobalSetting(value: $value)
	}
`

/**
 * @param lookup standard lookup object for the setting
 * @returns A string value of the global setting that the user gets to parse
 */
export async function loadGlobalSetting(lookup: { category: string; name: string; defaultValue: string; settingType: GetGlobalSetting$input['lookup']['settingType'] }) {
	const { data } = await globalSettingQuery.fetch({
		variables: {
			lookup,
		},
	})

	if (!data) {
		return lookup.defaultValue
	}

	return data.getGlobalSetting.value
}

/**
 * @param value the lookup object for the setting with default value replaced with the new value
 * @throws If the mutation fails
 * @returns The new value of the setting that the user set (User is responsible for parsing the value)
 */
export async function setGlobalSetting(value: { category: string; name: string; newValue: string; settingType: GetGlobalSetting$input['lookup']['settingType'] }) {
	try {
		await globalSettingMutation.mutate({
			value,
		})
	} catch (error) {
		throw new Error('Failed to set global setting')
	}

	return value.newValue
}
