export default {
    "name": "ChangeUserPassword",
    "kind": "HoudiniMutation",
    "hash": "eff8d1aa1c4aded17a6be479fafe4ea917be504fecedac2be7d59c854a657faf",

    "raw": `mutation ChangeUserPassword($newPassword: String!, $email: String!, $currentPassword: String!) {
  changePassword(
    newPassword: $newPassword
    email: $email
    currentPassword: $currentPassword
  )
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "changePassword": {
                "type": "Boolean",
                "keyRaw": "changePassword(currentPassword: $currentPassword, email: $email, newPassword: $newPassword)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newPassword": "String",
            "email": "String",
            "currentPassword": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=937c1148de148b50433cc3937037e34b23572ff7e82d3514ecdbfaf22cf8cd99";