<script lang="ts">
	import type { PaymentMethodSuccessResponse } from 'utility/payment-method-helper'

	import { onMount, createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	export let token: string = ''
	export let fullName: string | null = ''
	export let submitButtonText = 'Add Card'
	export let submitButtonDisabled = false

	// We do this on mount because we need to wait for the global payments configuration to finish after the component loads the original ui form
	onMount(() => {
		window.GlobalPayments.configure({
			env: '__heartlandApiEnv__',
			publicApiKey: '__heartlandPublicApiKey__',
			enableAutocomplete: true,
		})

		const cardForm = window.GlobalPayments.ui.form({
			fields: {
				'card-holder-name': {
					placeholder: 'Jane Smith',
					target: '#credit-card-card-holder',
					value: fullName ?? '',
				},
				'card-number': {
					placeholder: '•••• •••• •••• ••••',
					target: '#credit-card-card-number',
				},
				'card-expiration': {
					placeholder: 'MM / YYYY',
					target: '#credit-card-card-expiration',
				},
				'card-cvv': {
					placeholder: '•••',
					target: '#credit-card-card-cvv',
				},
				submit: {
					text: submitButtonText, //this property is undocumented, but allows you to change the submit button's text
					value: 'Submit',
					target: '#credit-card-submit',
				},
			},
			styles: {
				input: {
					margin: '2px !important;',
					display: 'block;',
					width: '100% !important;',
					height: 'calc(1.5em + .5rem + 2px);',
					padding: '.25rem .5rem;',
					'font-size': '.875rem;',
					'font-weight': '400;',
					'line-height': '1.5;',
					color: '#5a5a5a;',
					'background-color': '#fff;',
					'background-clip': 'padding-box;',
					border: '1px solid #ced4da;',
					'border-radius': '.3rem;',
				},
				button: {
					color: '#fff;',
					display: 'inline-block;',
					'font-weight': '400;',
					'text-align': 'center;',
					'vertical-align': 'middle;',
					'-webkit-user-select': 'none;',
					'-moz-user-select': 'none;',
					'-ms-user-select': 'none;',
					'user-select': 'none;',
					'background-color': '#78c2ad;',
					'border-color': '#78c2ad;',
					padding: '.375rem .75rem;',
					'font-size': '1rem;',
					'line-height': '1.5;',
					'border-radius': '.4rem;',
				},
			},
		})

		cardForm.ready(() => dispatch('credit-card-form-ready', true))

		// So because of undocumented behavior these DO have callbacks that have a res object. However the types want us to think its () => void
		// Anyway rabbit hole ensued and we are on V1 of globalpayments at somepoint we should look into V3 or the soon to be released V4

		//@ts-expect-error
		cardForm.on('token-success', (res: { details: PaymentMethodSuccessResponse; paymentReference: string }) => {
			dispatch('tokenSuccess', { ...res.details, token: res.paymentReference })
			token = res.paymentReference
		})

		//@ts-expect-error
		cardForm.on('token-error', res => {
			dispatch('tokenError', res)
		})

		cardForm.on('card-number', 'register', () => {
			dispatch('card-number-registered', true)
		})
	})
</script>

<!-- I want to remove the a11y-label warning below but because of the nature of what this compnent is doing hijacking global payments boxes I don't think we can-->
<!-- svelte-ignore a11y-label-has-associated-control -->
<label class="card-entry-label"
	>Name on Card
	<div id="credit-card-card-holder"></div>
</label>

<!-- svelte-ignore a11y-label-has-associated-control -->
<label class="card-entry-label"
	>Card Number (AMEX not accepted)
	<div id="credit-card-card-number"></div>
</label>

<div class="form-row">
	<!-- svelte-ignore a11y-label-has-associated-control -->
	<div class="col">
		<label class="card-entry-label"
			>CVV
			<div id="credit-card-card-cvv"></div>
		</label>
	</div>
	<div class="col">
		<!-- svelte-ignore a11y-label-has-associated-control -->
		<label class="card-entry-label"
			>Expiration Date
			<div id="credit-card-card-expiration"></div>
		</label>
	</div>
</div>

<slot />

<button
	class="btn btn-primary btn-block"
	class:d-none={!submitButtonDisabled}
	disabled>{submitButtonText}</button
>

<div
	id="credit-card-submit"
	class:d-none={submitButtonDisabled}
	class:credit-card-submit={!submitButtonDisabled}
></div>

<style>
	* :global(iframe) {
		width: 100%;
	}
	.card-entry-label {
		width: 100%;
	}
	* :global(.credit-card-submit > iframe) {
		height: 41px !important;
	}
</style>
