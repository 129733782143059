import template from './select-service.html'
import ractiveTransitionsSlide from 'ractive-transitions-slide'
import makeCheckbox from '@isoftdata/checkbox'

export default function createServiceSelectionComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				services: [],
				selectedServiceId: null,
				showWarning: true,
				alwaysShowServiceDescription: true,
				selectedAddOnIds: [],
			}
		},
		transitions: {
			slide: ractiveTransitionsSlide,
		},
		components: {
			itCheckbox: makeCheckbox(),
		},
		attributes: {
			required: [ 'services', 'selectedServiceId', 'showWarning', 'alwaysShowServiceDescription' ],
			optional: [ 'selectedAddOnIds' ],
		},
	})
}
