export default {
    "name": "LoadQuestionsFromResidenceServiceHelper",
    "kind": "HoudiniQuery",
    "hash": "93c69057a429c9dc4c6d9676d615d7dc2a59b5b9bded9d29ffb4e48c9893074a",

    "raw": `query LoadQuestionsFromResidenceServiceHelper($residenceId: PositiveInt!) {
  questionsFromResidenceService(residenceId: $residenceId) {
    serviceId
    questions {
      id
      question
      answers {
        id
        answer
        selected
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "questionsFromResidenceService": {
                "type": "ServiceAnsweredQuestions",
                "keyRaw": "questionsFromResidenceService(residenceId: $residenceId)",

                "selection": {
                    "fields": {
                        "serviceId": {
                            "type": "Float",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "questions": {
                            "type": "AnsweredQuestions",
                            "keyRaw": "questions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "question": {
                                        "type": "String",
                                        "keyRaw": "question",
                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "SelectedAnswer",
                                        "keyRaw": "answers",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                },

                                                "selected": {
                                                    "type": "Boolean",
                                                    "keyRaw": "selected",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "residenceId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c6d1bd4e30da25d07bc3776726732e9316376da98d24e40256d5f53650ecf9a4";