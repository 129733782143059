<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import TextArea from '@isoftdata/svelte-textarea'
	import Select from '@isoftdata/svelte-select'

	import { tagTypeList } from 'utility/tag-type-list'
	import { saveNewTag, type EntityType } from 'utility/tag-helper'

	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	export let show = false

	export let code: string | null = null
	export let name: string | null = null
	export let entityType: EntityType = 'RESIDENCE'
	export let description: string = ''
	export let prompt: string = ''
	let errorMessage: string = ''

	async function addNewTag() {
		if (!code || !name || !entityType) {
			errorMessage = 'Please fill in all required fields'
			return
		}
		try {
			const res = await saveNewTag({
				code,
				name,
				description,
				prompt,
				entityType,
			})

			dispatch('tagAdded', res)

			show = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			errorMessage = error.message
		}
	}
</script>

<Modal
	bind:show
	title="Add New Tag"
	cancelButtonText="Cancel"
	confirmButtonText="Create Tag"
	confirmButtonDisabled={!code || !name || !entityType}
	on:confirm={() => addNewTag()}
	on:close={() => (show = false)}
	on:backdropClick={() => (show = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				required
				title="Tag Code"
				id="tag-code-input"
				label="Tag Code"
				bind:value={code}
				type="text"
				validation={{
					value: code,
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				required
				title="Tag Name"
				id="tag-name-input"
				label="Name"
				bind:value={name}
				type="text"
				validation={{
					value: name,
				}}
			/>
		</div>
		<div class="col-12">
			<TextArea
				id="description-textarea"
				title="Description"
				label="Description"
				labelClass="py-0 mb-2"
				textareaStyle="min-height: 83px;"
				placeholder="Description about the tag..."
				bind:value={description}
			/>
		</div>
		<div class="col-12">
			<TextArea
				id="prompt-textarea"
				title="Prompt"
				label="Prompt"
				labelClass="py-0 mb-2"
				textareaStyle="min-height: 83px;"
				placeholder="A admin only description about the tag..."
				bind:value={prompt}
			/>
		</div>
		<div class="col-6">
			<Select
				showEmptyOption={false}
				id="tag-entity-type-select"
				title="Tag Type"
				label="Tag Type"
				bind:value={entityType}
			>
				{#each tagTypeList as tagType}
					<option value={tagType.value}>{tagType.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			{#if errorMessage}
				<div
					style="opacity: 1;"
					class="signin-message-box text-center alert alert-danger"
					role="alert"
				>
					<i class="fas fa-exclamation-circle"></i> <strong style="font-size: x-small">{errorMessage}</strong>
				</div>
			{/if}
		</div>
	</div>
</Modal>
