<script lang="ts">
	import { readAsDataURL } from '@isoftdata/promise-file-reader'
	import Input from '@isoftdata/svelte-input'
	import DateInput from '@isoftdata/svelte-date-input'
	import Select from '@isoftdata/svelte-select'

	import type { Mediator } from 'types/common'
	import formatE164 from 'utility/format/format-e164-phone-number'
	import validateEmail from 'utility/email-address-validate'
	import validatePhoneNumber from 'utility/phone-number-validate'
	import formatPhoneNumber from 'utility/format/format-phone-number'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import type { UserZone } from 'utility/profile-helper'
	import ProviderProfilePicture from './ProviderProfilePicture.svelte'

	import { graphql, AttachFileToProviderStore, type AttachFileToProvider$result } from '$houdini'
	import { createEventDispatcher, getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	// This is the interface type for what this component expects as a user account object. If it has more fields, they will be ignored. These are just the ones this component cares about.
	type UserAccount = {
		email: string
		mobile?: string | null
		firstName: string
		lastName: string
		provider?: {
			dateOfBirth: Date | null
			homeRegionId: number | null
			profilePictureFile: {
				path: string | null
			} | null
		} | null
	}

	// Typed event dispatcher
	const dispatch = createEventDispatcher<{
		change: undefined
		DOBChanged: undefined
		DOBChangedButton: undefined
		homeRegionChanged: undefined
	}>()

	export let userAccount: UserAccount
	export let disableOtherField = false
	export let disableEmailField = false
	export let showProfilePicture = true
	export let showHomeRegionSelect = false
	export let availableRegions: UserZone['regions'] = []
	export let requiredMobile = false
	export let descriptionForMobile: string | null = 'For SMS Notifications'
	export let isEditing: boolean = true
	export let dobChangedSaved: boolean = true
	export let phoneNumberIsValid = true
	export let emailIsValid = true
	export let showOptionalFieldsHint = false
	export let smallSpace: boolean = false

	const attachFileToProviderMutation: AttachFileToProviderStore = graphql`
		mutation AttachFileToProvider($input: NewProviderFileInput!) {
			attachFileToProvider(input: $input) {
				id
				profilePictureFile {
					path
				}
			}
		}
	`

	$: emailIsValid = userAccount.email ? validateEmail(userAccount.email) : false
	$: computedProfilePictureUrl = userAccount.provider?.profilePictureFile?.path ? formatImageFileUrl(userAccount.provider?.profilePictureFile?.path) : null
	$: formattedMobile = phoneNumberIsValid ? formatPhoneNumber(userAccount.mobile ?? '') : userAccount.mobile ?? ''
	// The Profile component is used in two different sized containers, so when smallSpace is true its in a heavily padded/margined container
	$: contactSizeBasedFieldClass = smallSpace ? 'col-md-6' : 'col-sm-12 col-md-6 col-lg-6 col-xl-6'
	$: sizeBasedFieldClass = smallSpace ? 'col-md-6' : 'col-sm-12 col-md-6 col-lg-4 col-xl-3'

	async function onFileUpload(event: Event) {
		const target = event.target as HTMLInputElement
		const files = target.files
		if (!userAccount.provider || !files || files.length === 0) {
			return
		}
		const fileString = await readAsDataURL(files[0])
		const prefixString = ';base64,'
		const base64EncodedData = fileString.substring(fileString.indexOf(prefixString) + prefixString.length)
		let uploadResult: AttachFileToProvider$result | null = null
		try {
			//format the input for uploading to the api here, the Profile state will create the endpoint for uploading the file
			const { data } = await attachFileToProviderMutation.mutate({
				input: {
					imageFile: {
						base64String: base64EncodedData,
						fileName: files[0].name,
					},
					imageFileType: 'PROFILE',
				},
			})
			uploadResult = data
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showMessage', {
				color: 'danger',
				heading: 'Error Uploading Profile Picture',
				message: error.message,
				time: 5000,
			})
		}

		if (!uploadResult) {
			return
		}

		const newProfilePictureFilePath = uploadResult.attachFileToProvider.profilePictureFile?.path ?? null

		if (!userAccount.provider.profilePictureFile) {
			userAccount.provider.profilePictureFile = {
				path: newProfilePictureFilePath,
			}
		} else {
			userAccount.provider.profilePictureFile.path = newProfilePictureFilePath
		}
	}

	function onPictureClick() {
		document.querySelector<HTMLInputElement>('#profilePictureInput')?.click()
	}

	function onEmailChange(event: Event) {
		userAccount.email = (event.target as HTMLInputElement).value
		if (emailIsValid) {
			updateUserInfo()
		}
	}

	function onMobileChange(event: Event) {
		if (event.target instanceof HTMLInputElement) {
			const newValue = event.target.value
			userAccount.mobile = newValue // this helps to update the section status for onboarding application
			phoneNumberIsValid = validatePhoneNumber(newValue)
			if (phoneNumberIsValid) {
				userAccount.mobile = formatE164(newValue)
				updateUserInfo()
			}
		}
	}

	function onDOBChange(event: Event) {
		if (event.target instanceof HTMLInputElement) {
			const newValue = event.target.value
			if (userAccount.provider) {
				const [year, month, day] = newValue.split('-')
				userAccount.provider.dateOfBirth = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
			}
			dispatch('DOBChanged')
		}
	}

	function updateUserInfo() {
		dispatch('change')
	}

	function updateUserHomeRegion(event: Event) {
		if (event.target instanceof HTMLSelectElement && userAccount.provider) {
			userAccount.provider.homeRegionId = parseInt(event.target.value, 10)
			dispatch('homeRegionChanged')
		}
	}
</script>

<div class="d-flex flex-md-nowrap flex-wrap justify-content-center align-items-center">
	{#if showProfilePicture}
		<div style="margin-right: 1.25rem">
			<input
				id="profilePictureInput"
				type="file"
				style="display: none;"
				on:change={onFileUpload}
			/>
			<ProviderProfilePicture
				profilePictureUrl={computedProfilePictureUrl}
				pictureSize={100}
				showFloatingButton
				on:click={onPictureClick}
			/>
		</div>
	{/if}
	<div class="form-row">
		<div class={contactSizeBasedFieldClass}>
			<Input
				required={isEditing}
				id="email-address-input"
				label="Email"
				value={userAccount.email}
				placeholder="janedoe@example.com"
				hint=""
				hintClass=""
				type="email"
				inputmode="email"
				disabled={disableEmailField}
				on:change={onEmailChange}
				validation={{
					value: userAccount.email,
				}}
			/>
			{#if userAccount.email && !emailIsValid}
				<div class="d-block invalid-feedback">Invalid email address</div>
			{:else}
				<div
					style="font-size: 80%;"
					class="d-block"
				>
					This is your username
				</div>
			{/if}
		</div>
		<div class={contactSizeBasedFieldClass}>
			<Input
				id="mobile-input"
				label="Mobile Phone #"
				value={formattedMobile}
				placeholder="xxx-xxx-xxxx"
				autocomplete="tel"
				hint={showOptionalFieldsHint ? 'Optional' : ''}
				hintClass="text-success"
				type="tel"
				inputmode="tel"
				disabled={disableOtherField}
				required={requiredMobile}
				on:change={onMobileChange}
			/>
			{#if !phoneNumberIsValid && userAccount.mobile !== ''}
				<div class="d-block invalid-feedback">Invalid phone number</div>
			{:else if descriptionForMobile}
				<div
					style="font-size: 80%;"
					class="d-block"
				>
					{descriptionForMobile}
				</div>
			{/if}
		</div>
		<div class={sizeBasedFieldClass}>
			<Input
				required={isEditing}
				id="first-name-input"
				label="First Name"
				placeholder="Jane"
				autocomplete="given-name"
				disabled={disableOtherField}
				bind:value={userAccount.firstName}
				on:change={updateUserInfo}
				validation={{
					value: userAccount.firstName,
				}}
			/>
		</div>
		<div class={sizeBasedFieldClass}>
			<Input
				id="last-name-input"
				required={isEditing}
				label="Last Name"
				placeholder="Doe"
				autocomplete="family-name"
				disabled={disableOtherField}
				bind:value={userAccount.lastName}
				on:change={updateUserInfo}
				validation={{
					value: userAccount.lastName,
				}}
			/>
		</div>
		{#if userAccount.provider}
			<div class={sizeBasedFieldClass}>
				<DateInput
					id="date-of-birth-input"
					required={isEditing}
					label="Date of Birth"
					type="date"
					placeholder="mm/dd/yyyy"
					value={userAccount.provider.dateOfBirth?.toISOString().split('T')[0] ?? ''}
					disabled={disableOtherField || dobChangedSaved}
					on:blur={onDOBChange}
					validation={{
						value: userAccount.provider.dateOfBirth?.toISOString().split('T')[0],
					}}
				/>
			</div>
			{#if showHomeRegionSelect}
				<div class={sizeBasedFieldClass}>
					<Select
						id="home-region-select"
						label="Home Region"
						placeholder="Select Region"
						bind:value={userAccount.provider.homeRegionId}
						disabled={disableOtherField}
						on:change={updateUserHomeRegion}
					>
						{#each availableRegions as region}
							<option value={region.id}>{region.name}</option>
						{/each}
					</Select>
				</div>
			{/if}
		{/if}
	</div>
</div>
