export default {
    "name": "LoadZones",
    "kind": "HoudiniQuery",
    "hash": "948e5d33bb802d85b67fd47458e1428cb67cb65a25842575bd0a18925b2deb89",

    "raw": `query LoadZones($pagination: PaginatedInput, $filter: ZoneFilter) {
  zones(pagination: $pagination, filter: $filter) {
    data {
      ...ZoneData
      id
    }
  }
}

fragment ZoneData on Zone {
  id
  name
  state
  description
  regions {
    id
    name
    description
    latitude
    longitude
    timeZone
    status
    zoneId
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "zones": {
                "type": "ZoneResponse",
                "keyRaw": "zones(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Zone",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "regions": {
                                        "type": "Region",
                                        "keyRaw": "regions",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "latitude": {
                                                    "type": "Latitude",
                                                    "keyRaw": "latitude",
                                                    "visible": true
                                                },

                                                "longitude": {
                                                    "type": "Longitude",
                                                    "keyRaw": "longitude",
                                                    "visible": true
                                                },

                                                "timeZone": {
                                                    "type": "TimeZone",
                                                    "keyRaw": "timeZone",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "RegionStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "zoneId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "zoneId",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "ZoneData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "ZoneFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ZoneFilter": {
                "id": "PositiveInt",
                "name": "NonEmptyString",
                "state": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f1212b72877ae6d00d349ee6f3454e7c3b1faf0678f0d1453a4d197f6bb2a7f6";