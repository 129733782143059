import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/AdminTagPricingData'


// create the fragment store

export class AdminTagPricingDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "AdminTagPricingDataStore",
			variables: true,
			
		})
	}
}
