<script lang="ts">
	import Button from '@isoftdata/svelte-button'

	import type { PaymentMethodCard } from 'utility/payment-method-helper'

	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	const cardTypeIconMap = {
		visa: { icon: 'fa-brands fa-cc-visa', displayName: 'Visa' },
		mastercard: { icon: 'fa-brands fa-cc-mastercard', displayName: 'MasterCard' },
		amex: { icon: 'fa-brands fa-cc-amex', displayName: 'American Express' },
		discover: { icon: 'fa-brands fa-cc-discover', displayName: 'Discover' },
		diners: { icon: 'fa-brands fa-cc-diners-club', displayName: 'Diners Club' },
		jcb: { icon: 'fa-brands fa-cc-jcb', displayName: 'JCB' },
		unknown: { icon: 'fas fa-credit-card-front', displayName: 'Unknown' },
	}

	export let showUpdateButton: boolean = false
	export let cards: PaymentMethodCard[]

	function editCard(id: any) {
		dispatch('editCard', id)
	}
</script>

<ul class="list-group">
	{#each cards as card}
		<li class="list-group-item">
			<div
				class="d-flex justify-content-between align-items-center"
				title={`${cardTypeIconMap[card.cardType]?.displayName || ''} ending in ${card.lastFour}`}
			>
				<div>
					<i class="text-dark fa-2xl {cardTypeIconMap[card.cardType]?.icon || 'fas fa-credit-card-font'}"></i>
				</div>
				<div>
					<small>
						•••• •••• •••• {card.lastFour}
					</small>
				</div>
				<div>
					<small>
						{card.expirationMonth}/{card.expirationYear?.toString().substring(2, 4)}
					</small>
				</div>
				<div>
					{#if showUpdateButton}
						<Button
							id="edit-card-button"
							title="Edit Card"
							size="sm"
							color="dark"
							on:click={() => editCard(card.id)}>Edit</Button
						>
					{/if}
				</div>
			</div>
		</li>
	{/each}
</ul>
