<script lang="ts">
	import ImageViewer from '@isoftdata/svelte-image-viewer'

	export let show: boolean = false
	export let files: string[] = []
	let currentPhotoIndex: number = 0
</script>

<ImageViewer
	bind:show
	showIndicators={false}
	{files}
	bind:currentPhotoIndex
	on:close={() => {
		show = false
		currentPhotoIndex = 0
	}}
/>
