<script lang="ts">
	import { formatISO } from 'date-fns'
	import { createEventDispatcher } from 'svelte'
	import type { JobStatus$options } from '$houdini/graphql'

	import { formatRelativeDateWeek } from 'utility/format/format-relative-date-week'
	import AvailableJobCard from './AvailableJobCard.svelte'

	const dispatch = createEventDispatcher()

	interface ProviderAvailableJobsListJobProp {
		id: number
		requestedSchedule: Date
		jobStatus: JobStatus$options
		transaction: {
			cost: string
		} | null
		service: {
			name: string
		}
		residence: {
			street: string
			displayLawnSquareFootage: number
			region: {
				name: string
			}
		}
		markerLabel: string | google.maps.MarkerLabel
		customerFirstJob: boolean
		answers:
			| {
					id: number
			  }[]
			| null
	}

	export let jobs: ProviderAvailableJobsListJobProp[]
	export let selectedJobId: number | null
	export let disableSelection = false

	function jobsByDate<T extends ProviderAvailableJobsListJobProp>(jobs: T[]): Map<string, { formattedDate: string; jobs: T[] }> {
		const jobsByDate = new Map<string, { formattedDate: string; jobs: T[] }>()

		jobs.forEach(job => {
			let date = job.requestedSchedule

			const formattedDate = formatRelativeDateWeek(date)
			const key = formatISO(date, { representation: 'date' })

			if (!jobsByDate.has(key)) {
				jobsByDate.set(key, { formattedDate, jobs: [] })
			}

			jobsByDate.get(key)?.jobs.push(job)
		})

		return jobsByDate
	}
</script>

{#each jobsByDate(jobs) as [key, jobsFormattedDate] (key)}
	<label
		for="jobList"
		class="mb-0 mt-2"
	>
		<h4 class="mb-0">{jobsFormattedDate.formattedDate}</h4>
	</label>
	<div
		id="jobList"
		class={"list-group mt-2"}
	>
		{#each jobsFormattedDate.jobs as job}
			<button
				id="job-{job.id}"
				type="button"
				class="list-group-item list-group-itemp-action p-2"
				on:click={() => dispatch('jobClicked', job.id)}
				disabled={disableSelection}
				class:cursor-default={disableSelection}
				class:active={job.id === selectedJobId}
			>
				<div class="d-flex align-items-center">
					<AvailableJobCard
						bind:job={job}
					/>
				</div>
			</button>

			{#if job.id === selectedJobId}
				<div class="list-group-item border border-primary">
					<slot />
				</div>
			{/if}
		{/each}
	</div>
{/each}

