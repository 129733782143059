import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/QuestionData'


// create the fragment store

export class QuestionDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "QuestionDataStore",
			variables: true,
			
		})
	}
}
