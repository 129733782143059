export default {
    "name": "MakeQuestion",
    "kind": "HoudiniMutation",
    "hash": "e873fe81139d4c81865dc395c22727ecc62373e3d7ee0c2d47e73ed1614e6089",

    "raw": `mutation MakeQuestion($question: NewQuestion!) {
  newQuestion(question: $question) {
    ...QuestionData
    id
  }
}

fragment QuestionData on Question {
  id
  question
  description
  questionDataType
  answers {
    ...AnswerData
    id
  }
  __typename
}

fragment AnswerData on Answer {
  id
  answer
  questionId
  tagId
  tag {
    ...TagData
    id
  }
  __typename
}

fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newQuestion": {
                "type": "Question",
                "keyRaw": "newQuestion(question: $question)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "question": {
                            "type": "String",
                            "keyRaw": "question",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "questionDataType": {
                            "type": "QuestionDataType",
                            "keyRaw": "questionDataType",
                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    },

                                    "questionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "questionId",
                                        "visible": true
                                    },

                                    "tagId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "tagId",
                                        "visible": true
                                    },

                                    "tag": {
                                        "type": "Tag",
                                        "keyRaw": "tag",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "prompt": {
                                                    "type": "String",
                                                    "keyRaw": "prompt",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "tagPricings": {
                                                    "type": "TagPricing",
                                                    "keyRaw": "tagPricings",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "tagId": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "tagId",
                                                                "visible": true
                                                            },

                                                            "serviceId": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "serviceId",
                                                                "visible": true
                                                            },

                                                            "priceAdjustment": {
                                                                "type": "String",
                                                                "keyRaw": "priceAdjustment",
                                                                "visible": true
                                                            },

                                                            "pricingType": {
                                                                "type": "PricingType",
                                                                "keyRaw": "pricingType",
                                                                "visible": true
                                                            },

                                                            "postPriceAdjustment": {
                                                                "type": "Boolean",
                                                                "keyRaw": "postPriceAdjustment",
                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        },

                                                        "fragments": {
                                                            "TagPricingsData": {
                                                                "arguments": {}
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "AnswerData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "QuestionData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "question": "NewQuestion"
        },

        "types": {
            "NewQuestion": {
                "description": "String",
                "question": "NonEmptyString",
                "questionDataType": "QuestionDataType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=9b9272aa62c713b54d04de4783b2427e4209733efe1fae6515be957ebd8ca1c4";