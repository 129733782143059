import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadResidenceHelperAnswers'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadResidenceHelperAnswersStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadResidenceHelperAnswersStore",
			variables: false,
		})
	}
}

export async function load_LoadResidenceHelperAnswers(params) {
	await initClient()

	const store = new LoadResidenceHelperAnswersStore()

	await store.fetch(params)

	return {
		LoadResidenceHelperAnswers: store,
	}
}
