<script lang="ts">
	import type { ComponentProps } from 'svelte'

	import Button from '@isoftdata/svelte-button'

	export let profilePictureUrl: string | null = null
	export let pictureSize = 80
	export let showFloatingButton = false
	export let floatingButtonColor: ComponentProps<Button>['color'] = 'primary'
	export let floatingButtonOutline = true
	export let floatingButtonIconClass: ComponentProps<Button>['iconClass'] = 'camera'
	export let floatingButtonIconStyle: string | null = null
	export let floatingButtonSize: ComponentProps<Button>['size'] = 'sm'
	export let clickable = false

	$: floatingButtonWrapperClass = floatingButtonSize === 'xs' ? 'btn-xs-floating-position' : 'btn-floating-position'
	$: floatingButtonClass = floatingButtonSize === 'xs' ? 'btn-xs-floating' : 'btn-floating'

	$: formattedUrl = profilePictureUrl ? `${profilePictureUrl}?width=${pictureSize}&height=${pictureSize}` : 'images/placeholder-hiro.png'
</script>

{#if clickable}
	<div
		class="profile-picture"
		style="cursor: pointer;"
		on:click
		on:keypress
		role="button"
		aria-haspopup="true"
		tabindex="0"
	>
		<img
			class="rounded-circle mr-2"
			src={formattedUrl}
			style="width: {pictureSize}px; height: {pictureSize}px;"
			loading="lazy"
			alt="Your LawnHiro provider"
		/>
	</div>
{:else}
	<div class="profile-picture">
		<img
			class="rounded-circle mr-2"
			src={formattedUrl}
			style="width: {pictureSize}px; height: {pictureSize}px;"
			loading="lazy"
			alt="Your LawnHiro provider"
		/>
		{#if showFloatingButton}
			<div class={floatingButtonWrapperClass}>
				<Button
					class={floatingButtonClass}
					color={floatingButtonColor}
					outline={floatingButtonOutline}
					size={floatingButtonSize}
					icon={{
						icon: floatingButtonIconClass,
						style: floatingButtonIconStyle,
					}}
					on:click
				></Button>
			</div>
		{/if}
	</div>
{/if}

<style>
	.profile-picture {
		height: 100% !important;
		max-width: 100px !important;
		max-height: 100px !important;
		border-radius: 100%;
		position: relative;
	}
	.btn-floating-position {
		width: 42px;
		height: 32px;
		border-radius: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.btn-xs-floating-position {
		width: 28px;
		height: 25px;
		border-radius: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	:global(.btn-floating) {
		width: 37px;
		height: 37px;
		border-radius: 50%;
	}
	:global(.btn-xs-floating) {
		width: 25px;
		height: 25px;
		border-radius: 50%;
	}
</style>
