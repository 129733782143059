import { QuoteFilter } from '$houdini'
import { datesFromRange, rangeFromDates } from '@isoftdata/utility-date-time'
import component from './Quote.svelte'

import { AppContext } from 'types/common'

import { loadQuotes, type QuoteManagementQuote } from 'utility/quotes-helper'
import { startOfDay, parseISO, endOfDay } from 'date-fns'

const DEFAULT_RANGE = 'Last 7 Days'

const creationDate = {
	from: {
		field: 'createdFrom',
		formatter: (date: string) => startOfDay(parseISO(date)),
	},
	to: {
		field: 'createdTo',
		formatter: (date: string) => endOfDay(parseISO(date)),
	},
}

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.quote',
		route: 'quote',
		querystringParameters: ['statuses', 'fromDate', 'toDate'],
		defaultParameters: {
			fromDate: () => datesFromRange(DEFAULT_RANGE).from,
			toDate: () => datesFromRange(DEFAULT_RANGE).to,
		},
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, { statuses, fromDate, toDate }) {
			const filter: QuoteFilter = {}
			if (fromDate) {
				filter.createdFrom = creationDate.from.formatter?.(fromDate) ?? fromDate
			}
			if (toDate) {
				filter.createdTo = creationDate.to.formatter?.(toDate) ?? toDate
			}

			const quotes: QuoteManagementQuote[] = await loadQuotes(filter)

			return {
				quotes,
				regionStatusFilter: statuses,
				dates: {
					from: fromDate || null,
					to: toDate || null,
				},
				range: rangeFromDates(fromDate, toDate) || 'Custom',
			}
		},
	})
}
