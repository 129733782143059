export default {
    "name": "ClockOutOfJobClaim",
    "kind": "HoudiniMutation",
    "hash": "d250ff175ef6a1aab6b470f969af8240e9d1de6a91fb6d77aca8216f35bef015",

    "raw": `mutation ClockOutOfJobClaim($jobClaimId: PositiveInt!) {
  clockOutOfJobClaim(jobClaimId: $jobClaimId) {
    id
    start
    end
    elapsedTime
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "clockOutOfJobClaim": {
                "type": "JobClaimActivity",
                "keyRaw": "clockOutOfJobClaim(jobClaimId: $jobClaimId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "start": {
                            "type": "DateTime",
                            "keyRaw": "start",
                            "visible": true
                        },

                        "end": {
                            "type": "DateTime",
                            "keyRaw": "end",
                            "nullable": true,
                            "visible": true
                        },

                        "elapsedTime": {
                            "type": "NonNegativeInt",
                            "keyRaw": "elapsedTime",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobClaimId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b15579588dd85ad3e59ed3fb00577d9b7c0f16ee083f35e3e4b5ada0919c778c";