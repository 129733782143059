export default (...args) => {
	try {
		return gtag('event', ...args)
	} catch (err) {
		console.error('gTap error occured: ', err)
	}
}

// usage:
/* gTagHelper('begin_checkout', {
    currency: 'USD',
    value: priceGA,
    items: [{
        item_id: selectedService.codeName,
        item_name: selectedService.name,
        currency: 'USD',
        index: 0,
        price: priceGA,
        quantity: 1,
    }],
}) */
