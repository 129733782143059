import template from './recurring-order.ractive.html'
import formatCityStateZip from 'utility/format/format-city-state-zip'
import makeGenericBadge from 'components/generic-badge'

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.customer.recurring-order',
		route: 'recurring-order',
		querystringParameters: [ 'residenceId', 'scheduleType' ],
		defaultParameters: {
			scheduleType: 'RECURRING_SCHEDULE',
		},
		template: {
			template,
			components: {
				genericBadge: makeGenericBadge(),
			},
			computed: {
				groupSchedulesByResidence() {
					const customerRecurringSchedules = this.get('customerRecurringSchedules')

					return customerRecurringSchedules.reduce((acc, schedule) => {
						if (acc[schedule.residence.id]) {
							acc[schedule.residence.id].residenceScheduleCount++
						} else {
							acc[schedule.residence.id] = {
								residenceId: schedule.residence.id,
								residenceStreet: schedule.residence.street,
								residenceCityState: formatCityStateZip({
									city: schedule.residence.city,
									state: schedule.residence.state,
									zip: schedule.residence.zip,
								}, false),
								residenceScheduleCount: 1,
							}
						}

						return acc
					}, {})
				},
			},
		},
		async resolve(data, { residenceId }) {
			const customerRecurringSchedulesQuery = `#graphql
				query GetCustomerServiceSchedules($filter: CustomerServiceScheduleFilter) {
  					getCustomerServiceSchedules(filter: $filter) {
                        id
						status
                        residence {
							id
                            street
                            city
                            state
                            zip
                            country
                        }
                    }
                }
            `

			const { getCustomerServiceSchedules: customerRecurringSchedules } = await mediator.call('apiFetch', customerRecurringSchedulesQuery, {
				filter: {
					status: 'ACTIVE',
				},
			})

			residenceId = parseInt(residenceId, 10) || customerRecurringSchedules?.[0]?.residence?.id || undefined

			// go to detail state if we are taking the first scheduleId off the list
			if (residenceId) {
				stateRouter.go('app.customer.recurring-order.detail', { residenceId }, { inherit: true, replace: true })
			}

			return {
				customerRecurringSchedules,
				selectedResidenceId: residenceId,
			}
		},
		activate({ domApi: ractive }) {
			/*
				Ensure the selected job is visible in the list
				focus seems to give us better behavior than scrollIntoView
				as far as moving the list only when the job in the list is not already visible
			*/
			ractive.find(`#residence-id-${ ractive.get('selectedResidenceId') }`)?.focus()
		},
	})
}
