export default {
    "name": "UpdateProviderOnboardingApplicationTransportationStatus",
    "kind": "HoudiniMutation",
    "hash": "27ab346ce8826aa3b31af2046451f1a043ad7af0f41af6a232669d44697c2b86",

    "raw": `mutation UpdateProviderOnboardingApplicationTransportationStatus($status: PictureFileStatus!, $providerOnboardingApplicationId: PositiveInt!, $rejectMessage: String) {
  updateProviderOnboardingApplicationTransportationStatus(
    status: $status
    providerOnboardingApplicationId: $providerOnboardingApplicationId
    rejectMessage: $rejectMessage
  ) {
    id
    status
    transportationStatus
    transportationRejectMessage
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderOnboardingApplicationTransportationStatus": {
                "type": "ProviderApplication",
                "keyRaw": "updateProviderOnboardingApplicationTransportationStatus(providerOnboardingApplicationId: $providerOnboardingApplicationId, rejectMessage: $rejectMessage, status: $status)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "transportationStatus": {
                            "type": "PictureFileStatus",
                            "keyRaw": "transportationStatus",
                            "visible": true
                        },

                        "transportationRejectMessage": {
                            "type": "String",
                            "keyRaw": "transportationRejectMessage",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "status": "PictureFileStatus",
            "providerOnboardingApplicationId": "PositiveInt",
            "rejectMessage": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=98bb1bc0be9a289c7ccb8f47565ead1f161914cd103bf6cafd2771f03a0067f2";