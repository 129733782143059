import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadProviderManagement'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadProviderManagementStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadProviderManagementStore",
			variables: false,
		})
	}
}

export async function load_LoadProviderManagement(params) {
	await initClient()

	const store = new LoadProviderManagementStore()

	await store.fetch(params)

	return {
		LoadProviderManagement: store,
	}
}
