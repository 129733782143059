export default {
    "name": "CouponServices",
    "kind": "HoudiniQuery",
    "hash": "15d0b7e0bc8babc0adb9aae6a9ea58a0eff39903c1df4a714ab97d32a69d00e1",

    "raw": `query CouponServices {
  services {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=773dc32a30031cc25003f1e58b6c6a9587afbdbd73ba7739766808397857e34b";