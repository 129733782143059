<script lang="ts">
	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import type { ComputedTransactionPayment } from 'client/utility/accounts-payable-helper'
	import formatCurrency from 'utility/format/format-currency'

	export let providerJobs: ComputedTransactionPayment[]
	export let providerJobsColumns: Column[]
</script>

<Table
	responsive
	rows={providerJobs}
	columns={providerJobsColumns}
	let:row
>
	<tr>
		<Td property="jobId">{row.jobId}</Td>
		<Td property="customerName">{row.customerName}</Td>
		<Td property="customerAddress">{row.customerAddress}</Td>
		<Td property="requestedScheduleFormatted">{row.requestedScheduleFormatted}</Td>
		<Td property="payout">{formatCurrency(row.payout)}</Td>
		<Td property="payableDate">{row.completedFormatted}</Td>
		{#if row.payoutType === 'JOB'}
			<Td property="formattedElapsedTime">{row.formattedElapsedTime}</Td>
		{:else}
			<Td property="formattedElapsedTime"><i class="text-muted">N/A</i></Td>
		{/if}
		<Td property="payoutType">{row.payoutType}</Td>
	</tr>
</Table>
