export default {
	PENDING: { class: 'success', label: 'Pending Order Creation', iconClass: 'fas fa-clock', providerLabel: 'Pending' },
	CLAIMABLE: { class: 'success', label: 'Pending Schedule Time', iconClass: 'fas fa-clock', providerLabel: 'Available' },
	CLAIMED: { class: 'secondary', label: 'Scheduled', iconClass: 'fas fa-square-check', providerLabel: 'Scheduled' },
	COMPLETED: { class: 'primary', label: 'Completed', iconClass: 'fas fa-badge-check', providerLabel: 'Completed' },
	EXPIRED: { class: 'danger', label: 'Expired', iconClass: 'fas fa-clock', providerLabel: 'Expired' },
	CANCELLED: { class: 'danger', label: 'Cancelled', iconClass: 'fas fa-xmark', providerLabel: 'Cancelled' },
	FAILED: { class: 'danger', label: 'Payment Failed', iconClass: 'fas fa-xmark', providerLabel: 'Cancelled' },
	FIRSTJOB: { class: 'warning', label: 'Customer First Job', iconClass: 'fas fa-star', providerLabel: 'Customer First Job' },
}
