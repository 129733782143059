import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/orderCreatedJobQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class orderCreatedJobQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "orderCreatedJobQueryStore",
			variables: true,
		})
	}
}

export async function load_orderCreatedJobQuery(params) {
	await initClient()

	const store = new orderCreatedJobQueryStore()

	await store.fetch(params)

	return {
		orderCreatedJobQuery: store,
	}
}
