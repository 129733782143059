<script
	context="module"
	lang="ts"
>
	export type NotificationPreference = {
		// The display name of the setting
		name: string
		// Is the setting enabled?
		sendEmail: boolean
		sendSms?: false
		sendPush: boolean
		// Keys to get setting name/etc
		emailKey: string
		smsKey?: never
		pushKey?: string
	}

	export type NotificationType = 'sms' | 'push' | 'email'
	// Load bearing nonsense, delete if you want to break syntax highlihgting
	;('')
</script>

<script lang="ts">
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { createEventDispatcher } from 'svelte'
	import type { SettingLookup } from '$houdini'
	import saveUserSetting, { type SetUserSettingInput } from 'utility/save-user-setting'
	import { booleanToString } from '@isoftdata/utility-boolean'

	const dispatch = createEventDispatcher<{
		pushToggle: {
			name: string
			enabled: boolean
		}
	}>()

	export let rows: Array<NotificationPreference> = []
	export let settingsProperties: Record<string, SettingLookup>

	export let showPush = false
	export let showSms = false

	export let disablePush = false
	export let disableSms = false

	const columns: Array<Column> = [
		{
			name: 'Notify me when a job is...',
			property: 'name',
		},
		{
			name: 'Via SMS',
			property: 'sendSms',
			align: 'center',
			width: '1rem',
		},
		{
			name: 'Via Push',
			property: 'sendPush',
			align: 'center',
			width: '1rem',
		},
		{
			name: 'Via Email',
			property: 'sendEmail',
			align: 'center',
			width: '1rem',
		},
	]

	$: computedColumns = columns.filter(column => {
		if (column.property === 'sendPush') {
			return showPush
		}
		if (column.property === 'sendSms') {
			return showSms
		}
		return true
	})

	async function saveSetting(event: Event, type: NotificationType, key?: string) {
		if (!key || !type) {
			throw new Error(`Missing key (${key}) or type (${type})`)
		}
		const newValue = (event.target as HTMLInputElement).checked

		const { defaultValue, ...setting } = settingsProperties[key]
		await saveUserSetting({
			newValue: booleanToString(newValue),
			...setting,
		})
		return newValue
	}
</script>

<Table
	hover={false}
	responsive
	{rows}
	columns={computedColumns}
	let:row
>
	<tr>
		<Td property="name">
			{row.name}
		</Td>

		{#if showSms}
			<Td property="sendSms">
				<Checkbox
					type="switch"
					checked={row.sendSms}
					disabled={disableSms}
					on:change={event => saveSetting(event, 'sms', row.smsKey)}
				/>
			</Td>
		{/if}
		{#if showPush}
			<Td property="sendPush">
				<Checkbox
					type="switch"
					checked={row.sendPush}
					disabled={disablePush}
					on:change={async event => {
						const newChecked = await saveSetting(event, 'push', row.pushKey)
						dispatch('pushToggle', {
							name: row.name,
							enabled: newChecked,
						})
					}}
				/>
			</Td>
		{/if}
		<Td property="sendEmail">
			<Checkbox
				type="switch"
				checked={row.sendEmail}
				on:change={event => saveSetting(event, 'email', row.emailKey)}
			/>
		</Td>
	</tr>
</Table>
