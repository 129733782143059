export default {
    "name": "AttachFileToProviderOnboardingApplication",
    "kind": "HoudiniMutation",
    "hash": "e6ccf0fad247db336f1a4a0727a5cd7cef45818731b9b0d35cddee1cb70e24ba",

    "raw": `mutation AttachFileToProviderOnboardingApplication($input: ProviderOnboardingApplicationFileInput!) {
  attachFileToProviderOnboardingApplication(input: $input) {
    id
    status
    driversLicensePictureFile {
      path
      id
    }
    driverLicenseStatus
    transportationPictureFile {
      path
      id
    }
    transportationStatus
    lastUpdated
    serviceApplications: providerServiceApplications {
      id
      serviceId
      status
      equipmentPictureFile {
        path
        id
      }
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "attachFileToProviderOnboardingApplication": {
                "type": "ProviderApplication",
                "keyRaw": "attachFileToProviderOnboardingApplication(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "driversLicensePictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "driversLicensePictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "driverLicenseStatus": {
                            "type": "PictureFileStatus",
                            "keyRaw": "driverLicenseStatus",
                            "visible": true
                        },

                        "transportationPictureFile": {
                            "type": "FileEntity",
                            "keyRaw": "transportationPictureFile",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "transportationStatus": {
                            "type": "PictureFileStatus",
                            "keyRaw": "transportationStatus",
                            "visible": true
                        },

                        "lastUpdated": {
                            "type": "DateTime",
                            "keyRaw": "lastUpdated",
                            "visible": true
                        },

                        "serviceApplications": {
                            "type": "ProviderServiceApplication",
                            "keyRaw": "serviceApplications",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderServiceApplicationStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "equipmentPictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "equipmentPictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "ProviderOnboardingApplicationFileInput"
        },

        "types": {
            "ImageFile": {
                "base64String": "String",
                "fileName": "String"
            },

            "ProviderOnboardingApplicationFileInput": {
                "image": "ImageFile",
                "providerOnboardingApplicationId": "PositiveInt",
                "serviceId": "PositiveInt",
                "type": "ProviderOnboardingImageUploadType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0f38ea5366024eb0c483a9f02fba70d87468fda2dcecd1e3ed62527e6e56554d";