import component from './Customer.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.customer',
		route: 'customer',
		querystringParameters: ['residenceId', 'scheduleType'],
		defaultChild: 'upcoming-orders',
		template: {
			component,
			svelte: true,
			options: {},
		},
		resolve(_data, parameters) {
			return Promise.resolve({
				childStates: [
					{
						title: 'Upcoming',
						name: 'app.customer.upcoming-orders',
					},
					{
						title: 'Subscriptions',
						name: 'app.customer.recurring-order',
					},
					{
						title: 'History',
						name: 'app.customer.order-history',
					},
				],
				scheduleType: parameters.scheduleType,
			})
		},
	})
}
