export default {
    "name": "DeleteTagPricingMutation",
    "kind": "HoudiniMutation",
    "hash": "fbcca4f0434b593b076f08a44026168054b7b877dd022daa1e897e1191babefc",

    "raw": `mutation DeleteTagPricingMutation($tagPricingId: PositiveInt!) {
  deleteTagPricing(tagPricingId: $tagPricingId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deleteTagPricing": {
                "type": "Boolean",
                "keyRaw": "deleteTagPricing(tagPricingId: $tagPricingId)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tagPricingId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=29ca0581936c5ce14e56cafd8eac845537bc2f23cdfe1d2f2ea42d19698854d5";