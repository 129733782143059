/** Pass an array of order by tokens to order the results of a query */
export const AccessLogOrderBy = {
    "DATE_ASC": "DATE_ASC",
    "DATE_DESC": "DATE_DESC",
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC"
};

/** The outcome of the access attempt */
export const AccessStatus = {
    "ACCOUNT_DEACTIVATED": "ACCOUNT_DEACTIVATED",
    "ACCOUNT_LOCKED": "ACCOUNT_LOCKED",
    "ACCOUNT_PENDING_ACTIVATION": "ACCOUNT_PENDING_ACTIVATION",
    "CONNECTIVITY_ERROR": "CONNECTIVITY_ERROR",
    "GRANTED": "GRANTED",
    "INSUFFICIENT_PRIVILEGES": "INSUFFICIENT_PRIVILEGES",
    "INVALID_ACCOUNT": "INVALID_ACCOUNT",
    "INVALID_PASSWORD": "INVALID_PASSWORD",
    "MODULE_MISSING": "MODULE_MISSING"
};

/** What type of system access was being requested */
export const AccessType = {
    "LOGIN": "LOGIN",
    "PASSWORD_CHANGE": "PASSWORD_CHANGE",
    "PERMISSION_VERIFICATION": "PERMISSION_VERIFICATION",
    "SESSION_UNLOCK": "SESSION_UNLOCK"
};

/** Pass an array of order by tokens to order the results of a query */
export const CouponOrderBy = {
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC"
};

export const CustomerWalletStatus = {
    "ACTIVE": "ACTIVE",
    "INACTIVE": "INACTIVE",
    "SINGE_USE": "SINGE_USE"
};

/** Determines what entity this tag attaches to. */
export const EntityType = {
    "CUSTOMER": "CUSTOMER",
    "JOB": "JOB",
    "PROVIDER": "PROVIDER",
    "REGION": "REGION",
    "RESIDENCE": "RESIDENCE",
    "SCHEDULE": "SCHEDULE"
};

/** Type of File */
export const FileType = {
    "EXECUTABLE": "EXECUTABLE",
    "IMAGE": "IMAGE",
    "PDF": "PDF",
    "REPORT": "REPORT",
    "TEXT": "TEXT",
    "UNKNOWN": "UNKNOWN"
};

/** Type of Image File */
export const ImageFileType = {
    "AFTER": "AFTER",
    "BEFORE": "BEFORE",
    "OTHER": "OTHER",
    "PROBLEM": "PROBLEM"
};

export const InvoiceStatus = {
    "FINALIZED": "FINALIZED",
    "OPEN": "OPEN",
    "VOID": "VOID"
};

/** Pass an array of order by tokens to order the results of a query */
export const JobClaimOrderBy = {
    "COMPLETED_AT_ASC": "COMPLETED_AT_ASC",
    "COMPLETED_AT_DESC": "COMPLETED_AT_DESC",
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC",
    "REQUESTED_SCHEDULE_ASC": "REQUESTED_SCHEDULE_ASC",
    "REQUESTED_SCHEDULE_DESC": "REQUESTED_SCHEDULE_DESC",
    "SCHEDULED_AT_ASC": "SCHEDULED_AT_ASC",
    "SCHEDULED_AT_DESC": "SCHEDULED_AT_DESC"
};

/** For any given job claim, this represents whether the work has been completed or cancelled */
export const JobClaimStatus = {
    "ACCEPTED": "ACCEPTED",
    "CANCELLED": "CANCELLED",
    "COMPLETED": "COMPLETED",
    "DELAYED": "DELAYED",
    "IN_PROGRESS": "IN_PROGRESS"
};

/** Pass an array of order by tokens to order the results of a query */
export const JobOrderBy = {
    "COMPLETED_ASC": "COMPLETED_ASC",
    "COMPLETED_DESC": "COMPLETED_DESC",
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC",
    "REQUESTED_SCHEDULE_ASC": "REQUESTED_SCHEDULE_ASC",
    "REQUESTED_SCHEDULE_DESC": "REQUESTED_SCHEDULE_DESC"
};

/** For any given job, this represents whether the work has been completed or cancelled */
export const JobStatus = {
    "CANCELLED": "CANCELLED",
    "CLAIMABLE": "CLAIMABLE",
    "CLAIMED": "CLAIMED",
    "COMPLETED": "COMPLETED",
    "EXPIRED": "EXPIRED",
    "FAILED": "FAILED",
    "PENDING": "PENDING"
};

export const PaymentStatus = {
    "FAILED": "FAILED",
    "OPEN": "OPEN",
    "PAID": "PAID",
    "RESOLVED": "RESOLVED"
};

/** The current status of a picture file approval */
export const PictureFileStatus = {
    "APPROVED": "APPROVED",
    "IN_REVIEW": "IN_REVIEW",
    "NOT_UPLOADED": "NOT_UPLOADED",
    "REJECTED": "REJECTED"
};

/** In Service Region has parcel, Out of Service Region does not */
export const PriceErrorStatus = {
    "IN_SERVICE_REGION": "IN_SERVICE_REGION",
    "OUT_OF_SERVICE_REGION": "OUT_OF_SERVICE_REGION"
};

/** Determines where in the pricing calculation the modifier gets used */
export const PricingType = {
    "FLAT": "FLAT",
    "PERCENT": "PERCENT"
};

/** Pass an array of order by tokens to order the results of a query */
export const ProviderApplicationOrderBy = {
    "CREATED_ASC": "CREATED_ASC",
    "CREATED_DESC": "CREATED_DESC",
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC"
};

/** The current status of the provider onboarding process */
export const ProviderApplicationStatus = {
    "APPLICATION_APPROVED": "APPLICATION_APPROVED",
    "COMPLETED": "COMPLETED",
    "DID_NOT_FINISH": "DID_NOT_FINISH",
    "HRIS_ACCOUNT_APPROVED": "HRIS_ACCOUNT_APPROVED",
    "HRIS_ERROR": "HRIS_ERROR",
    "INFORMATION_REQUIRED": "INFORMATION_REQUIRED",
    "IN_REVIEW": "IN_REVIEW",
    "REJECTED": "REJECTED",
    "SENT_HRIS_INVITE": "SENT_HRIS_INVITE",
    "SOR_CHECK_FAILED": "SOR_CHECK_FAILED",
    "SOR_CHECK_PASSED": "SOR_CHECK_PASSED",
    "SOR_REVIEW_REQUIRED": "SOR_REVIEW_REQUIRED"
};

/** The type of provider application */
export const ProviderApplicationType = {
    "NEW_SERVICE_REQUEST": "NEW_SERVICE_REQUEST",
    "ONBOARDING": "ONBOARDING",
    "RENEWAL": "RENEWAL"
};

/** Type of Image File */
export const ProviderImageType = {
    "PROFILE": "PROFILE"
};

/** The type of image being uploaded for the provider onboarding application */
export const ProviderOnboardingImageUploadType = {
    "DRIVERS_LICENSE": "DRIVERS_LICENSE",
    "SERVICE": "SERVICE",
    "TRANSPORTATION": "TRANSPORTATION"
};

/** The current status of the provider service application */
export const ProviderServiceApplicationStatus = {
    "APPROVED": "APPROVED",
    "IN_REVIEW": "IN_REVIEW",
    "REJECTED": "REJECTED"
};

/** The current status of the provider account */
export const ProviderStatus = {
    "APPROVED": "APPROVED",
    "DISABLED": "DISABLED",
    "PENDING_APPROVAL": "PENDING_APPROVAL"
};

/** Datatype of question to specify what answers it expects. */
export const QuestionDataType = {
    "BOOLEAN": "BOOLEAN",
    "CHOICE": "CHOICE"
};

/** The type of record referred to by the record # */
export const RecordType = {
    "NONE": "NONE",
    "REGION": "REGION"
};

/** Pass an array of order by tokens to order the results of a query */
export const RegionOrderBy = {
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC",
    "NAME_ASC": "NAME_ASC",
    "NAME_DESC": "NAME_DESC"
};

/** For any region, this represents whether the region is active. */
export const RegionStatus = {
    "ACTIVE": "ACTIVE",
    "INACTIVE": "INACTIVE"
};

/** Tracks whether a residence is currently active or not and whether jobs can be scheduled against it */
export const ResidenceStatus = {
    "ACTIVE": "ACTIVE",
    "INACTIVE": "INACTIVE"
};

/** Active status of schedule */
export const ServiceSchedulePeriodStatus = {
    "ACTIVE": "ACTIVE",
    "DISABLED": "DISABLED"
};

/** Active status of schedule */
export const ServiceScheduleStatus = {
    "ACTIVE": "ACTIVE",
    "DISABLED": "DISABLED",
    "PAUSED": "PAUSED"
};

/** The current status of this session if the access attempt started one */
export const SessionStatus = {
    "CLOSED": "CLOSED",
    "EXPIRED": "EXPIRED",
    "OPEN": "OPEN"
};

export const SettingType = {
    "ACCESSIBILITY": "ACCESSIBILITY",
    "HIDDEN": "HIDDEN",
    "IMPORTANT_CONFIGURATION": "IMPORTANT_CONFIGURATION",
    "INTERFACE_HISTORY": "INTERFACE_HISTORY",
    "INTERFACE_PREFERENCE": "INTERFACE_PREFERENCE",
    "OPTIONAL_CONFIGURATION": "OPTIONAL_CONFIGURATION",
    "PREFERENCE": "PREFERENCE"
};

/** Pass an array of order by tokens to order the results of a query */
export const TransactionOrderBy = {
    "ID_ASC": "ID_ASC",
    "ID_DESC": "ID_DESC"
};

export const TransactionPaymentClassification = {
    "FEE": "FEE",
    "JOB": "JOB",
    "TIP": "TIP"
};

export const TransactionPaymentStatus = {
    "ACCEPTED": "ACCEPTED",
    "FAILED": "FAILED",
    "PENDING": "PENDING"
};

export const TransactionType = {
    "AUTH": "AUTH",
    "CAPTURE": "CAPTURE",
    "CHARGE": "CHARGE",
    "RELEASE": "RELEASE"
};

/** What level a user has a given permission at */
export const UserAccessLevel = {
    "GLOBAL": "GLOBAL",
    "NONE": "NONE",
    "REGION": "REGION"
};

/** What type of access/role a user account has in the system */
export const UserAccessType = {
    "CUSTOMER": "CUSTOMER",
    "PROVIDER": "PROVIDER"
};

/** The current status of the user account */
export const UserAccountStatus = {
    "ACTIVE": "ACTIVE",
    "DEACTIVATED": "DEACTIVATED",
    "LOCKED": "LOCKED",
    "PENDING_ACTIVATION": "PENDING_ACTIVATION"
};