import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/findParcel'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class findParcelStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "findParcelStore",
			variables: false,
		})
	}
}

export async function load_findParcel(params) {
	await initClient()

	const store = new findParcelStore()

	await store.fetch(params)

	return {
		findParcel: store,
	}
}
