export function formatE164(phoneNumber?: string): string {
	if (phoneNumber) {
		if (phoneNumber[0] === '+' && phoneNumber[1] === '1') {
			return phoneNumber
		} else {
			phoneNumber = phoneNumber.replace(/\D/g, '')
			phoneNumber = `+1${phoneNumber}`
			return phoneNumber
		}
	} else {
		return ''
	}
}

export default formatE164
