<script lang="ts">
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import type { FailedPaymentJobData } from 'utility/admin-job-alerts'

	export let transactions: FailedPaymentJobData[number]['transactionPayments'] = []

	const absoluteDateTimeFormatter = (date: Date) => new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)

	let formattedTransaction: Transaction[] = transactions
		? transactions.reverse().map(transaction => {
				return {
					...transaction,
					created: absoluteDateTimeFormatter(transaction.created),
				}
			})
		: []

	type Transaction = Omit<Exclude<FailedPaymentJobData[number]['transactionPayments'], null>[number], 'created'> & { created: string }

	const columns: Column<Transaction>[] = [
		{ property: 'created', name: 'Date Created' },
		{ property: 'gatewayMessage', name: 'Gateway Message' },
		{ property: 'transactionType', name: 'Payment Type' },
		{ property: 'transactionPaymentClassification', name: 'Transaction Type' },
	]
</script>

<Table
	responsive
	bind:rows={formattedTransaction}
	{columns}
	let:row
>
	<tr>
		<Td property="created">{row.created}</Td>
		<Td property="gatewayMessage">{row.gatewayMessage}</Td>
		<Td property="transactionType">{row.transactionType}</Td>
		<Td property="transactionPaymentClassification">{row.transactionPaymentClassification}</Td>
	</tr>
</Table>
