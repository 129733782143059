export default {
    "name": "ClockIntoJobClaim",
    "kind": "HoudiniMutation",
    "hash": "b8ce1470bea042759fe728ba5b33fcf80797b4759000aafef5f70463154c08ab",

    "raw": `mutation ClockIntoJobClaim($jobClaimId: PositiveInt!) {
  clockIntoJobClaim(jobClaimId: $jobClaimId) {
    id
    start
    end
    elapsedTime
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "clockIntoJobClaim": {
                "type": "JobClaimActivity",
                "keyRaw": "clockIntoJobClaim(jobClaimId: $jobClaimId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "start": {
                            "type": "DateTime",
                            "keyRaw": "start",
                            "visible": true
                        },

                        "end": {
                            "type": "DateTime",
                            "keyRaw": "end",
                            "nullable": true,
                            "visible": true
                        },

                        "elapsedTime": {
                            "type": "NonNegativeInt",
                            "keyRaw": "elapsedTime",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobClaimId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=d8a306f3faa6ac84c2c32b6a323f1a3e809083066f48ccd69072eaab58c7a7e3";