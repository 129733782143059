export default {
    "name": "AttachFilesToJob",
    "kind": "HoudiniMutation",
    "hash": "8cfa5e1fe1a5001384930254fd139ad448d0f0489403f017ef3dca2bb2f484ac",

    "raw": `mutation AttachFilesToJob($input: NewJobFileInput!) {
  attachFilesToJob(input: $input) {
    id
    fileId
    rank
    public
    file {
      created
      id
      name
      path
      mimeType
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "attachFilesToJob": {
                "type": "JobFile",
                "keyRaw": "attachFilesToJob(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "fileId": {
                            "type": "PositiveInt",
                            "keyRaw": "fileId",
                            "visible": true
                        },

                        "rank": {
                            "type": "NonNegativeInt",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "file": {
                            "type": "FileEntity",
                            "keyRaw": "file",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "mimeType": {
                                        "type": "String",
                                        "keyRaw": "mimeType",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "NewJobFileInput"
        },

        "types": {
            "ImageFile": {
                "base64String": "String",
                "fileName": "String"
            },

            "NewJobFileInput": {
                "imageFile": "ImageFile",
                "imageFileType": "ImageFileType",
                "jobId": "PositiveInt",
                "publicFile": "Boolean",
                "rank": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0f14e685c497243fc9a489d6c16569ece937723ec4b645a34298ba5bad372c0e";