import { formatISO, parseISO } from 'date-fns'

/** @type {import('houdini').ConfigFile} */
const config = {
	watchSchema: {
		url: 'env:API_URL',
	},
	// apiUrl: 'http://localhost:4000/graphql',
	// url: 'http://localhost:4000/graphql',
	include: ['client/**/*.{svelte,graphql,gql,ts,js}'],
	plugins: {
		'houdini-svelte': {
			client: './client/services/houdini.ts',
		},
	},

	scalars: {
		PositiveInt: {
			type: 'number',
		},
		EmailAddress: {
			type: 'string',
		},
		UUID: {
			type: 'string',
		},
		NonEmptyString: {
			type: 'string',
		},
		NonNegativeInt: {
			type: 'number',
		},
		Void: {
			type: 'void',
		},
		UnsignedInt: {
			type: 'number',
		},
		SafeInt: {
			type: 'number',
		},
		PhoneNumber: {
			type: 'string',
		},
		Latitude: {
			type: 'number',
		},
		Longitude: {
			type: 'number',
		},
		TimeZone: {
			type: 'string',
		},
		Date: {
			type: 'Date',
			unmarshal: isoDateString => isoDateString ? parseISO(isoDateString) : null,
			marshal: date => date ? formatISO(date, { representation: 'date' }) : null,
		},
		DateTime: {
			type: 'Date',
			unmarshal: isoDateString => isoDateString ? parseISO(isoDateString) : null,
			marshal: date => date ? formatISO(date, { representation: 'complete'}) : null,
		},
		TimeZone: {
			type: 'string',
		}
	},
	// Caching can have a lot of side effects such as not updating the UI after reloading a state after a save, so I figure it's best to have it be opt-in.
	defaultCachePolicy: 'NetworkOnly',
	defaultFragmentMasking: 'disable',
}

export default config
