export default {
    "name": "GetServerInformation",
    "kind": "HoudiniQuery",
    "hash": "ed49d3b018f580a51076a919e8f1814a253f6c47ddedf609ee9e881b7a2a2a4c",

    "raw": `query GetServerInformation {
  getServerInformation {
    releaseVersionNumber
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getServerInformation": {
                "type": "ServerInformation",
                "keyRaw": "getServerInformation",

                "selection": {
                    "fields": {
                        "releaseVersionNumber": {
                            "type": "String",
                            "keyRaw": "releaseVersionNumber",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=851655ed7e1a9dfcfd2b4b70a7799fff9b38131699e8354a9b95594fea466054";