export default {
    "name": "RegionHelperServiceOfferData",
    "kind": "HoudiniFragment",
    "hash": "f01d9f8ac2220ccf23fe1b335aa04541db9fb4b15293e2fd2391b45b87da959e",

    "raw": `fragment RegionHelperServiceOfferData on ServiceOffer {
  id
  active
  service {
    id
    name
  }
  regionId
  chargeMinimum
  chargePerSquareFoot
  chargeFixed
  costPercent
  serviceId
  timeRange
  __typename
}
`,

    "rootType": "ServiceOffer",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "service": {
                "type": "Service",
                "keyRaw": "service",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "regionId": {
                "type": "PositiveInt",
                "keyRaw": "regionId",
                "visible": true
            },

            "chargeMinimum": {
                "type": "String",
                "keyRaw": "chargeMinimum",
                "visible": true
            },

            "chargePerSquareFoot": {
                "type": "String",
                "keyRaw": "chargePerSquareFoot",
                "visible": true
            },

            "chargeFixed": {
                "type": "String",
                "keyRaw": "chargeFixed",
                "nullable": true,
                "visible": true
            },

            "costPercent": {
                "type": "String",
                "keyRaw": "costPercent",
                "visible": true
            },

            "serviceId": {
                "type": "PositiveInt",
                "keyRaw": "serviceId",
                "visible": true
            },

            "timeRange": {
                "type": "String",
                "keyRaw": "timeRange",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=b4712e3001de56a3a2ec8c8ae3aff78381a918aa10cf66b7d5dc76de1b60846d";