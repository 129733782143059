import { graphql, findParcelStore, type NewUserAccountInfo } from '$houdini'
import parseGoogleMapsPlaceAddress from 'utility/map-google-place'
import type { PlaceResultWithSelected } from 'utility/google-maps-helper'

export const getQuoteFindParcelQuery: findParcelStore = graphql(`
	query findParcel($quote: QuoteInputType, $location: ParcelAddress, $serviceId: PositiveInt, $answerIds: [PositiveInt!]) {
		findParcel(quote: $quote, location: $location) {
			priceQuote(serviceId: $serviceId, quote: $quote, answerIds: $answerIds) {
				price
				error
			}
			googleMapsPlaceId
		}
	}
`)

export const getQuoteServicesQuery = graphql(`
	query getQuoteServices($filter: ServiceFilter) {
		services(filter: $filter) {
			data {
				id
				name
				codeName
				description
				warning
			}
		}
	}
`)

export const getQuoteServiceQuestionsQuery = graphql(`
	query getQuoteQuestions($questionFilter: QuestionFilter!) {
		questions(questionFilter: $questionFilter) {
			data {
				id
				question
				answers {
					id
					answer
				}
				services {
					id
				}
			}
		}
	}
`)

export type GetQuoteGoogleAddress = {
	latitude: number | null
	longitude: number | null
	googleMapsPlaceId: string
	street: string
	city: string
	county: string
	state: string
	zip: string
	country: string
}

export type GetQuoteService = {
	id: number
	name: string
	codeName: string
	description: string | null
	warning: string | null
}

export type AnswerQuestionQuestion = {
	id: number
	question: string
	answers:
		| {
				id: number
				answer: string
		  }[]
		| null
	services:
		| {
				id: number
		  }[]
		| null
}

export type GetQuoteStore = {
	selectableServices: GetQuoteService[]
	selectedService: GetQuoteService | undefined
	questions: AnswerQuestionQuestion[]
	selectedAnswerIds: Set<number>
	places: PlaceResultWithSelected[]
	bounds: google.maps.LatLngBounds | null
	emailAddress: string
	quotedPrice: string | null
	questionsAvailable: boolean
	inServiceArea: boolean
	priceError: string | null
	isLoadingPrice: boolean
	googleMapsPlaceId: string
	approvedQuote: boolean
	userAccount: NewUserAccountInfo & { confirmPassword: string; password?: string; requestedRoles: Array<string> }
}

export function getSelectedAddress(place: PlaceResultWithSelected | null): GetQuoteGoogleAddress | undefined {
	if (!place) return
	return {
		...parseGoogleMapsPlaceAddress(place?.address_components ?? {}),
		latitude: place?.geometry?.location?.lat?.() ?? null,
		longitude: place?.geometry?.location?.lng?.() ?? null,
		googleMapsPlaceId: place?.place_id ?? '',
	}
}

export async function getQuotePrice(quoteData: GetQuoteStore, derivedSelectedAddress: GetQuoteGoogleAddress | undefined) {
	let res: { readonly priceQuote: { readonly price: string | null; readonly error: string | null } } | null = null
	try {
		if (!derivedSelectedAddress) {
			throw new Error('No address selected')
		}
		if (!quoteData.selectedService) {
			throw new Error('No service selected')
		}
		const findParcelResult = await getQuoteFindParcelQuery.fetch({
			variables: {
				quote: {
					getQuotePage: true,
					quoteEmail: quoteData.emailAddress,
					serviceId: quoteData.selectedService.id,
				},
				location: {
					street: derivedSelectedAddress.street,
					city: derivedSelectedAddress.city,
					county: derivedSelectedAddress.county,
					state: derivedSelectedAddress.state,
					zip: derivedSelectedAddress.zip.toString(),
					country: derivedSelectedAddress.country,
					googleMapsPlaceId: derivedSelectedAddress.googleMapsPlaceId,
					latitude: derivedSelectedAddress.latitude ?? 0,
					longitude: derivedSelectedAddress.longitude ?? 0,
				},
				serviceId: quoteData.selectedService.id,
				answerIds: quoteData.questions.length > 0 ? Array.from(quoteData.selectedAnswerIds) : undefined,
			},
		})

		const findParcelResultData = findParcelResult.data?.findParcel
		if (!findParcelResultData) {
			throw new Error('No data returned')
		}
		res = findParcelResultData
	} catch (error) {
		console.error(error)
	}
	return res
}
