<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import GetQuoteCardHeader from './GetQuoteCardHeader.svelte'
	import GooglePlacesMap from '../GooglePlacesMap.svelte'
	import type { GetQuoteStore } from 'utility/get-quote-helper'
	import gTagHelper from 'utility/gtag-helper'
	import type { Writable } from 'svelte/store'

	export let quoteData: Writable<GetQuoteStore>
	export let stepOpen: boolean
	export let disabled: boolean

	$: header = $quoteData.quotedPrice && stepFinished && !stepOpen ? `$${$quoteData.quotedPrice}` : 'See Price'
	$: if (!$quoteData.quotedPrice && $quoteData.inServiceArea) {
		stepFinished = false
	}

	let stepFinished = false

	function confirmPrice(event: Event) {
		event.preventDefault()
		const priceGA = parseFloat($quoteData.quotedPrice || '0')

		gTagHelper('add_to_card', {
			currency: 'USD',
			value: priceGA,
			items: [
				{
					item_id: $quoteData.selectedService!.codeName,
					item_name: $quoteData.selectedService!.name,
					currency: 'USD',
					index: 0,
					price: priceGA,
					quantity: 1,
				},
			],
		})
		$quoteData.approvedQuote = true
		stepFinished = true
		// This is an as declaration because the getElementById returns an HTMLElement, but we know it's an input element and doing const emailAddressInput: HTMLInputElement is a type error
		const emailAddressInput = document.getElementById('emailAddressInput') as HTMLInputElement
		if (emailAddressInput) {
			emailAddressInput.value = $quoteData.emailAddress || ''
		}
	}
</script>

<CollapsibleCard
	entireHeaderToggles
	{disabled}
	cardHeaderClass="card-header d-flex justify-content-between h4"
	cardStyle="border-radius: 0px; border-bottom: none;"
	bind:bodyShown={stepOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<GetQuoteCardHeader
			{header}
			{stepFinished}
		/>
	</svelte:fragment>
	<div class="row">
		<div class="col-12 col-md-6 col-lg-5 col-xl-4">
			{#if $quoteData.inServiceArea}
				{#if $quoteData.priceError}
					<div
						class="alert alert-danger"
						role="alert"
					>
						{$quoteData.priceError}
					</div>
				{:else}
					<div
						class:h1={!$quoteData.isLoadingPrice}
						class:h2={$quoteData.isLoadingPrice}
					>
						{#if $quoteData.isLoadingPrice}
							<i class="fas fa-spin fa-spinner"></i> Getting Price...
						{:else}
							${$quoteData.quotedPrice}
						{/if}
					</div>
					<hr />
					<h5>What's Included:</h5>
					{#if $quoteData.selectedService}
						<ul>
							{#if $quoteData.selectedService.description}
								{#each $quoteData.selectedService.description.split('\n') as descriptionLine}
									<li style="font-size: smaller;">{descriptionLine}</li>
								{/each}
							{/if}
						</ul>
					{/if}
				{/if}
			{:else}
				<p style="font-size: x-large">This address is outside our current service area for {$quoteData.selectedService?.name}. We will notify you when Lawnhiro is available at this address.</p>
			{/if}
			<form
				on:submit={e => {
					confirmPrice(e)
				}}
				on:submit
			>
				<div class="mt-3">
					<Button
						id="confirmPriceBtn"
						class="mb-2"
						color="primary"
						iconClass="check"
						type="submit"
						disabled={$quoteData.isLoadingPrice}
					>
						Confirm Price
					</Button>
				</div>
			</form>
		</div>
		<div class="col-12 col-md-6 col-lg-7 col-xl-8">
			<GooglePlacesMap
				places={$quoteData.places}
				bounds={$quoteData.bounds}
				mapStyle="height: 30vh; min-height: 200px;"
			/>
		</div>
	</div>
</CollapsibleCard>
