import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadAllTimeRegion'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadAllTimeRegionStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadAllTimeRegionStore",
			variables: true,
		})
	}
}

export async function load_LoadAllTimeRegion(params) {
	await initClient()

	const store = new LoadAllTimeRegionStore()

	await store.fetch(params)

	return {
		LoadAllTimeRegion: store,
	}
}
