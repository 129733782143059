import template from './card-picker.html'
import makeButton from '@isoftdata/button'

const cardTypeIconMap = {
	'visa': { icon: 'fa-brands fa-cc-visa', displayName: 'Visa' },
	'mastercard': { icon: 'fa-brands fa-cc-mastercard', displayName: 'MasterCard' },
	'amex': { icon: 'fa-brands fa-cc-amex', displayName: 'American Express' },
	'discover': { icon: 'fa-brands fa-cc-discover', displayName: 'Discover' },
	'diners': { icon: 'fa-brands fa-cc-diners-club', displayName: 'Diners Club' },
	'jcb': { icon: 'fa-brands fa-cc-jcb', displayName: 'JCB' },
	'unknown': { icon: 'fas fa-credit-card-front', displayName: 'Unknown' },
}

export default function createCardPickerComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				cards: [],
				cardTypeIconMap,
				selectedCardId: null,
				showDeleteButton: true,
				showUpdateButton: false,
			}
		},
		components: {
			itButton: makeButton(),
		},
		attributes: {
			required: [],
			optional: [ 'cards', 'selectedCardId', 'showDeleteButton', 'showUpdateButton' ],
		},
		onrender() {
			// const ractive = this

			//
		},
	})
}
