import { getActiveWallet } from 'utility/payment-method-helper'
import component from './PaymentMethod.svelte'
import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.account.payment-method',
		route: 'payment-method',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			// Fetch active wallet if no wallet empty array
			const activeWallet = (await getActiveWallet()) ?? []
			return Promise.resolve({
				cards: activeWallet,
			})
		},
	})
}
