<script lang="ts">
	import { createEventDispatcher, getContext } from 'svelte'

	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import Attachment, { type BaseAttachmentFile, type UnsavedAttachmentFile } from '@isoftdata/svelte-attachments'

	import { attachNewFilesToJob, detachFilesFromJob, type FileAssociation } from 'utility/graphql/manage-image-files'
	import type { Mediator } from 'services/api-fetch'
	import { upsert } from '@isoftdata/utility-array'

	const mediator = getContext<Mediator>('mediator')

	const dispatch = createEventDispatcher()

	export let jobId: number | null
	export let jobClaimId: number | null
	export let isClockedIn: boolean
	export let isConfirmingCompletion: boolean
	export let show = false
	export let hasStartedJob = false
	export let photoFiles: BaseAttachmentFile[] = []
	export let maxImageDimensions = 1600
	export let fileAssociation: FileAssociation
	export let fileLimitGlobalSetting: {
		beforeServiceMinFileCount: number
		beforeServiceMaxFileCount: number
		afterServiceMinFileCount: number
		afterServiceMaxFileCount: number
	} = {
		beforeServiceMinFileCount: 2,
		beforeServiceMaxFileCount: 2,
		afterServiceMinFileCount: 2,
		afterServiceMaxFileCount: 2,
	}
	let fileInput: HTMLInputElement | undefined = undefined
	let saving = false

	$: photoFileUploadedCount = photoFiles.reduce((acc, file) => (file.fileId ? acc + 1 : acc), 0)

	$: uploadDisabled = hasStartedJob ? photoFileUploadedCount >= fileLimitGlobalSetting.afterServiceMaxFileCount : photoFileUploadedCount >= fileLimitGlobalSetting.beforeServiceMaxFileCount
	$: startJobDisabled = hasStartedJob ? photoFileUploadedCount < fileLimitGlobalSetting.afterServiceMinFileCount : photoFileUploadedCount < fileLimitGlobalSetting.beforeServiceMinFileCount

	async function deleteFileAssociation(filesDeleted: BaseAttachmentFile[]): Promise<void> {
		if (!jobId) {
			throw new Error('Job ID is missing')
		}
		const fileIdsToDelete = filesDeleted.map(file => {
			if (!file.fileId) {
				throw new Error('File ID is missing')
			} else {
				return file.fileId
			}
		})
		try {
			await detachFilesFromJob(jobId, fileIdsToDelete)
			fileIdsToDelete.forEach(fileId => {
				photoFiles = photoFiles.filter(file => file.fileId !== fileId)
			})
		} catch (error) {
			console.error(error)
		}
	}

	async function saveFileAndAssociate(filesAdded: UnsavedAttachmentFile[]) {
		if (filesAdded.length > fileLimitGlobalSetting.beforeServiceMaxFileCount || filesAdded.length > fileLimitGlobalSetting.afterServiceMaxFileCount) {
			mediator.call('showMessage', { message: 'You can only upload a maximum of 2 photos for each side of the lawn.', heading: 'Error Uploading File', time: 5000, color: 'danger' })
			photoFiles = photoFiles
			return
		}
		if (filesAdded.length === 0) {
			return
		}
		let uploadedFiles: BaseAttachmentFile[] = []
		for (const file of filesAdded) {
			const uploadedFile = await attachNewFilesToJob(file, fileAssociation)
			uploadedFiles.push({
				...file,
				...uploadedFile,
			})
		}
		uploadedFiles.forEach(uploadedFile => upsert(photoFiles, 'uuid', uploadedFile))
		photoFiles = photoFiles
	}

	async function startOrCompleteJob() {
		if (hasStartedJob) {
			isConfirmingCompletion = true
			dispatch('toggleClockState', { jobClaimId: jobClaimId, isClockedIn: isClockedIn })
			dispatch('focusInternalNotes', true)
		} else {
			dispatch('toggleClockState', { jobClaimId: jobClaimId, isClockedIn: isClockedIn })
		}
		show = false
	}

	async function addFiles(e: CustomEvent<UnsavedAttachmentFile[]>) {
		try {
			saving = true
			const filesAdded = e.detail
			await saveFileAndAssociate(filesAdded)
			saving = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { title: 'Error Uploading File' })
			saving = false
		}
	}

	async function deleteFiles(e: CustomEvent<BaseAttachmentFile[]>) {
		try {
			saving = true
			const filesDeleted = e.detail
			await deleteFileAssociation(filesDeleted)
			saving = false
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { title: 'Error Deleting File' })
			saving = false
		}
	}
</script>

<Modal
	bind:show
	title={hasStartedJob ? 'After Service Photos' : 'Before Service Photos'}
	cancelButtonText="Close"
	confirmButtonText={hasStartedJob ? 'Complete Job' : 'Start Job'}
	confirmButtonIcon={saving ? 'spinner' : 'check'}
	confirmButtonDisabled={saving ||
		startJobDisabled ||
		(hasStartedJob ? photoFileUploadedCount > fileLimitGlobalSetting.afterServiceMaxFileCount : photoFileUploadedCount > fileLimitGlobalSetting.beforeServiceMaxFileCount)}
	on:cancel={() => (show = false)}
	on:close={() => (show = false)}
	on:confirm={() => startOrCompleteJob()}
	modalSize="lg"
>
	<Button
		color="primary"
		class="mt-2 responsive-button"
		iconClass="camera"
		disabled={uploadDisabled}
		on:click={() => {
			fileInput?.click()
		}}>Add Photos</Button
	>
	<Attachment
		selectionButtonsDisabled
		hideRankFeatures
		hidePublicFeatures
		hideAttachmentFileInput
		displayModeButtonsDisabled
		bind:fileList={photoFiles}
		maxImageSize={maxImageDimensions}
		uniqueFileTypeSpecifer="image/*"
		{uploadDisabled}
		on:filesAdded={e => {
			addFiles(e)
		}}
		on:filesDeleted={e => {
			deleteFiles(e)
		}}
		bind:fileInput
	>
		<svelte:fragment slot="attachment-placeholder">
			<button
				class="row text-center btn-block border-0"
				on:click={() => fileInput?.click()}
			>
				<div>
					<img
						src="images/image-for-picture-prompt.png"
						style="height: 300px;"
						class="mb-2"
						alt="How to take photos of the front and back of your vehicle"
					/>
				</div>
			</button>
		</svelte:fragment>
	</Attachment>
	{#if !hasStartedJob ? photoFileUploadedCount < fileLimitGlobalSetting.beforeServiceMinFileCount : photoFileUploadedCount < fileLimitGlobalSetting.afterServiceMinFileCount}
		<span class="text-danger text-smaller font-weight-bold">
			* You must upload at least 1 FRONT lawn and 1 BACK lawn photo to {hasStartedJob ? 'complete' : 'start'} this job.
		</span>
	{/if}
	{#if hasStartedJob ? photoFileUploadedCount > fileLimitGlobalSetting.afterServiceMaxFileCount : photoFileUploadedCount > fileLimitGlobalSetting.beforeServiceMaxFileCount}
		<span class="text-danger text-smaller font-weight-bold"> * You can only upload a maximum of 2 photos. </span>
	{/if}
</Modal>
