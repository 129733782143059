export default {
    "name": "LoadResidenceHelperTags",
    "kind": "HoudiniQuery",
    "hash": "4e0d6c81cc41ddc9647ba580b1a74585ba4b00e07179746118ecdb6a93ad8690",

    "raw": `query LoadResidenceHelperTags($filter: TagQueryFilter) {
  tags(filter: $filter) {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "tags": {
                "type": "TagResponse",
                "keyRaw": "tags(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Tag",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "TagQueryFilter"
        },

        "types": {
            "TagQueryFilter": {
                "code": "String",
                "entityType": "EntityType",
                "id": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=855b415abe9aadb2af5fa93ae6cd8af35cb37e4e34ad0d574e6e4ee8537db63f";