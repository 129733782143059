import template from './customer-addresses.html'
import formatCityStateZip from 'utility/format/format-city-state-zip'
import parseGoogleMapsPlaceAddress from 'utility/map-google-place'
import addNewAddress from 'utility/add-new-address'

//Ractive components
import makeGooglePlacesAutocomplete from './google-places-autocomplete'
import makeGooglePlacesMap from './google-places-map'
import makeButton from '@isoftdata/button'

export default function createCustomerAddressesComponent(mediator) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				customerResidences: [],
				places: undefined,
				bounds: undefined,
				addResidence: false,
				selectableAddresses: false,
				selectedResidenceId: null,
				title: 'Addresses',
				headerClass: 'h4',
				isSavingNewResidence: false,
				serviceId: null,
				cardClass: '',
			}
		},
		components: {
			googlePlacesAutocomplete: makeGooglePlacesAutocomplete(),
			googlePlacesMap: makeGooglePlacesMap(),
			itButton: makeButton(),
		},
		attributes: {
			required: [ 'customerResidences' ],
			optional: [ 'selectableAddresses', 'title', 'headerClass', 'selectedResidenceId', 'serviceId', 'cardClass' ],
		},
		computed: {
			propertyAddresses() {
				return this.get('customerResidences')?.map?.(customer => {
					return {
						...customer,
						cityStateZipFormatted: formatCityStateZip({
							city: customer.city,
							state: customer.state,
							zip: customer.zip,
						}),
					}
				})
			},
			allAddressesHaveSameCityStateZip() {
				// const addresses = this.get('propertyAddresses')
				// //Check to see if all the addresses have the same city, state, zip combo
				// if (addresses?.length) {
				// 	return addresses.every(({ city, state }) => {
				// 		return addresses?.[0].city === city &&
				// 			addresses?.[0].state === state
				// 	})
				// } else {
				// 	return true
				// }

				//Jake requested to always display the full address of a residence (requesting during the multi-region version)
				return false
			},
		},
		async deactivateAddress(selectedAddress) {
			const ractive = this
			const deactivateResidence = `#graphql
				mutation DeactivateResidence($deactivateResidenceId: PositiveInt!) {
					deactivateResidence(id: $deactivateResidenceId) {
						id
					}
				}
			`

			try {
				await mediator.call('apiFetch', deactivateResidence, { deactivateResidenceId: selectedAddress.id })
				const addressIndex = ractive.get('customerResidences').findIndex(addresses => addresses.id === selectedAddress?.id)
				ractive.splice('customerResidences', addressIndex, 1)
				ractive.fire('addressRemoved', {}, { removedResidenceId: selectedAddress.id, remainingResidences: ractive.get('customerResidences') })
			} catch (err) {
				console.error(err)
				alert(err?.message
					|| `An error occurred while trying to remove the address.

					Please try again later or contact Lawn Hiro Support.`)
			}
		},
		async addAddress(places) {
			const ractive = this
			const session = JSON.parse(localStorage.getItem('session'))
			const customerId = session.customer.id

			const addressData = {
				...parseGoogleMapsPlaceAddress(places?.[0].address_components || {}),
				latitude: places?.[0].geometry?.location?.lat?.() || null,
				longitude: places?.[0].geometry?.location?.lng?.() || null,
			}

			ractive.set({ isSavingNewResidence: true })

			try {
				const { newResidence } = await addNewAddress(mediator, this.get('serviceId'), customerId, addressData, places?.[0].place_id)
				ractive.set({ addResidence: false }) //close the add-address ui
				ractive.splice('customerResidences', 0, 0, newResidence)
				ractive.fire('addressAdded', {}, newResidence)
				//alert(`You have added ${addressData?.street} successfully!`)
				ractive.set({ isSavingNewResidence: false })
			} catch (err) {
				alert(err.message ||
					`An error occurred while trying to add the address.

					Please try again later or contact Lawn Hiro Support.`)
				ractive.set({ isSavingNewResidence: false })
			}
		},
		async removeAddress(selectedAddress, event) {
			event?.stopPropagation() //prevent the click event from bubbling up, which would cause 'selectAddress' to be called

			// const session = JSON.parse(localStorage.getItem('session'))

			const ractive = this
			//check the jobs that are associated with the selected address
			const jobs = `#graphql
				query UpcomingOrders{
					upcomingOrders{
						data {
							id
							customerId
							residenceId
							jobStatus
						}
					}
				}
			`

			try {
				const pendingAndClaimedJobs = await mediator.call('apiFetch', jobs)

				//get the number of jobs that are pending or in-progress
				const numOfPendingJobs = pendingAndClaimedJobs?.jobs?.data.filter(job => job.jobStatus === 'PENDING')?.length || 0
				const numOfClaimedJobs = pendingAndClaimedJobs?.jobs?.data.filter(job => job.jobStatus === 'CLAIMED')?.length || 0

				if (numOfClaimedJobs) {
					//leave early if this residence has claimed jobs. They'll have to try again later.
					return alert(`You have ${numOfClaimedJobs} in-progress orders with this address. You are only allowed to remove this address after the claimed order(s) are complete.`)
				}

				if (numOfPendingJobs) {
					if (confirm(`You have ${numOfPendingJobs} pending orders associated with this address. Are you sure you want to remove this address and any associated orders?`)) {
						return ractive.deactivateAddress(selectedAddress)
					} else {
						//Don't ask them anymore questions, they cancelled.
						return
					}
				}

				//If we made it here, we can just ask them a standard confirmation question
				if (confirm('Are you sure you want to remove this address?')) {
					return ractive.deactivateAddress(selectedAddress)
				}
			} catch (err) {
				console.error(err)
				if (err.message) {
					alert(err.message)
				} else {
					alert('An error occurred while trying to remove the address.\n\nPlease try again later or contact Lawn Hiro Support.')
				}
			}
		},
		async addNewResidence() {
			const ractive = this
			await ractive.set({ addResidence: true })

			ractive.findComponent('googlePlacesAutocomplete')?.getInputElement?.()?.focus?.()
		},
		selectAddress(address) {
			const ractive = this
			ractive.set({ selectedResidenceId: address?.id })
			ractive.fire('selectAddress', {}, address)
		},
	})
}
