import { CompletedOrdersStore, CompletedOrdersCountStore, graphql, CompletedOrders$result } from '$houdini'

const completedOrdersDataQuery: CompletedOrdersStore = graphql`
	query CompletedOrders($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
		completedOrders(orderBy: $orderBy, pagination: $pagination) {
			data {
				id
				residence {
					street
					city
					state
					zip
					country
				}
				jobStatus
				requestedSchedule
				completed
				transaction {
					paymentStatus
					tip
				}
				review
				service {
					name
				}
				answers {
					id
					answer
				}
			}
		}
	}
`

const completedOrdersCountQuery: CompletedOrdersCountStore = graphql`
	query CompletedOrdersCount($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
		completedOrders(orderBy: $orderBy, pagination: $pagination) {
			info {
				totalItems
			}
		}
	}
`

export async function getCompletedOrdersCount(): Promise<number> {
	const { data } = await completedOrdersCountQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 1,
			},
		},
	})

	if (!data) {
		return 0
	}

	return data.completedOrders.info.totalItems
}

type CompletedOrdersData = CompletedOrders$result['completedOrders']['data']

export async function getCompletedOrders(): Promise<CompletedOrdersData> {
	const { data } = await completedOrdersDataQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 0,
			},
		},
	})

	if (!data) {
		return []
	}

	return data.completedOrders.data
}
