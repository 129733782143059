export default {
    "name": "findParcel",
    "kind": "HoudiniQuery",
    "hash": "7f59490ae53f968c1f0085b90d52e6c2c4f18929f0f1f64574bb742cc3f60cd5",

    "raw": `query findParcel($quote: QuoteInputType, $location: ParcelAddress, $serviceId: PositiveInt, $answerIds: [PositiveInt!]) {
  findParcel(quote: $quote, location: $location) {
    priceQuote(serviceId: $serviceId, quote: $quote, answerIds: $answerIds) {
      price
      error
    }
    googleMapsPlaceId
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "findParcel": {
                "type": "Parcel",
                "keyRaw": "findParcel(location: $location, quote: $quote)",

                "selection": {
                    "fields": {
                        "priceQuote": {
                            "type": "PriceQuote",
                            "keyRaw": "priceQuote(answerIds: $answerIds, quote: $quote, serviceId: $serviceId)",

                            "selection": {
                                "fields": {
                                    "price": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "price",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "error": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "error",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "googleMapsPlaceId": {
                            "type": "String",
                            "keyRaw": "googleMapsPlaceId",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "quote": "QuoteInputType",
            "location": "ParcelAddress",
            "serviceId": "PositiveInt",
            "answerIds": "PositiveInt"
        },

        "types": {
            "QuoteInputType": {
                "getQuotePage": "Boolean",
                "quoteEmail": "EmailAddress",
                "serviceId": "Float"
            },

            "ParcelAddress": {
                "city": "String",
                "country": "String",
                "county": "String",
                "googleMapsPlaceId": "String",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "state": "String",
                "street": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a909f1e76f657b217698e840de8e4c29385a94d557196c0a535bbd71574af3a3";