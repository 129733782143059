export default {
    "name": "LoadQuotes",
    "kind": "HoudiniQuery",
    "hash": "adedac71aba61982a33df1e75fcfcd8f634e13b59f90cd26e5e2fdafbe7daef7",

    "raw": `query LoadQuotes($filter: QuoteFilter) {
  quotes(filter: $filter) {
    email
    googleMapsPlaceId
    price
    serviceId
    street
    city
    state
    country
    zip
    priceErrorStatus
    service {
      id
      name
    }
    created
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "quotes": {
                "type": "Quote",
                "keyRaw": "quotes(filter: $filter)",

                "selection": {
                    "fields": {
                        "email": {
                            "type": "EmailAddress",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "googleMapsPlaceId": {
                            "type": "String",
                            "keyRaw": "googleMapsPlaceId",
                            "visible": true
                        },

                        "price": {
                            "type": "String",
                            "keyRaw": "price",
                            "nullable": true,
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "priceErrorStatus": {
                            "type": "PriceErrorStatus",
                            "keyRaw": "priceErrorStatus",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "created": {
                            "type": "DateTime",
                            "keyRaw": "created",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "QuoteFilter"
        },

        "types": {
            "QuoteFilter": {
                "createdFrom": "DateTime",
                "createdTo": "DateTime",
                "statuses": "PriceErrorStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=11566dca0a315e3049ec3bec134f82c0dfcffad4c894a0c3eb37ce7b755b8835";