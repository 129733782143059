import template from './credit-card-entry.html'

export default function createCreditCardEntryComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				token: '',
				fullName: '',
				submitButtonDisabled: false,
				submitButtonText: 'Add Card',
			}
		},
		attributes: {
			optional: [ 'token', 'fullName', 'submitButtonDisabled', 'submitButtonText' ],
		},
		css: `
			iframe { width: 100%; }
			.card-entry-label { width: 100%; }
			.credit-card-submit > iframe {
				height: 41px!important;
			}
		`,
		onrender() {
			const ractive = this

			GlobalPayments.configure({
				env: '__heartlandApiEnv__',
				publicApiKey: '__heartlandPublicApiKey__',
				enableAutocomplete: true,
			})
			//const cardForm = GlobalPayments.creditCard.form("#credit-card")

			const cardForm = GlobalPayments.ui.form({
				fields: {
					'card-holder-name': {
						placeholder: 'Jane Smith',
						target: '#credit-card-card-holder',
						value: this.get('fullName') || '',
					},
					'card-number': {
						placeholder: '•••• •••• •••• ••••',
						target: '#credit-card-card-number',
					},
					'card-expiration': {
						placeholder: 'MM / YYYY',
						target: '#credit-card-card-expiration',
					},
					'card-cvv': {
						placeholder: '•••',
						target: '#credit-card-card-cvv',
					},
					'submit': {
						text: ractive.get('submitButtonText'), //this property is undocumented, but allows you to change the submit button's text
						value: 'Submit',
						target: '#credit-card-submit',
					},
				},
				styles: {
					'input': {
						'margin': '2px !important;',
						'display': 'block;',
						'width': '100% !important;',
						'height': 'calc(1.5em + .5rem + 2px);',
						'padding': '.25rem .5rem;',
						'font-size': '.875rem;',
						'font-weight': '400;',
						'line-height': '1.5;',
						'color': '#5a5a5a;',
						'background-color': '#fff;',
						'background-clip': 'padding-box;',
						'border': '1px solid #ced4da;',
						'border-radius': '.3rem;',
					},
					'button': {
						'color': '#fff;',
						'display': 'inline-block;',
						'font-weight': '400;',
						'text-align': 'center;',
						'vertical-align': 'middle;',
						'-webkit-user-select': 'none;',
						'-moz-user-select': 'none;',
						'-ms-user-select': 'none;',
						'user-select': 'none;',
						'background-color': '#78c2ad;',
						'border-color': '#78c2ad;',
						'padding': '.375rem .75rem;',
						'font-size': '1rem;',
						'line-height': '1.5;',
						'border-radius': '.4rem;',
					},
				},
			})

			cardForm.ready(() => ractive.fire('credit-card-form-ready', {}, true))

			cardForm.on('token-success', res => {
				ractive.fire('tokenSuccess', {}, { ...res.details, token: res.paymentReference })
				ractive.set({ token: res.paymentReference })
			})

			cardForm.on('token-error', res => {
				ractive.fire('tokenError', {}, res)

				//TODO: show error message
			})

			cardForm.on('card-number', 'register', () => {
				ractive.fire('card-number-registered', {}, true)
			})

			/*
				GlobalPayments.events.addHandler(ractive.find('#testButton'), 'click', e => {
					console.log(cardForm)
				})
			*/
		},
	})
}
