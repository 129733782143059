import { loadCompletedJobClaimsData } from 'utility/completed-jobs-helper'
import component from './CompletedJobs.svelte'

import type { AppContext } from 'types/common'
import { getSession } from 'stores/session'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.provider.completed-jobs',
		route: 'completed-jobs',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()

			if (!session.provider) {
				return Promise.reject({ redirect: { state: 'app.login' } })
			}

			const jobClaims = await loadCompletedJobClaimsData(session.provider.id)

			return {
				jobClaims,
			}
		},
	})
}
