export default {
    "name": "getQuoteServices",
    "kind": "HoudiniQuery",
    "hash": "eecf2d56433058ac4ea35be87e623ea6719b04132b09bcad1af1be3409c2b4ae",

    "raw": `query getQuoteServices($filter: ServiceFilter) {
  services(filter: $filter) {
    data {
      id
      name
      codeName
      description
      warning
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "warning": {
                                        "type": "String",
                                        "keyRaw": "warning",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ServiceFilter"
        },

        "types": {
            "ServiceFilter": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "regionId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6c2c42816611b558eb3a4e1bc8b59330e2f86f4670b65068fc87b667fe48b809";