import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadRegionNoHelper'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadRegionNoHelperStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadRegionNoHelperStore",
			variables: false,
		})
	}
}

export async function load_LoadRegionNoHelper(params) {
	await initClient()

	const store = new LoadRegionNoHelperStore()

	await store.fetch(params)

	return {
		LoadRegionNoHelper: store,
	}
}
