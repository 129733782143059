<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Table, { Td } from '@isoftdata/svelte-table'

	import type { Mediator } from 'services/api-fetch'
	import type { SvelteAsr } from 'types/common'
	import { createZone, editZone, loadZones, type ZoneManagementZone, type ZoneManagementRegion, type ZoneManagementState, type ZoneModalType } from 'utility/zones-helper'
	import ZoneModal from './ZoneModal.svelte'

	import type { CreateZone$input, EditZone$input } from '$houdini/index'
	import { getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	export let asr: SvelteAsr

	export let zones: ZoneManagementZone[]
	export let states: ZoneManagementState[]

	const regions: ZoneManagementRegion[] = zones.flatMap(zone => zone.regions ?? [])

	let filteredZones: ZoneManagementZone[] = zones
	let stateFilter: string | null = null
	let hoveredRegionId: number | null = null

	$: statesInUse = states.filter(state => zones.some(zone => zone.state === state.stateAbbreviation))

	let selectedZone: ZoneManagementZone | null = null

	let zoneModal: ZoneModalType = {
		show: false,
		state: null,
		name: '',
		description: '',
		selectedRegionIds: [],
		editing: false,
		errorMessage: '',
	}

	function openAddZoneModal() {
		zoneModal = {
			show: true,
			state: null,
			name: '',
			description: '',
			selectedRegionIds: [],
			editing: false,
			errorMessage: '',
		}
	}

	function openEditZoneModal(zone: ZoneManagementZone) {
		zoneModal = {
			show: true,
			state: zone.state,
			name: zone.name,
			description: zone.description,
			selectedRegionIds: zone.regions?.map(region => region.id) ?? [],
			editing: true,
			errorMessage: '',
		}
	}

	async function addZone() {
		if (!zoneModal.state || !zoneModal.name) {
			let missingFields: string[] = []
			if (!zoneModal.state) {
				missingFields.push('State')
			}
			if (!zoneModal.name) {
				missingFields.push('Name')
			}
			if (missingFields.length > 0) {
				mediator.call('showMessage', { message: `${missingFields.toString()}`, time: 5000, heading: 'Missing Required Fields' })
			}
			zoneModal.errorMessage = 'Missing Required Fields'
			return
		}
		const newZoneInput: CreateZone$input = {
			newZone: {
				name: zoneModal.name,
				description: zoneModal.description,
				state: zoneModal.state,
			},
			regionIds: zoneModal.selectedRegionIds,
		}
		// We create the zones then load them so that all zones have the correct children regions since in zone creation we can yoink regions from other zones and this is simpler than trying to keep track of that
		try {
			await createZone(newZoneInput)
		} catch (err: unknown) {
			zoneModal.errorMessage = 'Failed to create zone'
			throw err
		}
		try {
			const reloadedZones = await loadZones()
			zones = reloadedZones
			filteredZones = reloadedZones
			stateFilter = null
			zoneModal.show = false
		} catch (err: unknown) {
			zoneModal.errorMessage = 'Failed to load zones'
			throw err
		}
	}

	async function updateZone() {
		if (!selectedZone) {
			return
		}
		const updateZoneInput: EditZone$input = {
			updateZone: {
				id: selectedZone.id,
				name: zoneModal.name,
				description: zoneModal.description,
				state: zoneModal.state,
			},
			regionIds: zoneModal.selectedRegionIds,
		}
		// We do the same thing here as in addZone to ensure that the regions are correct
		try {
			await editZone(updateZoneInput)
		} catch (err: unknown) {
			zoneModal.errorMessage = 'Failed to update zone'
			throw err
		}
		try {
			const reloadedZones = await loadZones()
			zones = reloadedZones
			filteredZones = reloadedZones
			stateFilter = null
			zoneModal.show = false
		} catch (err: unknown) {
			zoneModal.errorMessage = 'Failed to load zones'
			throw err
		}
	}

	$: filteredZones = stateFilter ? zones.filter(zone => zone.state === stateFilter) : zones
</script>

<div class="container-fluid mt-3">
	<div class="d-flex justify-content-end mb-3">
		<Button
			id="add-zone-button"
			title="Add Zone"
			iconClass={selectedZone ? 'pencil' : 'plus'}
			color="primary"
			on:click={() => {
				if (selectedZone) {
					openEditZoneModal(selectedZone)
				} else {
					openAddZoneModal()
				}
			}}
		>
			{selectedZone ? 'Edit' : 'Add'} Zone
		</Button>
	</div>
	<Table
		responsive
		filterEnabled
		showFilterLabel
		filterLabel="Filter Zones"
		rows={filteredZones}
		columns={[
			{ property: 'name', name: 'Name' },
			{ property: 'description', name: 'Description' },
			{ property: 'state', name: 'State' },
		]}
		filterPlaceholder="Search Zones"
		parentStyle="max-height: 70vh; overflow-y: auto;"
		let:row
	>
		<svelte:fragment slot="header">
			<div class="form-row align-items-end">
				<div class="col-12 col-md-2">
					<Select
						id="state-filter-select"
						title="Filter by State"
						label="State Filter"
						showEmptyOption
						emptyText="All States"
						bind:value={stateFilter}
						placeholder="Filter State"
					>
						{#each statesInUse as state}
							<option value={state.stateAbbreviation}>{state.stateName}</option>
						{/each}
					</Select>
				</div>
			</div>
		</svelte:fragment>
		<svelte:fragment slot="no-rows">
			<tr>
				<td colspan="3">No Zones Found</td>
			</tr>
		</svelte:fragment>
		<tr
			class="cursor-pointer"
			class:table-dark={selectedZone?.id === row.id}
			on:click={() => {
				selectedZone = selectedZone?.id === row.id ? null : row
			}}
		>
			<Td property="name">{row.name}</Td>
			<Td property="description">{row.description}</Td>
			<Td property="state">{row.state}</Td>
		</tr>
		{#if selectedZone?.id === row.id && row.regions}
			<td colspan="3">
				<div
					id="zone-id-{row.id}-regions"
					class="card"
				>
					<div class="card-header">
						<h5 class="mb-0">{row.name}'s Regions</h5>
					</div>
					<div class="card-body p-0">
						<ul
							class="list-group colspan-3 list-group-flush"
							style="max-height: 500px; overflow-y: auto; border-bottom-left-radius: 0.4rem; border-bottom-right-radius: 0.4rem;"
						>
							{#each row.regions as region}
								<a
									id="region-{region.id}-link"
									href={asr.makePath('app.admin.location.region', { regionId: region.id })}
									class="list-group-item list-group-item-action"
									class:list-group-item-primary={hoveredRegionId === region.id}
									on:mouseover={() => (hoveredRegionId = region.id)}
									on:focus={() => (hoveredRegionId = region.id)}
								>
									<div class="d-flex justify-content-between">
										<div>
											{region.name}
										</div>
										{#if hoveredRegionId === region.id}
											<i class="fas fa-external-link-alt"></i>
										{/if}
									</div>
								</a>
							{:else}
								<li class="list-group-item">No Regions Assigned to this Zone</li>
							{/each}
						</ul>
					</div>
				</div>
			</td>
		{/if}
	</Table>
</div>

<ZoneModal
	title={zoneModal.editing ? 'Edit Zone' : 'Add Zone'}
	confirmButtonText={zoneModal.editing ? 'Edit Zone' : 'Add Zone'}
	{regions}
	{states}
	{zones}
	bind:zoneModal
	on:confirm={zoneModal.editing ? updateZone : addZone}
/>
