export default {
    "name": "UpdateUserAccountInfoMutation",
    "kind": "HoudiniMutation",
    "hash": "bdd7c48a5d902cef52efaf633b5e2a47f177dfd1098779cc90b2df48f912818f",

    "raw": `mutation UpdateUserAccountInfoMutation($userAccountInfo: UpdateUserAccountInfo!) {
  updateAccountInfo(userAccountInfo: $userAccountInfo) {
    recoveryEmail
    firstName
    fullName
    lastName
    customer {
      id
      billingCountry
      billingZip
      billingState
      billingCity
      billingStreet
      ownedResidences {
        data {
          id
          street
          city
          state
          zip
        }
      }
    }
    provider {
      id
      status
      profilePictureFile {
        path
        id
      }
      latestOnboardingApplication {
        id
        status
      }
    }
    userRoles {
      permissionName
      value
    }
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateAccountInfo": {
                "type": "UserAccount",
                "keyRaw": "updateAccountInfo(userAccountInfo: $userAccountInfo)",

                "selection": {
                    "fields": {
                        "recoveryEmail": {
                            "type": "EmailAddress",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "visible": true
                        },

                        "fullName": {
                            "type": "String",
                            "keyRaw": "fullName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "billingCountry": {
                                        "type": "String",
                                        "keyRaw": "billingCountry",
                                        "visible": true
                                    },

                                    "billingZip": {
                                        "type": "String",
                                        "keyRaw": "billingZip",
                                        "visible": true
                                    },

                                    "billingState": {
                                        "type": "String",
                                        "keyRaw": "billingState",
                                        "visible": true
                                    },

                                    "billingCity": {
                                        "type": "String",
                                        "keyRaw": "billingCity",
                                        "visible": true
                                    },

                                    "billingStreet": {
                                        "type": "String",
                                        "keyRaw": "billingStreet",
                                        "visible": true
                                    },

                                    "ownedResidences": {
                                        "type": "ResidenceResponse",
                                        "keyRaw": "ownedResidences",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "data": {
                                                    "type": "Residence",
                                                    "keyRaw": "data",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "street": {
                                                                "type": "String",
                                                                "keyRaw": "street",
                                                                "visible": true
                                                            },

                                                            "city": {
                                                                "type": "String",
                                                                "keyRaw": "city",
                                                                "visible": true
                                                            },

                                                            "state": {
                                                                "type": "String",
                                                                "keyRaw": "state",
                                                                "visible": true
                                                            },

                                                            "zip": {
                                                                "type": "String",
                                                                "keyRaw": "zip",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "provider": {
                            "type": "Provider",
                            "keyRaw": "provider",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "latestOnboardingApplication": {
                                        "type": "ProviderApplication",
                                        "keyRaw": "latestOnboardingApplication",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderApplicationStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userRoles": {
                            "type": "UserRole",
                            "keyRaw": "userRoles",

                            "selection": {
                                "fields": {
                                    "permissionName": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "permissionName",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountInfo": "UpdateUserAccountInfo"
        },

        "types": {
            "UpdateUserAccountInfo": {
                "email": "EmailAddress",
                "firstName": "String",
                "id": "PositiveInt",
                "lastName": "String",
                "mobile": "PhoneNumber"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=79eb2389d02464898651ba3d442bf53e9e127f5e99f018ec9126d0f8cc400586";