<script lang="ts">
	import { slide } from 'svelte/transition'
	import type { GetQuoteService } from 'utility/get-quote-helper'
	import Alert from '@isoftdata/svelte-alert'

	export let services: GetQuoteService[] = []
	export let selectedService: GetQuoteService | undefined
	export let showWarning: boolean = true
	export let alwaysShowServiceDescription: boolean = true
</script>

{#each services as service}
	<div
		class="form-check mt-2"
		out:slide={{ duration: 300 }}
	>
		<input
			class="form-check-input"
			type="radio"
			name="selectedService"
			value={service}
			bind:group={selectedService}
			id="service-radio-{service.id}"
		/>
		<label
			class="form-check-label"
			for="service-radio-{service.id}"
		>
			<h5>{service.name}</h5>
			{#if selectedService}
				{#if alwaysShowServiceDescription || selectedService.id === service.id}
					<ul
						class="mb-2 pl-3"
						out:slide={{ duration: 100 }}
						in:slide={{ duration: 100 }}
					>
						{#if service.description}
							{#each service.description.split('\n') as descriptionLine}
								<li style="font-size: smaller;">
									{descriptionLine}
								</li>
							{/each}
						{/if}
					</ul>
					{#if service.warning && showWarning}
						<Alert
							color="danger"
							class="mb-1"
						>
							{service.warning}
						</Alert>
					{/if}
				{/if}
			{/if}
		</label>
	</div>
{/each}
