export default {
    "name": "ChangeAnswerToResidence",
    "kind": "HoudiniMutation",
    "hash": "12b9fc5fdea9a8880780095fa86af738a94c37dc07b736f810f5f15891887681",

    "raw": `mutation ChangeAnswerToResidence($updateResidenceAnswers: UpdateResidenceAnswers!) {
  updateAnswerToResidence(updateResidenceAnswers: $updateResidenceAnswers) {
    ...ResidenceData
    id
  }
}

fragment ResidenceData on Residence {
  id
  street
  city
  state
  zip
  regionId
  estimatedLawnSquareFootage
  effectiveLawnSquareFootage
  customer {
    userAccount {
      fullName
      email
      id
    }
    id
  }
  answers {
    id
    answer
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateAnswerToResidence": {
                "type": "Residence",
                "keyRaw": "updateAnswerToResidence(updateResidenceAnswers: $updateResidenceAnswers)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "estimatedLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "estimatedLawnSquareFootage",
                            "visible": true
                        },

                        "effectiveLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "effectiveLawnSquareFootage",
                            "nullable": true,
                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",

                            "selection": {
                                "fields": {
                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ResidenceData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "updateResidenceAnswers": "UpdateResidenceAnswers"
        },

        "types": {
            "UpdateResidenceAnswers": {
                "answerIds": "PositiveInt",
                "residenceId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=9bddda8415db02995b6ef522edce7996706a2ed5edd6b48330e5c92450857da0";