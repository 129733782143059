export default {
    "name": "LoadProviderJobClaims",
    "kind": "HoudiniQuery",
    "hash": "a2c32e5cadf76f5edd03eb1a78a2345f1f03dff773e5e3d69273783413400039",

    "raw": `query LoadProviderJobClaims($filter: JobClaimFilter, $orderBy: [JobClaimOrderBy!]) {
  jobClaims(filter: $filter, orderBy: $orderBy) {
    data {
      id
      jobClaimStatus
      scheduledAt
      cancellationReason
      jobId
      isLate
      job {
        id
        requestedSchedule
        customerFirstJob
        completed
        created
        residence {
          street
          city
          state
          zip
          country
          googleMapsPlaceId
          displayLawnSquareFootage
          latitude
          longitude
          region {
            name
            id
          }
          answers {
            tag {
              id
              name
              entityType
            }
            id
          }
          id
        }
        jobStatus
        specialInstructions
        transaction {
          cost
          tip
          id
        }
        files {
          id
          rank
          public
          imageFileType
          file {
            id
            name
            path
            hash
            type
          }
        }
        answers {
          id
          answer
        }
        service {
          name
          id
        }
        jobClaims {
          id
          scheduledAt
        }
      }
      allActivity {
        id
        elapsedTime
        end
        start
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "jobClaims": {
                "type": "JobClaimResponse",
                "keyRaw": "jobClaims(filter: $filter, orderBy: $orderBy)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "JobClaim",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "jobClaimStatus": {
                                        "type": "JobClaimStatus",
                                        "keyRaw": "jobClaimStatus",
                                        "visible": true
                                    },

                                    "scheduledAt": {
                                        "type": "DateTime",
                                        "keyRaw": "scheduledAt",
                                        "visible": true
                                    },

                                    "cancellationReason": {
                                        "type": "String",
                                        "keyRaw": "cancellationReason",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "jobId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "jobId",
                                        "visible": true
                                    },

                                    "isLate": {
                                        "type": "Boolean",
                                        "keyRaw": "isLate",
                                        "visible": true
                                    },

                                    "job": {
                                        "type": "Job",
                                        "keyRaw": "job",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "requestedSchedule": {
                                                    "type": "Date",
                                                    "keyRaw": "requestedSchedule",
                                                    "visible": true
                                                },

                                                "customerFirstJob": {
                                                    "type": "Boolean",
                                                    "keyRaw": "customerFirstJob",
                                                    "visible": true
                                                },

                                                "completed": {
                                                    "type": "DateTime",
                                                    "keyRaw": "completed",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "residence": {
                                                    "type": "Residence",
                                                    "keyRaw": "residence",

                                                    "selection": {
                                                        "fields": {
                                                            "street": {
                                                                "type": "String",
                                                                "keyRaw": "street",
                                                                "visible": true
                                                            },

                                                            "city": {
                                                                "type": "String",
                                                                "keyRaw": "city",
                                                                "visible": true
                                                            },

                                                            "state": {
                                                                "type": "String",
                                                                "keyRaw": "state",
                                                                "visible": true
                                                            },

                                                            "zip": {
                                                                "type": "String",
                                                                "keyRaw": "zip",
                                                                "visible": true
                                                            },

                                                            "country": {
                                                                "type": "String",
                                                                "keyRaw": "country",
                                                                "visible": true
                                                            },

                                                            "googleMapsPlaceId": {
                                                                "type": "String",
                                                                "keyRaw": "googleMapsPlaceId",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "displayLawnSquareFootage": {
                                                                "type": "NonNegativeInt",
                                                                "keyRaw": "displayLawnSquareFootage",
                                                                "visible": true
                                                            },

                                                            "latitude": {
                                                                "type": "Latitude",
                                                                "keyRaw": "latitude",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "longitude": {
                                                                "type": "Longitude",
                                                                "keyRaw": "longitude",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "region": {
                                                                "type": "Region",
                                                                "keyRaw": "region",

                                                                "selection": {
                                                                    "fields": {
                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "answers": {
                                                                "type": "Answer",
                                                                "keyRaw": "answers",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "tag": {
                                                                            "type": "Tag",
                                                                            "keyRaw": "tag",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "id": {
                                                                                        "type": "PositiveInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    },

                                                                                    "name": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "name",
                                                                                        "visible": true
                                                                                    },

                                                                                    "entityType": {
                                                                                        "type": "EntityType",
                                                                                        "keyRaw": "entityType",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "jobStatus": {
                                                    "type": "JobStatus",
                                                    "keyRaw": "jobStatus",
                                                    "visible": true
                                                },

                                                "specialInstructions": {
                                                    "type": "String",
                                                    "keyRaw": "specialInstructions",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "transaction": {
                                                    "type": "Transaction",
                                                    "keyRaw": "transaction",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "cost": {
                                                                "type": "String",
                                                                "keyRaw": "cost",
                                                                "visible": true
                                                            },

                                                            "tip": {
                                                                "type": "String",
                                                                "keyRaw": "tip",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "files": {
                                                    "type": "JobFile",
                                                    "keyRaw": "files",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "rank": {
                                                                "type": "NonNegativeInt",
                                                                "keyRaw": "rank",
                                                                "visible": true
                                                            },

                                                            "public": {
                                                                "type": "Boolean",
                                                                "keyRaw": "public",
                                                                "visible": true
                                                            },

                                                            "imageFileType": {
                                                                "type": "ImageFileType",
                                                                "keyRaw": "imageFileType",
                                                                "visible": true
                                                            },

                                                            "file": {
                                                                "type": "FileEntity",
                                                                "keyRaw": "file",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "path": {
                                                                            "type": "String",
                                                                            "keyRaw": "path",
                                                                            "visible": true
                                                                        },

                                                                        "hash": {
                                                                            "type": "String",
                                                                            "keyRaw": "hash",
                                                                            "visible": true
                                                                        },

                                                                        "type": {
                                                                            "type": "FileType",
                                                                            "keyRaw": "type",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "answers": {
                                                    "type": "Answer",
                                                    "keyRaw": "answers",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "answer": {
                                                                "type": "String",
                                                                "keyRaw": "answer",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "service": {
                                                    "type": "Service",
                                                    "keyRaw": "service",

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "jobClaims": {
                                                    "type": "JobClaim",
                                                    "keyRaw": "jobClaims",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "scheduledAt": {
                                                                "type": "DateTime",
                                                                "keyRaw": "scheduledAt",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "allActivity": {
                                        "type": "JobClaimActivity",
                                        "keyRaw": "allActivity",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "elapsedTime": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "elapsedTime",
                                                    "visible": true
                                                },

                                                "end": {
                                                    "type": "DateTime",
                                                    "keyRaw": "end",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "start": {
                                                    "type": "DateTime",
                                                    "keyRaw": "start",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "JobClaimFilter",
            "orderBy": "JobClaimOrderBy"
        },

        "types": {
            "JobClaimFilter": {
                "providerId": "PositiveInt",
                "scheduledAtFrom": "DateTime",
                "scheduledAtTo": "DateTime",
                "statuses": "JobClaimStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=56e96b2b486445c5c55b226a33dc1968e2af5c5482abcf2f8e6684014c732f5d";