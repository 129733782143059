import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadWeather'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadWeatherStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadWeatherStore",
			variables: false,
		})
	}
}

export async function load_LoadWeather(params) {
	await initClient()

	const store = new LoadWeatherStore()

	await store.fetch(params)

	return {
		LoadWeather: store,
	}
}
