export default {
    "name": "GetAdminAlertTransactionsCount",
    "kind": "HoudiniQuery",
    "hash": "35c26c1b0939cddb8e2d5050b4dbc8e9afbdd1196156a7031ac04cb0e71280c9",

    "raw": `query GetAdminAlertTransactionsCount($orderBy: [TransactionOrderBy!], $filter: AdminAlertTransactionFilter) {
  getAdminAlertTransactions(orderBy: $orderBy, filter: $filter) {
    info {
      totalItems
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getAdminAlertTransactions": {
                "type": "TransactionResponse",
                "keyRaw": "getAdminAlertTransactions(filter: $filter, orderBy: $orderBy)",

                "selection": {
                    "fields": {
                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "TransactionOrderBy",
            "filter": "AdminAlertTransactionFilter"
        },

        "types": {
            "AdminAlertTransactionFilter": {
                "resolved": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4b2afbc46bf349b497c6f3d4199dd72e70f73ab2bf2bd592550cb199d4681130";