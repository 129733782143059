import artifact from '$houdini/artifacts/DeleteServiceApplicationInOnboardingApplication'
import { MutationStore } from '../runtime/stores/mutation'

export class DeleteServiceApplicationInOnboardingApplicationStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
