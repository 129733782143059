export default {
    "name": "LoadProviderUserRegions",
    "kind": "HoudiniQuery",
    "hash": "8d66298c40dcd2a14df4ad0aa4a259e47fa9af34b8b76375a4843b227af78915",

    "raw": `query LoadProviderUserRegions($userId: PositiveInt!) {
  getUserRegions(userId: $userId) {
    region {
      id
      name
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getUserRegions": {
                "type": "UserRegion",
                "keyRaw": "getUserRegions(userId: $userId)",

                "selection": {
                    "fields": {
                        "region": {
                            "type": "Region",
                            "keyRaw": "region",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5d35749fea3ded7e659ba66898c6f4f35d440113a1a11f88a214ce71e9ab1a8d";