import {
	graphql,
	CouponServicesStore,
	CouponQueryStore,
	DeactivateCouponStore,
	MakeCouponStore,
	type CouponQuery$input,
	type CouponQuery$result,
	type CouponServices$result,
	type MakeCoupon$input,
} from '$houdini'
import formatCurrency from './format/format-currency'
import { toDisplay } from './format/format-percent'

graphql`
	fragment CouponData on Coupon {
		id
		code
		description
		validFrom
		validTo
		numberOfTimesUsed
		maximumTimesUsed
		usesPerUser
		serviceId
		priceAdjustment
		isPercentage
		active
		firstJobOnly
		isValid
	}
`

const couponServicesQuery: CouponServicesStore = graphql`
	query CouponServices {
		services {
			data {
				id
				name
			}
		}
	}
`

const couponQuery: CouponQueryStore = graphql`
	query CouponQuery($filter: CouponFilter, $orderBy: [CouponOrderBy!]) {
		coupons(filter: $filter, orderBy: $orderBy) {
			...CouponData
		}
	}
`

const deactivateCouponMutation: DeactivateCouponStore = graphql`
	mutation DeactivateCoupon($couponId: NonNegativeInt!) {
		deactivateCoupon(couponId: $couponId) {
			...CouponData
		}
	}
`

const createCouponMutation: MakeCouponStore = graphql`
	mutation MakeCoupon($newCoupon: NewCoupon!) {
		newCoupon(newCoupon: $newCoupon) {
			...CouponData
		}
	}
`

export type CouponBase = CouponQuery$result['coupons'][number]

export type FormattedCoupon = CouponBase & {
	formattedValidFrom: string
	formattedValidTo: string | null
	priceAdjustment: string
	service: string
}

export function formatCouponResponse<T extends CouponBase>(coupons: T[], services: CouponServices$result['services']['data']): FormattedCoupon[] {
	return coupons.map(coupon => {
		const service = services.find(service => service.id === coupon.serviceId)?.name ?? 'All'

		return {
			...coupon,
			formattedValidFrom: new Intl.DateTimeFormat('en-US').format(new Date(coupon.validFrom)),
			formattedValidTo: coupon.validTo ? new Intl.DateTimeFormat('en-US').format(new Date(coupon.validTo)) : null,
			priceAdjustment: coupon.isPercentage ? `${toDisplay(coupon.priceAdjustment)}%` : `${formatCurrency(coupon.priceAdjustment)}`,
			service,
		}
	})
}

export async function loadCouponServices() {
	const { data } = await couponServicesQuery.fetch()

	if (!data?.services.data) {
		return []
	}

	return data.services.data
}

export async function loadCoupons(filter?: CouponQuery$input['filter']) {
	const { data } = await couponQuery.fetch({
		variables: {
			filter,
			orderBy: ['ID_DESC'],
		},
	})

	if (!data?.coupons) {
		return []
	}

	return data.coupons
}

export async function deactivateCoupon(couponId: number) {
	try {
		const { data } = await deactivateCouponMutation.mutate({
			couponId,
		})

		if (!data?.deactivateCoupon) {
			throw new Error('Failed to deactivate coupon')
		}

		return data.deactivateCoupon
	} catch (error) {
		console.error(error)
	}
}

export async function createCoupon(newCoupon: MakeCoupon$input['newCoupon']) {
	try {
		const { data } = await createCouponMutation.mutate({
			newCoupon,
		})

		if (!data?.newCoupon) {
			throw new Error('Failed to create coupon')
		}

		return data.newCoupon
	} catch (error) {
		console.error(error)
	}
}
