export default {
    "name": "TestPushNotification",
    "kind": "HoudiniMutation",
    "hash": "c1d6049ad231bb76b31e60c3770b124534bcce6703d3caccd94292193877db51",

    "raw": `mutation TestPushNotification {
  testPushNotification
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "testPushNotification": {
                "type": "Boolean",
                "keyRaw": "testPushNotification",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    }
};

"HoudiniHash=df649d104eaa6494cd53420b5f4e0b65b36a51ff83b8753bd6e636cfe95370c0";