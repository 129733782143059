<script lang="ts">
	import type { LoadProviderJobClaims$result } from '$houdini/index'

	import Button from '@isoftdata/svelte-button'

	import CompletedJobsList from './CompletedJobsList.svelte'
	import CompletedJobsImageViewerModal from './CompletedJobsImageViewerModal.svelte'
	import CompletedJobsStatCard from './CompletedJobsStatCard.svelte'

	import type { SvelteAsr } from 'types/common'
	import formatImageFileUrl from 'utility/format/format-image-file-url'

	export let asr: SvelteAsr
	export let jobClaims: LoadProviderJobClaims$result['jobClaims']['data']

	let selectedJobId: number | null = null
	let showingModal: boolean = false

	$: selectedJobClaim = jobClaims.find(jobClaim => jobClaim.job.id === selectedJobId)
	$: selectedJobClaimFiles = selectedJobClaim?.job.files?.map(metadata => formatImageFileUrl(metadata.file?.path ?? '')) ?? []
</script>

<div class="row m-3">
	<div class="col-12 p-0">
		<CompletedJobsStatCard {jobClaims} />
		<CompletedJobsList
			{jobClaims}
			bind:selectedJobId
		>
			{#if selectedJobClaim}
				{#if selectedJobClaim.job.answers && selectedJobClaim.job.answers.length > 0}
					<div class="mb-1">
						<span>Add-Ons</span>
						<div>
							{#each selectedJobClaim.job.answers as answer}
								<span class="badge badge-pill badge-primary mr-1">{answer.answer}</span>
							{/each}
						</div>
					</div>
					<hr class="w-100 m-0" />
				{/if}
				{#if selectedJobClaim.job.files && selectedJobClaim.job.files.length > 0}
					<div class="d-flex">
						<Button
							outline
							size="sm"
							style="width: 100%;"
							icon={{
								class: 'fa-solid',
								icon: 'image',
							}}
							on:click={() => (showingModal = true)}>View Job Photos</Button
						>
					</div>
				{:else}
					<div class="text-center">
						<span class="text-smaller">There are no photos uploaded for this job.</span>
					</div>
				{/if}
			{/if}
		</CompletedJobsList>
		{#if jobClaims.length < 1}
			<div class="text-center mt-4 w-100">
				<div class="h3">No jobs found</div>
				<p class="lead">
					Go claim and complete jobs here at the <a href={asr.makePath('app.provider.available-jobs')}>available jobs page</a>.
				</p>
			</div>
		{/if}
	</div>
</div>

<CompletedJobsImageViewerModal
	files={selectedJobClaimFiles}
	bind:show={showingModal}
/>
