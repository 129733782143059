import {
	graphql,
	LoadResidenceHelperAnswersStore,
	LoadQuestionsFromResidenceServiceHelperStore,
	LoadResidenceHelperServicesStore,
	LoadResidenceHelperTagsStore,
	ChangeAnswerToResidenceStore,
	ChangeResidenceSizeStore,
	type LoadQuestionsFromResidenceServiceHelper$input,
	type LoadResidenceHelperServices$input,
	type LoadResidenceHelperTags$input,
	type LoadResidenceHelperAnswers$result,
	type LoadResidenceHelperTags$result,
} from '$houdini'
import type { Residence } from 'utility/graphql/residences-query'

export type ResidenceTag = LoadResidenceHelperAnswers$result['answers']['data'][number]['tag']
export type HelperTag = LoadResidenceHelperTags$result['tags']['data'][number]
export type AnswerToTag = LoadResidenceHelperAnswers$result['answers']['data'][number]

graphql`
	fragment ResidenceData on Residence {
		id
		street
		city
		state
		zip
		regionId
		estimatedLawnSquareFootage
		effectiveLawnSquareFootage
		customer {
			userAccount {
				fullName
				email
			}
		}
		answers {
			id
			answer
		}
	}
`

const loadAnswersToTagsQuery: LoadResidenceHelperAnswersStore = graphql`
	query LoadResidenceHelperAnswers {
		answers {
			data {
				id
				answer
				tag {
					id
					code
				}
			}
		}
	}
`

const loadQuestionsQuery: LoadQuestionsFromResidenceServiceHelperStore = graphql`
	query LoadQuestionsFromResidenceServiceHelper($residenceId: PositiveInt!) {
		questionsFromResidenceService(residenceId: $residenceId) {
			serviceId
			questions {
				id
				question
				answers {
					id
					answer
					selected
				}
			}
		}
	}
`

const loadServicesQuery: LoadResidenceHelperServicesStore = graphql`
	query LoadResidenceHelperServices($filter: ServiceFilter) {
		services(filter: $filter) {
			data {
				id
				name
			}
		}
	}
`

const loadTagsQuery: LoadResidenceHelperTagsStore = graphql`
	query LoadResidenceHelperTags($filter: TagQueryFilter) {
		tags(filter: $filter) {
			data {
				id
				name
			}
		}
	}
`

const updateAnswerMutation: ChangeAnswerToResidenceStore = graphql`
	mutation ChangeAnswerToResidence($updateResidenceAnswers: UpdateResidenceAnswers!) {
		updateAnswerToResidence(updateResidenceAnswers: $updateResidenceAnswers) {
			...ResidenceData
		}
	}
`

const updateResidenceSizeMutation: ChangeResidenceSizeStore = graphql`
	mutation ChangeResidenceSize($residence: SetResidenceSize!) {
		setResidenceSize(residence: $residence) {
			...ResidenceData
		}
	}
`

export async function getAnswersToTags(): Promise<AnswerToTag[]> {
	const { data } = await loadAnswersToTagsQuery.fetch()

	if (!data) {
		return new Array<AnswerToTag>()
	}

	return data.answers.data
}

export async function getQuestionsFromResidenceServiceHelper(variables: LoadQuestionsFromResidenceServiceHelper$input) {
	const { data } = await loadQuestionsQuery.fetch({ variables })

	if (!data) {
		return []
	}

	return data.questionsFromResidenceService
}

export async function getServices(variables?: LoadResidenceHelperServices$input) {
	const { data } = await loadServicesQuery.fetch({ variables })

	if (!data) {
		return []
	}

	return data.services.data
}

export async function getTags(variables?: LoadResidenceHelperTags$input) {
	const { data } = await loadTagsQuery.fetch({ variables })

	if (!data) {
		return []
	}

	return data.tags.data
}

export async function updateAnswerToResidence(selectedAnswerIds: number[], residenceId: number): Promise<Residence> {
	const { data } = await updateAnswerMutation.mutate({
		updateResidenceAnswers: {
			answerIds: selectedAnswerIds,
			residenceId,
		},
	})

	if (!data) {
		throw new Error('No data returned')
	}

	return data.updateAnswerToResidence
}

export async function updateResidenceSize(residenceId: number, squareFootage: number | null): Promise<Residence> {
	const { data } = await updateResidenceSizeMutation.mutate({
		residence: {
			residenceId,
			estimatedLawnSquareFootage: squareFootage,
		},
	})

	if (!data) {
		throw new Error('No data returned')
	}

	return data.setResidenceSize
}

export type FormattedResidence = Residence & {
	answers: Residence['answers']
	customerName: string | null
	customerEmail: string
	formattedResidence: string
	squareFootage: number
	formattedSquareFootage: string
	residenceTags: ResidenceTag[]
}

export function formatResidenceResult(residence: Residence, answerTagMap: LoadResidenceHelperAnswers$result['answers']['data']): FormattedResidence {
	return {
		...residence,
		answers: residence.answers,
		customerName: residence.customer.userAccount.fullName,
		customerEmail: residence.customer.userAccount.email,
		formattedResidence: `${residence.street}\n${residence.city}`,
		squareFootage: residence?.effectiveLawnSquareFootage ?? residence?.estimatedLawnSquareFootage,
		formattedSquareFootage: new Intl.NumberFormat('en-US').format(residence?.effectiveLawnSquareFootage ?? residence?.estimatedLawnSquareFootage),
		residenceTags: getTagsFromAnswers(residence.answers, answerTagMap),
	}
}

function getTagsFromAnswers(answers: Residence['answers'], answerTagMap: LoadResidenceHelperAnswers$result['answers']['data']) {
	if (!answers) {
		return []
	}
	return answers.reduce((acc, answer) => {
		const tag = answerTagMap.find(answerTagMap => answerTagMap.id === answer.id)?.tag
		if (tag) {
			acc.push({
				id: tag.id,
				code: tag.code,
			})
		}
		return acc
	}, new Array<{ id: number; code: string }>())
}
