<script lang="ts">
	import Table, { Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'

	import type { Answer } from 'utility/question-helper'

	import { createEventDispatcher } from 'svelte'

	//The below format is to fix the tables auto typing because edit is not a prop on Answer by default but the table needs all Td's to be props so this makes the type "Ok" with it
	export let answers: Array<Answer & { edit?: unknown }> = []

	const dispatch = createEventDispatcher()

	const openEditAnswerModal = (answer: Answer) => {
		dispatch('openEditAnswerModal', answer)
	}

	const deleteAnswer = (answer: Answer) => {
		dispatch('deleteAnswer', answer)
	}
</script>

<Table
	responsive
	tableClass="mb-0"
	rows={answers}
	columns={[
		{ property: 'answer', name: 'Answer' },
		{ property: 'tag[name]', name: 'Tag' },
		{ property: 'edit', name: '', sortType: false, align: 'right' },
	]}
	filterEnabled={false}
	let:row
>
	<svelte:fragment slot="no-rows">
		<tr>
			<td
				colspan="3"
				class="text-center">No Answers Found</td
			>
		</tr>
	</svelte:fragment>
	<tr>
		<Td property="answer">{row.answer}</Td>
		<Td property="tag[name]">{row.tag.name}</Td>
		<Td property="edit">
			<Button
				size="sm"
				color="primary"
				outline
				iconClass="pen"
				on:click={() => {
					openEditAnswerModal(row)
				}}
			>
				Edit Answer
			</Button>
			<Button
				size="sm"
				color="danger"
				outline
				iconClass="trash"
				on:click={() => {
					deleteAnswer(row)
				}}
			>
				Delete Answer
			</Button>
		</Td>
	</tr>
</Table>
