import { getSession } from 'stores/session'
import component from './AvailableJobs.svelte'

import type { AppContext } from 'types/common'
import { loadAvailableJobsData, type JobsWithmarkers } from 'utility/available-jobs-helper'
import type { LoadProviderAvailableServices$result, LoadProviderUserRegions$result } from '$houdini/index'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.provider.available-jobs',
		route: 'available-jobs',
		// The only external parameter we really need are date for an email, the rest are for filtering so I can make them Ids
		querystringParameters: ['date'],
		defaultParameters: {
			date: new Date().toLocaleDateString(),
		},
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, _parameters) {
			const session = getSession()
			const provider = session.provider
			const userAccountId = session.userAccountId

			if (!provider || !userAccountId) {
				return Promise.reject({
					redirectTo: {
						name: 'login',
					},
				})
			}

			const initialLoad = await loadAvailableJobsData(provider.status, provider.id, userAccountId)

			const jobsWithMarkers: JobsWithmarkers[] = initialLoad.jobs
			const providerServices: Exclude<LoadProviderAvailableServices$result['provider']['authorizedServices'], null>[number]['service'][] = initialLoad.serviceFilterList
			const providerRegionsList: Exclude<LoadProviderUserRegions$result['getUserRegions'], null>[number]['region'][] = initialLoad.providerRegionsList
			const filterByServiceIds: number[] = initialLoad.selectedServiceIds
			const filterByRegionIds: number[] = initialLoad.selectedRegionIds

			return Promise.resolve({
				session,
				providerStatus: provider.status,
				providerRegionsList,
				serviceFilterList: providerServices,
				selectedServiceIds: filterByServiceIds,
				selectedRegionIds: filterByRegionIds,
				jobs: jobsWithMarkers,
				jobPlaces: initialLoad.jobPlaces,
			})
		},
	})
}
