export default {
    "name": "Session",
    "kind": "HoudiniQuery",
    "hash": "2a783ab33c28c710e9b82e9b5b1b59253a5ce7912cd85efa251e2d65a269ca3e",

    "raw": `query Session {
  session {
    sessionToken: token
    email: userName
    userAccountId
    user {
      recoveryEmail
      firstName
      fullName
      lastName
      customer {
        id
        billingCountry
        billingZip
        billingState
        billingCity
        billingStreet
        ownedResidences {
          data {
            id
            street
            city
            state
            zip
          }
        }
      }
      provider {
        id
        status
        profilePictureFile {
          path
          id
        }
        latestOnboardingApplication {
          id
          status
        }
      }
      userRoles {
        permissionName
        value
      }
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "sessionToken": {
                            "type": "UUID",
                            "keyRaw": "sessionToken",
                            "nullable": true,
                            "visible": true
                        },

                        "email": {
                            "type": "String",
                            "keyRaw": "email",
                            "nullable": true,
                            "visible": true
                        },

                        "userAccountId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "userAccountId",
                            "nullable": true,
                            "visible": true
                        },

                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "recoveryEmail": {
                                        "type": "EmailAddress",
                                        "keyRaw": "recoveryEmail",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "visible": true
                                    },

                                    "fullName": {
                                        "type": "String",
                                        "keyRaw": "fullName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "visible": true
                                    },

                                    "customer": {
                                        "type": "Customer",
                                        "keyRaw": "customer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "billingCountry": {
                                                    "type": "String",
                                                    "keyRaw": "billingCountry",
                                                    "visible": true
                                                },

                                                "billingZip": {
                                                    "type": "String",
                                                    "keyRaw": "billingZip",
                                                    "visible": true
                                                },

                                                "billingState": {
                                                    "type": "String",
                                                    "keyRaw": "billingState",
                                                    "visible": true
                                                },

                                                "billingCity": {
                                                    "type": "String",
                                                    "keyRaw": "billingCity",
                                                    "visible": true
                                                },

                                                "billingStreet": {
                                                    "type": "String",
                                                    "keyRaw": "billingStreet",
                                                    "visible": true
                                                },

                                                "ownedResidences": {
                                                    "type": "ResidenceResponse",
                                                    "keyRaw": "ownedResidences",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "data": {
                                                                "type": "Residence",
                                                                "keyRaw": "data",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "street": {
                                                                            "type": "String",
                                                                            "keyRaw": "street",
                                                                            "visible": true
                                                                        },

                                                                        "city": {
                                                                            "type": "String",
                                                                            "keyRaw": "city",
                                                                            "visible": true
                                                                        },

                                                                        "state": {
                                                                            "type": "String",
                                                                            "keyRaw": "state",
                                                                            "visible": true
                                                                        },

                                                                        "zip": {
                                                                            "type": "String",
                                                                            "keyRaw": "zip",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "provider": {
                                        "type": "Provider",
                                        "keyRaw": "provider",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "profilePictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "profilePictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "latestOnboardingApplication": {
                                                    "type": "ProviderApplication",
                                                    "keyRaw": "latestOnboardingApplication",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "status": {
                                                                "type": "ProviderApplicationStatus",
                                                                "keyRaw": "status",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "userRoles": {
                                        "type": "UserRole",
                                        "keyRaw": "userRoles",

                                        "selection": {
                                            "fields": {
                                                "permissionName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "permissionName",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7d6536594a2d1d006ffc2766e024fe7f845c76a4a236d924fd0c574b41a8fe26";