import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/getQuoteServices'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class getQuoteServicesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "getQuoteServicesStore",
			variables: false,
		})
	}
}

export async function load_getQuoteServices(params) {
	await initClient()

	const store = new getQuoteServicesStore()

	await store.fetch(params)

	return {
		getQuoteServices: store,
	}
}
