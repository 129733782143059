<script lang="ts">
	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'

	import type { AdminTag } from 'utility/tag-helper'
	import { formatCurrency } from 'utility/format/format-currency'
	import { toDisplay } from 'utility/format/format-percent'

	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	export let tag: AdminTag

	const tagPricingTableColumns: Column[] = [
		{ property: 'serviceName', name: 'Service' },
		{ property: 'priceAdjustment', name: 'Price Adjustment' },
		{ property: 'edit', name: '', sortType: false },
		{ property: 'remove', name: '', sortType: false },
	]
</script>

{#if tag && tag.tagPricings && tag.tagPricings?.length > 0}
	<Table
		filterEnabled={false}
		class="mb-0"
		rows={tag.tagPricings}
		columns={tagPricingTableColumns}
		let:row
	>
		<tr>
			<Td property="serviceName">{row.service.name}</Td>
			<Td property="priceAdjustment">{row?.pricingType === 'PERCENT' ? `${toDisplay(row.priceAdjustment)}%` : formatCurrency(row.priceAdjustment)}</Td>
			<Td property="edit">
				<Button
					id="edit-tag-pricing-button"
					title="Edit Tag Pricing"
					outline
					size="sm"
					color="primary"
					iconClass="pen"
					on:click={() => {
						dispatch('editTagPricing', row)
					}}
				></Button>
			</Td>
			<Td property="remove">
				<Button
					id="delete-tag-pricing-button"
					title="Delete Tag Pricing"
					outline
					size="sm"
					color="danger"
					iconClass="trash"
					on:click={() => {
						dispatch('deleteTagPricing', { ...row, tagId: tag.id })
					}}
				></Button>
			</Td>
		</tr>
	</Table>
{:else}
	<p>No tag pricings found.</p>
{/if}
