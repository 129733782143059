export default {
    "name": "CreateZone",
    "kind": "HoudiniMutation",
    "hash": "b67b3fbc912f965eb29ca768564e722b7e0f573bce31d7abc162372bffee7709",

    "raw": `mutation CreateZone($newZone: NewZone!, $regionIds: [PositiveInt!]) {
  newZone(newZone: $newZone, regionIds: $regionIds) {
    ...ZoneData
    id
  }
}

fragment ZoneData on Zone {
  id
  name
  state
  description
  regions {
    id
    name
    description
    latitude
    longitude
    timeZone
    status
    zoneId
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newZone": {
                "type": "Zone",
                "keyRaw": "newZone(newZone: $newZone, regionIds: $regionIds)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "regions": {
                            "type": "Region",
                            "keyRaw": "regions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "latitude": {
                                        "type": "Latitude",
                                        "keyRaw": "latitude",
                                        "visible": true
                                    },

                                    "longitude": {
                                        "type": "Longitude",
                                        "keyRaw": "longitude",
                                        "visible": true
                                    },

                                    "timeZone": {
                                        "type": "TimeZone",
                                        "keyRaw": "timeZone",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "zoneId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "zoneId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ZoneData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newZone": "NewZone",
            "regionIds": "PositiveInt"
        },

        "types": {
            "NewZone": {
                "description": "String",
                "name": "NonEmptyString",
                "state": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1fa340360428e3bd919597b48d09c955aa5c72447949ad4b7a78556b293e577d";