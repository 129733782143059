export default {
    "name": "AddCardTocustomerWallet",
    "kind": "HoudiniMutation",
    "hash": "ce6fcdef4ff460136da4be86784dab60018cdf15af2ece5ac550fa105e0b2c02",

    "raw": `mutation AddCardTocustomerWallet($newCard: NewCustomerCard!) {
  addCardToCustomerWallet(newCard: $newCard) {
    id
    lastFour
    expirationMonth
    expirationYear
    cardType
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "addCardToCustomerWallet": {
                "type": "CustomerWallet",
                "keyRaw": "addCardToCustomerWallet(newCard: $newCard)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "lastFour": {
                            "type": "NonNegativeInt",
                            "keyRaw": "lastFour",
                            "visible": true
                        },

                        "expirationMonth": {
                            "type": "PositiveInt",
                            "keyRaw": "expirationMonth",
                            "visible": true
                        },

                        "expirationYear": {
                            "type": "PositiveInt",
                            "keyRaw": "expirationYear",
                            "visible": true
                        },

                        "cardType": {
                            "type": "NonEmptyString",
                            "keyRaw": "cardType",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newCard": "NewCustomerCard"
        },

        "types": {
            "NewCustomerCard": {
                "cardType": "NonEmptyString",
                "expirationMonth": "PositiveInt",
                "expirationYear": "PositiveInt",
                "lastFour": "NonNegativeInt",
                "name": "String",
                "token": "NonEmptyString"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=2a2871bbc3fc3ab1173749c05d26b5cf5da0220a27ed724f2fd0077fe55cb76d";