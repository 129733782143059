export default {
    "name": "UserProfileProviderDOBUpdateMutation",
    "kind": "HoudiniMutation",
    "hash": "a9be9a7ef4a0acac31e52eb65923d336b44f0fd1645a9d35b4af62687e571cfd",

    "raw": `mutation UserProfileProviderDOBUpdateMutation($dateOfBirth: DateTime!, $providerId: PositiveInt!) {
  updateProviderDateOfBirth(dateOfBirth: $dateOfBirth, providerId: $providerId) {
    id
    dateOfBirth
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderDateOfBirth": {
                "type": "Provider",
                "keyRaw": "updateProviderDateOfBirth(dateOfBirth: $dateOfBirth, providerId: $providerId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "dateOfBirth": {
                            "type": "Date",
                            "keyRaw": "dateOfBirth",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "dateOfBirth": "DateTime",
            "providerId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=bc499695bb4113ec188240bf758cb9240d321dd465cc6ab476503e89654e5334";