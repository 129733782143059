<script lang="ts">
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import GetQuoteCardHeader from './GetQuoteCardHeader.svelte'
	import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete.svelte'
	import GooglePlacesMap from '../GooglePlacesMap.svelte'
	import validateEmail from 'utility/email-address-validate'
	import { getQuotePrice, type GetQuoteGoogleAddress, type GetQuoteStore } from 'utility/get-quote-helper'
	import type { Readable, Writable } from 'svelte/store'

	export let quoteData: Writable<GetQuoteStore>
	export let derivedSelectedAddress: Readable<GetQuoteGoogleAddress | undefined>
	export let stepOpen: boolean
	export let disabled: boolean

	$: emailAddressIsValid = validateEmail($quoteData.emailAddress)

	$: header = $derivedSelectedAddress && stepFinished && !stepOpen ? $derivedSelectedAddress.street : 'Enter Address'

	$: if ($derivedSelectedAddress) {
		localStorage.setItem('newAddress', JSON.stringify($derivedSelectedAddress))
	} else {
		localStorage.removeItem('newAddress')
	}

	let stepFinished = false

	async function confirmAddress(e: Event) {
		e.preventDefault()
		if ($quoteData.questionsAvailable) {
			// If there are questions available, we need to answer them before we can get a price
			// So no point in continuing
			// If there are no questions available, we need to get the price
			stepFinished = true
			return
		}
		$quoteData.isLoadingPrice = true
		try {
			const findParcelResultData = await getQuotePrice($quoteData, $derivedSelectedAddress)
			if (!findParcelResultData) {
				throw new Error('No data returned')
			}

			$quoteData.inServiceArea = true
			$quoteData.quotedPrice = findParcelResultData.priceQuote.price
			$quoteData.priceError = findParcelResultData.priceQuote.error
		} catch (error) {
			console.error(error)
			$quoteData.inServiceArea = false
			$quoteData.quotedPrice = ''
			$quoteData.priceError = 'There was an error getting the price for this address. Please try again later.'
		}
		$quoteData.googleMapsPlaceId = $derivedSelectedAddress?.googleMapsPlaceId || ''
		$quoteData.isLoadingPrice = false
		stepFinished = true
	}
</script>

<CollapsibleCard
	entireHeaderToggles
	{disabled}
	cardHeaderClass="card-header d-flex justify-content-between h4"
	cardStyle="border-radius: 0px; border-bottom: none;"
	bind:bodyShown={stepOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<GetQuoteCardHeader
			{header}
			{stepFinished}
		/>
	</svelte:fragment>
	<div class="row">
		<div class="col-12 col-md-6 col-lg-4 col-xl-3">
			<div
				style="height: 100%;"
				class="d-flex flex-column"
			>
				<GooglePlacesAutocomplete
					bind:places={$quoteData.places}
					bind:bounds={$quoteData.bounds}
					pacInputValue={$derivedSelectedAddress?.street || ''}
				/>
				<form
					on:submit={e => {
						confirmAddress(e)
					}}
					on:submit
				>
					<Input
						id="quoteEmailAddressInput"
						showLabel
						label="Email"
						type="email"
						placeholder="janedoe@example.com"
						inputmode="email"
						bind:value={$quoteData.emailAddress}
						labelParentClass="mt-3"
					/>
					{#if $quoteData.emailAddress && !emailAddressIsValid}
						<small class="text-danger"> Please enter a valid email address </small>
					{:else}
						<small class="text-muted">We'll send your quote to this email</small>
					{/if}
					<div class="mt-3">
						<Button
							id="selectOptionsBtn"
							size="sm"
							color="primary"
							class="mb-2"
							iconClass="check"
							type="submit"
							disabled={!emailAddressIsValid || !$derivedSelectedAddress}
						>
							{#if $quoteData.questionsAvailable}
								Answer Questions
							{:else}
								See My Price
							{/if}
						</Button>
					</div>
				</form>
			</div>
		</div>
		<div class="col-12 col-md-6 col-lg-8 col-xl-9">
			<GooglePlacesMap
				places={$quoteData.places}
				bounds={$quoteData.bounds}
				mapStyle="height: 30vh; min-height: 200px;"
			/>
		</div>
	</div>
</CollapsibleCard>
