<script lang="ts">
	import { createEventDispatcher } from 'svelte'
	import { formatISO } from 'date-fns'
	import type { JobStatus$options } from '$houdini/graphql'

	import formatRelativeDateWeek from 'utility/format/format-relative-date-week'

	import CompletedJobsCard from './CompletedJobsCard.svelte'

	interface ProviderCompletedJobsListProp {
		id: number
		completed: Date | null
		jobStatus: JobStatus$options
		transaction: {
			cost: string
			tip: string | null
		} | null
		service: {
			name: string
		}
		answers:
			| {
					id: number
			  }[]
			| null
		residence: {
			street: string
			region: {
				name: string
			}
		}
		customerFirstJob: boolean
		files:
			| {
					id: number
			  }[]
			| null
	}

	export let jobClaims: { job: ProviderCompletedJobsListProp }[] = []
	export let selectedJobId: number | null = null

	function jobsByDate<T extends { job: ProviderCompletedJobsListProp }>(jobClaims: T[]): Map<string, { formattedDate: string; jobClaims: T[] }> {
		const jobsByDate = new Map<string, { formattedDate: string; jobClaims: T[] }>()

		jobClaims.forEach(jobClaim => {
			let date = jobClaim.job.completed

			if (!date) {
				if (!jobsByDate.has('No Date')) {
					jobsByDate.set('No Date', { formattedDate: 'No Date', jobClaims: [] })
				} else {
					jobsByDate.get('No Date')?.jobClaims.push(jobClaim)
				}
				return
			}

			const formattedDate = formatRelativeDateWeek(date)
			const key = formatISO(date, { representation: 'date' })

			if (!jobsByDate.has(key)) {
				jobsByDate.set(key, { formattedDate, jobClaims: [] })
			}

			jobsByDate.get(key)?.jobClaims.push(jobClaim)
		})

		return jobsByDate
	}

	async function handleJobClicked(e: CustomEvent<number>) {
		if (e.detail === selectedJobId) {
			selectedJobId = null
		} else {
			selectedJobId = e.detail
		}
	}
</script>

{#each jobsByDate(jobClaims) as [key, jobsFormattedDate] (key)}
	<label
		for="jobList"
		class="mb-0 mt-2"
	>
		<h4 class="mb-0">{jobsFormattedDate.formattedDate}</h4>
	</label>
	<div
		id="jobList"
		class="list-group flex-md-row flex-md-wrap"
	>
		<div class="form-row w-100">
			{#each jobsFormattedDate.jobClaims as jobClaim}
				<div
					class="col-12 col-md-6 col-lg-4 col-xl-3 my-1"
					style="border-radius: 0.4rem;"
				>
					<CompletedJobsCard
						job={jobClaim.job}
						{selectedJobId}
						on:jobClicked={handleJobClicked}
					/>
					{#if jobClaim.job.id === selectedJobId}
						<div class="list-group-item border border-primary">
							<slot />
						</div>
					{/if}
				</div>
			{/each}
		</div>
	</div>
{/each}
