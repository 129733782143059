export default {
    "name": "ChangeResidenceSize",
    "kind": "HoudiniMutation",
    "hash": "6fed82c60a340e30e0b35af6a8c2c3701379a3b9a18d7a4b5cec8abcdb8d6d72",

    "raw": `mutation ChangeResidenceSize($residence: SetResidenceSize!) {
  setResidenceSize(residence: $residence) {
    ...ResidenceData
    id
  }
}

fragment ResidenceData on Residence {
  id
  street
  city
  state
  zip
  regionId
  estimatedLawnSquareFootage
  effectiveLawnSquareFootage
  customer {
    userAccount {
      fullName
      email
      id
    }
    id
  }
  answers {
    id
    answer
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setResidenceSize": {
                "type": "Residence",
                "keyRaw": "setResidenceSize(residence: $residence)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "estimatedLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "estimatedLawnSquareFootage",
                            "visible": true
                        },

                        "effectiveLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "effectiveLawnSquareFootage",
                            "nullable": true,
                            "visible": true
                        },

                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",

                            "selection": {
                                "fields": {
                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "email": {
                                                    "type": "EmailAddress",
                                                    "keyRaw": "email",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ResidenceData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "residence": "SetResidenceSize"
        },

        "types": {
            "SetResidenceSize": {
                "estimatedLawnSquareFootage": "PositiveInt",
                "residenceId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=958ff6268ad72dc7a84457ea628021fa4d1d72030cb95dd9be3dc2958e7092a1";