<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import formatCityStateZip from 'utility/format/format-city-state-zip'
	import formatDateString from 'utility/format/format-date-string'
	import formatCurrency from 'utility/format/format-currency'
	import type { SvelteAsr } from 'types/common'
	import type { OrderCreatedJob } from './order-created'

	export let asr: SvelteAsr

	export let job: OrderCreatedJob | null = null
</script>

<div class="card border-0">
	<div class="card-header d-flex justify-content-between rounded-0 align-items-end">
		<h3 class="mb-0">Order Received</h3>
	</div>
	<div class="card-body">
		<div class="form-row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
			<div class="col">
				<h1
					id="order-received"
					class="font-handwriting text-center"
				>
					Thank You!
				</h1>
				<p>Check your email for order details and next steps.</p>
				<ujl class="list-group mt-2">
					{#if !!job}
						<li class="list-group-item">
							<span class="font-weight-bold d-block">Service</span>
							{job.service.name}
						</li>
						{#if job.answers && job.answers.length > 0}
							<li class="list-group-item">
								<span class="font-weight-bold d-block">Add-ons</span>
								<ul>
									{#each job.answers as answer}
										<li>{answer.answer}</li>
									{/each}
								</ul>
							</li>
						{/if}
						<li class="list-group-item">
							<span class="font-weight-bold d-block">Address</span>
							{job.residence.street}, <br />
							{formatCityStateZip(job.residence)}
						</li>
						<li class="list-group-item">
							<span class="font-weight-bold d-block">Scheduled For</span>
							{formatDateString(job.requestedSchedule.toISOString())}
						</li>
						<li class="list-group-item">
							<span class="font-weight-bold d-block">Total</span>
							{formatCurrency(job.transaction?.total)}
						</li>
						{#if job.specialInstructions}
							<li class="list-group-item">
								<span class="font-weight-bold d-block">Special Instructions</span>
								{job.specialInstructions}
							</li>
						{/if}
					{/if}
				</ujl>
			</div>
		</div>
	</div>
	<div class="card-footer d-flex flex-row-reverse">
		<Button
			href={asr.makePath('app.customer.upcoming-orders.detail', { jobId: job?.id })}
			iconClass="check"
		>
			Done
		</Button>
	</div>
</div>
