export default {
    "name": "SetUserRegionPermission",
    "kind": "HoudiniMutation",
    "hash": "afef17114ebe713a73c3877c1d68576050ef0d5fa4858196ccdfb6e6b9a6878b",

    "raw": `mutation SetUserRegionPermission($userRegion: EditUserPermissions!) {
  setUserRegionPermission(userRegion: $userRegion)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setUserRegionPermission": {
                "type": "Boolean",
                "keyRaw": "setUserRegionPermission(userRegion: $userRegion)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userRegion": "EditUserPermissions"
        },

        "types": {
            "EditUserPermissions": {
                "active": "Boolean",
                "regionId": "PositiveInt",
                "userAccountId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=32154fc3581657a86c914ed1ac5b816c13efef66f05e1574966edffcf86801a8";