<script lang="ts">
	import Table, { Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import { updateServiceOfferStatus, loadRegionHelperSpecificServiceOffer, type RegionHelperRegionService } from 'utility/region-helper'
	import type { Mediator } from 'types/common'
	import ServiceOfferModal from './ServiceOfferModal.svelte'
	import formatCurrency from 'utility/format/format-currency'

	import { createEventDispatcher, getContext } from 'svelte'
	import type { LoadRegionHelperServices$result } from '$houdini'

	const dispatch = createEventDispatcher()

	const mediator = getContext<Mediator>('mediator')

	export let services: LoadRegionHelperServices$result['services']['data']
	export let regionServices: Array<RegionHelperRegionService & { edit?: unknown }>
	export let selectedRegionId: number | undefined
	export let serviceOfferShow: boolean
	export let editingServiceOffer: boolean
	export let serviceOfferId: number | undefined
	export let chargePerSquareFoot: number | null
	export let chargeMinimum: number | null
	export let chargeFixed: number | null
	export let timeRange: string
	export let serviceId: number | undefined
	export let regionId: number | undefined
	export let serviceOfferErrorMessage: string

	async function openEditServiceModal(id: number): Promise<void> {
		try {
			const serviceOffer = await loadRegionHelperSpecificServiceOffer(id)
			if (serviceOffer) {
				serviceOfferShow = true
				editingServiceOffer = true
				serviceOfferId = serviceOffer.id
				chargePerSquareFoot = parseFloat(serviceOffer.chargePerSquareFoot)
				chargeMinimum = parseFloat(serviceOffer.chargeMinimum)
				chargeFixed = serviceOffer.chargeFixed ? parseFloat(serviceOffer.chargeFixed) : null
				timeRange = serviceOffer.timeRange === 'TwentyFourHour' ? 'TwentyFourHour' : 'SunriseSunset'
				serviceId = serviceOffer.service.id
				regionId = selectedRegionId
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { title: 'Error loading service offer', message: error.message })
			console.error(error)
		}
	}

	async function setServiceOfferStatus(id: number, active: boolean) {
		try {
			const updatedServiceOffer = await updateServiceOfferStatus(id, active)

			if (updatedServiceOffer) {
				dispatch('serviceOfferSaved', updatedServiceOffer)
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { title: 'Error updating service offer status', message: error.message })
			console.error(error)
		}
	}
</script>

<Table
	responsive
	class="mb-0"
	columns={[
		{ property: 'service[name]', name: 'Service', sortType: 'ALPHA_NUM' },
		{ property: 'chargeMinimum', name: 'Minimum Charge', numeric: true },
		{ property: 'chargePerSquareFoot', name: 'Charge per Square Foot', numeric: true },
		{ property: 'chargeFixed', name: 'Fixed charge', numeric: true },
		{ property: 'edit', name: 'Edit', align: 'center' },
		{ property: 'active', name: 'Active', align: 'center' },
	]}
	rows={regionServices}
	filterEnabled={false}
	let:row
>
	<svelte:fragment slot="no-rows">
		<tr>
			<td colspan="6">
				<span>No services found</span>
			</td>
		</tr>
	</svelte:fragment>
	<tr>
		<Td property="service[name]">{row.service.name}</Td>
		<Td property="chargeMinimum">{row.chargeMinimum ? formatCurrency(row.chargeMinimum) : ''}</Td>
		<Td property="chargePerSquareFoot">{row.chargePerSquareFoot ? `$${row.chargePerSquareFoot}` : ''}</Td>
		<Td property="chargeFixed">{row.chargeFixed ? formatCurrency(row.chargeFixed) : ''}</Td>
		<Td property="edit">
			<Button
				id="edit-service-offer-button"
				title="Edit"
				iconClass="pencil"
				size="xs"
				outline
				color="primary"
				on:click={() => openEditServiceModal(row.id)}
			/>
		</Td>
		<Td property="active">
			<Checkbox
				inline
				id="active-service-offer-checkbox"
				title="De/Activate service offer"
				checked={row.active}
				radioButtonColor={row.active ? 'primary' : 'danger'}
				trueIcon="check"
				falseIcon="times"
				trueLabel=""
				falseLabel=""
				type="radio"
				on:change={() => setServiceOfferStatus(row.id, !row.active)}
			/>
		</Td>
	</tr>
</Table>

<ServiceOfferModal
	bind:show={serviceOfferShow}
	bind:editingService={editingServiceOffer}
	bind:serviceId
	bind:serviceOfferId
	bind:chargePerSquareFoot
	bind:chargeMinimum
	bind:chargeFixed
	bind:timeRange
	bind:regionId
	bind:serviceOfferErrorMessage
	{services}
	on:serviceOfferSaved={() => dispatch('serviceOfferSaved')}
/>
