<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'
	export let showModal = false

	let selectedOs: 'ios' | 'android' = getDefaultOs()

	function getDefaultOs() {
		const userAgent = navigator.userAgent

		if (/android/i.test(userAgent)) {
			return 'android'
		}

		return 'ios'
	}

	export function show() {
		showModal = true
		selectedStepIndex = 0
	}

	function selectOs(os: 'ios' | 'android') {
		selectedOs = os
		selectedStepIndex = 0
	}

	const iosSteps = [
		{
			image: 'images/install-ios-1.jpg',
			text: 'Open Safari and navigate to app.lawnhiro.com. Then, tap the share button at the bottom of the screen.',
			alt: 'A picture of the LawnHiro login screen, shown on an iPhone.',
		},
		{
			image: 'images/install-ios-2.jpg',
			text: 'Next, scroll down until you see the "Add to Home Screen" option, and tap it.',
			alt: 'A picture of the share menu, shown on an iPhone.',
		},
		{
			image: 'images/install-ios-3.jpg',
			text: 'Finally, tap the "Add" button in the top right corner of the screen. Finally, you should see the LawnHiro app icon on your home screen.',
			alt: 'A picture of the "Add to Home Screen" menu, shown on an iPhone.',
		},
	]

	const androidSteps = [
		{
			image: 'images/install-android-1.jpg',
			text: 'Open Chrome and navigate to app.lawnhiro.com. Then, tap the menu button in the top right corner of the screen.',
			alt: 'A picture of the LawnHiro login screen, shown on an Android device.',
		},
		{
			image: 'images/install-android-2.jpg',
			text: 'Next, tap the "Add to Home screen" option.',
			alt: 'A picture of the Chrome menu, shown on an Android device.',
		},
		{
			image: 'images/install-android-3.jpg',
			text: 'Then, tap the "Add" button in the popup that appears.',
			alt: 'A picture of the "Add to Home screen" menu, shown on an Android device.',
		},
		{
			image: 'images/install-android-4.jpg',
			text: 'Then, either touch & hold the widget to manually place the icon on your home screen, or tap "Add to home screen" to automatically place it on your home screen. Finally, you should see the LawnHiro app icon on your home screen.',
			alt: 'A picture of the "Add to Home screen" menu, shown on an Android device.',
		},
	]

	let selectedStepIndex = 0
	$: selectedStep = selectedOs === 'ios' ? iosSteps[selectedStepIndex] : androidSteps[selectedStepIndex]
	$: maxStepIndex = selectedOs === 'ios' ? iosSteps.length - 1 : androidSteps.length - 1
</script>

<Modal
	title="Instructions"
	subtitle="How to add LawnHiro to your home screen"
	bind:show={showModal}
	on:close={() => (showModal = false)}
	on:confirm={() => {
		showModal = false
	}}
	footerShown={false}
>
	<div
		class="btn-group btn-group-toggle w-100"
		data-toggle="buttons"
	>
		<label
			class="btn btn-primary w-50"
			class:active={selectedOs === 'ios'}
		>
			<input
				type="radio"
				name="options"
				id="option1"
				on:click={() => selectOs('ios')}
			/>
			<Icon
				prefix="fab"
				icon="apple"
			></Icon> iOS
		</label>
		<label
			class="btn btn-primary w-50"
			class:active={selectedOs === 'android'}
		>
			<input
				type="radio"
				name="options"
				id="option2"
				on:click={() => selectOs('android')}
			/>
			<Icon
				prefix="fab"
				icon="android"
			></Icon> Android
		</label>
	</div>

	<div
		class="d-flex justify-content-center"
		style="height: 50vh;"
	>
		<img
			class="img-fluid img-thumbnail mt-3 mb-3"
			src={selectedStep.image}
			alt={selectedStep.alt}
		/>
	</div>

	<p class="text-center">{selectedStep.text}</p>

	<div class="btn-group w-100 mb-1">
		<Button
			outline
			size="sm"
			iconClass="chevron-left"
			class="w-50"
			colorGreyDisabled={false}
			disabled={selectedStepIndex <= 0}
			on:click={() => (selectedStepIndex -= 1)}
		>
			Previous
		</Button>
		<Button
			outline
			size="sm"
			class="w-50"
			iconClass="chevron-right"
			colorGreyDisabled={false}
			disabled={selectedStepIndex >= maxStepIndex}
			on:click={() => (selectedStepIndex += 1)}
		>
			Next
		</Button>
	</div>

	<small>
		<p class="text-center">Instructions are based on iOS version 17 and Android version 14, and may vary if you are on a different version.</p>
	</small>
</Modal>
