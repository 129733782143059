<script lang="ts">
	import { getContext, onDestroy, type ComponentProps } from 'svelte'
	import type { Writable } from 'svelte/store'

	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

	import type { SvelteAsr, Mediator } from 'types/common'
	import type { ProviderMediatorProviders } from './provider'

	import { getAvailableJobCount, getProviderPassedScheduledJobClaimCount } from 'utility/graphql/get-provider-alert-counts'
	import minToMs from 'utility/min-to-ms'

	const mediator = getContext<Mediator<ProviderMediatorProviders>>('mediator')
	export let asr: SvelteAsr

	export let childStates: ComponentProps<AsrNavTabBar>['tabs']
	export let availableJobsStore: Writable<number>
	export let claimedJobsStore: Writable<number>
	export let providerId: number
	// export let providerServiceIds: Array<number>
	// export let providerRegionIds: Array<number>

	// NOTE: Jake requested to remove the alert icon for available jobs, so we're not using this anymore
	// but I'm keeping it here in case we need to use it again in the future

	// let providerAvailableJobsIntervalId = setInterval(async () => {
	// 	let newCount = 0
	// 	try {
	// 		newCount = await getAvailableJobCount(providerServiceIds, providerRegionIds)
	// 	} catch (e) {
	// 		console.error(e)
	// 	}
	// 	if (newCount !== $availableJobsStore) {
	// 		$availableJobsStore = newCount
	// 	}
	// }, minToMs(5))

	let providerPassedScheduledJobClaimCountIntervalId = setInterval(async () => {
		let newCount = 0
		try {
			newCount = await getProviderPassedScheduledJobClaimCount({ providerId, statuses: ['IN_PROGRESS', 'ACCEPTED'] })
		} catch (e) {
			console.error(e)
		}
		if (newCount !== $claimedJobsStore) {
			$claimedJobsStore = newCount
		}
	}, minToMs(5))

	// const availableJobsCountUnsub = availableJobsStore.subscribe(count => {
	// 	const index = childStates.findIndex(state => state.name === 'app.provider.available-jobs')
	// 	const providerState = childStates[index]
	// 	if ($availableJobsStore > 0 && providerState) {
	// 		providerState.icon = {
	// 			class: 'text-danger',
	// 			icon: 'bell-exclamation',
	// 		}
	// 	} else if (providerState) {
	// 		providerState.icon = undefined
	// 	}
	// 	childStates[index] = providerState
	// 	childStates = childStates
	// })

	const providerPassedScheduledJobClaimCountCountUnsub = claimedJobsStore.subscribe(count => {
		const index = childStates.findIndex(state => state.name === 'app.provider.claimed-jobs')
		const providerState = childStates[index]
		if ($claimedJobsStore > 0 && providerState) {
			providerState.icon = {
				class: 'text-danger',
				icon: 'bell-exclamation',
			}
		} else if (providerState) {
			providerState.icon = undefined
		}
		childStates[index] = providerState
		childStates = childStates
	})

	const removeJobWasClaimedProvider = mediator.provide('jobWasClaimed', () => {
		$availableJobsStore -= 1
	})

	onDestroy(() => {
		removeJobWasClaimedProvider()
		providerPassedScheduledJobClaimCountCountUnsub()
		clearInterval(providerPassedScheduledJobClaimCountIntervalId)
	})
	// availableJobsCountUnsub()
	// clearInterval(providerAvailableJobsIntervalId)
</script>

<div class="card mb-3 border-0">
	<div class="card-header rounded-0">
		<div>
			<h3>
				<i class="fak fa-lawnmower sidebar-left-icon"></i>
				Jobs
			</h3>
			<AsrNavTabBar
				{asr}
				ulClass="nav-tabs card-header-tabs align-items-end"
				tabs={childStates}
				breakpoint={false}
			/>
		</div>
	</div>
	<div class="card-body p-0">
		<uiView></uiView>
	</div>
</div>
