import { graphql, LoadProviderClaimableJobsCountStore, LoadPassedScheduleTimeJobClaims$input } from '$houdini'
import { getPassedScheduleTimeJobClaimCount } from './provider-job-claims-query'

const availableJobCountQuery: LoadProviderClaimableJobsCountStore = graphql`
	query LoadProviderClaimableJobsCount($filter: ClaimableJobFilter) {
		claimableJobs(filter: $filter) {
			info {
				totalItems
			}
		}
	}
`

export async function getAvailableJobCount(serviceIds: number[], regionIds: number[]): Promise<number> {
	const { data } = await availableJobCountQuery.fetch({
		variables: {
			filter: {
				serviceId: serviceIds,
				regionIds,
			},
		},
	})
	if (!data) {
		throw new Error('No data returned')
	}
	return data.claimableJobs.info.totalItems
}

export async function getProviderPassedScheduledJobClaimCount(filter: LoadPassedScheduleTimeJobClaims$input['filter']): Promise<number> {
	return await getPassedScheduleTimeJobClaimCount(filter)
}
