<script lang="ts">
	import type { Mediator } from 'services/api-fetch'
	import type { SvelteAsr } from 'client/types/common'
	import type { SettingLookup } from '$houdini'
	import type { ComponentEvents } from 'svelte'

	import NotificationTable, { type NotificationPreference } from './NotificationTable.svelte'
	import { getPushSubscription } from '@isoftdata/client-web-push'
	import { getContext, onMount } from 'svelte'
	import { graphql, SubscribeToPushNotificationsStore, TestPushNotificationStore } from '$houdini'
	import Button from '@isoftdata/svelte-button'
	import InstallationInstructionsModal from 'components/InstallationInstructionsModal.svelte'

	export let asr: SvelteAsr

	export let isCustomer: boolean
	export let isProvider: boolean
	export let customerNotificationSettings: Array<NotificationPreference>
	export let providerNotificationSettings: Array<NotificationPreference>
	export let settingsProperties: Record<string, SettingLookup>
	export let pushNotificationsEnabled: boolean
	export let publicVapidKey: string
	export let canEnablePushNotifications: boolean

	let installationInstructionsModal: InstallationInstructionsModal

	const mediator = getContext<Mediator>('mediator')

	const createAlertSubscription: SubscribeToPushNotificationsStore = graphql`
		mutation SubscribeToPushNotifications($subscription: NewPushSubscription!) {
			subscribeToPushNotifications(subscription: $subscription)
		}
	`

	const testPushNotification: TestPushNotificationStore = graphql`
		mutation TestPushNotification {
			testPushNotification
		}
	`

	async function testPush() {
		const result = await testPushNotification.mutate(null)
		console.log('result', result)
	}

	async function subscribeToPushNotifications() {
		const serviceWorkerRegistration = await mediator.call('getServiceWorkerRegistration')
		if (!serviceWorkerRegistration) {
			throw new Error('No service worker registration')
		}
		let subscription
		try {
			subscription = await getPushSubscription(serviceWorkerRegistration, publicVapidKey)
		} catch (e) {
			alert('Error subscribing to push notifications. Please make sure that you have turned on notifications in your browser settings.')
			console.error('Error getting subscription', e)
		}
		updateSubscriptionState()

		const subJson = subscription.toJSON()

		if (!subJson.keys) {
			throw new Error('No keys')
		}

		await createAlertSubscription.mutate({
			subscription: {
				endpoint: subscription.endpoint,
				keys: {
					p256dh: subJson.keys.p256dh,
					auth: subJson.keys.auth,
				},
				expirationTime: 10,
			},
		})
	}

	async function unsubscribeFromPushNotifications() {
		const sub = await getSubscription()
		sub?.unsubscribe()
		await updateSubscriptionState()
		// If the app was loaded once subscriptions were already enabled, it might not properly reset the state
		// In that case, reload the page lmao
		asr.go(null, { reset: Date.now() })
	}

	async function getSubscription() {
		const serviceWorkerRegistration = await mediator.call('getServiceWorkerRegistration')
		return (await serviceWorkerRegistration?.pushManager.getSubscription()) ?? null
	}

	async function updateSubscriptionState() {
		const sub = await getSubscription()
		console.log(sub)
		pushNotificationsEnabled = sub !== null
	}

	/** If they're toggling push notifications on for the first time, ask them to subscribe if possible */
	function onPushToggle(event: ComponentEvents<NotificationTable>['pushToggle']) {
		const enabled = event.detail.enabled
		if (enabled && !pushNotificationsEnabled && canEnablePushNotifications && confirm('Would you like to subscribe to push notifications on this device?')) {
			subscribeToPushNotifications()
		} else if (enabled && !pushNotificationsEnabled && !canEnablePushNotifications) {
			alert(
				`Your browser does not support push notifications. Please ensure your device and browser are up to date.

If you are on a mobile device, you may need to add LawnHiro to your home screen before being able to receive push notifications.
`,
			)
		}
	}

	onMount(async () => {
		await updateSubscriptionState()
	})
</script>

<div class="m-3">
	{#if isCustomer && isProvider}
		<div class="card mb-1">
			<h5 class="card-header">Customer</h5>
			<div class="card-body">
				<NotificationTable
					{settingsProperties}
					rows={customerNotificationSettings}
					on:pushToggle={onPushToggle}
				></NotificationTable>
			</div>
		</div>

		<div class="card mb-1">
			<h5 class="card-header">Provider</h5>
			<div class="card-body">
				<NotificationTable
					showPush
					{settingsProperties}
					rows={providerNotificationSettings}
					on:pushToggle={onPushToggle}
				></NotificationTable>
			</div>
		</div>
	{:else if isCustomer}
		<div class="card mb-1">
			<h5 class="card-header">Notification Preferences</h5>
			<div class="card-body">
				<NotificationTable
					{settingsProperties}
					rows={customerNotificationSettings}
					on:pushToggle={onPushToggle}
				></NotificationTable>
			</div>
		</div>
	{:else if isProvider}
		<div class="card mb-1">
			<h5 class="card-header">Notification Preferences</h5>
			<div class="card-body">
				<NotificationTable
					showPush
					{settingsProperties}
					rows={providerNotificationSettings}
					on:pushToggle={onPushToggle}
				></NotificationTable>
			</div>
		</div>
	{/if}

	<!-- For now, only providers have push notifications -->
	{#if isProvider}
		<div class="card">
			<h5 class="card-header d-flex justify-content-between">
				Push Notifications
				{#if canEnablePushNotifications && pushNotificationsEnabled}
					<span class="badge badge-success">Enabled</span>
				{:else if canEnablePushNotifications && !pushNotificationsEnabled}
					<span class="badge badge-warning">Not Enabled</span>
				{:else}
					<span class="badge badge-danger">Not Supported</span>
				{/if}
			</h5>
			<div class="card-body text-center">
				{#if !canEnablePushNotifications}
					<p>Your browser does not support push notifications. Please ensure your device and browser are up to date.</p>

					<p>If you are on a mobile device, you may need to add LawnHiro to your home screen before being able to receive push notifications.</p>

					<Button
						class="w-100"
						color="primary"
						title="See instructions on how to add LawnHiro to your home screen"
						on:click={() => installationInstructionsModal.show()}>See How</Button
					>
				{:else if !pushNotificationsEnabled}
					<p>Before you can receive push notifications on this device, you must subscribe to notifications on this device.</p>

					<Button
						class="w-100"
						iconClass="bell-on"
						title="Subscribe to push notifications on this device"
						colorGreyDisabled={false}
						disabled={!canEnablePushNotifications}
						on:click={() => subscribeToPushNotifications()}
					>
						Subcribe to Push Notifications
					</Button>
				{:else if pushNotificationsEnabled}
					<p>You will receive push notifications on all subscribed devices when an applicable alert is sent.</p>

					<div class="w-100 mobile-full-width">
						<Button
							iconClass="bell-slash"
							title="Unsubscribe from all push notifications on this device"
							colorGreyDisabled={false}
							disabled={!canEnablePushNotifications || !pushNotificationsEnabled}
							on:click={() => unsubscribeFromPushNotifications()}
						>
							Unsubscribe
						</Button>
						<Button
							iconClass="paper-plane"
							colorGreyDisabled={false}
							disabled={!pushNotificationsEnabled}
							on:click={testPush}
							>Send a Test Notification
						</Button>
					</div>
				{/if}
			</div>
		</div>
	{/if}
</div>

<InstallationInstructionsModal bind:this={installationInstructionsModal}></InstallationInstructionsModal>

<style>
	/* Until I make the layout better, just make everything have a max width */
	div.m-3 > div.card {
		max-width: 600px;
	}

	* :global(.custom-control-input:disabled) ~ :global(.custom-control-label::before),
	:global(.custom-control-input[disabled]) ~ :global(.custom-control-label::before) {
		background-color: var(--secondary);
	}

	@media (max-width: 767.9px) {
		div .mobile-full-width :global(button) {
			width: 100%;
		}
		div .mobile-full-width :global(button):first-child {
			margin-bottom: 1rem;
		}
	}

	@media (min-width: 768px) {
		div .mobile-full-width {
			display: flex;
			justify-content: space-between;
			gap: 0.5rem;
		}
		div .mobile-full-width :global(button) {
			flex-grow: 1;
		}
	}
</style>
