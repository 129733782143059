export default {
    "name": "UpcomingOrdersCount",
    "kind": "HoudiniQuery",
    "hash": "66e4d849e540018a1539ea7ed78bdadf5c13194a0ff96b29e20ae96bb8f65dce",

    "raw": `query UpcomingOrdersCount($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
  upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
    info {
      totalItems
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "upcomingOrders": {
                "type": "JobResponse",
                "keyRaw": "upcomingOrders(orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "JobOrderBy",
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=47653e537eac0ded70b61d58880a9b6bd70dc88827455b5aed4a376da0b261af";