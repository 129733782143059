export default {
    "name": "verifyUserAccount",
    "kind": "HoudiniMutation",
    "hash": "4007fc068428ba0cba0b3b08431830b9739fcde88d228d55f83c3a73b5812c4a",

    "raw": `mutation verifyUserAccount($verificationToken: NonEmptyString!, $userId: PositiveInt!) {
  verifyUserAccount(verificationToken: $verificationToken, userId: $userId) {
    email
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "verifyUserAccount": {
                "type": "UserAccount",
                "keyRaw": "verifyUserAccount(userId: $userId, verificationToken: $verificationToken)",

                "selection": {
                    "fields": {
                        "email": {
                            "type": "EmailAddress",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "verificationToken": "NonEmptyString",
            "userId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=d469726cc563b4a229d65aa80ae6b8cd54ed8f05a8fe98002b9c84fea871d517";