export default {
    "name": "PasswordRecovery",
    "kind": "HoudiniMutation",
    "hash": "03ac0f15ce38482d694ac064a0dca7db568b7d78e47e699f5d8a5607f1398987",

    "raw": `mutation PasswordRecovery($email: String!) {
  passwordRecovery(email: $email) {
    email
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "passwordRecovery": {
                "type": "PasswordResetResponse",
                "keyRaw": "passwordRecovery(email: $email)",

                "selection": {
                    "fields": {
                        "email": {
                            "type": "String",
                            "keyRaw": "email",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "email": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=49cf7dcdd0e0f5a476d2ed03257fc3a6f856eecad93978089bb388c492ed9c5c";