<script lang="ts">
	import { Table, Td, type Column } from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Button from '@isoftdata/svelte-button'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import Icon from '@isoftdata/svelte-icon'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Modal from '@isoftdata/svelte-modal'
	import TextArea from '@isoftdata/svelte-textarea'
	import DateRange from '@isoftdata/svelte-date-range'
	import Dropdown, { DropdownItem } from '@isoftdata/svelte-dropdown'

	import type {
		ProviderApplicationStatus$options as ProviderApplicationStatusType,
		PictureFileStatus$options as PictureFileStatusType,
		ProviderServiceApplicationStatus$options as ProviderServiceApplicationStatusType,
		ProviderApplicationStatus$options,
	} from '$houdini'
	import { ProviderApplicationStatus, PictureFileStatus, ProviderServiceApplicationStatus } from '$houdini'
	import type { Region, Provider, OnboardingApplication, PaginationInfo } from './onboarding'
	import {
		onboardingApplicationQuery,
		updateProviderServiceAdminMutation,
		updateProviderTransportationStatusAdminMutation,
		updateProviderDriverLicenseStatusAdminMutation,
		updateProviderOnboardingApplicationStatusMutation,
		completeProviderOnboardingMutation,
		setProviderOnboardingApplicationWaitlistMutation,
	} from './onboarding'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import toTitleCase from 'to-title-case'
	import { parseISO as parseISODate, startOfDay, endOfDay } from 'date-fns'
	import { getContext } from 'svelte'
	import type { Mediator } from 'types/common'
	import type { PersistentStore } from '@macfja/svelte-persistent-store'
	import type { NamedDateRange } from '@isoftdata/utility-date-time'

	export let computedColumns: Column[]
	export let onboardingApplications: OnboardingApplication[]
	export let serviceColumns: { property: string; label: string; id: number }[]
	export let regions: Region[] = []
	export let states: string[] = []
	export let cities: string[] = []
	export let zipCodes: string[] = []
	export let pageNumber: number
	export let userSavedPageSize: PersistentStore<number>
	export let providerPaginationInfo: PaginationInfo
	export let userSavedDateRangeFilter: PersistentStore<NamedDateRange>
	export let userSavedOnboardingStatusFilter: PersistentStore<ProviderApplicationStatus$options | null>

	type CopyColumn = 'Mobile Numbers' | 'Addresses' | 'Emails'

	type ApplicationExtraDetail = {
		applicationName: string
		statusLabel: string
		statusBadgeColor: string
		filePath: string
	}

	type ApprovalModalContent = {
		disableConfirmButton?: boolean
		show: boolean
		showRejectMessage: boolean
		rejectMessage: string
		title: string
		providerFullName: string
		type: 'EQUIPMENT' | 'TRANSPORTATION' | 'DRIVER_LICENSE' | 'APPROVE_APPLICATION' | 'REJECT_APPLICATION'
		applicationDetail?: { applicationId: number; applicationStatusLabel: string; applicationStatusBadgeColor: string; providerAgreement?: boolean; extraDetails?: ApplicationExtraDetail[] }
		filePath?: string
		serviceName?: string
	}

	let selectedRowIds: number[] = []

	let showFilters = false
	let showRegionFilters = false
	let textFilter = ''
	let selectedFilterRegionIds: number[] = []
	let stateFilter: string | null = null
	let cityFilter: string | null = null
	let zipFilter: string | null = null
	let waitListFilter: boolean | null = null
	let datesFilter: { startDate: string; endDate: string } | null = null
	let highlightRow = false
	let loading = false

	let approvalModalContent: ApprovalModalContent = {
		show: false,
		showRejectMessage: false,
		rejectMessage: '',
		title: '',
		providerFullName: '',
		filePath: '',
		type: 'TRANSPORTATION',
	}

	const mediator = getContext<Mediator>('mediator')

	const applicationStatusMap = {
		COMPLETED: { label: 'Completed', value: ProviderApplicationStatus.COMPLETED, badgeColor: 'badge-success', tableRowColor: 'table-success' },
		APPLICATION_APPROVED: { label: 'Application Approved', value: ProviderApplicationStatus.APPLICATION_APPROVED, badgeColor: 'badge-info', tableRowColor: 'table-info' },
		HRIS_ACCOUNT_APPROVED: { label: 'Gusto Account Approved', value: ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED, badgeColor: 'badge-info', tableRowColor: 'table-info' },
		DID_NOT_FINISH: { label: 'Did Not Finish', value: ProviderApplicationStatus.DID_NOT_FINISH, badgeColor: 'badge-secondary', tableRowColor: 'table-secondary' },
		IN_REVIEW: { label: 'Need Review', value: ProviderApplicationStatus.IN_REVIEW, badgeColor: 'badge-warning', tableRowColor: 'table-warning' },
		REJECTED: { label: 'Rejected', value: ProviderApplicationStatus.REJECTED, badgeColor: 'badge-danger', tableRowColor: 'table-danger' },
		SENT_HRIS_INVITE: { label: 'Sent Gusto Invite', value: ProviderApplicationStatus.SENT_HRIS_INVITE, badgeColor: 'badge-info', tableRowColor: 'table-info' },
		INFORMATION_REQUIRED: { label: 'Information Required', value: ProviderApplicationStatus.INFORMATION_REQUIRED, badgeColor: 'badge-light', tableRowColor: 'table-light' },
		HRIS_ERROR: { label: 'Gusto Error', value: ProviderApplicationStatus.HRIS_ERROR, badgeColor: 'badge-danger', tableRowColor: 'table-danger' },
		SOR_CHECK_PASSED: { label: 'SOR Check Passed', value: ProviderApplicationStatus.SOR_CHECK_PASSED, badgeColor: 'badge-success', tableRowColor: 'table-success' },
		SOR_CHECK_FAILED: { label: 'SOR Check Failed', value: ProviderApplicationStatus.SOR_CHECK_FAILED, badgeColor: 'badge-danger', tableRowColor: 'table-danger' },
		SOR_REVIEW_REQUIRED: { label: 'SOR Review Required', value: ProviderApplicationStatus.SOR_REVIEW_REQUIRED, badgeColor: 'badge-warning', tableRowColor: 'table-warning' },
	}

	const imageFileStatusMap = {
		APPROVED: { label: 'Approved', value: PictureFileStatus.APPROVED, tableColumnColor: 'bg-success', badgeColor: 'badge-success' },
		REJECTED: { label: 'Rejected', value: PictureFileStatus.REJECTED, tableColumnColor: 'bg-danger', badgeColor: 'badge-danger' },
		IN_REVIEW: { label: 'Needs Review', value: PictureFileStatus.IN_REVIEW, tableColumnColor: 'bg-warning', badgeColor: 'badge-warning' },
		NOT_UPLOADED: { label: 'Not Uploaded', value: PictureFileStatus.NOT_UPLOADED, tableColumnColor: '', badgeColor: 'badge-warning' },
	}

	const reviewActions = {
		APPLICATION_APPROVED: { label: 'Approve', value: ProviderApplicationStatus.APPLICATION_APPROVED, color: 'success' },
		REJECT: { label: 'Reject', value: ProviderApplicationStatus.REJECTED, color: 'secondary' },
	}

	function tableClickHandler(event: MouseEvent, applicationId: number) {
		if (event.shiftKey) {
			document.getSelection()?.removeAllRanges()
			if (selectedRowIds.length > 0) {
				const lastSelectedIndex = onboardingApplications.findIndex(application => application.id === selectedRowIds[selectedRowIds.length - 1])
				const clickedIndex = onboardingApplications.findIndex(application => application.id === applicationId)
				const minIndex = Math.min(lastSelectedIndex, clickedIndex)
				const maxIndex = Math.max(lastSelectedIndex, clickedIndex)
				selectedRowIds = onboardingApplications.slice(minIndex, maxIndex + 1).map(application => application.id)
			} else {
				selectedRowIds = [applicationId]
			}
		} else {
			if (selectedRowIds.includes(applicationId)) {
				event.stopPropagation()
				selectedRowIds = selectedRowIds.filter(id => id !== applicationId)
			} else {
				event.stopPropagation()
				selectedRowIds = [...selectedRowIds, applicationId]
			}
		}
	}

	function handleCopy(columnToCopy: CopyColumn) {
		const selectedApplications = onboardingApplications.filter(application => selectedRowIds.includes(application.id))
		if (selectedApplications.length === 0) {
			alert('Please select at least one row to copy')
			return
		}
		copyColValues(
			columnToCopy,
			selectedApplications.map(application => application.provider),
		)
	}

	function copyColValues(col: CopyColumn, providers: Provider[]) {
		// Adding the slice(2) to remove the country code since it's always +1 and PM wants copy to clipboard without it (I added the +1 check to prvent weirdness)
		const getMobileNumbers = (providers: Provider[]) =>
			providers
				.filter(provider => provider.userAccount.mobile)
				.map(provider => (provider.userAccount.mobile?.slice(0, 2) === '+1' ? provider.userAccount.mobile.slice(2) : provider.userAccount.mobile))
				.join(', ')
		const getAddresses = (providers: Provider[]) =>
			providers
				.filter(provider => provider.street && provider.city && provider.state && provider.zip)
				.map(provider => `${provider.street} ${provider.city} ${provider.state} ${provider.zip}`)
				.join(', ')
		const getEmails = (providers: Provider[]) =>
			providers
				.filter(provider => provider.userAccount.email)
				.map(provider => provider.userAccount.email)
				.join(', ')

		const columnFnMap = new Map([
			['Mobile Numbers', getMobileNumbers],
			['Addresses', getAddresses],
			['Emails', getEmails],
		])

		const columnFn = columnFnMap.get(col)
		if (columnFn) {
			navigator.clipboard.writeText(columnFn(providers))
			alert(`All selected ${col.toLowerCase()} have been copied to clipboard!`)
		}
	}

	const textSearch = (event: Event) => {
		const target = event.target as HTMLInputElement
		textFilter = target.value
		loadOnboardingApplications()
	}

	function updateSelectedRegionsFilter(event: Event, regionId: number) {
		const target = event.target as HTMLInputElement
		if (target.checked) {
			selectedFilterRegionIds = [...selectedFilterRegionIds, regionId]
		} else {
			selectedFilterRegionIds = selectedFilterRegionIds.filter(id => id !== regionId)
		}
		loadOnboardingApplications()
	}

	function getApplicationAllFileData(application: OnboardingApplication) {
		const applicationData: ApplicationExtraDetail[] = [
			{
				applicationName: 'Transportation',
				statusLabel: imageFileStatusMap[application.transportationStatus].label,
				statusBadgeColor: imageFileStatusMap[application.transportationStatus].badgeColor,
				filePath: application.transportationPictureFile?.path ? formatImageFileUrl(application.transportationPictureFile.path) : '',
			},
			{
				applicationName: 'Driver License',
				statusLabel: imageFileStatusMap[application.driverLicenseStatus].label,
				statusBadgeColor: imageFileStatusMap[application.driverLicenseStatus].badgeColor,
				filePath: application.driversLicensePictureFile?.path ? formatImageFileUrl(application.driversLicensePictureFile.path) : '',
			},
		]
		if (application.serviceApplications) {
			application.serviceApplications.forEach(serviceApplication => {
				applicationData.push({
					applicationName: serviceApplication.service.name,
					statusLabel: imageFileStatusMap[serviceApplication.status].label,
					statusBadgeColor: imageFileStatusMap[serviceApplication.status].badgeColor,
					filePath: serviceApplication.equipmentPictureFile?.path ? formatImageFileUrl(serviceApplication.equipmentPictureFile.path) : '',
				})
			})
		}
		return applicationData
	}

	async function reviewServiceApplication(providerServiceApplicationId: number, status: ProviderServiceApplicationStatusType, rejectMessage?: string) {
		try {
			const { data } = await updateProviderServiceAdminMutation.mutate({
				providerServiceApplicationId,
				status,
				rejectMessage: rejectMessage ?? null,
			})
			if (data) {
				const res = data.updateProviderServiceApplicationStatus
				if (res.providerApplication) {
					const onboardingApplicationIndex = onboardingApplications.findIndex(application => application.id === res.providerApplicationId)
					onboardingApplications[onboardingApplicationIndex].status = res.providerApplication.status
					const serviceApplication = onboardingApplications[onboardingApplicationIndex].serviceApplications
					if (serviceApplication) {
						const serviceApplicationIndex = serviceApplication.findIndex(serviceApplication => serviceApplication.id === res.id)
						serviceApplication[serviceApplicationIndex].status = res.status
						serviceApplication[serviceApplicationIndex].rejectMessage = res.rejectMessage
						onboardingApplications[onboardingApplicationIndex].serviceApplications = serviceApplication
					}
				}
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error as Error, { message: error.messge, title: 'Error Reviewing Service Equipment' })
		}
	}

	async function reviewOnboardingTransportation(providerOnboardingApplicationId: number, status: PictureFileStatusType, rejectMessage?: string) {
		try {
			const { data } = await updateProviderTransportationStatusAdminMutation.mutate({
				providerOnboardingApplicationId,
				status,
				rejectMessage: rejectMessage ?? null,
			})
			if (data) {
				const res = data.updateProviderOnboardingApplicationTransportationStatus
				const onboardingApplicationIndex = onboardingApplications.findIndex(application => application.id === res.id)
				onboardingApplications[onboardingApplicationIndex].status = res.status
				onboardingApplications[onboardingApplicationIndex].transportationStatus = res.transportationStatus
				onboardingApplications[onboardingApplicationIndex].transportationRejectMessage = res.transportationRejectMessage
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error as Error, { message: error.message, title: 'Error Reviewing Transportation' })
		}
	}

	async function reviewOnboardingDriverLicense(providerOnboardingApplicationId: number, status: PictureFileStatusType, rejectMessage?: string) {
		try {
			const { data } = await updateProviderDriverLicenseStatusAdminMutation.mutate({
				providerOnboardingApplicationId,
				status,
				rejectMessage: rejectMessage ?? null,
			})
			if (data) {
				const res = data.updateProviderOnboardingApplicationDriversLicenseStatus
				const onboardingApplicationIndex = onboardingApplications.findIndex(application => application.id === res.id)
				onboardingApplications[onboardingApplicationIndex].status = res.status
				onboardingApplications[onboardingApplicationIndex].driverLicenseStatus = res.driverLicenseStatus
				onboardingApplications[onboardingApplicationIndex].driverLicenseRejectMessage = res.driverLicenseRejectMessage
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error as Error, { message: error.message, title: 'Error Reviewing Driver License' })
		}
	}

	function gustoInviteStatusChange(event: Event, applicationId: number) {
		const target = event.target as HTMLInputElement
		const updatedStatus = target.checked ? ProviderApplicationStatus.SENT_HRIS_INVITE : ProviderApplicationStatus.APPLICATION_APPROVED
		updateOnboardingApplicationStatus(applicationId, updatedStatus)
	}

	function gustoAccountStatusChange(event: Event, applicationId: number) {
		const target = event.target as HTMLInputElement
		const updatedStatus = target.checked ? ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED : ProviderApplicationStatus.SENT_HRIS_INVITE
		updateOnboardingApplicationStatus(applicationId, updatedStatus)
	}

	async function updateOnboardingApplicationStatus(applicationId: number, status: ProviderApplicationStatusType, rejectMessage?: string) {
		try {
			const { data } = await updateProviderOnboardingApplicationStatusMutation.mutate({
				providerOnboardingApplicationId: applicationId,
				status,
				rejectMessage,
			})
			if (data) {
				const res = data.updateProviderOnboardingApplicationStatus
				const onboardingApplicationIndex = onboardingApplications.findIndex(application => application.id === res.id)
				onboardingApplications[onboardingApplicationIndex].status = res.status
				onboardingApplications[onboardingApplicationIndex].rejectMessage = res.rejectMessage
				onboardingApplications[onboardingApplicationIndex].gustoInviteSent =
					res.status === ProviderApplicationStatus.SENT_HRIS_INVITE || res.status === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED || res.status === ProviderApplicationStatus.COMPLETED
				onboardingApplications[onboardingApplicationIndex].gustoAccountApproved = res.status === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED || res.status === ProviderApplicationStatus.COMPLETED
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error as Error, { message: error.message, title: 'Error Updating Application Status' })
		}
	}

	async function setOnboardingApplicationWaitlist(event: Event, applicationId: number) {
		const target = event.target as HTMLInputElement
		const moveToWaitlist = target.checked
		const alertMessage = moveToWaitlist
			? 'Are you sure you want to set this application to waitlist? This action will trigger an email to notify the provider.'
			: 'Are you sure you want to set this application out of waitlist?'

		const onboardingApplicationIndex = onboardingApplications.findIndex(application => application.id === applicationId)
		try {
			if (confirm(alertMessage)) {
				const { data } = await setProviderOnboardingApplicationWaitlistMutation.mutate({
					providerOnboardingApplicationId: applicationId,
					moveToWaitlist,
				})

				if (data) {
					onboardingApplications[onboardingApplicationIndex].inWaitlist = data.setProviderApplicationWaitlist.inWaitlist
				}
			} else {
				onboardingApplications[onboardingApplicationIndex].inWaitlist = !moveToWaitlist
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error as Error, { message: error.message, title: 'Error Setting Application to Waitlist' })
		}
	}

	async function activateProvider(applicationId: number, applicationIndex: number) {
		try {
			const selectedApplication = onboardingApplications[applicationIndex]
			if (!selectedApplication.provider.homeRegion && !confirm('Provider does not have a home region. Do you want to proceed?')) {
				return
			}

			const { data } = await completeProviderOnboardingMutation.mutate({
				providerOnboardingApplicationId: applicationId,
			})

			if (data) {
				const res = data.completeProviderOnboardingApplication
				onboardingApplications[applicationIndex].status = res.status
				alert('Provider has been activated!')
			}
		} catch (error: any) {
			console.error(error)
			mediator.call('showError', error as Error, { message: error.message, title: 'Error Activating Provider' })
		}
	}

	async function loadOnboardingApplications() {
		loading = true
		const { data } = await onboardingApplicationQuery.fetch({
			variables: {
				filter: {
					homeRegionIds: selectedFilterRegionIds.length > 0 ? selectedFilterRegionIds : undefined,
					search: textFilter,
					status: $userSavedOnboardingStatusFilter,
					state: stateFilter,
					city: cityFilter,
					zip: zipFilter,
					createdFrom: datesFilter?.startDate ? startOfDay(parseISODate(datesFilter.startDate)) : undefined,
					createdTo: datesFilter?.endDate ? endOfDay(parseISODate(datesFilter.endDate)) : undefined,
					showWaitlistOnly: waitListFilter ? true : undefined,
					hideWaitlist: waitListFilter === false ? true : undefined,
				},
				pagination: {
					pageNumber,
					pageSize: $userSavedPageSize,
				},
				orderBy: ['ID_DESC'],
			},
		})
		if (!data?.providerOnboardingApplications.data) {
			return
		}
		onboardingApplications = data.providerOnboardingApplications.data.map(application => ({
			...application,
			gustoInviteSent: application.status === 'SENT_HRIS_INVITE' || application.status === 'HRIS_ACCOUNT_APPROVED' || application.status === 'COMPLETED',
			gustoAccountApproved: application.status === 'HRIS_ACCOUNT_APPROVED' || application.status === 'COMPLETED',
			providerFullName: application.provider.userAccount.fullName,
			providerEmail: application.provider.userAccount.email,
			providerPhoneNumber: application.provider.userAccount.mobile,
			providerHomeRegion: application.provider.homeRegion?.name,
			providerAddress: `${application.provider.street} ${application.provider.city} ${application.provider.state} ${application.provider.zip}`,
		}))
		providerPaginationInfo = {
			totalItems: data?.providerOnboardingApplications?.info?.totalItems ?? 0,
			totalPages: data?.providerOnboardingApplications?.info?.totalPages ?? 1,
			pageSize: data?.providerOnboardingApplications?.info?.pageSize ?? 0,
		}
		loading = false
	}
</script>

<Table
	responsive
	stickyHeader
	lazySort
	columnHidingEnabled
	idProp="id"
	parentStyle="max-height: 70vh; overflow-y: auto;"
	columns={computedColumns}
	bind:rows={onboardingApplications}
	filterEnabled={true}
	filterColumnClass="d-none"
	showFilterLabel={false}
	bind:selectedRowIds
	selectionEnabled={true}
	selectionMode="RANGE"
	rowSelectionIdProp="id"
	paginationEnabled={true}
	perPageCount={$userSavedPageSize}
	currentPageNumber={pageNumber}
	totalItemsCount={providerPaginationInfo.totalItems}
	on:pageChange={async event => {
		pageNumber = event.detail.pageNumber
		selectedRowIds = []
		await loadOnboardingApplications()
	}}
>
	<svelte:fragment slot="header">
		<CollapsibleCard
			cardHeaderClass="card-header d-flex justify-content-between align-items-center h5"
			headerText="Filters"
			entireHeaderToggles
			cardClass="mb-2 mt-2 w-100"
			bind:bodyShown={showFilters}
			on:show
		>
			<div class="form-row">
				<div class="col-xl-6 col-12">
					<DateRange
						colClass="col-4"
						excludedRanges={[]}
						bind:defaultRange={$userSavedDateRangeFilter}
						bind:range={$userSavedDateRangeFilter}
						rangeLabel="Created Date"
						disabled={loading}
						on:change={event => {
							const { dates } = event.detail
							datesFilter = {
								startDate: dates.from,
								endDate: dates.to,
							}
							loadOnboardingApplications()
						}}
					/>
				</div>
				<div class="col-xl-6 col-12">
					<Select
						showEmptyOption={false}
						label="Page Size"
						class="d-inline"
						bind:value={$userSavedPageSize}
						options={[10, 25, 50, 100, 1000]}
						disabled={loading}
						on:change={() => {
							pageNumber = 1
							loadOnboardingApplications()
						}}
					/>
				</div>
				<hr class="w-100 m-2" />
				<div class="col-12">
					<div class="form-row">
						<div class="col-12 col-md-3">
							<Select
								label="Onboarding Status"
								emptyText="All Statuses"
								bind:value={$userSavedOnboardingStatusFilter}
								disabled={loading}
								on:change={() => loadOnboardingApplications()}
							>
								{#each Object.keys(applicationStatusMap) as key}
									<option value={applicationStatusMap[key].value}>{applicationStatusMap[key].label}</option>
								{/each}
							</Select>
						</div>
						<div class="col-12 col-md-2">
							<Select
								label="State"
								emptyText="All States"
								bind:value={stateFilter}
								options={states}
								disabled={loading}
								on:change={() => loadOnboardingApplications()}
							/>
						</div>
						<div class="col-12 col-md-2">
							<Select
								label="City"
								emptyText="All Cities"
								bind:value={cityFilter}
								options={cities}
								disabled={loading}
								on:change={() => loadOnboardingApplications()}
							/>
						</div>
						<div class="col-12 col-md-2">
							<Select
								label="Zip"
								emptyText="All Zips"
								bind:value={zipFilter}
								options={zipCodes}
								disabled={loading}
								on:change={() => loadOnboardingApplications()}
							/>
						</div>
						<div class="col-12 col-md-3">
							<div class="mb-1">
								<Select
									showEmptyOption
									label="Waitlist Filter"
									emptyText="No Filter"
									bind:value={waitListFilter}
									disabled={loading}
									on:change={() => loadOnboardingApplications()}
									emptyValue={null}
								>
									<option value={true}>Waitlist Only</option>
									<option value={false}>Not on Waitlist</option>
								</Select>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12">
					<CollapsibleCard
						cardHeaderClass="card-header d-flex justify-content-between align-items-center h6"
						headerText="Home Region Filters"
						entireHeaderToggles
						cardClass="mt-2 mb-1"
						bind:bodyShown={showRegionFilters}
						on:show
					>
						<!-- TODO: this list will get longer and longer, maybe think of a way to display them. But I guess this is fine for v1 -->
						{#if regions.length > 0}
							<div class="form-row">
								{#each regions as region}
									<div class="col-12 col-sm-4 col-md-3">
										<Checkbox
											checked={selectedFilterRegionIds.includes(region.id)}
											label={region.name}
											disabled={loading}
											on:change={event => {
												updateSelectedRegionsFilter(event, region.id)
											}}
										/>
									</div>
								{/each}
							</div>
						{:else}
							No Regions
						{/if}
					</CollapsibleCard>
				</div>
			</div>
		</CollapsibleCard>
		<div class="d-flex justify-content-between">
			<Checkbox
				label="Highlight Rows"
				bind:checked={highlightRow}
			/>
			<Input
				labelParentClass="w-25 mb-1"
				showLabel={false}
				type="text"
				placeholder="Search"
				value={textFilter}
				on:change={textSearch}
			/>
		</div>
	</svelte:fragment>
	<svelte:fragment
		slot="body"
		let:rows
	>
		{#if loading}
			<tr>
				<td
					colspan={computedColumns.length}
					class="text-center"
				>
					<div
						class="spinner-border text-primary"
						role="status"
					></div>
				</td>
			</tr>
		{:else}
			{#each rows as application, index}
				{@const tableRowColor = applicationStatusMap[application.status].tableRowColor ?? ''}
				<tr
					class:table-dark={selectedRowIds.includes(application.id)}
					class={highlightRow ? (!selectedRowIds.includes(application.id) ? tableRowColor : '') : ''}
					on:click={event => {
						tableClickHandler(event, application.id)
					}}
				>
					<Td property="created">{new Date(application.created).toLocaleDateString()}</Td>
					<Td property="status">
						<span class="badge {applicationStatusMap[application.status].badgeColor}">
							{applicationStatusMap[application.status].label}
						</span>
						{#if application.inWaitlist}
							<span class="badge badge-warning ml-1">Waitlist</span>
						{/if}
					</Td>
					<Td property="providerFullName">{application.providerFullName}</Td>
					<Td property="providerEmail">
						<a
							href="mailto:{application.providerEmail}"
							class:text-dark={highlightRow && !selectedRowIds.includes(application.id)}
						>
							{application.providerEmail}
						</a>
					</Td>
					<Td property="providerPhoneNumber">
						{#if application.providerPhoneNumber}
							<a
								href={`tel:${application.providerPhoneNumber}`}
								class:text-dark={highlightRow && !selectedRowIds.includes(application.id)}
							>
								<!-- Parity with what the copy function will be pasting out to minimize confusion-->
								{application.providerPhoneNumber.slice(0, 2) ? application.providerPhoneNumber.slice(2) : application.providerPhoneNumber}
							</a>
						{:else}
							-
						{/if}
					</Td>
					<Td property="providerHomeRegion">
						<span class="badge badge-{application.provider.homeRegion?.status === 'ACTIVE' ? 'success' : 'danger'}">
							{application.providerHomeRegion ?? ''}
						</span>
					</Td>
					<Td property="provider[street]">
						<span class="text-nowrap">{application.provider.street}</span>
					</Td>
					<Td property="provider[city]">
						<span class="text-nowrap">{application.provider.city}</span>
					</Td>
					<Td property="provider[state]">
						<span class="text-nowrap">{application.provider.state}</span>
					</Td>
					<Td property="provider[zip]">
						<span class="text-nowrap">{application.provider.zip}</span>
					</Td>
					<Td property="shirtSize">
						{application.provider.shirtSize ?? ''}
					</Td>
					<Td property="hasLiabilityInsurance">
						<Icon
							icon={application.hasLiabilityInsurance ? 'check' : 'times'}
							class={highlightRow ? 'text-dark' : application.hasLiabilityInsurance ? 'text-success' : 'text-danger'}
						/>
					</Td>
					<Td property="acceptedProviderAgreement">
						<Icon
							icon={application.acceptedProviderAgreement ? 'check' : 'times'}
							class={highlightRow ? 'text-dark' : application.acceptedProviderAgreement ? 'text-success' : 'text-danger'}
						/>
					</Td>
					<Td
						property="transportation"
						class={imageFileStatusMap[application.transportationStatus].tableColumnColor}
						stopPropagation
					>
						{#if application.transportationPictureFile}
							{@const formattedTransportationPictureFile = { path: formatImageFileUrl(application.transportationPictureFile.path) }}
							<ImageThumbnail
								showImageCount={false}
								fileCount={1}
								thumbnailFile={formattedTransportationPictureFile}
								noImagePath="/images/noimage.jpg"
								thumbnailQueryParams="?width=50&height=50&fit=cover&quality=80&format=webp"
								on:click={() => {
									approvalModalContent = {
										show: true,
										showRejectMessage: !!application?.transportationRejectMessage,
										rejectMessage: application?.transportationRejectMessage ?? '',
										title: 'Approve Transportation?',
										providerFullName: `${application.provider.userAccount.firstName} ${application.provider.userAccount.lastName}`,
										applicationDetail: {
											applicationId: application.id,
											applicationStatusLabel: imageFileStatusMap[application.transportationStatus].label,
											applicationStatusBadgeColor: imageFileStatusMap[application.transportationStatus].badgeColor,
										},
										type: 'TRANSPORTATION',
										filePath: application.transportationPictureFile?.path ? formatImageFileUrl(application.transportationPictureFile.path) : '',
									}
								}}
							/>
						{/if}
					</Td>
					<Td
						property="driversLicense"
						class={imageFileStatusMap[application.driverLicenseStatus].tableColumnColor}
						stopPropagation
					>
						{#if application.driversLicensePictureFile}
							{@const formattedDriverLicensePictureFile = { path: formatImageFileUrl(application.driversLicensePictureFile.path) }}
							<ImageThumbnail
								showImageCount={false}
								fileCount={1}
								thumbnailFile={formattedDriverLicensePictureFile}
								noImagePath="/images/noimage.jpg"
								thumbnailQueryParams="?width=50&height=50&fit=cover&quality=80&format=webp"
								on:click={() => {
									approvalModalContent = {
										show: true,
										showRejectMessage: !!application.driverLicenseRejectMessage,
										rejectMessage: application.driverLicenseRejectMessage ?? '',
										title: `Approve Driver's License?`,
										providerFullName: `${application.provider.userAccount.firstName} ${application.provider.userAccount.lastName}`,
										applicationDetail: {
											applicationId: application.id,
											applicationStatusLabel: imageFileStatusMap[application.driverLicenseStatus].label,
											applicationStatusBadgeColor: imageFileStatusMap[application.driverLicenseStatus].badgeColor,
										},
										type: 'DRIVER_LICENSE',
										filePath: application?.driversLicensePictureFile?.path ? formatImageFileUrl(application.driversLicensePictureFile.path) : '',
									}
								}}
							/>
						{/if}
					</Td>
					{#each serviceColumns as column}
						{@const serviceApplication = application.serviceApplications?.find(service => service.serviceId === column.id)}
						<Td
							property={column.property}
							class={serviceApplication ? imageFileStatusMap[serviceApplication.status].tableColumnColor : ''}
							stopPropagation
						>
							{#if serviceApplication}
								{#if serviceApplication.equipmentPictureFile}
									{@const formattedEquipmentPictureFile = { path: formatImageFileUrl(serviceApplication.equipmentPictureFile.path) }}
									<ImageThumbnail
										showImageCount={false}
										fileCount={1}
										thumbnailFile={formattedEquipmentPictureFile}
										noImagePath="/images/noimage.jpg"
										thumbnailQueryParams="?width=50&height=50&fit=cover&quality=80&format=webp"
										on:click={() => {
											approvalModalContent = {
												show: true,
												showRejectMessage: !!serviceApplication.rejectMessage,
												rejectMessage: serviceApplication.rejectMessage ?? '',
												title: 'Approve Equipment?',
												providerFullName: `${application.provider.userAccount.firstName} ${application.provider.userAccount.lastName}`,
												applicationDetail: {
													applicationId: serviceApplication.id,
													applicationStatusLabel: imageFileStatusMap[serviceApplication.status].label,
													applicationStatusBadgeColor: imageFileStatusMap[serviceApplication.status].badgeColor,
												},
												serviceName: serviceApplication.service.name,
												type: 'EQUIPMENT',
												filePath: serviceApplication.equipmentPictureFile?.path ? formatImageFileUrl(serviceApplication.equipmentPictureFile.path) : '',
											}
										}}
									/>
								{/if}
							{/if}
						</Td>
					{/each}
					<Td
						property="inWaitlist"
						stopPropagation
					>
						<Checkbox
							showLabel={false}
							checked={application.inWaitlist}
							disabled={application.status !== ProviderApplicationStatus.INFORMATION_REQUIRED && application.status !== ProviderApplicationStatus.IN_REVIEW}
							on:change={event => {
								setOnboardingApplicationWaitlist(event, application.id)
							}}
						/>
					</Td>
					<Td
						property="review"
						stopPropagation
					>
						<div
							class="btn-group btn-group-toggle"
							data-toggle="buttons"
						>
							{#each Object.values(reviewActions) as action}
								<label
									class="btn btn-outline-{action.color} btn-sm d-flex align-items-center"
									class:active={action.value === application.status ||
										(action.value === ProviderApplicationStatus.APPLICATION_APPROVED &&
											(application.status === ProviderApplicationStatus.SENT_HRIS_INVITE ||
												application.status === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED ||
												application.status === ProviderApplicationStatus.COMPLETED))}
									class:disabled={application.status === ProviderApplicationStatus.REJECTED || application.status === ProviderApplicationStatus.COMPLETED}
									class:cursor-pointer={application.status !== ProviderApplicationStatus.REJECTED}
								>
									<input
										type="radio"
										name={action.value}
										value={action.value}
										autocomplete="off"
										disabled={action.value === application.status || application.status === ProviderApplicationStatus.REJECTED}
										on:click={() => {
											const allFileData = getApplicationAllFileData(application)
											if (action.value === ProviderApplicationStatus.REJECTED) {
												approvalModalContent = {
													show: true,
													showRejectMessage: true,
													rejectMessage: '',
													title: 'Reject Application?',
													providerFullName: `${application.provider.userAccount.firstName} ${application.provider.userAccount.lastName}`,
													applicationDetail: {
														applicationId: application.id,
														applicationStatusLabel: applicationStatusMap[application.status].label,
														applicationStatusBadgeColor: applicationStatusMap[application.status].badgeColor,
														extraDetails: allFileData,
													},
													type: 'REJECT_APPLICATION',
												}
											} else if (action.value === ProviderApplicationStatus.APPLICATION_APPROVED) {
												const disableConfirmButton = allFileData.some(fileData => fileData.statusLabel !== 'Approved') || !application.acceptedProviderAgreement
												approvalModalContent = {
													disableConfirmButton,
													show: true,
													showRejectMessage: false,
													rejectMessage: '',
													title: 'Approve Application?',
													providerFullName: `${application.provider.userAccount.firstName} ${application.provider.userAccount.lastName}`,
													applicationDetail: {
														applicationId: application.id,
														applicationStatusLabel: applicationStatusMap[application.status].label,
														applicationStatusBadgeColor: applicationStatusMap[application.status].badgeColor,
														providerAgreement: application.acceptedProviderAgreement,
														extraDetails: allFileData,
													},
													type: 'APPROVE_APPLICATION',
												}
											}
										}}
									/>
									{action.label}
								</label>
							{/each}
						</div>
					</Td>
					<Td
						property="gustoInviteSent"
						stopPropagation
					>
						<Checkbox
							showLabel={false}
							checked={application.gustoInviteSent}
							disabled={!(
								application.status === ProviderApplicationStatus.APPLICATION_APPROVED ||
								application.status === ProviderApplicationStatus.SENT_HRIS_INVITE ||
								application.status === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED
							)}
							on:change={event => gustoInviteStatusChange(event, application.id)}
						/>
					</Td>
					<Td
						property="gustoAccountApproved"
						stopPropagation
					>
						<Checkbox
							showLabel={false}
							checked={application.gustoAccountApproved}
							disabled={!(
								application.status === ProviderApplicationStatus.APPLICATION_APPROVED ||
								application.status === ProviderApplicationStatus.SENT_HRIS_INVITE ||
								application.status === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED
							)}
							on:change={event => gustoAccountStatusChange(event, application.id)}
						/>
					</Td>
					<Td
						property="activateProvider"
						stopPropagation
					>
						{#if application.status === ProviderApplicationStatus.COMPLETED}
							<span class="badge badge-success">Provider Activated</span>
						{:else}
							<Button
								color="success"
								size="sm"
								disabled={application.status !== ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED}
								on:click={() => {
									activateProvider(application.id, index)
								}}
							>
								Activate Provider
							</Button>
						{/if}
					</Td>
				</tr>
			{/each}
		{/if}
	</svelte:fragment>
</Table>

<div
	class="card-footer mt-2 d-flex justify-content-between align-items-center"
	style="margin-left: -20px; margin-right: -20px"
>
	<Dropdown
		parentDivClass="mt-1"
		size="sm"
		iconClass="copy"
		outline
	>
		Copy Selected
		<svelte:fragment slot="dropdownItems">
			<DropdownItem
				icon="mobile-screen"
				on:click={() => {
					handleCopy('Mobile Numbers')
				}}
			>
				Mobile Numbers
			</DropdownItem>
			<DropdownItem
				icon="map-marker-alt"
				on:click={() => {
					handleCopy('Addresses')
				}}
			>
				Addresses
			</DropdownItem>
			<DropdownItem
				icon="envelope"
				on:click={() => {
					handleCopy('Emails')
				}}
			>
				Emails
			</DropdownItem>
		</svelte:fragment>
	</Dropdown>
	<div class="d-flex align-items-center mb-1">
		<span class="mr-2">
			Total Selected: {selectedRowIds.length}
		</span>
		<Button
			color="info"
			size="sm"
			outline
			on:click={() => {
				if (selectedRowIds.length > 0) {
					selectedRowIds = []
				} else {
					selectedRowIds = onboardingApplications.map(application => application.id)
				}
			}}
		>
			{selectedRowIds.length > 0 ? 'Deselect All' : 'Select All'}
		</Button>
	</div>
</div>

<Modal
	bind:show={approvalModalContent.show}
	title={approvalModalContent.title}
	confirmButtonText={approvalModalContent.type === 'REJECT_APPLICATION' ? 'Reject' : 'Approve'}
	confirmButtonColor={approvalModalContent.type === 'REJECT_APPLICATION' ? 'danger' : 'success'}
	confirmButtonDisabled={!!approvalModalContent.disableConfirmButton}
	cancelShown={false}
	backdropClickCancels={false}
	on:close={() => {
		approvalModalContent.show = false
	}}
	on:confirm={() => {
		approvalModalContent.show = false
		if (approvalModalContent.applicationDetail) {
			if (approvalModalContent.type === 'EQUIPMENT') {
				reviewServiceApplication(approvalModalContent.applicationDetail.applicationId, ProviderServiceApplicationStatus.APPROVED)
			} else if (approvalModalContent.type === 'TRANSPORTATION') {
				reviewOnboardingTransportation(approvalModalContent.applicationDetail.applicationId, PictureFileStatus.APPROVED)
			} else if (approvalModalContent.type === 'DRIVER_LICENSE') {
				reviewOnboardingDriverLicense(approvalModalContent.applicationDetail.applicationId, PictureFileStatus.APPROVED)
			} else if (approvalModalContent.type === 'APPROVE_APPLICATION') {
				updateOnboardingApplicationStatus(approvalModalContent.applicationDetail.applicationId, ProviderApplicationStatus.APPLICATION_APPROVED)
			} else if (approvalModalContent.type === 'REJECT_APPLICATION') {
				updateOnboardingApplicationStatus(approvalModalContent.applicationDetail.applicationId, ProviderApplicationStatus.REJECTED, approvalModalContent.rejectMessage)
			}
		}
	}}
>
	<span>Provider: {approvalModalContent.providerFullName}</span>
	{#if approvalModalContent.serviceName}
		<br />
		<span>Service: {approvalModalContent.serviceName}</span>
	{/if}
	{#if approvalModalContent.applicationDetail}
		<br />
		<span>
			Status:
			<span class="badge {approvalModalContent.applicationDetail.applicationStatusBadgeColor}">
				{approvalModalContent.applicationDetail.applicationStatusLabel}
			</span>
		</span>
	{/if}
	{#if approvalModalContent.applicationDetail?.extraDetails}
		{#if approvalModalContent.applicationDetail?.providerAgreement !== undefined}
			<br />
			<span class="mt-2">
				Provider Agreement:
				<span class="badge {approvalModalContent.applicationDetail.providerAgreement ? 'badge-success' : 'badge-danger'}">
					{approvalModalContent.applicationDetail.providerAgreement ? 'Accepted' : 'Not Accepted'}
				</span>
			</span>
		{/if}
		<div class="form-row mt-2">
			{#each approvalModalContent.applicationDetail.extraDetails as extraDetail}
				<div class="col-12 col-sm-6 mb-1">
					<div class="card h-100 w-100">
						<div class="card-body d-flex flex-column align-items-center justify-content-between">
							<span>{extraDetail.applicationName}</span>
							{#if extraDetail.filePath}
								<img
									src={extraDetail.filePath}
									alt={toTitleCase(extraDetail.applicationName)}
									style="max-width: 80px; max-height: 80px;"
								/>
							{/if}
							<span class="badge {extraDetail.statusBadgeColor}">
								{extraDetail.statusLabel}
							</span>
						</div>
					</div>
				</div>
			{/each}
		</div>
	{/if}
	{#if approvalModalContent.filePath}
		<img
			class="mt-2 mb-2"
			src={approvalModalContent.filePath}
			alt={toTitleCase(approvalModalContent.type) + ' Approval'}
			style="max-width: 100%; max-height: 100%;"
		/>
	{/if}
	{#if approvalModalContent.type !== 'APPROVE_APPLICATION'}
		<Checkbox
			label="Use Custom Reject Message"
			bind:checked={approvalModalContent.showRejectMessage}
		/>
	{/if}
	{#if approvalModalContent.showRejectMessage}
		<TextArea
			class="mt-2"
			showLabel={false}
			placeholder="Enter reject reason here..."
			bind:value={approvalModalContent.rejectMessage}
		/>
	{/if}
	<svelte:fragment slot="modalFooter">
		<Button
			class="mb-1"
			color="secondary"
			icon={{
				icon: 'times',
				color: 'white',
			}}
			on:click={() => {
				approvalModalContent.show = false
				if (approvalModalContent.applicationDetail) {
					if (approvalModalContent.type === 'EQUIPMENT') {
						reviewServiceApplication(approvalModalContent.applicationDetail.applicationId, ProviderServiceApplicationStatus.REJECTED, approvalModalContent.rejectMessage)
					} else if (approvalModalContent.type === 'TRANSPORTATION') {
						reviewOnboardingTransportation(approvalModalContent.applicationDetail.applicationId, PictureFileStatus.REJECTED, approvalModalContent.rejectMessage)
					} else if (approvalModalContent.type === 'DRIVER_LICENSE') {
						reviewOnboardingDriverLicense(approvalModalContent.applicationDetail.applicationId, PictureFileStatus.REJECTED, approvalModalContent.rejectMessage)
					}
				}
			}}
		>
			{approvalModalContent.type === 'APPROVE_APPLICATION' || approvalModalContent.type === 'REJECT_APPLICATION' ? 'Cancel' : 'Reject'}
		</Button>
	</svelte:fragment>
</Modal>
