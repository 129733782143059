export default {
    "name": "RegionHelperRegionMetricData",
    "kind": "HoudiniFragment",
    "hash": "df4c4bfab8c96e0b9f93538351050fe42233c9a3ab81bc945bba820ccaebed91",

    "raw": `fragment RegionHelperRegionMetricData on RegionMetricData {
  numberOfActiveProviders
  numberOfQuotes
  numberOfJobsCompleted
  numberOfPerformingProviders
  conversion
  revenue
  providerJobVolume
  numberOfProvidersHomeRegion
  __typename
}
`,

    "rootType": "RegionMetricData",
    "stripVariables": [],

    "selection": {
        "fields": {
            "numberOfActiveProviders": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfActiveProviders",
                "visible": true
            },

            "numberOfQuotes": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfQuotes",
                "visible": true
            },

            "numberOfJobsCompleted": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfJobsCompleted",
                "visible": true
            },

            "numberOfPerformingProviders": {
                "type": "NonNegativeInt",
                "keyRaw": "numberOfPerformingProviders",
                "visible": true
            },

            "conversion": {
                "type": "String",
                "keyRaw": "conversion",
                "visible": true
            },

            "revenue": {
                "type": "String",
                "keyRaw": "revenue",
                "visible": true
            },

            "providerJobVolume": {
                "type": "Float",
                "keyRaw": "providerJobVolume",
                "visible": true
            },

            "numberOfProvidersHomeRegion": {
                "type": "Float",
                "keyRaw": "numberOfProvidersHomeRegion",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=6031bd1e5236d012ccef0545650da1d0a78e7782a38ee3bde77f688105f454dd";