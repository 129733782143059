<script lang="ts">
	import { formatISO } from 'date-fns'
	import { createEventDispatcher } from 'svelte'

	import { formatRelativeDateWeek } from 'utility/format/format-relative-date-week'
	import ClaimedJobsCard from './ClaimedJobsCard.svelte'
	import type { JobClaimWithMarker } from 'client/utility/claimed-jobs-helper'

	const dispatch = createEventDispatcher()

	export let jobClaims: JobClaimWithMarker[]
	export let listGroupClass = ''
	export let selectedJobId: number | null
	export let disableSelection = false

	function jobsByDate(jobClaims: JobClaimWithMarker[]): Map<string, { formattedDate: string; jobClaims: JobClaimWithMarker[] }> {
		const jobsByDate = new Map<string, { formattedDate: string; jobClaims: JobClaimWithMarker[] }>()

		jobClaims.forEach(jobClaim => {
			let date = jobClaim.job.scheduledAt

			const formattedDate = formatRelativeDateWeek(date)
			const key = formatISO(date, { representation: 'date' })

			if (!jobsByDate.has(key)) {
				jobsByDate.set(key, { formattedDate, jobClaims: [] })
			}

			jobsByDate.get(key)?.jobClaims.push(jobClaim)
		})

		return jobsByDate
	}
</script>

{#each jobsByDate(jobClaims) as [key, jobsFormattedDate] (key)}
	<label
		for="jobList"
		class="mb-0 mt-2"
	>
		<h4 class="mb-0">{jobsFormattedDate.formattedDate}</h4>
	</label>
	<div
		id="jobList"
		class={`list-group ${listGroupClass}`}
	>
		{#each jobsFormattedDate.jobClaims as jobClaim}
			<button
				id="job-{jobClaim.job.id}"
				type="button"
				class="list-group-item list-group-itemp-action p-2"
				on:click={() => {
					dispatch('jobClicked', jobClaim.job.id)
				}}
				disabled={disableSelection}
				class:cursor-default={disableSelection}
				class:active={jobClaim.job.id === selectedJobId}
			>
				<div class="d-flex align-items-center">
					<ClaimedJobsCard
						bind:job={jobClaim.job}
						jobHasPassedScheduledTime={jobClaim.isLate}
					/>
				</div>
			</button>

			{#if jobClaim.job.id === selectedJobId}
				<div class="list-group-item border border-primary">
					<slot />
				</div>
			{/if}
		{/each}
	</div>
{/each}
