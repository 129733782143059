export default {
    "name": "GetAdminAlertTransactions",
    "kind": "HoudiniQuery",
    "hash": "87d8a8264dfecbb74820095717d4187055801e09da23fd5649f387ecb72b55b4",

    "raw": `query GetAdminAlertTransactions($orderBy: [TransactionOrderBy!], $filter: AdminAlertTransactionFilter) {
  getAdminAlertTransactions(orderBy: $orderBy, filter: $filter) {
    data {
      id
      total
      paymentStatus
      invoiceStatus
      job {
        id
        service {
          name
          id
        }
      }
      customer {
        userAccount {
          fullName
          email
          id
        }
        id
      }
      transactionPayments {
        created
        gatewayMessage
        transactionType
        transactionPaymentClassification
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getAdminAlertTransactions": {
                "type": "TransactionResponse",
                "keyRaw": "getAdminAlertTransactions(filter: $filter, orderBy: $orderBy)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Transaction",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "total": {
                                        "type": "String",
                                        "keyRaw": "total",
                                        "visible": true
                                    },

                                    "paymentStatus": {
                                        "type": "PaymentStatus",
                                        "keyRaw": "paymentStatus",
                                        "visible": true
                                    },

                                    "invoiceStatus": {
                                        "type": "InvoiceStatus",
                                        "keyRaw": "invoiceStatus",
                                        "visible": true
                                    },

                                    "job": {
                                        "type": "Job",
                                        "keyRaw": "job",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "service": {
                                                    "type": "Service",
                                                    "keyRaw": "service",

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "customer": {
                                        "type": "Customer",
                                        "keyRaw": "customer",

                                        "selection": {
                                            "fields": {
                                                "userAccount": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "userAccount",

                                                    "selection": {
                                                        "fields": {
                                                            "fullName": {
                                                                "type": "String",
                                                                "keyRaw": "fullName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "email": {
                                                                "type": "EmailAddress",
                                                                "keyRaw": "email",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "transactionPayments": {
                                        "type": "TransactionPayment",
                                        "keyRaw": "transactionPayments",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "gatewayMessage": {
                                                    "type": "String",
                                                    "keyRaw": "gatewayMessage",
                                                    "visible": true
                                                },

                                                "transactionType": {
                                                    "type": "TransactionType",
                                                    "keyRaw": "transactionType",
                                                    "visible": true
                                                },

                                                "transactionPaymentClassification": {
                                                    "type": "TransactionPaymentClassification",
                                                    "keyRaw": "transactionPaymentClassification",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "TransactionOrderBy",
            "filter": "AdminAlertTransactionFilter"
        },

        "types": {
            "AdminAlertTransactionFilter": {
                "resolved": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0d8cf55edb38be7e20808b1548f388431c49bf8f7bc555ab2616c299a4f9281b";