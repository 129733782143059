<script lang="ts">
	import type { LoadRegionHelperRegions$result } from '$houdini'
	import Table, { Td } from '@isoftdata/svelte-table'

	export let containedCities: Exclude<LoadRegionHelperRegions$result['regions']['data'][number]['containedCities'], null>
</script>

<Table
	responsive
	columns={[
		{ property: 'city', name: 'City' },
		{ property: 'zip', name: 'Zip Code', numeric: true },
		{ property: 'state', name: 'State' },
		{ property: 'country', name: 'Country' },
		{ property: 'description', name: 'Description' },
	]}
	rows={containedCities}
	filterEnabled={false}
	let:row
>
	<svelte:fragment slot="no-rows">
		<tr>
			<td colspan="5">
				<span>No cities found</span>
			</td>
		</tr>
	</svelte:fragment>
	<tr>
		<Td property="city">{row.city ?? ''}</Td>
		<Td property="zip">{row.zip ?? ''}</Td>
		<Td property="state">{row.state ?? ''}</Td>
		<Td property="country">{row.country ?? ''}</Td>
		<Td property="description">{row.description ?? ''}</Td>
	</tr>
</Table>
