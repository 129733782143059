import { isThisWeek, differenceInDays, format, isToday, isTomorrow, isYesterday } from 'date-fns'

function simpleDate(date: Date): Date {
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1))
}

export function formatRelativeDateWeek(date: Date, now = new Date()): string {
	date = simpleDate(date)
	const today = simpleDate(now)

	if (isThisWeek(date)) {
		if (isToday(date)) {
			return `Today (${date.toLocaleDateString()})`
		} else if (isTomorrow(date)) {
			return `Tomorrow (${date.toLocaleDateString()})`
		} else if (isYesterday(date)) {
			return `Yesterday (${date.toLocaleDateString()})`
		} else {
			return `This ${format(date, 'EEEE')} (${date.toLocaleDateString()})`
		}
	} else if (differenceInDays(date, today) > 0 && differenceInDays(date, today) <= 7) {
		// Next week
		return `Next ${format(date, 'EEEE')} (${date.toLocaleDateString()})`
	} else if (differenceInDays(date, today) < 0 && differenceInDays(date, today) >= -7) {
		// Last week
		return `Last ${format(date, 'EEEE')} (${date.toLocaleDateString()})`
	} else {
		return date.toLocaleDateString()
	}
}

export default formatRelativeDateWeek
