import sessionStore from 'stores/session'
import { graphql } from '$houdini'
import type { Login$result } from '$houdini'

const loginMutation = graphql(`
	mutation Login($email: String!, $password: String!, $regionId: PositiveInt!) {
		createSession(email: $email, password: $password, regionId: $regionId) {
			sessionToken
			email
			userAccountId
			user {
				recoveryEmail
				firstName
				fullName
				lastName
				customer {
					id
					billingCountry
					billingZip
					billingState
					billingCity
					billingStreet
					ownedResidences {
						data {
							id
							street
							city
							state
							zip
						}
					}
				}
				provider {
					id
					status
					profilePictureFile {
						path
					}
					latestOnboardingApplication {
						id
						status
					}
				}
				userRoles {
					permissionName
					value
				}
			}
		}
	}
`)

export function storeSession(responseSession: ResponseSession): ClientSession {
	// Apparently createSession.user is nullable?
	if (!responseSession.user) {
		throw new Error('Failed to login')
	}
	const { user, ...rest } = responseSession

	const storedSession: ClientSession = {
		...rest,
		...user,
		authToken: responseSession.sessionToken,
		siteId: 1,
		hasOnboardingApplication: !!user.provider?.latestOnboardingApplication,
	}

	sessionStore.set(storedSession)

	return storedSession
}

export const loginAndSaveSession = async (mediator, { email, password, regionId = 1 }: { email: string; password: string; regionId: 1 }) => {
	const { data } = await loginMutation.mutate({ email, password, regionId })

	if (!data) {
		throw new Error('Failed to login')
	}

	return storeSession(data.createSession)
}
type ResponseSession = NonNullable<Login$result['createSession']>
// We mutate the session after receiving it from the API, so this is slightly different than what it returns
export type ClientSession = Omit<ResponseSession, 'user'> & ResponseSession['user'] & { authToken: string | null; siteId: 1; hasOnboardingApplication: boolean }
