import component from './Admin.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin',
		route: 'admin',
		template: {
			component,
			svelte: true,
			options: {},
		},
		resolve(_data, _parameters) {
			return Promise.resolve({})
		},
	})
}
