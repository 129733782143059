import template from './customer-quote-options.html'
import ractiveTransitionsSlide from 'ractive-transitions-slide'
import makeButton from '@isoftdata/button'

export default function createServiceSelectionComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				questions: [],
				selectedAnswerIds: [],
				allQuestionsAnswered: false,
			}
		},
		transitions: {
			slide: ractiveTransitionsSlide,
		},
		attributes: {
			required: [ 'questions' ],
			optional: [ 'selectedAnswerIds', 'allQuestionsAnswered' ],
		},
		components: {
			itButton: makeButton(),
		},
		observe: {
			// computed property does not work in this case, it can only be pass from the state to the component,
			// not the other way around (computed property in the component cannot be passed to the state)
			questions(questions) {
				const selectedAnswerIds = questions.reduce((idList, question) => {
					const selectedAnswer = question.answers.find(answer => answer.selected)
					if (selectedAnswer) {
						idList.push(selectedAnswer.id)
					}
					return idList
				}, [])
				const allQuestionsAnswered = questions.length === selectedAnswerIds.length
				this.set({ selectedAnswerIds, allQuestionsAnswered })
			},
		},
		setSelected(questionIndex, answerIndex) {
			this.set(`questions[${questionIndex}].answers.*.selected`, false)
			this.set(`questions[${questionIndex}].answers[${answerIndex}].selected`, true)
		},
	})
}
