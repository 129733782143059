export default {
    "name": "LoadOnboardingQuery",
    "kind": "HoudiniQuery",
    "hash": "86ec28cba6b02b8ea6d7dc0a2224fb7cbbf6b821f155ef4603ff8bfbdec8fd63",

    "raw": `query LoadOnboardingQuery($providerId: PositiveInt!, $filter: ServiceFilter, $pagination: PaginatedInput) {
  provider(id: $providerId) {
    id
    status
    street
    city
    state
    zip
    status
    shirtSize
    homeRegion {
      id
      name
    }
    userAccount {
      firstName
      lastName
      email
      mobile
      provider {
        dateOfBirth
        homeRegionId
        profilePictureFile {
          path
          id
        }
        id
      }
      id
    }
    onboardingApplication: latestOnboardingApplication {
      id
      status
      created
      completed
      driversLicensePictureFile {
        path
        id
      }
      driverLicenseStatus
      driverLicenseRejectMessage
      transportationPictureFile {
        path
        id
      }
      transportationStatus
      transportationRejectMessage
      acceptedProviderAgreement
      hasLiabilityInsurance
      inWaitlist
      rejectMessage
      lastUpdated
      serviceApplications: providerServiceApplications {
        id
        serviceId
        rejectMessage
        status
        equipmentPictureFile {
          path
          id
        }
      }
    }
  }
  services(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      description
      requirement
      examplePictureFile {
        path
        id
      }
    }
  }
  shirtSizes: getShirtSizes
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "provider": {
                "type": "Provider",
                "keyRaw": "provider(id: $providerId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "shirtSize": {
                            "type": "String",
                            "keyRaw": "shirtSize",
                            "nullable": true,
                            "visible": true
                        },

                        "homeRegion": {
                            "type": "Region",
                            "keyRaw": "homeRegion",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "EmailAddress",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "mobile": {
                                        "type": "PhoneNumber",
                                        "keyRaw": "mobile",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "provider": {
                                        "type": "Provider",
                                        "keyRaw": "provider",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "dateOfBirth": {
                                                    "type": "Date",
                                                    "keyRaw": "dateOfBirth",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "homeRegionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "homeRegionId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "profilePictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "profilePictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "onboardingApplication": {
                            "type": "ProviderApplication",
                            "keyRaw": "onboardingApplication",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderApplicationStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "completed": {
                                        "type": "DateTime",
                                        "keyRaw": "completed",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "driversLicensePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "driversLicensePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "driverLicenseStatus": {
                                        "type": "PictureFileStatus",
                                        "keyRaw": "driverLicenseStatus",
                                        "visible": true
                                    },

                                    "driverLicenseRejectMessage": {
                                        "type": "String",
                                        "keyRaw": "driverLicenseRejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "transportationPictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "transportationPictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "transportationStatus": {
                                        "type": "PictureFileStatus",
                                        "keyRaw": "transportationStatus",
                                        "visible": true
                                    },

                                    "transportationRejectMessage": {
                                        "type": "String",
                                        "keyRaw": "transportationRejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "acceptedProviderAgreement": {
                                        "type": "Boolean",
                                        "keyRaw": "acceptedProviderAgreement",
                                        "visible": true
                                    },

                                    "hasLiabilityInsurance": {
                                        "type": "Boolean",
                                        "keyRaw": "hasLiabilityInsurance",
                                        "visible": true
                                    },

                                    "inWaitlist": {
                                        "type": "Boolean",
                                        "keyRaw": "inWaitlist",
                                        "visible": true
                                    },

                                    "rejectMessage": {
                                        "type": "String",
                                        "keyRaw": "rejectMessage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastUpdated": {
                                        "type": "DateTime",
                                        "keyRaw": "lastUpdated",
                                        "visible": true
                                    },

                                    "serviceApplications": {
                                        "type": "ProviderServiceApplication",
                                        "keyRaw": "serviceApplications",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "rejectMessage": {
                                                    "type": "String",
                                                    "keyRaw": "rejectMessage",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "status": {
                                                    "type": "ProviderServiceApplicationStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "equipmentPictureFile": {
                                                    "type": "FileEntity",
                                                    "keyRaw": "equipmentPictureFile",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "requirement": {
                                        "type": "String",
                                        "keyRaw": "requirement",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "examplePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "examplePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "shirtSizes": {
                "type": "String",
                "keyRaw": "shirtSizes",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerId": "PositiveInt",
            "filter": "ServiceFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ServiceFilter": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "regionId": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=84fd180c07e91a747f00bbd434d156876b0fef732e590464fce00ca909ce8d4f";