export const userAccountFields = `#graphql
	id
	mobile
	created
	email
	firstName
	lastName
	fullName
	status
	customer {
		id
	}
	provider {
		id
		status
		profilePictureFile {
			id
			path
		}
	}
	authorizedRegions {
        id
    }
`

export const userAccountsQuery = `#graphql
    query UserAccounts($filter: UserFilter, $pagination: PaginatedInput) {
        userAccounts(filter: $filter, pagination: $pagination) {
            data {
                ${userAccountFields}
            }
        }
    }
`
