import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/Session'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class SessionStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "SessionStore",
			variables: false,
		})
	}
}

export async function load_Session(params) {
	await initClient()

	const store = new SessionStore()

	await store.fetch(params)

	return {
		Session: store,
	}
}
