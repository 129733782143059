export default {
    "name": "CustomerResidencesQuery",
    "kind": "HoudiniQuery",
    "hash": "29d20af978990e7a1f99d677c22b02dcb6ed11511074760fe22e0b4506d87e79",

    "raw": `query CustomerResidencesQuery($filter: ResidenceFilter) {
  residences(filter: $filter) {
    data {
      id
      street
      city
      state
      zip
      regionId
      region {
        status
        id
      }
      customerId
      status
      country
      county
      latitude
      longitude
      googleMapsPlaceId
      displayLawnSquareFootage
      estimatedLawnSquareFootage
      effectiveLawnSquareFootage
      answers {
        id
        question {
          services {
            id
          }
          id
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "residences": {
                "type": "ResidenceResponse",
                "keyRaw": "residences(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Residence",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "regionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "regionId",
                                        "visible": true
                                    },

                                    "region": {
                                        "type": "Region",
                                        "keyRaw": "region",

                                        "selection": {
                                            "fields": {
                                                "status": {
                                                    "type": "RegionStatus",
                                                    "keyRaw": "status",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "customerId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "customerId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ResidenceStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "county": {
                                        "type": "String",
                                        "keyRaw": "county",
                                        "visible": true
                                    },

                                    "latitude": {
                                        "type": "Latitude",
                                        "keyRaw": "latitude",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "longitude": {
                                        "type": "Longitude",
                                        "keyRaw": "longitude",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "googleMapsPlaceId": {
                                        "type": "String",
                                        "keyRaw": "googleMapsPlaceId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "displayLawnSquareFootage": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "displayLawnSquareFootage",
                                        "visible": true
                                    },

                                    "estimatedLawnSquareFootage": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "estimatedLawnSquareFootage",
                                        "visible": true
                                    },

                                    "effectiveLawnSquareFootage": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "effectiveLawnSquareFootage",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "question": {
                                                    "type": "Question",
                                                    "keyRaw": "question",

                                                    "selection": {
                                                        "fields": {
                                                            "services": {
                                                                "type": "Service",
                                                                "keyRaw": "services",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ResidenceFilter"
        },

        "types": {
            "ResidenceFilter": {
                "customerId": "PositiveInt",
                "residenceKeywordSearch": "String",
                "residenceStatus": "ResidenceStatus",
                "tagId": "PositiveInt",
                "userAccountId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=641da584085f04956f00531440f7c204d58fbfa0b9ef21347e3cd026af0df01d";