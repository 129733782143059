export default {
    "name": "LoadGlobalSetting",
    "kind": "HoudiniQuery",
    "hash": "d9d36d74353aa5a9f8b5a2d0b9883883f0b48cd50f389ddee37fe52e5974e2e9",

    "raw": `query LoadGlobalSetting($lookup: SettingLookup!) {
  getGlobalSetting(lookup: $lookup) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getGlobalSetting": {
                "type": "SettingResult",
                "keyRaw": "getGlobalSetting(lookup: $lookup)",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "lookup": "SettingLookup"
        },

        "types": {
            "SettingLookup": {
                "category": "String",
                "defaultValue": "String",
                "name": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6d5c2864cfc7c409549394f795a6c5b8bd01e020d2514da6f328c6e471ade375";