export default {
    "name": "LoadPayables",
    "kind": "HoudiniQuery",
    "hash": "b743dc1ee98b5e43e0fe658184544f71baaa929216a4d9a25b5013136ff29981",

    "raw": `query LoadPayables($filter: PayableJobFilter) {
  payables(filter: $filter) {
    data {
      transaction {
        paymentStatus
        cost
        invoiceStatus
        coupon {
          code
          id
        }
        job {
          residence {
            zip
            state
            city
            street
            id
          }
          customer {
            userAccount {
              fullName
              id
            }
            id
          }
          completed
          totalElapsedTime
          jobStatus
          requestedSchedule
          id
          created
          activeJobClaim {
            provider {
              userAccount {
                firstName
                lastName
                fullName
                email
                id
              }
              id
            }
            id
          }
        }
        tip
        id
      }
      payableDate
      amount
      created
      transactionPaymentClassification
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "payables": {
                "type": "TransactionPaymentResponse",
                "keyRaw": "payables(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "TransactionPayment",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "transaction": {
                                        "type": "Transaction",
                                        "keyRaw": "transaction",

                                        "selection": {
                                            "fields": {
                                                "paymentStatus": {
                                                    "type": "PaymentStatus",
                                                    "keyRaw": "paymentStatus",
                                                    "visible": true
                                                },

                                                "cost": {
                                                    "type": "String",
                                                    "keyRaw": "cost",
                                                    "visible": true
                                                },

                                                "invoiceStatus": {
                                                    "type": "InvoiceStatus",
                                                    "keyRaw": "invoiceStatus",
                                                    "visible": true
                                                },

                                                "coupon": {
                                                    "type": "Coupon",
                                                    "keyRaw": "coupon",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "code": {
                                                                "type": "NonEmptyString",
                                                                "keyRaw": "code",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "job": {
                                                    "type": "Job",
                                                    "keyRaw": "job",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "residence": {
                                                                "type": "Residence",
                                                                "keyRaw": "residence",

                                                                "selection": {
                                                                    "fields": {
                                                                        "zip": {
                                                                            "type": "String",
                                                                            "keyRaw": "zip",
                                                                            "visible": true
                                                                        },

                                                                        "state": {
                                                                            "type": "String",
                                                                            "keyRaw": "state",
                                                                            "visible": true
                                                                        },

                                                                        "city": {
                                                                            "type": "String",
                                                                            "keyRaw": "city",
                                                                            "visible": true
                                                                        },

                                                                        "street": {
                                                                            "type": "String",
                                                                            "keyRaw": "street",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "customer": {
                                                                "type": "Customer",
                                                                "keyRaw": "customer",

                                                                "selection": {
                                                                    "fields": {
                                                                        "userAccount": {
                                                                            "type": "UserAccount",
                                                                            "keyRaw": "userAccount",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "fullName": {
                                                                                        "type": "String",
                                                                                        "keyRaw": "fullName",
                                                                                        "nullable": true,
                                                                                        "visible": true
                                                                                    },

                                                                                    "id": {
                                                                                        "type": "PositiveInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "completed": {
                                                                "type": "DateTime",
                                                                "keyRaw": "completed",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "totalElapsedTime": {
                                                                "type": "NonNegativeInt",
                                                                "keyRaw": "totalElapsedTime",
                                                                "visible": true
                                                            },

                                                            "jobStatus": {
                                                                "type": "JobStatus",
                                                                "keyRaw": "jobStatus",
                                                                "visible": true
                                                            },

                                                            "requestedSchedule": {
                                                                "type": "Date",
                                                                "keyRaw": "requestedSchedule",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "created": {
                                                                "type": "DateTime",
                                                                "keyRaw": "created",
                                                                "visible": true
                                                            },

                                                            "activeJobClaim": {
                                                                "type": "JobClaim",
                                                                "keyRaw": "activeJobClaim",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "provider": {
                                                                            "type": "Provider",
                                                                            "keyRaw": "provider",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "userAccount": {
                                                                                        "type": "UserAccount",
                                                                                        "keyRaw": "userAccount",

                                                                                        "selection": {
                                                                                            "fields": {
                                                                                                "firstName": {
                                                                                                    "type": "String",
                                                                                                    "keyRaw": "firstName",
                                                                                                    "visible": true
                                                                                                },

                                                                                                "lastName": {
                                                                                                    "type": "String",
                                                                                                    "keyRaw": "lastName",
                                                                                                    "visible": true
                                                                                                },

                                                                                                "fullName": {
                                                                                                    "type": "String",
                                                                                                    "keyRaw": "fullName",
                                                                                                    "nullable": true,
                                                                                                    "visible": true
                                                                                                },

                                                                                                "email": {
                                                                                                    "type": "EmailAddress",
                                                                                                    "keyRaw": "email",
                                                                                                    "visible": true
                                                                                                },

                                                                                                "id": {
                                                                                                    "type": "PositiveInt",
                                                                                                    "keyRaw": "id",
                                                                                                    "visible": true
                                                                                                }
                                                                                            }
                                                                                        },

                                                                                        "visible": true
                                                                                    },

                                                                                    "id": {
                                                                                        "type": "PositiveInt",
                                                                                        "keyRaw": "id",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "tip": {
                                                    "type": "String",
                                                    "keyRaw": "tip",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "payableDate": {
                                        "type": "DateTime",
                                        "keyRaw": "payableDate",
                                        "visible": true
                                    },

                                    "amount": {
                                        "type": "String",
                                        "keyRaw": "amount",
                                        "visible": true
                                    },

                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "transactionPaymentClassification": {
                                        "type": "TransactionPaymentClassification",
                                        "keyRaw": "transactionPaymentClassification",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "PayableJobFilter"
        },

        "types": {
            "PayableJobFilter": {
                "completedFrom": "DateTime",
                "completedTo": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=e6e45914057548326b89a6a77bc0399fc685caddb1886dc2124ccd9bb7abde0f";