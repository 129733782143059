import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadZones'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadZonesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadZonesStore",
			variables: false,
		})
	}
}

export async function load_LoadZones(params) {
	await initClient()

	const store = new LoadZonesStore()

	await store.fetch(params)

	return {
		LoadZones: store,
	}
}
