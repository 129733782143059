export default {
    "name": "AddAreaToRegionAdmin",
    "kind": "HoudiniMutation",
    "hash": "e1bd1eb4036c8823600ef117664f809257fd1cd5483e9a744336795a0d5c9947",

    "raw": `mutation AddAreaToRegionAdmin($newRegionArea: NewRegionArea!) {
  addAreaToRegion(newRegionArea: $newRegionArea) {
    ...RegionHelperRegionAreaData
    id
  }
}

fragment RegionHelperRegionAreaData on RegionArea {
  id
  city
  state
  zip
  country
  description
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "addAreaToRegion": {
                "type": "RegionArea",
                "keyRaw": "addAreaToRegion(newRegionArea: $newRegionArea)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "nullable": true,
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "nullable": true,
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "RegionHelperRegionAreaData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newRegionArea": "NewRegionArea"
        },

        "types": {
            "NewRegionArea": {
                "city": "String",
                "country": "NonEmptyString",
                "description": "String",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "regionId": "PositiveInt",
                "state": "NonEmptyString",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=2ee697e2d340613382fa7559c94b952da6f0883cf97718834b6ce8ae7c498864";