import type { JobClaimStatus$options } from '$houdini/graphql'
import { toDate, sub, isAfter, type Duration } from 'date-fns'

export interface JobClaimStats {
	jobClaimStatus: JobClaimStatus$options
	job: {
		completed: Date | null
		transaction: {
			cost: string
			tip: string | null
		} | null
	}
	allActivity:
		| {
				elapsedTime: number
		  }[]
		| null
}

export function providerStatCalculations(jobClaims: JobClaimStats[], dateRange: Duration) {
	const completedJobClaims = jobClaims.filter(jobClaim => jobClaim.jobClaimStatus === 'COMPLETED' && jobClaim.job.completed && isAfter(jobClaim?.job?.completed, toDate(sub(new Date(), dateRange))))

	const { hours, totalEarnings } = completedJobClaims.reduce(
		({ hours, totalEarnings }, jobClaim) => {
			if (!jobClaim.job.transaction || !jobClaim.allActivity) {
				return { hours, totalEarnings }
			}
			return {
				hours:
					hours +
					jobClaim.allActivity.reduce((acc, activity) => {
						return acc + activity?.elapsedTime
					}, 0),
				totalEarnings: totalEarnings + (parseFloat(jobClaim?.job?.transaction?.cost) + (parseFloat(jobClaim?.job?.transaction?.tip ?? '0') ?? 0)),
			}
		},
		{ hours: 0, totalEarnings: 0 }
	)

	return {
		totalEarnings,
		hours,
		numberOfJobs: completedJobClaims.length,
	}
}

export default providerStatCalculations
