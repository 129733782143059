export default {
    "name": "SetUserSetting",
    "kind": "HoudiniMutation",
    "hash": "b65469f015d39972c645abdf5808d3c4110fd38e0c65eb67535b6d7c6d7c2407",

    "raw": `mutation SetUserSetting($value: SettingChange!) {
  setUserSetting(value: $value)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setUserSetting": {
                "type": "Void",
                "keyRaw": "setUserSetting(value: $value)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "value": "SettingChange"
        },

        "types": {
            "SettingChange": {
                "category": "String",
                "name": "String",
                "newValue": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=53426e2e519a5a4b57d753103d7f6d27728d4f368fbe5693f26b7564f364830a";