export default {
    "name": "AnswerData",
    "kind": "HoudiniFragment",
    "hash": "3e1abc614aadc1bbebd35ee8911e37346c809eeb9e04bff6451c5c5fb9191fe8",

    "raw": `fragment AnswerData on Answer {
  id
  answer
  questionId
  tagId
  tag {
    ...TagData
    id
  }
  __typename
}

fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Answer",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "answer": {
                "type": "String",
                "keyRaw": "answer",
                "visible": true
            },

            "questionId": {
                "type": "PositiveInt",
                "keyRaw": "questionId",
                "visible": true
            },

            "tagId": {
                "type": "PositiveInt",
                "keyRaw": "tagId",
                "visible": true
            },

            "tag": {
                "type": "Tag",
                "keyRaw": "tag",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "prompt": {
                            "type": "String",
                            "keyRaw": "prompt",
                            "nullable": true,
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "tagPricings": {
                            "type": "TagPricing",
                            "keyRaw": "tagPricings",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "tagId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "tagId",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "priceAdjustment": {
                                        "type": "String",
                                        "keyRaw": "priceAdjustment",
                                        "visible": true
                                    },

                                    "pricingType": {
                                        "type": "PricingType",
                                        "keyRaw": "pricingType",
                                        "visible": true
                                    },

                                    "postPriceAdjustment": {
                                        "type": "Boolean",
                                        "keyRaw": "postPriceAdjustment",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "TagPricingsData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "TagData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=a6d70578b2300944e7544b32dc46e2a9cfefa14d77ccd2c0918fb8d626b72d40";