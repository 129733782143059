export default {
    "name": "MakeTag",
    "kind": "HoudiniMutation",
    "hash": "15a4fa7191d0ad2b8b5fb7acca3b32c4aaafd93bef461f861688b0f3601e062b",

    "raw": `mutation MakeTag($tag: NewTag!) {
  newTag(tag: $tag) {
    ...TagData
    id
  }
}

fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newTag": {
                "type": "Tag",
                "keyRaw": "newTag(tag: $tag)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "prompt": {
                            "type": "String",
                            "keyRaw": "prompt",
                            "nullable": true,
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "tagPricings": {
                            "type": "TagPricing",
                            "keyRaw": "tagPricings",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "tagId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "tagId",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "priceAdjustment": {
                                        "type": "String",
                                        "keyRaw": "priceAdjustment",
                                        "visible": true
                                    },

                                    "pricingType": {
                                        "type": "PricingType",
                                        "keyRaw": "pricingType",
                                        "visible": true
                                    },

                                    "postPriceAdjustment": {
                                        "type": "Boolean",
                                        "keyRaw": "postPriceAdjustment",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "TagPricingsData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "TagData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tag": "NewTag"
        },

        "types": {
            "NewTag": {
                "code": "NonEmptyString",
                "description": "String",
                "entityType": "EntityType",
                "name": "NonEmptyString",
                "prompt": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=650ac45d750fd01f6037696cdf26319687f210fe84399c3a837f9e33747ca57e";