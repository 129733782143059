<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Table, { Td, type Column, type UuidRowProps } from '@isoftdata/svelte-table'
	import DateRange from '@isoftdata/svelte-date-range'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import { upsert } from '@isoftdata/utility-array'
	import type { rangeFromDates } from '@isoftdata/utility-date-time'

	import {
		selectRegionWithProviders,
		saveRegionHelper,
		createNewRegionAreaHelper,
		loadRegionHelperSpecificRegion,
		loadRegionsWithAllTimeData,
		type RegionWithMetricsAndProviders,
		type RegionWithMetrics,
	} from 'utility/region-helper'
	import type { ZoneManagementZone } from 'utility/zones-helper'
	import { setGlobalSetting } from 'utility/setting-helper'
	import type { Mediator } from 'types/common'
	import timeZoneList from 'utility/time-zone'
	import stateList from 'utility/states'
	import countryList from 'utility/countries'
	import { toDisplay } from 'utility/format/format-percent'
	import formatCurrency from 'utility/format/format-currency'
	import AreaTable from './AreaTable.svelte'
	import ServicesTable from './ServicesTable.svelte'
	import ProvidersTable from './ProvidersTable.svelte'
	import ServiceOfferModal from './ServiceOfferModal.svelte'
	import Throbber from 'components/Throbber.svelte'

	import type { WritableDeep } from 'type-fest'
	import { parseISO } from 'date-fns'
	import { SettingType, type LoadRegionHelperServices$result } from '$houdini/index'
	import { getContext } from 'svelte'

	export let dates: { from: string; to: string }
	export let range: ReturnType<typeof rangeFromDates> & undefined
	export let regions: WritableDeep<RegionWithMetrics[]> = []
	export let services: LoadRegionHelperServices$result['services']['data']
	export let zones: ZoneManagementZone[]
	export let nonHomeRegionRatioSetting: number = 0.5
	export let selectedRegionId: number | undefined = undefined

	const mediator = getContext<Mediator>('mediator')

	const regionStatusList = [
		{ value: 'ACTIVE', label: 'Active' },
		{ value: 'INACTIVE', label: 'Inactive' },
	]

	let selectedRegion: RegionWithMetricsAndProviders | null = null
	let loadingRegionData = false
	let loadingRegionSubData = false

	let regionModalShow: boolean = false
	let editingRegion: boolean = false
	let regionModalRegionId: number | null = null
	let regionName: string = ''
	let regionDescription: string = ''
	let regionTimeZone: string | null = null
	let regionLatitude: number | null = null
	let regionLongitude: number | null = null
	let regionStatus: RegionWithMetrics['status'] = 'ACTIVE'
	let regionZoneId: number | null = null
	let regionSourceRegion: RegionWithMetrics | null = null
	let regionCloningRegion: boolean = false
	let regionErrorMessage: string = ''

	let regionAreaModalShow: boolean = false
	let city: string = ''
	let zip: string = ''
	let state: string = 'NE'
	let country: string = 'US'
	let regionAreaLatitude: number | null = null
	let regionAreaLongitude: number | null = null
	let regionAreaDescription: string = ''
	let regionAreaRegionId: number | undefined = undefined
	let regionAreaErrorMessage: string = ''

	let serviceOfferShow: boolean = false
	let editingServiceOffer: boolean = false
	let serviceOfferId: number | undefined = undefined
	let chargePerSquareFoot: number | null = null
	let chargeMinimum: number | null = null
	let chargeFixed: number | null = null
	let timeRange: string = 'SunriseSunset'
	let serviceId: number | undefined = undefined
	let regionId: number | undefined = undefined
	let serviceOfferErrorMessage: string = ''

	let showConfigModal: boolean = false

	let tableColumns: Column<RegionWithMetrics & UuidRowProps>[] = [
		{ property: 'name', name: 'Name' },
		{ property: 'description', name: 'Description' },
		{ property: 'regionMetricData[numberOfQuotes]', name: 'Quotes', title: 'Number of address quoted in the selected period', numeric: true },
		{ property: 'regionAllTimeMetricData[numberOfQuotes]', name: 'A.T. Quotes', title: 'Total number of address that can be quoted', numeric: true },
		{ property: 'regionMetricData[numberOfJobsCompleted]', name: 'Jobs', title: 'Number of jobs completed in the selected period', numeric: true },
		{ property: 'regionAllTimeMetricData[numberOfJobsCompleted]', name: 'A.T. Jobs', title: 'Cumulative number of jobs completed', numeric: true },
		{ property: 'regionMetricData[conversion]', name: 'Conversion', title: 'Percentage of parcels that have jobs in the selected period', numeric: true },
		{ property: 'regionAllTimeMetricData[conversion]', name: 'A.T. Conversion', title: 'Number of parcels that have jobs / Number of parcels created', numeric: true },
		{ property: 'regionMetricData[numberOfPerformingProviders]', name: 'Prov', title: 'Providers who completed at least one job in the selected period', numeric: true },
		{ property: 'regionMetricData[providerJobVolume]', name: 'EC', title: 'Estimated capacity of the region', numeric: true },
		{ property: 'regionMetricData[numberOfActiveProviders]', name: 'AP', title: 'Total number of activated providers', numeric: true },
		{ property: 'regionMetricData[numberOfProvidersHomeRegion]', name: 'PHR', title: 'Number of providers who have this region as their home region', numeric: true },
		{ property: 'regionMetricData[revenue]', name: 'Revenue', title: 'Revenue collected in the selected period', sortType: 'ALPHA_NUM', numeric: true },
		{ property: 'regionAllTimeMetricData[revenue]', name: 'A.T. Revenue', title: 'Total revenue collected in the region lifetime', sortType: 'ALPHA_NUM', numeric: true },
		{ property: 'status', name: 'Status' },
	]

	selectRegionOnLoad().catch(console.error)

	async function selectRegion(id: number) {
		if (selectedRegion?.id === id) {
			selectedRegion = null
			selectedRegionId = undefined
		} else {
			selectedRegionId = id
			loadingRegionSubData = true
			try {
				selectedRegion = await selectRegionWithProviders(id, regions, dates)
			} catch (err: unknown) {
				const error = err as Error
				mediator.call('showError', error, { message: 'An error occurred while loading the region', title: 'Error Loading Region' })
				console.error(error)
			} finally {
				loadingRegionSubData = false
			}
		}
	}

	async function selectRegionOnLoad() {
		if (regions.length > 0 && selectedRegionId) {
			await selectRegion(selectedRegionId)
			const cardElement = document.getElementById(`region-id-${selectedRegionId}`)
			if (cardElement) {
				cardElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}
		}
	}

	function openNewRegionModal(): void {
		regionModalShow = true
		editingRegion = false
		regionModalRegionId = null
		regionName = ''
		regionDescription = ''
		regionTimeZone = null
		regionLatitude = null
		regionLongitude = null
		regionStatus = 'ACTIVE'
		regionZoneId = null
		regionSourceRegion = null
		regionCloningRegion = false
	}

	function copyRegion(sourceRegion: RegionWithMetrics): void {
		regionModalShow = true
		editingRegion = false
		regionModalRegionId = null
		regionName = ''
		regionDescription = ''
		regionTimeZone = null
		regionLatitude = null
		regionLongitude = null
		regionStatus = 'ACTIVE'
		regionZoneId = sourceRegion.zoneId
		regionSourceRegion = sourceRegion
		regionCloningRegion = true
	}

	async function openEditRegionModal(regionId: number): Promise<void> {
		try {
			const region = await loadRegionHelperSpecificRegion(regionId)
			if (region) {
				regionModalShow = true
				editingRegion = true
				regionModalRegionId = region.id
				regionName = region.name
				regionDescription = region.description
				regionTimeZone = region.timeZone
				regionLatitude = region.latitude
				regionLongitude = region.longitude
				regionStatus = region.status
				regionZoneId = region.zoneId
				regionSourceRegion = null
				regionCloningRegion = false
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: 'An error occurred while loading the region', title: 'Error Loading Region' })
			console.error(error)
		}
	}

	function addRegionArea(): void {
		regionAreaModalShow = true
		regionAreaRegionId = selectedRegionId
		city = ''
		zip = ''
		state = 'NE' // maybe don't default anymore
		country = 'US'
		regionAreaLatitude = null
		regionAreaLongitude = null
		regionAreaDescription = ''
	}

	function openAddServiceOfferModal(): void {
		serviceOfferShow = true
		editingServiceOffer = false
		serviceOfferId = undefined
		chargePerSquareFoot = null
		chargeMinimum = null
		chargeFixed = null
		timeRange = 'SunriseSunset'
		serviceId = undefined
		regionId = selectedRegionId
	}

	function serviceOfferSaved(e: CustomEvent): void {
		const regionToUpdate = regions.find(region => region.id === e.detail.regionId)
		if (regionToUpdate?.offeredServices) {
			regionToUpdate.offeredServices = upsert(regionToUpdate.offeredServices, 'id', e.detail)
			regions = regions
		}
	}

	async function saveRegion(): Promise<void> {
		try {
			if (!regionZoneId) {
				throw new Error('Zone is required')
			}
			const savedRegion = await saveRegionHelper(
				{
					id: regionModalRegionId,
					name: regionName,
					editingRegion,
					description: regionDescription,
					timeZone: regionTimeZone,
					latitude: regionLatitude,
					longitude: regionLongitude,
					status: regionStatus,
					zoneId: regionZoneId,
					sourceRegion: regionSourceRegion,
					cloningRegion: regionCloningRegion,
				},
				dates.from,
				dates.to,
			)

			if (savedRegion) {
				const regionIndex = regions.findIndex(region => region.id === savedRegion.id)
				if (regionIndex > -1) {
					regions[regionIndex] = savedRegion
				} else {
					regions = [...regions, savedRegion]
				}
				await selectRegion(savedRegion.id)
				const regionRow = document.getElementById(`region-id-${savedRegion.id}`)
				if (regionRow) {
					regionRow.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: 'An error occurred while saving the region', title: 'Error Saving Region' })
			regionErrorMessage = error.message
			console.error(error)
		} finally {
			regionModalShow = false
			editingRegion = false
			regionModalRegionId = null
			regionName = ''
			regionDescription = ''
			regionTimeZone = null
			regionLatitude = null
			regionLongitude = null
			regionStatus = 'ACTIVE'
			regionZoneId = null
			regionSourceRegion = null
			regionCloningRegion = false
		}
	}

	async function createNewRegionArea(): Promise<void> {
		try {
			const newRegionArea = await createNewRegionAreaHelper({
				regionId: regionAreaRegionId,
				city,
				zip,
				state,
				country,
				description: regionAreaDescription,
			})

			if (newRegionArea) {
				const selectedRegionIndex = regions.findIndex(region => region.id === selectedRegionId)
				if (selectedRegionIndex > -1 && regions[selectedRegionIndex].containedCities) {
					regions[selectedRegionIndex].containedCities = upsert(regions[selectedRegionIndex].containedCities, 'id', newRegionArea)
				}
				regions = regions
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: 'An error occurred while saving the region area', title: 'Error Saving Region Area' })
			regionAreaErrorMessage = error.message
			console.error(error)
		} finally {
			regionAreaModalShow = false
			city = ''
			zip = ''
			state = 'NE'
			country = 'US'
			regionAreaLatitude = null
			regionAreaLongitude = null
			regionAreaDescription = ''
		}
	}

	async function loadRegionData(): Promise<void> {
		selectedRegion = null
		selectedRegionId = undefined
		loadingRegionData = true
		try {
			regions = await loadRegionsWithAllTimeData(parseISO(dates.from), parseISO(dates.to))
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: 'An error occurred while loading the region data', title: 'Error Loading Region Data' })
			console.error(error)
		}
		loadingRegionData = false
	}

	function openConfigModal(e: MouseEvent): void {
		showConfigModal = true
	}

	async function saveConfig() {
		try {
			await setGlobalSetting({
				category: 'Region Metrics',
				name: 'Non-Home Region Ratio',
				newValue: nonHomeRegionRatioSetting.toString(),
				settingType: SettingType.IMPORTANT_CONFIGURATION,
			})
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: 'An error occurred while saving the configuration', title: 'Error Saving Configuration' })
			console.error(error)
		}

		try {
			loadingRegionData = true
			regions = await loadRegionsWithAllTimeData(parseISO(dates.from), parseISO(dates.to))
			loadingRegionData = false
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: 'An error occurred while loading the region data', title: 'Error Loading Region Data' })
			console.error(error)
		}
		showConfigModal = false
	}
</script>

<div class="container-fluid mt-3">
	<div class="d-flex justify-content-between">
		<Button
			id="config-button"
			title="Configuration"
			outline
			iconClass="gear"
			on:click={openConfigModal}
		/>
		<Button
			id="new-region-button"
			title="Add New Region"
			iconClass="plus"
			on:click={openNewRegionModal}
		>
			Add New Region</Button
		>
	</div>
	<Table
		responsive
		filterEnabled
		showFilterLabel
		filterLabel="Filter Regions"
		columns={tableColumns}
		rows={regions}
		filterPlaceholder="Filter Regions"
		let:row
	>
		<svelte:fragment slot="no-rows">
			<tr>
				<td colspan="13">
					<p>No regions found</p>
				</td>
			</tr>
		</svelte:fragment>
		<svelte:fragment slot="header">
			<div class="d-flex align-items-baseline">
				<DateRange
					rangeLabel="Analytics Date Range"
					colClass="col-12 col-sm-4 mb-1"
					bind:dates
					bind:range
					on:change={loadRegionData}
				/>
			</div>
		</svelte:fragment>
		<tr
			id="region-id-{row.id}"
			class="cursor-pointer"
			class:table-dark={selectedRegionId === row.id}
			on:click={() => {
				selectRegion(row.id)
			}}
		>
			<Td
				property="name"
				class="font-weight-bold">{row.name}</Td
			>
			<Td property="description">{row.description}</Td>
			{#if !loadingRegionData}
				<Td property="regionMetricData[numberOfQuotes]">{row.regionMetricData.numberOfQuotes}</Td>
				<Td property="regionAllTimeMetricData[numberOfQuotes]">{row.regionAllTimeMetricData?.numberOfQuotes ?? 0}</Td>
				<Td property="regionMetricData[numberOfJobsCompleted]">{row.regionMetricData.numberOfJobsCompleted}</Td>
				<Td property="regionAllTimeMetricData[numberOfJobsCompleted]">{row.regionAllTimeMetricData?.numberOfJobsCompleted ?? 0}</Td>
				<Td property="regionMetricData[conversion]">{toDisplay(row.regionMetricData.conversion)}%</Td>
				<Td property="regionAllTimeMetricData[conversion]"
					>{row.regionAllTimeMetricData?.conversion ? `${toDisplay(row.regionAllTimeMetricData.conversion).toFixed(2).replace(/\.00$/, '')}%` : '0%'}</Td
				>
				<Td property="regionMetricData[numberOfPerformingProviders]">{row.regionMetricData.numberOfPerformingProviders}</Td>
				<Td property="regionMetricData[providerJobVolume]">{row.regionMetricData.providerJobVolume.toFixed(2).replace(/\.00$/, '')}</Td>
				<Td property="regionMetricData[numberOfActiveProviders]">{row.regionMetricData.numberOfActiveProviders}</Td>
				<Td property="regionMetricData[numberOfProvidersHomeRegion]">{row.regionMetricData.numberOfProvidersHomeRegion}</Td>
				<Td property="regionMetricData[revenue]">{formatCurrency(row.regionMetricData.revenue)}</Td>
				<Td property="regionAllTimeMetricData[revenue]">{row.regionAllTimeMetricData?.revenue ? formatCurrency(row.regionAllTimeMetricData.revenue) : '$0'}</Td>
			{:else}
				<Td property="regionMetricData[numberOfQuotes]"><span class="placeholder"></span></Td>
				<Td property="regionAllTimeMetricData[numberOfQuotes]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[numberOfJobsCompleted]"><span class="placeholder"></span></Td>
				<Td property="regionAllTimeMetricData[numberOfJobsCompleted]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[conversion]"><span class="placeholder"></span></Td>
				<Td property="regionAllTimeMetricData[conversion]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[numberOfPerformingProviders]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[providerJobVolume]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[numberOfActiveProviders]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[numberOfProvidersHomeRegion]"><span class="placeholder"></span></Td>
				<Td property="regionMetricData[revenue]"><span class="placeholder"></span></Td>
				<Td property="regionAllTimeMetricData[revenue]"><span class="placeholder"></span></Td>
			{/if}
			<Td property="status">
				{#if row.status === 'ACTIVE'}
					<div class="text-success">{row.status}</div>
				{:else}
					<div class="text-danger">{row.status}</div>
				{/if}
			</Td>
		</tr>
		{#if selectedRegionId === row.id}
			{#if loadingRegionSubData}
				<Throbber />
			{:else}
				<tr>
					<td colspan={tableColumns.length}>
						<div
							id="region-id-{row.id}"
							class="card"
						>
							<div class="card-header d-flex justify-content-between py-1 px-2">
								<h5 class="mb-0">{row.name}</h5>
								<div>
									<Button
										id="copy-region-button"
										title="Copy Region"
										iconClass="copy"
										size="xs"
										outline
										color="success"
										on:click={() => copyRegion(row)}
									>
										Create New Region with these Service Offers
									</Button>
									<Button
										id="edit-region-button"
										title="Edit Region"
										iconClass="pen"
										size="xs"
										on:click={() => openEditRegionModal(row.id)}>Edit Region</Button
									>
								</div>
							</div>
							<div class="card-body p-2">
								<div class="card">
									<div class="card-header d-flex justify-content-between py-1 px-2">
										<h5 class="mb-0">Areas in this Region</h5>
										<Button
											id="add-region-area-button"
											title="Add Region Area"
											iconClass="plus"
											size="xs"
											outline
											color="success"
											on:click={addRegionArea}>Add New Area</Button
										>
									</div>
									<div class="card-body p-0">
										<AreaTable containedCities={row.containedCities ?? []} />
									</div>
								</div>
								<div class="card mt-2">
									<div class="card-header d-flex justify-content-between py-1 px-2">
										<h5 class="mb-0">Services Offered</h5>
										<Button
											id="add-service-offer-button"
											title="Add Service Offer"
											iconClass="plus"
											size="xs"
											outline
											color="success"
											on:click={openAddServiceOfferModal}>Add Service Offer</Button
										>
									</div>
									<div class="card-body p-0">
										<ServicesTable
											{services}
											bind:serviceOfferShow
											bind:editingServiceOffer
											bind:serviceId
											bind:serviceOfferId
											bind:chargePerSquareFoot
											bind:chargeMinimum
											bind:chargeFixed
											bind:timeRange
											bind:regionId
											bind:serviceOfferErrorMessage
											regionServices={row.offeredServices ?? []}
											selectedRegionId={row.id}
											on:serviceOfferSaved={serviceOfferSaved}
										/>
									</div>
								</div>
								<div class="card mt-2">
									<div class="card-header d-flex justify-content-between py-1 px-2">
										<h5>Providers in Region</h5>
									</div>
									<div class="card-body p-0">
										<ProvidersTable providers={selectedRegion?.providers ?? []} />
									</div>
								</div>
							</div>
						</div>
					</td>
				</tr>
			{/if}
		{/if}
	</Table>
</div>

<Modal
	bind:show={regionModalShow}
	title={editingRegion ? 'Edit Region' : 'Add New Region'}
	cancelButtonText="Cancel"
	confirmButtonText={editingRegion ? 'Confirm' : 'Create Region'}
	confirmButtonDisabled={!regionName || !regionTimeZone || !regionLatitude || !regionLongitude}
	on:confirm={() => saveRegion()}
	on:close={() => (regionModalShow = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				id="region-name-input"
				title="Region Name"
				label="Name"
				bind:value={regionName}
				type="text"
				required
				validation={{
					value: regionName,
				}}
			/>
		</div>
		<div class="col-6">
			<Select
				id="region-time-zone-select"
				title="Region Time-Zone"
				label="Time-Zone"
				bind:value={regionTimeZone}
				showEmptyOption={false}
				required
				validation={{
					value: regionTimeZone,
				}}
			>
				{#each timeZoneList as timeZone}
					<option value={timeZone}>{timeZone}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Input
				id="region-latitude-input"
				title="Region Latitude"
				label="Latitude"
				bind:value={regionLatitude}
				type="number"
				required
				validation={{
					value: regionLatitude,
				}}
			/>
		</div>
		<div class="col-6">
			<Input
				id="region-longitude-input"
				title="Region Longitude"
				label="Longitude"
				bind:value={regionLongitude}
				type="number"
				required
				validation={{
					value: regionLongitude,
				}}
			/>
		</div>
		<div class="col-12">
			<Input
				id="region-description=input"
				title="Region Description"
				label="Description"
				bind:value={regionDescription}
				type="text"
			/>
		</div>
		<div class="col-6">
			<Select
				id="region-status-select"
				title="Region Status"
				label="Region Status"
				bind:value={regionStatus}
				showEmptyOption={false}
			>
				{#each regionStatusList as status}
					<option value={status.value}>{status.label}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Select
				id="region-zone-select"
				title="Region Zone"
				label="Zone"
				required
				validation={{
					value: regionZoneId,
				}}
				bind:value={regionZoneId}
				showEmptyOption={regionZoneId ? false : true}
			>
				{#each zones as zone}
					<option value={zone.id}>{zone.name}</option>
				{/each}
			</Select>
		</div>
	</div>
	{#if regionErrorMessage}
		<span class="text-danger text-smaller font-weight-bold">{regionErrorMessage}</span>
	{/if}
</Modal>

<Modal
	bind:show={regionAreaModalShow}
	title="Add New Area"
	cancelButtonText="Cancel"
	confirmButtonText="Add Region Area"
	on:confirm={() => createNewRegionArea()}
	on:close={() => (regionAreaModalShow = false)}
>
	<div class="form-row">
		<div class="col-6">
			<Input
				id="city-input"
				title="City"
				label="City"
				bind:value={city}
				type="text"
			/>
		</div>
		<div class="col-6">
			<Input
				id="zip-input"
				title="Zip Code"
				label="Zip Code"
				bind:value={zip}
				type="text"
			/>
		</div>
		<div class="col-6">
			<Select
				id="state-select"
				title="State"
				label="State"
				bind:value={state}
				showEmptyOption={false}
			>
				{#each stateList as state}
					<option value={state}>{state}</option>
				{/each}
			</Select>
		</div>
		<div class="col-6">
			<Select
				id="country-select"
				title="Country"
				label="Country"
				bind:value={country}
				showEmptyOption={false}
			>
				{#each countryList as country}
					<option value={country}>{country}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			<Input
				id="region-area-description-input"
				title="Region Area Description"
				label="Description"
				bind:value={regionAreaDescription}
				type="text"
			/>
		</div>
		<span class="text-info text-smaller">*The more fields are filled, the more specific the region area is.</span>
	</div>
	{#if regionAreaErrorMessage}
		<span class="text-danger text-smaller font-weight-bold">{regionAreaErrorMessage}</span>
	{/if}
</Modal>

<Modal
	bind:show={showConfigModal}
	title="Configuration"
	cancelButtonText="Close"
	confirmButtonText="Save"
	on:confirm={() => saveConfig()}
	on:close={() => (showConfigModal = false)}
	on:backdrop={() => (showConfigModal = false)}
>
	<Input
		id="non-home-region-ratio-input"
		title="Non-Home Region Ratio"
		label="Non Home Region Ratio"
		bind:value={nonHomeRegionRatioSetting}
		type="number"
	/>
</Modal>

<ServiceOfferModal
	bind:show={serviceOfferShow}
	bind:editingService={editingServiceOffer}
	bind:serviceId
	bind:serviceOfferId
	bind:chargePerSquareFoot
	bind:chargeMinimum
	bind:chargeFixed
	bind:timeRange
	bind:regionId
	bind:serviceOfferErrorMessage
	{services}
	on:serviceOfferSaved={serviceOfferSaved}
/>

<style>
	@keyframes gradientAnimation {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
	.placeholder {
		background: linear-gradient(270deg, #f8f9fa, #e0e0e0, #f8f9fa);
		background-size: 400% 400%;
		animation: gradientAnimation 3s ease infinite;
		display: inline-block;
		width: 100%;
		height: 1rem;
	}
</style>
