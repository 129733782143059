export default {
    "name": "ActiveWallet",
    "kind": "HoudiniQuery",
    "hash": "78c5b8655af9dfb6db5c37ad405294dbedcd10fb5cda32455af4f38bff0ef2a2",

    "raw": `query ActiveWallet {
  session {
    customer {
      activeWallet {
        id
        lastFour
        expirationMonth
        expirationYear
        cardType
      }
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "customer": {
                            "type": "Customer",
                            "keyRaw": "customer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "activeWallet": {
                                        "type": "CustomerWallet",
                                        "keyRaw": "activeWallet",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "lastFour": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "lastFour",
                                                    "visible": true
                                                },

                                                "expirationMonth": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "expirationMonth",
                                                    "visible": true
                                                },

                                                "expirationYear": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "expirationYear",
                                                    "visible": true
                                                },

                                                "cardType": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "cardType",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=033c3fb04e878d722ccffc534c10a1cbaddfee6b92e25903f554abf9593c10a8";