export default {
    "name": "orderCreatedJobQuery",
    "kind": "HoudiniQuery",
    "hash": "96714cf3a2a620f4b80ca21aa1faccc1a2db92a6e70bbba3b389e50024ccb645",

    "raw": `query orderCreatedJobQuery($jobId: PositiveInt!) {
  job(id: $jobId) {
    id
    residence {
      street
      city
      state
      zip
      id
    }
    specialInstructions
    requestedSchedule
    service {
      name
      id
    }
    transaction {
      total
      id
    }
    answers {
      id
      answer
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "job": {
                "type": "Job",
                "keyRaw": "job(id: $jobId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "residence": {
                            "type": "Residence",
                            "keyRaw": "residence",

                            "selection": {
                                "fields": {
                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "specialInstructions": {
                            "type": "String",
                            "keyRaw": "specialInstructions",
                            "nullable": true,
                            "visible": true
                        },

                        "requestedSchedule": {
                            "type": "Date",
                            "keyRaw": "requestedSchedule",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "transaction": {
                            "type": "Transaction",
                            "keyRaw": "transaction",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "total": {
                                        "type": "String",
                                        "keyRaw": "total",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "answer": {
                                        "type": "String",
                                        "keyRaw": "answer",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=3cc32b4b55315c0123dbae877024a71eca1f734c678743cb5d0616a58e49056c";