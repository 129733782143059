import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadRegionHelperRegions'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadRegionHelperRegionsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadRegionHelperRegionsStore",
			variables: false,
		})
	}
}

export async function load_LoadRegionHelperRegions(params) {
	await initClient()

	const store = new LoadRegionHelperRegionsStore()

	await store.fetch(params)

	return {
		LoadRegionHelperRegions: store,
	}
}
