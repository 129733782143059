export default {
    "name": "QuestionData",
    "kind": "HoudiniFragment",
    "hash": "adbc533fd2f1337b8d2d13884a2a95bb5202d8d31b0f20648f8d537807af355f",

    "raw": `fragment QuestionData on Question {
  id
  question
  description
  questionDataType
  answers {
    ...AnswerData
    id
  }
  __typename
}

fragment AnswerData on Answer {
  id
  answer
  questionId
  tagId
  tag {
    ...TagData
    id
  }
  __typename
}

fragment TagData on Tag {
  id
  code
  name
  description
  prompt
  entityType
  tagPricings {
    ...TagPricingsData
    id
  }
  __typename
}

fragment TagPricingsData on TagPricing {
  id
  tagId
  serviceId
  priceAdjustment
  pricingType
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Question",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "question": {
                "type": "String",
                "keyRaw": "question",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "questionDataType": {
                "type": "QuestionDataType",
                "keyRaw": "questionDataType",
                "visible": true
            },

            "answers": {
                "type": "Answer",
                "keyRaw": "answers",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "answer": {
                            "type": "String",
                            "keyRaw": "answer",
                            "visible": true
                        },

                        "questionId": {
                            "type": "PositiveInt",
                            "keyRaw": "questionId",
                            "visible": true
                        },

                        "tagId": {
                            "type": "PositiveInt",
                            "keyRaw": "tagId",
                            "visible": true
                        },

                        "tag": {
                            "type": "Tag",
                            "keyRaw": "tag",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "prompt": {
                                        "type": "String",
                                        "keyRaw": "prompt",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "tagPricings": {
                                        "type": "TagPricing",
                                        "keyRaw": "tagPricings",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "tagId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "tagId",
                                                    "visible": true
                                                },

                                                "serviceId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "serviceId",
                                                    "visible": true
                                                },

                                                "priceAdjustment": {
                                                    "type": "String",
                                                    "keyRaw": "priceAdjustment",
                                                    "visible": true
                                                },

                                                "pricingType": {
                                                    "type": "PricingType",
                                                    "keyRaw": "pricingType",
                                                    "visible": true
                                                },

                                                "postPriceAdjustment": {
                                                    "type": "Boolean",
                                                    "keyRaw": "postPriceAdjustment",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "TagPricingsData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "TagData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "AnswerData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=b54b008f4383a074d091e8e2ab8523a764196c5c4e0ce30ab92e783e340871e0";