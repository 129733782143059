<script lang="ts">
	import { Pagination, type Column, Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { Dropdown, DropdownItem } from '@isoftdata/svelte-dropdown'
	import Table from '@isoftdata/svelte-table'
	import Modal from '@isoftdata/svelte-modal'

	import {
		formatProviderData,
		updateProviderStatusMutation,
		detachFileFromProviderManagementMutation,
		getProviderData,
		type ProviderManagementService,
		type ProviderManagementZone,
		type ProviderManagementProvider,
		type ProviderManagementCache,
		type ProviderManagementRegion,
	} from 'utility/provider-management-helper'
	import { updateUserHomeRegion } from 'utility/profile-helper'
	import type { Mediator } from 'types/common'
	import ProviderServiceManagement from 'components/ProviderServiceManagement.svelte'
	import ProviderZoneManagement from 'components/ProviderZoneManagement.svelte'
	import ProviderCard from 'components/ProviderCard.svelte'
	import ProviderProfilePicture from 'components/ProviderProfilePicture.svelte'
	import ProviderRegionManagement from 'components/ProviderRegionManagement.svelte'

	import { ProviderApplicationStatus, ProviderStatus, type ProviderStatus$options } from '$houdini'
	import { getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	type CopyColumn = 'Mobile Number' | 'Email'

	export let services: ProviderManagementService[] = []
	export let zones: ProviderManagementZone[] = []
	export let regions: ProviderManagementRegion[] = []
	export let providerPaginationInfo: { totalItems: number; totalPages: number } = { totalItems: 0, totalPages: 1 }
	export let providerCache: ProviderManagementCache = {
		pages: [],
	}
	let page = 1
	export let pageSize = 12
	export let cards = true

	let tableColums: Column[] = [
		{
			property: 'id',
			name: 'ID',
		},
		{
			property: 'name',
			name: 'Name',
		},
		{
			property: 'email',
			name: 'Email',
		},
		{
			property: 'status',
			name: 'Status',
		},
		{
			property: 'phone',
			name: 'Phone',
		},
	]
	let profileModalOpen = false
	let selectedProvider: ProviderManagementProvider | undefined = undefined
	let statusFilter: ProviderStatus$options | null = null
	let zoneFilter: number | null = null
	let regionFilter: number | null = null
	let serviceFilter: number[] | null = null
	let filterChanged = false
	let servicesOpen = false
	let regionsOpen = false
	let zonesOpen = false
	let textFilter = ''

	let statusToTitleCase = (status: ProviderStatus$options) => {
		return status
			.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(' ')
	}

	$: currentRows = providerCache.pages[page - 1]?.providers ?? []
	$: availableRegionsToSelectedProvider = zones.filter(zone => selectedProvider?.userAccount.authorizedZones?.some(authorizedZone => authorizedZone.id === zone.id)).flatMap(zone => zone.regions)

	$: computedZonesFilter = regionFilter ? zones.filter(zone => (regionFilter ? zone.regions.map(region => region.id).includes(regionFilter) : true)) : zones
	$: computedRegionsFilter = zoneFilter ? regions.filter(region => zoneFilter === region.zoneId) : regions

	const textSearch = (event: Event) => {
		const target = event.target as HTMLInputElement
		textFilter = target.value
		filterChanged = true
		loadProviders()
	}

	async function deleteSelectedProfilePicture(provider: ProviderManagementProvider) {
		if (!provider.profilePictureFile) return
		try {
			await detachFileFromProviderManagementMutation.mutate({
				providerId: provider.id,
				fileId: provider.profilePictureFile?.id,
			})
			const providerIndex = currentRows.findIndex(p => p.id === provider.id)
			if (providerIndex !== -1) {
				currentRows[providerIndex].profilePictureFile = null
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: error.message, title: 'Failed to delete provider profile picture' })
			console.error(error)
		}
	}

	const updateProviderStatus = async (provider: ProviderManagementProvider, status: ProviderStatus$options) => {
		try {
			const res = await updateProviderStatusMutation.mutate({
				providerId: provider.id,
				status,
			})
			if (res.data?.setProviderStatus) {
				provider.status = status
				providerCache.pages[page - 1].providers = providerCache.pages[page - 1].providers.map(p => {
					if (p.id === provider.id) {
						return provider
					}
					return p
				})
			}
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: error.message, title: 'Failed to update provider status' })
			console.error(error)
		}
	}

	async function loadProviders() {
		if (providerCache.pages[page - 1]) {
			if (providerCache.pages[page - 1].retrivalTime.getTime() + 1000 * 60 * 5 > new Date().getTime() && !filterChanged) {
				return
			}
		}
		try {
			const zoneIds = zoneFilter ? [zoneFilter] : undefined
			const res = await getProviderData({ page, pageSize }, { status: statusFilter, zoneIds, serviceIds: serviceFilter, search: textFilter, regionId: regionFilter })

			const providers: ProviderManagementProvider[] = res.providers?.data.map(provider => {
				return {
					...provider,
					profilePictureFile: provider.profilePictureFile
						? {
								...provider.profilePictureFile,
								url: '', // This gets populated by formatProviderData and no reason to duplicate it here
							}
						: null,
				}
			})
			if (filterChanged) {
				providerCache.pages = []
			}
			providerCache.pages[page - 1] = {
				providers: formatProviderData(providers),
				retrivalTime: new Date(),
			}
			providerPaginationInfo = {
				totalItems: res.providers?.info?.totalItems ?? 0,
				totalPages: res.providers?.info?.totalPages ?? 1,
			}
			filterChanged = false
		} catch (err: unknown) {
			const error = err as Error
			mediator.call('showError', error, { message: error.message, title: 'Failed to load providers' })
			console.error(error)
		}
	}

	async function updateProviderHomeRegion(e: Event) {
		const target = e.target
		if (!(target instanceof HTMLSelectElement)) return
		const homeRegionId = parseInt(target.value)
		const newHomeRegion = regions.find(region => region.id === homeRegionId)
		if (selectedProvider && newHomeRegion) {
			try {
				await updateUserHomeRegion(selectedProvider.id, homeRegionId)
				selectedProvider.homeRegion = newHomeRegion
			} catch (err: unknown) {
				const error = err as Error
				mediator.call('showError', error, { message: error.message, title: 'Failed to update provider home region' })
				console.error(error)
			}
		}
	}

	function handleCopy(columnToCopy: CopyColumn) {
		if (!selectedProvider) {
			alert('Please select a provider to copy ' + columnToCopy)
			return
		}
		let textToCopy = ''
		switch (columnToCopy) {
			case 'Mobile Number':
				textToCopy = selectedProvider.userAccount.mobile?.slice(0, 2) === '+1' ? selectedProvider.userAccount.mobile?.slice(2) : (selectedProvider.userAccount.mobile ?? '')
				break
			case 'Email':
				textToCopy = selectedProvider.userAccount.email
				break
		}

		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy)
			alert(columnToCopy + ' copied to clipboard')
		} else {
			alert('No ' + columnToCopy + ' found for the selected provider')
		}
	}
</script>

<div class="form-row flex-xl-row-reverse mt-3">
	{#if selectedProvider}
		<div class="col-xl-4">
			<div class="card mb-1 w-100">
				<div class="card-header d-flex justify-content-between align-items-center">
					<h4 class="mb-0">
						{selectedProvider.userAccount.firstName}
						{selectedProvider.userAccount.lastName}
					</h4>
					{#if selectedProvider.status === 'PENDING_APPROVAL' || selectedProvider.status === 'DISABLED'}
						<Button
							id="approve-provider-button"
							title="Approve Provider"
							iconClass="check"
							disabled={selectedProvider.latestOnboardingApplication?.status !== ProviderApplicationStatus.COMPLETED}
							on:click={() => {
								if (selectedProvider && confirm('Are you sure you want to approve this provider?')) {
									updateProviderStatus(selectedProvider, ProviderStatus.APPROVED)
								}
							}}
						>
							Approve Provider</Button
						>
					{:else if selectedProvider.status === 'APPROVED'}
						<Button
							id="disable-provider-button"
							title="Disable Provider"
							iconClass="xmark"
							class="btn-danger"
							on:click={() => {
								if (selectedProvider && confirm('Are you sure you want to disable this provider?')) {
									updateProviderStatus(selectedProvider, ProviderStatus.DISABLED)
								}
							}}
						>
							Disable Provider</Button
						>
					{/if}
				</div>
				<div class="card-body">
					<div class="form-row">
						<div class="col-12">
							<ProviderServiceManagement
								provider={selectedProvider}
								serviceList={services}
								bind:servicesOpen
								on:updateProvider={e => {
									let updatedProviderIndex = providerCache.pages[page - 1].providers.findIndex(p => p.id === e.detail.provider.id)
									providerCache.pages[page - 1].providers[updatedProviderIndex] = e.detail.provider
								}}
								on:show={() => {
									regionsOpen = false
									zonesOpen = false
								}}
							/>
						</div>
						<div class="col-12">
							<ProviderZoneManagement
								bind:provider={selectedProvider}
								zoneList={zones}
								bind:zonesOpen
								on:show={() => {
									servicesOpen = false
									regionsOpen = false
								}}
							/>
						</div>
						<div class="col-12">
							<ProviderRegionManagement
								provider={selectedProvider}
								regionList={regions}
								bind:regionsOpen
								on:show={() => {
									servicesOpen = false
									zonesOpen = false
								}}
							/>
						</div>
						<div class="col-12">
							<Select
								title="Home Region"
								showEmptyOption={true}
								id="provider-home-region-select"
								label="Home Region"
								emptyText="Select Home Region"
								emptyValue={null}
								value={selectedProvider.homeRegion?.id ?? null}
								disabled={selectedProvider.status !== 'APPROVED'}
								on:change={e => {
									updateProviderHomeRegion(e)
								}}
							>
								{@const needToAddForcedRegion = !!selectedProvider.homeRegion?.id && !availableRegionsToSelectedProvider.map(region => region.id).includes(selectedProvider.homeRegion?.id)}
								{#each availableRegionsToSelectedProvider as region (region.id)}
									<option value={region.id}>{region.name}</option>
									{#if needToAddForcedRegion}
										<option value={selectedProvider.homeRegion?.id}>{selectedProvider.homeRegion?.name}</option>
									{/if}
								{/each}
							</Select>
						</div>
					</div>
				</div>
			</div>
		</div>
	{/if}
	<div
		class:col-xl-8={selectedProvider}
		class:col-xl-12={!selectedProvider}
	>
		<div class="card">
			<div class="card-header">
				<div class="form-row align-items-end">
					<div class="col-12 col-md-6 col-lg-2">
						<Select
							title="Status"
							showEmptyOption={true}
							id="provider-status-filter-select"
							label="Status"
							class="form-control"
							emptyText="All"
							bind:value={statusFilter}
							on:change={() => {
								filterChanged = true
								page = 1
								selectedProvider = undefined
								loadProviders()
							}}
						>
							{#each Object.values(ProviderStatus) as status}
								<option value={status}>{statusToTitleCase(status)}</option>
							{/each}
						</Select>
					</div>
					<div class="col-12 col-md-6 col-lg-2">
						<Select
							title="Zone"
							showEmptyOption={true}
							id="provider-zone-filter-select"
							label="Zone"
							class="form-control"
							emptyText="All"
							bind:value={zoneFilter}
							on:change={() => {
								filterChanged = true
								page = 1
								selectedProvider = undefined
								loadProviders()
							}}
						>
							{#each computedZonesFilter as zone (zone.id)}
								<option value={zone.id}>{zone.name}</option>
							{/each}
						</Select>
					</div>
					<div class="col-12 col-md-6 col-lg-2">
						<Select
							title="Region"
							showEmptyOption={true}
							id="provider-region-filter-select"
							label="Region"
							class="form-control"
							emptyText="All"
							bind:value={regionFilter}
							on:change={() => {
								filterChanged = true
								page = 1
								selectedProvider = undefined
								loadProviders()
							}}
						>
							{#each computedRegionsFilter as region (region.id)}
								<option value={region.id}>{region.name}</option>
							{/each}
						</Select>
					</div>
					<div class="col-12 col-md-6 col-lg-2">
						<Select
							title="Service"
							showEmptyOption={true}
							id="provider-service-filter-select"
							label="Service"
							class="form-control"
							emptyText="All"
							bind:value={serviceFilter}
							on:change={() => {
								filterChanged = true
								page = 1
								selectedProvider = undefined
								loadProviders()
							}}
						>
							{#each services as service (service.id)}
								<option value={service.id}>{service.name}</option>
							{/each}
						</Select>
					</div>
					<div class="col-12 col-md-6 col-lg-3">
						<Input
							id="provider-text-search-input"
							title="Search"
							label="Search"
							value={textFilter}
							type="text"
							placeholder="Search"
							on:blur={textSearch}
						/>
					</div>
					<div class="col-12 col-md-6 col-lg-1 mb-1 mt-2">
						<Checkbox
							inline
							id="provider-cards-checkbox"
							title="Cards/Table"
							showLabel={false}
							radioButtonColor="primary"
							type="radio"
							trueIcon="table"
							falseIcon="list"
							trueLabel=""
							falseLabel=""
							bind:checked={cards}
							on:change={async e => {
								page = 1
								selectedProvider = undefined
							}}
						/>
					</div>
				</div>
			</div>
			{#if cards}
				<div class="card-body">
					<div class="card-columns">
						{#each currentRows as row (row.id)}
							<ProviderCard
								provider={row}
								bind:selectedProvider
							/>
						{/each}
					</div>

					<Pagination
						items={providerCache.pages[page - 1]?.providers ?? []}
						currentPageItems={currentRows}
						totalItemsCount={providerPaginationInfo.totalItems}
						lastPageNumber={providerPaginationInfo.totalPages}
						perPageCount={pageSize}
						currentPageNumber={page}
						on:pageChange={e => {
							page = e.detail.pageNumber
							selectedProvider = undefined
							loadProviders()
						}}
					/>
				</div>
			{:else}
				<Table
					responsive
					stickyHeader
					columnHidingEnabled
					parentStyle="max-height: 70vh; overflow-y: auto;"
					columns={tableColums}
					bind:rows={currentRows}
					filterEnabled={false}
					selectionEnabled
					selectionMode="SINGLE"
					rowSelectionIdProp="id"
					paginationEnabled
					perPageCount={pageSize}
					currentPageNumber={page}
					totalItemsCount={providerPaginationInfo.totalItems}
					on:pageChange={e => {
						page = e.detail.pageNumber
						selectedProvider = undefined
						loadProviders()
					}}
				>
					<svelte:fragment
						slot="body"
						let:rows
					>
						{#each rows as row (row.id)}
							<tr
								class:table-primary={selectedProvider === row}
								on:click={() => {
									if (selectedProvider === row) {
										selectedProvider = undefined
									} else {
										selectedProvider = row
									}
								}}
							>
								<Td property="id">{row.id}</Td>
								<Td
									property="name"
									stopPropagation
								>
									<div class="d-flex align-items-center">
										<ProviderProfilePicture
											clickable
											profilePictureUrl={row.profilePictureFile?.url ?? null}
											pictureSize={40}
											showFloatingButton={false}
											on:click={() => {
												if (row.profilePictureFile?.path) {
													selectedProvider = row
													profileModalOpen = true
												}
											}}
										/>
										{row.userAccount.firstName}
										{row.userAccount.lastName}
									</div>
								</Td>
								<Td
									property="email"
									stopPropagation
								>
									<a
										href="mailto:{row.userAccount.email}"
										class:text-dark={selectedProvider === row}
									>
										{row.userAccount.email}
									</a>
								</Td>
								<Td property="status">{statusToTitleCase(row.status)}</Td>
								<Td
									property="phone"
									stopPropagation
								>
									{#if row.userAccount.mobile}
										<a
											href="tel:{row.userAccount.mobile}"
											class:text-dark={selectedProvider === row}
										>
											{row.userAccount.mobile}
										</a>
									{:else}
										-
									{/if}
								</Td>
							</tr>
						{/each}
					</svelte:fragment>
				</Table>
			{/if}
			<div class="card-footer">
				<Dropdown
					parentDivClass="mt-1"
					size="sm"
					iconClass="copy"
					outline
					disabled={!selectedProvider}
				>
					Copy Selected Provider's
					<svelte:fragment slot="dropdownItems">
						<DropdownItem
							icon="mobile-screen"
							on:click={() => {
								handleCopy('Mobile Number')
							}}
						>
							Mobile Number
						</DropdownItem>
						<DropdownItem
							icon="envelope"
							on:click={() => {
								handleCopy('Email')
							}}
						>
							Email
						</DropdownItem>
					</svelte:fragment>
				</Dropdown>
			</div>
		</div>
	</div>
</div>

<Modal
	title="Provider Profile Picture"
	bind:show={profileModalOpen}
	confirmButtonColor="danger"
	confirmButtonText="Delete Profile Picture"
	cancelShown={false}
	on:close={() => {
		profileModalOpen = false
	}}
	on:confirm={() => {
		if (selectedProvider && confirm("Are you sure you want to delete this provider's profile picture?")) {
			deleteSelectedProfilePicture(selectedProvider)
			profileModalOpen = false
		}
	}}
>
	<!-- svelte-ignore a11y-img-redundant-alt -->
	<img
		src={selectedProvider?.profilePictureFile?.url ?? ''}
		alt="Profile Picture"
		class="img-fluid"
	/>
</Modal>
