export default {
    "name": "LoadJobClaimActivity",
    "kind": "HoudiniQuery",
    "hash": "34fff0b11b04b561bce9c24d5ae2237643cb665287093a3edef9d256efa1d528",

    "raw": `query LoadJobClaimActivity($jobClaimId: PositiveInt!) {
  jobClaim(id: $jobClaimId) {
    id
    jobClaimStatus
    scheduledAt
    cancellationReason
    allActivity {
      id
      elapsedTime
      end
      start
    }
    job {
      id
      requestedSchedule
      specialInstructions
      residence {
        latitude
        longitude
        answers {
          tag {
            id
            name
          }
          id
        }
        id
      }
      service {
        name
        maximumDaysToPerform
        id
      }
      answers {
        id
        answer
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "jobClaim": {
                "type": "JobClaim",
                "keyRaw": "jobClaim(id: $jobClaimId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "jobClaimStatus": {
                            "type": "JobClaimStatus",
                            "keyRaw": "jobClaimStatus",
                            "visible": true
                        },

                        "scheduledAt": {
                            "type": "DateTime",
                            "keyRaw": "scheduledAt",
                            "visible": true
                        },

                        "cancellationReason": {
                            "type": "String",
                            "keyRaw": "cancellationReason",
                            "nullable": true,
                            "visible": true
                        },

                        "allActivity": {
                            "type": "JobClaimActivity",
                            "keyRaw": "allActivity",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "elapsedTime": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "elapsedTime",
                                        "visible": true
                                    },

                                    "end": {
                                        "type": "DateTime",
                                        "keyRaw": "end",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "start": {
                                        "type": "DateTime",
                                        "keyRaw": "start",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "job": {
                            "type": "Job",
                            "keyRaw": "job",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "requestedSchedule": {
                                        "type": "Date",
                                        "keyRaw": "requestedSchedule",
                                        "visible": true
                                    },

                                    "specialInstructions": {
                                        "type": "String",
                                        "keyRaw": "specialInstructions",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "residence": {
                                        "type": "Residence",
                                        "keyRaw": "residence",

                                        "selection": {
                                            "fields": {
                                                "latitude": {
                                                    "type": "Latitude",
                                                    "keyRaw": "latitude",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "longitude": {
                                                    "type": "Longitude",
                                                    "keyRaw": "longitude",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "answers": {
                                                    "type": "Answer",
                                                    "keyRaw": "answers",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "tag": {
                                                                "type": "Tag",
                                                                "keyRaw": "tag",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "maximumDaysToPerform": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "maximumDaysToPerform",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobClaimId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=240f1cbf42dd7fb20e49f84a839433bfba78b7352478de0f80dded309c68ceb7";