<script lang="ts">
	import { graphql } from '$houdini'
	import Button from '@isoftdata/svelte-button'
	import type { SvelteAsr } from 'client/types/common'
	import { onMount } from 'svelte'

	const verifyEmailMutation = graphql(`
		mutation verifyUserAccount($verificationToken: NonEmptyString!, $userId: PositiveInt!) {
			verifyUserAccount(verificationToken: $verificationToken, userId: $userId) {
				email
			}
		}
	`)

	export let asr: SvelteAsr
	export let userId: string
	export let verificationToken: string
	let verifiedAccountEmail: string

	let error: string | null = null

	async function verifyEmail() {
		try {
			const { data } = await verifyEmailMutation.mutate({
				verificationToken,
				userId: parseInt(userId, 10),
			})
			if (!data) {
				error = 'An unknown error occurred while verifying your email. Please contact Lawn Hiro support.'
				throw new Error(error)
			}
			verifiedAccountEmail = data.verifyUserAccount.email
		} catch (err) {
			console.error(err)
			alert((err as Error)?.message || 'An unknown error occurred while verifying your email. Please contact Lawn Hiro support.')
		}
	}

	function gotoLogin() {
		asr.go('login', { from: 'email-verification', email: verifiedAccountEmail ?? '' })
	}

	onMount(async () => {
		await verifyEmail()
	})
</script>

<div class="container d-flex justify-content-center mt-3">
	<div class="card text-center">
		{#if !!error}
			<div class="card-header">
				<h3 class="mb-0">Error</h3>
			</div>
			<div class="card-body">
				<span>{error}</span>
			</div>
		{:else}
			<div class="card-header">
				<h3 class="mb-0">Email Verified</h3>
			</div>
			<div class="card-body">
				<span>Thank you for verifying your email address.</span><br />
				<Button
					class="mt-3"
					color="primary"
					on:click={gotoLogin}
				>
					Login
				</Button>
			</div>
		{/if}
	</div>
</div>
