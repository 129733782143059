export function formatDuration(seconds: number | string) {
	seconds = typeof seconds === 'string' ? parseInt(seconds, 10) : seconds

	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(seconds / 60 / 60)
	const reminderMinutes = minutes % 60
	seconds = seconds % 60
	seconds = Math.floor(seconds)

	if (hours) {
		const formattedHours = formatHours(hours)

		//if the remiaining minutes is 0, we don't need to show it
		if (reminderMinutes > 0) {
			const formattedMinutes = formatMinutes(reminderMinutes)
			return `${formattedHours} ${formattedMinutes}`
		} else {
			return formattedHours
		}
	} else if (minutes) {
		return formatMinutes(minutes)
	} else {
		return formatSeconds(seconds)
	}
}

export function formatHours(hours: number) {
	return new Intl.NumberFormat(navigator.language || 'en-US', {
		style: 'unit',
		unit: 'hour',
		unitDisplay: 'short',
	}).format(hours)
}

export function formatMinutes(minutes: number) {
	return new Intl.NumberFormat(navigator.language || 'en-US', {
		style: 'unit',
		unit: 'minute',
		unitDisplay: 'short',
	}).format(minutes)
}

export function formatSeconds(seconds: number) {
	return new Intl.NumberFormat(navigator.language || 'en-US', {
		style: 'unit',
		unit: 'second',
		unitDisplay: 'short',
	}).format(seconds)
}

export default formatDuration
