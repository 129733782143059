import artifact from '$houdini/artifacts/UpdateProviderOnboardingApplicationTransportationStatus'
import { MutationStore } from '../runtime/stores/mutation'

export class UpdateProviderOnboardingApplicationTransportationStatusStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
