import component from './Zone.svelte'

import { AppContext } from 'types/common'

import { loadZones, loadStates } from 'utility/zones-helper'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.location.zone',
		route: 'zone',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const [zones, states] = await Promise.allSettled([
				loadZones(undefined, {
					pageNumber: 1,
					pageSize: 0,
				}),
				loadStates(),
			]).then(results => {
				const zones = results[0].status === 'fulfilled' ? results[0].value : []
				const states = results[1].status === 'fulfilled' ? results[1].value : []

				return [zones, states]
			})

			return {
				zones,
				states,
			}
		},
	})
}
