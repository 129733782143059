import { LoadWeatherStore, graphql } from '$houdini/index'
import { eachHourOfInterval, getHours, isToday, isTomorrow, format, startOfToday, endOfToday } from 'date-fns'
import { timeRangeList } from './time-range-list'

//TODO: Hit an api like https://api.sunrise-sunset.org/json?lat=36.7201600&lng=-4.4203400&formatted=0
//to find the last valid work hour of a day based on daylight avaliable

const weatherQuery: LoadWeatherStore = graphql`
	query LoadWeather($location: WeatherLocation) {
		weather(location: $location) {
			sunrise
			sunset
		}
	}
`

export type TimeingHour = {
	date: Date
	relativeDay: string
	hour: string
}

export type ValidJobHours = {
	days: Record<string, TimeingHour[]>
	sunrise: string
	sunset: string
}

export async function getValidJobDates(lat: number, long: number, startDate: Date, endDate: Date, timeRange?: string) {
	let firstWorkHourOfDay: number
	let lastWorkHourOfDay: number

	const { data } = await weatherQuery.fetch({
		variables: {
			location: {
				latitude: lat,
				longitude: long,
				when: startDate,
			},
		},
	})

	if (!data?.weather) {
		throw new Error('Failed to load weather data')
	}

	if (timeRange === timeRangeList[0].value) {
		firstWorkHourOfDay = getHours(startOfToday())
		lastWorkHourOfDay = getHours(endOfToday())
	} else {
		firstWorkHourOfDay = getHours(data.weather.sunrise)
		lastWorkHourOfDay = getHours(data.weather.sunset)
	}

	const allHours = eachHourOfInterval({
		start: startDate,
		end: endDate,
	})
		.filter(date => {
			const hourOfDay = getHours(date)

			return hourOfDay > firstWorkHourOfDay && hourOfDay < lastWorkHourOfDay
		})
		.map(date => {
			let dayString: string
			if (isToday(date)) {
				dayString = 'Today'
			} else if (isTomorrow(date)) {
				dayString = 'Tomorrow'
			} else {
				dayString = date.toLocaleDateString('en-US')
			}

			return {
				date,
				relativeDay: dayString,
				hour: format(date, 'h:mm a'),
			}
		})

	const sunriseSunsetHours = allHours.reduce<ValidJobHours>(
		(acc, hour) => {
			if (acc.days[hour.relativeDay]) {
				acc.days[hour.relativeDay] = [...acc.days[hour.relativeDay], hour]
			} else {
				acc.days[hour.relativeDay] = [hour]
			}
			return acc
		},
		{
			days: {},
			sunrise: format(data.weather.sunrise, 'h:mm a'),
			sunset: format(data.weather.sunset, 'h:mm a'),
		}
	)

	const twentyFourHourHours = allHours.reduce<ValidJobHours>(
		(acc, hour) => {
			if (acc.days[hour.relativeDay]) {
				acc.days[hour.relativeDay] = [...acc.days[hour.relativeDay], hour]
			} else {
				acc.days[hour.relativeDay] = [hour]
			}
			return acc
		},
		{
			days: {},
			sunrise: format(getHours(startOfToday()), 'h:mm a'),
			sunset: format(getHours(endOfToday()), 'h:mm a'),
		}
	)

	if (timeRange === timeRangeList[0].value) {
		return twentyFourHourHours
	} else {
		return sunriseSunsetHours
	}
}

export default getValidJobDates
