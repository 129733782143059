<script lang="ts">
	import Modal from '@isoftdata/svelte-modal'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import { slide } from 'svelte/transition'
	import { tick } from 'svelte'

	export let error: Error | null = null
	export let message = ''
	export let title = 'Encountered an Error'

	let copied = false
	let showDetails = false
	let shown = false

	export async function show(options: { error: Error; message?: string; title?: string }) {
		shown = true
		error = options.error
		message = options.message ?? message
		title = options.title ?? title
		await tick()
		document.querySelector<HTMLButtonElement>('#confirm-modal')?.focus()
	}

	export function close() {
		shown = false
		error = null
		message = ''
		copied = false
		showDetails = false
		title = 'Encountered an Error'
	}

	function copy() {
		navigator.clipboard.writeText(advancedDetails)
		copied = true
	}

	function buildAdvancedDetails(error: Error | null) {
		let string = `Application Version: __buildVersion__ (__buildEnv__) \r\nBuild Date: __buildDate__\r\nUser Agent: ${navigator.userAgent}\r\n${error?.message}\r\n`
		if (error?.stack) {
			string += `\r\n${error.stack}`
		}
		return string
	}

	function buildMailToLink(title, advancedDetails) {
		const subject = encodeURIComponent(`LawnHiro Error Report: ${title}`)
		const body = encodeURIComponent(`Error details:\r\n\r\n${advancedDetails}`)
		// As of 7/12/23, this email address goes to Jake and Matthew.
		return `mailto:support@lawnhiro.com?body=${body}&subject=${subject}`
	}

	$: advancedDetails = buildAdvancedDetails(error)
	$: emailLink = buildMailToLink(title, advancedDetails)
</script>

<Modal
	bind:show={shown}
	{title}
	confirmButtonIcon={copied ? 'check' : 'copy'}
	cancelButtonIcon={null}
	confirmButtonText={copied ? 'Copied!' : 'Copy to Clipboard'}
	cancelButtonText="OK"
	zIndex={1000000}
	on:confirm={copy}
	on:close={close}
>
	<strong>{message || error?.message}</strong>
	<p>
		If the problem persists, please contact <a href={emailLink}>Lawnhiro support</a>.
	</p>
	<p>You can send us the error details by clicking "Copy to Clipboard" and sending it in your message.</p>
	<Checkbox
		label="Show details"
		bind:checked={showDetails}
	/>
	{#if showDetails}
		<div
			transition:slide={{ duration: 200 }}
			class="bg-light px-1 pt-1"
		>
			<pre>{advancedDetails}</pre>
		</div>
	{/if}
</Modal>
