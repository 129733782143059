export default {
    "name": "LoadRegionHelperServices",
    "kind": "HoudiniQuery",
    "hash": "6ca6db0843d8999c8c23d472981acfd1fa91874608eb4cd3229c766a40429609",

    "raw": `query LoadRegionHelperServices($pagination: PaginatedInput) {
  services(pagination: $pagination) {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=9e9ef5b98d424d3fed23fcd6c4bfb511fada9019401f5bfe73202e13d7a372e1";