export default {
    "name": "JobForPhotoUpload",
    "kind": "HoudiniQuery",
    "hash": "28e4be020c988bd1b3d425212ed81f1c88d7dfce2c23eea0932e60638982d56e",

    "raw": `query JobForPhotoUpload($jobId: PositiveInt!) {
  job(id: $jobId) {
    files {
      fileId
      public
      rank
      imageFileType
      file {
        name
        created
        mimeType
        path
        id
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "job": {
                "type": "Job",
                "keyRaw": "job(id: $jobId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "files": {
                            "type": "JobFile",
                            "keyRaw": "files",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "fileId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "fileId",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "imageFileType": {
                                        "type": "ImageFileType",
                                        "keyRaw": "imageFileType",
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "FileEntity",
                                        "keyRaw": "file",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "jobId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7e85fdefcec9285b40e532a070cb0f5f6e76047860c6c8816c10e2983daafaf8";