export default {
    "name": "LoadWeather",
    "kind": "HoudiniQuery",
    "hash": "3647a6c35015402c1bab0cdeb012d85c9849585e97ac03d4c6c501c36457bda7",

    "raw": `query LoadWeather($location: WeatherLocation) {
  weather(location: $location) {
    sunrise
    sunset
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "weather": {
                "type": "Weather",
                "keyRaw": "weather(location: $location)",

                "selection": {
                    "fields": {
                        "sunrise": {
                            "type": "DateTime",
                            "keyRaw": "sunrise",
                            "visible": true
                        },

                        "sunset": {
                            "type": "DateTime",
                            "keyRaw": "sunset",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "location": "WeatherLocation"
        },

        "types": {
            "WeatherLocation": {
                "latitude": "Latitude",
                "longitude": "Longitude",
                "when": "Date"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=990d380505ade0ae1811c8dcf8ccbe373d18c162c18052cbcbf4cd7e9211a342";