import { stringToBoolean } from '@isoftdata/utility-string'
import template from './detail.ractive.html'
import financialNumber from 'financial-number'
import formatCurrency from 'utility/format/format-currency'

//Ractive components
import makeButton from '@isoftdata/button'
import makeOrderDetail from 'components/customer-order-detail'
import makeModal from '@isoftdata/modal'
import makeTextArea from '@isoftdata/textarea'
import makeInput from '@isoftdata/input'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeNewCardEntry from 'components/credit-card-entry'
import makeCardPicker from 'components/card-picker'

const tipAmountOptions = [
	{ label: '15%', value: 0.15 },
	{ label: '20%', value: 0.20 },
	{ label: '25%', value: 0.25 },
	{ label: 'Custom', value: 'custom' },
]

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.customer.order-history.detail',
		route: 'detail',
		querystringParameters: [ 'jobId', 'leaveTip' ],
		template: {
			template,
			components: {
				itButton: makeButton(),
				orderDetail: makeOrderDetail(mediator),
				itModal: makeModal(),
				itTextArea: makeTextArea({ twoway: true, lazy: 300 }),
				itInput: makeInput(),
				itCurrencyInput: makeCurrencyInput({ twoway: true, lazy: true }),
				newOrderCreditCardEntry: makeNewCardEntry(),
				newCardEntry: makeNewCardEntry(),
				cardPicker: makeCardPicker(),
			},
			computed: {
				computedTipAmountOptions() {
					const selectedJobTotal = this.get('job')?.transaction?.total
					if (!selectedJobTotal) {
						return []
					}
					const formattedTipAmountOptions = tipAmountOptions.map(option => {
						return {
							...option,
							amount: option.label === 'Custom' ? 'custom' : financialNumber(selectedJobTotal).times(option.value.toString()).changePrecision(2).toString(),
						}
					})
					this.set({ selectedTip: formattedTipAmountOptions[0].amount })
					return formattedTipAmountOptions
				},
				tipLabelWidth() {
					return Math.floor(100 / this.get('computedTipAmountOptions').length || 1) || 100
				},
			},
			async openReviewModal() {
				await this.set({ showReviewModal: true })
				this.find('#reviewTextArea')?.focus()
			},
			closeReviewModal() {
				this.set({ showReviewModal: false, review: '' })
			},
			openTipModal() {
				stateRouter.go(null, { jobId: this.get('selectedJobId'), leaveTip: true }, { inherit: true, replace: true })
			},
			closeTipModal() {
				stateRouter.go(null, { jobId: this.get('selectedJobId'), leaveTip: false }, { inherit: true, replace: true })
			},
			formatCurrency,
			async submitTip(cardInfo, customerWalletId) {
				this.set({ isProcessing: true })
				this.set({ submitButtonText: 'Processing...' })
				const selectedTip = this.get('selectedTip')
				const tipAmount = this.get('tipAmount')
				const token = cardInfo.token

				const tipJobMutation = `#graphql
					mutation TipJob($tip: TipInput!, $review: String) {
						tipJob(tip: $tip, review: $review) {
							id
							residence {
								street
								city
								state
								zip
								country
								googleMapsPlaceId
							}
							jobStatus
							requestedSchedule
							completed
							transaction {
								total
								tip
							}
							jobClaims {
								provider {
									userAccount {
										fullName
									}
								}
								jobClaimStatus
								scheduledAt
							}
							review
						}
					}
				`

				try {
					const { tipJob } = await mediator.call('apiFetch', tipJobMutation, {
						tip: {
							jobId: this.get('selectedJobId'),
							...(customerWalletId ? { customerWalletId } : { token }), //if we have a customer wallet id, we don't need a token. Just one or the other is required
							amount: selectedTip === 'custom' ? tipAmount : selectedTip,
						},
						review: this.get('review') || null,
					})

					this.set({ job: tipJob })
					this.closeTipModal()
				} catch (err) {
					console.error(err?.message)
				} finally {
					this.set({ isProcessing: false })
				}
			},
			async submitReview() {
				this.set({ isProcessing: true })
				this.set({ submitButtonText: 'Processing...' })
				const reviewJobMutation = `#graphql
					mutation ReviewJob($jobId: PositiveInt!, $review: String) {
						reviewJob(jobId: $jobId, review: $review) {
							id
							residence {
								street
								city
								state
								zip
								country
								googleMapsPlaceId
							}
							jobStatus
							requestedSchedule
							completed
							transaction {
								total
								tip
							}
							jobClaims {
								provider {
									userAccount {
										fullName
									}
								}
								jobClaimStatus
								scheduledAt
							}
							review
						}
					}
				`

				try {
					const { reviewJob } = await mediator.call('apiFetch', reviewJobMutation, {
						jobId: this.get('selectedJobId'),
						review: this.get('review'),
					})
					this.set({ job: reviewJob })

					this.set({
						review: '',
						showReviewModal: false,
					})
				} catch (err) {
					console.error(err?.message)
				} finally {
					this.set({ isProcessing: false })
				}
			},
			async addCardToWallet(cardInfo) {
				//They want to save the card to their wallet for future use
				const addCardToCustomerWalletMutation = `#graphql
					mutation AddCardToCustomerWallet($newCard: NewCustomerCard!) {
						addCardToCustomerWallet(newCard: $newCard) {
							id
							lastFour
							expirationMonth
							expirationYear
							cardType
						}
					}
				`

				try {
					const { addCardToCustomerWallet } = await mediator.call('apiFetch', addCardToCustomerWalletMutation, {
						newCard: {
							lastFour: parseInt(cardInfo.cardLast4, 10),
							expirationMonth: parseInt(cardInfo.expiryMonth, 10),
							expirationYear: parseInt(cardInfo.expiryYear, 10),
							token: cardInfo.token,
							cardType: cardInfo.cardType,
						},
					})

					this.push('cards', addCardToCustomerWallet)
					return addCardToCustomerWallet
				} catch (err) {
					console.error(err)
					alert(err?.message || 'An unknown error occurred while adding your card to your wallet. Please contact LawnHiro support.')
				}
			},
		},
		async resolve(_data, parameters) {
			const jobQuery = `#graphql
				query Job($jobId: PositiveInt!) {
					job(id: $jobId) {
						id
						residence {
							street
							city
							state
							zip
							country
							googleMapsPlaceId
						}
						jobStatus
						requestedSchedule
						completed
						transaction {
							total
							tip
							paymentStatus
							internalNotes
						}
						jobClaims {
							provider {
								userAccount {
									fullName
								}
								profilePictureFile {
									path
								}
							}
							jobClaimStatus
							scheduledAt
						}
						review
						service {
							name
						}
						answers {
							id
							answer
						}
						files {
							id
							fileId
							rank
							public
							imageFileType
							file{
								id
								name
								created
								updated
								hash
								path
								type
								mimeType
							}
						}
					}
				}
			`

			const activeWalletQuery = `#graphql
				query ActiveWallet {
					session {
						customer {
							activeWallet {
								id
								lastFour
								expirationMonth
								expirationYear
								cardType
							}
						}
					}
				}
			`
			const [ walletRes, jobRes ] = await Promise.all([
				mediator.call('apiFetch', activeWalletQuery, {}),
				mediator.call('apiFetch', jobQuery, { jobId: parameters.jobId }),
			])

			return {
				job: jobRes?.job,
				selectedJobId: parameters.jobId,
				totalForSelectedJob: formatCurrency(jobRes?.job?.transaction?.total),
				review: jobRes?.job?.review || null,
				selectedTip: 0,
				tipAmount: 0,
				checked: true,
				showTipModal: (stringToBoolean(parameters.leaveTip) && !jobRes?.job.transaction.tip) || false,
				showReviewModal: false,
				cards: walletRes?.session?.customer?.activeWallet || [],
				selectedCardId: walletRes?.session?.customer?.activeWallet?.[0]?.id || null,
				isProcessing: false,
				submitButtonText: 'Submit',
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext

			ractive.observe('selectedTip', selectedTip => {
				ractive.set({ submitButtonText: selectedTip === 'custom' ? `Tip ${formatCurrency(ractive.get('tipAmount'))}` : `Tip ${formatCurrency(selectedTip)}` })
			})

			ractive.observe('tipAmount', tipAmount => {
				ractive.set({ submitButtonText: `Tip ${formatCurrency(tipAmount)}` })
			}, { init: false })

			ractive.on('newOrderTokenError', (_context, error) => {
				if (error && Array.isArray(error?.reasons)) {
					const errorMessages = error.reasons.map(({ message }) => message).join('\n\n')
					alert(errorMessages)
				} else {
					alert('There was an error processing your card. Please contact LawnHiro support.')
				}
			})

			ractive.on('newOrderTokenSuccess', async(_context, cardInfo) => {
				if (ractive.get('saveCard')) {
					try {
						const addCardToCustomerWallet = await ractive.addCardToWallet(cardInfo)

						if (!ractive.get('isAddingCardToWallet')) {
							//if they're not adding to the wallet,
							//submit means we're going to create the job now
							await ractive.submitTip(cardInfo, addCardToCustomerWallet.id)
						} else {
							ractive.set({
								isAddingCardToWallet: false,
								selectedCardId: addCardToCustomerWallet.id,
							})
						}
					} catch (err) {
						console.error(err)
						alert(err?.message || 'An unknown error occurred while adding your card to your wallet. Please try again later.')
					}
				} else {
					//Just create the job with the token we got
					await ractive.submitTip(cardInfo)
				}
			})

			ractive.on('deleteCard', async(_context, event, cardId) => {
				event?.stopPropagation()

				if (confirm('Are you sure you want to remove this card from your account?')) {
					const deactivateCardInCustomerWalletMutation = `#graphql
						mutation DeactivateCardInCustomerWallet($deactivateCardInCustomerWalletId: PositiveInt!) {
							deactivateCardInCustomerWallet(id: $deactivateCardInCustomerWalletId) {
								id
							}
						}
					`

					const { deactivateCardInCustomerWallet } = await mediator.call('apiFetch', deactivateCardInCustomerWalletMutation, { deactivateCardInCustomerWalletId: cardId })
					const cardIndex = ractive.get('cards').findIndex(card => card.id === deactivateCardInCustomerWallet.id)
					ractive.splice('cards', cardIndex, 1)
					ractive.set({ selectedCardId: ractive.get('cards')?.[0]?.id || null })
				}
			})
		},
	})
}
