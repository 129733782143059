export default {
    "name": "UpdateServiceOfferStatus",
    "kind": "HoudiniMutation",
    "hash": "8b63b5ed876436847ebc5963cf42fdab0aa85fa15a272b632093589ee051283a",

    "raw": `mutation UpdateServiceOfferStatus($changeServiceOfferStatus: ChangeServiceOfferStatus!) {
  changeServiceOfferStatus(changeServiceOfferStatus: $changeServiceOfferStatus) {
    ...RegionHelperServiceOfferData
    id
  }
}

fragment RegionHelperServiceOfferData on ServiceOffer {
  id
  active
  service {
    id
    name
  }
  regionId
  chargeMinimum
  chargePerSquareFoot
  chargeFixed
  costPercent
  serviceId
  timeRange
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "changeServiceOfferStatus": {
                "type": "ServiceOffer",
                "keyRaw": "changeServiceOfferStatus(changeServiceOfferStatus: $changeServiceOfferStatus)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "chargeMinimum": {
                            "type": "String",
                            "keyRaw": "chargeMinimum",
                            "visible": true
                        },

                        "chargePerSquareFoot": {
                            "type": "String",
                            "keyRaw": "chargePerSquareFoot",
                            "visible": true
                        },

                        "chargeFixed": {
                            "type": "String",
                            "keyRaw": "chargeFixed",
                            "nullable": true,
                            "visible": true
                        },

                        "costPercent": {
                            "type": "String",
                            "keyRaw": "costPercent",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "timeRange": {
                            "type": "String",
                            "keyRaw": "timeRange",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "RegionHelperServiceOfferData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "changeServiceOfferStatus": "ChangeServiceOfferStatus"
        },

        "types": {
            "ChangeServiceOfferStatus": {
                "active": "Boolean",
                "id": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=09b6ff06ee4cec80565ca7e9753a1665c0bef6e0d35dd05591546f4dfe49c796";