import template from './detail.ractive.html'
import makeOrderDetail from 'components/customer-order-detail'
import { klona } from 'klona'

const defaultCancelJobModalState = Object.freeze({
	show: false,
	jobId: null,
	cancellationReason: '',
	isLoading: false,
})

const jobData = `#graphql
	id
	residence {
		street
		city
		state
		zip
		country
		googleMapsPlaceId
	}
	jobStatus
	requestedSchedule
	jobClaims {
		provider {
			userAccount {
				fullName
			}
			profilePictureFile {
				id
				path
			}
		}
		jobClaimStatus
		scheduledAt
	}
	service {
		name
	}
	answers {
		id
		answer
	}
`

const cancelJobQuery = `#graphql
	mutation Mutation($cancelJobInput: CancelJobInput!) {
		cancelJob(cancelJobInput: $cancelJobInput) {
			${jobData}
		}
	}
`

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.customer.upcoming-orders.detail',
		route: 'detail',
		querystringParameters: [ 'jobId' ],
		template: {
			template,
			components: {
				orderDetail: makeOrderDetail({ mediator, defaultCancelJobModalState }),
			},
		},
		async resolve(_data, _parameters) {
			const jobQuery = `#graphql
				query Job($jobId: PositiveInt!) {
					job(id: $jobId) {
						${jobData}
					}
				}
			`

			const res = await mediator.call('apiFetch', jobQuery, {
				jobId: _parameters.jobId,
			})

			return { job: res?.job || {} }
		},
		activate({ domApi: ractive }) {
			ractive.on('cancelOrder', async(_context, cancelJobId, cancellationReason) => {
				const orderDetailComponent = ractive.findComponent('orderDetail')
				orderDetailComponent.set('cancelJobModal.isLoading', true)
				try {
					const { cancelJob } = await mediator.call('apiFetch', cancelJobQuery, { cancelJobInput: { jobId: cancelJobId, reason: cancellationReason } })
					await mediator.call('updateJobStatus', { jobId: cancelJob.id, jobStatus: cancelJob.jobStatus })
					ractive.set({ job: cancelJob })
					orderDetailComponent.set('cancelJobModal', klona(defaultCancelJobModalState))
				} catch (err) {
					let message
					switch (err?.extensions?.code) {
						case 'MODIFY_JOB_ERROR_OUTSIDE_CANCELABLE_HOURS':
							message = 'The job has been claimed by a Lawnhiro provider and is no longer cancelable. Please contact Lawnhiro support to cancel the job.'
							break
						default:
							message = 'There was an error cancelling your order. Please try again. If the problem persists, please contact Lanhiro support.'
							break
					}
					console.error(err)
					alert(message)
					orderDetailComponent.set('cancelJobModal.isLoading', false)
				}
			})
		},
	})
}
