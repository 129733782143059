import { FragmentStore } from '../runtime/stores/fragment'
import artifact from '$houdini/artifacts/NewResidenceData'


// create the fragment store

export class NewResidenceDataStore extends FragmentStore {
	constructor() {
		super({
			artifact,
			storeName: "NewResidenceDataStore",
			variables: true,
			
		})
	}
}
