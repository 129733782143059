<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'

	import { getUserZones, type UserZone } from 'utility/profile-helper'
	import { LoadProviderUserRegionsHelper } from 'utility/available-jobs-helper'
	import { updateProviderRegionMutation } from 'utility/provider-management-helper'
	import { getSession } from 'stores/session'
	import type { Mediator } from 'services/api-fetch'
	import Throbber from 'components/Throbber.svelte'

	import { getContext, onMount, createEventDispatcher } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	const dispatch = createEventDispatcher()

	let zones: UserZone[] = []
	let availibleRegions: UserZone['regions'] = []
	let providerRegionIds: number[] = []
	let loading: boolean = false

	// This is used to keep track of the displayed opted in state of the regions in the zones
	$: zoneIdsWithRegionsOptedIn = zones.filter(zone => zone.regions.some(region => providerRegionIds.includes(region.id))).map(zone => zone.id)

	const session = getSession()

	// We load the users zones and regions on mount to display the regions the user can opt into
	// Potential improvement: Group the queries into one async call and use the async then syntax svelte offers with a skeleton UI
	onMount(async () => {
		loading = true
		if (!session.userAccountId) return
		if (session.provider) {
			zones = await getUserZones(session.userAccountId)
			// We flatten the array of arrays of regions into one array of regions because the regions are unique across zones so theoretically there are no duplicates
			availibleRegions = zones.flatMap(zone => zone.regions)
			providerRegionIds = (await LoadProviderUserRegionsHelper(session.userAccountId)).map(region => region.region.id)
		}
		loading = false
	})

	async function updateProviderRegions(regionId: number) {
		const trueFalseValue = providerRegionIds.includes(regionId) ? false : true
		if (!session.userAccountId) {
			mediator.call('showMessage', {
				color: 'danger',
				heading: 'Error Updating Region Opt In',
				message: 'An error occurred while updating the region opt in. Please try again later.',
				time: 5000,
			})
			return
		}
		const { data } = await updateProviderRegionMutation.mutate({
			userRegion: {
				userAccountId: session.userAccountId,
				regionId,
				active: trueFalseValue,
			},
		})

		if (!data) {
			const regionName = availibleRegions.find(region => region.id === regionId)?.name
			mediator.call('showMessage', {
				color: 'danger',
				heading: 'Error Updating Region Opt In',
				message: `An error occurred while updating the region opt in for ${regionName ?? 'Unknown Region'}. Please try again later.`,
				time: 5000,
			})
		}

		dispatch('optInChanged', { regionId, active: trueFalseValue })

		if (!trueFalseValue) {
			providerRegionIds = providerRegionIds.filter(id => id !== regionId)
		} else {
			providerRegionIds = [...providerRegionIds, regionId]
		}
	}

	async function optOutOfAllRegionsInZone(zoneId: number) {
		const regionIds = zones.find(zone => zone.id === zoneId)?.regions.map(region => region.id) ?? []
		const regionsIdsToOptOutOf = regionIds.filter(regionId => providerRegionIds.includes(regionId))
		for (const regionId of regionsIdsToOptOutOf) {
			await updateProviderRegions(regionId)
		}
	}
</script>

{#if loading}
	<Throbber />
{:else if !loading && availibleRegions.length === 0}
	<p>No regions available</p>
{:else if availibleRegions.length > 0}
	{#each zones as zone}
		<div class="card">
			<div class="card-header d-flex justify-content-between">
				<h5 class="mb-0">{zone.name}</h5>
				{#if zoneIdsWithRegionsOptedIn.includes(zone.id)}
					<Button
						outline
						id="opt-out-all-regions-in-zone-{zone.id}"
						title="Opt out of all regions in this zone"
						iconClass="xmark"
						size="xs"
						color="secondary"
						on:click={() => optOutOfAllRegionsInZone(zone.id)}>Opt Out</Button
					>
				{/if}
			</div>
			<div class="card-body p-0">
				<ul
					class="list-group list-group-flush"
					style="border-radius: 0.4rem"
				>
					{#each zone.regions as region}
						<li class="list-group-item">
							<Checkbox
								id="region-{region.id}-checkbox"
								label={region.name}
								labelClass="w-100"
								type="switch"
								checked={providerRegionIds.includes(region.id)}
								on:change={() => updateProviderRegions(region.id)}
							></Checkbox>
						</li>
					{/each}
				</ul>
			</div>
		</div>
	{/each}
{/if}

<style>
	div.card:not(:last-child) {
		margin-bottom: 0.5rem;
	}
</style>
