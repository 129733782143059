<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Table, { type Column, Td } from '@isoftdata/svelte-table'

	import { removeTag, removeTagPricing, type AdminTag, type AdminTagService, type EntityType, type PricingType } from 'utility/tag-helper'
	import { toDisplay } from 'utility/format/format-percent'
	import formatCurrency from 'utility/format/format-currency'
	import type { Mediator } from 'types/common'
	import TagPricingTable from './TagPricingTable.svelte'
	import TagModal from './TagModal.svelte'
	import TagPricingModal from './TagPricingModal.svelte'

	import type { WritableDeep } from 'type-fest'
	import type { CreateNewTag$result, NewTagPricingMutation$result, UpdateTagPricingMutation$result } from '$houdini/index'
	import { getContext } from 'svelte'
	import toTitleCase from 'to-title-case'

	const mediator = getContext<Mediator>('mediator')

	export let tags: WritableDeep<AdminTag & { remove: unknown }>[] = []
	export let allServices: AdminTagService[] = []

	let showTagModal: boolean = false
	let showTagPricingModal: boolean = false
	let editingTagPricing: boolean = false
	let selectedTagId: number | null = null
	let selectedTag: AdminTag | null = null
	let serviceTypesList: AdminTagService[] = []

	let tagCode: string | null = null
	let tagName: string | null = null
	let tagEntityType: EntityType = 'RESIDENCE'
	let tagDescription: string = ''
	let tagPrompt: string = ''

	let tagPricingTagPricingId: number | null = null
	let tagPricingPricingType: PricingType = 'PERCENT'
	let tagPricingPriceAdjustment: number = 0
	let tagPricingPostPriceAdjustment: boolean = false
	let tagPricingServiceId: number | null = null

	$: availableServices = allServices.reduce((acc: AdminTagService[], service) => {
		const isServiceAlreadyAdded = selectedTag?.tagPricings?.find(tagPricing => tagPricing.serviceId === service.id)
		if (!isServiceAlreadyAdded) {
			acc.push(service)
		}
		return acc
	}, [])

	const tagTableColumns: Column<AdminTag & { remove: unknown }>[] = [
		{ property: 'code', name: 'Code' },
		{ property: 'name', name: 'Name' },
		{ property: 'description', name: 'Description' },
		{ property: 'prompt', name: 'Prompt' },
		{ property: 'entityType', name: 'Type' },
		{ property: 'remove', name: '', sortType: false },
	]

	function openTagModal() {
		tagCode = null
		tagName = null
		tagEntityType = 'RESIDENCE'
		tagDescription = ''
		tagPrompt = ''
		showTagModal = true
		// Find the tag code and focus it
	}

	function selectTag(id: any) {
		if (selectedTagId === id) {
			selectedTagId = null
			selectedTag = null
			return
		}
		const tag = tags.find(tag => tag.id === id)
		selectedTagId = id
		selectedTag = tag ?? null
	}

	async function deleteTag(id: number, name: string, index: number) {
		console.log(index)
		if (confirm(`Are you sure you want to delete the "${name}" tag?`)) {
			try {
				const res = await removeTag(id)
				if (res) {
					if (index > -1) {
						tags.splice(index, 1)
						tags = tags
					}
				}
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { title: 'Failed to delete tag', message: error.message })
			}
		}
	}

	function openTagPricingModal() {
		editingTagPricing = false
		serviceTypesList = availableServices
		tagPricingPricingType = 'PERCENT'
		tagPricingPriceAdjustment = 0
		tagPricingPostPriceAdjustment = false
		tagPricingServiceId = null
		showTagPricingModal = true
	}

	function openEditTagPricingModal(event: CustomEvent<Exclude<AdminTag['tagPricings'], null>[number]>) {
		editingTagPricing = true
		serviceTypesList = allServices
		tagPricingTagPricingId = event.detail.id
		tagPricingPricingType = event.detail.pricingType
		tagPricingPriceAdjustment = event.detail.pricingType === 'PERCENT' ? toDisplay(event.detail.priceAdjustment || '0') : parseFloat(event.detail?.priceAdjustment)
		tagPricingPostPriceAdjustment = event.detail.postPriceAdjustment
		tagPricingServiceId = event.detail.serviceId
		showTagPricingModal = true
	}

	async function deleteTagPricing(tagPricingEvent: CustomEvent<Exclude<AdminTag['tagPricings'], null>[number] & { tagId: number }>) {
		if (
			confirm(
				`Are you sure you want to delete the ${tagPricingEvent.detail.pricingType === 'PERCENT' ? `${toDisplay(tagPricingEvent.detail.priceAdjustment)}%` : formatCurrency(tagPricingEvent.detail.priceAdjustment)} price for "${tagPricingEvent.detail.service?.name}"?`,
			)
		) {
			try {
				const res = await removeTagPricing(tagPricingEvent.detail.id)

				if (!res) {
					throw new Error('Failed to delete tag pricing')
				}

				const tagIndex = tags.findIndex(tag => tag.id === tagPricingEvent.detail.tagId)
				if (tagIndex > -1) {
					const tag = tags[tagIndex]
					if (tag.tagPricings) {
						const tagPricingIndex = tag.tagPricings.findIndex(tagPricing => tagPricing.id === tagPricingEvent.detail.id)
						if (tagPricingIndex > -1) {
							tag.tagPricings.splice(tagPricingIndex, 1)
							tag.tagPricings = tag.tagPricings
						}
					}
					tags[tagIndex] = tag
				}
			} catch (err: unknown) {
				const error = err as Error
				console.error(error)
				mediator.call('showError', error, { title: 'Failed to delete tag pricing', message: error.message })
			}
		}
	}

	function addedTagPricing(tagPricingEvent: CustomEvent<WritableDeep<NewTagPricingMutation$result['newTagPricing']> & { tagId: number }>) {
		const tagIndex = tags.findIndex(tag => tag.id === tagPricingEvent.detail.tagId)
		if (tagIndex > -1) {
			const tag = tags[tagIndex]
			if (tag.tagPricings) {
				tag.tagPricings.push(tagPricingEvent.detail)
				tag.tagPricings = tag.tagPricings
			}
			tags[tagIndex] = tag
		}
	}

	function updatedTagPricing(tagPricingEvent: CustomEvent<WritableDeep<UpdateTagPricingMutation$result['editTagPricing']> & { tagId: number }>) {
		const tagIndex = tags.findIndex(tag => tag.id === tagPricingEvent.detail.tagId)
		if (tagIndex > -1) {
			const tag = tags[tagIndex]
			if (tag.tagPricings) {
				const tagPricingIndex = tag.tagPricings.findIndex(tagPricing => tagPricing.id === tagPricingEvent.detail.id)
				if (tagPricingIndex > -1) {
					tag.tagPricings[tagPricingIndex] = tagPricingEvent.detail
					tag.tagPricings = tag.tagPricings
				}
			}
			tags[tagIndex] = tag
		}
	}

	function updatedTags(tagEvent: CustomEvent<WritableDeep<CreateNewTag$result['newTag']>>) {
		tags.push(tagEvent.detail as AdminTag & { remove: unknown })
		tags = tags
	}
</script>

<div class="container-fluid mt-3">
	<div class="d-flex justify-content-end">
		<Button
			id="add-tag-button"
			title="Add Tag"
			iconClass="plus"
			on:click={() => openTagModal()}
		>
			Add New Tag
		</Button>
	</div>
	<Table
		responsive
		filterEnabled
		showFilterLabel
		filterLabel="Filter Tags"
		rows={tags}
		columns={tagTableColumns}
		let:row
	>
		<svelte:fragment slot="no-rows">
			<tr>
				<td
					colspan="6"
					class="text-center">No tags found</td
				>
			</tr>
		</svelte:fragment>
		<tr
			class="cursor-pointer"
			class:table-dark={selectedTagId === row.id}
			on:click={() => selectTag(row.id)}
		>
			<Td
				property="code"
				class="font-weight-bold">{row.code}</Td
			>
			<Td property="name">{row.name}</Td>
			<Td property="description">{row.description ?? ''}</Td>
			<Td property="prompt">{row.prompt ?? ''}</Td>
			<Td property="entityType">{toTitleCase(row.entityType)}</Td>
			<Td property="remove">
				<Button
					id="edit-tag-button"
					title="Edit Tag"
					size="sm"
					color="danger"
					iconClass="trash"
					on:click={() => deleteTag(row.id, row.name, row.originalIndex)}
				></Button>
			</Td>
		</tr>
		{#if selectedTagId === row.id && selectedTag}
			<tr>
				<td colspan="6">
					<div class="card">
						<div class="card-header d-flex justify-content-between py-1 px-2">
							<h5>Tag Pricing</h5>
							<Button
								id="add-tag-pricing-button"
								title="Add Tag Pricing"
								size="xs"
								iconClass="plus"
								on:click={() => openTagPricingModal()}
							>
								Add Tag Price
							</Button>
						</div>
						<div class="card-body p-2">
							<TagPricingTable
								tag={selectedTag}
								on:editTagPricing={e => openEditTagPricingModal(e)}
								on:deleteTagPricing={e => deleteTagPricing(e)}
							/>
						</div>
					</div>
				</td>
			</tr>
		{/if}
	</Table>
</div>

<TagModal
	bind:show={showTagModal}
	bind:code={tagCode}
	bind:name={tagName}
	bind:entityType={tagEntityType}
	bind:description={tagDescription}
	bind:prompt={tagPrompt}
	on:tagAdded={tagEvent => {
		updatedTags(tagEvent)
	}}
/>

<TagPricingModal
	bind:show={showTagPricingModal}
	bind:editing={editingTagPricing}
	bind:serviceTypesList
	bind:tagPricingId={tagPricingTagPricingId}
	bind:pricingType={tagPricingPricingType}
	bind:priceAdjustment={tagPricingPriceAdjustment}
	bind:postPriceAdjustment={tagPricingPostPriceAdjustment}
	bind:serviceId={tagPricingServiceId}
	bind:tagId={selectedTagId}
	on:tagPricingAdded={tagPricingEvent => {
		addedTagPricing(tagPricingEvent)
	}}
	on:tagPricingUpdated={tagPricingEvent => {
		updatedTagPricing(tagPricingEvent)
	}}
/>
