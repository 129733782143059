export default {
    "name": "AdminTagPricingData",
    "kind": "HoudiniFragment",
    "hash": "c26b37e6980d60cbde489d3d2ed4b27f373c4164d2b7e3eb8633fb80fe761638",

    "raw": `fragment AdminTagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  service {
    name
    id
  }
  postPriceAdjustment
  __typename
}
`,

    "rootType": "TagPricing",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "priceAdjustment": {
                "type": "String",
                "keyRaw": "priceAdjustment",
                "visible": true
            },

            "pricingType": {
                "type": "PricingType",
                "keyRaw": "pricingType",
                "visible": true
            },

            "serviceId": {
                "type": "PositiveInt",
                "keyRaw": "serviceId",
                "visible": true
            },

            "service": {
                "type": "Service",
                "keyRaw": "service",
                "nullable": true,

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "postPriceAdjustment": {
                "type": "Boolean",
                "keyRaw": "postPriceAdjustment",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=fcd1a38409c916b57c974a39203b55a301c2c471bb4517736fee30614ff64362";