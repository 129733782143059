import { persist, createLocalStorage } from '@macfja/svelte-persistent-store'
import { writable, get } from 'svelte/store'
import type { ClientSession } from 'utility/login-and-save-session'

const session = persist(writable<ClientSession | Record<string, never>>({}), createLocalStorage(), 'session')

export default session

/** Runs `get` from `svelte/store` on the session store. You should only use this in the case where subscribing doesn't make sense. */
export function getSession() {
	return get(session)
}
