<script lang="ts">
	import { Table, type Column, Td } from '@isoftdata/svelte-table'
	import { formatISO, nextMonday, nextSunday, parseISO } from 'date-fns'
	import { previousMonday, previousSunday } from 'date-fns'
	import { loadPayables, type ProviderPayable, computeTransactionPayments, computeProviderPayouts, getCouponCodesUsed, type ComputedProviderPayable } from 'utility/accounts-payable-helper'
	import type { Mediator } from 'types/common'
	import { getContext } from 'svelte'
	import AccountsPayableProviderJobs from './AccountsPayableProviderJobs.svelte'
	import jsonToCSV from '@isoftdata/json-to-csv'
	import Button from '@isoftdata/svelte-button'
	import DateRange from '@isoftdata/svelte-date-range'
	import formatCurrency from 'utility/format/format-currency'
	import formatDuration from 'utility/format/format-duration'

	export let payables: ProviderPayable[]
	export let dates: { from: string; to: string }
	export let loadingPayables = false

	const mediator = getContext<Mediator>('mediator')

	const providerJobsColumns: Column[] = [
		{ property: 'jobId', name: 'Job ID' },
		{ property: 'customerName', name: 'Customer Name' },
		{ property: 'customerAddress', name: 'Customer Address' },
		{ property: 'requestedScheduleFormatted', name: 'Requested Schedule' },
		{ property: 'payout', name: 'Payout Amount', numeric: true },
		{ property: 'payableDate', name: 'Payable Date', defaultSortColumn: true },
		{ property: 'formattedElapsedTime', name: 'Elapsed Time' },
		{ property: 'payoutType', name: 'Transaction Type' },
	]
	$: computedTransactionPayments = computeTransactionPayments(payables)
	$: providerPayouts = computeProviderPayouts(computedTransactionPayments)

	function makeGustoCSV(providerPayouts: ComputedProviderPayable[]) {
		return window.btoa(
			jsonToCSV(
				providerPayouts.map(({ providerId, providerFirstName, providerLastName, totalPayout }) => {
					return {
						contractor_type: 'individual',
						first_name: providerFirstName,
						last_name: providerLastName,
						ssn: '',
						business_name: '',
						ein: '',
						memo: '',
						hours_worked: '',
						wage: totalPayout,
						reimbursement: '',
						bonus: '',
						invoice_number: `${new Date().toISOString().slice(0, 10)}:${providerId}`, //alphanumeric, max length 25 characters(per Gusto docs)
					}
				}),
			),
		)
	}

	$: gustoCSVDownloadable = makeGustoCSV(providerPayouts)

	$: currentPeriodIsOpen = parseISO(dates.to) > new Date()

	$: displayCouponCodesUsed = Object.entries(getCouponCodesUsed(computedTransactionPayments))
		.map(([couponCode, count]) => {
			return `${couponCode}(${count})`
		})
		.join(', ')

	async function previousPeriod() {
		const { from, to } = dates
		dates = {
			from: formatISO(previousMonday(parseISO(from)), { representation: 'date' }),
			to: formatISO(previousSunday(parseISO(to)), { representation: 'date' }),
		}
		await loadAndUpdateJobs(dates)
	}
	async function nextPeriod() {
		const { from, to } = dates
		dates = {
			from: formatISO(nextMonday(parseISO(from)), { representation: 'date' }),
			to: formatISO(nextSunday(parseISO(to)), { representation: 'date' }),
		}
		await loadAndUpdateJobs(dates)
	}

	async function loadAndUpdateJobs(dates) {
		try {
			loadingPayables = true
			const updatedPayables = await loadPayables(dates)
			if (payables) {
				payables = updatedPayables
			}
		} catch (err: unknown) {
			if (err instanceof Error) {
				console.error(err)
				mediator.call('showError', err, { message: 'An error occurred while loading payable jobs', title: 'Error Loading Payables' })
			}
		} finally {
			loadingPayables = false
		}
	}
</script>

<Table
	responsive
	filterEnabled
	showFilterLabel
	rows={providerPayouts}
	columns={[
		{ property: 'providerEmail', name: 'Provider Email' },
		{ property: 'providerName', name: 'Provider Name' },
		{ property: 'totalPayout', name: 'Total Payout' },
		{ property: 'totalElapsedTime', name: 'Total Elapsed Time' },
	]}
	filterLabel="Filter Providers"
	let:row
>
	<svelte:fragment slot="header">
		<div class="mb-2">
			<Button
				download="lawn_hiro_provider_payouts_{dates.from}-{dates.to}.csv"
				href="data:text/csv;base64, {gustoCSVDownloadable}"
				iconClass="file-csv"
			>
				Export to Gusto CSV
			</Button>
			{#if currentPeriodIsOpen}
				<br />
				<small class="text-danger"> The currently shown period is still open. You can't export the payouts yet. </small>
			{/if}
		</div>
		<div class="d-flex align-items-baseline">
			<DateRange
				hideDateRange
				inline
				disabled
				colClass="col-md-6"
				bind:dates
			/>
			<Button
				class="mr-1"
				size="sm"
				style="width: 80px;"
				on:click={previousPeriod}>Previous</Button
			>
			<Button
				size="sm"
				style="width: 80px;"
				on:click={nextPeriod}>Next</Button
			>
			{#if displayCouponCodesUsed}
				<div class="ml-4 font-weight-bold">
					Coupon Codes Used: {displayCouponCodesUsed}
				</div>
			{/if}
		</div>
	</svelte:fragment>
	<svelte:fragment
		slot="body"
		let:rows
	>
		{#if loadingPayables}
			<tr><td colspan="4"><i class="fa fa-spinner fa-spin fa-3x fa-fw mt-3"></i></td></tr>
		{:else}
			{#each rows as row}
				<tr style="font-weight: bold; font-size: larger;">
					<Td property="providerEmail"><a href="mailto:{row.providerEmail}">{row.providerEmail}</a></Td>
					<Td property="providerName">{row.providerName}</Td>
					<Td property="totalPayout">{formatCurrency(row.totalPayout)}</Td>
					<Td property="totalElapsedTime">{formatDuration(row.totalElapsedTime)}</Td>
				</tr>
				<tr>
					<td colspan="4">
						<AccountsPayableProviderJobs
							providerJobs={row.jobs}
							{providerJobsColumns}
						/>
					</td>
				</tr>
			{:else}
				<tr><td colspan="4">No data available</td></tr>
			{/each}
		{/if}
	</svelte:fragment>
</Table>
