import type { LoadOnboardingQuery$result, PictureFileStatus$options, ProviderServiceApplicationStatus$options, ProviderApplicationStatus$options } from '$houdini'
import { graphql } from '$houdini'
import type { WritableDeep } from 'type-fest'

export type ServiceApplicationStatus = ProviderServiceApplicationStatus$options
export type PictureFileStatus = PictureFileStatus$options
export type ProviderApplicationStatus = ProviderApplicationStatus$options

export type Service = LoadOnboardingQuery$result['services']['data'][0] & {
	examplePictureFilePath: string | null
	uploadedPictureFilePath: string | null
	newPictureFileUploaded: boolean
	serviceApplicationId: number | null
	serviceApplicationStatus: ServiceApplicationStatus | null
	rejectMessage: string | null
}

export type Provider = WritableDeep<
	Omit<LoadOnboardingQuery$result['provider'], 'authorizedServices'> & {
		city: string
		userAccount: LoadOnboardingQuery$result['provider']['userAccount'] & {
			mobile: string
		}
		inWaitlist: boolean
		tshirtSize: string | null
		onboardingApplicationStatus: ProviderApplicationStatus | null
		homeRegionName: string | null
		hasLiabilityInsurance: boolean
		hasAcceptedProviderAgreement: boolean
		transportationPictureFile: {
			path: string | null
			rejectMessage?: string | null
			status: PictureFileStatus | null
		}
		driversLicensePictureFile: {
			path: string | null
			rejectMessage?: string | null
			status: PictureFileStatus | null
		}
		services: Service[]
	}
>

export const uploadFileToOnboardingApplicationMutation = graphql(`
	mutation AttachFileToProviderOnboardingApplication($input: ProviderOnboardingApplicationFileInput!) {
		attachFileToProviderOnboardingApplication(input: $input) {
			id
			status
			driversLicensePictureFile {
				path
			}
			driverLicenseStatus
			transportationPictureFile {
				path
			}
			transportationStatus
			lastUpdated
			serviceApplications: providerServiceApplications {
				id
				serviceId
				status
				equipmentPictureFile {
					path
				}
			}
		}
	}
`)

export const updateProviderBasicInfoMutation = graphql(`
	mutation UpdateProviderOnboardingBasicInfo($onboardingInfo: ProviderOnboardingInfo!) {
		updateProviderOnboardingBasicInfo(onboardingInfo: $onboardingInfo) {
			id
			street
			state
			zip
			city
			homeRegionId
			homeRegion {
				name
			}
			acceptedProviderAgreement
			userAccount {
				email
				firstName
				lastName
				mobile
			}
			onboardingApplication: latestOnboardingApplication {
				id
				status
				lastUpdated
			}
		}
	}
`)

export const notInterestedServiceApplicationMutation = graphql(`
	mutation DeleteServiceApplicationInOnboardingApplication($providerApplicationId: PositiveInt!, $providerServiceApplicationId: PositiveInt!) {
		deleteServiceApplicationInOnboardingApplication(providerApplicationId: $providerApplicationId, providerServiceApplicationId: $providerServiceApplicationId) {
			id
			status
			lastUpdated
		}
	}
`)
