export default {
    "name": "JobData",
    "kind": "HoudiniFragment",
    "hash": "754fb743b42eeab91ae7151eff7fcd748d0e33ebc7776da36c32a1bbbb186b8f",

    "raw": `fragment JobData on Job {
  id
  jobStatus
  created
  requestedSchedule
  totalElapsedTime
  customerId
  completed
  serviceScheduleId
  customerFirstJob
  customer {
    id
    userAccount {
      fullName
      email
      mobile
      status
      id
    }
  }
  residenceId
  residence {
    id
    region {
      id
      name
    }
    longitude
    latitude
    zip
    street
    state
    estimatedLawnSquareFootage
    effectiveLawnSquareFootage
    city
    country
    googleMapsPlaceId
  }
  jobClaims {
    provider {
      userAccountId
      userAccount {
        fullName
        id
      }
      profilePictureFile {
        path
        id
      }
      id
    }
    cancellationReason
    internalNotes
    providerId
    jobClaimStatus
    scheduledAt
    isLate
    id
  }
  transaction {
    cost
    total
    tax
    subtotal
    id
  }
  service {
    name
    id
  }
  files {
    id
    fileId
    rank
    public
    imageFileType
    file {
      id
      name
      created
      updated
      hash
      path
      type
      mimeType
    }
  }
  __typename
}
`,

    "rootType": "Job",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "jobStatus": {
                "type": "JobStatus",
                "keyRaw": "jobStatus",
                "visible": true
            },

            "created": {
                "type": "DateTime",
                "keyRaw": "created",
                "visible": true
            },

            "requestedSchedule": {
                "type": "Date",
                "keyRaw": "requestedSchedule",
                "visible": true
            },

            "totalElapsedTime": {
                "type": "NonNegativeInt",
                "keyRaw": "totalElapsedTime",
                "visible": true
            },

            "customerId": {
                "type": "PositiveInt",
                "keyRaw": "customerId",
                "visible": true
            },

            "completed": {
                "type": "DateTime",
                "keyRaw": "completed",
                "nullable": true,
                "visible": true
            },

            "serviceScheduleId": {
                "type": "PositiveInt",
                "keyRaw": "serviceScheduleId",
                "nullable": true,
                "visible": true
            },

            "customerFirstJob": {
                "type": "Boolean",
                "keyRaw": "customerFirstJob",
                "visible": true
            },

            "customer": {
                "type": "Customer",
                "keyRaw": "customer",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "fullName": {
                                        "type": "String",
                                        "keyRaw": "fullName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "EmailAddress",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "mobile": {
                                        "type": "PhoneNumber",
                                        "keyRaw": "mobile",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "UserAccountStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "residenceId": {
                "type": "PositiveInt",
                "keyRaw": "residenceId",
                "visible": true
            },

            "residence": {
                "type": "Residence",
                "keyRaw": "residence",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "region": {
                            "type": "Region",
                            "keyRaw": "region",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "longitude": {
                            "type": "Longitude",
                            "keyRaw": "longitude",
                            "nullable": true,
                            "visible": true
                        },

                        "latitude": {
                            "type": "Latitude",
                            "keyRaw": "latitude",
                            "nullable": true,
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "estimatedLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "estimatedLawnSquareFootage",
                            "visible": true
                        },

                        "effectiveLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "effectiveLawnSquareFootage",
                            "nullable": true,
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "googleMapsPlaceId": {
                            "type": "String",
                            "keyRaw": "googleMapsPlaceId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "jobClaims": {
                "type": "JobClaim",
                "keyRaw": "jobClaims",
                "nullable": true,

                "selection": {
                    "fields": {
                        "provider": {
                            "type": "Provider",
                            "keyRaw": "provider",

                            "selection": {
                                "fields": {
                                    "userAccountId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "userAccountId",
                                        "visible": true
                                    },

                                    "userAccount": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccount",

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "String",
                                                    "keyRaw": "fullName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "profilePictureFile": {
                                        "type": "FileEntity",
                                        "keyRaw": "profilePictureFile",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "cancellationReason": {
                            "type": "String",
                            "keyRaw": "cancellationReason",
                            "nullable": true,
                            "visible": true
                        },

                        "internalNotes": {
                            "type": "String",
                            "keyRaw": "internalNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "providerId": {
                            "type": "PositiveInt",
                            "keyRaw": "providerId",
                            "visible": true
                        },

                        "jobClaimStatus": {
                            "type": "JobClaimStatus",
                            "keyRaw": "jobClaimStatus",
                            "visible": true
                        },

                        "scheduledAt": {
                            "type": "DateTime",
                            "keyRaw": "scheduledAt",
                            "visible": true
                        },

                        "isLate": {
                            "type": "Boolean",
                            "keyRaw": "isLate",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "transaction": {
                "type": "Transaction",
                "keyRaw": "transaction",
                "nullable": true,

                "selection": {
                    "fields": {
                        "cost": {
                            "type": "String",
                            "keyRaw": "cost",
                            "visible": true
                        },

                        "total": {
                            "type": "String",
                            "keyRaw": "total",
                            "visible": true
                        },

                        "tax": {
                            "type": "String",
                            "keyRaw": "tax",
                            "visible": true
                        },

                        "subtotal": {
                            "type": "String",
                            "keyRaw": "subtotal",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "service": {
                "type": "Service",
                "keyRaw": "service",

                "selection": {
                    "fields": {
                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "files": {
                "type": "JobFile",
                "keyRaw": "files",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "fileId": {
                            "type": "PositiveInt",
                            "keyRaw": "fileId",
                            "visible": true
                        },

                        "rank": {
                            "type": "NonNegativeInt",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "imageFileType": {
                            "type": "ImageFileType",
                            "keyRaw": "imageFileType",
                            "visible": true
                        },

                        "file": {
                            "type": "FileEntity",
                            "keyRaw": "file",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "created": {
                                        "type": "DateTime",
                                        "keyRaw": "created",
                                        "visible": true
                                    },

                                    "updated": {
                                        "type": "DateTime",
                                        "keyRaw": "updated",
                                        "visible": true
                                    },

                                    "hash": {
                                        "type": "String",
                                        "keyRaw": "hash",
                                        "visible": true
                                    },

                                    "path": {
                                        "type": "String",
                                        "keyRaw": "path",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "FileType",
                                        "keyRaw": "type",
                                        "visible": true
                                    },

                                    "mimeType": {
                                        "type": "String",
                                        "keyRaw": "mimeType",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=d8c8ac34f72d1f77d9fdb16aed89cca397d251aaadbfba02cea95dac3748d040";