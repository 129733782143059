export default {
    "name": "Logout",
    "kind": "HoudiniMutation",
    "hash": "3fa4c6ab69151f1c5cd5417721529b3fd2bdd885f4aa853de8e9b59409f7b507",

    "raw": `mutation Logout {
  closeSession
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "closeSession": {
                "type": "Boolean",
                "keyRaw": "closeSession",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=c1abaf5b4835541e97184c516b152b43f878a2188bbff8a2cd9c8c3a84e38927";