import component from './Residence.svelte'

import { getResidences } from 'utility/graphql/residences-query'

import { AppContext } from 'types/common'
import { getAnswersToTags, getTags, formatResidenceResult } from 'utility/residence-helper'

const PAGESIZE = 10

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.residence',
		route: 'residence',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, _parameters) {
			const pageNumber = 1
			const pageSize = PAGESIZE

			const answersToTags = await getAnswersToTags()

			const res = await getResidences({
				filter: {
					residenceStatus: 'ACTIVE',
				},
				pagination: {
					pageNumber,
					pageSize,
				},
			})

			const residences = res.data?.residences.data ? res.data?.residences.data.map(residance => formatResidenceResult(residance, answersToTags)) : []
			const info = res.data?.residences?.info

			const tags = await getTags({
				filter: {
					entityType: 'RESIDENCE',
				},
			})

			return Promise.resolve({
				residences,
				answersToTags,
				residenceTags: tags,
				pageNumber,
				pageSize,
				totalItems: info?.totalItems ?? 0,
			})
		},
	})
}
