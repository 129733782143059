export default {
    "name": "LoadResidenceHelperServices",
    "kind": "HoudiniQuery",
    "hash": "6127f41f8d19f7f4d5a3d5d21a83a79daf61e5bb2e315f01721f80422bd0aa13",

    "raw": `query LoadResidenceHelperServices($filter: ServiceFilter) {
  services(filter: $filter) {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "services": {
                "type": "ServiceResponse",
                "keyRaw": "services(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Service",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ServiceFilter"
        },

        "types": {
            "ServiceFilter": {
                "active": "Boolean",
                "allowNewApplication": "Boolean",
                "allowSubscription": "Boolean",
                "regionId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=fe7f5439203d60c7e7902ee9956d53f47097e4be1f82672c647dafe5741da4cd";