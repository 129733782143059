<script lang="ts">
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import type { Mediator, SvelteAsr } from 'types/common'
	import { getContext, onDestroy, type ComponentProps } from 'svelte'
	import { getAllPassedScheduledTimeJobClaimCount, getFailedPaymentJobCount } from 'utility/admin-job-alerts'
	import minToMs from 'utility/min-to-ms'
	import type { AdminJobMediatorProviders } from './manage-job'
	import { adminJobAlertStore } from '../../app'

	const mediator = getContext<Mediator<AdminJobMediatorProviders>>('mediator')

	export let asr: SvelteAsr
	export let childStates: ComponentProps<AsrNavTabBar>['tabs']
	export let showRefreshHeader: boolean = false
	export let failedJobCount: number = 0
	export let passedScheduledTimeJobClaimCount: number = 0

	adminJobAlertStore.set({
		failedJobCount: failedJobCount,
		passedScheduledTimeJobClaimCount: passedScheduledTimeJobClaimCount,
	})

	const adminJobAlertStoreUnsubscribe = adminJobAlertStore.subscribe(store => {
		const failedJobStateIndex = childStates.findIndex(state => state.name === 'app.admin.manage-job.failed-payment-job')
		const failedJobState = childStates[failedJobStateIndex]
		const jobStateIndex = childStates.findIndex(state => state.name === 'app.admin.manage-job.job')
		const jobState = childStates[jobStateIndex]

		if (store.failedJobCount > 0 && failedJobState) {
			failedJobState.icon = {
				icon: 'circle-exclamation',
				class: 'text-danger',
			}
		} else if (failedJobState) {
			failedJobState.icon = undefined
		}

		if (store.passedScheduledTimeJobClaimCount > 0 && jobState) {
			jobState.icon = {
				icon: 'circle-exclamation',
				class: 'text-danger',
			}
		} else if (jobState) {
			jobState.icon = undefined
		}

		childStates[failedJobStateIndex] = failedJobState
		childStates[jobStateIndex] = jobState
		childStates = childStates
	})

	let failedJobCountIntervalId = setInterval(async () => {
		let newCount = 0
		try {
			newCount = await getFailedPaymentJobCount()
		} catch (e) {
			console.error(e)
		}
		if (newCount !== $adminJobAlertStore.failedJobCount) {
			showRefreshHeader = true
			$adminJobAlertStore.failedJobCount = newCount
		}
	}, minToMs(5))

	let passedScheduledTimeJobClaimCountIntervalId = setInterval(async () => {
		let newCount = 0
		try {
			newCount = await getAllPassedScheduledTimeJobClaimCount()
		} catch (e) {
			console.error(e)
		}
		if (newCount !== $adminJobAlertStore.passedScheduledTimeJobClaimCount) {
			showRefreshHeader = true
			$adminJobAlertStore.passedScheduledTimeJobClaimCount = newCount
		}
	}, minToMs(5))

	const removeJobResolvedProvider = mediator.provide('failedJobResolved', () => {
		if ($adminJobAlertStore.failedJobCount <= 0) {
			$adminJobAlertStore.failedJobCount = 0
		} else {
			$adminJobAlertStore.failedJobCount -= 1
		}
	})

	let selectedTab: string

	onDestroy(() => {
		adminJobAlertStoreUnsubscribe()
		removeJobResolvedProvider()
		clearInterval(failedJobCountIntervalId)
		clearInterval(passedScheduledTimeJobClaimCountIntervalId)
	})
</script>

<div class="card">
	<div class="card-header admin-nav-tab-bar">
		<AsrNavTabBar
			tabs={childStates}
			{asr}
			breakpoint="md"
			bind:selectedTab
		/>
	</div>
</div>
{#if showRefreshHeader}
	<h6 class="text-danger d-inline-block ml-3 mb-0 mt-3">The job list is out of date. Please refresh the page.</h6>
{/if}

<uiView />
