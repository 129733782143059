import { isToday as dateIsToday, isTomorrow as dateIsTomorrow, isYesterday as dateIsYesterday, differenceInDays, isBefore as dateIsBefore } from 'date-fns'

export interface RelativeDateObject {
	formattedDate: string
	isRelativeFormat: boolean
}

export function formatRelativeDate(date?: Date, today = new Date()): RelativeDateObject {
	let formattedDate: string
	let dateDifference: number
	let isRelativeFormat = true

	if (!date) {
		return {
			// Maybe have a fallback input later
			formattedDate: 'Err',
			isRelativeFormat: false,
		}
	}

	if (dateIsBefore(date, today)) {
		dateDifference = differenceInDays(today, date)
	} else {
		dateDifference = differenceInDays(date, today)
	}

	if (dateIsYesterday(date)) {
		formattedDate = 'Yesterday'
	} else if (dateIsToday(date)) {
		formattedDate = 'Today'
	} else if (dateIsTomorrow(date)) {
		formattedDate = 'Tomorrow'
	} else if (dateDifference <= 7) {
		const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' })
		formattedDate = relativeTimeFormatter.format(differenceInDays(date, today), 'day')
	} else {
		formattedDate = date.toLocaleDateString(navigator?.language || 'en-US', {
			day: 'numeric',
			month: 'numeric',
			year: date.getUTCFullYear() === new Date().getUTCFullYear() ? undefined : '2-digit',
		})
		isRelativeFormat = false
	}

	return {
		isRelativeFormat,
		formattedDate,
	}
}

export default formatRelativeDate
