import { graphql, AttachFilesToJobStore, type ImageFileType$options, DetachFilesFromJobStore } from '$houdini'
import { globalSettingQuery } from './get-file-limit-global-setting'
import pFileReader from 'promise-file-reader'

export async function getMaxImageDimensions() {
	const { data } = await globalSettingQuery.fetch({
		variables: {
			lookup: {
				category: 'Admin settings',
				defaultValue: '1600',
				name: 'Max image dimensions',
				settingType: 'IMPORTANT_CONFIGURATION',
			},
		},
	})

	if (!data?.getGlobalSetting) {
		return 1600
	}

	return parseInt(data.getGlobalSetting.value, 10) ?? 1600
}

const attachFilesToJobMutation: AttachFilesToJobStore = graphql`
	mutation AttachFilesToJob($input: NewJobFileInput!) {
		attachFilesToJob(input: $input) {
			id
			fileId
			rank
			public
			file {
				created
				id
				name
				path
				mimeType
			}
		}
	}
`

export type FileAssociation = {
	userAccountId: number | null
	jobId: number | null
	imageFileType: ImageFileType$options
}

interface SuperFile {
	File: File
	name: string
	public: boolean
	rank: number
}

export async function attachNewFilesToJob(newFile: SuperFile, association: FileAssociation) {
	if (!association.jobId) {
		throw new Error('Job ID is required to attach files to job')
	}
	let fileString = await pFileReader.readAsDataURL(newFile.File)
	fileString = fileString.replace(/^data:.*;base64,/, '') // Remove the data:image/jpeg;base64, part

	const { data } = await attachFilesToJobMutation.mutate({
		input: {
			imageFile: {
				base64String: fileString,
				fileName: newFile.name,
			},
			imageFileType: association.imageFileType,
			jobId: association.jobId,
			publicFile: newFile.public,
			rank: newFile.rank,
		},
	})

	if (!data?.attachFilesToJob.file?.path) {
		throw new Error('Failed to attach files to job')
	}

	const url = `__fileServerUrl__${data.attachFilesToJob.file.path}`

	return {
		fileId: data.attachFilesToJob.fileId,
		public: data.attachFilesToJob.public,
		rank: data.attachFilesToJob.rank,
		path: url,
		mimeType: data.attachFilesToJob.file.mimeType,
		created: data.attachFilesToJob.file.created,
	}
}

const detachFilesFromJobMutation: DetachFilesFromJobStore = graphql`
	mutation DetachFilesFromJob($jobId: PositiveInt!, $fileIds: [PositiveInt!]!) {
		detachFilesFromJob(jobId: $jobId, fileIds: $fileIds)
	}
`

export async function detachFilesFromJob(jobId: number, fileIds: number[]) {
	const { data } = await detachFilesFromJobMutation.mutate({
		jobId,
		fileIds,
	})

	if (!data?.detachFilesFromJob) {
		throw new Error('Failed to detach files from job')
	}

	return data.detachFilesFromJob
}
