import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ActiveWallet'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ActiveWalletStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ActiveWalletStore",
			variables: false,
		})
	}
}

export async function load_ActiveWallet(params) {
	await initClient()

	const store = new ActiveWalletStore()

	await store.fetch(params)

	return {
		ActiveWallet: store,
	}
}
