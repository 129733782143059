<script lang="ts">
	import type { JobStatus$options } from '$houdini/graphql'
	import formatCurrency from 'utility/format/format-currency'
	
	import GenericBadge from 'components/GenericBadge.svelte'
	import ProviderJobListResidenceStreet from 'components/ProviderJobListResidenceStreet.svelte'

	interface ProviderJobListAvailableJobsJobProps {
		jobStatus: JobStatus$options
		transaction: {
			cost: string
		} | null
		service: {
			name: string
		}
		answers:
			| {
					id: number
			  }[]
			| null
		residence: {
			street: string
			displayLawnSquareFootage: number
			region: {
				name: string
			}
		}
		markerLabel: string | google.maps.MarkerLabel
		customerFirstJob: boolean
	}

	export let job: ProviderJobListAvailableJobsJobProps
</script>

<div class="d-flex mr-3 flex-column">
	{#if job.jobStatus === 'CLAIMED'}
		<GenericBadge
			jobType={job.jobStatus}
			text="Claimed By You"
		/>
	{/if}
	<h3 class="mb-0">{formatCurrency(job.transaction?.cost)}</h3>
</div>
<div class="ellepsis text-left">
	<GenericBadge
		type="SERVICE"
		text={job.service.name}
		badgeClass="mt-2"
		style="font-size: inherit;"
	/>
	<div class="d-flex flex-wrap">
		<GenericBadge
			type="LOCATION"
			text={job.markerLabel.toString()}
		/>
		<GenericBadge
			type="REGION"
			text={job.residence.region.name}
		/>
		<GenericBadge
			type="SQFT"
			text={`${Math.round(job.residence.displayLawnSquareFootage/100)*100} sqft`}
		/>
		{#if job.answers && job.answers.length > 0}
			<span class="badge badge-danger mb-1"><i class="fa-solid fa-cicle-exclamation"></i> {job.answers.length} Add-Ons</span>
		{/if}
	</div>
	<ProviderJobListResidenceStreet
		street={job.residence.street}
		customerFirstJob={job.customerFirstJob}
	/>
</div>
