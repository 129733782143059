import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadPayables'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadPayablesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadPayablesStore",
			variables: false,
		})
	}
}

export async function load_LoadPayables(params) {
	await initClient()

	const store = new LoadPayablesStore()

	await store.fetch(params)

	return {
		LoadPayables: store,
	}
}
