export default {
    "name": "LoadProviderClaimableJobs",
    "kind": "HoudiniQuery",
    "hash": "6007b80670fa4d0022d46d512709f4c40d32dfbee83983e0e6f8fcccec173ceb",

    "raw": `query LoadProviderClaimableJobs($orderBy: [JobOrderBy!], $filter: ClaimableJobFilter, $pagination: PaginatedInput) {
  claimableJobs(orderBy: $orderBy, filter: $filter, pagination: $pagination) {
    data {
      id
      jobStatus
      requestedSchedule
      customerFirstJob
      specialInstructions
      residence {
        longitude
        latitude
        zip
        street
        state
        displayLawnSquareFootage
        country
        city
        googleMapsPlaceId
        region {
          id
          name
        }
        answers {
          id
          answer
          tag {
            id
            name
            entityType
          }
        }
        id
      }
      answers {
        id
        answer
        tag {
          id
          name
          entityType
        }
      }
      transaction {
        cost
        id
      }
      service {
        name
        minimumDaysToPerform
        maximumDaysToPerform
        serviceOffer {
          id
          regionId
          timeRange
        }
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "claimableJobs": {
                "type": "JobResponse",
                "keyRaw": "claimableJobs(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Job",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "jobStatus": {
                                        "type": "JobStatus",
                                        "keyRaw": "jobStatus",
                                        "visible": true
                                    },

                                    "requestedSchedule": {
                                        "type": "Date",
                                        "keyRaw": "requestedSchedule",
                                        "visible": true
                                    },

                                    "customerFirstJob": {
                                        "type": "Boolean",
                                        "keyRaw": "customerFirstJob",
                                        "visible": true
                                    },

                                    "specialInstructions": {
                                        "type": "String",
                                        "keyRaw": "specialInstructions",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "residence": {
                                        "type": "Residence",
                                        "keyRaw": "residence",

                                        "selection": {
                                            "fields": {
                                                "longitude": {
                                                    "type": "Longitude",
                                                    "keyRaw": "longitude",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "latitude": {
                                                    "type": "Latitude",
                                                    "keyRaw": "latitude",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "zip": {
                                                    "type": "String",
                                                    "keyRaw": "zip",
                                                    "visible": true
                                                },

                                                "street": {
                                                    "type": "String",
                                                    "keyRaw": "street",
                                                    "visible": true
                                                },

                                                "state": {
                                                    "type": "String",
                                                    "keyRaw": "state",
                                                    "visible": true
                                                },

                                                "displayLawnSquareFootage": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "displayLawnSquareFootage",
                                                    "visible": true
                                                },

                                                "country": {
                                                    "type": "String",
                                                    "keyRaw": "country",
                                                    "visible": true
                                                },

                                                "city": {
                                                    "type": "String",
                                                    "keyRaw": "city",
                                                    "visible": true
                                                },

                                                "googleMapsPlaceId": {
                                                    "type": "String",
                                                    "keyRaw": "googleMapsPlaceId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "region": {
                                                    "type": "Region",
                                                    "keyRaw": "region",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "answers": {
                                                    "type": "Answer",
                                                    "keyRaw": "answers",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "answer": {
                                                                "type": "String",
                                                                "keyRaw": "answer",
                                                                "visible": true
                                                            },

                                                            "tag": {
                                                                "type": "Tag",
                                                                "keyRaw": "tag",

                                                                "selection": {
                                                                    "fields": {
                                                                        "id": {
                                                                            "type": "PositiveInt",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        },

                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "entityType": {
                                                                            "type": "EntityType",
                                                                            "keyRaw": "entityType",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "answers": {
                                        "type": "Answer",
                                        "keyRaw": "answers",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "answer": {
                                                    "type": "String",
                                                    "keyRaw": "answer",
                                                    "visible": true
                                                },

                                                "tag": {
                                                    "type": "Tag",
                                                    "keyRaw": "tag",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "entityType": {
                                                                "type": "EntityType",
                                                                "keyRaw": "entityType",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "transaction": {
                                        "type": "Transaction",
                                        "keyRaw": "transaction",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "cost": {
                                                    "type": "String",
                                                    "keyRaw": "cost",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "minimumDaysToPerform": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "minimumDaysToPerform",
                                                    "visible": true
                                                },

                                                "maximumDaysToPerform": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "maximumDaysToPerform",
                                                    "visible": true
                                                },

                                                "serviceOffer": {
                                                    "type": "ServiceOffer",
                                                    "keyRaw": "serviceOffer",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "regionId": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "regionId",
                                                                "visible": true
                                                            },

                                                            "timeRange": {
                                                                "type": "String",
                                                                "keyRaw": "timeRange",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "JobOrderBy",
            "filter": "ClaimableJobFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ClaimableJobFilter": {
                "regionIds": "PositiveInt",
                "requestedScheduleFrom": "Date",
                "requestedScheduleTo": "Date",
                "serviceId": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=fa606af2703de1e21ad95ec109ed63068a7c0114a5643231431f77f06599ec9d";