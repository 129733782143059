<script lang="ts">
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Input from '@isoftdata/svelte-input'

	import { updateProviderZoneMutation, type ProviderManagementProvider, type ProviderManagementZone } from 'utility/provider-management-helper'
	import type { Mediator } from 'types/common'
	import SwitchCard from './SwitchCard.svelte'

	import { getContext, type ComponentEvents, type ComponentProps } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	export let provider: ProviderManagementProvider
	export let zoneList: ProviderManagementZone[] = []
	export let zonesOpen = false

	let filter = ''
	let filteredSwitchItemList: ComponentProps<SwitchCard>['itemList']

	$: switchItemList = zoneList.map(zone => {
		return {
			id: zone.id,
			name: zone.name,
			checked: !!provider.userAccount.authorizedZones?.find(authorizedZone => authorizedZone.id === zone.id),
		}
	})

	$: if (filter) {
		filteredSwitchItemList = switchItemList.filter(zone => zone.name.toLowerCase().includes(filter.toLowerCase()))
	} else {
		filteredSwitchItemList = switchItemList
	}

	async function updateZone(event: ComponentEvents<SwitchCard>['change']) {
		const authorized = event.detail.checked
		const zoneId = event.detail.id
		try {
			const { data } = await updateProviderZoneMutation.mutate({
				userZone: {
					active: authorized,
					zoneId,
					userAccountId: provider.userAccount.id,
				},
			})

			if (data) {
				const selectedZone = zoneList.find(zone => zone.id === zoneId)
				if (!selectedZone) {
					throw new Error('Zone not found')
				}
				if (provider.userAccount.authorizedZones) {
					if (authorized) {
						provider.userAccount.authorizedZones = [
							...provider.userAccount.authorizedZones,
							{
								id: zoneId,
								name: selectedZone.name,
								regions: selectedZone.regions,
							},
						]
					} else {
						provider.userAccount.authorizedZones = provider.userAccount.authorizedZones.filter(authorizedZone => authorizedZone.id !== zoneId)
					}
				} else {
					provider.userAccount.authorizedZones = [
						{
							id: zoneId,
							name: selectedZone.name,
							regions: selectedZone.regions,
						},
					]
				}
			}
		} catch (err) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { message: error.message, title: 'Error Updating Zone' })
		}
	}
</script>

<CollapsibleCard
	entireHeaderToggles
	cardClass="mb-1 w-100"
	cardHeaderClass="d-flex justify-content-between align-items-center card-header h5"
	bind:bodyShown={zonesOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<span>
			{provider.userAccount.firstName}'s Zones
			<span class="badge badge-primary mx-2">{provider.userAccount.authorizedZones?.length ?? 0}</span>
		</span>
	</svelte:fragment>
	<Input
		id="zone-search"
		title="Search Zones"
		type="text"
		placeholder="Search Zones"
		bind:value={filter}
		showLabel={false}
	/>
	{#if filteredSwitchItemList.length > 0}
		<SwitchCard
			showLabel={false}
			itemList={filteredSwitchItemList}
			on:change={updateZone}
		/>
	{:else}
		<div
			class="alert alert-info w-100"
			role="alert"
		>
			No zones found
		</div>
	{/if}
</CollapsibleCard>
