<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import Select from '@isoftdata/svelte-select'
	import { toggleTransactionStatus } from 'utility/failed-payments-job-helper'
	import { getFailedPaymentJobs, type FailedPaymentJobData } from 'utility/admin-job-alerts'
	import { upsert } from '@isoftdata/utility-array'
	import FailedPaymentTransactionTable from './FailedPaymentTransactionTable.svelte'
	import { getContext } from 'svelte'
	import type { AdminJobMediatorProviders } from '../manage-job'
	import type { Mediator } from 'services/api-fetch'

	const mediator = getContext<Mediator<AdminJobMediatorProviders>>('mediator')

	export let jobs: FailedPaymentJobData = []
	let noJobMessage: string = 'failed'
	let getResolvedJobs: boolean | null = false
	let selectedJobId: number | null = null

	let jobStatusFilterList = [
		{ value: true, label: 'Resolved Jobs Only' },
		{ value: false, label: 'Failed Jobs Only' },
		{ value: null, label: 'Failed & Resolved Jobs' },
	]

	const columns: Column<FailedPaymentJobData[number]>[] = [
		{ property: 'id', name: 'Job ID' },
		{ property: 'customer[userAccount][fullName]', name: 'Customer Name' },
		{ property: 'customer[userAccount][email]', name: 'Customer Email' },
		{ property: 'job[service][name]', name: 'Service' },
		{ property: 'total', name: 'Total' },
		{ property: 'paymentStatus', name: 'Payment Status' },
		{ property: 'invoiceStatus', name: 'Invoice Status' },
	]

	function selectJob(jobId: number) {
		if (selectedJobId === jobId) {
			selectedJobId = null
		} else {
			selectedJobId = jobId
		}
	}

	async function resolveJob(jobId: number) {
		try {
			const toggleTransactionStatuRes = await toggleTransactionStatus(jobId)
			upsert(jobs, 'id', toggleTransactionStatuRes)
			jobs = jobs
			mediator.call('failedJobResolved')
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { title: 'Failed to resolve job' })
		}
	}

	async function filterChanged() {
		try {
			jobs = await getFailedPaymentJobs(getResolvedJobs)
		} catch (err: unknown) {
			const error = err as Error
			console.error(error)
			mediator.call('showError', error, { title: 'Failed to get failed payment jobs' })
		}
	}
</script>

<div class="container-fluid mt-3">
	<Table
		responsive
		filterEnabled
		showFilterLabel
		{columns}
		rows={jobs}
		filterLabel="Filter Jobs"
		let:row
	>
		<svelte:fragment slot="no-rows">
			<tr>
				<td
					colspan="7"
					class="text-center"
				>
					There are no jobs with a {noJobMessage} payment.
				</td>
			</tr>
		</svelte:fragment>
		<svelte:fragment slot="header">
			<div class="form-row align-items-end">
				<div class="col-12 col-md-3">
					<Select
						label="Job Status"
						bind:value={getResolvedJobs}
						showEmptyOption={false}
						on:change={filterChanged}
					>
						{#each jobStatusFilterList as status}
							<option value={status.value}>{status.label}</option>
						{/each}
					</Select>
				</div>
			</div>
		</svelte:fragment>
		<tr
			class="cursor-pointer"
			class:table-dark={selectedJobId === row.id}
			on:click={() => selectJob(row.id)}
		>
			<Td property="id">{row.id}</Td>
			<Td property="customer[userAccount][fullName]">{row.customer.userAccount.fullName}</Td>
			<Td property="customer[userAccount][email]"><a href="mailto:{row.customer.userAccount.email}">{row.customer.userAccount.email}</a></Td>
			<Td property="job[service][name]">{row.job?.service.name}</Td>
			<Td property="total">{row.total}</Td>
			<Td
				property="paymentStatus"
				class={row.paymentStatus === 'FAILED' ? 'text-danger' : 'text-success'}>{row.paymentStatus}</Td
			>
			<Td property="invoiceStatus">{row.invoiceStatus}</Td>
		</tr>
		{#if selectedJobId === row.id}
			<tr>
				<td colspan="7">
					<div class="card">
						<div class="card-header">
							<div class="d-flex justify-content-between align-items-center">
								<h4 class="mb-0">Job Transaction Payments</h4>
								{#if row.paymentStatus === 'FAILED'}
									<Button
										on:click={() => {
											resolveJob(row.id)
										}}
									>
										Mark as Resolved
									</Button>
								{/if}
							</div>
						</div>
						<div class="card-body p-2">
							<FailedPaymentTransactionTable transactions={row.transactionPayments} />
						</div>
					</div>
				</td>
			</tr>
		{/if}
	</Table>
</div>
