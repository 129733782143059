import type { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
import component from './Provider.svelte'
import { getSession } from 'stores/session'
import { AppContext } from 'types/common'
import type { ComponentProps } from 'svelte'

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.provider',
		route: 'provider',
		defaultChild: 'onboarding',
		querystringParameters: [],
		defaultParameters: {},
		data: {},
		template: {
			svelte: true,
			component,
			options: {},
		},

		async resolve(_data, _parameters) {
			const session = getSession()

			if (!session) {
				stateRouter.go('login')
				return {}
			}

			const childStates: ComponentProps<AsrNavTabBar>['tabs'] = [
				{
					name: 'app.admin.provider.management',
					title: 'Management',
					inherit: false,
				},
				{
					name: 'app.admin.provider.onboarding',
					title: 'Onboarding',
					inherit: false,
				},
			]

			return Promise.resolve({
				childStates,
			})
		},
	})
}
