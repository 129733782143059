import { getSession } from 'stores/session'
import component from './ClaimedJobs.svelte'

import { loadFormattedJobClaimsWithMarkers, type JobClaimWithMarker } from 'utility/claimed-jobs-helper'

import type { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.provider.claimed-jobs',
		route: 'claimed-jobs',
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve() {
			const session = getSession()

			if (!session.provider) {
				return Promise.reject({ redirect: { state: 'app.login' } })
			}

			let jobClaimsWithMarkers: JobClaimWithMarker[] = []
			if (session.provider.status === 'APPROVED') {
				jobClaimsWithMarkers = await loadFormattedJobClaimsWithMarkers(session.provider.id)
			}

			const { LatLng } = (await google.maps.importLibrary('core')) as google.maps.CoreLibrary

			const jobPlaces = jobClaimsWithMarkers.map(jobClaim => {
				let geometry: { location: google.maps.LatLng } | undefined = undefined
				if (jobClaim.position) {
					geometry = {
						location: new LatLng(jobClaim.position.latitude, jobClaim.position.longitude),
					}
				}
				return {
					id: jobClaim.id,
					geometry,
					name: jobClaim.job.residence.street,
					selected: false,
				}
			})

			return Promise.resolve({
				providerId: session.provider.id,
				jobClaimsWithMarkers,
				jobPlaces,
				session,
			})
		},
	})
}
