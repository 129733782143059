export default {
    "name": "NewResidenceData",
    "kind": "HoudiniFragment",
    "hash": "25e1bd81a2eb8cbf7723bb73f4cb278a25d764083a7d3904683bccd586ab92c7",

    "raw": `fragment NewResidenceData on Residence {
  id
  regionId
  region {
    status
    id
  }
  customerId
  status
  street
  city
  state
  zip
  country
  county
  googleMapsPlaceId
  estimatedLawnSquareFootage
  effectiveLawnSquareFootage
  displayLawnSquareFootage
  latitude
  longitude
  answers {
    id
    question {
      services {
        id
      }
      id
    }
  }
  __typename
}
`,

    "rootType": "Residence",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "regionId": {
                "type": "PositiveInt",
                "keyRaw": "regionId",
                "visible": true
            },

            "region": {
                "type": "Region",
                "keyRaw": "region",

                "selection": {
                    "fields": {
                        "status": {
                            "type": "RegionStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "customerId": {
                "type": "PositiveInt",
                "keyRaw": "customerId",
                "visible": true
            },

            "status": {
                "type": "ResidenceStatus",
                "keyRaw": "status",
                "visible": true
            },

            "street": {
                "type": "String",
                "keyRaw": "street",
                "visible": true
            },

            "city": {
                "type": "String",
                "keyRaw": "city",
                "visible": true
            },

            "state": {
                "type": "String",
                "keyRaw": "state",
                "visible": true
            },

            "zip": {
                "type": "String",
                "keyRaw": "zip",
                "visible": true
            },

            "country": {
                "type": "String",
                "keyRaw": "country",
                "visible": true
            },

            "county": {
                "type": "String",
                "keyRaw": "county",
                "visible": true
            },

            "googleMapsPlaceId": {
                "type": "String",
                "keyRaw": "googleMapsPlaceId",
                "nullable": true,
                "visible": true
            },

            "estimatedLawnSquareFootage": {
                "type": "NonNegativeInt",
                "keyRaw": "estimatedLawnSquareFootage",
                "visible": true
            },

            "effectiveLawnSquareFootage": {
                "type": "NonNegativeInt",
                "keyRaw": "effectiveLawnSquareFootage",
                "nullable": true,
                "visible": true
            },

            "displayLawnSquareFootage": {
                "type": "NonNegativeInt",
                "keyRaw": "displayLawnSquareFootage",
                "visible": true
            },

            "latitude": {
                "type": "Latitude",
                "keyRaw": "latitude",
                "nullable": true,
                "visible": true
            },

            "longitude": {
                "type": "Longitude",
                "keyRaw": "longitude",
                "nullable": true,
                "visible": true
            },

            "answers": {
                "type": "Answer",
                "keyRaw": "answers",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "question": {
                            "type": "Question",
                            "keyRaw": "question",

                            "selection": {
                                "fields": {
                                    "services": {
                                        "type": "Service",
                                        "keyRaw": "services",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=831eb33896904cfd3251bfe3b00538bca7dcce132a0ab1f8cfe4d39659a795e0";