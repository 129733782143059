import { graphql, UpcomingOrdersCheckQueryStore, DeactivateAddressMutationStore } from '$houdini'

const customerUpcomingOrdersCheckQuery: UpcomingOrdersCheckQueryStore = graphql`
	query UpcomingOrdersCheckQuery {
		upcomingOrders {
			data {
				id
				customerId
				residenceId
				jobStatus
			}
		}
	}
`

const deactivateAddressMutation: DeactivateAddressMutationStore = graphql`
	mutation DeactivateAddressMutation($deactivateResidenceId: PositiveInt!) {
		deactivateResidence(id: $deactivateResidenceId) {
			id
		}
	}
`

export async function loadCustomerUpcomingOrdersCheck() {
	//This call filters on the API side to the ctx userAccountId
	const { data } = await customerUpcomingOrdersCheckQuery.fetch()

	if (!data?.upcomingOrders?.data) {
		return []
	}

	return data?.upcomingOrders?.data
}

export async function deactivateAddress(residenceId: number) {
	const { data } = await deactivateAddressMutation.mutate({
		deactivateResidenceId: residenceId,
	})

	if (!data?.deactivateResidence.id) {
		throw new Error(`Failed to deactivate address ${residenceId}`)
	}

	return data.deactivateResidence.id
}
