export default {
    "name": "UserProfileUpdateProviderHomeRegion",
    "kind": "HoudiniMutation",
    "hash": "3c7ed328da8e839487d6dd75289445f3f481193bb5e74230d3ab10923a39e4d0",

    "raw": `mutation UserProfileUpdateProviderHomeRegion($providerId: PositiveInt!, $regionId: PositiveInt!) {
  updateProviderHomeRegion(providerId: $providerId, regionId: $regionId) {
    id
    homeRegionId
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderHomeRegion": {
                "type": "Provider",
                "keyRaw": "updateProviderHomeRegion(providerId: $providerId, regionId: $regionId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "homeRegionId": {
                            "type": "PositiveInt",
                            "keyRaw": "homeRegionId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerId": "PositiveInt",
            "regionId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=de6b4cbb0967ed7e268df4937bd323b10e0e5e55fcfb22d1e8cd66346ad69e91";