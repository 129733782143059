import component from './OrderCreated.svelte'

import { graphql, orderCreatedJobQueryStore, type orderCreatedJobQuery$result } from '$houdini'

import { AppContext } from 'types/common'

export type OrderCreatedJob = Exclude<orderCreatedJobQuery$result['job'], null>

const orderCreatedJobQuery: orderCreatedJobQueryStore = graphql`
	query orderCreatedJobQuery($jobId: PositiveInt!) {
		job(id: $jobId) {
			id
			residence {
				street
				city
				state
				zip
			}
			specialInstructions
			requestedSchedule
			service {
				name
			}
			transaction {
				total
			}
			answers {
				id
				answer
			}
		}
	}
`

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.order-created',
		route: 'order-created',
		querystringParameters: ['orderId'],
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, parameters) {
			let job: OrderCreatedJob | null = null
			const { data } = await orderCreatedJobQuery.fetch({
				variables: {
					jobId: parseInt(parameters.orderId, 10),
				},
			})

			if (data?.job) {
				job = data.job
			}

			return {
				job,
			}
		},
	})
}
