import pFileReader from 'promise-file-reader'

export async function getMaxImageDimensions(mediator) {
	const getMaxImageDimensionsQuery = `#graphql
		query GetGlobalSetting($lookup: SettingLookup!) {
			getGlobalSetting(lookup: $lookup) {
				value
			}
		}
	`

	const { getGlobalSetting: maxImageDimensions } = await mediator.call('apiFetch', getMaxImageDimensionsQuery, {
		lookup: {
			category: 'Admin settings',
			defaultValue: '1600',
			name: 'Max image dimensions',
			settingType: 'IMPORTANT_CONFIGURATION',
		},
	})

	return parseInt(maxImageDimensions?.value, 10) || 1600
}

export async function attachFilesToJob(mediator, newfile, association) {
	const attachFilesToJobMutation = `#graphql
        mutation AttachFilesToJob($input: NewJobFileInput!) {
            attachFilesToJob(input: $input) {
                id
                fileId
                rank
                public
                file {
                    created
                    id
                    name
                    path
                    mimeType
                }
            }
        }
    `

	let data = await pFileReader.readAsDataURL(newfile.File)
	data = data.replace(/^data:.*;base64,/, '') // Remove the data:image/jpeg;base64, part

	const response = await mediator.call('apiFetch', attachFilesToJobMutation, {
		input: {
			imageFile: {
				base64String: data,
				fileName: newfile.name,
			},
			imageFileType: association.imageFileType,
			jobId: association.jobId,
			publicFile: newfile.public,
			rank: newfile.rank,
		},
	})

	const url = `__fileServerUrl__${response.attachFilesToJob.file.path}`

	// We should only get here if we don't throw, which *should* mean we know `data` is available. See `handleError` for when the request throws.
	return {
		fileId: response.attachFilesToJob.fileId,
		public: response.attachFilesToJob.public,
		rank: response.attachFilesToJob.rank,
		path: url,
		mimeType: response.attachFilesToJob.file.mimeType,
		createdDate: response.attachFilesToJob.file.created,
	}
}

export async function detachFilesFromJob(mediator, newfile, association) {
	const detachFilesFromJobMutation = `#graphql
        mutation DetachFilesFromJob($jobId: PositiveInt!, $fileIds: [PositiveInt!]!) {
            detachFilesFromJob(jobId: $jobId, fileIds: $fileIds)
        }
    `

	return await mediator.call('apiFetch', detachFilesFromJobMutation, {
		jobId: association.jobId,
		fileIds: newfile,
	})
}
