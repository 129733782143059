export default {
    "name": "AddNewAddressWithPriceQuoteMutation",
    "kind": "HoudiniMutation",
    "hash": "ca0831fb22b0032a4ac00351c2fb3115de356e57016949bba14507933fb1ac48",

    "raw": `mutation AddNewAddressWithPriceQuoteMutation($serviceId: PositiveInt, $newResidence: NewResidence!) {
  newResidence(newResidence: $newResidence) {
    ...NewResidenceData
    parcel {
      priceQuote(serviceId: $serviceId) {
        price
      }
      id
    }
    id
  }
}

fragment NewResidenceData on Residence {
  id
  regionId
  region {
    status
    id
  }
  customerId
  status
  street
  city
  state
  zip
  country
  county
  googleMapsPlaceId
  estimatedLawnSquareFootage
  effectiveLawnSquareFootage
  displayLawnSquareFootage
  latitude
  longitude
  answers {
    id
    question {
      services {
        id
      }
      id
    }
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newResidence": {
                "type": "Residence",
                "keyRaw": "newResidence(newResidence: $newResidence)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "region": {
                            "type": "Region",
                            "keyRaw": "region",

                            "selection": {
                                "fields": {
                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "customerId": {
                            "type": "PositiveInt",
                            "keyRaw": "customerId",
                            "visible": true
                        },

                        "status": {
                            "type": "ResidenceStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "county": {
                            "type": "String",
                            "keyRaw": "county",
                            "visible": true
                        },

                        "googleMapsPlaceId": {
                            "type": "String",
                            "keyRaw": "googleMapsPlaceId",
                            "nullable": true,
                            "visible": true
                        },

                        "estimatedLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "estimatedLawnSquareFootage",
                            "visible": true
                        },

                        "effectiveLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "effectiveLawnSquareFootage",
                            "nullable": true,
                            "visible": true
                        },

                        "displayLawnSquareFootage": {
                            "type": "NonNegativeInt",
                            "keyRaw": "displayLawnSquareFootage",
                            "visible": true
                        },

                        "latitude": {
                            "type": "Latitude",
                            "keyRaw": "latitude",
                            "nullable": true,
                            "visible": true
                        },

                        "longitude": {
                            "type": "Longitude",
                            "keyRaw": "longitude",
                            "nullable": true,
                            "visible": true
                        },

                        "answers": {
                            "type": "Answer",
                            "keyRaw": "answers",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "question": {
                                        "type": "Question",
                                        "keyRaw": "question",

                                        "selection": {
                                            "fields": {
                                                "services": {
                                                    "type": "Service",
                                                    "keyRaw": "services",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        },

                        "parcel": {
                            "type": "Parcel",
                            "keyRaw": "parcel",

                            "selection": {
                                "fields": {
                                    "priceQuote": {
                                        "type": "PriceQuote",
                                        "keyRaw": "priceQuote(serviceId: $serviceId)",

                                        "selection": {
                                            "fields": {
                                                "price": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "price",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    },

                    "fragments": {
                        "NewResidenceData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceId": "PositiveInt",
            "newResidence": "NewResidence"
        },

        "types": {
            "NewResidence": {
                "city": "String",
                "country": "String",
                "county": "String",
                "customerId": "PositiveInt",
                "googleMapsPlaceId": "String",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "state": "String",
                "street": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=44da74512fb6e4f5b5a7f4a7b509a6d926e78f0105bf00a7009ae05a1e7817e3";