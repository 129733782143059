export default {
    "name": "UpdateProviderServiceStatusMutationProviderManagement",
    "kind": "HoudiniMutation",
    "hash": "1b564ddb2d019d357e8fab7d1fc072f520684bb7d45685a44b2f284a52127b88",

    "raw": `mutation UpdateProviderServiceStatusMutationProviderManagement($certifiedServicesPermission: CertifiedServicesPermission!) {
  setProviderService(certifiedServicesPermission: $certifiedServicesPermission) {
    id
    status
    authorizedServices {
      id
      service {
        id
        name
        codeName
        description
        active
      }
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setProviderService": {
                "type": "Provider",
                "keyRaw": "setProviderService(certifiedServicesPermission: $certifiedServicesPermission)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "authorizedServices": {
                            "type": "ProviderService",
                            "keyRaw": "authorizedServices",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "codeName": {
                                                    "type": "String",
                                                    "keyRaw": "codeName",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "certifiedServicesPermission": "CertifiedServicesPermission"
        },

        "types": {
            "CertifiedServicesPermission": {
                "certified": "Boolean",
                "providerId": "PositiveInt",
                "serviceId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b32729cdd45d5d76714e0f3e0d2feb2098fa6b03fa33ec04fc57160282e000df";