export default {
    "name": "CompletedOrdersCount",
    "kind": "HoudiniQuery",
    "hash": "014bf202d0f57bbffaa4cfd3d413992ac508f4a8b95d63883e7593131b75de91",

    "raw": `query CompletedOrdersCount($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
  completedOrders(orderBy: $orderBy, pagination: $pagination) {
    info {
      totalItems
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "completedOrders": {
                "type": "JobResponse",
                "keyRaw": "completedOrders(orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "orderBy": "JobOrderBy",
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6a05c2d6a858ff60e1c9a15a0a9a1f75aef6da57b687373fff8c9d17ba7e7576";