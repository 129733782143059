<script lang="ts">
	import { buildUrl } from '@isoftdata/utility-url'
	import Button from '@isoftdata/svelte-button'

	interface Residence {
		street: string
		googleMapsPlaceId: string | null
		latitude: number | null
		longitude: number | null
	}

	export let residence: Residence

	if (!residence.googleMapsPlaceId) {
		throw new Error('Google Maps Place ID is required')
	}

	$: selectedJobGoogleMapsUrl = buildUrl(`https://www.google.com/maps/dir/`, {
		api: 1,
		destination_place_id: residence.googleMapsPlaceId ?? '',
		destination: `${residence.latitude}, ${residence.longitude}`,
	})

	$: selectedJobAppleMapsUrl = buildUrl(`http://maps.apple.com/?`, {
		daddr: `${residence.latitude}, ${residence.longitude}`,
		dirflg: 'd',
		t: 'h',
	})
</script>

<span>Directions</span>
<div class="d-flex justify-content-between">
	<Button
		outline
		class="mt-2 w-100"
		icon={{
			class: 'fa-brands',
			icon: 'google',
		}}
		color="success"
		href={selectedJobGoogleMapsUrl}
		target="lawn_hiro_provider_google_map"
	></Button>
	<Button
		outline
		class="mt-2 ml-1 w-100"
		icon={{
			class: 'fa-brands',
			icon: 'apple',
		}}
		color="success"
		href={selectedJobAppleMapsUrl}
		target="lawn_hiro_provider_apple_map"
	></Button>
</div>
