export default {
    "name": "CompleteProviderOnboardingApplication",
    "kind": "HoudiniMutation",
    "hash": "f544be8b0912b9d772bee73925c10f65d0fa7754530d7d5d445ffba90dbd6e54",

    "raw": `mutation CompleteProviderOnboardingApplication($providerOnboardingApplicationId: PositiveInt!) {
  completeProviderOnboardingApplication(
    providerOnboardingApplicationId: $providerOnboardingApplicationId
  ) {
    id
    completed
    status
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "completeProviderOnboardingApplication": {
                "type": "ProviderApplication",
                "keyRaw": "completeProviderOnboardingApplication(providerOnboardingApplicationId: $providerOnboardingApplicationId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "completed": {
                            "type": "DateTime",
                            "keyRaw": "completed",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "providerOnboardingApplicationId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=f04e544a105e8372a2a25c2a0488cce4f0d51fbf89c6ebc208a0119bca79c531";