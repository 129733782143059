import component from './Location.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.location',
		route: 'location',
		defaultChild: 'zone',
		template: {
			svelte: true,
			component,
			options: {},
		},

		async resolve(_data, _parameters) {
			const childStates = [
				{
					name: 'app.admin.location.zone',
					title: 'Zone',
					inherit: false,
				},
				{
					name: 'app.admin.location.region',
					title: 'Region',
					inherit: false,
				},
			]

			return Promise.resolve({
				childStates,
			})
		},
	})
}
