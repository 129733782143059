export default async(mediator, userAccountId) => {
	const residenceQuery = `#graphql
		query Residences($filter: ResidenceFilter) {
			residences(filter: $filter) {
				data {
					id
					street
					city
					state
					zip
					regionId
					region {
						status
					}
					latitude
					longitude
					googleMapsPlaceId
					displayLawnSquareFootage
					answers {
						id
						question {
							services {
								id
							}
						}
					}
				}
			}
		}
	`

	const res = await mediator.call('apiFetch', residenceQuery, {
		filter: {
			userAccountId,
			residenceStatus: 'ACTIVE',
		},
	})

	return res?.residences?.data.sort((a, b) => {
		if (a.region?.status === 'ACTIVE' && b.region?.status !== 'ACTIVE') {
			return -1
		} else if (a.region?.status !== 'ACTIVE' && b.region?.status === 'ACTIVE') {
			return 1
		} else {
			return 0
		}
	})
}
