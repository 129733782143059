<script lang="ts">
	export let steps: {
		stepNumber: number
		label: string
		completed: boolean
	}[]
</script>

<div
	class="progresses mb-3"
	style="margin: auto"
>
	{#each steps as step}
		<div class="steps">
			<div
				class="steps-circle"
				class:complete={step.completed}
			>
				{#if step.completed}
					<i class="fas fa-check" />
				{:else}
					<span>{step.stepNumber}</span>
				{/if}
			</div>
			<span class="steps-label">{step.label}</span>
		</div>
		<div
			class="line"
			class:complete={step.completed}
		></div>
	{/each}
</div>

<style>
	.progresses {
		display: flex;
		max-width: 600px;
	}

	.line {
		flex: 1;
		height: 3px;
		background: grey;
		margin-top: 18px;
	}

	.line:last-child {
		display: none;
	}

	.steps {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 40px;
	}

	.steps-circle {
		display: flex;
		background-color: white;
		color: black;
		font-size: 14px;
		width: 40px;
		height: 40px;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 2px solid grey;
	}

	.steps-label {
		text-align: center;
		font-size: small;
	}

	.line.complete,
	.steps-circle.complete {
		background-color: #63d19e;
		color: #fff;
		border: 2px solid #63d19e;
	}
</style>
