<script lang="ts">
	import Table, { Td } from '@isoftdata/svelte-table'
	import formatCurrency from 'utility/format/format-currency'
	import formatPhoneNumber from 'utility/format/format-phone-number'
	import ProviderProfilePicture from 'components/ProviderProfilePicture.svelte'
	import type { LoadRegionHelperProviders$result } from '$houdini'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import toTitleCase from 'to-title-case'

	export let providers: LoadRegionHelperProviders$result['providers']['data']
</script>

<Table
	responsive
	class="mb-0"
	rows={providers}
	columns={[
		{ property: 'userAccount[fullName]', name: 'Name', defaultSortColumn: true },
		{ property: 'userAccount[email]', name: 'Email' },
		{ property: 'userAccount[mobile]', name: 'Mobile' },
		{ property: 'providerMetricData[lastJobDate]', name: 'Last Job' },
		{ property: 'providerMetricData[numberOfJobsCompletedInRegion]', name: 'Jobs in Region', title: 'Number of jobs completed in the last 30 days in this region', numeric: true },
		{ property: 'providerMetricData[numberOfJobsCompleted]', name: 'Jobs in all Regions', title: 'Number of jobs completed in the last 30 days across all regions', numeric: true },
		{ property: 'userAccount[status]', name: 'Status' },
		{ property: 'providerMetricData[paidOutInRegion]', name: 'Paid Out in Region', title: 'Total payout in the last 30 days in this region', numeric: true },
		{ property: 'providerMetricData[paidOut]', name: 'Paid Out in all Regions', title: 'Total payout in the last 30 days across all regions', numeric: true },
		{ property: 'userAccount[lastLoginDate]', name: 'Last Login' },
	]}
	filterEnabled={false}
	let:row
>
	<svelte:fragment slot="no-rows">
		<tr>
			<td colspan="10">
				<span>No providers found</span>
			</td>
		</tr>
	</svelte:fragment>
	<tr>
		<Td property="userAccount[fullName]">
			<div class="d-flex align-items-center">
				<ProviderProfilePicture
					profilePictureUrl={row.profilePictureFile?.path ? formatImageFileUrl(row.profilePictureFile?.path) : null}
					pictureSize={50}
				/>
				{row.userAccount.fullName}
			</div>
		</Td>
		<Td property="userAccount[email]"><a href="mailto:{row.userAccount.email}">{row.userAccount.email}</a></Td>
		<Td property="userAccount[mobile]"><a href="tel:{row.userAccount.mobile}">{formatPhoneNumber(row.userAccount.mobile)}</a></Td>
		<Td property="providerMetricData[lastJobDate]">{row.providerMetricData.lastJobDate ? row.providerMetricData.lastJobDate?.toLocaleDateString() : 'Never'}</Td>
		<Td property="providerMetricData[numberOfJobsCompletedInRegion]">{row.providerMetricData.numberOfJobsCompletedInRegion}</Td>
		<Td property="providerMetricData[numberOfJobsCompleted]">{row.providerMetricData.numberOfJobsCompleted}</Td>
		<Td property="userAccount[status]">{toTitleCase(row.userAccount.status)}</Td>
		<Td property="providerMetricData[paidOutInRegion]">{formatCurrency(row.providerMetricData.paidOutInRegion)}</Td>
		<Td property="providerMetricData[paidOut]">{formatCurrency(row.providerMetricData.paidOut)}</Td>
		<Td property="userAccount[lastLoginDate]">{row.userAccount.lastLoginDate ? row.userAccount.lastLoginDate?.toLocaleDateString() : 'Never'}</Td>
	</tr>
</Table>
