import { graphql, ActiveWalletStore, type ActiveWallet$result, AddCardTocustomerWalletStore, EditCardInCustomerWalletStore } from '$houdini'

export type PaymentMethodCard = Exclude<Exclude<Exclude<ActiveWallet$result['session'], null>['customer'], null>['activeWallet'], null>[number]

export type PaymentMethodSuccessResponse = {
	cardBin: string
	cardLast4: string
	cardNumber: string
	cardSecurityCode: boolean
	cardType: string
	cardholderName: string
	expiryMonth: string
	expiryYear: string
	token: string
}

const activeWalletQuery: ActiveWalletStore = graphql`
	query ActiveWallet {
		session {
			customer {
				activeWallet {
					id
					lastFour
					expirationMonth
					expirationYear
					cardType
				}
			}
		}
	}
`

const addCardToWalletMutation: AddCardTocustomerWalletStore = graphql`
	mutation AddCardTocustomerWallet($newCard: NewCustomerCard!) {
		addCardToCustomerWallet(newCard: $newCard) {
			id
			lastFour
			expirationMonth
			expirationYear
			cardType
		}
	}
`

const updateCardINWalletMutation: EditCardInCustomerWalletStore = graphql`
	mutation EditCardInCustomerWallet($cardInfo: editCustomerCard!) {
		editCardInCustomerWallet(cardInfo: $cardInfo) {
			id
			lastFour
			expirationMonth
			expirationYear
			cardType
		}
	}
`

export async function getActiveWallet() {
	const { data } = await activeWalletQuery.fetch()

	if (!data?.session?.customer) {
		return null
	}

	return data.session.customer.activeWallet
}

export async function addCustomerCardToWallet(newCard: PaymentMethodSuccessResponse) {
	const { data } = await addCardToWalletMutation.mutate({
		newCard: {
			lastFour: parseInt(newCard.cardLast4, 10),
			expirationMonth: parseInt(newCard.expiryMonth, 10),
			expirationYear: parseInt(newCard.expiryYear, 10),
			token: newCard.token,
			cardType: newCard.cardType,
		},
	})

	if (!data?.addCardToCustomerWallet) {
		throw new Error('Failed to add card to wallet')
	}

	return data.addCardToCustomerWallet
}

export async function updateCustomerCardInWallet(cardInfo: PaymentMethodSuccessResponse, cardToUpdateId: number) {
	const { data } = await updateCardINWalletMutation.mutate({
		cardInfo: {
			id: cardToUpdateId,
			lastFour: parseInt(cardInfo.cardLast4, 10),
			expirationMonth: parseInt(cardInfo.expiryMonth, 10),
			expirationYear: parseInt(cardInfo.expiryYear, 10),
			cardType: cardInfo.cardType,
			token: cardInfo.token,
		},
	})

	if (!data?.editCardInCustomerWallet) {
		throw new Error('Failed to update card in wallet')
	}

	return data.editCardInCustomerWallet
}
