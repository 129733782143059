import { format as formatDate } from 'date-fns'
import getFormattedRelativeDate, { type RelativeDateObject } from 'utility/format/format-relative-date'

export function formatRelativeDateTime(date: Date, today = new Date()): RelativeDateObject {
	const { formattedDate, isRelativeFormat } = getFormattedRelativeDate(date, today)
	return {
		formattedDate: `${formattedDate} at ${formatDate(date, 'h a')}`,
		isRelativeFormat,
	}
}

export default formatRelativeDateTime
