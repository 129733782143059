<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import type { AnswerQuestionQuestion } from 'utility/get-quote-helper'

	export let selectedAnswerIds: Set<number>
	export let questions: AnswerQuestionQuestion[]

	function onAnswerClick(question: AnswerQuestionQuestion, answer: { id: number; answer: string }) {
		const existingAnswer = question.answers?.find(questionAnswer => selectedAnswerIds.has(questionAnswer.id))
		if (existingAnswer) {
			selectedAnswerIds.delete(existingAnswer.id)
		}
		selectedAnswerIds.add(answer.id)
		selectedAnswerIds = selectedAnswerIds
	}
</script>

<ul class="list-group">
	{#each questions as question (question.id)}
		<li
			class="list-group-item"
			style="background-color: white;"
		>
			<div class="form-row align-items-end">
				<div class="col-12">
					<h5>{question.question}</h5>
				</div>
				{#if question.answers}
					{#each question.answers as answer}
						<div class="col-12 col-md-auto d-flex flex-column">
							<Button
								id={`answer-button-${answer.id}`}
								class="mb-1"
								size="sm"
								outline={!selectedAnswerIds.has(answer.id)}
								on:click={() => onAnswerClick(question, answer)}
							>
								{answer.answer}
							</Button>
						</div>
					{/each}
				{/if}
			</div>
		</li>
	{/each}
</ul>
