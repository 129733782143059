export default {
    "name": "UpdateProviderOnboardingApplicationStatus",
    "kind": "HoudiniMutation",
    "hash": "ae4bf18b752e9ed1997dd6c880b6706800f2e4c3cb67606f1a96db25fe75087e",

    "raw": `mutation UpdateProviderOnboardingApplicationStatus($status: ProviderApplicationStatus!, $providerOnboardingApplicationId: PositiveInt!, $rejectMessage: String) {
  updateProviderOnboardingApplicationStatus(
    status: $status
    providerOnboardingApplicationId: $providerOnboardingApplicationId
    rejectMessage: $rejectMessage
  ) {
    id
    status
    rejectMessage
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderOnboardingApplicationStatus": {
                "type": "ProviderApplication",
                "keyRaw": "updateProviderOnboardingApplicationStatus(providerOnboardingApplicationId: $providerOnboardingApplicationId, rejectMessage: $rejectMessage, status: $status)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "rejectMessage": {
                            "type": "String",
                            "keyRaw": "rejectMessage",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "status": "ProviderApplicationStatus",
            "providerOnboardingApplicationId": "PositiveInt",
            "rejectMessage": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b74ef8fd7d0377c2c2da35345e0a3c368fc6641ce5a4927c0085a6e5a9c6fc11";