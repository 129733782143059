import component from './EmailVerification.svelte'

import { AppContext, ResolveParameters } from 'types/common'

type EmailVerificationParameters = {
	userId: string
	verificationToken: string
}

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'email-verification',
		route: 'email-verification',
		querystringParameters: ['userId', 'verificationToken'],
		template: {
			svelte: true,
			component,
			options: {},
		},

		async resolve(_data, parameters: ResolveParameters<EmailVerificationParameters>) {
			if (!parameters.userId || !parameters.verificationToken) {
				throw {
					redirectTo: {
						name: 'login',
					},
				}
			}

			return Promise.resolve({
				userId: parameters.userId,
				verificationToken: parameters.verificationToken,
			})
		},
	})
}
