import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadAdminOnboardingApplications'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadAdminOnboardingApplicationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadAdminOnboardingApplicationsStore",
			variables: false,
		})
	}
}

export async function load_LoadAdminOnboardingApplications(params) {
	await initClient()

	const store = new LoadAdminOnboardingApplicationsStore()

	await store.fetch(params)

	return {
		LoadAdminOnboardingApplications: store,
	}
}
