/* eslint-disable no-mixed-spaces-and-tabs */
import template from './user-account.ractive.html'
import { userAccountsQuery } from 'utility/graphql/user-accounts-query'
import getComputedUserAccount from 'utility/get-computed-user-account'

//Ractive components
import makeSelect from '@isoftdata/select'
import makeButton from '@isoftdata/button'
import makeCheckboxButton from '@isoftdata/checkbox-button'
import makeTable from '@isoftdata/table'
import makeUserTable from 'components/admin-user-table'

let accountStatusFilterList = [
	{ value: 'true', label: 'Active Only' },
]

const providersQueryData = `#graphql
	id
	status
	userAccount {
		fullName
		email
	}
	currentlyClockedInto {
		id
		elapsedTime
		jobClaim {
			job {
				residence {
					street
				}
			}
		}
	}
`

const flattenProvider = provider => {
	return {
		...provider,
		...provider.userAccount,
	}
}

const providerServicesQuery = `#graphql
	authorizedServices {
		service {
			id
			name
			description
			active
		}
	}
`

export default ({ stateRouter, mediator }) => {
	stateRouter.addState({
		name: 'app.admin.user-account',
		route: 'user-account',
		querystringParameters: [ 'activeOnly', 'accountType' ],
		template: {
			template,
			components: {
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itButton: makeButton(),
				itCheckboxButton: makeCheckboxButton({ twoway: false }),
				itTable: makeTable(),
				userTable: makeUserTable(mediator),
			},
			computed: {
				computedRegionsForSelectedUser() {
					return this.get('regions').map(region => {
						const userRegions = this.get('authorizedRegions')
						return {
							...region,
							checked: userRegions.some(userRegion => userRegion.id === region.id),
						}
					})
				},
				computedServicesForSelectedUser() {
					return this.get('services').map(service => {
						const userCertifiedServices = this.get('certifiedServices')
						return {
							...service,
							checked: userCertifiedServices.some(userCertifiedService => userCertifiedService.id === service.id),
						}
					})
				},
			},
			async changeProviderStatus(provider, destinationStatus) {
				if (confirm(`Are you sure you want to set ${provider.userAccount.fullName}'s provider status to ${destinationStatus.toLowerCase()}?`)) {
					const setProviderStatusMutation = `#graphql
						mutation SetProviderStatus($status: ProviderStatus!, $providerId: PositiveInt!) {
							setProviderStatus(status: $status, providerId: $providerId) {
								${providersQueryData}
							}
						}
					`

					try {
						const { setProviderStatus: savedProvider } = await mediator.call('apiFetch', setProviderStatusMutation, {
							providerId: provider.id,
							status: destinationStatus,
						})

						this.set('selectedUserAccount.provider', flattenProvider(savedProvider))
					} catch (err) {
						console.error(err?.message || 'An unknown error occurred.')
					}
				}
			},
			async setRegionPermission(regionId, authorized) {
				const userAccountId = this.get('selectedUserAccountId')
				const setUserRegionPermissionMutation = `#graphql
					mutation SetUserRegionPermission($userRegion: EditUserPermissions!) {
						setUserRegionPermission(userRegion: $userRegion)
					}
				`

				try {
					const { setUserRegionPermission } = await mediator.call('apiFetch', setUserRegionPermissionMutation, {
						userRegion: {
							regionId,
							userAccountId,
							active: authorized,
						},
					}) //this mutation returns a boolean value
					if (setUserRegionPermission) {
						// add into the user region list
						this.upsert('authorizedRegions', 'id', { id: regionId })
					} else {
						// remove from user region list
						const authorizedRegionIndex = this.get('authorizedRegions').findIndex(authorizedRegion => authorizedRegion.id === regionId)
						this.splice('authorizedRegions', authorizedRegionIndex, 1)
					}
				} catch (err) {
					console.error(err)
				}
			},

			async setServicesPermission(serviceId, certified) {
				const selectedUserAccount = this.get('selectedUserAccount')
				const setProviderServiceMutation = `#graphql
					mutation SetProviderService($certifiedServicesPermission: CertifiedServicesPermission!) {
						setProviderService(certifiedServicesPermission: $certifiedServicesPermission) {
							${providerServicesQuery}
						}
					}
				`

				try {
					const { setProviderService: providerServices } = await mediator.call('apiFetch', setProviderServiceMutation, {
						certifiedServicesPermission: {
							providerId: selectedUserAccount?.provider?.id,
							serviceId,
							certified,
						},
					})
					this.set({ certifiedServices: providerServices.authorizedServices.map(services => services.service) })
				} catch (err) {
					console.error(err)
				}
			},
		},
		async resolve(_data, parameters) {
			const permissionListQuery = `#graphql
				query Permissions($pagination: PaginatedInput) {
					permissions(pagination: $pagination) {
						data {
							codeName
							category
							displayName
							description
						}
					}
				}
			`

			const regionQuery = `#graphql
				query Regions($pagination: PaginatedInput) {
					regions(pagination: $pagination) {
						data {
							id
							name
							status
						}
					}
				}
			`

			const servicesQuery = `#graphql
				query Services {
					services {
						data {
							id
							name
							description
							active
						}
					}
				}
			`

			let filter = {}
			if (parameters.activeOnly) {
				filter.activeOnly = true
			}

			const [{ userAccounts }, { permissions }] = await Promise.all([
				mediator.call('apiFetch', userAccountsQuery, {
					filter,
					pagination: { pageSize: 0 },
				}),
				mediator.call('apiFetch', permissionListQuery, { pagination: { pageSize: 0 } }),
			])

			const { regions } = await mediator.call('apiFetch', regionQuery, {
				pagination: {
					pageNumber: 1,
					pageSize: 0,
				},
			})

			const { services } = await mediator.call('apiFetch', servicesQuery)

			return {
				userAccounts: userAccounts.data.map(getComputedUserAccount),
				permissions: permissions.data.reduce((acc, permission) => {
					const { codeName, ...restOfPermission } = permission
					return {
						...acc,
						[codeName]: restOfPermission,
					}
				}, {}),
				selectedUserAccountId: null,
				isLoadingUserAccountDetails: false,
				selectedUserAccount: {},
				accountStatusFilter: (parameters?.activeOnly || ''), //Array.isArray(parameters?.activeOnly) ? parameters?.activeOnly.join(',') : parameters?.activeOnly,
				accountStatusFilterList,
				accountTypeFilter: parameters?.accountType || '',
				showRegionList: true,
				regions: regions?.data,
				authorizedRegions: [],
				services: services?.data,
				certifiedServices: [],
			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext

			ractive.on('permissionChange', async(_context, selectedUserAccountId, permissionCode, event) => {
				const setUserAccountPermissionMutation = `#graphql
					mutation SetUserAccountPermission($accessLevel: UserAccessLevel!, $permissionCode: String!, $userAccountId: PositiveInt!) {
						setUserAccountPermission(accessLevel: $accessLevel, permissionCode: $permissionCode, userAccountId: $userAccountId)
					}
				`
				try {
					await mediator.call('apiFetch', setUserAccountPermissionMutation, {
						accessLevel: event?.target?.value?.toUpperCase?.(),
						permissionCode,
						userAccountId: selectedUserAccountId,
					})
				} catch (err) {
					console.error(err?.message || `An unknown error occurred while trying to set the user's permission`)
				}
			})

			ractive.on('selectUserAccount', async(_context, id) => {
				if (ractive.get('selectedUserAccountId') === id) {
					await ractive.set({
						selectedUserAccountId: null,
						selectedUserAccount: {},
					})
				} else {
					await ractive.set({
						selectedUserAccountId: id,
						isLoadingUserAccountDetails: true,
					})

					const userAccountQuery = `#graphql
						query UserAccount($userAccountId: PositiveInt!) {
							userAccount(id: $userAccountId) {
								customer {
									id
									ownedResidences {
										data {
											displayLawnSquareFootage
											street
											city
										}
									}
								}
								provider {
									id
									userAccount {
										fullName
									}
									status
									${providerServicesQuery}
								}
								userRoles {
									permissionName
									value
								}
								authorizedRegions {
									id
								}
							}
						}
					`

					try {
						const res = await mediator.call('apiFetch', userAccountQuery, { userAccountId: id })
						const permissions = ractive.get('permissions')

						let userPermissions = []
						let permissionToHide = [ 'PROVIDERS_CAN_CLAIM_JOBS' ]
						for (const key in permissions) {
							if (!permissionToHide.includes(key)) {
								userPermissions.push({ ...permissions[key], codeName: key, value: res.userAccount.userRoles.find(role => role.permissionName === key)?.value || 'None' })
							}
						}

						await ractive.set({
							authorizedRegions: res?.userAccount?.authorizedRegions,
							certifiedServices: res?.userAccount?.provider?.authorizedServices?.map(services => services.service) || [],
						})

						ractive.set({ selectedUserAccount: { ...res.userAccount, userPermissions } })
					} catch (err) {
						console.error(err?.message || `An unknown error occurred while trying load user account info`)
					} finally {
						ractive.set({ isLoadingUserAccountDetails: false })
					}
				}
			})

			const { cancel: cancelAccountFilterObservers } = ractive.observe('accountStatusFilter accountTypeFilter', () => {
				stateRouter.go(null, {
					activeOnly: ractive.get('accountStatusFilter'),
					accountType: ractive.get('accountTypeFilter'),
				}, { inherit: true, replace: true })
			}, { init: false })

			activateContext.on('destroy', () => cancelAccountFilterObservers())
		},
	})
}
