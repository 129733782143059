import component from './Analytics.svelte'

import { AppContext } from 'types/common'

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.admin.analytics',
		route: 'analytics',
		template: {
			svelte: true,
			component,
			options: {},
		},
		resolve(_data, _parameters) {
			return Promise.resolve()
		},
	})
}
