export default {
    "name": "UpdateProviderOnboardingBasicInfo",
    "kind": "HoudiniMutation",
    "hash": "17b4700697f0620de2649c8b3cdf52e706558836f5841ba7ce189dbb923fe4b4",

    "raw": `mutation UpdateProviderOnboardingBasicInfo($onboardingInfo: ProviderOnboardingInfo!) {
  updateProviderOnboardingBasicInfo(onboardingInfo: $onboardingInfo) {
    id
    street
    state
    zip
    city
    homeRegionId
    homeRegion {
      name
      id
    }
    acceptedProviderAgreement
    userAccount {
      email
      firstName
      lastName
      mobile
      id
    }
    onboardingApplication: latestOnboardingApplication {
      id
      status
      lastUpdated
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderOnboardingBasicInfo": {
                "type": "Provider",
                "keyRaw": "updateProviderOnboardingBasicInfo(onboardingInfo: $onboardingInfo)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "homeRegionId": {
                            "type": "PositiveInt",
                            "keyRaw": "homeRegionId",
                            "nullable": true,
                            "visible": true
                        },

                        "homeRegion": {
                            "type": "Region",
                            "keyRaw": "homeRegion",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "acceptedProviderAgreement": {
                            "type": "Date",
                            "keyRaw": "acceptedProviderAgreement",
                            "nullable": true,
                            "visible": true
                        },

                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",

                            "selection": {
                                "fields": {
                                    "email": {
                                        "type": "EmailAddress",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "visible": true
                                    },

                                    "mobile": {
                                        "type": "PhoneNumber",
                                        "keyRaw": "mobile",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "onboardingApplication": {
                            "type": "ProviderApplication",
                            "keyRaw": "onboardingApplication",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProviderApplicationStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "lastUpdated": {
                                        "type": "DateTime",
                                        "keyRaw": "lastUpdated",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "onboardingInfo": "ProviderOnboardingInfo"
        },

        "types": {
            "ProviderOnboardingAccountInfo": {
                "email": "String",
                "firstName": "String",
                "lastName": "String",
                "mobile": "PhoneNumber"
            },

            "ProviderShippingAddress": {
                "city": "String",
                "state": "String",
                "street": "String",
                "zip": "String"
            },

            "ProviderOnboardingInfo": {
                "acceptProviderAgreement": "Boolean",
                "baseUserAccountInfo": "ProviderOnboardingAccountInfo",
                "hasLiabilityInsurance": "Boolean",
                "providerId": "PositiveInt",
                "providerOnboardingApplicationId": "PositiveInt",
                "shippingAddress": "ProviderShippingAddress",
                "shirtSize": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c01b681a1f2969d0cd52e8d9db6c5c8725b8489119b9110e3fd65203973ee6d5";