<script lang="ts">
	import { updateProviderRegionMutation, type ProviderManagementProvider, type ProviderManagementRegion } from 'utility/provider-management-helper'
	import SwitchCard from './SwitchCard.svelte'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Input from '@isoftdata/svelte-input'
	import type { ComponentEvents, ComponentProps } from 'svelte'

	export let provider: ProviderManagementProvider
	export let regionList: ProviderManagementRegion[] = []
	export let regionsOpen = false
	let filter = ''

	let switchItemList: ComponentProps<SwitchCard>['itemList']
	let filteredSwitchItemList: ComponentProps<SwitchCard>['itemList']

	$: switchItemList = regionList.map(region => {
		return {
			id: region.id,
			name: region.name,
			checked: !!provider.userAccount.authorizedRegions?.find(authorizedRegion => authorizedRegion.id === region.id),
		}
	})

	$: if (filter) {
		filteredSwitchItemList = switchItemList.filter(region => region.name.toLowerCase().includes(filter.toLowerCase()))
	} else {
		filteredSwitchItemList = switchItemList
	}

	async function updateRegion(event: ComponentEvents<SwitchCard>['change']) {
		const authorized = event.detail.checked
		const regionId = event.detail.id
		try {
			const { data } = await updateProviderRegionMutation.mutate({
				userRegion: {
					active: authorized,
					regionId,
					userAccountId: provider.userAccount.id,
				},
			})

			// currently, the API does not return the updated authorized regions
			if (data) {
				const selectedRegion = regionList.find(region => region.id === regionId)
				if (!selectedRegion) {
					throw new Error('Region not found')
				}
				if (provider.userAccount.authorizedRegions) {
					if (authorized) {
						provider.userAccount.authorizedRegions = [
							...provider.userAccount.authorizedRegions,
							{
								id: regionId,
								name: selectedRegion.name,
								status: selectedRegion.status,
							},
						]
					} else {
						provider.userAccount.authorizedRegions = provider.userAccount.authorizedRegions.filter(authorizedRegion => authorizedRegion.id !== regionId)
					}
				} else {
					provider.userAccount.authorizedRegions = [
						{
							id: regionId,
							name: selectedRegion.name,
							status: selectedRegion.status,
						},
					]
				}
			}
		} catch (error) {
			console.error(error)
			alert('An error occurred when giving the user access to the region. Please try again later. If the problem persists, please contact support.')
		}
	}
</script>

<CollapsibleCard
	entireHeaderToggles
	cardClass="mb-1 w-100"
	cardHeaderClass="d-flex justify-content-between align-items-center card-header h5"
	bind:bodyShown={regionsOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<span>
			{provider.userAccount.firstName}'s Regions
			<span class="badge badge-primary mx-2">{provider.userAccount.authorizedRegions?.length ?? 0}</span>
		</span>
	</svelte:fragment>
	<Input
		type="text"
		placeholder="Search Regions"
		bind:value={filter}
		showLabel={false}
	/>
	{#if filteredSwitchItemList.length > 0}
		<SwitchCard
			showLabel={false}
			itemList={filteredSwitchItemList}
			on:change={updateRegion}
		/>
	{:else}
		<div
			class="alert alert-info w-100"
			role="alert"
		>
			No regions found
		</div>
	{/if}
</CollapsibleCard>
