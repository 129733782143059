export default {
    "name": "ChangePassword",
    "kind": "HoudiniMutation",
    "hash": "a4cf6456756020b439c36b7e53a300528ae0aac2da2cf955cc67b0719ace0246",

    "raw": `mutation ChangePassword($email: String!, $resetToken: String, $newPassword: String!) {
  changePassword(
    email: $email
    resetToken: $resetToken
    newPassword: $newPassword
  )
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "changePassword": {
                "type": "Boolean",
                "keyRaw": "changePassword(email: $email, newPassword: $newPassword, resetToken: $resetToken)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "email": "String",
            "resetToken": "String",
            "newPassword": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6f8d541ec63de8a42d8d2d9a8904692219f2db9af0c385041793965e2bdbfd2a";