import template from './provider-profile-picture.html'
import makeButton from '@isoftdata/button'

export default function createProviderProfilePictureComponent() {
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				profilePictureUrl: '',
				pictureSize: 80,
				showFloatingButton: false,
				floatingButtonBootstrapSizeClass: 'btn',
				floatingButtonFontAwesomeIcon: 'fa-camera',
				floatingButtonClass: 'btn-outline-primary',
				floatingButtonIconStyle: null,
			}
		},
		css: `
			.profile-picture {
				width: 100% !important;
				height: 100% !important;
				max-width: 100px !important;
				max-height: 100px !important;
				border-radius: 100%;
				position: relative;
			}
			.btn-floating-position {
				width: 42px;
				height: 32px;
				border-radius: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
			}
			.btn-xs-floating-position {
				width: 28px;
				height: 25px;
				border-radius: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
			}
			.btn-floating {
				width: 37px;
				height: 37px;
				border-radius: 50%;
			}
			.btn-xs-floating {
				width: 25px;
				height: 25px;
				border-radius: 50%;
			}
		`,
		attributes: {
			required: [ 'profilePictureUrl' ],
			optional: [ 'pictureSize', 'showFloatingButton', 'floatingButtonBootstrapSizeClass', 'floatingButtonFontAwesomeIcon', 'floatingButtonClass', 'floatingButtonIconStyle' ],
		},
		components: {
			itButton: makeButton(),
		},
		computed: {
			computedButtonSizeStyleClass() {
				const ractive = this
				const buttonSizeClass = ractive.get('floatingButtonBootstrapSizeClass')

				if (buttonSizeClass === 'btn-xs') {
					return buttonSizeClass
				}
				return 'btn'
			},
			formattedPictureURLWithSize() {
				const ractive = this
				const pictureSize = ractive.get('pictureSize')
				const profilePictureUrl = ractive.get('profilePictureUrl')

				if (!profilePictureUrl) {
					return 'images/placeholder-hiro.png'
				} else {
					return `${profilePictureUrl}?width=${pictureSize}&height=${pictureSize}`
				}
			},
		},
	})
}
