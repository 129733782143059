<script lang="ts">
	import Button from '@isoftdata/svelte-button'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import SelectService from '../SelectService.svelte'
	import GetQuoteCardHeader from './GetQuoteCardHeader.svelte'
	import { getQuoteServiceQuestionsQuery, type GetQuoteStore } from 'utility/get-quote-helper'
	import type { Writable } from 'svelte/store'

	export let quoteData: Writable<GetQuoteStore>
	export let stepOpen: boolean
	export let disabled: boolean

	let stepFinished = false

	async function confirmService(e: Event) {
		e.preventDefault()
		try {
			const questionResults = await getQuoteServiceQuestionsQuery.fetch({
				variables: {
					questionFilter: {
						serviceId: $quoteData.selectedService?.id,
					},
				},
			})
			$quoteData.questions = questionResults.data?.questions?.data || []
			$quoteData.questionsAvailable = $quoteData.questions.length > 0
			$quoteData.selectedAnswerIds = new Set<number>()
			$quoteData.quotedPrice = null
			stepFinished = true
		} catch (error) {
			console.error(error)
		}
	}
	$: header = $quoteData.selectedService?.name && stepFinished && !stepOpen ? $quoteData.selectedService.name : 'Select Service'
</script>

<CollapsibleCard
	entireHeaderToggles
	{disabled}
	cardHeaderClass="card-header d-flex justify-content-between h4 rounded-0"
	cardStyle="border-bottom: none; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
	bind:bodyShown={stepOpen}
	on:show
>
	<svelte:fragment slot="cardHeader">
		<GetQuoteCardHeader
			{header}
			{stepFinished}
		/>
	</svelte:fragment>

	<div class="row">
		<div class="col-12">
			<div
				class="d-flex flex-column justify-content-between"
				style="height: 100%;"
			>
				<SelectService
					bind:selectedService={$quoteData.selectedService}
					services={$quoteData.selectableServices}
					showWarning={false}
					alwaysShowServiceDescription={false}
				/>
				<form
					on:submit={e => {
						confirmService(e)
					}}
					on:submit
				>
					<div class="mt-3">
						<Button
							id="selectedServiceBtn"
							color="primary"
							iconClass="check"
							type="submit"
							disabled={!$quoteData.selectedService}
						>
							Select Service
						</Button>
					</div>
				</form>
			</div>
		</div>
	</div>
</CollapsibleCard>
