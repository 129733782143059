export default {
    "name": "ResendVerificationEmail",
    "kind": "HoudiniMutation",
    "hash": "324a88a2f06163b8ebed72db9ef56c22bb202e44cd7a8a4125fff8e0bbf8711a",

    "raw": `mutation ResendVerificationEmail($email: EmailAddress, $userId: PositiveInt) {
  requestEmailVerification(email: $email, userId: $userId)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "requestEmailVerification": {
                "type": "Void",
                "keyRaw": "requestEmailVerification(email: $email, userId: $userId)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "email": "EmailAddress",
            "userId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ab6dc6aa96955062912c8efb594433c9487811d8ec4e3484180281626b126553";