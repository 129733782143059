import {
	graphql,
	LoadQuestionsStore,
	ChangeAnswerStore,
	ChangeQuestionStore,
	DeleteAnswerStore,
	DeleteQuestionStore,
	LoadTagsStore,
	MakeAnswerStore,
	MakeQuestionStore,
	MakeTagStore,
	type LoadQuestions$result,
	type ChangeQuestion$input,
	type MakeTag$input,
	type LoadTags$result,
} from '$houdini'
import type { WritableDeep } from 'type-fest'

export type Question = LoadQuestions$result['questions']['data'][number]
export type Answer = Exclude<LoadQuestions$result['questions']['data'][number]['answers'], null>[number]

graphql`
	fragment TagPricingsData on TagPricing {
		id
		tagId
		serviceId
		priceAdjustment
		pricingType
		postPriceAdjustment
	}
`

graphql`
	fragment TagData on Tag {
		id
		code
		name
		description
		prompt
		entityType
		tagPricings {
			...TagPricingsData
		}
	}
`

graphql`
	fragment AnswerData on Answer {
		id
		answer
		questionId
		tagId
		tag {
			...TagData
		}
	}
`

graphql`
	fragment QuestionData on Question {
		id
		question
		description
		questionDataType
		answers {
			...AnswerData
		}
	}
`

const loadQuestionsQuery: LoadQuestionsStore = graphql`
	query LoadQuestions {
		questions {
			data {
				...QuestionData
			}
		}
	}
`

const loadTagsQuery: LoadTagsStore = graphql`
	query LoadTags($filter: TagQueryFilter) {
		tags(filter: $filter) {
			data {
				...TagData
			}
		}
	}
`
// Using Make and Change for verbs to prevent type conflicts with the api Create/Update
const newQuestionMutation: MakeQuestionStore = graphql`
	mutation MakeQuestion($question: NewQuestion!) {
		newQuestion(question: $question) {
			...QuestionData
		}
	}
`

const updateQuestionMutation: ChangeQuestionStore = graphql`
	mutation ChangeQuestion($editQuestion: EditQuestion!) {
		editQuestion(editQuestion: $editQuestion) {
			...QuestionData
		}
	}
`

const deleteQuestionMutation: DeleteQuestionStore = graphql`
	mutation DeleteQuestion($deleteQuestionId: PositiveInt!) {
		deleteQuestion(id: $deleteQuestionId)
	}
`

const newAnswerMutation: MakeAnswerStore = graphql`
	mutation MakeAnswer($answer: NewAnswer!) {
		newAnswer(answer: $answer) {
			...AnswerData
		}
	}
`

const updateAnswerMutation: ChangeAnswerStore = graphql`
	mutation ChangeAnswer($editAnswer: EditAnswer!) {
		editAnswer(editAnswer: $editAnswer) {
			...AnswerData
		}
	}
`

const deleteAnswerMutation: DeleteAnswerStore = graphql`
	mutation DeleteAnswer($deleteAnswerId: PositiveInt!) {
		deleteAnswer(id: $deleteAnswerId)
	}
`

const newTagMutation: MakeTagStore = graphql`
	mutation MakeTag($tag: NewTag!) {
		newTag(tag: $tag) {
			...TagData
		}
	}
`

export async function loadQuestions() {
	const { data } = await loadQuestionsQuery.fetch()

	if (!data?.questions.data) {
		return []
	}

	return data.questions.data
}

export async function loadTags(selectedQuestion: LoadQuestions$result['questions']['data'][number]): Promise<LoadTags$result['tags']['data']> {
	const { data } = await loadTagsQuery.fetch({
		variables: {
			filter: {
				entityType: selectedQuestion.questionDataType === 'CHOICE' ? 'RESIDENCE' : 'JOB',
			},
		},
	})

	if (!data?.tags.data) {
		return []
	}

	return data.tags.data
}

interface SaveQuestionArgs {
	questionId?: number | null
	question: string
	description: string
	questionDataType: ChangeQuestion$input['editQuestion']['questionDataType']
	editing: boolean
}

export async function saveQuestion({ questionId, question, description, questionDataType, editing }: SaveQuestionArgs): Promise<Question> {
	try {
		let savedQuestion: WritableDeep<LoadQuestions$result['questions']['data'][number]> | undefined

		if (editing && questionId) {
			const { data } = await updateQuestionMutation.mutate({
				editQuestion: {
					questionId,
					question,
					description,
					questionDataType,
				},
			})

			savedQuestion = data?.editQuestion
		} else {
			const { data } = await newQuestionMutation.mutate({
				question: {
					question,
					description,
					questionDataType,
				},
			})

			savedQuestion = data?.newQuestion
		}

		if (!savedQuestion) {
			throw new Error('Failed to save question')
		}

		return savedQuestion
	} catch (error: unknown) {
		console.error(error)
		throw error
	}
}

export async function deleteQuestion(questionId: number): Promise<void> {
	try {
		await deleteQuestionMutation.mutate({
			deleteQuestionId: questionId,
		})
	} catch (error: unknown) {
		console.error(error)
		throw error
	}
}

interface SaveAnswerArgs {
	answerId?: number
	editing: boolean
	answer: string
	selectedTagId: number | null
	tagCode: string | null
	tagName: string | null
	tagDescription: string | null
	tagPrompt: string | null
	tagEntityType: MakeTag$input['tag']['entityType']
}

export async function saveAnswer({ answerId, editing, answer, selectedTagId, tagCode, tagName, tagDescription, tagPrompt, tagEntityType }: SaveAnswerArgs, questionId: number) {
	try {
		let savedAnswer: Exclude<LoadQuestions$result['questions']['data'][number]['answers'], null>[number] | undefined
		let tagId: number | undefined

		if (!selectedTagId && tagName && tagCode) {
			const { data } = await newTagMutation.mutate({
				tag: {
					entityType: tagEntityType,
					name: tagName,
					code: tagCode,
					description: tagDescription,
					prompt: tagPrompt,
				},
			})

			tagId = data?.newTag.id
		} else if (selectedTagId) {
			tagId = selectedTagId
		} else {
			throw new Error('No tag selected')
		}

		if (editing && answerId && tagId) {
			const { data } = await updateAnswerMutation.mutate({
				editAnswer: {
					id: answerId,
					answer,
					tagId,
				},
			})

			savedAnswer = data?.editAnswer
		} else {
			if (!tagId) {
				throw new Error('No tag selected')
			}
			const newAnswer = await newAnswerMutation.mutate({
				answer: {
					answer,
					questionId,
					tagId,
				},
			})

			savedAnswer = newAnswer.data?.newAnswer
		}

		if (!savedAnswer) {
			throw new Error('Failed to save answer')
		}

		return savedAnswer
	} catch (error: unknown) {
		console.error(error)
		throw error
	}
}

export async function deleteAnswer(answerId: number): Promise<void> {
	try {
		await deleteAnswerMutation.mutate({
			deleteAnswerId: answerId,
		})
	} catch (error: unknown) {
		console.error(error)
		throw error
	}
}
