export default {
    "name": "CreateNewTag",
    "kind": "HoudiniMutation",
    "hash": "7c422df7908211f4666d010ae4d478f147192a4e57d6e70c8929e2cf7de8ed8b",

    "raw": `mutation CreateNewTag($tag: NewTag!) {
  newTag(tag: $tag) {
    id
    code
    name
    description
    prompt
    entityType
    tagPricings {
      ...AdminTagPricingData
      id
    }
  }
}

fragment AdminTagPricingData on TagPricing {
  id
  priceAdjustment
  pricingType
  serviceId
  service {
    name
    id
  }
  postPriceAdjustment
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "newTag": {
                "type": "Tag",
                "keyRaw": "newTag(tag: $tag)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "prompt": {
                            "type": "String",
                            "keyRaw": "prompt",
                            "nullable": true,
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "tagPricings": {
                            "type": "TagPricing",
                            "keyRaw": "tagPricings",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "priceAdjustment": {
                                        "type": "String",
                                        "keyRaw": "priceAdjustment",
                                        "visible": true
                                    },

                                    "pricingType": {
                                        "type": "PricingType",
                                        "keyRaw": "pricingType",
                                        "visible": true
                                    },

                                    "serviceId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "serviceId",
                                        "visible": true
                                    },

                                    "service": {
                                        "type": "Service",
                                        "keyRaw": "service",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "postPriceAdjustment": {
                                        "type": "Boolean",
                                        "keyRaw": "postPriceAdjustment",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "AdminTagPricingData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "tag": "NewTag"
        },

        "types": {
            "NewTag": {
                "code": "NonEmptyString",
                "description": "String",
                "entityType": "EntityType",
                "name": "NonEmptyString",
                "prompt": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4af6a3ca764a167436fa7642bd5773cd60c2aad96762d04ed903a346fc1ae82a";