import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AdminTagServiceQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AdminTagServiceQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AdminTagServiceQueryStore",
			variables: false,
		})
	}
}

export async function load_AdminTagServiceQuery(params) {
	await initClient()

	const store = new AdminTagServiceQueryStore()

	await store.fetch(params)

	return {
		AdminTagServiceQuery: store,
	}
}
