export default {
    "name": "UpdateProviderServiceApplicationStatus",
    "kind": "HoudiniMutation",
    "hash": "a2e601b994614d79009a9fce5d6b376eeeac1bfe9c12be933c1088e0bf6db84a",

    "raw": `mutation UpdateProviderServiceApplicationStatus($status: ProviderServiceApplicationStatus!, $providerServiceApplicationId: Float!, $rejectMessage: String) {
  updateProviderServiceApplicationStatus(
    status: $status
    providerServiceApplicationId: $providerServiceApplicationId
    rejectMessage: $rejectMessage
  ) {
    id
    status
    rejectMessage
    providerApplicationId
    providerApplication {
      status
      id
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProviderServiceApplicationStatus": {
                "type": "ProviderServiceApplication",
                "keyRaw": "updateProviderServiceApplicationStatus(providerServiceApplicationId: $providerServiceApplicationId, rejectMessage: $rejectMessage, status: $status)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "status": {
                            "type": "ProviderServiceApplicationStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "rejectMessage": {
                            "type": "String",
                            "keyRaw": "rejectMessage",
                            "nullable": true,
                            "visible": true
                        },

                        "providerApplicationId": {
                            "type": "PositiveInt",
                            "keyRaw": "providerApplicationId",
                            "nullable": true,
                            "visible": true
                        },

                        "providerApplication": {
                            "type": "ProviderApplication",
                            "keyRaw": "providerApplication",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "status": {
                                        "type": "ProviderApplicationStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "status": "ProviderServiceApplicationStatus",
            "providerServiceApplicationId": "Float",
            "rejectMessage": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=54fa1e8c5d11af85cf3e782b5d05e19175696958b1d755f5952a53be690416bf";