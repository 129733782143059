export default {
    "name": "SubscribeToPushNotifications",
    "kind": "HoudiniMutation",
    "hash": "a1f0740e6c05bd69633eec375f3174fdbfd0f8474344f6c2fd719f83255962a3",

    "raw": `mutation SubscribeToPushNotifications($subscription: NewPushSubscription!) {
  subscribeToPushNotifications(subscription: $subscription)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "subscribeToPushNotifications": {
                "type": "Int",
                "keyRaw": "subscribeToPushNotifications(subscription: $subscription)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "subscription": "NewPushSubscription"
        },

        "types": {
            "PushSubscriptionKeys": {
                "auth": "String",
                "p256dh": "String"
            },

            "NewPushSubscription": {
                "endpoint": "String",
                "expirationTime": "Int",
                "keys": "PushSubscriptionKeys"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=b89b02a99b35777e3e3eeb718fce6606607a9d87d24c3b1e217df2f1f54fdfc8";