import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/LoadRegionHelperSpecificRegion'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class LoadRegionHelperSpecificRegionStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "LoadRegionHelperSpecificRegionStore",
			variables: true,
		})
	}
}

export async function load_LoadRegionHelperSpecificRegion(params) {
	await initClient()

	const store = new LoadRegionHelperSpecificRegionStore()

	await store.fetch(params)

	return {
		LoadRegionHelperSpecificRegion: store,
	}
}
