// Used to parse the state parameters sent from the landing state
export function jsonOrNull(json?: string): Record<string, unknown> | null {
	if (!json) {
		return null
	}
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return JSON.parse(json)
	} catch (e) {
		return null
	}
}

export default jsonOrNull
