import { UpcomingOrdersCountStore, UpcomingOrders$result, UpcomingOrdersStore, graphql } from '$houdini'

const upcomingOrdersDataQuery: UpcomingOrdersStore = graphql`
	query UpcomingOrders($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
		upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
			data {
				id
				residence {
					street
					city
					state
					zip
					country
				}
				jobStatus
				requestedSchedule
				service {
					name
					id
				}
				jobClaims {
					scheduledAt
				}
				answers {
					id
					answer
				}
			}
		}
	}
`

const upcomingOrdersCountQuery: UpcomingOrdersCountStore = graphql`
	query UpcomingOrdersCount($orderBy: [JobOrderBy!], $pagination: PaginatedInput) {
		upcomingOrders(orderBy: $orderBy, pagination: $pagination) {
			info {
				totalItems
			}
		}
	}
`

type UpcomingOrdersData = UpcomingOrders$result['upcomingOrders']['data']

export async function getUpcomingOrders(): Promise<UpcomingOrdersData> {
	const { data } = await upcomingOrdersDataQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 0,
			},
		},
	})

	if (!data) {
		return []
	}

	return data.upcomingOrders.data
}

export async function getUpcomingOrdersCount() {
	const { data } = await upcomingOrdersCountQuery.fetch({
		variables: {
			pagination: {
				pageNumber: 1,
				pageSize: 1,
			},
		},
	})

	if (!data) {
		return 0
	}

	return data.upcomingOrders.info.totalItems
}
