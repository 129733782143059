import { datesFromRange } from '@isoftdata/utility-date-time'

import component from './Region.svelte'
import { AppContext } from 'types/common'
import { loadRegionHelperServices, loadRegionsWithAllTimeData } from 'utility/region-helper'
import { loadGlobalSetting } from 'utility/setting-helper'
import { loadZones } from 'utility/zones-helper'

import { formatISO, parseISO } from 'date-fns'
import { SettingType } from '$houdini/graphql'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.admin.location.region',
		route: 'region',
		querystringParameters: ['regionId'],
		defaultParameters: {
			regionId: null,
		},
		template: {
			component,
			svelte: true,
			options: {},
		},
		async resolve(_data, parameters) {
			const regionId = parameters.regionId ? parseInt(parameters.regionId, 10) : null

			const from = parseISO(datesFromRange('Previous Month', true).from) ?? null
			const to = parseISO(datesFromRange('Previous Month', true).to) ?? null

			// Load all the data needed for the region page in parallel when all the data is loaded then return the data
			const [services, regions, zones, nonHomeRegionRatioSetting] = await Promise.allSettled([
				loadRegionHelperServices({
					pagination: {
						pageNumber: 1,
						pageSize: 0,
					},
				}),
				loadRegionsWithAllTimeData(from, to),
				loadZones(),
				loadGlobalSetting({
					category: 'Region Metrics',
					name: 'Non-Home Region Ratio',
					defaultValue: '0.5',
					settingType: SettingType.IMPORTANT_CONFIGURATION,
				}),
			]).then(results =>
				results.map(result => {
					// If the promise fails then return null.
					// Maybe we can handle the error in the future but for now null is fine
					if (result.status === 'fulfilled') {
						return result.value
					}
					return null
				})
			)

			return {
				selectedRegionId: regionId,
				regions,
				services,
				dates: {
					from: formatISO(from, { representation: 'date' }),
					to: formatISO(to, { representation: 'date' }),
				},
				range: 'This Month',
				zones,
				nonHomeRegionRatioSetting,
			}
		},
	})
}
