export default {
    "name": "LoadPassedScheduleTimeJobClaims",
    "kind": "HoudiniQuery",
    "hash": "600694d51f89131d6a516fd1c3313868745a32c36304364c04927df76486c2a4",

    "raw": `query LoadPassedScheduleTimeJobClaims($pagination: PaginatedInput, $filter: JobClaimFilter) {
  jobClaims(pagination: $pagination, filter: $filter) {
    data {
      isLate
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "jobClaims": {
                "type": "JobClaimResponse",
                "keyRaw": "jobClaims(filter: $filter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "JobClaim",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "isLate": {
                                        "type": "Boolean",
                                        "keyRaw": "isLate",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "filter": "JobClaimFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "JobClaimFilter": {
                "providerId": "PositiveInt",
                "scheduledAtFrom": "DateTime",
                "scheduledAtTo": "DateTime",
                "statuses": "JobClaimStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=48bdd376cdf9fc99527d56b5667c631f3784d5fb2a6611d7aa07fedaa6073022";