export default {
    "name": "LoadRegionHelperSpecificServiceOffer",
    "kind": "HoudiniQuery",
    "hash": "457800b017dcdc1b3ce63b0feeae1b01d9e4731bbd315a18013b5ba444cff547",

    "raw": `query LoadRegionHelperSpecificServiceOffer($serviceOfferId: PositiveInt!) {
  serviceOffer(id: $serviceOfferId) {
    ...RegionHelperServiceOfferData
    id
  }
}

fragment RegionHelperServiceOfferData on ServiceOffer {
  id
  active
  service {
    id
    name
  }
  regionId
  chargeMinimum
  chargePerSquareFoot
  chargeFixed
  costPercent
  serviceId
  timeRange
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "serviceOffer": {
                "type": "ServiceOffer",
                "keyRaw": "serviceOffer(id: $serviceOfferId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "service": {
                            "type": "Service",
                            "keyRaw": "service",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "regionId": {
                            "type": "PositiveInt",
                            "keyRaw": "regionId",
                            "visible": true
                        },

                        "chargeMinimum": {
                            "type": "String",
                            "keyRaw": "chargeMinimum",
                            "visible": true
                        },

                        "chargePerSquareFoot": {
                            "type": "String",
                            "keyRaw": "chargePerSquareFoot",
                            "visible": true
                        },

                        "chargeFixed": {
                            "type": "String",
                            "keyRaw": "chargeFixed",
                            "nullable": true,
                            "visible": true
                        },

                        "costPercent": {
                            "type": "String",
                            "keyRaw": "costPercent",
                            "visible": true
                        },

                        "serviceId": {
                            "type": "PositiveInt",
                            "keyRaw": "serviceId",
                            "visible": true
                        },

                        "timeRange": {
                            "type": "String",
                            "keyRaw": "timeRange",
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "RegionHelperServiceOfferData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "serviceOfferId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a5b910ca53f536f16f3fd37f60f6dfa725d9aabc7c7409ff3ea780048ca01df1";