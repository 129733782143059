import { graphql, ToggleTransactionStatusStore } from '$houdini'

const resolveTransactionStatusMutation: ToggleTransactionStatusStore = graphql`
	mutation ToggleTransactionStatus($transactionId: PositiveInt!) {
		toggleTransactionStatus(transactionId: $transactionId) {
			id
			total
			paymentStatus
			invoiceStatus
			job {
				id
				service {
					name
				}
			}
			customer {
				userAccount {
					fullName
					email
				}
			}
			transactionPayments {
				created
				gatewayMessage
				transactionType
				transactionPaymentClassification
			}
		}
	}
`

export async function toggleTransactionStatus(transactionId: number) {
	const { data } = await resolveTransactionStatusMutation.mutate({
		transactionId,
	})

	if (!data?.toggleTransactionStatus) {
		throw new Error('Failed to toggle transaction status')
	}

	return data.toggleTransactionStatus
}
