<script lang="ts">
	import Label from '@isoftdata/svelte-label'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Icon from '@isoftdata/svelte-icon'
	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import Alert from '@isoftdata/svelte-alert'
	import UserProfile from 'components/UserProfile.svelte'
	import ProgressBar from 'components/ProgressBar.svelte'
	import FileInput from '@isoftdata/svelte-file-input'

	import {
		type Provider,
		type ServiceApplicationStatus as ServiceApplicationStatusType,
		type PictureFileStatus as PictureFileStatusType,
		notInterestedServiceApplicationMutation,
		updateProviderBasicInfoMutation,
		uploadFileToOnboardingApplicationMutation,
	} from 'utility/provider-application-helper'
	import { type ComponentProps, getContext } from 'svelte'

	import states from 'utility/states'
	import type { ProviderOnboardingImageUploadType$options as ProviderOnboardingImageUploadType, UpdateProviderOnboardingBasicInfo$input } from '$houdini'
	import { ProviderApplicationStatus, ProviderServiceApplicationStatus, PictureFileStatus } from '$houdini'
	import { readAsDataURL } from '@isoftdata/promise-file-reader'
	import formatImageFileUrl from 'utility/format/format-image-file-url'
	import type { Mediator } from 'types/common'
	import toTitleCase from 'to-title-case'
	import { updateUserDOB } from 'utility/profile-helper'
	import { getSession } from 'stores/session'

	export let provider: Provider
	export let shirtSizes: string[]
	const session = getSession()

	type ProcessStatus = 'NEED_TO_COMPLETE' | 'PENDING_REVIEW' | 'COMPLETED' | 'NEED_CORRECTION'
	// This type is the input type of my save function. Omits the providerOnboardingApplicationId from the input as it will always be the current application id.
	type OnboardingBasicInfoInputs = Omit<UpdateProviderOnboardingBasicInfo$input['onboardingInfo'], 'providerOnboardingApplicationId'>

	const mediator = getContext<Mediator>('mediator')
	const ANIMATION_DURATION = 200
	const processStatusMap: Record<ProcessStatus, { status: string; textColor: string }> = {
		NEED_TO_COMPLETE: { status: 'Need To Complete', textColor: 'text-secondary' },
		PENDING_REVIEW: { status: 'Pending Review', textColor: 'text-info' },
		COMPLETED: { status: 'Completed', textColor: 'text-success' },
		NEED_CORRECTION: { status: 'Need Correction', textColor: 'text-danger' },
	}

	const serviceApplicationStatusMap: Record<ServiceApplicationStatusType, { icon: ComponentProps<Icon>['icon']; description: string; color: string }> = {
		IN_REVIEW: { icon: 'hourglass-half', description: 'Pending Admin Review', color: 'text-info' },
		APPROVED: { icon: 'check', description: 'Approved', color: 'text-success' },
		REJECTED: { icon: 'triangle-exclamation', description: 'Rejected', color: 'text-danger' },
	}

	const pictureFileStatusMap: Record<PictureFileStatusType, ProcessStatus> = {
		APPROVED: 'COMPLETED',
		REJECTED: 'NEED_CORRECTION',
		IN_REVIEW: 'PENDING_REVIEW',
		NOT_UPLOADED: 'NEED_TO_COMPLETE',
	}

	let openCard: 'BASIC' | 'SERVICES' | 'TRANSPORTATION' | 'LICENSE' | 'AGRREEMENT' | 'NONE' = 'NONE'
	let openServiceCard: number | null = null
	let alertBadge: { show: boolean; message: string; heading: string; color: ComponentProps<Alert>['color'] } = { show: false, message: '', heading: '', color: undefined }

	let basicInfoStatus: ProcessStatus
	let servicesStatus: ProcessStatus
	let transportationStatus: ProcessStatus
	let licenseStatus: ProcessStatus
	let agreementStatus: ProcessStatus

	let newTransportationImageUploaded = false
	let newLicenseImageUploaded = false
	let uploadingFileImage = false
	let dateOfBirthChanged = false
	let dobChangedSaved = false

	let serviceEquipmentApprovalCounts = {
		approved: 0,
		pending: 0,
		rejected: 0,
	}

	let serviceEquipmentApprovedSummary = ''
	let serviceEquipmentPendingSummary = ''
	let serviceEquipmentRejectedSummary = ''

	let steps: ComponentProps<ProgressBar>['steps']

	$: provider.city = provider.city ? toTitleCase(provider.city) : ''
	$: disableCollapsibleCard = provider.onboardingApplicationStatus === ProviderApplicationStatus.COMPLETED || provider.onboardingApplicationStatus === ProviderApplicationStatus.REJECTED

	$: basicInfoStatus =
		provider.userAccount.email &&
		provider.userAccount.firstName &&
		provider.userAccount.lastName &&
		provider.userAccount.mobile &&
		provider.userAccount.provider?.dateOfBirth &&
		provider.street &&
		provider.city &&
		provider.state &&
		provider.zip &&
		provider.tshirtSize
			? 'COMPLETED'
			: 'NEED_TO_COMPLETE'
	$: servicesStatus = provider.services ? getServiceStatus() : 'NEED_TO_COMPLETE'
	$: transportationStatus = provider.transportationPictureFile.status ? pictureFileStatusMap[provider.transportationPictureFile.status] : 'NEED_TO_COMPLETE'
	$: licenseStatus = provider.driversLicensePictureFile.status ? pictureFileStatusMap[provider.driversLicensePictureFile.status] : 'NEED_TO_COMPLETE'
	$: agreementStatus = provider.hasAcceptedProviderAgreement ? 'COMPLETED' : 'NEED_TO_COMPLETE'
	$: getServiceEquipmentApprovalCounts(provider.services)
	$: getOnboardingCurrentStatusMessage(provider.onboardingApplicationStatus)

	$: steps = [
		{
			stepNumber: 1,
			label: 'Fill Out Application',
			completed: provider.onboardingApplicationStatus !== ProviderApplicationStatus.INFORMATION_REQUIRED && provider.onboardingApplicationStatus !== ProviderApplicationStatus.HRIS_ERROR,
		},
		{
			stepNumber: 2,
			label: 'Application In Review',
			completed:
				provider.onboardingApplicationStatus === ProviderApplicationStatus.IN_REVIEW ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.APPLICATION_APPROVED ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.SENT_HRIS_INVITE ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.COMPLETED,
		},
		{
			stepNumber: 3,
			label: 'Application Approved',
			completed:
				provider.onboardingApplicationStatus === ProviderApplicationStatus.APPLICATION_APPROVED ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.SENT_HRIS_INVITE ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.COMPLETED,
		},
		{
			stepNumber: 4,
			label: 'Payroll Invitation',
			completed:
				provider.onboardingApplicationStatus === ProviderApplicationStatus.SENT_HRIS_INVITE ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED ||
				provider.onboardingApplicationStatus === ProviderApplicationStatus.COMPLETED,
		},
		{
			stepNumber: 5,
			label: 'Finished!',
			completed: provider.onboardingApplicationStatus === ProviderApplicationStatus.COMPLETED,
		},
	]

	function getOnboardingCurrentStatusMessage(onboardingApplicationStatus) {
		if (provider.inWaitlist) {
			alertBadge = {
				show: true,
				message: 'You are currently in the waiting list. We will notify you once we have an opening.',
				heading: 'In Waitlist',
				color: 'info',
			}
		} else {
			if (onboardingApplicationStatus === ProviderApplicationStatus.INFORMATION_REQUIRED || onboardingApplicationStatus === ProviderApplicationStatus.DID_NOT_FINISH) {
				alertBadge = {
					show: true,
					message: 'Please fill out the application to proceed.',
					heading: 'Information Required',
					color: 'warning',
				}
			} else if (onboardingApplicationStatus === ProviderApplicationStatus.IN_REVIEW) {
				alertBadge = {
					show: true,
					message: 'Your application is currently in review. Please wait for approval.',
					heading: 'Application In Review',
					color: 'info',
				}
			} else if (onboardingApplicationStatus === ProviderApplicationStatus.APPLICATION_APPROVED) {
				alertBadge = {
					show: true,
					message: 'Your application has been approved. Please check your email for the invite from our payroll system, Gusto.',
					heading: 'Application Approved',
					color: 'success',
				}
			} else if (onboardingApplicationStatus === ProviderApplicationStatus.SENT_HRIS_INVITE) {
				alertBadge = {
					show: true,
					message: 'An invite from our payroll system, Gusto, has been sent to your email. Please check your email for the next steps.',
					heading: 'Payroll Invitation Sent',
					color: 'info',
				}
			} else if (onboardingApplicationStatus === ProviderApplicationStatus.HRIS_ACCOUNT_APPROVED) {
				alertBadge = {
					show: true,
					message: 'Your account in our payroll system, Gusto, has been approved. Please wait for the final approval from our team.',
					heading: 'Gusto Account Approved',
					color: 'success',
				}
			} else if (onboardingApplicationStatus === ProviderApplicationStatus.COMPLETED) {
				alertBadge = {
					show: true,
					message: 'Congratulations! You have completed the onboarding process. You can now start claiming jobs.',
					heading: 'Application Completed',
					color: 'success',
				}
			} else if (onboardingApplicationStatus === ProviderApplicationStatus.REJECTED) {
				alertBadge = {
					show: true,
					message: 'Your application has been rejected. Please check your email for the reason and how to proceed.',
					heading: 'Application Rejected',
					color: 'danger',
				}
			} else {
				alertBadge = {
					show: false,
					message: '',
					heading: '',
					color: undefined,
				}
			}
		}
	}

	function updateDateOfBirth(dateOfBirth: Date) {
		if (dateOfBirthChanged && session.provider?.id) {
			updateUserDOB(dateOfBirth, session.provider.id)
			dobChangedSaved = true
			dateOfBirthChanged = false
		}
	}

	function getServiceEquipmentApprovalCounts(services) {
		serviceEquipmentApprovalCounts = services.reduce(
			(counts, service) => {
				if (service.serviceApplicationStatus === ProviderServiceApplicationStatus.APPROVED) {
					counts.approved++
				} else if (service.serviceApplicationStatus === ProviderServiceApplicationStatus.IN_REVIEW) {
					counts.pending++
				} else if (service.serviceApplicationStatus === ProviderServiceApplicationStatus.REJECTED) {
					counts.rejected++
				}
				return counts
			},
			{ approved: 0, pending: 0, rejected: 0 },
		)

		serviceEquipmentApprovedSummary = serviceEquipmentApprovalCounts.approved ? `${serviceEquipmentApprovalCounts.approved} approved` : ''
		serviceEquipmentPendingSummary = serviceEquipmentApprovalCounts.pending ? `${serviceEquipmentApprovalCounts.pending} pending` : ''
		serviceEquipmentRejectedSummary = serviceEquipmentApprovalCounts.rejected ? `${serviceEquipmentApprovalCounts.rejected} rejected` : ''
	}

	function getServiceStatus() {
		const serviceApplications = provider.services.filter(service => service.serviceApplicationStatus !== null) || []

		if (serviceApplications.length === 0) {
			return 'NEED_TO_COMPLETE'
		}
		if (serviceApplications.some(service => service.serviceApplicationStatus === ProviderServiceApplicationStatus.IN_REVIEW)) {
			return 'PENDING_REVIEW'
		} else if (serviceApplications.every(service => service.serviceApplicationStatus === ProviderServiceApplicationStatus.APPROVED)) {
			return 'COMPLETED'
		} else if (serviceApplications.some(service => service.serviceApplicationStatus === ProviderServiceApplicationStatus.REJECTED)) {
			return 'NEED_CORRECTION'
		}
		return 'NEED_TO_COMPLETE'
	}

	function toggleFileInput(id: string) {
		document.getElementById(id)?.click()
	}

	async function saveProviderAgreement(event: Event) {
		const element = event.target
		if (element instanceof HTMLInputElement) {
			provider.hasAcceptedProviderAgreement = element.checked
			await updateOnboardingInfo({
				providerId: provider.id,
				hasLiabilityInsurance: provider.hasLiabilityInsurance,
				acceptProviderAgreement: provider.hasAcceptedProviderAgreement,
			})
		}
	}

	async function saveUserInfo() {
		await updateOnboardingInfo({
			providerId: provider.id,
			hasLiabilityInsurance: provider.hasLiabilityInsurance,
			shirtSize: provider.tshirtSize,
			baseUserAccountInfo: {
				firstName: provider.userAccount.firstName,
				lastName: provider.userAccount.lastName,
				email: provider.userAccount.email,
				mobile: provider.userAccount.mobile?.trim() || null,
			},
		})
	}

	async function saveShippingAddressChanges() {
		if (provider.street && provider.city && provider.state && provider.zip) {
			await updateOnboardingInfo({
				providerId: provider.id,
				shippingAddress: {
					street: provider.street,
					city: provider.city,
					state: provider.state,
					zip: provider.zip,
				},
				hasLiabilityInsurance: provider.hasLiabilityInsurance,
			})
		}
	}

	async function saveServiceEquipmentFile(file: File | undefined, serviceId: number, serviceIndex: number) {
		if (!file) {
			return
		}
		const res = await saveFileIntoOnboardingApplication(file, 'SERVICE', serviceId)
		if (res) {
			const selectedServiceApplication = res.serviceApplications?.find(service => service.serviceId === serviceId)
			provider.services[serviceIndex] = {
				...provider.services[serviceIndex],
				uploadedPictureFilePath: formatImageFileUrl(selectedServiceApplication?.equipmentPictureFile?.path || ''),
				newPictureFileUploaded: true,
				serviceApplicationStatus: selectedServiceApplication?.status || ProviderServiceApplicationStatus.IN_REVIEW,
			}
			provider.onboardingApplicationStatus = res.status
		}
	}

	async function notInterestedService(providerServiceApplicationId: number) {
		try {
			if (!provider.onboardingApplication) {
				throw new Error('Provider does not have an onboarding application')
			}
			const { data } = await notInterestedServiceApplicationMutation.mutate({
				providerServiceApplicationId,
				providerApplicationId: provider.onboardingApplication.id,
			})

			if (data) {
				const serviceApplicationIndex = provider.services.findIndex(service => service.serviceApplicationId === providerServiceApplicationId)
				provider.services[serviceApplicationIndex] = {
					...provider.services[serviceApplicationIndex],
					uploadedPictureFilePath: null,
					newPictureFileUploaded: false,
					serviceApplicationId: null,
					serviceApplicationStatus: null,
					rejectMessage: null,
				}
				provider.onboardingApplicationStatus = data.deleteServiceApplicationInOnboardingApplication.status
				provider.onboardingApplication.lastUpdated = data.deleteServiceApplicationInOnboardingApplication.lastUpdated
			}

			if (provider.onboardingApplicationStatus === ProviderApplicationStatus.IN_REVIEW) {
				alert('Thank you for updating your application. Your service application has been removed. Please wait for the admin to review your application again.')
			}
		} catch (error) {
			console.error(error)
			mediator.call('showError', error as Error, { message: 'This action cannot be proceed at the moment. Please try again.', title: 'Something went wrong...' })
		}
	}

	async function saveTransportationImage(file: File | undefined) {
		if (!file) return
		const res = await saveFileIntoOnboardingApplication(file, 'TRANSPORTATION')
		if (res) {
			provider.transportationPictureFile = {
				status: res.transportationStatus,
				path: formatImageFileUrl(res.transportationPictureFile?.path || ''),
			}
			provider.onboardingApplicationStatus = res.status
			newTransportationImageUploaded = true
		}
	}

	async function saveLicenseImage(file: File | undefined) {
		if (!file) return
		const res = await saveFileIntoOnboardingApplication(file, 'DRIVERS_LICENSE')
		if (res) {
			provider.driversLicensePictureFile = {
				status: res.driverLicenseStatus,
				path: formatImageFileUrl(res.driversLicensePictureFile?.path || ''),
			}
			provider.onboardingApplicationStatus = res.status
			newLicenseImageUploaded = true
		}
	}

	async function updateOnboardingInfo(onboardingInfo: OnboardingBasicInfoInputs) {
		try {
			if (!provider.onboardingApplication) {
				throw new Error('Provider does not have an onboarding application')
			}
			const { data } = await updateProviderBasicInfoMutation.mutate({
				onboardingInfo: {
					providerId: onboardingInfo.providerId,
					shippingAddress: onboardingInfo.shippingAddress,
					baseUserAccountInfo: onboardingInfo.baseUserAccountInfo,
					hasLiabilityInsurance: onboardingInfo.hasLiabilityInsurance,
					acceptProviderAgreement: onboardingInfo.acceptProviderAgreement,
					shirtSize: onboardingInfo.shirtSize,
					providerOnboardingApplicationId: provider.onboardingApplication.id,
				},
			})
			if (data) {
				provider.onboardingApplicationStatus = data.updateProviderOnboardingBasicInfo.onboardingApplication?.status ?? null
				provider.onboardingApplication.lastUpdated = data.updateProviderOnboardingBasicInfo.onboardingApplication?.lastUpdated ?? new Date()
			}

			if (provider.onboardingApplicationStatus === ProviderApplicationStatus.IN_REVIEW) {
				alert('Thank you for filling out the application. Please wait for the admin to review your application.')
			}
		} catch (error) {
			console.error(error)
			mediator.call('showError', error as Error, {
				message: 'Something went wrong when updating your information. Please refresh the page and try again.',
				title: 'Error on updating your information',
			})
		}
	}

	async function saveFileIntoOnboardingApplication(file: File, type: ProviderOnboardingImageUploadType, serviceId?: number) {
		const fileString = await readAsDataURL(file)
		const prefixString = ';base64,'
		const base64EncodedData = fileString.substring(fileString.indexOf(prefixString) + prefixString.length)
		uploadingFileImage = true
		try {
			if (!provider.onboardingApplication) {
				throw new Error('Provider does not have an onboarding application')
			}
			const { data } = await uploadFileToOnboardingApplicationMutation.mutate({
				input: {
					providerOnboardingApplicationId: provider.onboardingApplication.id,
					image: {
						base64String: base64EncodedData,
						fileName: file.name,
					},
					serviceId,
					type,
				},
			})
			if (data) {
				provider.onboardingApplication.lastUpdated = data.attachFileToProviderOnboardingApplication.lastUpdated
				return data.attachFileToProviderOnboardingApplication
			}

			if (provider.onboardingApplicationStatus === ProviderApplicationStatus.IN_REVIEW) {
				alert('Thank you for filling out the application. Please wait for the admin to review your application.')
			}
		} catch (error) {
			console.error(error)
			mediator.call('showError', error as Error, { message: 'Failed to upload image file. Please try again.', title: 'Error Uploading Image File' })
		} finally {
			uploadingFileImage = false
		}
	}
</script>

<ProgressBar {steps} />
<div style="max-width: 600px; margin: auto;">
	{#if alertBadge.show}
		<Alert
			shown
			color={alertBadge.color}
			class="mb-3"
			dismissable
			on:dismissed={() => {
				alertBadge.show = false
			}}
		>
			<h6
				class="mb-0 alert-heading"
				slot="heading"
			>
				{alertBadge.heading}
			</h6>
			<span>{alertBadge.message}</span><br />
			<span>{provider.onboardingApplication?.lastUpdated ? ` Last updated: ${new Date(provider.onboardingApplication.lastUpdated).toLocaleString()}` : ''}</span>
		</Alert>
	{/if}
	<CollapsibleCard
		cardHeaderClass="card-header d-flex justify-content-between align-items-center"
		bodyShownAnimationDuration={ANIMATION_DURATION}
		entireHeaderToggles={true}
		footerHides={true}
		bodyShown={openCard === 'BASIC'}
		disabled={disableCollapsibleCard}
		on:show={() => {
			openCard = 'BASIC'
		}}
		on:hide={() => {
			openCard = 'NONE'
			if (provider.userAccount.provider?.dateOfBirth) {
				updateDateOfBirth(provider.userAccount.provider?.dateOfBirth)
			}
		}}
	>
		<div slot="cardHeader">
			<h5 class="mb-0">Basic Information</h5>
			<span class={processStatusMap[basicInfoStatus].textColor}>{processStatusMap[basicInfoStatus].status}</span>
		</div>
		<div class="form-row">
			<div class="col-12 form-row">
				<UserProfile
					smallSpace
					bind:userAccount={provider.userAccount}
					descriptionForMobile={null}
					showProfilePicture
					disableEmailField
					requiredMobile
					{dobChangedSaved}
					on:change={saveUserInfo}
					on:DOBChanged={() => {
						dateOfBirthChanged = true
					}}
				/>
				<hr class="col-12" />
				<div class="col-12">
					<h6 class="mb-0">Shipping Address</h6>
					<small class="text-muted">This is where we will send your welcome package.</small>
				</div>
				<div class="col-12">
					<Input
						required
						label="Street Address"
						type="text"
						bind:value={provider.street}
						validation={{
							value: provider.street,
						}}
						on:change={saveShippingAddressChanges}
					/>
				</div>
				<div class="col-12 col-sm-4">
					<Input
						required
						label="City"
						type="text"
						bind:value={provider.city}
						validation={{
							value: provider.city,
						}}
						on:change={saveShippingAddressChanges}
					/>
				</div>
				<div class="col-6 col-sm-4">
					<Select
						required
						label="State"
						options={states.sort()}
						bind:value={provider.state}
						emptyValue={''}
						validation={{
							value: provider.state,
						}}
						on:change={saveShippingAddressChanges}
					/>
				</div>
				<div class="col-6 col-sm-4">
					<Input
						required
						label="Zip"
						type="text"
						bind:value={provider.zip}
						validation={{
							value: provider.zip,
						}}
						on:change={saveShippingAddressChanges}
					/>
				</div>
			</div>
			<hr class="col-12" />
			<div class="col-12">
				<Label
					label="Do you already have commercial liability insurance?"
					labelClass="mr-2 pb-0"
					required
				>
					<small class="d-block text-muted pb-2">Not required to start but you'll benefit if you do.</small>
					<Checkbox
						showLabel={false}
						bind:checked={provider.hasLiabilityInsurance}
						type="radio"
						radioButtonColor="primary"
						on:change={saveUserInfo}
					/>
				</Label>
			</div>
			<hr class="col-12" />
			<div class="col-12">
				<Select
					inline
					required
					label="T-shirt Size"
					options={shirtSizes}
					labelParentClass="form-inline"
					labelClass="mr-2 p-0"
					showEmptyOption={true}
					emptyText="Select Size"
					bind:value={provider.tshirtSize}
					validation={{
						value: provider.tshirtSize,
					}}
					on:change={saveUserInfo}
				/>
			</div>
		</div>
	</CollapsibleCard>
	<CollapsibleCard
		cardHeaderClass="card-header d-flex justify-content-between align-items-center"
		bodyShownAnimationDuration={ANIMATION_DURATION}
		entireHeaderToggles={true}
		footerHides={true}
		bodyShown={openCard === 'SERVICES'}
		disabled={disableCollapsibleCard}
		on:show={() => {
			openCard = 'SERVICES'
			if (provider.userAccount.provider?.dateOfBirth) {
				updateDateOfBirth(provider.userAccount.provider?.dateOfBirth)
			}
		}}
		on:hide={() => {
			openCard = 'NONE'
		}}
	>
		<div slot="cardHeader">
			<h5 class="mb-0">Service Equipment Verification</h5>
			<span class={processStatusMap[servicesStatus].textColor}>{processStatusMap[servicesStatus].status}</span><br />
			<small class="text-success">{serviceEquipmentApprovedSummary}</small>
			<small class="text-info">{serviceEquipmentPendingSummary}</small>
			<small class="text-danger">{serviceEquipmentRejectedSummary}</small>
		</div>
		<span class="font-weight-bold">For each service you wish to offer, please upload an image of the necessary equipment. You must have all the required equipment to perform the job.</span>
		<div class="mt-2">
			{#each provider.services as service, index}
				<CollapsibleCard
					headerText=""
					cardHeaderClass="card-header d-flex justify-content-between align-items-center h6"
					bodyShownAnimationDuration={ANIMATION_DURATION}
					entireHeaderToggles={true}
					footerHides={true}
					bodyShown={openServiceCard === service.id}
					on:show={() => {
						openServiceCard = service.id
					}}
					on:hide={() => {
						openServiceCard = null
					}}
				>
					<div slot="cardHeader">
						<div class="d-flex flex-column">
							<span>{service.name}</span>
							{#if service.serviceApplicationStatus}
								<small class={serviceApplicationStatusMap[service.serviceApplicationStatus].color}>
									<Icon icon={serviceApplicationStatusMap[service.serviceApplicationStatus].icon} />
									{serviceApplicationStatusMap[service.serviceApplicationStatus].description}
								</small>
							{/if}
						</div>
					</div>
					<span class="font-weight-bold">Please upload an image of your equipment for this service.</span><br />
					{#if service.requirement}
						<span class="font-weight-bold">Requirements:</span>
						<ul>
							{#each service.requirement.split('\n') as requirement}
								<li>{requirement}</li>
							{/each}
						</ul>
					{/if}
					<FileInput
						id={`equipment-${service.id}`}
						accept="image/*"
						style="display: none;"
						on:change={event => saveServiceEquipmentFile(event.detail[0], service.id, index)}
					/>
					<div class="d-flex flex-column mt-2">
						{#if service.examplePictureFilePath && !service.uploadedPictureFilePath}
							<label
								for={`equipment-${service.id}`}
								class="mb-1"
							>
								Example Image:
							</label>
							<img
								id={`equipment-${service.id}-example-image`}
								class="rounded w-100"
								src={service.examplePictureFilePath}
								alt="Service Example"
								title="Example of the service"
							/>
						{:else if service.uploadedPictureFilePath}
							<img
								id={`equipment-${service.id}-uploaded-image`}
								class="rounded w-100"
								src={service.uploadedPictureFilePath}
								alt="Your Equipment"
								title="Your Uploaded Equipment Image"
							/>
							{#if service.newPictureFileUploaded}
								<Alert
									class="mt-2 mb-0"
									shown={true}
									color="success"
								>
									<Icon
										class="mr-1"
										icon="check"
									/>
									Equipment image uploaded!
								</Alert>
							{:else if service.serviceApplicationStatus === ProviderServiceApplicationStatus.REJECTED}
								<Alert
									class="mt-2 mb-0"
									shown={true}
									color="danger"
								>
									<h6
										class="mb-0 alert-heading"
										slot="heading"
									>
										Your equipment has been rejected!
									</h6>
									{service.rejectMessage}
								</Alert>
							{/if}
						{/if}
					</div>
					<Button
						class="mt-2 w-100"
						size="sm"
						textClass="text-center"
						icon={{
							class: uploadingFileImage ? 'fa-spin' : '',
							icon: uploadingFileImage ? 'spinner' : 'file-upload',
						}}
						wrap
						outline
						on:click={() => toggleFileInput(`equipment-${service.id}`)}
					>
						{service.uploadedPictureFilePath ? 'Change' : 'Upload'} Equipment Image
					</Button>
					{#if service.serviceApplicationStatus === ProviderServiceApplicationStatus.REJECTED}
						<Button
							class="mt-2 w-100"
							color="secondary"
							size="sm"
							textClass="text-center"
							icon={{
								icon: 'times',
							}}
							wrap={true}
							outline={true}
							on:click={() => {
								if (confirm('Are you sure you want to remove this service?') && service.serviceApplicationId) {
									notInterestedService(service.serviceApplicationId)
								}
							}}
						>
							Not Interested
						</Button>
					{/if}
				</CollapsibleCard>
			{/each}
		</div>
	</CollapsibleCard>
	<CollapsibleCard
		cardHeaderClass="card-header d-flex justify-content-between align-items-center"
		bodyShownAnimationDuration={ANIMATION_DURATION}
		entireHeaderToggles={true}
		footerHides={true}
		bodyShown={openCard === 'TRANSPORTATION'}
		disabled={disableCollapsibleCard}
		on:show={() => {
			openCard = 'TRANSPORTATION'
			if (provider.userAccount.provider?.dateOfBirth) {
				updateDateOfBirth(provider.userAccount.provider?.dateOfBirth)
			}
		}}
		on:hide={() => {
			openCard = 'NONE'
		}}
	>
		<div slot="cardHeader">
			<h5 class="mb-0">Transportation Verification</h5>
			<span class={processStatusMap[transportationStatus].textColor}>{processStatusMap[transportationStatus].status}</span>
		</div>
		<span class="font-weight-bold">Please upload an image of your set up - Your truck, trailer or cargo carrier as seen in the image below.</span><br />
		<small class="text-muted">
			We need to make sure that our providers have a safe way to transport their equipment. You must have one of these three options:
			<ul>
				<li>Truck</li>
				<li>Trailer</li>
				<li>Cargo Carrier</li>
			</ul>
		</small>
		{#if provider.transportationPictureFile.path}
			<img
				class="rounded w-100"
				src={provider.transportationPictureFile.path}
				alt="Your Transportation"
				title="Your Transportation"
			/>
			{#if newTransportationImageUploaded}
				<Alert
					class="mt-2 mb-0"
					shown={true}
					color="success"
				>
					<Icon
						class="mr-1"
						icon="check"
					/>
					Transportation image uploaded!
				</Alert>
			{:else if provider.transportationPictureFile.status === PictureFileStatus.REJECTED}
				<Alert
					class="mt-2 mb-0"
					shown={true}
					color="danger"
				>
					<h6
						class="mb-0 alert-heading"
						slot="heading"
					>
						Your transportation has been rejected!
					</h6>
					{provider.transportationPictureFile.rejectMessage}
				</Alert>
			{/if}
		{:else}
			<img
				class="rounded w-100"
				src="images/transportation-example.png"
				alt="Transportation Example"
				title="Transportation Example"
			/>
		{/if}
		<FileInput
			id="vehicle"
			accept="image/*"
			style="display: none;"
			on:change={event => saveTransportationImage(event.detail[0])}
		/>
		<Button
			class="mt-2 w-100"
			size="sm"
			icon={{
				class: uploadingFileImage ? 'fa-spin' : '',
				icon: uploadingFileImage ? 'spinner' : 'file-upload',
			}}
			outline
			on:click={() => toggleFileInput('vehicle')}
		>
			{provider.transportationPictureFile?.path ? 'Change' : 'Submit'} Transportation Image
		</Button>
	</CollapsibleCard>
	<CollapsibleCard
		cardHeaderClass="card-header d-flex justify-content-between align-items-center"
		bodyShownAnimationDuration={ANIMATION_DURATION}
		entireHeaderToggles={true}
		footerHides={true}
		bodyShown={openCard === 'LICENSE'}
		disabled={disableCollapsibleCard}
		on:show={() => {
			openCard = 'LICENSE'
			if (provider.userAccount.provider?.dateOfBirth) {
				updateDateOfBirth(provider.userAccount.provider?.dateOfBirth)
			}
		}}
		on:hide={() => {
			openCard = 'NONE'
		}}
	>
		<div slot="cardHeader">
			<h5 class="mb-0">Driver's License</h5>
			<span class={processStatusMap[licenseStatus].textColor}>{processStatusMap[licenseStatus].status}</span>
		</div>
		<span class="font-weight-bold">Please upload an image of the FRONT of your Driver License.</span><br />
		<small class="text-muted"
			>We need to verify your identity and that you have a valid driver's license. Your data is transmitted to us via industry-standard encryption and is stored encrypted on our servers. Once we have
			it, only authorized persons are allowed to view your data.</small
		>
		{#if provider.driversLicensePictureFile.path}
			<img
				class="rounded w-100"
				src={provider.driversLicensePictureFile.path}
				alt="Your License"
				title="Your License"
			/>
			{#if newLicenseImageUploaded}
				<Alert
					class="mt-2 mb-0"
					shown={true}
					color="success"
				>
					<Icon
						class="mr-1"
						icon="check"
					/>
					License image uploaded!
				</Alert>
			{:else if provider.driversLicensePictureFile.status === PictureFileStatus.REJECTED}
				<Alert
					class="mt-2 mb-0"
					shown={true}
					color="danger"
				>
					<h6
						class="mb-0 alert-heading"
						slot="heading"
					>
						Your driver license has been rejected!
					</h6>
					{provider.driversLicensePictureFile.rejectMessage}
				</Alert>
			{/if}
		{/if}
		<FileInput
			id="license"
			accept="image/*"
			style="display: none;"
			on:change={event => saveLicenseImage(event.detail[0])}
		/>
		<Button
			class="mt-1 w-100"
			size="sm"
			icon={{
				class: uploadingFileImage ? 'fa-spin' : '',
				icon: uploadingFileImage ? 'spinner' : 'file-upload',
			}}
			outline
			on:click={() => toggleFileInput('license')}
		>
			{provider.driversLicensePictureFile.path ? 'Change' : 'Submit'} Front License Image
		</Button>
	</CollapsibleCard>
	<CollapsibleCard
		cardHeaderClass="card-header d-flex justify-content-between align-items-center"
		bodyShownAnimationDuration={ANIMATION_DURATION}
		entireHeaderToggles={true}
		footerHides={true}
		bodyShown={openCard === 'AGRREEMENT'}
		disabled={disableCollapsibleCard}
		on:show={() => {
			openCard = 'AGRREEMENT'
			if (provider.userAccount.provider?.dateOfBirth) {
				updateDateOfBirth(provider.userAccount.provider?.dateOfBirth)
			}
		}}
		on:hide={() => {
			openCard = 'NONE'
		}}
	>
		<div slot="cardHeader">
			<h5 class="mb-0">Provider Agreement</h5>
			<span class={processStatusMap[agreementStatus].textColor}>{processStatusMap[agreementStatus].status}</span>
		</div>
		<Button
			class="w-100 mb-1"
			color="primary"
			size="sm"
			href="Lawnhiro-Provider-Agreement.pdf"
			target="provider-agreement"
			outline
		>
			View Provider Agreement
		</Button>
		<div class="mt-2">
			<Checkbox
				label="I agree to the terms and conditions"
				checked={provider.hasAcceptedProviderAgreement}
				type="checkbox"
				inline={true}
				on:change={saveProviderAgreement}
			/>
		</div>
	</CollapsibleCard>
</div>

<style>
	@media (max-width: 577px) {
		* :global(.w-xs-100) {
			width: 100% !important;
		}
	}

	div :global(.card:not(:last-child):not(:first-child)) {
		border-radius: 0px;
		border-bottom: none;
	}

	div :global(.card:first-child:not(:only-child)) {
		border-bottom: none;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	div :global(.card:last-child:not(:only-child)) {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
</style>
