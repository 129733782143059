export default {
    "name": "RegionHelperRegionAreaData",
    "kind": "HoudiniFragment",
    "hash": "6c788eb072eb10aa6f11c33951e3d8c42fea2f7a42fb1391022517d8aac5422c",

    "raw": `fragment RegionHelperRegionAreaData on RegionArea {
  id
  city
  state
  zip
  country
  description
  __typename
}
`,

    "rootType": "RegionArea",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "city": {
                "type": "String",
                "keyRaw": "city",
                "nullable": true,
                "visible": true
            },

            "state": {
                "type": "String",
                "keyRaw": "state",
                "visible": true
            },

            "zip": {
                "type": "String",
                "keyRaw": "zip",
                "nullable": true,
                "visible": true
            },

            "country": {
                "type": "String",
                "keyRaw": "country",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=daa63257c493a529fbe904a8279eb2448e2f432da9346bcbe6641c1f80afc795";