<script lang="ts">
	import type { ValidJobHours } from 'utility/get-valid-job-dates'

	export let label: string = 'When will you perform this job?'
	export let jobDay: string
	export let selectedDateTime: Date | null | undefined
	export let validJobHours: ValidJobHours = {
		days: {},
		sunrise: '',
		sunset: '',
	}
	export let timeRange: string | null
	export let disabled = false

	$: allValidJobDays = Object.keys(validJobHours.days) ?? {}
	$: selectedDayHours = validJobHours.days[jobDay] ?? []
</script>

<div class="form-group mb-0">
	<label for="daySelection">{label}</label>
	<div class="input-group input-group-sm">
		<select
			id="daySelection"
			class="custom-select"
			bind:value={jobDay}
			aria-label="Select a Date"
			{disabled}
			on:change={() => selectedDateTime = validJobHours.days[jobDay][0].date}
		>
			{#each allValidJobDays as day}
				<option value={day}>{day}</option>
			{/each}
		</select>
		<select
			bind:value={selectedDateTime}
			class="custom-select"
			aria-label="Select a Time"
			{disabled}
		>
		{#if timeRange === 'SunriseSunset'}
			<option
				disabled
				style="font-weight: lighter;"
			>
				Sunrise: {validJobHours.sunrise}
			</option>
		{/if}
		{#each selectedDayHours as hour}
			<option value={hour.date}>{hour.hour}</option>
		{/each}
		{#if timeRange === 'SunriseSunset'}
			<option
				disabled
				style="font-weight: lighter;"
			>
				Sunset: {validJobHours.sunset}
			</option>
		{/if}
		</select>
	</div>
</div>
