export default {
    "name": "GetGlobalSetting",
    "kind": "HoudiniQuery",
    "hash": "ee18214771afe4f47c7837a26b0fd1afe6e1604bf3ecfb625025d74ee723db5a",

    "raw": `query GetGlobalSetting($lookup: SettingLookup!) {
  getGlobalSetting(lookup: $lookup) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getGlobalSetting": {
                "type": "SettingResult",
                "keyRaw": "getGlobalSetting(lookup: $lookup)",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "lookup": "SettingLookup"
        },

        "types": {
            "SettingLookup": {
                "category": "String",
                "defaultValue": "String",
                "name": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b0398f46d3a92320cef9191852ab3b737385d2a1255c235afc131aecbc5d972b";