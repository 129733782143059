import template from './service.ractive.html'
import { klona } from 'klona'
import { toDisplay as percentToDisplay } from 'utility/format/format-percent'
import { toSave as percentToSave } from 'utility/format/format-percent'
import formatCurrency from 'utility/format/format-currency'
import { tagPricingTypeList } from 'utility/tag-pricing-types'
import { questionDataTypeList } from 'utility/question-data-types'
import { tagTypeList } from 'utility/tag-type-list'
import formatImageFileUrl from 'utility/format/format-image-file-url'
import { readAsDataURL } from '@isoftdata/promise-file-reader'

//Ractive components
import makeCheckbox from '@isoftdata/checkbox'
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'
import makeTextArea from '@isoftdata/textarea'
import makeCurrencyInput from '@isoftdata/currency-input'
import makeCollapsibleCardComponent from '@isoftdata/collapsible-card'

const serviceStatusList = [
	{ value: 'Active', label: 'Active' },
	{ value: 'Inactive', label: 'Inactive' },
]

const defaultServiceModalState = Object.freeze({
	serviceId: null,
	name: '',
	codeName: '',
	description: '',
	warning: '',
	status: null,
	maximumDaysToPerform: null,
	allowSubscription: false,
	allowNewApplication: false,
	show: false,
	errorMessage: '',
	isEditing: false,
})

const defaultServiceQuestionModalState = Object.freeze({
	selectedQuestionId: null,
	question: '',
	description: '',
	questionDataType: 'CHOICE',
	questions: [],
	isMakingNewQuestion: true,
	isEditingServiceQuestion: false,
	show: false,
	errorMessage: '',
})

const defaultServicePeriodModalState = Object.freeze({
	period: null,
	availableTags: [],
	selectedTagId: null,
	isMakingNewTag: true,
	tagCode: null,
	tagName: null,
	tagDescription: '',
	tagPrompt: '',
	postPriceAdjustment: true,
	tagEntityType: 'SCHEDULE',
	pricingType: 'PERCENT',
	priceAdjustment: null,
	show: false,
	errorMessage: '',
})

const defaultAnswerModalState = Object.freeze({
	answer: '',
	answerId: null,
	isMakingNewTag: true,
	tagOptionList: [],
	selectedTagId: null,
	tagCode: null,
	tagName: null,
	tagDescription: '',
	tagPrompt: '',
	tagEntityType: 'RESIDENCE',
	tagPricingId: null,
	pricingType: 'PERCENT',
	priceAdjustment: null,
	postPriceAdjustment: false,
	questionDataType: 'CHOICE',
	errorMessage: '',
	editing: false,
	show: false,
})

const tagPricingField = `#graphql
	id
	priceAdjustment
	pricingType
	serviceId
	tagId
	postPriceAdjustment
`
const tagFields = `#graphql
	id
	code
	name
	description
	prompt
	entityType
	tagPricingForService(serviceId: $serviceId) {
		${tagPricingField}
	}
`

const servicePeriodField = `#graphql
	id
    tagId
    serviceId
    status
    serviceSchedulePeriod
    tag {
		${tagFields}
    }
`
const answerFields = `#graphql
	id
	answer
	tag {
		${tagFields}
	}
`
const questionFields = `#graphql
	id
	question
	description
	questionDataType
	answers {
		${answerFields}
	}
	services {
		id
	}
`

const serviceField = `#graphql
    id
    name
    description
    codeName
    active
    allowSubscription
    warning
	maximumDaysToPerform
	requirement
	allowNewApplication
    examplePictureFileId
	examplePictureFile {
        id
        name
        path
    }
`

const tagsQuery = `#graphql
	query Tags($filter: TagQueryFilter, $serviceId: PositiveInt) {
		tags(filter: $filter) {
			data {
				${tagFields}
			}
		}
	}
`

const questionsQuery = `#graphql
	query Questions($questionFilter: QuestionFilter, $serviceId: PositiveInt) {
  		questions(questionFilter: $questionFilter) {
			data {
				${questionFields}
			}
		}
	}
`

const serviceSchedulePeriodsQuery = `#graphql
	query ServiceSchedulePeriods($filter: ServiceSchedulePeriodFilter, $serviceId: PositiveInt) {
  		serviceSchedulePeriods(filter: $filter) {
			data {
				${servicePeriodField}
			}
		}
	}
`

const newServiceSchedulePeriodMutation = `#graphql
	mutation NewServiceSchedulePeriod($serviceSchedulePeriod: NewServiceSchedulePeriod!, $serviceId: PositiveInt) {
		newServiceSchedulePeriod(serviceSchedulePeriod: $serviceSchedulePeriod) {
			${servicePeriodField}
		}
	}
`

const newTagPricingMutation = `#graphql
	mutation NewTagPricing($tagPricing: NewTagPricing!) {
		newTagPricing(tagPricing: $tagPricing) {
			${tagPricingField}
		}
	}
`

const newTagMutation = `#graphql
	mutation NewTag($tag: NewTag!, $serviceId: PositiveInt) {
		newTag(tag: $tag) {
			${tagFields}
		}
	}
`

const updateServiceSchedulePeriodStatusMutation = `#graphql
	mutation ToggleServiceSchedulePeriod($serviceSchedulePeriodId: Float!) {
		toggleServiceSchedulePeriod(serviceSchedulePeriodId: $serviceSchedulePeriodId) {
			id
			tagId
			serviceId
			status
			serviceSchedulePeriod
		}
	}
`
const deleteAnswerMutation = `#graphql
	mutation DeleteAnswer($deleteAnswerId: PositiveInt!) {
		deleteAnswer(id: $deleteAnswerId)
	}
`
const newAnswerMutation = `#graphql
	mutation NewAnswer($answer: NewAnswer!, $serviceId: PositiveInt) {
		newAnswer(answer: $answer) {
			${answerFields}
		}
	}
`
const editAnswerMutation = `#graphql
	mutation EditAnswer($editAnswer: EditAnswer!, $serviceId: PositiveInt) {
		editAnswer(editAnswer: $editAnswer) {
			${answerFields}
		}
	}
`
const editTagPricingMutation = `#graphql
	mutation EditTagPricing($tagPricing: EditTagPricing!) {
		editTagPricing(tagPricing: $tagPricing) {
			${tagPricingField}
		}
	}
`
const serviceQuestionMutation = `#graphql
	mutation AddQuestionToService($newServiceQuestion: NewServiceQuestion!, $serviceId: PositiveInt) {
		addQuestionToService(newServiceQuestion: $newServiceQuestion) {
			questions {
				${questionFields}
			}
		}
	}
`
const newQuestionMutation = `#graphql
	mutation NewQuestion($question: NewQuestion!, $serviceId: PositiveInt) {
		newQuestion(question: $question) {
			${questionFields}
		}
	}
`

export default function({ mediator, stateRouter }) {
	stateRouter.addState({
		name: 'app.admin.service',
		route: 'service',
		querystringParameters: [ 'status' ],
		template: {
			template,
			components: {
				itCheckbox: makeCheckbox(),
				itTable: makeTable(),
				itButton: makeButton(),
				itModal: makeModal(),
				itSelect: makeSelect({ twoway: true, lazy: false }),
				itInput: makeInput({ twoway: true, lazy: false }),
				itInputLazy: makeInput({ twoway: true, lazy: true }),
				itTextArea: makeTextArea({ twoway: true, lazy: false }),
				itCurrencyInput: makeCurrencyInput({ twoway: true }),
				itCollapsibleCard: makeCollapsibleCardComponent(),
			},
			computed: {
				columns() {
					return [
						{ property: 'name', name: 'Name' },
						{ property: 'description', name: 'Description' },
						{ property: 'maximumDaysToPerform', name: 'Service Window (Days)' },
						{ property: 'active', name: 'Status' },
						{ property: 'edit', name: '', sortType: false },
					]
				},

				selectedService() {
					const selectedServiceId = this.get('selectedServiceId')
					const services = this.get('services')
					return services.find(service => service.id === selectedServiceId)
				},
				selectedQuestion() {
					const selectedQuestionId = this.get('selectedQuestionId')
					const selectedServiceQuestions = this.get('selectedServiceQuestions')
					return selectedServiceQuestions.find(question => question.id === selectedQuestionId)
				},
				servicePeriods() {
					const selectedServiceSchedulePeriods = this.get('selectedServiceSchedulePeriods')
					return selectedServiceSchedulePeriods.map(serviceSchedulePeriod => {
						const tagPricing = serviceSchedulePeriod?.tag?.tagPricingForService
						return {
							...serviceSchedulePeriod,
							tagName: serviceSchedulePeriod.tag.name,
							priceAdjustment: tagPricing?.pricingType === 'PERCENT' ? `${percentToDisplay(tagPricing.priceAdjustment)}%` : formatCurrency(tagPricing.priceAdjustment),
						}
					})
				},
				selectedServiceResidenceQuestions() {
					const selectedServiceQuestions = this.get('selectedServiceQuestions')
					return selectedServiceQuestions.filter(question => question.questionDataType === 'CHOICE')
				},
				selectedServiceAddOns() {
					const selectedServiceQuestions = this.get('selectedServiceQuestions')
					return selectedServiceQuestions.filter(question => question.questionDataType === 'BOOLEAN')
				},
				selectedResidenceQuestionAnswerData() {
					const selectedQuestion = this.get('selectedQuestion')
					const selectedQuestionAnswers = selectedQuestion?.answers.map(answerOption => {
						const answerPricings = answerOption?.tag?.tagPricingForService

						let formattedPriceAdjustment = ''
						let pricingType
						let postPriceAdjustment = false

						if (answerPricings) {
							formattedPriceAdjustment = answerPricings?.pricingType === 'PERCENT' ? `${percentToDisplay(answerPricings?.priceAdjustment)}%` : formatCurrency(answerPricings?.priceAdjustment)
							pricingType = answerPricings?.pricingType
							postPriceAdjustment = answerPricings?.postPriceAdjustment
						}
						return {
							...answerOption,
							answer: answerOption.answer,
							tagName: answerOption.tag.name,
							tagPricingId: answerPricings?.id,
							priceAdjustment: answerPricings?.pricingType === 'PERCENT' ? `${percentToDisplay(answerPricings?.priceAdjustment)}` : answerPricings?.priceAdjustment,
							formattedPriceAdjustment,
							type: answerOption.tag.entityType,
							pricingType,
							postPriceAdjustment,
						}
					})
					return selectedQuestionAnswers
				},
				newAnswerAllowed() {
					const selectedQuestion = this.get('selectedQuestion')
					return !((selectedQuestion?.questionDataType === 'BOOLEAN' && selectedQuestion?.answers?.length > 0))
				},
			},
			setSeleectedServiceId(serviceId) {
				const selectedServiceId = this.get('selectedServiceId')
				this.set('selectedServiceId', selectedServiceId === serviceId ? null : serviceId)
			},
			uploadServiceImage() {
				document.getElementById('serviceImage')?.click()
			},
			removeServiceImage() {
				const fileUrl = this.get('serviceModal.serviceImageURL')
				this.set('serviceModal.serviceImageURL', URL.revokeObjectURL(fileUrl))
				this.set('serviceModal.serviceImageFile', null)
			},
			setImageFile(files) {
				const fileUrl = URL.createObjectURL(files[0])
				this.set('serviceModal.serviceImageURL', fileUrl)
			},
			async openEditAnswerModal(modalState = {}) {
				return await this.openAnswerModal({
					answer: modalState?.answer,
					answerId: modalState?.id,
					isMakingNewTag: false,
					selectedTagId: modalState?.tag?.id,
					tagCode: modalState?.tag?.code,
					tagName: modalState?.tagName,
					tagPricingId: modalState?.tagPricingId,
					pricingType: modalState?.pricingType,
					priceAdjustment: modalState?.priceAdjustment,
					postPriceAdjustment: modalState?.postPriceAdjustment ?? false,
					editing: true,
				})
			},
			async openAnswerModal(modalState = {}) {
				const selectedQuestion = this.get('selectedQuestion')
				const { tags } = await mediator.call('apiFetch', tagsQuery, {
					filter: {
						entityType: selectedQuestion.questionDataType === 'CHOICE' ? 'RESIDENCE' : 'JOB',
					},
				})

				modalState.tagOptionList = tags?.data?.reduce((acc, tag) => {
					if (modalState.editing) {
						if (tag.id === modalState.selectedTagId) {
							acc.push(tag)
							return acc
						}
					} else if (!selectedQuestion.answers?.some(answer => answer.tag.id === tag.id)) {
						acc.push(tag)
					}

					return acc
				}, [])

				await this.set({
					answerModal: {
						...klona(defaultAnswerModalState),
						...klona(modalState),
						tagEntityType: selectedQuestion.questionDataType === 'CHOICE' ? 'RESIDENCE' : 'JOB',
						show: true,
					},
				})
				this.find('#answerInput')?.select?.()
			},
			async openAddServiceModal() {
				await this.set({
					serviceModal: {
						...klona(defaultServiceModalState),
						status: 'Active',
						maximumDaysToPerform: 1,
						show: true,
					},
				})
				this.observe('serviceModal.name serviceModal.codeName', () => {
					const name = this.get('serviceModal.name')
					const codeName = this.get('serviceModal.codeName')

					const computedCodeName = (codeName.trim() || name.trim())
						.split(' ')
						.map(word => word.trim())
						.join('_')
						.toUpperCase()

					this.set('serviceModal.codeName', computedCodeName)
				})
			},
			async openEditServiceModal(selectedService) {
				await this.set({
					serviceModal: {
						...klona(defaultServiceModalState),
						serviceId: selectedService.id,
						name: selectedService.name,
						codeName: selectedService.codeName,
						description: selectedService.description,
						warning: selectedService.warning,
						status: selectedService.active ? 'Active' : 'Inactive',
						maximumDaysToPerform: selectedService.maximumDaysToPerform,
						allowSubscription: selectedService.allowSubscription,
						allowNewApplication: selectedService.allowNewApplication,
						requirement: selectedService.requirement,
						serviceImageFile: null,
						serviceImageURL: selectedService.examplePictureFile?.path ? formatImageFileUrl(selectedService.examplePictureFile?.path) : null,
						show: true,
						isEditing: true,
					},
				})
			},
			async openAddServiceQuestionModal() {
				const { questions } = await mediator.call('apiFetch', questionsQuery)
				const selectedServiceQuestions = this.get('selectedServiceQuestions')
				const filteredQuestions = questions.data.filter(question => !selectedServiceQuestions.find(serviceQuestion => serviceQuestion.id === question.id))
				await this.set({
					serviceQuestionModal: {
						...klona(defaultServiceQuestionModalState),
						questions: filteredQuestions,
						show: true,
					},
				})
				this.find('#questionDataTypeSelect')?.focus?.()

				this.observe('serviceQuestionModal.questionDataType', dataType => {
					const questions = filteredQuestions.filter(question => question.questionDataType === dataType)
					this.set('serviceQuestionModal.questions', questions)
				})
			},
			async openAddServicePeriodModal(serviceId) {
				const { tags } = await mediator.call('apiFetch', tagsQuery, { filter: { entityType: 'SCHEDULE' }, serviceId })
				const existingServicePeriods = this.get('servicePeriods')
				const availableTags = tags.data.filter(tag => !existingServicePeriods.find(servicePeriod => servicePeriod.tagId === tag.id))

				await this.set({
					addServicePeriodModal: {
						...klona(defaultServicePeriodModalState),
						availableTags,
						show: true,
					},
				})

				this.observe('addServicePeriodModal.pricingType', () => {
					this.set('addServicePeriodModal.priceAdjustment', 0)
				}, { init: false })
			},
			async saveServiceQuestion(modalContext = {}) {
				let questionId
				try {
					if (modalContext.isMakingNewQuestion) {
						const { newQuestion } = await mediator.call('apiFetch', newQuestionMutation, {
							question: {
								question: modalContext.question,
								description: modalContext.description,
								questionDataType: modalContext.questionDataType,
							},
						})
						questionId = newQuestion.id
					} else {
						questionId = modalContext.selectedQuestionId
					}
					const { addQuestionToService } = await mediator.call('apiFetch', serviceQuestionMutation, {
						newServiceQuestion: {
							serviceId: this.get('selectedServiceId'),
							questionId,
						},
						serviceId: this.get('selectedServiceId'),
					})
					await this.set({ selectedServiceQuestions: addQuestionToService?.questions })

					await this.set({
						//close the modal and remove all data to the default state
						serviceQuestionModal: klona(defaultServiceQuestionModalState),
						selectedQuestionId: questionId,
					})
				} catch (error) {
					console.error(error)
					this.set('serviceQuestionModal.errorMessage', error.message)
				}
			},
			async saveService({ serviceId, name, codeName, description, warning, status, maximumDaysToPerform, allowSubscription, allowNewApplication, requirement, serviceImageFile, serviceImageURL }) {
				try {
					const serviceToSave = {
						name,
						codeName,
						description: description || null,
						warning: warning || null,
						active: status === 'Active',
						allowSubscription,
						allowNewApplication,
						minimumDaysToPerform: 0, //always set this to 0 for now as we are currently not using it
						maximumDaysToPerform,
						requirement,
					}

					if (serviceImageFile) {
						const fileString = await readAsDataURL(serviceImageFile[0])
						const prefixString = ';base64,'
						const base64EncodedData = fileString.substring(fileString.indexOf(prefixString) + prefixString.length)
						serviceToSave.exampleImageFile = {
							base64String: base64EncodedData,
							fileName: serviceImageFile[0].name,
						}
					}
					if (!serviceImageURL) {
						serviceToSave.exampleImageFile = null
					}

					let savedService
					if (serviceId) {
						const editServiceMutation = `#graphql
							mutation EditService($editService: EditService!) {
                                editService(editService: $editService) {
									${serviceField}
								}
							}
						`
						const { editService } = await mediator.call('apiFetch', editServiceMutation, {
							editService: {
								id: serviceId,
								...serviceToSave,
							},
						})
						savedService = editService
					} else {
						const newServiceMutation = `#graphql
                            mutation NewService($newService: NewService!) {
                                newService(newService: $newService) {
                                    ${serviceField}
                                }
                            }
                        `

						const { newService } = await mediator.call('apiFetch', newServiceMutation, { newService: serviceToSave })
						savedService = newService
					}
					this.upsert('services', 'id', savedService)

					//close the modal and remove all data to the default state
					await this.set({ serviceModal: klona(defaultServiceModalState) })
				} catch (err) {
					console.error(err)
					this.set(
						'serviceModal.show', true,
						'serviceModal.errorMessage', err.message,
					)
				}
			},
			async removeQuestionFromService() {
				const selectedQuestion = this.get('selectedQuestion')
				const serviceName = this.get('selectedService')?.name || 'Unknown Service'
				if (confirm(`Are you sure you want to remove the "${selectedQuestion.question}" question from the "${serviceName}" service?`)) {
					try {
						const removeQuestionFromServiceMutation = `#graphql
							mutation RemoveQuestionFromService($removeServiceQuestion: RemoveServiceQuestion!, $serviceId: PositiveInt) {
								removeQuestionFromService(removeServiceQuestion: $removeServiceQuestion) {
									questions {
										${questionFields}
									}
								}
							}
						`
						const { removeQuestionFromService } = await mediator.call('apiFetch', removeQuestionFromServiceMutation, {
							removeServiceQuestion: {
								serviceId: this.get('selectedServiceId'),
								questionId: selectedQuestion.id,
							},
							serviceId: this.get('selectedServiceId'),
						})
						this.set({ selectedServiceQuestions: removeQuestionFromService?.questions })
					} catch (error) {
						console.error(error)
					}
				}
			},
			async addNewServicePeriod({ selectedTagId, priceAdjustment, tagCode, tagName, tagDescription, tagPrompt, tagEntityType, pricingType, period, postPriceAdjustment }) {
				let tagId
				if (!selectedTagId) {
					try {
						const { newTag } = await mediator.call('apiFetch', newTagMutation, {
							tag: {
								code: tagCode,
								name: tagName,
								description: tagDescription,
								prompt: tagPrompt,
								entityType: tagEntityType,
							},
						})
						tagId = newTag.id
					} catch (err) {
						console.error(err)
						this.set('addServicePeriodModal.errorMessage', err.message)
					}
				} else {
					tagId = selectedTagId
				}

				//We need to build a tag pricing regardless of if we made a new tag or not
				const serviceId = this.get('selectedServiceId')

				const tagPricing = {
					tagId,
					serviceId,
					priceAdjustment: pricingType === 'PERCENT' ? percentToSave(priceAdjustment) : priceAdjustment.toString(),
					pricingType,
					postPriceAdjustment,
				}

				const serviceSchedulePeriod = {
					serviceId,
					tagId,
					serviceSchedulePeriod: period,
				}
				try {
					// we don't need the new tag pricing object, since tag pricing need to tie to a tag
					// which in the newServiceSchedulePeriod mutation, we are passing the same tagId as well
					// therefore, in the return object of newServiceSchedulePeriod mutation, we will get the new tagPricing object as well
					await mediator.call('apiFetch', newTagPricingMutation, { tagPricing })
					const { newServiceSchedulePeriod } = await mediator.call('apiFetch', newServiceSchedulePeriodMutation, { serviceSchedulePeriod, serviceId })

					await this.upsert('selectedServiceSchedulePeriods', 'id', newServiceSchedulePeriod)
					await this.set('addServicePeriodModal.show', false)
				} catch (err) {
					console.error(err)
					this.set('addServicePeriodModal.errorMessage', err.message)
				}
			},
			async updateServiceSchedulePeriodStatus(serviceSchedulePeriodId) {
				try {
					const { toggleServiceSchedulePeriod: updatedServiceSchedulePeriod } = await mediator.call('apiFetch', updateServiceSchedulePeriodStatusMutation, { serviceSchedulePeriodId })
					const schedulePeriodIndex = this.get('selectedServiceSchedulePeriods').findIndex(schedulePeriod => schedulePeriod.id === updatedServiceSchedulePeriod.id)
					this.set(`selectedServiceSchedulePeriods.${schedulePeriodIndex}.status`, updatedServiceSchedulePeriod.status)
				} catch (err) {
					console.error(err)
					this.set('addServicePeriodModal.errorMessage', err.message)
				}
			},
			async saveAnswer(modalContext = {}) {
				try {
					let savedAnswer
					let savedTag
					let savedTagPricing

					/* First off we need to confirm a tag exists. If it does not, we need to create it. */

					if (modalContext.selectedTagId) {
						const { tags: existingTag } = await mediator.call('apiFetch', tagsQuery, {
							filter: {
								id: modalContext.selectedTagId,
							},
						})
						savedTag = existingTag.data[0] //this grabs a list of 1 tag
					} else {
						const { newTag } = await mediator.call('apiFetch', newTagMutation, {
							tag: {
								entityType: modalContext.tagEntityType,
								name: modalContext.tagName,
								code: modalContext.tagCode,
								description: modalContext.tagDescription,
								prompt: modalContext.tagPrompt,
							},
						})

						savedTag = newTag
					}

					/* Second we need to confirm an answer exists. If it does not, we need to create it. */

					if (modalContext.editing) {
						const { editAnswer } = await mediator.call('apiFetch', editAnswerMutation, {
							editAnswer: {
								id: modalContext.answerId,
								answer: modalContext.answer,
								tagId: savedTag.id,
							},
						})
						savedAnswer = editAnswer
					} else {
						const { newAnswer } = await mediator.call('apiFetch', newAnswerMutation, {
							answer: {
								answer: modalContext.answer,
								questionId: this.get('selectedQuestionId'),
								tagId: savedTag.id,
							},
						})

						savedAnswer = newAnswer
					}

					/* Third we need to confirm a tagPricing exists. If it does not, we need to create it or do nothing if the priceAdjustment is 0. */

					modalContext.priceAdjustment = modalContext?.priceAdjustment?.replace('%', '') || 0
					if (modalContext.tagPricingId) {
						const { editTagPricing } = await mediator.call('apiFetch', editTagPricingMutation, {
							tagPricing: {
								tagPricingId: modalContext.tagPricingId,
								pricingType: modalContext.pricingType,
								priceAdjustment: modalContext.pricingType === 'PERCENT' ? percentToSave(modalContext.priceAdjustment) : modalContext.priceAdjustment.toString(),
								postPriceAdjustment: modalContext.postPriceAdjustment,
							},
						})
						savedTagPricing = editTagPricing
					} else if (modalContext.priceAdjustment === 0 || '') {
						// if priceAdjustment is 0, we don't need to create a tagPricing
						savedTagPricing = null
					} else {
						const { newTagPricing } = await mediator.call('apiFetch', newTagPricingMutation, {
							tagPricing: {
								tagId: savedTag.id,
								serviceId: this.get('selectedServiceId'),
								pricingType: modalContext.pricingType,
								priceAdjustment: modalContext.pricingType === 'PERCENT' ? percentToSave(modalContext.priceAdjustment) : modalContext.priceAdjustment.toString(),
								postPriceAdjustment: modalContext.postPriceAdjustment,
							},
						})
						savedTagPricing = newTagPricing
					}

					/* Last we need to update the tagPricing on the answer and update the answer in the selectedServiceQuestions array. */

					savedAnswer.tag.tagPricingForService = savedTagPricing
					const questionIndex = this.get('selectedServiceQuestions').findIndex(question => question.id === this.get('selectedQuestionId'))
					this.upsert(`selectedServiceQuestions[${questionIndex}].answers`, `id`, savedAnswer)

					await this.set({
						//close the modal and remove all data to the default state
						answerModal: klona(defaultAnswerModalState),
					})
				} catch (error) {
					console.error(error)
					this.set('answerModal.errorMessage', error?.message)
				}
			},
			async deleteAnswer(answerId, answer) {
				const questionId = this.get('selectedQuestionId')
				const question = this.get('selectedServiceQuestions').find(question => question.id === questionId)?.question || 'Unknown Question'
				if (confirm(`Are you sure you want to delete this answer "${answer}" for question "${question}" ?`)) {
					try {
						await mediator.call('apiFetch', deleteAnswerMutation, {
							deleteAnswerId: answerId,
						})

						const selectedQuestionId = this.get('selectedQuestionId')
						const questionIndex = this.get('selectedServiceQuestions').findIndex(question => question.id === selectedQuestionId)
						const answerIndex = this.get(`selectedServiceQuestions[${questionIndex}].answers`).findIndex(answer => answer.id === answerId)
						this.splice(`selectedServiceQuestions[${questionIndex}].answers`, answerIndex, 1)
					} catch (error) {
						console.error(error)
						mediator.call('showError', error, { message: `Error deleting answer ${answer}`, title: 'Error Deleting Answer' })
					}
				}
			},
		},
		async resolve(_data, parameters) {
			const servicesQuery = `#graphql
                query Services($filter: ServiceFilter) {
  					services(filter: $filter) {
                        data {
                            ${serviceField}
                        }
                    }
                }
            `

			let filter = {}
			if (parameters.status === 'Active') {
				filter.active = true
			} else if (parameters.status === 'Inactive') {
				filter.active = false
			} else {
				filter.active = null
			}

			const { services } = await mediator.call('apiFetch', servicesQuery, { filter })

			return {
				services: services?.data,
				sortedServices: [],
				serviceModal: klona(defaultServiceModalState),
				serviceQuestionModal: klona(defaultServiceQuestionModalState),
				addServicePeriodModal: klona(defaultServicePeriodModalState),
				answerModal: klona(defaultAnswerModalState),
				serviceStatusList,
				serviceStatusFilter: parameters?.status || '',
				selectedServiceId: null,
				tagTypeList,
				questionDataTypeList,
				selectedQuestionId: null,
				tagPricingTypeList,
				selectedServiceQuestions: [],
				selectedServiceSchedulePeriods: [],
				sortedServicePeriods: [],
				sortedSelectedServiceQuestions: [],
				isLoading: false,

			}
		},
		activate(activateContext) {
			const { domApi: ractive } = activateContext

			ractive.observe('serviceModal.show', show => {
				if (!show) {
					const fileUrl = ractive.get('serviceModal.serviceImageURL')
					if (fileUrl) {
						this.set('serviceModal.serviceImageURL', URL.revokeObjectURL(fileUrl))
					}
				}
			}, { init: false })

			const { cancel: cancelFilterObserver } = ractive.observe('serviceStatusFilter', () => {
				stateRouter.go('app.admin.service', {
					status: ractive.get('serviceStatusFilter'),
				})
			}, { init: false })

			const { cancel: cancelNewTagObserver } = ractive.observe('addServicePeriodModal.selectedTagId', tagId => {
				ractive.set('addServicePeriodModal.isMakingNewTag', !tagId)
			})

			const { cancel: cancelNewAnswerTagObserver } = ractive.observe('answerModal.selectedTagId', newAnswerTagId => {
				ractive.set('answerModal.isMakingNewTag', !newAnswerTagId)
			})

			const { cancel: cancelNewQuestionObserver } = ractive.observe('serviceQuestionModal.selectedQuestionId', questionId => {
				ractive.set('serviceQuestionModal.isMakingNewQuestion', !questionId)
			})

			ractive.observe('selectedServiceId', async serviceId => {
				await ractive.set({ isLoading: true, selectedServiceQuestions: [], selectedServiceSchedulePeriods: [], selectedQuestionId: null })
				const { questions } = await mediator.call('apiFetch', questionsQuery, { questionFilter: { serviceId }, serviceId })
				const { serviceSchedulePeriods } = await mediator.call('apiFetch', serviceSchedulePeriodsQuery, { filter: { serviceId }, serviceId })
				await ractive.set({
					isLoading: false,
					selectedServiceQuestions: questions?.data,
					selectedServiceSchedulePeriods: serviceSchedulePeriods?.data,
				})
			}, { init: false })

			activateContext.on('destroy', () => {
				cancelFilterObserver()
				cancelNewTagObserver()
				cancelNewAnswerTagObserver()
				cancelNewQuestionObserver()
			})
		},
	})
}
