import { parseISO } from 'date-fns'
import formatRelativeDateTime from './format/format-relative-date-time'

export interface ComputedJobClaimBase {
	scheduledAt: string | Date
}

export interface ComputedJobClaim extends ComputedJobClaimBase {
	scheduledAt: Date
	scheduledAtFormatted: string
}

export function getComputedJobClaim<T extends ComputedJobClaimBase>(jobClaim: T, today: Date | undefined): T & ComputedJobClaim {
	if (!today) {
		today = new Date()
	}

	const scheduledAt = jobClaim.scheduledAt instanceof Date ? jobClaim.scheduledAt : parseISO(jobClaim.scheduledAt)

	return {
		...jobClaim,
		scheduledAt,
		scheduledAtFormatted: `Scheduled for ${formatRelativeDateTime(scheduledAt, today).formattedDate}`,
	}
}

export default getComputedJobClaim
