export default {
    "name": "EditZone",
    "kind": "HoudiniMutation",
    "hash": "e2fddfe0e9504b091503465334c6deec00671095b72d45aed4ab40aae8d9f580",

    "raw": `mutation EditZone($updateZone: UpdateZone!, $regionIds: [PositiveInt!]) {
  updateZone(updateZone: $updateZone, regionIds: $regionIds) {
    ...ZoneData
    id
  }
}

fragment ZoneData on Zone {
  id
  name
  state
  description
  regions {
    id
    name
    description
    latitude
    longitude
    timeZone
    status
    zoneId
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateZone": {
                "type": "Zone",
                "keyRaw": "updateZone(regionIds: $regionIds, updateZone: $updateZone)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "regions": {
                            "type": "Region",
                            "keyRaw": "regions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "latitude": {
                                        "type": "Latitude",
                                        "keyRaw": "latitude",
                                        "visible": true
                                    },

                                    "longitude": {
                                        "type": "Longitude",
                                        "keyRaw": "longitude",
                                        "visible": true
                                    },

                                    "timeZone": {
                                        "type": "TimeZone",
                                        "keyRaw": "timeZone",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "RegionStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "zoneId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "zoneId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "ZoneData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "updateZone": "UpdateZone",
            "regionIds": "PositiveInt"
        },

        "types": {
            "UpdateZone": {
                "description": "String",
                "id": "PositiveInt",
                "name": "String",
                "state": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=eeeda04e1a89431dbb94ea00e6696d64b272ccc5ae7304eb9a9f1caeb9021da5";